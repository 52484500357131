import React from "react";
import "../styles/CustomerSupport.css";
import { Link } from "react-router-dom";
import raiseTicket from "../images/customer_support/raise_ticket.svg";
import arrowTicket from "../images/customer_support/arrow_right.svg";
import email from "../images/customer_support/email.svg";
import contacts from "../images/customer_support/contacts.svg";
import customerSupport from "../images/customer_support/customer_support.svg";
import { Helmet } from 'react-helmet-async'; // Import Helmet

// Functional component for the Home page
const PrivacyPolicy = () => {
  return (
    <>
    {/* Helmet for SEO Metadata */}
    <Helmet>
        <title>Privacy Policy | Fundswala</title>
        <meta 
          name="description" 
          content="Read the Privacy Policy of Fundswala to understand how we collect, use, and protect your personal information." 
        />
      </Helmet>
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-4">
        <div className="page_inner_content_box ">
          <h5
            className="mb-4 main-heading-title text-center"
            style={{ fontSize: "40px" }}
          >
            Privacy Policy
          </h5>
          <div className="container mt-5">
            <p>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </p>
            <p>
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy has been created with the help of the Privacy Policy
              Generator.
            </p>
            <h6>Interpretation and Definitions</h6>
            <h6>Interpretation</h6>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h6>Definitions</h6>
            <h6>For the purposes of this Privacy Policy:</h6>
            <p>
              Account means a unique account created for You to access our
              Service or parts of our Service.
            </p>
            <p>
              Company (referred to as either “the Company”, “We”, “Us” or “Our”
              in this Agreement) refers to Fundswala, Private.(An Initiative by
              – VNP Mutual Fund Services Private Limited)
            </p>
            <p>
              Cookies are small files that are placed on Your computer, mobile
              device or any other device by a website, containing the details of
              Your browsing history on that website among its many uses.
            </p>
            <p>Country refers to: Uttar Pradesh, India</p>
            <p>
              Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </p>
            <p>
              Personal Data is any information that relates to an identified or
              identifiable individual.
            </p>
            <h6>Service refers to the Website.</h6>
            <p>
              Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company
              in analyzing how the Service is used.
            </p>
            <p>
              Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </p>
            <p>
              Website refers to Fundswala, accessible from
              https://www.fundswala.com/cms/privacy-policy/ You means the
              individual accessing or using the Service, or the company, or
              other legal entity on behalf of which such individual is accessing
              or using the Service, as applicable.
            </p>
            <p>Collecting and Using Your Personal Data</p>
            <p>Types of Data Collected</p>
            <h6>Personal Data</h6>
            <p>
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
            </ul>

            <h6>Usage Data</h6>
            <p>
              Usage Data is collected automatically when using the Service.
              Usage Data may include information such as Your Device’s Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>

            <h6>Tracking Technologies and Cookies</h6>
            <p>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <ul>
              <li>
                <strong>Cookies or Browser Cookies:</strong> A cookie is a small
                file placed on Your Device. You can instruct Your browser to
                refuse all Cookies or to indicate when a Cookie is being sent.
                However, if You do not accept Cookies, You may not be able to
                use some parts of our Service. Unless you have adjusted Your
                browser setting so that it will refuse Cookies, our Service may
                use Cookies.
              </li>
              <li>
                <strong>Flash Cookies:</strong> Certain features of our Service
                may use local stored objects (or Flash Cookies) to collect and
                store information about Your preferences or Your activity on our
                Service. Flash Cookies are not managed by the same browser
                settings as those used for Browser Cookies. For more information
                on how You can delete Flash Cookies, please read{" "}
                <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">
                  this guide
                </a>
                .
              </li>
              <li>
                <strong>Web Beacons:</strong> Certain sections of our Service
                and our emails may contain small electronic files known as web
                beacons (also referred to as clear gifs, pixel tags, and
                single-pixel gifs) that permit the Company, for example, to
                count users who have visited those pages or opened an email and
                for other related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </li>
            </ul>
            <p>
              Cookies can be “Persistent” or “Session” Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. You can learn more about cookies here:{" "}
              <a href="https://www.termsfeed.com/privacy-policy-generator/">
                Cookies by TermsFeed Generator
              </a>
              .
            </p>

            <h6>Types of Cookies</h6>
            <ul>
              <li>
                <strong>Necessary / Essential Cookies</strong>
                <ul>
                  <li>Type: Session Cookies</li>
                  <li>Administered by: Us</li>
                  <li>
                    Purpose: These Cookies are essential to provide You with
                    services available through the Website and to enable You to
                    use some of its features. They help to authenticate users
                    and prevent fraudulent use of user accounts. Without these
                    Cookies, the services that You have asked for cannot be
                    provided, and We only use these Cookies to provide You with
                    those services.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                <ul>
                  <li>Type: Persistent Cookies</li>
                  <li>Administered by: Us</li>
                  <li>
                    Purpose: These Cookies identify if users have accepted the
                    use of cookies on the Website.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Functionality Cookies</strong>
                <ul>
                  <li>Type: Persistent Cookies</li>
                  <li>Administered by: Us</li>
                  <li>
                    Purpose: These Cookies allow us to remember choices You make
                    when You use the Website, such as remembering your login
                    details or language preference. The purpose of these Cookies
                    is to provide You with a more personal experience and to
                    avoid You having to re-enter your preferences every time You
                    use the Website.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>

            <h6>Use of Your Personal Data</h6>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li>
                To provide and maintain our Service, including to monitor the
                usage of our Service.
              </li>
              <li>
                To manage Your Account: to manage Your registration as a user of
                the Service. The Personal Data You provide can give You access
                to different functionalities of the Service that are available
                to You as a registered user.
              </li>
              <li>
                For the performance of a contract: the development, compliance
                and undertaking of the purchase contract for the products, items
                or services You have purchased or of any other contract with Us
                through the Service.
              </li>
              <li>
                To contact You: To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication, such as a
                mobile application’s push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </li>
              <li>
                To provide You with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.
              </li>
              <li>
                To manage Your requests: To attend and manage Your requests to
                Us.
              </li>
              <li>
                For business transfers: We may use Your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by Us about our Service users is among the assets transferred.
              </li>
              <li>
                For other purposes: We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience.
              </li>
            </ul>
            <p>
              The full policy continues further as detailed in the original
              text.
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PrivacyPolicy;
