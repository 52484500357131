import {
  GET_EXPLORE_FUNDS_LIST,
  GET_AMC_LISTS,
  GET_EXPLORE_FUNDS_DETAIL,
  GET_NAV_CHART,
  GET_SIMILAR_FUNDS,
  CART_START,
  ADD_MY_CART,
  DELETE_CART,
  CHANGE_ADD_TO_CART_STATUS,
  CHANGE_WATCHLIST_STATUS,
  UPDATE_EXPLORE_FUNDS_LIST,
} from "../actions/types";

const initialState = {
  exploreFundsList: [],
  amcLists: [],
  exploreFundsDetail: {},
  navChart: [],
  similarFunds: {},
  addMyCart: "",
  deleteMyCart: "",
  loading: false,
  loadData: false,
};

const exploreFundsReducers = (state = initialState, action) => {
  switch (action.type) {
    case CART_START:
      return { ...state, loading: true };
    case GET_EXPLORE_FUNDS_LIST:
      return { ...state, loading: false, exploreFundsList: action.payload };
    case GET_AMC_LISTS:
      return { ...state, loading: false, amcLists: action.payload };
    case GET_EXPLORE_FUNDS_DETAIL:
      return { ...state, loading: false, exploreFundsDetail: action.payload };
    case GET_NAV_CHART:
      return { ...state, loading: false, navChart: action.payload };
    case GET_SIMILAR_FUNDS:
      return { ...state, loading: false, similarFunds: action.payload };
    case ADD_MY_CART:
      return { ...state, loading: false, addMyCart: action.payload };
    case DELETE_CART:
      return { ...state, loading: false, deleteMyCart: action.payload };
    case CHANGE_ADD_TO_CART_STATUS:
      return { ...state, loading: false, loadData: action.payload };
    case CHANGE_WATCHLIST_STATUS:
      return { ...state, loading: false, loadData: action.payload };
    case UPDATE_EXPLORE_FUNDS_LIST:
      return {
        ...state,
        exploreFundsList: updateExploreFundsList(
          state.exploreFundsList,
          action.payload
        ),
      };
    default:
      return state;
  }
};

const updateExploreFundsList = (currentList, newList) => {
  // Combine the current list and new list, and only update existing records
  const updatedList = [...currentList?.data?.products]; // Copy the current list to avoid direct mutation

  newList.forEach((newItem) => {
    const existingItemIndex = updatedList.findIndex(
      (item) => item.id === newItem.id
    );

    if (existingItemIndex === -1) {
      // If the item doesn't exist in the current list, add it
      updatedList.push(newItem);
    } else {
      // If the item exists, update it only if necessary
      const existingItem = updatedList[existingItemIndex];
      if (!areRecordsEqual(existingItem, newItem)) {
        updatedList[existingItemIndex] = { ...existingItem, ...newItem };
      }
    }
  });
  const completeObj = { ...currentList?.data, products: updatedList };
  return { ...currentList, data: completeObj };
};

// Helper function to compare two records (for partial update)
const areRecordsEqual = (record1, record2) => {
  // Check if the records are equal (you can adjust this logic based on the fields you want to compare)
  return JSON.stringify(record1) === JSON.stringify(record2); // Simple deep comparison
};
export default exploreFundsReducers;
