import React, { useEffect, useState } from "react";
import "../styles/redeem.css";
import InvestmentHeaderBox1 from "../components/InvestmentHeaderBox1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectDropDown from "../components/SelectDropDown";
import { Formik, useFormik } from "formik";
import "../styles/profile.css";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { stpScheme, stpShow, stpStore } from "../actions/holdingAction";
import { getLocalStorageUserData } from "../utils/userData";
import { currentDate } from "../utils/DateFormate";
import PopupModel from "../components/PopupModel";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";

const STP = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const userData = getLocalStorageUserData();
  const { stpShows, stpStores, stpSchemes, loading, error } = useSelector(
    (state) => state.holdings
  );
  const dataOfStp = location?.state?.props;

  const closeModal = () => {
    setIsPopupVisible(false);
  };

  useEffect(() => {
    const payload = {
      id: userData?.id,
      amc_product_id: dataOfStp?.product?.amc_product_id,
      productlimit_id: dataOfStp?.productlimit?.id,
      folio_no: dataOfStp?.folioNumber,
    };
    dispatch(stpShow(payload));
  }, []);

  const targetScemaOption = [
    {
      label: "Growth Option",
      value: "Z",
    },
    {
      label: "Dividend Payout",
      value: "X",
    },
    {
      label: "Dividend Reinvest",
      value: "Y",
    },
  ];

  const actionPopup = () => {
    navigate("/portfolio");
  };

  const formik = useFormik({
    initialValues: {
      id: userData?.id,
      amc_product_id: stpShows?.data?.product?.amc_product_id || 0,
      target_scheme_reinvest: "",
      all_scheme: null,
      payout_scheme: null,
      // reinvest_scheme: "Y",
      product_code: stpShows?.data?.product?.product_code,
      product_id: stpShows?.data?.product?.id,
      productlimit_id: stpShows?.data?.productlimit?.id,
      isin: stpShows?.data?.product?.isin,
      amount:
        parseInt(stpShows?.data?.currentAmount?.replace(/,/g, "")).toString() ||
        "0",
      current_value: stpShows?.data?.currentAmount,
      current_units: stpShows?.data?.availableUnits,
      target_scheme: "",
      source_scheme: "",
      date_installment: "",
      frequency: "",
      tenure: "",
      folio_no: stpShows?.data?.folio_no?.toString(),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(/^\d+(\.\d+)?$/, "Amount must be a valid number") // Regex to allow only numbers or float values
        .required("Amount is required")
        .typeError("Amount must be a number"),
      target_scheme: Yup.string()
        .notOneOf(["Select"], "Target scheme is required")
        .required("Target scheme is required"),
      source_scheme: Yup.string()
        .notOneOf(["Select"], "Source scheme is required")
        .required("Source scheme is required"),
      date_installment: Yup.string()
        .notOneOf(["Select"], "Day is required")
        .required("Day is required"),
      frequency: Yup.string()
        .notOneOf(["Select"], "Frequency is required")
        .required("Frequency is required"),
      tenure: Yup.number()
        .notOneOf(["Select"], "No. of Installation is required")
        .typeError("No. of Installations must be a number")
        .required("No. of Installations is required")
        .min(1, "No. of Installations must be at least 1"),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        target_scheme_reinvest: values?.target_scheme,
        target_scheme: values?.source_scheme,
      };
      dispatch(stpStore(payload)).then((response) => {
        if (response) {
          if (response?.status == true) {
            navigate(
              `/orders?order=${
                response?.data?.order_id
                  ? response?.data?.order_id
                  : response?.data?.order
              }`
            );
          } else {
            setIsPopupVisible(true);
          }
        } else {
          setIsPopupVisible(true);
        }
      });
    },
  });

  const cancelBack = () => {
    formik.resetForm();
    navigate("/portfolio");
  };
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            <Link className="back_icon" to="/portfolio">
              <FontAwesomeIcon icon={faAngleLeft} className="color me-3" />
            </Link>
            STP
          </h5>
          <InvestmentHeaderBox1 props={dataOfStp} />
          <div className="card redeam_bottom_card ">
            <div className="card-body p-0">
              <div className="row align-items-center r_inner_section">
                <div className="col-md-auto col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    Amount:
                    <span className="fw-bold ms-2">
                      ₹{stpShows?.data?.currentAmount}
                    </span>
                  </h6>
                </div>
                <div className="col-md-auto col-sm-auto px-lg-3 px-0">
                  <span className="divider"></span>
                </div>
                <div className="col-md-auto col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    Available units:
                    <span className="fw-bold ms-2 ">
                      {stpShows?.data?.availableUnits}
                    </span>
                  </h6>
                </div>
                <div className="col-md-auto col-sm-auto px-lg-3 px-0">
                  <span className="divider"></span>
                </div>
                <div className="col-md-auto col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    NAV as on {currentDate()}:
                    <span className="fw-bold ms-2 text_success">
                      ₹{stpShows?.data?.product_nav}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="card redeam_bottom_card">
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="amount" className="form-label">
                        Amount <span className="red-color">*</span>
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <input
                        type="text"
                        name="amount"
                        id="amount"
                        className="form-control"
                        placeholder="₹"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.amount && formik.touched.amount && (
                        <div className="text-danger">
                          {formik.errors.amount}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Target Scheme input */}
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="target_scheme" className="form-label">
                        Target Scheme Option{" "}
                        <span className="red-color">*</span>
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <SelectDropDown
                        name="target_scheme"
                        id="target_scheme"
                        value={formik.values.target_scheme}
                        changeFunction={(value) => {
                          formik?.setFieldValue("target_scheme", value);
                          formik?.setFieldValue("source_scheme", "");
                          formik?.setFieldValue("date_installment", "");
                          formik?.setFieldValue("frequency", "");
                          formik?.setFieldValue("tenure", "");
                        }}
                        options={targetScemaOption}
                      />
                      {formik.errors.target_scheme &&
                        formik.touched.target_scheme && (
                          <div className="text-danger">
                            {formik.errors.target_scheme}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Source Scheme input */}
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="source_scheme" className="form-label">
                        Target Scheme <span className="red-color">*</span>
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <SelectDropDown
                        name="source_scheme"
                        id="source_scheme"
                        value={formik?.values?.source_scheme}
                        changeFunction={(value) => {
                          formik?.setFieldValue("source_scheme", value);
                          formik?.setFieldValue("date_installment", "");
                          formik?.setFieldValue("frequency", "");
                          formik?.setFieldValue("tenure", "");
                          const payload = {
                            target_amc_product_id: value,
                            Source_amc_product_id: value,
                          };
                          dispatch(stpScheme(payload));
                        }}
                        options={stpShows?.data?.target_products
                          ?.filter(
                            (target_product) =>
                              target_product.reinvest_tag ===
                              formik.values.target_scheme // Filter options based on reinvest_tag
                          )
                          ?.map((target_product) => {
                            return [
                              {
                                value: target_product.amc_product_id,
                                label: target_product.product_long_name, // Label is the product name
                              },
                              {
                                value: target_product.amc_product_id,
                                label: `Minimum Amount For STP: ${target_product.minimum_amount}`, // Show the minimum amount message
                                isDisabled: true, // Disable this option
                              },
                            ];
                          })
                          .flat()}
                        disabled={
                          formik.values.target_scheme != "" &&
                          formik.values.target_scheme != "Select"
                            ? (option) => option.isDisabled
                            : true
                        }
                      />
                      {formik.errors.source_scheme &&
                        formik.touched.source_scheme && (
                          <div className="text-danger">
                            {formik.errors.source_scheme}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Date of Installment input */}
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="date_installment" className="form-label">
                        Day <span className="red-color">*</span>
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <SelectDropDown
                        name="date_installment"
                        id="date_installment"
                        value={formik?.values?.date_installment}
                        changeFunction={(value) => {
                          formik?.setFieldValue("date_installment", value);
                          formik?.setFieldValue("frequency", "");
                          formik?.setFieldValue("tenure", "");
                        }}
                        options={stpShows?.data?.stpDates?.map((stp_date) => ({
                          value: stp_date,
                          label: stp_date,
                        }))}
                        disabled={
                          formik?.values?.source_scheme != "" &&
                          formik.values.source_scheme != "Select"
                            ? false
                            : true
                        }
                      />

                      {formik.errors.date_installment &&
                        formik.touched.date_installment && (
                          <div className="text-danger">
                            {formik.errors.date_installment}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* Frequency input */}
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="frequency" className="form-label">
                        Frequency <span className="red-color">*</span>
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <SelectDropDown
                        name="frequency"
                        id="frequency"
                        value={formik?.values?.frequency}
                        changeFunction={(value) => {
                          formik?.setFieldValue("frequency", value);
                          formik?.setFieldValue("tenure", "");
                        }}
                        options={
                          stpSchemes
                            ? Object.entries(stpSchemes).map(([id, label]) => ({
                                value: id,
                                label:
                                  label.charAt(0).toUpperCase() +
                                  label.slice(1),
                              }))
                            : []
                        }
                        disabled={
                          formik?.values?.date_installment != "" &&
                          formik?.values?.date_installment != "Select"
                            ? false
                            : true
                        }
                      />
                      {formik.errors.frequency && formik.touched.frequency && (
                        <div className="text-danger">
                          {formik.errors.frequency}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Tenure input */}
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="tenure" className="form-label">
                        No. of Installations{" "}
                        <span className="red-color">*</span>
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <SelectDropDown
                        name="tenure"
                        id="tenure"
                        value={formik?.values?.tenure}
                        changeFunction={(value) =>
                          formik?.setFieldValue("tenure", value)
                        }
                        options={stpShows?.data?.stpTenures
                          ?.filter((stp_tenure) => {
                            switch (formik?.values?.frequency) {
                              case "Q": // Quarterly
                                return parseInt(stp_tenure) > 12; // Example logic: show dates > 12
                              case "Y": // Yearly
                                return parseInt(stp_tenure) > 60; // Example logic: show dates > 60
                              case "OM": // Other Monthly
                              case "OW": // Other Weekly
                              default: // Default case
                                return true; // Show all dates
                            }
                          })
                          ?.map((stp_date) => ({
                            value: stp_date,
                            label: stp_date,
                          }))}
                        disabled={
                          formik?.values?.frequency != "" &&
                          formik?.values?.frequency != "Select"
                            ? false
                            : true
                        }
                      />
                      {formik.errors.tenure && formik.touched.tenure && (
                        <div className="text-danger">
                          {formik.errors.tenure}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer form_card ">
              <div className="">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    onClick={cancelBack}
                    className="form_button "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={
                      parseFloat(
                        stpShows?.data?.currentAmount?.replace(/,/g, "")
                      ) < parseFloat(formik?.values?.amount)
                        ? true
                        : false
                    }
                    className="form_button next_btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {isPopupVisible && stpStores?.status != true && (
          <PopupModel
            id="alertModel"
            title="Lorem Ipsum"
            content={stpStores?.message}
            image={cancel}
            actionPopup={actionPopup}
            closeModal={closeModal}
            btnColor={"btn-success"}
          />
        )}
      </div>
    </div>
  );
};

export default STP;
