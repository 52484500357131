import axios from "axios";
import {
  GET_CARD,
  NACH_SEND_OTP,
  NACH_VERIFY_OTP,
  PROCEED_CARD,
  PURCHASE_CART,
} from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
import { toast } from "react-toastify";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const getCards = (payload) => {
  return async (dispatch) => {
    try {
      if (payload?.id) {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}cart/product-show`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        dispatch({ type: GET_CARD, payload: response.data }); // Ensure `response.data` has the correct format
      }
    } catch (error) {
      console.error("Error fetching FAQ's data", error.message);
    }
  };
};

export const cartProceed = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}cart/proceed-cart`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: PROCEED_CARD, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      console.error("Error fetching FAQ's data", error.message);
      return error;
    }
  };
};

export const nachSendOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}nach-verify-otp`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response?.data?.status == true) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch({ type: NACH_SEND_OTP, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      console.error("Error fetching FAQ's data", error.message);
    }
  };
};

export const nachVerifyOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}trxn-verify-otp`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: NACH_VERIFY_OTP, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      console.error("Error fetching FAQ's data", error.message);
    }
  };
};

export const purchaseCart = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}cart/buy`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: PURCHASE_CART, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      console.error("Error fetching FAQ's data", error.message);
    }
  };
};
