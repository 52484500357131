import { combineReducers } from "redux";
import usersReducer from "./usersReducers";
import messageReducers from "./messageReducers";
import holdingsReducers from"./holdingreducer";
import watchListReducers from "./watchListReducers";
import footerReducers from "./footerreducers";
import cardReducers from "./cardReducers";
import goalsReducers from "./goalsReducers";
import exploreFundsReducers from "./exploreFundsReducers";
import sipCalculatorReducers from "./calculatorReducers";
import transactionReducer from "./transectionReducer";
import notificationReducers from "./notificationReducers"
import reportReducers from "./reportReducers";
import adminListReducers from "./adminReducers";
const rootReducer = combineReducers({
  users: usersReducer,
  messages: messageReducers,
  holdings : holdingsReducers,
  watchList: watchListReducers,
  footer : footerReducers,
  myCard : cardReducers,
  exploreFunds: exploreFundsReducers,
  goals : goalsReducers,
  calculator: sipCalculatorReducers,
  transaction : transactionReducer,
  notification: notificationReducers,
  report: reportReducers,
  admin: adminListReducers,  // Add your other reducers here if needed  // Important: Keep the order of the reducers as per your state structure  // For example: adminList: adminListReducers,  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //...  //
});

export default rootReducer;
