import React, { useRef } from 'react';
import '../styles/auth.css'; // Import styles
import logo from '../images/icons/header-logo.svg';
import loginSideImage from '../images/login_page/login_image.svg';
import calendarImage from '../images/calendar_month.svg';
import eyeImage from '../images/login_page/eye-image.svg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';


// Functional component for the Home page
const LoginOTP = () => {
    const otpInputs = useRef([]); // Ref to store all OTP input elements
    
    // Handle input change and move to the next field
    const handleInputChange = (index, event) => {
        const value = event.target.value;

        // Allow only numeric input
        if (/^[0-9]*$/.test(value)) {
            event.target.value = value; // Set the valid value

            if (value && index < otpInputs.current.length - 1) {
                otpInputs.current[index + 1].focus(); // Focus the next input
            }
        }
    };

    // Handle backspace to focus the previous field
    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && !event.target.value && index > 0) {
            otpInputs.current[index - 1].focus(); // Focus the previous input
        }
    };
    return (
        <section className="login" id="auth_section">
            <div className="container-fluid  login-container">
                <div className="row">
                    <div className="col-lg-6 col-md-5 px-0">
                        <div className="auth_left_content d-md-flex d-none card h-100 justify-content-start">
                            <Link className="navbar-brand" to={`/`} >
                                <img src={logo} alt="Logo" />
                                {/* Replace 'logo.png' with your logo image path */}
                                FUNDSWALA
                            </Link>
                            <div className="mb-xxl-5">
                                <h3 className="geeting_text mb-lg-4">Welcome to Fundswala</h3>
                                <h3 className="mb-4">Join Us Now ! </h3>
                            </div>
                            <img src={loginSideImage} alt="" className="login-image " />

                        </div>
                    </div>
                    <div className="col-lg-6  col-md-7 px-0">
                        <div className="right-side-container" style={{height:'100vh'}}>
                            <div className="d-md-none d-flex flex-column align-items-center">
                                <Link className="navbar-brand" to={`/`} style={{ color: 'var(--primary-color)' }}>
                                    <img src={logo} alt="Logo" />
                                    {/* Replace 'logo.png' with your logo image path */}
                                    FUNDSWALA
                                </Link>
                                <div className="my-3 text-center">
                                    <h3 className="geeting_text " style={{ color: 'var(--primary-color)' }}>Welcome to Fundswala</h3>
                                    <h3 className="geeting_text mb-0" style={{ color: 'var(--primary-color)' }}>Join Us Now ! </h3>
                                </div>
                                <a href="/" className="back-link  d-block d-md-none mb-3 mt-3"><FontAwesomeIcon icon={faAngleLeft} className='color me-2' style={{ fontSize: '16px' }}/>Back</a>
                                <h5 className='form-title d-md-none d-block  my-0' style={{}} >Verify OTP</h5>
                            </div>



                            {/* <!-- Tab Content --> */}
                            <div className="tab-content mt-md-3 mt-4">
                                <div className="tab-pane fade show active" id="signIn" role="tabpanel" aria-labelledby="signin-tab">
                                <a href="/" className="back-link  d-md-block d-none"><FontAwesomeIcon icon={faAngleLeft} className='color me-2' style={{ fontSize: '16px' }}/>Back</a>
                                    <h5 className='form-title d-md-block d-none'>Verify OTP
                                    </h5>
                                    <form>
                                        <div className="form-group ">
                                            <label htmlFor="mobileNumber" className="form-label">Mobile Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="mobileNumber"
                                                placeholder="Enter Your Mobile Number"
                                                
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label htmlFor="otp" className="form-label ps-0">
                                                Enter OTP sent to this Mobile Number
                                            </label>
                                            <div className="otp-input-group">
                                                {[...Array(6)].map((_, index) => (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        className="otp-input"
                                                        maxLength="1"
                                                        ref={(el) => (otpInputs.current[index] = el)} // Store ref
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        onKeyDown={(event) => handleKeyDown(index, event)}
                                                    />
                                                ))}
                                            </div>
                                        </div>

                                        
                                            <span className="otp-label">Didn't receive OTP? <a href="/" className="resend-link">Resend</a></span>
                                        

                                        <div className="mt-md-5 mt-sm-4 mt-3 text-end">
                                            <button type="submit" className="form_button next_btn">
                                                Verify OTP
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
};

export default LoginOTP;
