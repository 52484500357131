import React, { useEffect, useState } from "react";
import "../styles/mysip.css";
import SipCart from "../components/SipCarts";
import growthTree from "../images/icons/growth-tree.svg";
import featureimage from "../images/orders/logo.svg";
import bankimage from "../images/bankimage.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  nachSendOtp,
  nachVerifyOtp,
  purchaseCart,
} from "../actions/cardAction";
import { getLocalStorageUserData } from "../utils/userData";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
import PopupModel from "../components/PopupModel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BuySipLumpsumModel = ({ isModalOpen, handleModalClose, amt }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToCardData } = useSelector((state) => state.myCard);
  const userData = getLocalStorageUserData();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [paymentMode, setPaymentMode] = useState("NACH");
  const [bankDetail, setBankDetail] = useState(addToCardData?.data?.enach[0]);
  const [openWitch, setOpenWhich] = useState();
  const [otpMessage, setOtpMessage] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const [isDisabled, setIsDisabled] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [verifyOtpError, setVerifyOTPError] = useState("");
  const [viewBtn, setViewBtn] = useState(false);
  const [verifyBtnDisable, setVerifyBtnDisable] = useState(false);

  useEffect(() => {
    setBankDetail(addToCardData?.data?.enach[0]);
  }, [addToCardData]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/[^0-9]/.test(value) && value !== "") return;

    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
    if (!value && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // If the current input is empty and the user presses backspace, move focus to the previous input
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };
  const handleModalCloseWithReset = (val) => {
    handleModalClose(); // Call the original modal close function
    setIsOtpVerified(false);
    setIsDisabled(false);
    setVerifyOTPError("");
    setOtp(["", "", "", "", "", ""]);
    setOtpMessage("");
    setPaymentMode("NACH");
    setProceed("false");
    setBankDetail("");
    setOpenWhich("");
    setViewBtn(false);
    if (val != 1) {
      navigate("/my-profile");
    }
  };

  const sendOtp = () => {
    setIsDisabled(true);
    const paload = {
      id: userData?.id,
    };
    dispatch(nachSendOtp(paload)).then((response) => {
      if (response?.status == true) {
        setViewBtn(true);
      }
    });
    setTimeout(() => {
      setIsDisabled(false);
    }, 120000); // 120000ms = 2 minutes
  };

  const closeModal = () => {
    setIsPopupVisible(false);
  };
  const verifyOtp = () => {
    setVerifyBtnDisable(true);
    if (otp.every((digit) => digit === "")) {
      setVerifyOTPError("OTP is required");
    } else if (otp.some((digit) => digit === "")) {
      setVerifyOTPError("OTP length must be 6 digits");
    } else {
      setVerifyOTPError("");
      const payload = {
        id: userData?.id,
        otp: otp.join(""),
      };
      dispatch(nachVerifyOtp(payload)).then((res) => {
        setVerifyBtnDisable(false);
        if (res?.status == false) {
          setOtpMessage(res?.message);
        } else {
          setIsOtpVerified(true);
          setViewBtn(false);
          setOtpMessage(res?.message);
        }
      });
    }
  };
  const callPurchaseApi = () => {
    if (amt <= 0) {
      toast.error("Amount must be geter then 0");
    } else {
      setIsPopupVisible(true);
      if (bankDetail != "" && isOtpVerified) {
        const findBankId = addToCardData?.data?.bank_accounts?.find(
          (res) => res?.bank_code == bankDetail?.bank_code
        );

        const payload = {
          id: userData?.id,
          bank_account_id: findBankId?.id,
          no_netbanking: null,
          no_upi: null,
          mandate_id: bankDetail?.id,
          payment_mode: paymentMode,
          proceed: null,
          amt: amt,
        };
        dispatch(purchaseCart(payload))
          .then((res) => {
            setOpenWhich(res);
          })
          .catch((error) => {
            // handleModalCloseWithReset();
          });
      }
    }
  };

  useEffect(() => {
    if (openWitch) {
      if (openWitch?.data?.link && openWitch?.status == true) {
        window.location.href = openWitch?.data?.link;
      } else if (openWitch?.status == false) {
        toast.error(openWitch?.message, {
          autoClose: 2000, // Duration in milliseconds (5 seconds in this case)
        });
        // setTimeout(() => {
        //   window.location.href = "/sip-lumpsum-cart";
        // }, 1000);
      } else if (openWitch?.status == true) {
        toast.success(openWitch?.message);
        setTimeout(() => {
          navigate("/orders", { state: { orderId: openWitch?.data?.order } });
        }, 1000);
      }
    }
  }, [openWitch]);

  if (!isModalOpen) return null;

  return (
    <div className="container-fluid ">
      <div className=" mb-3">
        <div className="">
          <div className="modal_card">
            {/* Trigger Button */}
            {/* <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#paymentModal"
            >
              Select Payment modal
            </button> */}

            {/* Modal */}

            <div
              className={`modal fade modal_card_inner my-cart-custom-no-border ${
                isModalOpen ? "show" : ""
              }`}
              id="paymentModal"
              tabIndex="-1"
              aria-labelledby="paymentModalLabel"
              aria-hidden={!isModalOpen}
            >
              <div className="modal-dialog modal-dialog-centered p-3 modal-dialog-scrollable my-cart-scrollbar">
                <div className="modal-content cart-custom-popup">
                  {!isOtpVerified ? (
                    <>
                      {addToCardData?.data?.enach?.length > 0 ? (
                        <>
                          {" "}
                          <div className="modal-header goals-custom-header">
                            <h5
                              className="modal-title"
                              id="netbankingModalLabel"
                            >
                              Select a Payment Method
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                            ></button>
                          </div>
                          {/* Modal Body */}
                          <div className="modal-body p-0 my-cart-custom-body">
                            {/* eNACH Section */}
                            <div className="modal_body my-cart-custom-body">
                              <div className=" eNach_middle_part gap-3">
                                <div className="form-check ps-0 d-flex align-items-center">
                                  <input
                                    className="form-input sip_input"
                                    type="radio"
                                    name="payment_mode"
                                    id="eNACH"
                                    value={paymentMode}
                                    checked={paymentMode == "NACH"}
                                    onChange={() => {
                                      setPaymentMode("NACH");
                                    }}
                                  />
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="eNACH"
                                  >
                                    eNACH
                                  </label>
                                </div>
                              </div>
                              <p className="form-label text-danger mt-2">
                                Cart has SIPs, therefore, payment can be done
                                through eNACH only.
                              </p>

                              <>
                                <div className="mt-3 table-responsive">
                                  <table
                                    className="table sip_modaltable mb-0"
                                    style={{
                                      borderCollapse: "separate",
                                      borderSpacing: "0px 15px",
                                    }}
                                  >
                                    <tr>
                                      <th></th>
                                      <th className="fw-semibold">Bank</th>
                                      <th className="fw-semibold">
                                        Account Number
                                      </th>
                                      <th>Limit (Rs.)</th>
                                    </tr>
                                    <>
                                      {addToCardData?.data?.enach?.length >
                                      0 ? (
                                        addToCardData?.data?.enach?.map(
                                          (res) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <input
                                                    className="form-input sip_input"
                                                    type="radio"
                                                    name="bankId"
                                                    id="bankId"
                                                    onClick={() => {
                                                      setBankDetail(res);
                                                    }}
                                                    checked={
                                                      parseInt(res?.id) ==
                                                      parseInt(bankDetail?.id)
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <img
                                                    src={res?.icon}
                                                    className="bank_image"
                                                  />
                                                  {res?.bank_name}
                                                </td>
                                                <td>{res?.account_no}</td>
                                                <td>{res?.amount}</td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <p>No record found</p>
                                      )}
                                    </>
                                  </table>
                                  {bankDetail == "" && proceed && (
                                    <p style={{ color: "red" }}>
                                      Please, select bank
                                    </p>
                                  )}
                                </div>
                                <div className="mt-xl-4 modal_body text-center my-cart-custom-no-border">
                                  <label className="form_label">
                                    {/* Verify through OTP * */}
                                    <a
                                      onClick={() => {
                                        if (!isDisabled) {
                                          sendOtp();
                                        }
                                      }}
                                      className="ms-md-4 ms-3 "
                                    >
                                      Send OTP to verify
                                    </a>
                                  </label>
                                  <div className="d-flex justify-content-center mt-md-4 mt-2">
                                    {otp?.map((value, index) => (
                                      <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        className="otp-input text-center mx-1"
                                        value={value}
                                        onChange={(e) =>
                                          handleOtpChange(e, index)
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, index)
                                        }
                                        id={`otp-input-${index}`}
                                        autoFocus={index === 0}
                                      />
                                    ))}
                                  </div>
                                  {verifyOtpError && (
                                    <p style={{ color: "red" }}>
                                      {verifyOtpError}
                                    </p>
                                  )}
                                  {otpMessage && (
                                    <p style={{ color: "red" }}>{otpMessage}</p>
                                  )}
                                  <div className="d-flex justify-content-center mt-3">
                                    {viewBtn && (
                                      <button
                                        className="verify_button"
                                        disabled={verifyBtnDisable}
                                        onClick={() => {
                                          verifyOtp();
                                        }}
                                      >
                                        Verify OTP
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <>
                            <div
                              className={`modal-body text-center p-lg-5 p-4 `}
                            >
                              <img
                                src={cancel}
                                alt="Modal Icon"
                                className="mb-3"
                              />
                              <p style={{ color: "red", margin: "20px" }}>
                                You have not registered for eNACH, please
                                register.
                              </p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className={`btn btn-success`}
                                onClick={handleModalCloseWithReset}
                              >
                                OK
                              </button>
                              <button
                                type="button"
                                className={`btn btn-primary`}
                                onClick={() => {
                                  handleModalCloseWithReset(1);
                                }}
                              >
                                Later
                              </button>
                            </div>
                          </>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {addToCardData?.data?.enach?.length > 0 ? (
                        <div className="modal_body text-center">
                          <div className="mt-3 table-responsive">
                            <table
                              className="table sip_modaltable mb-0"
                              style={{
                                borderCollapse: "separate",
                                borderSpacing: "0px 15px",
                              }}
                            >
                              <tr>
                                <th></th>
                                <th className="fw-semibold">Bank</th>
                                <th className="fw-semibold">Account Number</th>
                                <th>Limit (Rs.)</th>
                              </tr>
                              <>
                                {addToCardData?.data?.enach?.length > 0 ? (
                                  addToCardData?.data?.enach?.map((res) => {
                                    return (
                                      <tr>
                                        <td>
                                          <input
                                            className="form-input sip_input"
                                            type="radio"
                                            name="bankId"
                                            id="bankId"
                                            onClick={() => {
                                              setBankDetail(res);
                                            }}
                                            checked={res?.id == bankDetail?.id}
                                          />
                                        </td>
                                        <td>
                                          <img
                                            src={res?.icon}
                                            className="bank_image"
                                          />
                                          {res?.bank_name}
                                        </td>
                                        <td>{res?.account_no}</td>
                                        <td>{res?.amount}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <p>No record found</p>
                                )}
                              </>
                            </table>
                            {bankDetail == "" && proceed && (
                              <p style={{ color: "red" }}>
                                Please, select bank
                              </p>
                            )}
                          </div>
                          {/* OTP Verified Section */}
                          <div className="d-flex justify-content-center p-lg-2">
                            <button className="verified_button">
                              {otpMessage}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <>
                            <div
                              className={`modal-body text-center p-lg-5 p-4 `}
                            >
                              <img
                                src={cancel}
                                alt="Modal Icon"
                                className="mb-3"
                              />
                              <p style={{ color: "red", margin: "20px" }}>
                                You have not registered for eNACH, please
                                register.
                              </p>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className={`btn btn-success`}
                                onClick={handleModalCloseWithReset}
                              >
                                OK
                              </button>
                              <button
                                type="button"
                                className={`btn btn-primary`}
                                onClick={() => {
                                  handleModalCloseWithReset(1);
                                }}
                              >
                                Later
                              </button>
                            </div>
                          </>
                        </>
                      )}
                    </>
                  )}
                  {addToCardData?.data?.enach?.length > 0 && (
                    <div className="modal-footer form_card ">
                      <div className="d-flex justify-content-between gap-3">
                        <button
                          type="submit"
                          className="form_button "
                          onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          disabled={
                            paymentMode == "NACH" && isOtpVerified
                              ? false
                              : bankDetail != ""
                              ? false
                              : true
                          }
                          onClick={() => {
                            setProceed(true);
                            callPurchaseApi();
                          }}
                          className="form_button next_btn"
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySipLumpsumModel;
