import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import AdminTransactionTable from "../../components/Admin/AdminTransactionTable";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../loader/Loader-ui";
import InvestButton from "../../components/InvestButton";
import exportIcon from "../../images/icons/export.svg";
import { IoSearch } from "react-icons/io5";
import { transactionList } from "../../actions/adminAction";
import { useNavigate } from "react-router-dom";

const AdminTransaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("all");
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [rmType, setRMType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransactionList = async () => {
    try {
      setIsLoading(true);
      await dispatch(transactionList(
        {
          page_num: currentPage,
          per_page: "10",
          search: search,   // Include search filter
          status: status,   // Include status filter
          rmId: rmType
        }
      ));
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionList();
  }, [currentPage, search, status, rmType]);

  const { adminTransactionList = {} } = useSelector((state) => state?.admin);
  const investmentSchemeData = adminTransactionList?.data?.transactionsList  || [];
  const totalPages = adminTransactionList?.data?.pagination?.total_pages || 1;
  const rmUserList = adminTransactionList?.data?.rmsList || [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleRMIdChange = (e) => {
    setRMType(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  // Function to clear all filters
  const clearFilters = () => {
    setRMType("");
    setSearch("");
    setStatus("");
    setCurrentPage(1); // Reset to first page when search changes
  };

  return (
    <div className="container-fluid page_background">
      {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <div className="d-flex justify-content-between mb-3 align-items-center">
            <h5 className="mb-4 main-heading-title">Transactions</h5>
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <InvestButton
                  label={
                    <>
                      <img src={exportIcon} className="mr-1" />
                      Export
                    </>
                  }
                  color="blue"
                  className=""
                  onClick={() => {
                    navigate("/admin/transactionExport", { state: { from: "transactions" } });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="search-container">
            <div>
              <div className="row align-items-end">
                {/* Search Input */}
                <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
                  <div className="position-relative">
                    <IoSearch className="search-icon-custom" />
                    <input
                      type="text"
                      className="search-input-custom"
                      placeholder="Search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                {/* Relationship Manager Dropdown */}
                <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
                  <div className="dropdown-label-custom">
                    Relationship Manager:
                  </div>
                  <select
                    className="form-select custom-select2"
                    value={rmType} // Set selected value
                    onChange={handleRMIdChange} // Handle selection
                    displayEmpty
                  >
                    <option value={""} disabled>
                      Select
                    </option>
                    {rmUserList?.map((rm, index) => (
                      <option key={index} value={rm.id}>
                        {rm.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Transaction status Dropdown */}
                <div className="col-md-3 col-sm-6 col-xs-12 mb-3 status-col">
                  <div className="dropdown-label-custom">Status:</div>
                  <select
                    className="form-select  custom-select"
                    defaultValue=""
                    required
                    displayEmpty
                    value={status} // Bind state
                    onChange={handleStatusChange} // Handle selection
                  >
                    <option value={""} disabled>
                      Select
                    </option>
                    <option value={"all"}>All</option>
                    <option value={"Pending"}>Pending</option>
                    <option value={"Success"}>
                    Success
                    </option>
                    <option value={"Rejected"}>Rejected</option>
                    <option value={"Authorized"}>Authorized</option>
                  </select>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 mb-3 status-col">
                  <InvestButton
                    label={<>Clear</>}
                    color="blue"
                    className="white-space-nowrap investor-btn-p-10"
                    onClick={clearFilters}
                  />
                </div>
                <div className="col"> </div>
              </div>
            </div>
          </div>
          <AdminTransactionTable
            data={investmentSchemeData}
          />
          <div className="pt-5">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTransaction;
