import { useState, useEffect, useRef } from "react";
import "../../styles/transaction.css";
import holding from "../../images/orders/logo.svg";
import filter from "../../images/transaction/filter.svg";
import { IoMdClose } from "react-icons/io";

const AdminTransactionTable = ({
  data,
  selectedType,
  selectedStatus,
  onTypeChange,
  onStatusChange,
}) => {
  const transactions = [
    {
      id: 5013387678,
      date: "2025-01-01",
      name: "Govindrajan Tukaram Gaikwad",
      scheme: "439/ICICI Prudential Business Cycle Fund Growth",
      target_scheme: "N/A",
      trxn_status: "Success",
    },
    {
      id: 5013387678,
      date: "2025-01-02",
      name: "Govindrajan Tukaram Gaikwad",
      scheme: "439/ICICI Prudential Business Cycle Fund Growth",
      target_scheme: "N/A",
      trxn_status: "Pending",
    },
    {
      id: 35013387678,
      date: "2025-01-03",
      name: "Govindrajan Tukaram Gaikwad",
      scheme: "439/ICICI Prudential Business Cycle Fund Growth",
      target_scheme: "N/A",
      trxn_status: "Rejected",
    },
  ];

  return (
    <div className="table-container table-responsive">
      <table className="table_transaction">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th> Name</th>
            <th>Scheme</th>
            <th>Target Scheme</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((transaction, index) => (
              <tr
                key={index}
                className={`${transaction.trxn_status.toLowerCase()} transaction_row`}
              >
                <td> {transaction?.folio_no || "NA"}</td>
                <td>
                  {transaction?.entry_date
                    ? new Date(transaction?.entry_date).toLocaleDateString(
                        "en-GB"
                      )
                    : "NA"}
                </td>
                <td>{transaction?.investor_name || "NA"}</td>
                <td className="white-space-normal">
                  {transaction?.scheme_name || "NA"}
                </td>
                <td>{transaction.target_product || "NA"}</td>
                <td>
                  <span
                    className={`status ${transaction?.trxn_status.toLowerCase()}`}
                  >
                    {transaction?.trxn_status || "NA"}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ height: "200px" }}>
              <td colSpan="6" className="no-data-message">
                No transactions found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <style jsx>{`
        .filter-popup {
          position: absolute;
          // background: white;
          padding: 8px;
          // border: 1px solid #dee2e6;
          border-radius: 4px;
          z-index: 1000;
          min-width: 200px;
        }

        .status-filter-popup {
          position: absolute;
          padding: 8px;
          border-radius: 4px;
          z-index: 1000;
          min-width: 200px;
          right: 36px;
        }
        .close-btn-mb {
          margin-bottom: 4px;
        }

        .btn-link {
          color: #6c757d;
          text-decoration: none;
        }

        .btn-link:hover {
          color: #0d6efd;
        }

        .input-group .btn {
          border-left: none;
        }
      `}</style>
    </div>
  );
};

export default AdminTransactionTable;
