import React, { useState, useEffect } from "react";
import "../styles/downloads.css"; // Import styles
import HeroSection from "../components/HeroSection";
import HeroBackgroundImage from "../images/downloads/heroimg.jpg";
import searchSrc from "../images/downloads/Search.svg";
import searchDropDown from "../images/downloads/Drop Down Active.svg";
//import image
import Image1 from "../images/Fund Icons/aditya-birla-sun-life-mutual-fund.jpg";
import Image2 from "../images/Fund Icons/axis.jpg"; // Image4 was here, so this becomes Image2
import Image3 from "../images/Fund Icons/bnp-paribas-mutual-fund.jpg"; // Image6 was here
import Image4 from "../images/Fund Icons/boi-axa-mutual-fund.jpg"; // Image7 was here
import Image5 from "../images/Fund Icons/baroda-pioneer-mutual-fund.jpg"; // Image8 was here
import Image6 from "../images/Fund Icons/canara-mutual-fund.jpg"; // Image10 was here
import Image7 from "../images/Fund Icons/dsp-mutual-fund.jpg"; // Image11 was here
import Image8 from "../images/Fund Icons/edelweiss-mutual-fund.jpg"; // Image12 was here
import Image9 from "../images/Fund Icons/franklin-templeton-mutual-fund.jpg"; // Image13 was here
import Image10 from "../images/Fund Icons/hdfc-mutual-fund.jpg"; // Image14 was here
import Image11 from "../images/Fund Icons/icici-prudential-mutual-fund.jpg"; // Image16 was here
import Image12 from "../images/Fund Icons/idfc-mutual-fund.jpg"; // Image17 was here
import Image13 from "../images/Fund Icons/idbi-mutual-fund.jpg"; // Image18 was here
import Image14 from "../images/Fund Icons/indiabulls-mutual-fund.jpg"; // Image19 was here
import Image15 from "../images/Fund Icons/invesco-mutual-fund.jpg"; // Image20 was here
import Image16 from "../images/Fund Icons/jm-financial-mutual-fund.jpg"; // Image21 was here
import Image17 from "../images/Fund Icons/kotak-mutual-fund.jpg"; // Image22 was here
import Image18 from "../images/Fund Icons/lt-mutual-fund.jpg"; // Image23 was here
import Image19 from "../images/Fund Icons/lic-mutual-fund.jpg"; // Image24 was here
import Image20 from "../images/Fund Icons/mahinra-mutual-fund.jpg"; // Image26 was here
import Image21 from "../images/Fund Icons/mirae-asset-mutual-fund.jpg"; // Image27 was here
import Image22 from "../images/Fund Icons/nippon-mutual-fund.jpg"; // Image28 was here
import Image23 from "../images/Fund Icons/pgim-mf-common-application-form.jpg"; // Image30 was here
import Image24 from "../images/Fund Icons/ppfas-mutual-fund.jpg"; // Image31 was here
import Image25 from "../images/Fund Icons/sbi-mutual-fund.jpg"; // Image32 was here
import Image27 from "../images/Fund Icons/sundaram-mutual-fund.jpg"; // Image34 was here
import Image28 from "../images/Fund Icons/tata-mutual-fund.jpg"; // Image35 was here
import Image29 from "../images/Fund Icons/uti-mutual-fund.jpg"; // Image36 was here
import Image30 from "../images/Fund Icons/hsbc-mutual-fund.jpg"; // Image

// PDF Imports
import adityaBirlaPdf from "../download/aditya_birla_sun_life_mutual_fund-debt-_-liquid-1.pdf";
import adityaBirlaEquityPdf from "../download/aditya_birla_sun_life_mutual_fund-equity-2.pdf";
import axisMutualFundDebtPdf from "../download/axis-mutual-fund-debt.pdf";
import axisMutualFundEquityHybridIndexPdf from "../download/axis-mutual-fund-equity-hybrid-index-_-fof-1.pdf";
import barodaPioneerMutualFundDebtPdf from "../download/baroda-pioneer-mutual-fund-debt.pdf";
import barodaPioneerMutualFundEquityPdf from "../download/baroda-pioneer-mutual-fund-equity.pdf";
import bnpParibasMutualFundDebtPdf from "../download/bnp-paribas-mutual-fund-debt.pdf";
import bnpPribasMutualFundEquityPdf from "../download/bnp-pribas-mutual-fund-equity.pdf";
import boiAxaMutualFundPdf from "../download/boi_axa_mutual_fund.pdf";
import canaraMutualFundPdf from "../download/canara-mutual-fund.pdf";
import dspMutualFundNewPdf from "../download/dsp-mutual-fund-new-1.pdf";
import edelweissMutualFundPdf from "../download/edelweiss-mutual-fund.pdf";
import franklinTempletonMutualFundEquityPdf from "../download/franklin-templeton-mutual-fund-equity.pdf";
import hdfcMutualFundPdf from "../download/hdfc-mutual-fund.pdf";
import hsbcMutualFundDebtPdf from "../download/hsbc-mutual-fund-debt.pdf";
import iciciMutualFundPdf from "../download/icici-mutual-fund.pdf";
import idbiMutualFundPdf from "../download/idbi-mutual-fund.pdf";
import idfcMutualFundPdf from "../download/idfc-mutual-fund.pdf";
import indiabullsMutualFundPdf from "../download/indiabulls-mutual-fund.pdf";
import invescoMutualFundEquityPdf from "../download/invesco-mutual-fund-equity.pdf";
import jmFinancialMutualFundPdf from "../download/jm-financial-mutual-fund.pdf";
import kotakMutualFundPdf from "../download/kotak-mutual-fund.pdf";
import ltMutualFundPdf from "../download/l_t-mutual-fund.pdf";
import licMutualFundDebtPdf from "../download/lic-mutual-fund-debt.pdf";
import licMutualFundEquityHybridUlisPdf from "../download/lic-mutual-fund-equity-hybrid-_-ulis.pdf";
import mahindraMutualFundPdf from "../download/mahinra-mutual-fund.pdf";
import miraeAssetMutualFundPdf from "../download/mirae-asset-mutual-fund.pdf";
import nipponMutualFundDebtLiquidPdf from "../download/nippon-mutual-fund-debt-_-liquid.pdf";
import nipponMutualFundEquityPdf from "../download/nippon-mutual-fund-equity.pdf";
import pgimMfCommonApplicationFormPdf from "../download/pgim_mf_common_application_form.pdf";
import ppfasMutualFundPdf from "../download/ppfas_mutual_fund.pdf";
import sbiMutualFundDebtPdf from "../download/sbi-mutual-fund-debt.pdf";
import sbiMutualFundEquityPdf from "../download/sbi-mutual-fund-equity.pdf";
import sundaramMutualPdf from "../download/sundaram-mutual.pdf";
import tataMutualFundPdf from "../download/tata_mutual_fund.pdf";
import utiMutualFundPdf from "../download/uti_mutual_fund.pdf";
import { Helmet } from 'react-helmet-async'; // Import Helmet

const Downloads = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleCount, setVisibleCount] = useState(2); // Start with 2 rows visible
  const rowSize = 2; // Number of rows to add on each scroll

  const [downloadFundNameList] = useState([
    {
      label: "Aditya Birla Sun Life Mutual Fund – Debt & Liquid",
      value: adityaBirlaPdf,
      image: Image1,
    },
    {
      label: "Aditya Birla Sun Life Mutual Fund – Equity",
      value: adityaBirlaEquityPdf,
      image: Image1,
    },
    {
      label: "Axis Mutual Fund – Debt",
      value: axisMutualFundDebtPdf,
      image: Image2,
    },
    {
      label: "Axis Mutual Fund – Equity, Hybrid, Index & FOF",
      value: axisMutualFundEquityHybridIndexPdf,
      image: Image2,
    },
    {
      label: "BNP Paribas Mutual Fund – Debt",
      value: bnpParibasMutualFundDebtPdf,
      image: Image3,
    },
    {
      label: "BNP Pribas Mutual Fund – Equity",
      value: bnpPribasMutualFundEquityPdf,
      image: Image3,
    },
    {
      label: "BOI AXA Mutual Fund – CAF",
      value: boiAxaMutualFundPdf,
      image: Image4,
    },
    {
      label: "Baroda Pioneer Mutual Fund – Debt",
      value: barodaPioneerMutualFundDebtPdf,
      image: Image5,
    },
    {
      label: "Baroda Pioneer Mutual Fund – Equity",
      value: barodaPioneerMutualFundEquityPdf,
      image: Image5,
    },
    {
      label: "Canara Mutual Fund – CAF",
      value: canaraMutualFundPdf,
      image: Image6,
    },
    {
      label: "DSP Mutual Fund – CAF",
      value: dspMutualFundNewPdf,
      image: Image7,
    },
    {
      label: "Edelweiss Mutual Fund – CAF",
      value: edelweissMutualFundPdf,
      image: Image8,
    },
    {
      label: "Franklin Templeton Mutual Fund – CAF",
      value: franklinTempletonMutualFundEquityPdf,
      image: Image9,
    },
    {
      label: "HDFC Mutual Fund – CAF",
      value: hdfcMutualFundPdf,
      image: Image10,
    },
    {
      label: "HSBC Mutual Fund – Equity",
      value: hsbcMutualFundDebtPdf,
      image: Image30,
    },
    {
      label: "HSBC Mutual Fund – Debt",
      value: adityaBirlaEquityPdf,
      image: Image30,
    },
    {
      label: "ICICI Prudential Mutual Fund – CAF",
      value: iciciMutualFundPdf,
      image: Image11,
    },
    {
      label: "IDFC Mutual Fund – CAF",
      value: idfcMutualFundPdf,
      image: Image12,
    },
    {
      label: "IDBI Mutual Fund – CAF",
      value: idbiMutualFundPdf,
      image: Image13,
    },
    {
      label: "Indiabulls Mutual Fund – CAF",
      value: indiabullsMutualFundPdf,
      image: Image14,
    },
    {
      label: "Invesco Mutual Fund – CAF",
      value: invescoMutualFundEquityPdf,
      image: Image15,
    },
    {
      label: "JM Financial Mutual Fund – CAF",
      value: jmFinancialMutualFundPdf,
      image: Image16,
    },
    {
      label: "Kotak Mutual Fund – CAF",
      value: kotakMutualFundPdf,
      image: Image17,
    },
    {
      label: "L&T Mutual Fund – CAF",
      value: ltMutualFundPdf,
      image: Image18,
    },
    {
      label: "LIC Mutual Fund – Debt",
      value: licMutualFundDebtPdf,
      image: Image19,
    },
    {
      label: "LIC Mutual fund – Equity, Hybrid & ULIS",
      value: licMutualFundEquityHybridUlisPdf,
      image: Image19,
    },
    {
      label: "Mahindra Mutual Fund – CAF",
      value: mahindraMutualFundPdf,
      image: Image20,
    },
    {
      label: "Mirae asset Mutual Fund – CAF",
      value: miraeAssetMutualFundPdf,
      image: Image21,
    },
    {
      label: "Nippon Mutual Fund – Debt & Liquid",
      value: nipponMutualFundDebtLiquidPdf,
      image: Image22,
    },
    {
      label: "Nippon Mutual Fund – Equity",
      value: nipponMutualFundEquityPdf,
      image: Image22,
    },
    {
      label: "PGIM MF Common Application form",
      value: pgimMfCommonApplicationFormPdf,
      image: Image23,
    },
    {
      label: "PPFAS Mutual Fund – CAF",
      value: ppfasMutualFundPdf,
      image: Image24,
    },
    {
      label: "SBI Mutual Fund – Debt",
      value: sbiMutualFundDebtPdf,
      image: Image25,
    },
    {
      label: "SBI Mutual Fund – Equity",
      value: sbiMutualFundEquityPdf,
      image: Image25,
    },
    {
      label: "Sundaram Mutual Fund – CAF",
      value: sundaramMutualPdf,
      image: Image27,
    },
    {
      label: "Tata Mutual Fund",
      value: tataMutualFundPdf,
      image: Image28,
    },
    {
      label: "UTI Mutual Fund",
      value: utiMutualFundPdf,
      image: Image29,
    },
  ]);

  // Filter the list based on search query
  const filteredList = downloadFundNameList.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const visibleItems = filteredList.slice(0, visibleCount); // Show only visible items

  // Scroll event handler
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 10
    ) {
      setVisibleCount((prevCount) => prevCount + rowSize); // Load more rows
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    {/* Helmet for Dynamic SEO Metadata */}
    <Helmet>
  <title>Download | Fundswala</title>
  <meta 
    name="description" 
    content="Download mutual fund PDFs, investment reports, fact sheets, and other financial documents from Fundswala. Get all the information you need to make informed investment decisions." 
  />
</Helmet>
      <HeroSection
        title=""
        subtitle="Mutual Fund Applications"
        bgImage={HeroBackgroundImage}
      />

      <section className="downloads_Plan_section" id="downloads_Plan_section">
        <div className="container">
          {/* Search Section */}
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4  g-4">
            <div className="col mt-md-3 mt-0 ">
              <label htmlFor="amcSelect" className="downloads-form-label">
                AMC
              </label>
              <form
                className="search-box mb-4 mt-3 px-0"
                style={{ backgroundColor: "transparent", padding: "0px 13px" }}
              >
                <div className="input-group">
                  <button
                    type="submit"
                    style={{ background: "none", border: "none" }}
                    className="me-2 form_search"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img src={searchSrc} alt="Search" />
                  </button>
                  <input
                    type="text"
                    className="form-select custom-select border-0 bg-transparent"
                    placeholder="Search Mutual Funds"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {/* <img
                    src={searchDropDown}
                    alt="Dropdown Icon"
                    className="custom-select-icon"
                  /> */}
                </div>
              </form>
            </div>
          </div>

          {/* Download List Section */}
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
            {filteredList.length > 0 ? (
              filteredList.map((item, index) => (
                <div className="col" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <img
                        src={item.image}
                        alt={item.label}
                        style={{ maxWidth: "100px", marginBottom: "15px" }}
                      />
                      <h5 className="card_title">
                        <a
                          href={item.value}
                          download
                          style={{
                            color: "inherit", // Inherit the color from the parent element
                            textDecoration: "none", // Remove underline
                          }}
                        >
                          {item.label}
                        </a>
                      </h5>
                      {/* <a
                        href={item.value}
                        download
                        className="btn btn-primary mt-3"
                      >
                        Download
                      </a> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col">
                <p>No results found for your search.</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Downloads;
