import axios from "axios";

// Create an Axios instance without a baseURL
const axiosInstance = axios.create();

// Add an interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Check if the response contains logout indicators
    const { status, isuserlogout, message } = response?.data || {};
    const sessionExpiredMessages = [
      "Session expired. Please login again.",
      "Authentication Error!",
      "Authentication Errroor!"
    ];

    if (
      status === false &&
      (isuserlogout === true || sessionExpiredMessages.includes(message))
    ) {
      // Clear local storage
      localStorage.removeItem("userData");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");

      // Redirect to login
      setTimeout(() => {
        window.location.href = "/login";

        // Clear browser history to prevent back navigation
        setTimeout(() => {
          window.history.pushState(null, "", "/login");
          window.history.pushState(null, "", "/login");
        }, 500);
      }, 1000);
      // Optionally reject the promise
      return Promise.reject({
        response,
        customMessage: "Session expired or authentication error.",
      });
    }

    // If no error, return the response
    return response;
  },
  (error) => {
    // Handle other errors here
    console.error("Axios error:", error);

    // Reject the error to propagate it
    return Promise.reject(error);
  }
);

export default axiosInstance;
