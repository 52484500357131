import React, { useEffect, useRef, useState } from "react";
import "../styles/FundDetailInvestmentBox.css"; // Import styles
import Gross_down from "../images/gross_down_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardHeader } from "../actions/holdingAction";
import upArrow from "../images/Up.svg";
import { getLocalStorageUserData } from "../utils/userData";
// Functional component for the funddetails page
const PortFolioInvestmentHeader = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const valuationData = useSelector((state) => state.holdings);
  const [loading, setLoading] = useState(false);
  const userData = getLocalStorageUserData();
  useEffect(() => {
    const fetchData = async () => {
      const payload = { id: userData?.id };
      try {
        setLoading(true);
        await dispatch(dashboardHeader(payload));
      } catch (error) {
        console.error("API call failed: ", error);
      } finally {
        setLoading(false);
      }
    };
    // if (isMounted.current) {
    fetchData();
    // } else {
    //   isMounted.current = true;
    // }
  }, [dispatch, userData?.id]);
  return (
    <div className="start_investment_box card-header">
      <h5 className="page-title">My Portfolio</h5>
      <div className="investment-info">
        <div className="">
          <h6 className="investment-value-header">Invested Value</h6>
          <span className="value-text">
            ₹
            {valuationData?.valuationData?.data?.net_invested_amount ? valuationData?.valuationData?.data?.net_invested_amount : 0}
            {/* {valuationData?.valuationData?.data?.net_invested_amount
              ? Number(
                  valuationData?.valuationData?.data?.net_invested_amount
                )
              : 0} */}
          </span>
        </div>
        <div className="">
          <h6 className="investment-value-header">Market Value</h6>
          <span className="value-text">
            ₹{valuationData?.valuationData?.data?.portfolio_value}
          </span>
        </div>

        <div className="">
          <h6 className="investment-value-header">Gain/Loss</h6>
          <div className="d-flex gap-3 align-items-center">
            <span className="value-text">
            {console.log(valuationData?.valuationData?.data?.totalGainLossAmount,"valuationData?.valuationData?.data?.totalGainLossAmount1")}
              <span
                style={{
                  color: String(
                    valuationData?.valuationData?.data?.totalGainLossAmount ??
                      "0"
                  ).startsWith("-")
                    ? "red"
                    : "#80ff9c",
                }}
              >
                ₹
                {valuationData?.valuationData?.data?.totalGainLossAmount ??
                  "0.0"}
              </span>
            </span>
            <img
              src={
                String(
                  valuationData?.valuationData?.data?.total_percentage ?? "0"
                ).startsWith("-")
                  ? Gross_down
                  : upArrow
              }
              alt=""
            />
            <span
              className="gross_percent"
              style={{
                color: String(
                  valuationData?.valuationData?.data?.total_percentage ?? "0"
                ).startsWith("-")
                  ? "red"
                  : "#80ff9c",
              }}
            >
              {valuationData?.valuationData?.data?.total_percentage ?? "0"}
            </span>
          </div>
        </div>

        <div className="">
          <h6 className="investment-value-header">Monthly SIP Amt.</h6>
          <span className="value-text">
            ₹{valuationData?.valuationData?.data?.monthlySIPAmount}.00
          </span>
        </div>
        <div className="">
          <h6 className="investment-value-header">Amt. Debited this Month</h6>
          <span className="value-text">
            ₹{valuationData?.valuationData?.data?.amountDebitedThisMonth}.00
          </span>
        </div>
      </div>
    </div>
  );
};

export default PortFolioInvestmentHeader;
