import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import bell from "../images/sidebar_icon/bell.svg";
import cart from "../images/sidebar_icon/cart.svg";
import bookmark from "../images/sidebar_icon/watchlist.svg";
import avatar from "../images/sidebar_icon/avatar.svg";
import { toast } from "react-toastify";
import { getLocalStorageUserData } from "../utils/userData";
import { useDispatch, useSelector } from "react-redux";
import { getCards } from "../actions/cardAction";
import NotificationPanel from "./Notification.js";
import {
  getNotificationList,
  getHeaderCount,
} from "../actions/notificationAction.js";
import { getWatchList } from "../actions/watchListActions.js";

const AfterLoginHeader = (props) => {
  const controller = new AbortController();
  const isMounted = useRef(false);
  const userData = getLocalStorageUserData();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const cardData = useSelector((state) => state.myCard);
  const watchList = useSelector(
    (state) => state.watchList?.getWatchList?.data?.watchlist
  );
  const notificationList = useSelector(
    (state) => state.notification?.notificationList?.data?.notifications || []
  );

  const [cartCount, setCartCount] = useState(0);
  const [watchListCount, setWatchListCount] = useState(0);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const notificationRef = useRef(null);

  const headerCountData = useSelector(
    (state) => state.notification?.headerCount?.data || {}
  );
  
  const headerDataCount = useCallback(async () => {
    try {
      setIsLoading(true); // Show loader
      await dispatch(getHeaderCount(userData?.id));
    } catch (error) {
      console.error("One or more API calls failed: ", error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  }, [dispatch, userData?.id]);

  useEffect(() => {
    if(userData?.id){
    headerDataCount();
    }
  }, [headerDataCount]);

  // const fetchData = async () => {
  //   try {
  //     if (userData?.id) {
  //       const payload = { id: userData?.id };
  //       dispatch(getCards(payload)); // Fetch card data
  //       dispatch(getNotificationList(payload)); // Fetch notifications
  //       dispatch(getWatchList(payload)); // Fetch watchlist
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const payload = { id: userData?.id };
  //     try {
  //       await Promise.all([dispatch(getCards(payload))]);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   // if (isMounted.current) {
  //   fetchData();
  //   // } else {
  //   //   isMounted.current = true;
  //   // }
  // }, [dispatch, userData?.id]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const payload = { id: userData?.id };
  //     try {
  //       await Promise.all([dispatch(getNotificationList(payload))]);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   // if (isMounted.current) {
  //   fetchData();
  //   // } else {
  //   //   isMounted.current = true;
  //   // }
  //   return () => {
  //     controller.abort(); // Abort the ongoing request on cleanup
  //   };
  // }, [dispatch, userData?.id]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const payload = { id: userData?.id };
  //     try {
  //       await Promise.all([dispatch(getWatchList(payload))]);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   // if (isMounted.current) {
  //   fetchData();
  //   // } else {
  //   //   isMounted.current = true;
  //   // }
  // }, [dispatch, userData?.id]);

  // useEffect(() => {
  //   fetchData();
  // }, [dispatch, userData?.id]);

  // useEffect(() => {
  //   setCartCount(cardData?.addToCardData?.data?.products_in_cart?.length || 0);
  // }, [cardData?.addToCardData?.data?.products_in_cart]);

  // useEffect(() => {
  //   setWatchListCount(watchList?.length || 0);
  // }, [watchList?.length]);

  // const logoutUser = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userId");
  //   localStorage.removeItem("userData");
  //   toast.success("Logout successfully!");
  //   setTimeout(() => {
  //     window.location.href = "/login";
  //   }, 1000);
  // };
  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userData");
    
    toast.success("Logout successfully!");

    setTimeout(() => {
      // Redirect to login page
      window.location.href = "/login";

      // Clear browser history to prevent back navigation
      setTimeout(() => {
        window.history.pushState(null, "", "/login");
        window.history.pushState(null, "", "/login");
      }, 500);
    }, 1000);
};


  const toggleNotification = () => {
    setNotificationOpen(!isNotificationOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        !event.target.closest(".nav-link") // Ensure the bell icon click doesn't close it
      ) {
        setNotificationOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="profile_navbar navbar navbar-expand-lg navbar-light">
      <div className="container-fluid ">
        <button
          class="btn ms-4 p-0 shadow-none border-0 d-lg-none"
          id="menu-toggle"
          onClick={props?.onClickBtn}
        >
          <i class="fa-solid fa-bars text-white"></i>
        </button>
        <div
          className="navbar-collapse"
          id="navbarSupportedContent"
          style={{ flexBasis: "auto" }}
        >
          <ul className="managed_navbar navbar-nav ms-auto mt-2 mt-lg-0 align-items-center justify-content-end d-flex flex-row flex-wrap">
            <li className="nav-item ">
              <button
                className="nav-link p-2 position-relative"
                onClick={toggleNotification}
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Notifications"
              >
                <img src={bell} alt="" className="header-images" />
                <span className="badge_counter">
                  {headerCountData?.notificationUnReadCount >= 99
                    ? "99+"
                    : headerCountData?.notificationUnReadCount || 0}
                </span>
              </button>
            </li>
            <li className="nav-item ">
              <a
                className="nav-link p-2 position-relative"
                href="/sip-lumpsum-cart"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="My cart"
              >
                <img src={cart} alt="" className="header-images" />
                <span className="badge_counter">{headerCountData?.cartCount || 0}</span>
              </a>
            </li>
            <li className="nav-item ">
              <a
                className="nav-link p-2 position-relative"
                href="/wishlist"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Wishlist"
              >
                <img src={bookmark} alt="" className="header-images" />
                <span className="badge_counter">{headerCountData?.watchListCount || 0}</span>
              </a>
            </li>
            {/* <li className="nav-item active">
              <a
                className="nav-link p-2 d-flex align-items-center gap-2"
                href="/"
              >
                <img src={moon} alt="" className="header-images" />
                <span className="mode_label">Dark Mode</span>
                <div className="form-check form-switch ms-2">
                  <input
                    className="form-check-input shadow-none switch_mode"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked
                  />
                </div>
              </a>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle avtar d-flex align-items-center"
                href="/"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#007bff",
                    color: "white",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  {userData?.name ? userData.name.charAt(0).toUpperCase() : ""}
                </div>
                <span className="ps-2 user_label pe-3 d-sm-inline-flex">
                  Welcome {userData?.name ? userData.name : "NA"}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end py-0 overflow-hidden"
                aria-labelledby="navbarDropdown"
              >
                <div className="border-bottom p-2 d-sm-none d-flex">
                  <img src={avatar} alt="user icon" className="me-2" />
                  <span className="ps-2 user_label text-nowrap">
                    Welcome {userData?.name ? userData?.name : "NA"}
                  </span>
                </div>
                <Link className="dropdown-item py-2" to="/my-profile">
                  My Profile
                </Link>
                <Link className="dropdown-item py-2" to="/change-password">
                  Change Password{" "}
                </Link>
                {/* <div className="dropdown-divider"></div> */}
                <Link
                  className="dropdown-item py-2"
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <NotificationPanel
        isOpen={isNotificationOpen}
        onClose={toggleNotification}
        notificationRef={notificationRef}
      />
    </nav>
  );
};

export default AfterLoginHeader;
