import React, { useEffect, useState } from "react";
import help_icon from "../images/sidebar_icon/help_icon.svg";
import SelectDropDown from "../components/SelectDropDown";
import { useNavigate } from "react-router-dom";
import {
  getBankDetail,
  userOnboardingGetDetails,
  userOnboardingStoreData,
} from "../actions/usersActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageUserData } from "../utils/userData";
import { sanitizeInput } from "../utils/sanitizeInput";
import { Document, Page } from "react-pdf"; // Optional for advanced PDF handling
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// Functional component for the Home page
const BankDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const [chequeData, setChequeData] = useState("");
  const formData = new FormData();

  const { userOnboardGetData, userOnboardStoreData, loading, error } =
    useSelector((state) => state.users);

  useEffect(() => {
    const payload = {
      id: userData?.id,
      completed_wizard_step: "1",
    };
    dispatch(userOnboardingGetDetails(payload));
  }, []);

  const accountTypeOptions = [
    { value: "SB", label: "Saving" },
    { value: "CA", label: "Current" },
    { value: "CC", label: "CashCredit" },
  ];

  const handleNextClick = () => {
    navigate("/fatca-declartion");
  };
  const handleBack = () => {
    // navigate("/personal-details");
    window.location.href = "/personal-details";
  };

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });

  const formik = useFormik({
    initialValues: {
      acc_no: userOnboardGetData?.data?.bank_account[0]?.acc_no || "",
      acc_no_confirm: userOnboardGetData?.data?.bank_account[0]?.acc_no || "",
      bank_holder_name: userOnboardGetData?.data?.username
        ? userOnboardGetData?.data?.username
        : userOnboardGetData?.data?.bank_account[0]?.bank_holder_name
        ? userOnboardGetData?.data?.bank_account[0]?.bank_holder_name
        : "",
      acc_type: userOnboardGetData?.data?.bank_account[0]?.acc_type || "",
      ifsc_code: userOnboardGetData?.data?.bank_account[0]?.ifsc_code || "",
      bank_code: userOnboardGetData?.data?.bank_account[0]?.bank_code || "",
      branch_name: userOnboardGetData?.data?.bank_account[0]?.branch_name || "",
      branch_addr1:
        userOnboardGetData?.data?.bank_account[0]?.branch_addr1 || "",
      file_attachment: userOnboardGetData?.data?.cheque_img || "",
      set_default:
        userOnboardGetData?.data?.bank_account[0]?.set_default || "N",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      acc_no: Yup.string()
        .matches(/^\d+$/, "Account number must contain only numbers") // Allow only numeric characters
        .min(8, "Account number must be at least 8 digits")
        .max(16, "Account number must not exceed 16 digits")
        .required("Account number is required"),
      acc_no_confirm: Yup.string()
        .matches(/^\d+$/, "Account number must contain only numbers") // Allow only numeric characters
        .min(8, "Account number must be at least 8 digits")
        .max(16, "Account number must not exceed 16 digits")
        .required("Confirm Account Number  is required")
        .oneOf([Yup.ref("acc_no")], "Account numbers do not match"),
      bank_holder_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Invalid Account Holder Name format")
        .required("Account Holder Name is required"),
      acc_type: Yup.string()
        .notOneOf(["Select"], "Account Type is required")
        .required("Account Type is required"),
      ifsc_code: Yup.string()
        .required("IFSC Code is required")
        .matches(
          /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
          "Invalid IFSC code, should follow format XXXX0XXXXXX"
        ),
      bank_code: Yup.string()
        .notOneOf(["Select"], "Bank Name is required")
        .required("Bank Name is required"),
      branch_name: Yup.string().required("Branch Name is required"),
      branch_addr1: Yup.string()
        .transform((value) => value.replace(/\s{2,}/g, " "))
        .test(sanitizeTest("Branch Address"))
        .max(200, "Input should not accept more than 200 characters")
        .required("Branch Address is required"),
      file_attachment: Yup.mixed()
        .required("Cancelled Cheque is required")
        .test("fileType", "Only PDF or image files are allowed", (value) => {
          if (!value) return false; // Ensure the value exists
          if (typeof value === "string") {
            // Handle file as a link
            const allowedExtensions = /\.(pdf|jpg|jpeg|png)$/i;
            return allowedExtensions.test(value);
          } else if (value instanceof File) {
            // Handle file as a File object
            const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
            return allowedTypes.includes(value.type);
          }
          return false;
        }),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        id: userData?.id,
        branch_addr1: values?.branch_addr1?.replace(/\s{2,}/g, " "),
        completed_wizard_step: 1,
      };
      for (const key in payload) {
        if (key === "file_attachment") {
          // For file attachments, ensure it is a valid File object
          if (payload[key] instanceof File) {
            formData.append(key, payload[key]);
          } else {
            console.warn("file_attachment is not a valid File object.");
          }
        } else {
          formData.append(key, payload[key]);
        }
      }
      dispatch(
        userOnboardingStoreData(
          formData,
          userData?.id,
          values?.acc_no,
          payload?.completed_wizard_step
        )
      );
    },
  });

  const callDetilOnIFSCCode = (ifscCode) => {
    dispatch(getBankDetail(ifscCode))
      .then((res) => {
        const matchingOption = Object.entries(
          userOnboardGetData?.data?.banks
        ).find(([id]) => res.BANKCODE.includes(id));
        if (matchingOption) {
          const [value] = matchingOption;
          formik.setFieldValue("bank_code", value);
        } else {
          formik.setFieldValue("bank_code", res.BANKCODE);
        }

        formik.setFieldValue("branch_name", res?.BRANCH);
        formik.setFieldValue("branch_addr1", res?.ADDRESS);
      })
      .catch((error) => {
        console.log("Error:".error);
      });
  };
  useEffect(() => {
    if (userOnboardGetData) {
      setChequeData(userOnboardGetData?.data?.cheque_img);
      if (
        userOnboardGetData?.wizard_step == 4 ||
        userOnboardGetData?.wizard_step == 5
      ) {
        window.location.href = "/dashboard";
      } else if (1 <= userOnboardGetData?.wizard_step) {
      } else {
        if (userOnboardGetData?.wizard_step == 0) {
          window.location.href = "/personal-details";
        } else if (userOnboardGetData?.wizard_step == 1) {
          window.location.href = "/bank-details";
        } else if (userOnboardGetData?.wizard_step == 2) {
          window.location.href = "/fatca-declartion";
        }
      }
    }
  }, [userOnboardGetData]);
  const isPDF = chequeData && chequeData.endsWith(".pdf");
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="progress-container page_inner_content_box">
          <div className="step-info">
            <span>Step 2 of 4</span>
          </div>
          <h4 className="page-title mb-0">Bank Details</h4>
        </div>
        <div className="progress-bar">
          <div className="progress-filled" style={{ width: "50%" }}></div>
        </div>
        <div className="page_inner_content_box d-flex flex-wrap justify-content-between ">
          <form
            id="bank_form"
            onSubmit={formik.handleSubmit}
            className="step_form"
          >
            <div className="card border-0">
              <div className="card-body form_card">
                <div className="row">
                  <div className="col-xl-7 col-lg-8 col-12 order-lg-1 order-2">
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="acc_no" className="form-label">
                          Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="acc_no"
                          placeholder="Enter Account Number"
                          value={formik.values.acc_no}
                          {...formik.getFieldProps("acc_no")}
                        />
                        {formik.touched.acc_no && formik.errors.acc_no ? (
                          <div className="text-danger">
                            {formik.errors.acc_no}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="acc_no_confirm" className="form-label">
                          Confirm Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="password"
                          className="form-control"
                          id="acc_no_confirm"
                          placeholder="Confirm Account Number"
                          {...formik.getFieldProps("acc_no_confirm")}
                        />
                        {formik.touched.acc_no_confirm &&
                        formik.errors.acc_no_confirm ? (
                          <div className="text-danger">
                            {formik.errors.acc_no_confirm}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label
                          htmlFor="accountHolderName"
                          className="form-label"
                        >
                          Account Holder Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="accountHolderName"
                          placeholder="Enter Account Holder Name"
                          {...formik.getFieldProps("bank_holder_name")}
                        />
                        {formik.touched.bank_holder_name &&
                        formik.errors.bank_holder_name ? (
                          <div className="text-danger">
                            {formik.errors.bank_holder_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="acc_type" className="form-label">
                          Account Type*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="acc_type"
                          id=""
                          value={formik.values.acc_type}
                          changeFunction={(value) =>
                            formik?.setFieldValue("acc_type", value)
                          }
                          options={accountTypeOptions}
                        />
                        {formik.touched.acc_type && formik.errors.acc_type ? (
                          <div className="text-danger">
                            {formik.errors.acc_type}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="ifscCode" className="form-label">
                          IFSC Code*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="ifscCode"
                          name="ifsc_code"
                          placeholder="Enter IFSC Code"
                          onChange={(e) => {
                            formik.handleChange(e); // Handle Formik's change
                            callDetilOnIFSCCode(e.target.value); // Call your custom function
                          }}
                          value={formik.values.ifsc_code}
                        />
                        {formik.touched.ifsc_code && formik.errors.ifsc_code ? (
                          <div className="text-danger">
                            {formik.errors.ifsc_code}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="bankName" className="form-label">
                          Bank Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="bank_code"
                          id=""
                          value={formik.values.bank_code}
                          changeFunction={(value) =>
                            formik?.setFieldValue("bank_code", value)
                          }
                          options={userOnboardGetData?.data?.banks?.map(
                            (bank) => ({
                              value: bank.bank_code,
                              label: bank.bank_name,
                            })
                          )}
                        />
                        {formik.touched.bank_code && formik.errors.bank_code ? (
                          <div className="text-danger">
                            {formik.errors.bank_code}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="branch_name" className="form-label">
                          Branch Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="branch_name"
                          placeholder="Enter Branch Name"
                          {...formik.getFieldProps("branch_name")}
                        />
                        {formik.touched.branch_name &&
                        formik.errors.branch_name ? (
                          <div className="text-danger">
                            {formik.errors.branch_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="branch_addr1" className="form-label">
                          Branch Address*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="branch_addr1"
                          placeholder="Enter Branch Address"
                          {...formik.getFieldProps("branch_addr1")}
                        />
                        {formik.touched.branch_addr1 &&
                        formik.errors.branch_addr1 ? (
                          <div className="text-danger">
                            {formik.errors.branch_addr1}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {chequeData ? (
                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6">
                          <label
                            htmlFor="cancelledCheque"
                            className="form-label"
                          >
                            Cancelled Cheque (Image/PDF)*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6">
                          {isPDF ? (
                            // Render PDF
                            <iframe
                              src={chequeData}
                              width="100%"
                              height="200px"
                              title="PDF Viewer"
                              style={{ border: "none" }}
                            ></iframe>
                          ) : (
                            /* Alternatively, use react-pdf for advanced PDF rendering */
                            // <Document file={fileData}>
                            //   <Page pageNumber={1} />
                            // </Document>
                            // Render Image
                            <img
                              src={chequeData}
                              alt="Uploaded File"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6">
                          <label
                            htmlFor="cancelledCheque"
                            className="form-label"
                          >
                            Cancelled Cheque (Image/PDF)*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6">
                          <input
                            type="file"
                            className="form-control"
                            id="file_attachment"
                            name="file_attachment"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              formik?.setFieldValue("file_attachment", file);
                            }}
                          />
                          {formik.touched.file_attachment &&
                          formik.errors.file_attachment ? (
                            <div className="text-danger">
                              {formik.errors.file_attachment}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    <div className="row align-items-center mb-4 ml-2">
                      <div className="form-check form-check-inline ">
                        <input
                          className="form-check-input"
                          style={{
                            marginLeft: "-10px",
                            border: "1px solid gray",
                          }}
                          type="checkbox"
                          name="set_default"
                          id="set_default"
                          checked={formik.values.set_default === "Y"}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "set_default",
                              e.target.checked ? "Y" : "N"
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <label className="form-check-label ms-2" htmlFor="male">
                          Set as a primary account
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-4 ps-xl-5 order-lg-2 order-1 mb-lg-0 mb-4">
                    <div className="info-box px-lg-4">
                      <div className="d-flex align-items-start">
                        <img
                          src={help_icon}
                          alt=""
                          className="me-3"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <div>
                          <h5 className="fw-semibold mb-3">
                            Secure Your Transactions
                          </h5>
                          <p className="mb-2">
                            Adding your bank account details enables secure and
                            seamless transactions. Rest assured, your
                            information is protected with advanced encryption.
                          </p>
                          <p className="highlight_text">
                            You can edit these details later if needed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer form_card card_footer">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="form_button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button type="submit" className="form_button next_btn">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
