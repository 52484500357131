const PopupModel = (props) => {
  return (
    // <div
    //   className="modal fade"
    //   style={{backgroundColor:'#00000080'}}
    //   id={props.id}
    //   tabIndex="-1"
    //   aria-labelledby={`${props.id}Label`}
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content">
    //     <div className="modal-header border-bottom-0 position-absolute  end-0 p-0">
    //         <button
    //           type="button"
    //           className="btn-close  p-4 shadow-none"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //           style={{ zIndex:99999 }}
    //         ></button>
    //       </div>
    //       <div className="modal-body text-center p-lg-5 p-4">
    //         <img
    //           className="successLogo mb-3"
    //           src={props.image}
    //           alt="Success logo"
    //         />
    //         <h2 className="successText">{props.title}</h2>
    //         <p className="successEmailText">
    //           {props.content}
    //         </p>

    //         <button
    //           type="button"
    //           className="btn btn-primary ok_button_inner"
    //           data-bs-dismiss="modal"
    //         >
    //           OK
    //         </button>
    //         </div>
    //     </div>
    //   </div>
    // </div>

    <div
      className="modal fade show"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ width: "400px" }}
        role="document"
      >
        {/* <div className="modal-content"> */}
        <div className={`modal-content ${props?.customClass || ""}`}>
          {/* <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={props?.closeModal}
              aria-label="Close"
            ></button>
          </div> */}
          {/* <div className="modal-body text-center p-lg-5 p-4"> */}
          <div
            className={`modal-body text-center p-lg-5 p-4 ${
              props?.custombodyclass || ""
            }`}
          >
            <img src={props.image} alt="Modal Icon" className="mb-3" />
            <p>{props.content}</p>
          </div>
          {props?.type == "addtocart" ? (
            <>
              <div className="modal-footer">
                <button
                  type="button"
                  className={`btn ${props?.btnColor}`}
                  onClick={() => {
                    if (props?.actionPopup) {
                      props?.actionPopup();
                    }
                    props?.closeModal();
                  }}
                >
                  OK
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={props?.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`btn ${props?.btnColor}`}
                  onClick={() => {
                    if (props?.actionPopup) {
                      props?.actionPopup();
                    }
                    props?.closeModal();
                  }}
                >
                  OK
                </button>
                {props?.later && (
                  <button
                    type="button"
                    className={`btn ${props?.later}`}
                    onClick={() => {
                      if (props?.actionPopup) {
                        props?.actionPopup(1);
                      }
                      props?.closeModal();
                    }}
                  >
                    Later
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupModel;
