import React, { useEffect, useState } from 'react';
import WishlistCards from '../../components/WishlistCards';
import featureimage from '../../images/orders/logo.svg';
import growthTree from '../../images/icons/growth-tree.svg';
import InvestButton from '../../components/InvestButton';
import ReportTable from './ReportTable';
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../../actions/messageAction';
import Loader from '../../loader/Loader-ui';
import { getLocalStorageUserData } from "../../utils/userData";
import ReportChart from './ReportChart';

// Functional component for the Home page
const SummaryReport = () => {
    const dispatch = useDispatch();
    const { getMessage: { data }, loading } = useSelector((state) => state.messages);
    const [isLoading, setIsLoading] = useState(false);
    const userData = getLocalStorageUserData();
    useEffect(() => {
        const payload = { id: userData?.id };
        setIsLoading(true);
        dispatch(getMessage(payload)).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            console.error("API call failed: ", error);
        })
    }, [dispatch, userData?.id])

    const tickets = data?.ticket || [];

    return (
        <div className="container-fluid page_background ">
            {isLoading && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            <div className="page-inner-content mb-3" >
                <div className="page_inner_content_box ">
                    <div className="d-flex gap-3 flex-wrap justify-content-between align-itmes-center mb-4 ">
                        <h5 className="mb-0 main-heading-title">
                            Summary Report
                        </h5>
                        <InvestButton label='Get Customer wise Report' url='/raise-ticket' color='blue' />
                    </div>

                    <div className="ticket_card_inner page_content_overflow  tickets_Messages">
                        <div className="row align-items-end">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="dropdown-label-custom">Relationship Manager:</div>
                                <select className="form-select custom-select" defaultValue="" required>
                                    <option value="" disabled hidden>
                                        Select
                                    </option>
                                    <option>Manager 1</option>
                                    <option>Manager 2</option>
                                    <option>Manager 3</option>
                                </select>
                            </div>

                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                        <div className='mb-3'>
                            {/* <TicketTable number='123' message='Lorem Ipsum' status='Open'/> */}
                            <ReportTable tickets={tickets} />
                            <ReportChart />
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default SummaryReport;
