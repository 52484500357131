import { useState, useEffect, useRef } from "react";
import "../../styles/transaction.css";
import holding from "../../images/orders/logo.svg";
import filter from "../../images/transaction/filter.svg";
import { IoMdClose } from "react-icons/io";

const InvestorTable = ({
  data,
  selectedType,
  selectedStatus,
  onTypeChange,
  onStatusChange,
}) => {
  const [showTypeOptions, setShowTypeOptions] = useState(false);
  const [showStatusOptions, setShowStatusOptions] = useState(false);

  const typeOptions = [
    { value: "all", label: "All" },
    { value: "Fresh Purchase", label: "Fresh Purchase" },
    { value: "Additional Purchase", label: "Additional Purchase" },
    { value: "Redeem", label: "Redeem" },
    { value: "Switch", label: "Switch" },
    { value: "Gross Dividend", label: "Gross Dividend" },
  ];

  const status = [
    { value: "all", label: "All" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" },
    { value: "Success", label: "Success" },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle missing or invalid date
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleTypeChange = (type) => {
    onTypeChange(type);
    setShowTypeOptions(false); // Close the dropdown after selecting a filter
  };

  const handleStatusChange = (status) => {
    onStatusChange(status);
    setShowStatusOptions(false); // Close the dropdown after selecting a filter
  };

  const initialData = [
    { id: 1, status: "Success", type: "Fresh Purchase" },
    { id: 2, status: "Pending", type: "Additional Purchase" },
    { id: 3, status: "Rejected", type: "Redeem" },
    { id: 4, status: "Rejected by RTA with Reason", type: "Gross Dividend" },
    { id: 5, type: "Switch" },
  ];

  const [dataFilter, setDataFilter] = useState(initialData);
  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const filterRef = useRef(null);

  const uniqueStatuses = [...new Set(initialData.map((item) => item.status))];
  const uniqueTypes = [...new Set(initialData.map((item) => item.type))];

  useEffect(() => {
    let filteredData = [...initialData];

    if (statusFilter) {
      filteredData = filteredData.filter((item) =>
        item.status?.toLowerCase().includes(statusFilter?.toLowerCase())
      );
    }

    if (typeFilter) {
      filteredData = filteredData.filter((item) =>
        item.type?.toLowerCase().includes(typeFilter?.toLowerCase())
      );
    }

    setDataFilter(filteredData);
  }, [statusFilter, typeFilter]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setActiveFilter(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterClick = (filter) => {
    setActiveFilter(activeFilter === filter ? null : filter);
  };

  const clearFilter = (filter) => {
    if (filter === "status") {
      setStatusFilter("");
    } else {
      setTypeFilter("");
    }
  };

  return (
    <div className="table-container table-responsive">
      <table className="table_transaction">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>IIN</th>
            <th>Registration Status</th>
            <th>FATCA Status</th>
            <th>KYC Status</th>
            {/* <th>
              Type
              <div className="filter-container">
                <img
                  src={filter}
                  alt="Filter"
                  style={{ width: '12px', height: '12px', marginLeft: '8px', cursor: 'pointer' }}
                  onClick={() => setShowTypeOptions(!showTypeOptions)}
                />
                {showTypeOptions && (
                  <div className="dropdown1">
                    {typeOptions.map((option) => (
                      <div key={option.value} onClick={() => handleTypeChange(option?.value)}>
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {data?.investorsList && data?.investorsList.length > 0 ? (
            data.investorsList.map((transaction, index) => (
              <tr
                key={index}
                className={`${
                  transaction?.transaction?.trxn_status?.toLowerCase() ||
                  transaction?.status?.toLowerCase()
                } transaction_row`}
              >
                <td>
                  {/* {formatDate(
                    transaction?.post_date ||
                      transaction?.transaction?.authorized_date_time
                  )} */}
                  {index + 1}
                </td>
                <td>
                  {/* <img
                    src={transaction?.icon}
                    alt="Holding"
                    className="transection_image"
                  /> */}
                  {transaction?.name || "NA"}
                </td>
                <td>{transaction?.profile?.customer_id || "NA"}</td>
                <td>
                  <span
                    className={`${
                      transaction?.profile?.iin_status === "Y"
                        ? "investor-status investor-status-active"
                        : transaction?.profile?.iin_status === "N"
                        ? "investor-status investor-status-inactive"
                        : ""
                    } `}
                  >
                    {transaction?.profile?.iin_status === "Y"
                      ? "Active"
                      : transaction?.profile?.iin_status === "N"
                      ? "Inactive"
                      : "NA"}
                  </span>
                </td>
                <td>
                  <span
                    className={`${
                      transaction?.profile?.fatca_status === "Y"
                        ? "investor-status investor-status-active"
                        : transaction?.profile?.fatca_status === "N"
                        ? "investor-status investor-status-inactive"
                        : ""
                    } `}
                  >
                    {transaction?.profile?.fatca_status === "Y"
                      ? "Active"
                      : transaction?.profile?.fatca_status === "N"
                      ? "Inactive"
                      : "NA"}
                  </span>
                  {/* {transaction?.profile?.fatca_status || "NA"} */}
                </td>
                <td>
                  <span
                    className={`${
                      transaction?.profile?.kyc_status === "Y"
                        ? "investor-status investor-status-active"
                        : transaction?.profile?.kyc_status === "N"
                        ? "investor-status investor-status-inactive"
                        : ""
                    } `}
                  >
                    {transaction?.profile?.kyc_status === "Y"
                      ? "Active"
                      : transaction?.profile?.kyc_status === "N"
                      ? "Inactive"
                      : "NA"}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ height: "200px" }}>
              <td className="no-data-message">No Investors found</td>
            </tr>
          )}
        </tbody>
      </table>

      <style jsx>{`
        .filter-popup {
          position: absolute;
          // background: white;
          padding: 8px;
          // border: 1px solid #dee2e6;
          border-radius: 4px;
          z-index: 1000;
          min-width: 200px;
        }

        .status-filter-popup {
          position: absolute;
          padding: 8px;
          border-radius: 4px;
          z-index: 1000;
          min-width: 200px;
          right: 20px;
        }
        .close-btn-mb {
          margin-bottom: 4px;
        }

        .btn-link {
          color: #6c757d;
          text-decoration: none;
        }

        .btn-link:hover {
          color: #0d6efd;
        }

        .input-group .btn {
          border-left: none;
        }
      `}</style>
    </div>
  );
};

export default InvestorTable;
