import { useState, useEffect, useRef } from "react";
import Pencil from "../../images/icons/Pencil.svg";
import View from "../../images/icons/view-icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  adminEmailUpdate,
  adminMobileUpdate,
  investorList,
  ResubmitProfileUcid,
} from "../../actions/adminAction";
const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const sampleData = {
  orders: [
    {
      name: "Prajakta Rahul Baravkar",
      mobile: "9898989898",
      ucid: "1234567890",
      added_date: "30-12-2024",
      fatca_status: "Customer not registered on Fundswala",
      kyc_status: "",
      iin_status: "",
      nach_details: "",
      status: "pending",
    },
    {
      name: "Indra H. L.",
      mobile: "1212121212",
      ucid: "1234567891",
      added_date: "12-12-2024",
      fatca_status: "Active",
      kyc_status: "Active",
      iin_status: "Inactive",
      nach_details: true,
      status: "active",
    },
    {
      name: "Lalitha K. S.",
      mobile: "1234567890",
      ucid: "1234567892",
      added_date: "12-12-2024",
      fatca_status: "Inactive",
      kyc_status: "Active",
      iin_status: "Active",
      nach_details: true,
      status: "active",
    },
  ],
};

const Investor = ({ data, currentPage }) => {
  const dispatch = useDispatch(); // Initialize dispatch
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedMobile, setUpdatedMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const getChipStyles = (statusClass, statusValue) => {
    if (statusClass === "N") {
      return {
        backgroundColor: "#fedfbe",
        color: "#f48109",
        fontWeight: "600",
      }; // Yellow background for 'Inactive'
    } else {
      return statusClass === "Y"
        ? { backgroundColor: "#aafec0", color: "#28ae70", fontWeight: "600" }
        : { backgroundColor: "#ffe6e6", color: "#dc3545", fontWeight: "600" };
    }
  };

  const handleEditClick = (investor, type) => {
    setSelectedInvestor(investor);
    if (type === "email") {
      setUpdatedEmail(investor?.email || "");
      document.getElementById("emailModal").classList.add("show", "d-block");
    } else if (type === "mobile") {
      setUpdatedMobile(investor?.mobile || "");
      document.getElementById("mobileModal").classList.add("show", "d-block");
    }
  };

  const closeModal = (id) => {
    document.getElementById(id).classList.remove("show", "d-block");
  };

  const handleSaveEmail = async () => {
    if (!updatedEmail) {
      toast.error("Email cannot be empty");
      return;
    }

    const payload = {
      user_id: selectedInvestor?.id,
      email: updatedEmail,
    };

    try {
      await dispatch(adminEmailUpdate(payload));
      toast.success("Email updated successfully");
      await dispatch(investorList({ page_num: 1, per_page: "10", search: "" }));
      closeModal("emailModal");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to update email");
    }
  };

  const handleSaveMobile = async () => {
    if (!updatedMobile) {
      toast.error("Mobile number cannot be empty");
      return;
    }

    const payload = {
      user_id: selectedInvestor?.id,
      mobile: updatedMobile,
    };

    try {
      await dispatch(adminMobileUpdate(payload));
      toast.success("Mobile number updated successfully");
      await dispatch(investorList({ page_num: 1, per_page: "10", search: "" }));
      closeModal("mobileModal");
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to update mobile number"
      );
    }
  };

  const handleResubmitProfile = async (data) => {
    try {
      setLoading(true);
      let response = await dispatch(ResubmitProfileUcid(data));
      if (response?.status === false) {
        toast.error(response?.message || "Something went wrong!");
      } else {
        toast.success(response?.message);
      }
      // await dispatch(investorList({ page_num: 1, per_page: "10", search: "" }));
      // closeModal("mobileModal");
    } catch (error) {
      toast.error("Failed to assign users.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handleNavigation = (data) => {
    navigate("/admin/viewinvestor", { state: data });
  };

  const handleEditNavigation = (data) => {
    navigate("/admin/investeredit", { state: data });
  };

  const Goviewnachdetails = (data) => {
    navigate("/admin/viewnachdetails", { state: data });
  };

  const GoMappingdetails = (data) => {
    navigate("/admin/viewmaping", { state: data });
  };

  const Goinvestorstatus = (data, type) => {
    navigate("/admin/investorstatus", { state: { data: data, type: type } });
  };

  const GoIINStatus = (data, type) => {
    navigate("/admin/investorstatus", { state: { data: data, type: type } });
  };

  return (
    <div className="table-container table-responsive">
      <table className="table_transaction">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>UCID</th>
            <th>FATCA Status</th>
            <th>KYC Status</th>
            <th>IIN Status</th>
            <th>Nach Details</th>
            <th>Added Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data?.map((investor, index) => (
              <tr
                key={index}
                className={`${
                  investor?.investor?.trxn_status?.toLowerCase() ||
                  investor?.status?.toLowerCase()
                } transaction_row`}
              >
                <td>{(currentPage - 1) * 10 + index + 1}</td>
                <td className="white-space-normal">{investor?.name || "NA"}</td>
                <td
                  className="investor-num-call investor-call-cursor"
                  sx={{ cursor: "pointer", color: "#0a23c1" }}
                  onClick={() => handleEditClick(investor, "email")}
                >
                  {investor?.email || "Add"}
                </td>
                <td
                  className="investor-num-call investor-call-cursor"
                  sx={{ cursor: "pointer", color: "#0a23c1" }}
                  onClick={() => handleEditClick(investor, "mobile")}
                >
                  {investor?.mobile || "Add"}
                </td>
                {investor?.profile ? (
                  <>
                    {investor?.profile?.customer_id ? (
                      <td
                        onClick={() =>
                          GoMappingdetails(investor?.profile?.customer_id)
                        }
                        className="investor-num-call investor-call-cursor"
                      >
                        {investor?.profile?.customer_id}
                      </td>
                    ) : (
                      <td
                        onClick={() =>
                          handleResubmitProfile(investor?.profile?.id)
                        }
                        className="investor-num-call investor-call-cursor"
                      >
                        Resubmit Profile
                      </td>
                    )}

                    {investor?.is_profile === false ? (
                      <td className="text-center" colSpan={6}>
                        <span className="white-space-normal ">
                          Customer not registered on Fundswala
                        </span>
                      </td>
                    ) : (
                      <>
                        <td
                          className="investor-call-cursor"
                          onClick={() =>
                            investor.profile?.fatca_status === "N"
                              ? Goinvestorstatus(investor?.profile?.id, "Fatca")
                              : undefined
                          }
                          // onClick={investor.profile?.fatca_status === "N" ? Goinvestorstatus(investor?.profile?.user_id) : undefined}
                        >
                          <span
                            className={`investor-status investor-status-${investor.profile?.fatca_status}`}
                            style={{
                              ...getChipStyles(investor.profile?.fatca_status),
                              borderRadius: "15px",
                              padding: "5px 20px",
                              display: "inline-block",
                            }}
                          >
                            {investor.profile?.fatca_status === "Y"
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </td>
                        {/* <td className="investor-call-cursor" onClick={Goinvestorstatus}>
                <span
                  className={`investor-status investor-status-${investor.profile?.fatca_status}`}
                >
                  {investor.profile?.fatca_status || "NA"}
                </span>
              </td> */}
                        <td>
                          {investor?.is_profile !== false && (
                            <span
                              className={`investor-status investor-status-${investor.profile?.kyc_status}`}
                              style={{
                                ...getChipStyles(investor.profile?.kyc_status),
                                borderRadius: "15px",
                                padding: "5px 20px",
                                display: "inline-block",
                              }}
                            >
                              {investor.profile?.kyc_status === "Y"
                                ? "Active"
                                : "Inactive"}
                            </span>
                          )}
                        </td>
                        {/* <td>
                {investor.profile?.kyc_status && (
                  <span
                    className={`investor-status investor-status-${investor.profile?.kyc_status}`}
                  >
                    {investor?.kyc_status || "NA"}
                  </span>
                )}
              </td> */}
                        <td
                          className="investor-call-cursor"
                          // onClick={GoIINStatus}
                          onClick={() =>
                            investor?.profile?.iin_status === "N"
                              ? GoIINStatus(investor?.profile?.id, "IIN")
                              : undefined
                          }
                        >
                          {investor?.is_profile !== false && (
                            <span
                              className={`investor-status investor-status-${investor?.iin_status}`}
                              style={{
                                ...getChipStyles(investor?.profile?.iin_status),
                                borderRadius: "15px",
                                padding: "5px 20px",
                                display: "inline-block",
                              }}
                            >
                              {investor?.profile?.iin_status === "Y"
                                ? "Active"
                                : "Inactive"}
                            </span>
                          )}
                        </td>
                        {/* <td className="investor-call-cursor" onClick={GoIINStatus}>
                {investor?.iin_status && (
                  <span
                    className={`investor-status investor-status-${investor?.is_iin}`}
                  >
                    {investor?.iin_status || "NA"}
                  </span>
                )}
              </td> */}
                        <td>
                          {investor?.is_profile !== false && (
                            <div
                              className="goals-view investor-call-cursor"
                              style={{ cursor: "pointer" }}
                              // onClick={Goviewnachdetails}
                              onClick={() =>
                                Goviewnachdetails(investor?.mandates || [])
                              }
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="View Enach"
                            >
                              <img
                                src={View}
                                alt="View"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="View Enach"
                              />
                            </div>
                          )}
                        </td>
                        {/* <td>
                <div
                  className="goals-view investor-call-cursor"
                  style={{ cursor: "pointer" }}
                  onClick={Goviewnachdetails}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Delete"
                >
                  <img
                    src={View}
                    alt="View"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Delete"
                  />
                </div>
              </td> */}
                        <td>
                          {investor?.created_at
                            ? new Date(investor.created_at).toLocaleDateString(
                                "en-GB"
                              )
                            : "NA"}
                        </td>
                        <td>
                          {/* <div className="investor-action-buttons">
                  View Edit
                </div> */}
                          <div className="d-flex gap-2">
                            <div
                              className="goals-edit"
                              style={{ cursor: "pointer" }}
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Edit"
                              onClick={() =>
                                handleEditNavigation(investor?.profile?.user_id)
                              }
                            >
                              <img
                                src={Pencil}
                                alt=""
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Edit"
                              />
                            </div>

                            <div
                              className="goals-view"
                              style={{ cursor: "pointer" }}
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="View"
                              onClick={() =>
                                handleNavigation(investor?.profile?.id)
                              }
                            >
                              <img
                                src={View}
                                alt="View"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="View"
                                // url="/admin/viewinvestor"
                              />
                            </div>
                          </div>
                        </td>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <td colSpan={8} className="text-center">
                      <span className="white-space-normal">
                        Profile is not created
                      </span>
                    </td>
                  </>
                )}
              </tr>
            ))
          ) : (
            <tr style={{ height: "200px" }}>
              <td colSpan="11" className="no-data-message">
                No Investor found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Dialog for Mandate Details */}
      {/* <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Mandate Details</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bank Name</TableCell>
                  <TableCell>Acc. Type</TableCell>
                  <TableCell>Acc. No</TableCell>
                  <TableCell>Nach Status</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Nach Limit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedMandates.length > 0 ? (
                  selectedMandates.map((mandate, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{mandate?.bank_name || "-"}</TableCell>
                      <TableCell>{mandate.ac_type || "-"}</TableCell>
                      <TableCell>{mandate?.account_no || "-"}</TableCell>
                      <TableCell>
                        {mandate?.ach_mandate_status || "-"}
                      </TableCell>
                      <TableCell>
                        {mandate?.from_date
                          ? new Date(mandate?.from_date).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {mandate?.to_date
                          ? new Date(mandate?.to_date).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell>{mandate?.amount || "-"}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      No Mandates Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Email Update Modal */}
      <div id="emailModal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Email Address</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => closeModal("emailModal")}
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="email"
                className="form-control"
                placeholder="Enter new email"
                value={updatedEmail}
                onChange={(e) => setUpdatedEmail(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => closeModal("emailModal")}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleSaveEmail}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Update Modal */}
      <div id="mobileModal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Mobile Number</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => closeModal("mobileModal")}
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control"
                placeholder="Enter new mobile number"
                value={updatedMobile}
                onChange={(e) => setUpdatedMobile(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => closeModal("mobileModal")}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleSaveMobile}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investor;
