import axios from "axios";
import {
  GET_EXPLORE_FUNDS_LIST,
  GET_AMC_LISTS,
  GET_EXPLORE_FUNDS_DETAIL,
  GET_NAV_CHART,
  GET_SIMILAR_FUNDS,
  ADD_MY_CART,
  CART_START,
  DELETE_CART,
  CHANGE_ADD_TO_CART_STATUS,
  CHANGE_WATCHLIST_STATUS,
  UPDATE_EXPLORE_FUNDS_LIST,
} from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
import { toast } from "react-toastify";
import { getCards } from "./cardAction";
import axiosInstance from "../api/axios";
import { getHeaderCount } from "./notificationAction";
const token = getLocalStorageUserToken();

export const getExploreFundsList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_EXPLORE_FUNDS_LIST });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}explore-funds`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_EXPLORE_FUNDS_LIST, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_EXPLORE_FUNDS_LIST, error: error.message }); // Dispatching an error state if there's a problem
      console.error("Error fetching explore funds list:", error.message);
    }
  };
};

export const updateExploreFundsList = (updatedRecords) => ({
  type: UPDATE_EXPLORE_FUNDS_LIST,
  payload: [updatedRecords],
});

export const getAMCLists = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_AMC_LISTS });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}amc-list-for-explore-funds`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_AMC_LISTS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_AMC_LISTS, error: error.message }); // Dispatching an error state if there's a problem
      console.error("Error fetching AMC lists:", error.message);
    }
  };
};

export const getFundDetails = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}show-fund`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_EXPLORE_FUNDS_DETAIL, payload: response.data });
    } catch (error) {
      console.error("Error fetching fund details:", error.message);
    }
  };
};

export const getNAVChart = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}chart-data-nav/${payload.isin}/${payload.range}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_NAV_CHART, payload: response.data || [] });
    } catch (error) {
      console.error("Error fetching NAV chart:", error.message);
    }
  };
};

export const AddToMyCart = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}cart/product-add`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.status == false) {
        // toast.error(
        //   response.data.message?.original?.message
        //     ? response.data.message?.original?.message
        //     : response.data?.message
        // );
      } else {
        if (payload.is_additional_purchase == true) {
          window.location.href = "/sip-lumpsum-cart";
        }
        // toast.success(response.data.message);
      }
      // dispatch(getCards({ id: payload.id }));
      dispatch(getHeaderCount(payload.id));
      dispatch({ type: ADD_MY_CART, payload: response.data || [] });
      return response.data;
    } catch (error) {
      dispatch({ type: ADD_MY_CART, error: error.message });
      // console.error("Error fetching NAV chart:", error.message);
    }
  };
};

export const DeleteMyCart = (payload, from, userId) => {
  return async (dispatch) => {
    dispatch({ type: CART_START });
    try {
      let response;
      if (from == 1) {
        response = await axiosInstance.delete(
          `${process.env.REACT_APP_API_URL}cart/funds-delete/${payload}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        dispatch(getHeaderCount(userId));
        dispatch(getCards({ id: userId }));
      } else {
        response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}cart/delete`,
          payload,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        dispatch(getHeaderCount(payload.id));
        dispatch(getCards({ id: payload.id }));
      }
      if (response.data.status == false) {
        toast.error(
          response.data.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
        );
      } else {
        toast.success(response.data.message);
      }

      dispatch({ type: DELETE_CART, payload: response.data || [] });
    } catch (error) {
      dispatch({ type: DELETE_CART, error: error.message });
      // console.error("Error fetching NAV chart:", error.message);
    }
  };
};

export const getSimilarFunds = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}similar-funds/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_SIMILAR_FUNDS, payload: response.data });
    } catch (error) {
      console.error("Error fetching similar funds:", error.message);
    }
  };
};

export const getSimilarFundsGuest = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}similar-funds-guest/${payload}`
      );
      dispatch({ type: GET_SIMILAR_FUNDS, payload: response.data });
    } catch (error) {
      console.error("Error fetching similar funds:", error.message);
    }
  };
};

export const changeAddToCartState = (payload) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_ADD_TO_CART_STATUS, payload });
  };
};
export const changewatchlistState = (payload) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_WATCHLIST_STATUS, payload });
  };
};

export const getFundDetailsForGuest = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}show-funds-guest`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_EXPLORE_FUNDS_DETAIL, payload: response.data });
    } catch (error) {
      console.error("Error fetching fund details:", error.message);
    }
  };
};