import axios from "axios";
import { GET_WATCH_LIST, DELETE_WATCH_LIST } from "./types";
import { toast } from "react-toastify";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
import { getHeaderCount } from "./notificationAction";
const token = getLocalStorageUserToken();

export const getWatchList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_WATCH_LIST }); // Dispatching a loading state
    try {
      // const token = process.env.REACT_APP_API_TOKEN;
      if (payload?.id) {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}watchlist/show`,
          payload,
          {
            headers: {
              Authorization: `${token}`, // Pass the token here
            },
          }
        );
        dispatch({ type: GET_WATCH_LIST, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: GET_WATCH_LIST, error: error.message }); // Dispatching an error state if there's a problem
    }
  };
};

export const deleteWatchList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_WATCH_LIST }); // Dispatching a loading state
    // API call to delete watchlist item
    try {
      // const token = process.env.REACT_APP_API_TOKEN;
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}watchlist/remove`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch({ type: DELETE_WATCH_LIST, payload: response.data });
      // dispatch(getWatchList({ id: payload?.id }));
      dispatch(getHeaderCount(payload?.id));
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch({ type: DELETE_WATCH_LIST, error: error.message }); // Dispatching an error state if there's a problem
      toast.error(error.message);
    }
  };
};

export const addedWatchList = (payload) => {
  return async (dispatch) => {
    // API call to add watchlist item
    try {
      // const token = process.env.REACT_APP_API_TOKEN;
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}watchlist/update`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // dispatch(getWatchList({ id: payload.id }));
      dispatch(getHeaderCount(payload?.id));
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching watchlist items:", error.message);
      toast.error(error.message);
    }
  };
};
