import React, { useRef, useState, useEffect } from "react";
import calendarImage from "../images/calendar_month.svg";

// Utility function to format the date to "YYYY-MM-DD"
const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const DatePickerInput = ({
  selectedDate,
  minDate,
  maxDate,
  onChange,
  props,
  disable,
}) => {
  const dateInputRef = useRef(null);
  const [hasValue, setHasValue] = useState(!!selectedDate);

  // Ensure input updates correctly if selectedDate changes externally
  useEffect(() => {
    setHasValue(!!selectedDate);
  }, [selectedDate]);

  const handleIconClick = () => {
    if (dateInputRef.current) {
      if (dateInputRef.current.showPicker) {
        dateInputRef.current.showPicker(); // Modern browsers
      } else {
        dateInputRef.current.focus(); // Fallback for older browsers
      }
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setHasValue(!!value);
    onChange(value); // Call the onChange prop to update Formik state
  };

  return (
    <div className="custom_date_input position-relative custom-date-wrapper2">
      <input
        type="date"
        placeholder="YYYY-MM-DD"
        id="dob"
        className={`form-control date_birth_input ${
          hasValue ? "has-value" : ""
        }`}
        ref={dateInputRef}
        value={formatDate(selectedDate)} // Ensure proper date format
        onChange={handleInputChange}
        min={minDate}
        max={maxDate}
        disabled={disable ? disable : false}
      />
      <span className="custom-placeholder2">DD/MM/YYYY</span>
      <span
        className="calendericon passwordicon"
        onClick={() => {
          if (!disable) {
            handleIconClick();
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <img src={calendarImage} alt="Calendar Icon" />
      </span>
    </div>
  );
};

export default DatePickerInput;
