import React from 'react';
import '../../styles/ticketTable.css';

const ReportTable = () => {
    return (
        <div className="table-responsive ">
            <table className="table tickets_Messages_table">
                <thead>
                    <tr className="ticket_table_head">
                        <th scope="col">Ticket No.</th>
                        <th scope="col">Total Investments</th>
                        <th scope="col">Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Static data for table */}
                    <tr className="ticket_table">
                        <td>Lumpsum</td>
                        <td>481</td>
                        <td>₹ 20,620,682.54</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>SIP</td>
                        <td>1489</td>
                        <td>₹ 49,083,190.05</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>STP</td>
                        <td>0</td>
                        <td>₹ 0.00</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>Switch</td>
                        <td>121</td>
                        <td>₹ 11,686,180.56</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>SWP</td>
                        <td>0</td>
                        <td>₹ 0.00</td>
                    </tr>

                    {/* Total row */}
                    <tr className="ticket_table total_row">
                        <td>Total</td>
                        <td>2091</td>
                        <td>₹ 81,390,053.15</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ReportTable;
