import React, { useState } from "react";
import "../../styles/profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import {
    AdminChangePasswordPassword
} from "../../actions/adminAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Functional component for the Home page
const ChangePassword = () => {
  const [disable, setDisable] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old Password is required"),
    new_password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*]/,
        "Password must contain at least one special character"
      ),
    password_confirm: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password")], "Passwords must match"),
  });

  const initialValues = {
    oldPassword: "",
    new_password: "",
    password_confirm: "",
  };

  const handleSubmit = (values) => {
    setDisable(true);
    const payload = {
      oldPassword: values.oldPassword,
      newPassword: values.new_password,
    };
    dispatch(AdminChangePasswordPassword(payload)).then((response) => {
      setDisable(false);
    });
  };
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ resetForm }) => (
              <>
                <Form>
                  <h5 className="mb-4 main-heading-title">Change Password</h5>
                  <div
                    className="card redeam_bottom_card"
                    style={{
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  >
                    <div className="row form_body justify-content-center">
                      <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                        <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                          <div className="col-lg-5 col-md-6 col-sm-5">
                            <label
                              htmlFor="oldPassword"
                              className="form-label"
                            >
                              Old Password
                            </label>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <Field
                                type={showOldPassword ? "text" : "password"}
                                className="form-control"
                                id="oldPassword"
                                name="oldPassword"
                                placeholder="Enter Password"
                              />
                              <span
                                className="position-absolute"
                                onClick={() =>
                                  setShowOldPassword(!showOldPassword)
                                }
                                style={{
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={showOldPassword ? faEyeSlash : faEye}
                                />
                              </span>
                            </div>
                            <div>
                              <ErrorMessage
                                name="oldPassword"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        {/* 
                        <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                          <div className="col-lg-5 col-md-6 col-sm-5">
                            <label
                              htmlFor="password_confirm"
                              className="form-label"
                            >
                              Confirm Password
                            </label>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                            <Field
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control"
                              id="password_confirm"
                              name="password_confirm"
                              placeholder="Re-enter Password"
                            />
                            <ErrorMessage
                              name="password_confirm"
                              component="div"
                              className="text-danger"
                            />
                            <span
                              className="password-toggle-icon"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={showConfirmPassword ? faEyeSlash : faEye}
                              />
                            </span>
                          </div>
                        </div> */}

                        {/* Password Field */}
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-5 col-md-6 col-sm-5">
                            <label
                              htmlFor="new_password"
                              className="form-label"
                            >
                              New Password
                            </label>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <Field
                                type={showNewPassword ? "text" : "password"}
                                className="form-control"
                                id="new_password"
                                name="new_password"
                                placeholder="Enter Password"
                              />
                              <span
                                className="position-absolute"
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                                style={{
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={showNewPassword ? faEyeSlash : faEye}
                                />
                              </span>
                            </div>
                            <div>
                              <ErrorMessage
                                name="new_password"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Confirm Password Field */}
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-5 col-md-6 col-sm-5">
                            <label
                              htmlFor="password_confirm"
                              className="form-label"
                            >
                              Confirm New Password
                            </label>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <Field
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control"
                                id="password_confirm"
                                name="password_confirm"
                                placeholder="Re-enter Password"
                              />
                              <span
                                className="position-absolute"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                style={{
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    showConfirmPassword ? faEyeSlash : faEye
                                  }
                                />
                              </span>
                            </div>
                            <div>
                              <ErrorMessage
                                name="password_confirm"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        {/* Submit Button */}
                        {/* <div className="row align-items-center mt-4">
                          <div className="col-lg-12 text-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer form_card form_card_border">
                    <div className="d-flex justify-content-between gap-2">
                      <button
                        type="button"
                        onClick={() => {
                          resetForm();
                          window.location.href = "/rm/dashboard";
                        }}
                        className="form_button "
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="form_button next_btn"
                        disabled={disable}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
