import React from 'react';
import '../styles/ticketTable.css';
// Functional component for the Home page
const TicketTable = ({ tickets }) => {
    return (
        
                    <div className="table-responsive">
                                <table className="table tickets_Messages_table">
                                    <thead> 
                                        <tr className="ticket_table_head">
                                            <th scope="col">Ticket No.</th>
                                            <th scope="col">Message Type</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets && tickets.length > 0 ? (
                                            tickets.map((ticket) => 
                                                // Map over the messages for each ticket
                                                ticket.message.map((msg) => (
                                                    <tr className="ticket_table" key={msg.id}>
                                                        <td>{msg.id}</td>  
                                                        <td>{msg.type}</td>  
                                                        <td>{msg.subject}</td>  
                                                        <td>{msg.message_text}</td>  
                                                        <td>{msg.status === 0 ? 'Open' : 'Closed'}</td>  
                                                    </tr>
                                                ))
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No tickets found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                           
                        
                    </div>

        
    );
};

export default TicketTable;