import { useState, useEffect, useRef } from "react";
import Pencil from "../../images/icons/Pencil.svg";
import View from "../../images/icons/view-icon.svg";
import { useNavigate } from "react-router-dom";

const Investor = ({ data, currentPage }) => {
  const getChipStyles = (statusClass, statusValue) => {
    if (statusClass === "N") {
      return {
        backgroundColor: "#fedfbe",
        color: "#f48109",
        fontWeight: "600",
      }; // Yellow background for 'Inactive'
    } else {
      return statusClass === "Y"
        ? { backgroundColor: "#aafec0", color: "#28ae70", fontWeight: "600" }
        : { backgroundColor: "#ffe6e6", color: "#dc3545", fontWeight: "600" };
    }
  };
  const navigate = useNavigate();

  const handleNavigation = (data) => {
    navigate("/rm/viewinvestor", { state: data });
  };

  const handleEditNavigation = () => {
    navigate("/admin/investeredit");
  };

  const Goviewnachdetails = () => {
    navigate("/admin/viewnachdetails");
  };

  const GoMappingdetails = () => {
    navigate("/admin/viewmaping");
  };

  const Goinvestorstatus = () => {
    navigate("/admin/investorstatus");
  };

  const GoIINStatus = () => {
    navigate("/admin/iinstatus");
  };

  return (
    <div className="table-container table-responsive">
      <table className="table_transaction">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>UCID</th>
            <th>FATCA Status</th>
            <th>KYC Status</th>
            <th>IIN Status</th>
            <th>Added Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((investor, index) => (
            <tr
              key={index}
              className={`${
                investor?.investor?.trxn_status?.toLowerCase() ||
                investor?.status?.toLowerCase()
              } transaction_row`}
            >
              <td>{(currentPage - 1) * 10 + index + 1}</td>
              <td className="white-space-normal">{investor?.name || "NA"}</td>
              <td>{investor?.email || "NA"}</td>
              <td>{investor?.mobile || "NA"}</td>
              <td>{investor?.profile?.customer_id || "NA"}</td>
              <td>
                <span
                  className={`investor-status investor-status-${investor.profile?.fatca_status}`}
                  style={{
                    ...getChipStyles(investor.profile?.fatca_status),
                    borderRadius: "15px",
                    padding: "5px 20px",
                    display: "inline-block",
                  }}
                >
                  {investor.profile?.fatca_status === "Y"
                    ? "Active"
                    : "Inactive"}{" "}
                </span>
              </td>

              <td>
                {investor?.profile?.kyc_status && (
                  <span
                    className={`investor-status investor-status-${investor.profile?.kyc_status}`}
                    style={{
                      ...getChipStyles(investor.profile?.kyc_status),
                      borderRadius: "15px",
                      padding: "5px 20px",
                      display: "inline-block",
                    }}
                  >
                    {investor.profile?.kyc_status === "Y"
                      ? "Active"
                      : "Inactive"}{" "}
                  </span>
                )}
              </td>
              <td>
                {investor?.profile?.iin_status && (
                  <span
                    className={`investor-status investor-status-${investor?.profile?.iin_status}`}
                    style={{
                      ...getChipStyles(
                        investor?.profile?.iin_status === "Y" ? "Y" : "N"
                      ),
                      borderRadius: "15px",
                      padding: "5px 20px",
                      display: "inline-block",
                    }}
                  >
                    {investor?.profile?.iin_status === "Y" ? "Active" : "Inactive"}
                  </span>
                )}
              </td>
              <td>
                {investor?.created_at
                  ? new Date(investor.created_at).toLocaleDateString("en-GB")
                  : "NA"}
              </td>
              <td>
                <div
                  className="goals-view"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="View"
                  onClick={() =>
                    handleNavigation(investor?.profile?.id)
                  }                
                  >
                  <img
                    src={View}
                    alt="View"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="View"
                    // url="/rm/viewinvestor"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Investor;
