import axios from "axios";
import { GET_FAQS, TERMS_CONDITIONS } from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const getFaqs = (payload) => {
    return async (dispatch) => {
      try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}faq`,payload,{
          headers:{
              Authorization: token
          }
        });
             
        dispatch({ type: GET_FAQS, payload: response.data }); // Ensure `response.data` has the correct format
      } catch (error) {
        console.error("Error fetching FAQ's data", error.message);
      }
    };
  };
export const termAndConditions = (payload) => {
    return async (dispatch) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}tnc`);

        dispatch({ type: TERMS_CONDITIONS, payload: response.data }); // Ensure `response.data` has the correct format
      } catch (error) {
        console.error("Error fetching Terms & conditions", error.message);
      }
    };
  };