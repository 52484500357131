import React from "react";
import AdminTicketTable from "../../components/Admin/AdminTicketTable";

const AdminSupport = () => {
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box ">
          <div className="d-flex gap-3 flex-wrap justify-content-between align-itmes-center mb-4 ">
            <h5 className="mb-0 main-heading-title">Tickets & Messages</h5>
          </div>
          <div className="ticket_card_inner page_content_overflow  tickets_Messages">
            <div className="mb-3">
              <AdminTicketTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSupport;
