const IconContentBox = (props) => {
    return (
        <div className="d-flex gap-md-4 gap-sm-3 gap-2 value-item">
            <div className="value-icon me-2">
                <span className="">
                    <img src={props.icone} alt="" className="value-icon-img"/>
                </span>
            </div>
            <div className="value-description-box">
                <h3 className="value-title mb-3">{props.title}</h3>
                <p className="value-description">
                    {props.content}
                </p>
            </div>
        </div>
    );
};

export default IconContentBox;
  