import axios from "axios";
import { GET_MESSAGE, MESSAGE_SEND, MESSAGE_TYPE } from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const messageType = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}message/new`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: MESSAGE_TYPE, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      console.error("Error fetching message types:", error.message);
    }
  };
};
export const sendMessage = (payload, getState) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}message/send`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: MESSAGE_SEND, payload: response.data });
      toast.success("Message sent successfully!");

      dispatch({ type: GET_MESSAGE, payload: response.data });
    } catch (error) {
      console.error("Error fetching message types:", error.message);
      toast.error("Failed to send message!");
    }
  };
};
export const getMessage = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}message`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: GET_MESSAGE, payload: response.data });
    } catch (error) {
      console.error("Error fetching message types:", error.message);
    }
  };
};
