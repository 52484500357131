import React, { useState } from "react";


import "../styles/SelectDropDown.css";
import searchDropDown from "../images/downloads/Drop Down Active.svg";

const SelectDropDown = (props) => {

  const [isDefaultSelected, setIsDefaultSelected] = useState(true); // Tracks if "Select" is chosen


  return (
    <div className="position-relative">
      <select
        name={props.name}
        id={props.id}
        className={`form-select custom-select2 ${props.error && props.touched ? "is-invalid" : ""
          }`}
        value={props.value}
        // onChange={(e) => props.changeFunction(e.target.value)}
        onChange={(e) => {
          const selectedValue = e.target.value;
          setIsDefaultSelected(selectedValue === "");
          props.changeFunction(selectedValue);
        }}
        onBlur={props.onBlur}
        disabled={props.disabled}
        style={{
          color: isDefaultSelected ? "#a9a9a9" : "#2C2D3A",
        }}
      >
        <option value="" >Select</option>
        {props?.options !== "" &&
          props?.options?.map((entry, index) => (
            <option key={index} value={entry.value}>
              {entry.label}
            </option>
          ))}
      </select>
      {props.error && props.touched && (
        <div className="invalid-feedback">{props.error}</div>
      )}
    </div>
  );
};

export default SelectDropDown;
