import React, { useEffect } from "react";
import SelectDropDown from "../SelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import { InvestorAddFormData } from "../../actions/adminAction";
import DatePickerInput from "../DatePickerInput";

export default function StaticNomineeForm({ formik, nomineeCount, setNomineeCount }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(InvestorAddFormData());
  }, [dispatch]);

  const userOnboardGetData = useSelector((state) => state.admin.adminAddInvestorData) || {};
  console.log(nomineeCount,"nomineeCount");
  
  const addNominee = () => {
    if (nomineeCount < 3) {
      setNomineeCount(nomineeCount + 1);
    }
  };

  const deleteNominee = () => {
    if (nomineeCount > 1) {
      setNomineeCount(nomineeCount - 1);
    }
  };
  console.log(formik,"formik");
  
  return (
    <div className="row form_body justify-content-center">
      <div className="col-xl-7 col-lg-10 col-md-10 my-2">
        <form id="manage_nominee">
          {[...Array(nomineeCount)].map((_, index) => {
            const i = index + 1;
            const nameKey = `nominee${i}_name`;
            const relationKey = `nominee${i}_relation`;
            const dobKey = `nominee${i}_dob`;
            const percentKey = `nominee${i}_percent`;
            const panKey = `nom${i}_pan`;
            const guardianNameKey = `nominee${i}_guard_name`;
            const guardianPanKey = `nominee${i}_guard_pan`;

            const dob = new Date(formik.values[dobKey]);
            const age = isNaN(dob.getTime()) ? null : new Date().getFullYear() - dob.getFullYear();

            return (
              <div key={i}>
                <h5 className="nominee_title">{i} Nominee:</h5>

                {/* Nominee Name */}
                <div className="row mb-4">
                  <div className="col-lg-5 col-md-6">
                    <label htmlFor={nameKey} className="form-label">Nominee Name *</label>
                  </div>
                  <div className="col-lg-7 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id={nameKey}
                      name={nameKey}
                      placeholder="Enter Nominee Name"
                      value={formik.values[nameKey] || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched[nameKey] && formik.errors[nameKey] && (
                      <div className="text-danger">{formik.errors[nameKey]}</div>
                    )}
                  </div>
                </div>

                {/* Relation */}
                <div className="row mb-4">
                  <div className="col-lg-5 col-md-6">
                    <label htmlFor={relationKey} className="form-label">Relation *</label>
                  </div>
                  <div className="col-lg-7 col-md-6">
                    <SelectDropDown
                      name={relationKey}
                      id={relationKey}
                      value={formik.values[relationKey] || ""}
                      changeFunction={(value) => formik.setFieldValue(relationKey, value)}
                      options={userOnboardGetData?.data?.relations?.map(
                        (relation) => ({
                          value: relation.value,
                          label: relation.label,
                        })
                      )}
                    />
                    {formik.touched[relationKey] && formik.errors[relationKey] && (
                      <div className="text-danger">{formik.errors[relationKey]}</div>
                    )}
                  </div>
                </div>

                {/* Date of Birth */}
                <div className="row mb-4">
                  <div className="col-lg-5 col-md-6">
                    <label htmlFor={dobKey} className="form-label">Date of Birth *</label>
                  </div>
                  <div className="col-lg-7 col-md-6">
                    <DatePickerInput
                      selectedDate={formik.values[dobKey] || ""}
                      onChange={(value) => formik.setFieldValue(dobKey, value)}
                      minDate="1900-01-01"
                      maxDate={new Date().toISOString().split("T")[0]}
                    />
                    {formik.touched[dobKey] && formik.errors[dobKey] && (
                      <div className="text-danger">{formik.errors[dobKey]}</div>
                    )}
                  </div>
                </div>

                {/* Nominee % */}
                <div className="row mb-4">
                  <div className="col-lg-5 col-md-6">
                    <label htmlFor={percentKey} className="form-label">Nominee % *</label>
                  </div>
                  <div className="col-lg-7 col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      id={percentKey}
                      name={percentKey}
                      placeholder="Enter Nominee %"
                      value={formik.values[percentKey] || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched[percentKey] && formik.errors[percentKey] && (
                      <div className="text-danger">{formik.errors[percentKey]}</div>
                    )}
                  </div>
                </div>

                {/* PAN or Guardian Details */}
                {age >= 18 || age === null ? (
                  <div className="row mb-4">
                    <div className="col-lg-5 col-md-6">
                      <label htmlFor={panKey} className="form-label">Enter PAN Number *</label>
                    </div>
                    <div className="col-lg-7 col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        id={panKey}
                        name={panKey}
                        placeholder="Enter PAN Number"
                        value={formik.values[panKey] || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched[panKey] && formik.errors[panKey] && (
                        <div className="text-danger">{formik.errors[panKey]}</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor={guardianNameKey} className="form-label">Guardian Name *</label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id={guardianNameKey}
                          name={guardianNameKey}
                          placeholder="Guardian Name"
                          value={formik.values[guardianNameKey] || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched[guardianNameKey] && formik.errors[guardianNameKey] && (
                          <div className="text-danger">{formik.errors[guardianNameKey]}</div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* Delete Button */}
                {nomineeCount > 1 && (
                  <button type="button" className="delete_button text-decoration-none" onClick={deleteNominee}>
                    <span>-</span> Delete nominee
                  </button>
                )}
              </div>
            );
          })}

          {/* Add More Button */}
          {nomineeCount < 3 && (
            <button type="button" className="more_button text-decoration-none" onClick={addNominee}>
              <span>+</span> Add More
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
