import React, { useEffect, useState } from "react";
import "../styles/profile.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { faqDetail, faqAdd, faqEdit } from "../actions/adminAction";
import { FAQ_DETAIL_RESET} from "../actions/types";
import { toast } from "react-toastify";

// Functional component for the Home page
const FaqAdd = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const id = location.state; // Get FAQ id from route params if available

  const [initialValues, setInitialValues] = useState({
    question: "",
    answer: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(faqDetail(id));
    } else {
      setInitialValues({ question: "", answer: "" }); // Reset values for Add mode
    }
    return () => {
        dispatch({ type: FAQ_DETAIL_RESET }); // Dispatch an action to reset FAQ detail data
      };
  }, [dispatch, id]);

  const { faqDetailData = {} } = useSelector((state) => state?.admin);
  const data = faqDetailData?.data || {};
  
  useEffect(() => {
    if (data) {
      setInitialValues({
        question: data.question || "",
        answer: data.answer || "",
      });
    }
  }, [data, id]);

  const validationSchema = Yup.object({
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (id) {
        await dispatch(faqEdit(values, id)); // Edit API call
        toast.success("FAQ updated successfully!");
      } else {
        await dispatch(faqAdd(values)); // Add API call
        toast.success("FAQ added successfully!");
      }
      setInitialValues({ question: "", answer: "" }); // Reset state
      navigate(-1); // Navigate back after submission
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
    setSubmitting(false);
  };

  const handleCancel = () => {
    navigate(-1); // Go to the previous page
  };

  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <>
              <Form>
                <h5 className="mb-4 main-heading-title">
                  {id ? "Edit FAQ" : "Add FAQ"}
                </h5>
                <div
                  className="card redeam_bottom_card"
                  style={{
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  <div className="row form_body justify-content-center">
                    <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                      <div className="row  mb-2">
                        <div className="col-lg-5 col-md-6 col-sm-5">
                          <label htmlFor="otp" className="form-label">
                            Question
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-7">
                          <Field
                            as="textarea" // Use textarea instead of text input
                            rows="3" // Define the height of the textarea
                            className="form-control"
                            id="question"
                            name="question"
                            placeholder="Enter Question"
                          />
                          <ErrorMessage
                            name="question"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="row  mb-2">
                        <div className="col-lg-5 col-md-6 col-sm-5">
                          <label htmlFor="otp" className="form-label">
                            Answer
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-7">
                          <Field
                            as="textarea"
                            rows="10"
                            type="text"
                            className="form-control"
                            id="answer"
                            name="answer"
                            placeholder="Enter Answer"
                            style={{ height: "auto" }}
                          />
                          <ErrorMessage
                            name="answer"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer form_card form_card_border">
                  <div className="d-flex justify-content-between gap-2">
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="form_button "
                    >
                      Cancel
                    </button>
                    <button type="submit" className="form_button next_btn">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FaqAdd;
