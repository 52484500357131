import React, { useEffect, useState } from "react";
import "../../styles/redeem.css";
import "../../styles/SelectDropDown.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import SelectDropDown from "../../components/SelectDropDown";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getAmcListAdmin,
  getCapGainLossReportAdmin,
  getDateWiseReportAdmin,
  getProductListAdmin,
  getSchemeWiseReportAdmin,
  getTypeWiseReportAdmin,
  getCustomerListAdmin,
} from "../../actions/reportAction";
import { getLocalStorageUserData } from "../../utils/userData";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader-ui";
import DatePickerInput from "../../components/DatePickerInput";
import InvestButton from "../../components/InvestButton";
// Functional component for the Home page
const AdminReports = () => {
  const dispatch = useDispatch();
  const datewise = useSelector((state) => state.report);
  const navigate = useNavigate();
  const userData = getLocalStorageUserData();
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState("screen");
  const [showDateFields, setShowDateFields] = useState(false);
  const [selectedAmc, setSelectedAmc] = useState("");
  const [loading, setLoading] = useState(false);
  const [showFields, setShowFields] = useState(true);
  const [customer, setCustomer] = useState("");
  const [isDefaultSelected, setIsDefaultSelected] = useState(true); // Tracks if "Select" is chosen
  const { customerList = [] } = useSelector((state) => state?.report);

  const reportTypeOptions = [
    { value: "datewise", label: "Transaction Date Wise" },
    { value: "schemewise", label: "Scheme Wise" },
    { value: "typeWise", label: "Type Wise" },
    { value: "capGainLoss", label: "Capital Gain Loss" },
  ];

  const typeOptions = [
    { value: "All", label: "All" },
    { value: "Equity", label: "Equity" },
    { value: "Debt", label: "Debt" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "ELSS", label: "ELSS" },
    { value: "Others", label: "Others" },
  ];
  const transactionPeriodOptions = [
    { value: "all", label: "All" },
    { value: "customized", label: "Customized" },
  ];
  const transactionTypeOptions = [
    { value: "all", label: "All" },
    { value: "SIP", label: "SIP" },
    { value: "Non SIP", label: "Non SIP" },
  ];

  const getFinancialPeriods = () => {
    const today = new Date();
    const currentYear =
      today.getMonth() >= 3 ? today.getFullYear() : today.getFullYear() - 1;
    const periods = [];

    // Generate periods dynamically from the current year back to 2021
    for (let i = 0; i <= currentYear - 2021; i++) {
      const startYear = currentYear - i;
      const endYear = startYear + 1;

      let label;
      if (i === 0) {
        label = `Current Financial Year ${startYear} - ${endYear}`;
      } else if (i === 1) {
        label = `Previous Financial Year ${startYear} - ${endYear}`;
      } else {
        label = `${i}nd Last Financial Year ${startYear} - ${endYear}`;
      }

    //   periods.push({
    //     label: label,
    //     value: i === 0 ? "CFY" : i === 1 ? "PFY" : `${i}LFY`,
    //   });
    periods.push({
        label: label,
        value: i === 0 ? "CFY" : i === 1 ? "PFY" : i === 2 ? "SLFY" : i === 3 ? "TLFY" : ""
      });
    }
    const handleButtonClick = (response) => {
      const fileUrl = response?.data?.report_url;

      if (fileUrl) {
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = "";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } else {
        console.error("File URL not found in the response");
      }
      toast.success(response?.message);
    };
    const handleAllChange = (e) => {
      if (e.target.checked) {
        // Select all checkboxes
        setSelectedFields([
          "NAV_date",
          "sub_type",
          "trn_days",
          "abs_return",
          "annualized_return",
        ]);
      } else {
        // Deselect all checkboxes
        setSelectedFields([]);
      }
    };

    return periods;
  };
  const defaultFields = [
    "NAV_date",
    "sub_type",
    "trn_days",
    "abs_return",
    "annualized_return",
  ];
  const fieldsToPass =
    selectedFields.length === 0 ? defaultFields : selectedFields;
  const financialPeriods = getFinancialPeriods();

  const fetchInvestorList = async () => {
    try {
      // setLoading(true);
      await dispatch(getCustomerListAdmin());
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvestorList();
  }, [dispatch]);

  const handleRadioChange = (e) => {
    setSelectedFormat(e.target.value);
  };

  const handleTransactionPeriodChange = (value) => {
    formik.setFieldValue("transactionPeriod", value);
    setShowDateFields(value === "customized");
    if (value !== "customized") {
      formik.setFieldValue("fromDate", "");
      formik.setFieldValue("toDate", "");
    }
  };
  const resetFields = () => {
    setCustomer("");
    setSelectedFields([]);
    setSelectedFormat("screen");
    formik.resetForm();
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
  };

  const handleAmcChange = (value) => {
    setSelectedAmc(value); // Set the selected AMC
    formik.setFieldValue("amc", value); // Update Formik's field value
  };

  const formik = useFormik({
    initialValues: {
      reportType: "",
      transactionPeriod: "",
      transactionType: "All",
      fromDate: "",
      toDate: "",
    },

    validationSchema: Yup.object({
      reportType: Yup.string()
        .notOneOf(["Select"], "Report Type is required")
        .required("Report Type is required"),
      transactionPeriod: Yup.string()
        .notOneOf(["Select"], "Transaction Period is required")
        .required("Transaction Period is required"),
      transactionType: Yup.string()
        .notOneOf(["Select"], "Transaction Type is required")
        .required("Transaction Type is required"),
      fromDate: Yup.string().when("transactionPeriod", {
        is: "customized",
        then: (schema) => schema.required("From Date is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      toDate: Yup.string().when("transactionPeriod", {
        is: "customized",
        then: (schema) => schema.required("To Date is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: (values) => {
      const payload = {
        user_id: customer,
        report_type: values?.reportType,
        fields:
          selectedFields.length === 0
            ? [
                "NAV_date",
                "sub_type",
                "trn_days",
                "abs_return",
                "annualized_return",
              ]
            : selectedFields,
        formatType: selectedFormat || "",
        check_all:
          selectedFields.length === 0 || selectedFields.length === 5
            ? "check_all"
            : "",
        type: values?.type || "",
        from_date: formik.values?.fromDate || "",
        to_date: formik.values?.toDate || "",
        fund_trxn_type: formik.values?.transactionType || "",
      };
      if (values.reportType === "schemewise") {
        payload.amc = values?.amc;
        payload.product = values?.product;
      }
      if (values.reportType === "capGainLoss") {
        payload.transaction_period = values?.transactionPeriod;
      }
      setLoading(true);
      if (values?.reportType === "datewise") {
        dispatch(getDateWiseReportAdmin(payload)).then((response) => {
          if (response?.status === true) {
            if (selectedFormat === "email") {
              toast.success(response?.message);
            } else {
              handleButtonClick(response);
            }
            resetFields();
            if (selectedFormat === "screen") {
              // Navigate after file download only if format is selected
              navigate("/admin/reports-list", {
                state: {
                  apiResponse: response,
                  selectedFields: fieldsToPass,
                },
              });
            }
            setLoading(false);
            setShowFields(true);
          } else {
            toast.error(response?.message);
            setLoading(false);
          }
        });
      } else if (values.reportType === "typeWise") {
        dispatch(getTypeWiseReportAdmin(payload)).then((response) => {
          if (response?.status === true) {
          if (selectedFormat === "email") {
            toast.success(response?.message);
          } else {
            handleButtonClick(response);
          }
          resetFields();
          if (selectedFormat === "screen") {
            // Navigate after file download only if format is selected
            navigate("/admin/reports-list", {
              state: { apiResponse: response, selectedFields: fieldsToPass},
            });
          }
          setLoading(false);
          setShowFields(true);
        } else {
          toast.error(response?.message);
          setLoading(false);
        }
        });
      } else if (values.reportType === "schemewise") {
        dispatch(getSchemeWiseReportAdmin(payload)).then((response) => {
          if (response?.status === true) {
          if (selectedFormat === "email") {
            toast.success(response?.message);
          } else {
            handleButtonClick(response);
          }
          resetFields();
          if (selectedFormat === "screen") {
            // Navigate after file download only if format is selected
            navigate("/admin/reports-list", {
              state: { apiResponse: response, selectedFields: fieldsToPass},
            });
          }
          setLoading(false);
          setShowFields(true);
        } else {
          toast.error(response?.message);
          setLoading(false);
        }
        });
      } else if (values.reportType === "capGainLoss") {
        dispatch(getCapGainLossReportAdmin(payload)).then((response) => {
          if (response?.status === true) {
          if (selectedFormat === "email") {
            toast.success(response?.message);
          } else {
            handleButtonClick(response);
          }
          resetFields();
          if (selectedFormat === "screen") {
            // Navigate after file download only if format is selected
            navigate("/admin/reports-list", {
              state: { apiResponse: response, selectedFields: fieldsToPass},
            });
          }
          setLoading(false);
          setShowFields(true);
        } else {
          toast.error(response?.message);
          setLoading(false);
        }
        });
      }
    },
  });

  console.log(formik.values.reportType,"formik.values.reportType");

  useEffect(() => {
    if (customer) {
      const fetchData = async () => {
        const payload = { id: customer };
        try {
          await dispatch(getAmcListAdmin(payload));
        } catch (error) {
          console.error("API call failed: ", error);
        }
      };

      fetchData();
    }
  }, [dispatch, customer, formik.values.reportType]); // Added formik.values.reportType

  useEffect(() => {
    if (customer) {
      const fetchData = async () => {
        const payload = {
          id: customer,
          amc_code: selectedAmc || "",
        };
        try {
          await dispatch(getProductListAdmin(payload));
        } catch (error) {
          console.error("API call failed: ", error);
        }
      };

      fetchData();
    }
  }, [dispatch, customer, selectedAmc, formik.values.reportType]); // Added formik.values.reportType

  const amcList = datewise?.amcListAdmin || [];
  const productList = datewise?.productListAdmin || [];

  const handleButtonClick = (response) => {
    const fileUrl = response?.data?.report_url;

    if (fileUrl) {
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = "";
      anchor.target = "_blank";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else {
      console.error("File URL not found in the response");
    }
    toast.success(response?.message);
    // navigate('/admin/reports-list', { state: { apiResponse: response } });
  };
  const handleAllChange = () => {
    if (selectedFields.length === 5) {
      setSelectedFields([]);
      setShowFields(true);
    } else {
      setSelectedFields([
        "NAV_date",
        "sub_type",
        "trn_days",
        "abs_return",
        "annualized_return",
      ]);
      setShowFields(false);
    }
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setSelectedFields((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
    if (selectedFields.includes(value)) {
      setSelectedFields(selectedFields.filter((field) => field !== value));
    } else {
      setSelectedFields([...selectedFields, value]);
    }
  };

  return (
    <div className="container-fluid page_background ">
      {loading ? (
        <Loader />
      ) : (
        <div className="page-inner-content mb-3">
          <div className="page_inner_content_box">
            <div className="d-flex justify-content-between mb-3 align-items-center">
              <h5 className=" main-heading-title">
                {/* <Link className="back_icon" to={"/dashboard"}>
                                <FontAwesomeIcon icon={faAngleLeft} className="color me-3" />
                            </Link> */}
                Reports
              </h5>
              {/* <div className='d-flex justify-content-center align-items-center gap-3'>
                                <div>
                                    <InvestButton label={
                                        <>
                                            View Summary Report
                                        </>
                                    } url='/admin/summary-report' color='blue' className='' state={{ from: "goals" }} />
                                </div>
                            </div> */}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div
                className="card redeam_bottom_card "
                style={{
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                <div className="row form_body justify-content-center">
                  <div className="col-xxl-7 col-xl-8 col-lg-10 col-md-10 my-2">
                    {/* Customer Dropdown */}
                    <div className="row align-items-center mb-3">
                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="user_id" className="form-label">
                          Customer <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-lg-8">
                        {/* <SelectDropDown
                          name="user_id"
                          id="user_id"
                          value={customer || ""}
                          changeFunction={(e) => setCustomer(e.target.value)}
                          options={customerList.map((customer) => ({
                            value: customer.user_id,
                            label: customer.inv_name,
                          }))}
                        /> */}
                        <div className="position-relative">
                          <select
                            name="user_id"
                            id="user_id"
                            className="form-select custom-select2 "
                            value={customer}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(e) => setCustomer(e.target.value)}
                            // style={{
                            //     color: isDefaultSelected ? "#a9a9a9" : "#2C2D3A",
                            //   }}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            {/* Dynamically generate dropdown options */}
                            {customerList.map((investor) => (
                              <option
                                key={investor?.user_id}
                                value={investor?.user_id}
                              >
                                {investor?.inv_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* Report Type Dropdown */}
                    <div className="row align-items-center mb-3">
                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="reportType" className="form-label">
                          Report Type <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <SelectDropDown
                          name="reportType"
                          id="reportType"
                          value={formik.values.reportType}
                          changeFunction={(value) => {
                            formik.setFieldValue("reportType", value);
                            if (value !== "capGainLoss") {
                              formik.setFieldValue("transactionPeriod", "");
                            }
                          }}
                          options={reportTypeOptions}
                          onBlur={formik.handleBlur}
                          error={formik.errors.reportType}
                          touched={formik.touched.reportType}
                        />
                      </div>
                    </div>
                    {formik.values.reportType === "schemewise" && (
                      <>
                        <div className="row align-items-center mb-3">
                          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                            <label htmlFor="amc" className="form-label">
                              AMC
                            </label>
                          </div>
                          {console.log(amcList,"amcList")}
                          <div className="col-lg-8">
                            <SelectDropDown
                              name="amc"
                              id="amc"
                              value={formik.values.amc}
                              changeFunction={handleAmcChange}
                              options={amcList.map((amc) => ({
                                value: amc.amc_code,
                                label: amc.long_name,
                              }))}
                              onBlur={formik.handleBlur}
                              error={formik.errors.amc}
                              touched={formik.touched.amc}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center mb-3">
                          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                            <label htmlFor="product" className="form-label">
                              Product
                            </label>
                          </div>
                          <div className="col-lg-8">
                            <SelectDropDown
                              name="product"
                              id="product"
                              value={formik.values.product}
                              changeFunction={(value) =>
                                formik.setFieldValue("product", value)
                              }
                              options={productList.map((product) => ({
                                value: product.amc_product_id,
                                label: product.product_long_name,
                              }))}
                              onBlur={formik.handleBlur}
                              error={formik.errors.product}
                              touched={formik.touched.product}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {formik.values.reportType === "typeWise" && (
                      <div className="row align-items-center mb-3">
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                          <label htmlFor="type" className="form-label">
                            Type
                          </label>
                        </div>
                        <div className="col-lg-8">
                          <SelectDropDown
                            name="type"
                            id="type"
                            value={formik.values.type}
                            changeFunction={(value) =>
                              formik.setFieldValue("type", value)
                            }
                            options={typeOptions}
                            onBlur={formik.handleBlur}
                            error={formik.errors.type}
                            touched={formik.touched.type}
                          />
                        </div>
                      </div>
                    )}
                    {/* Transaction Period Dropdown */}
                    <div className="row align-items-center mb-3">
                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                        <label
                          htmlFor="transactionPeriod"
                          className="form-label"
                        >
                          Transaction Period{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <SelectDropDown
                          name="transactionPeriod"
                          id="transactionPeriod"
                          value={formik.values.transactionPeriod}
                          changeFunction={handleTransactionPeriodChange}
                          options={
                            formik.values.reportType === "capGainLoss"
                              ? financialPeriods // Show financial periods for capGainLoss
                              : transactionPeriodOptions // Show default transaction periods
                          }
                          onBlur={formik.handleBlur}
                          error={formik.errors.transactionPeriod}
                          touched={formik.touched.transactionPeriod}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mb-3">
                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="transactionType" className="form-label">
                          Transaction Type{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                      </div>
                      <div className="col-lg-8">
                        <SelectDropDown
                          name="transactionType"
                          id="transactionType"
                          value={formik.values.transactionType}
                          changeFunction={(value) => {
                            formik.setFieldValue("transactionType", value);
                          }}
                          options={transactionTypeOptions}
                          // onBlur={formik.handleBlur}
                          // error={formik.errors.transactionType}
                          // touched={formik.touched.transactionType}
                        />
                      </div>
                    </div>

                    {showDateFields && (
                      <>
                        <div className="row align-items-center mb-3">
                          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                            <label htmlFor="fromDate" className="form-label">
                              From <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <div className="col-lg-8">
                            {/* <input
                                                            type="date"
                                                            className="form-control"
                                                            id="fromDate"
                                                            name="fromDate"
                                                            value={formik.values.fromDate}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        /> */}
                            <DatePickerInput
                              selectedDate={formik.values.fromDate}
                              onChange={(date) =>
                                formik.setFieldValue("fromDate", date)
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.errors?.fromDate &&
                              formik.touched?.fromDate && (
                                <span className="text-danger">
                                  {formik.errors?.fromDate}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="row align-items-center mb-3">
                          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                            <label htmlFor="toDate" className="form-label">
                              To <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                          <div className="col-lg-8">
                            {/* <input
                                type="date"
                                className="form-control"
                                id="toDate"
                                name="toDate"
                                value={formik.values.toDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              /> */}
                            <DatePickerInput
                              selectedDate={formik.values.toDate}
                              onChange={(date) =>
                                formik.setFieldValue("toDate", date)
                              }
                              onBlur={formik.handleBlur}
                              minDate={formik.values.fromDate}
                            />
                            {formik.errors?.toDate &&
                              formik.touched?.toDate && (
                                <span className="text-danger">
                                  {formik.errors.toDate}
                                </span>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                    {formik.values.reportType !== "capGainLoss" && (
                      <>
                        <div className="row  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                            <label className="form-label">Fields</label>
                          </div>
                          <div className="col-xl-8 col-lg-7 col-md-6 col-sm-7">
                            <div className="d-flex flex-wrap align-items-center row-gap-2">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="All"
                                  id="All"
                                  value="All"
                                  checked={selectedFields.length === 5}
                                  onChange={handleAllChange}
                                />
                                <label
                                  className="form-check-label ms-2"
                                  htmlFor="male"
                                >
                                  Check All
                                </label>
                              </div>
                              {showFields && (
                                <>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="NAV_date"
                                      id="NAV_date"
                                      value="NAV_date"
                                      checked={selectedFields.includes(
                                        "NAV_date"
                                      )}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ms-2"
                                      htmlFor="NAV_date"
                                    >
                                      NAV Date
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="sub_type"
                                      id="sub_type"
                                      value="sub_type"
                                      checked={selectedFields.includes(
                                        "sub_type"
                                      )}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ms-2"
                                      htmlFor="sub_type"
                                    >
                                      Sub Type
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="trn_days"
                                      id="trn_days"
                                      value="trn_days"
                                      checked={selectedFields.includes(
                                        "trn_days"
                                      )}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ms-2"
                                      htmlFor="trn_days"
                                    >
                                      Trn Days
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="abs_return"
                                      id="abs_return"
                                      value="abs_return"
                                      checked={selectedFields.includes(
                                        "abs_return"
                                      )}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ms-2"
                                      htmlFor="abs_return"
                                    >
                                      Abs Return
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="annualized_return"
                                      id="annualized_return"
                                      value="annualized_return"
                                      checked={selectedFields.includes(
                                        "annualized_return"
                                      )}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label ms-2"
                                      htmlFor="annualized_return"
                                    >
                                      Annualized Return
                                    </label>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row  mb-lg-4 mb-md-4 mb-sm-4">
                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-5">
                        <label className="form-label">Format</label>
                      </div>
                      <div className="col-xl-8 col-lg-7 col-md-6 col-sm-7">
                        <div class="d-flex flex-wrap align-items-center gap-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="formate"
                              id="screen"
                              value="screen"
                              checked={selectedFormat === "screen"}
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="screen"
                            >
                              Screen
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="formate"
                              id="excel"
                              value="excel"
                              checked={selectedFormat === "excel"}
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="excel"
                            >
                              Excel
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="formate"
                              id="pdf"
                              value="pdf"
                              checked={selectedFormat === "pdf"}
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="pdf"
                            >
                              PDF
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="formate"
                              id="email"
                              value="email"
                              checked={selectedFormat === "email"}
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="email"
                            >
                              Email
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer form_card form_card_border ">
                <div className="">
                  <div className="d-flex justify-content-between gap-2">
                    <button
                      type="button"
                      className="form_button"
                      onClick={resetFields}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="form_button next_btn">
                      Show
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminReports;
