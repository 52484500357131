import React from 'react';
import '../styles/FundDetailInvestmentBox.css'; // Import styles
import Gross_down from '../images/gross_down_icon.svg';

// Functional component for the funddetails page
const FundDetailHeader = () => {
    return (
        <div className="start_investment_box card-header"> 
            <div className="investment-info">
                <div className="ms-1">
                    <h6 className="investment-value-header">Invested Value</h6>
                    <span className="value-text">₹500.00 </span>
                </div>
                <div className="ms-1">
                    <h6 className="investment-value-header">Market Value</h6>
                    <span className="value-text">₹480.06</span>
                </div>
                <div className="ms-1">
                    <h6 className="investment-value-header">Gain/Loss</h6>
                    <div className="d-flex gap-3 align-items-center">
                    <span className="value-text">-₹19.94</span>
                    <img src={Gross_down} />
                    <span className="gross_percent">-3.99 %</span>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default FundDetailHeader;