import React from "react";
import "../../styles/redeem.css";
import "../../styles/report.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { getLocalStorageUserData } from "../../utils/userData";

const Reports = () => {
  const userData = getLocalStorageUserData();
  const location = useLocation();
  const { apiResponse, selectedFields } = location?.state || {};
  const responseData = apiResponse?.data?.report;
  const userDetail = apiResponse?.user;

  const transactionData = {
    fundName: "KOTAK NIFTY 50 INDEX FUND-REGULAR PLAN-GROWTH",
    transactions: [
      {
        subType: "EQUITY",
        trxnType: "Lumpsum",
        folioNo: "117111176/66",
        investment: {
          date: "13-01-23",
          units: "8.7",
          nav: "11.49",
          amount: "100",
        },
        redemption: {
          date: "06-10-23",
          units: "8.7",
          nav: "12.63",
          amount: "109.88",
        },
        navDate: "266",
        unitsLeft: "0",
        curNav: "9.8756",
        glSt: "0",
        glLt: "NA",
        annRet: "9.88",
        absRet: "13.8",
        cagr: "0",
        currVal: "0",

      },
    ],
  };

  const calculateTotals = (key) => {
    return transactionData.transactions.reduce(
      (sum, transaction) => sum + Number(transaction[key]?.amount || 0),
      0
    );
  };
  const showSubType = selectedFields?.includes("sub_type");
  const showDays = selectedFields?.includes("trn_days");
  const showAbsReturn = selectedFields?.includes("abs_return");
  const showNavDate = selectedFields?.includes("NAV_date");
  const showAnnualizedReturn = selectedFields?.includes("annualized_return");

  const isCapGainLossReport = apiResponse?.report_type === "capGainLoss";


  const grandTotalOfInvestedAmount = responseData?.grand_total_of_invested_amount || 0;
  const grandTotalOfRedeemedAmount = responseData?.grand_total_of_redeemed_amount || 0;
  const grandTotalOfCurrentValue = responseData?.grand_total_of_current_value || 0;

  const netAmount = grandTotalOfInvestedAmount - grandTotalOfRedeemedAmount

  const investedAmount = responseData?.Equity?.reduce((total, item) => {
    return total + (item?.data?.subTotalOfInvestedAmountOfScheme || 0);
  }, 0);

  const redeemedAmount = responseData?.Equity?.reduce((total, item) => {
    return total + (item?.data?.subTotalOfRedeemedAmountOfScheme || 0);
  }, 0);

  const difference = investedAmount - redeemedAmount;
  const visibleColumns = [
    !showNavDate,
    !showDays,
    !showAbsReturn,
    !showAnnualizedReturn,
  ].filter(Boolean).length;

  // Dynamic colSpan for the empty <td>
  const dynamicColSpan = 9 - visibleColumns;

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            <a className="back_icon" href="/admin/reports">
              <FontAwesomeIcon icon={faAngleLeft} className="color me-3" />
            </a>
            Reports
          </h5>
          {console.log(location?.state, "apiResponse")}
          <div className="card">
            <div className="card-body">
              <h6 className="text-center mb-4 report-heading-title">
                {apiResponse?.data?.title}
              </h6>

              <div className="customer-info row">
                <div className="col-md-4 mb-2">
                  <div className="report-sub-title-div">
                    <strong className="report-sub-title">Name:</strong>{" "}
                    {userDetail.name}
                  </div>
                  <div className="report-sub-title-div">
                    <strong className="report-sub-title">Email:</strong>{" "}
                    {userDetail.email}
                  </div>
                </div>
                <div className="col-md-4 mb-2">
                  <div className="report-sub-title-div">
                    <strong className="report-sub-title">
                      Customer No.:
                    </strong>{" "}
                    {userDetail?.customer_id}
                  </div>
                  <div className="report-sub-title-div">
                    <strong className="report-sub-title">Mobile:</strong>{" "}
                    {userDetail.mobile}
                  </div>
                </div>
                <div className="col-md-4 mb-2">
                  <div className="report-sub-title-div">
                    <strong className="report-sub-title">PAN:</strong>{" "}
                    {userDetail.pan}
                  </div>
                </div>
              </div>

              <div className="report-table">
                <div className="table-responsive">
                  {/* KOTAK NIFTY 50 INDEX FUND-REGULAR PLAN-GROWTH Table */}

                  {!isCapGainLossReport &&
                    (responseData?.transactions_data?.length > 0 ? (
                      <>
                        {responseData.transactions_data.map(
                          (data, mainIndex) => (
                            <table
                              className="table table-bordered"
                              key={mainIndex}
                            >
                              <thead>
                                <tr className="table-main-heading">
                                  <th colSpan={21}>{data?.scheme_name}</th>
                                </tr>
                                <tr className="table-header">
                                  {showSubType && <th rowSpan={2}>Sub Type</th>}
                                  <th rowSpan={2}>Trxn Type</th>
                                  <th rowSpan={2}>Folio No</th>
                                  <th colSpan={4}>Investment</th>
                                  <th colSpan={4}>Redemption</th>
                                  {showNavDate && <th rowSpan={2}>NAV Date</th>}
                                  {showDays && <th rowSpan={2}>Days</th>}
                                  <th rowSpan={2}>Units Left</th>
                                  <th rowSpan={2}>Cur Nav</th>
                                  <th rowSpan={2}>GL ST</th>
                                  <th rowSpan={2}>GL LT</th>
                                  {showAnnualizedReturn && <th rowSpan={2}>Annualized Return</th>}
                                  {showAbsReturn && <th rowSpan={2}>ABS Return</th>}
                                  <th rowSpan={2}>CAGR</th>
                                  <th className="no-bottom-border">
                                    <span className="custom-val-th">
                                      Curr Val
                                    </span>
                                  </th>
                                </tr>
                                <tr>
                                  <th>Date</th>
                                  <th>Units</th>
                                  <th>NAV</th>
                                  <th>Amount</th>
                                  <th>Date</th>
                                  <th>Units</th>
                                  <th>NAV</th>
                                  <th>Amount</th>
                                  <th className="no-top-border"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.transactions.map(
                                  (transaction, index) => (
                                    <tr key={index}>
                                      {showSubType && (<td>{transaction?.sub_type || "-"}</td>)}
                                      <td>{transaction?.trxn_type || "-"}</td>
                                      <td>
                                        {transaction?.folio_number || "-"}
                                      </td>
                                      <td>
                                        {transaction?.purchase_date || "-"}
                                      </td>
                                      <td>
                                        {transaction?.purchase_units?.toFixed(
                                          2
                                        ) || "-"}
                                      </td>
                                      <td>
                                        {transaction?.purchase_nav?.toFixed(
                                          2
                                        ) || "-"}
                                      </td>
                                      <td>
                                        {transaction?.purchase_amount?.toFixed(
                                          2
                                        ) || "0"}
                                      </td>
                                      <td>
                                        {transaction?.redeem_date || "-"}
                                      </td>
                                      <td>
                                        {transaction?.redeem_units || "-"}
                                      </td>
                                      <td>
                                        {transaction?.redeem_nav || "-"}
                                      </td>
                                      <td>
                                        {transaction?.redeem_amount || "0"}
                                      </td>
                                      {showNavDate && (<td>
                                        {transaction?.current_nav_date || "-"}
                                      </td>)}
                                      {showDays && (<td>{transaction?.trxn_days || "-"}</td>)}
                                      <td>
                                        {transaction?.remaining_units || "-"}
                                      </td>
                                      <td>
                                        {transaction?.current_nav || "-"}
                                      </td>
                                      <td>
                                        {transaction?.gl_st?.toFixed(2) ||
                                          "-"}
                                      </td>
                                      <td>
                                        {transaction?.gl_lt?.toFixed(2) ||
                                          "-"}
                                      </td>
                                      {showAnnualizedReturn && (<td>
                                        {transaction?.annualized_return ||
                                          "-"}
                                      </td>)}
                                      {showAbsReturn && (<td>
                                        {transaction?.absolute_return || "-"}
                                      </td>)}
                                      <td>{transaction?.cagr || "-"}</td>
                                      <td>
                                        {transaction?.current_value || "0"}
                                      </td>
                                    </tr>
                                  )
                                )}


                                <tr className="sub-total">
                                  <td
                                    colSpan={showSubType ? 6 : 5} // If showSubType is true, colSpan will be 6; otherwise, it will be 5
                                    className="sub-total-td-color"
                                  >
                                    Sub Total
                                  </td>
                                  <td className="sub-total-td-color">
                                    {data?.subTotalOfInvestedAmountOfScheme?.toFixed(2)}
                                  </td>
                                  <td colSpan={3}></td>
                                  <td className="sub-total-td-color">
                                    {data?.subTotalOfRedeemedAmountOfScheme?.toFixed(2)}
                                  </td>
                                  <td colSpan={dynamicColSpan}></td>  {/* Adjusted colSpan */}
                                  <td className="sub-total-td-color">
                                    {data?.subTotalOfCurrentValueOfScheme?.toFixed(2)}
                                  </td>
                                </tr>

                                {mainIndex + 1 === responseData?.transactions_data?.length ? (
                                  <>

                                    <tr>
                                      <td
                                        colSpan={showSubType ? 6 : 5}
                                        className="sub-total-td-color2">
                                        Grand Total
                                      </td>
                                      <td className="sub-total-td-color2">
                                        {responseData?.grand_total_of_invested_amount?.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td colSpan={3}></td>
                                      <td className="sub-total-td-color2">
                                        {responseData?.grand_total_of_redeemed_amount?.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td colSpan={dynamicColSpan}></td>
                                      <td className="sub-total-td-color2">
                                        {responseData?.grand_total_of_current_value?.toFixed(
                                          2
                                        )}
                                      </td>
                                    </tr>

                                  </>

                                ) : (

                                  <>
                                    {/* Condition is false */}


                                  </>
                                )}

                              </tbody>
                            </table>
                          )
                        )}

                        {/* <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td colSpan={6} className="sub-total-td-color2">
                                  Grand Total
                                </td>
                                <td className="sub-total-td-color2">
                                  {responseData?.grand_total_of_invested_amount?.toFixed(
                                    2
                                  )}
                                </td>
                                <td colSpan={6}></td>
                                <td className="sub-total-td-color2">
                                  {responseData?.grand_total_of_redeemed_amount?.toFixed(
                                    2
                                  )}
                                </td>
                                <td colSpan={8}></td>
                                <td className="sub-total-td-color2">
                                  {responseData?.grand_total_of_current_value?.toFixed(
                                    2
                                  )}
                                </td>
                              </tr>
                       
                            </tbody>
                          </table> */}

                      </>
                    ) : (
                      <div className="no-data-message">
                        Data not available
                      </div>
                    ))}
                </div>
              </div>
              {isCapGainLossReport && (
                <div className="report-table">
                  <div className="table-responsive">
                    {responseData?.Equity?.length > 0 ? (
                      responseData?.Equity?.map((equityData, mainIndex) => (
                        <div>
                          <table
                            className="table table-bordered"
                            key={mainIndex}
                          >
                            <thead>
                              <tr className="table-main-heading">
                                <th colSpan={20}>{equityData?.details}</th>
                              </tr>
                              <tr className="table-header">
                                <th rowSpan={2}>Tran date</th>
                                <th rowSpan={2}>Tran Type</th>
                                <th rowSpan={2}>Pur amt</th>
                                <th rowSpan={2}>Div/Rein</th>
                                <th rowSpan={2}>Pur NAV</th>
                                <th rowSpan={2}>Units</th>
                                <th rowSpan={2}>Sell type</th>
                                <th rowSpan={2}>Sell date</th>
                                <th rowSpan={2}>Sell NAV</th>
                                <th rowSpan={2}>Red amt</th>
                                <th rowSpan={2}>Cur date</th>
                                <th rowSpan={2}>Units Left</th>
                                <th rowSpan={2}>Cur NAV</th>
                                <th rowSpan={2}>Cur amt</th>
                                <th rowSpan={2}>Div amt</th>
                                <th rowSpan={2}>Days</th>
                                <th rowSpan={2}>GL ST</th>
                                <th rowSpan={2}>GL LT</th>
                                <th rowSpan={2}>Ret ABS</th>
                                <th rowSpan={2}>Ret CAGR</th>
                              </tr>
                            </thead>
                            <tbody>
                              {equityData?.data?.transactions?.map(
                                (transaction, index) => (
                                  <tr key={index}>
                                    <td>{transaction?.tran_date || "-"}</td>
                                    <td>{transaction?.tran_type || "-"}</td>
                                    <td>{transaction?.pur_amt || "-"}</td>
                                    <td>{transaction?.div_rein || "-"}</td>
                                    <td>{transaction?.pur_NAV || "-"}</td>
                                    <td>{transaction?.units || "-"}</td>
                                    <td>{transaction?.sell_type || "-"}</td>
                                    <td>{transaction?.sell_date || "-"}</td>
                                    <td>{transaction?.sell_NAV || "-"}</td>
                                    <td>{transaction?.red_amt || "-"}</td>
                                    <td>
                                      {transaction?.current_date || "-"}
                                    </td>
                                    <td>
                                      {transaction?.remaining_units || "-"}
                                    </td>
                                    <td>{transaction?.current_NAV || "-"}</td>
                                    <td>
                                      {transaction?.current_value || "-"}
                                    </td>
                                    <td>{transaction?.div_amt || "-"}</td>
                                    <td>{transaction?.days || "0"}</td>
                                    <td>{transaction?.gl_st || "-"}</td>
                                    <td>{transaction?.gl_lt || "-"}</td>
                                    <td>{transaction?.ret_ABS || "-"}</td>
                                    <td>{transaction?.ret_CAGR || "-"}</td>
                                  </tr>
                                )
                              )}

                              <tr className="sub-total">
                                <td
                                  colSpan={2}
                                  className="sub-total-td-color"
                                >
                                  Sub Total
                                </td>
                                <td className="sub-total-td-color">
                                  {
                                    equityData?.data
                                      ?.subTotalOfInvestedAmountOfScheme
                                  }
                                </td>
                                <td colSpan={6}></td>
                                <td className="sub-total-td-color">
                                  {
                                    equityData?.data
                                      ?.subTotalOfRedeemedAmountOfScheme
                                  }
                                </td>
                                <td colSpan={3}></td>
                                <td className="sub-total-td-color">
                                  {
                                    equityData?.data
                                      ?.subTotalOfCurrentValueOfScheme
                                  }
                                </td>
                                <td colSpan={2}></td>
                                <td className="sub-total-td-color">
                                  {equityData?.data?.subTotalOfGLST}
                                </td>
                                <td className="sub-total-td-color">
                                  {equityData?.data?.subTotalOfGLLT}
                                </td>
                                <td colSpan={2}></td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            style={{
                              marginTop: "10px",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "14px",
                            }}
                          >
                            Weg. CAGR: {equityData?.data?.WegCAGR || "0.00"}%
                            , Ret. ABS:{" "}
                            {equityData?.data?.AVG_ABS_ret || "0.00"}%
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No data available</p>
                    )}
                    {responseData?.Equity?.length > 0 && (
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td colSpan={6} className="sub-total-td-color2">
                              Grand Total
                            </td>
                            {responseData?.grand_total_of_invested_amount ? (
                              <td className="sub-total-td-color2">
                                {responseData?.grand_total_of_invested_amount}
                              </td>
                            ) : (
                              <td className="sub-total-td-color2">
                                {responseData?.Equity?.reduce((total, item) => {
                                  const updatedTotal =
                                    total +
                                    (item?.data
                                      ?.subTotalOfInvestedAmountOfScheme || 0);
                                  return updatedTotal;
                                }, 0)}
                              </td>
                            )}


                            <td colSpan={3}></td>
                            {responseData?.grand_total_of_redeemed_amount ? (
                              <td className="sub-total-td-color2">
                                {responseData?.grand_total_of_redeemed_amount}
                              </td>) : (
                              <td className="sub-total-td-color2">
                                {responseData?.Equity?.reduce((total, item) => {
                                  const updatedTotal =
                                    total +
                                    (item?.data
                                      ?.subTotalOfRedeemedAmountOfScheme || 0);
                                  return updatedTotal;
                                }, 0)}
                              </td>
                            )}
                            <td colSpan={8}></td>
                            {responseData?.grand_total_of_current_value ? (
                              <td className="sub-total-td-color2">
                                {responseData?.grand_total_of_current_value}
                              </td>
                            ) : (
                              <td className="sub-total-td-color2">
                                {responseData?.Equity?.reduce((total, item) => {
                                  const updatedTotal =
                                    total +
                                    (item?.data
                                      ?.subTotalOfCurrentValueOfScheme || 0);
                                  return updatedTotal;
                                }, 0)}
                              </td>
                            )}
                          </tr>
                          {/* <tr>
                              <td colSpan={6} className="sub-total-td-color2">
                                Net Total (Grand Total - Redeem)
                              </td>
                              <td className="sub-total-td-color2">
                                {netAmount ? netAmount : difference}
                              </td>
                              <td colSpan={13}></td>
                            </tr> */}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
