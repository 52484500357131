import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../images/sidebar_icon/logo.svg';
import dashbord from '../images/sidebar_icon/dashbord.svg';
import explore from '../images/sidebar_icon/explore.svg';
import portfolioIcon from '../images/sidebar_icon/portfolio2.svg';
import transactionsIcon from '../images/sidebar_icon/transactions.svg';
import reportsIcon from '../images/sidebar_icon/reports.svg';
import goalsIcon from '../images/sidebar_icon/goals.svg';
import supportIcon from '../images/sidebar_icon/support2.svg';

const AfterLoginSidebar = (props) => {
    const menuItems = [
        { label: 'Dashboard', path: '/dashboard', icon: dashbord },
        { label: 'Explore', path: '/explore', icon: explore },
        { label: 'Portfolio', path: '/portfolio', icon: portfolioIcon },
        { label: 'Transactions', path: '/transaction', icon: transactionsIcon },
        { label: 'Reports', path: '/reports', icon: reportsIcon },
        { label: 'Goals', path: '/goals', icon: goalsIcon },
        { label: 'Support', path: '/customer-support', icon: supportIcon },
    ];
    // Use window.matchMedia to check screen width dynamically
  const isLargeScreen = window.matchMedia("(max-width: 992px)").matches;
  return (
    <>
    
    <nav id={`sidebar`} className={`text-white  ${!props.isVisible   ? '' : 'active'}`} >
        <div className="sidebar-header text-center primary-text">
            <button id="close-sidebar" className="btn btn-link text-white position-absolute"
                style={{top: '10px',right: '10px',fontSize:'15px'}} onClick={props.onClickBtn}>
                <i className="fas fa-times"></i>
            </button>
            <Link to="/" className="">
                <img src={logo} alt="" className="" />
            </Link>

        </div>
        <ul className="main_menu list-unstyled components  d-flex flex-column gap-2">


            {menuItems.map((item, index) => (
                <li
                    key={index}
                    className="pcoded-hasmenu d-flex flex-column"
                >
                    <NavLink
                        to={item.path}
                        className={({ isActive }) =>
                            isActive ? 'active' : ''
                        }
                        onClick={props.onClickBtn}
                    >
                        <img
                            src={item.icon}
                            alt={`${item.label} Icon`}
                            className=""
                        />
                        <span className="pcoded-mtext">{item.label}</span>
                    </NavLink>
                </li>
            ))}

        </ul>
    </nav>

    
    </>
  );
};

export default AfterLoginSidebar;
