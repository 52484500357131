import React from "react";
import "../styles/RedeemOrders.css";
import logo from "../images/icons/growth-tree.svg";
import { Link } from "react-router-dom";

const RedeemOrderPlaced = () => {
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box" style={{ height: "100vh" }}>
          <h5 className="mb-4 main-heading-title">Orders</h5>

          <div className="card mt-4 border-0 order_box">
            <div className="d-flex column-gap-2  row-gap-2 flex-wrap align-items-center px-3 pt-3">
              <div className="d-flex align-items-center">
                <img
                  src={logo}
                  alt="Fund Logo"
                  className="me-3 fund-logo"
                  width="50"
                />

                <Link to="/" className="">
                  <h5 className="mb-0 outerHeading">
                    SBI PSU Direct Plan Growth
                  </h5>
                </Link>
              </div>
              <div className="ms-sm-auto text-end currency">
                <h5 className="text_success mb-1 aditya_currency">₹200</h5>
                <p className="mb-0  aditya_currency_time">1 second ago</p>
              </div>
            </div>

            <div className="d-flex column-gap-4 row-gap-3 transaction_content pb-3">
              <p className="mb-1  transaction_inner">
                Transaction No.{" "}
                <span className="d-block transaction_inner_value mt-1">
                  1234567890
                </span>
              </p>
              <p className="mb-0 transaction_inner">
                Ref. <br />
                <span className="d-block  transaction_inner_value mt-1">
                  12345678
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemOrderPlaced;
