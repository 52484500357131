import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import "../../styles/common.css";
import bank_details from "../../images/my_profile/bank_details.svg";

const PersonelDetail = ({ data }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingTwo">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img src={bank_details} alt="" className="personel_details_img" />

              <span className="personal_inner_text">Bank Details</span>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />

        <div className="accordion-body accordion-body1 profile_accordion_body">
          <div className="d-flex justify-content-end align-items-right"></div>

          {/* Edit Personal Details Content */}

          {/* View Personal Details Content */}

          <div className="card redeam_bottom_card">
            <div className="row form_body justify-content-center">
              <div className="col-xl-6 col-lg-10 col-md-10 ">
                <form>
                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Bank Name:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.bank_name}
                        {/* {userGetProfile?.data?.user?.name} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Account Type:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.acc_type}

                        {/* {userGetProfile?.data?.user?.dob &&
                          convertDateToDDMMYYYYStartWithYear(
                            userGetProfile?.data?.user?.dob
                          )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Account No:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span className="white-space-nowrap">
                        {data?.acc_no}
                        {/* {userGetProfile?.data?.user?.email} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">IFSC Code:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.ifsc_code}
                        {/* {relationLabel(
                          userGetProfile?.data?.user?.profile
                            ?.email_relation
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Branch Name:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.branch_name}
                        {/* {userGetProfile?.data?.user?.mobile} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Branch Address:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.branch_addr1}
                        {/* {relationLabel(
                          userGetProfile?.data?.user?.profile
                            ?.mobile_relation
                        )} */}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonelDetail;
