import { useState, useEffect, useRef } from "react";
import "../styles/transaction.css";
import holding from "../images/orders/logo.svg";
import filter from "../images/transaction/filter.svg";
import { IoMdClose } from "react-icons/io";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";

const TransactionTable = ({
  data,
  selectedType,
  selectedStatus,
  onTypeChange,
  onStatusChange,
  onFundType,
  onSortChange,
  sortFields
}) => {
  console.log("sortFields",sortFields);
  const [showTypeOptions, setShowTypeOptions] = useState(false);
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [showFundsTypeOptions, setShowFundsTypeOptions] = useState(false);

  const typeOptions = [
    { value: "all", label: "All" },
    { value: "Fresh Purchase", label: "Fresh Purchase" },
    { value: "Additional Purchase", label: "Additional Purchase" },
    { value: "Redeem", label: "Redeem" },
    { value: "Switch", label: "Switch" },
    { value: "Gross Dividend", label: "Gross Dividend" },
  ];

  const status = [
    { value: "all", label: "All" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" },
    { value: "Success", label: "Success" },
  ];

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle missing or invalid date
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

 const handleSortClick = (field) => {
  onSortChange(field); // Pass the field to parent to handle sorting
}

  const handleTypeChange = (type) => {
    onTypeChange(type);
    setShowTypeOptions(false); // Close the dropdown after selecting a filter
  };

  const handleStatusChange = (status) => {
    onStatusChange(status);
    setShowStatusOptions(false); // Close the dropdown after selecting a filter
  };
  const handleFundsTypeChange = (status) => {
    onFundType(status);
    setShowFundsTypeOptions(false); // Close the dropdown after selecting a filter
  };

  const initialData = [
    { id: 1, status: "Success", type: "Fresh Purchase" ,fundType :"Redeem"},
    { id: 2, status: "Pending", type: "Additional Purchase" ,fundType :"Lumpsum" },
    { id: 3, status: "Rejected", type: "Redeem" ,fundType :"	SIP" },
    { id: 4, status: "Rejected by RTA with Reason", type: "Gross Dividend" },
    { id: 5, type: "Switch" },
  ];

  const [dataFilter, setDataFilter] = useState(initialData);
  const [statusFilter, setStatusFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [fundTypeFilter, setFundTypeFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const filterRef = useRef(null);

  const uniqueStatuses = [...new Set(initialData.map((item) => item.status).filter(Boolean))];
  const uniqueTypes = [...new Set(initialData.map((item) => item.type).filter(Boolean))];
  const uniqueFundsTypes = [...new Set(initialData.map((item) => item.fundType).filter(Boolean))];  

  useEffect(() => {
    let filteredData = [...initialData];

    if (statusFilter) {
      filteredData = filteredData.filter((item) =>
        item.status?.toLowerCase().includes(statusFilter?.toLowerCase())
      );
    }

    if (typeFilter) {
      filteredData = filteredData.filter((item) =>
        item.type?.toLowerCase().includes(typeFilter?.toLowerCase())
      );
    }

    setDataFilter(filteredData);
  }, [statusFilter, typeFilter]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setActiveFilter(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterClick = (filter) => {
    setActiveFilter(activeFilter === filter ? null : filter);
  };

  const clearFilter = (filter) => {
    if (filter === "status") {
      setStatusFilter("");
    } else if(filter === "type") {
      setTypeFilter("");
    }
    else{
      setFundTypeFilter("");
    }
    window?.location?.reload();
  };

  return (
    <div className="table-container table-responsive">
      <table className="table_transaction">
        <thead>
          <tr>
            <th>
              <div className="d-flex align-items-center gap-2">
                Date
                <div className="d-flex flex-column" onClick={() => handleSortClick("post_date")}>
                {sortFields?.post_date === "asc" || sortFields?.created_at === "asc" ? <TiArrowSortedUp style={{ cursor: "pointer" }} /> : <TiArrowSortedDown style={{ cursor: "pointer" }} />}
                </div>
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center gap-2">
                Holding Name
                <div className="d-flex flex-column" onClick={() => handleSortClick("product_long_name")}>
                {sortFields?.product_long_name === "asc" || sortFields?.scheme_name === "asc" ? <TiArrowSortedUp style={{ cursor: "pointer" }} /> : <TiArrowSortedDown style={{ cursor: "pointer" }} />}
                </div>
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center gap-2">
                Invested Amt.
                <div className="d-flex flex-column" onClick={() => handleSortClick("amount")}>
                {sortFields?.amount === "asc" || sortFields?.amt === "asc" ? <TiArrowSortedUp style={{ cursor: "pointer" }} /> : <TiArrowSortedDown style={{ cursor: "pointer" }} />}
                </div>
              </div>
            </th>

            <th>
              <div>
                Type
                <button
                  className="btn btn-link btn-sm p-0 ms-2"
                  onClick={() => handleFilterClick("type")}
                >
                  <img
                    src={filter}
                    alt="Filter"
                    style={{
                      width: "12px",
                      height: "12px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              {activeFilter === "type" && (
                <div className="filter-popup" ref={filterRef}>
                  <div className="input-group input-group-type">
                    <select
                      className="form-select transaction-width"
                      value={typeFilter}
                      onChange={(e) => {
                        setTypeFilter(e.target.value);
                        handleTypeChange(e.target.value);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="" disabled selected>
                        Select Type
                      </option>
                      {uniqueTypes.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {typeFilter && (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => clearFilter("type")}
                      >
                        <IoMdClose className="close-btn-mb" />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </th>

            <th>
              <div>
                Fund Type
                <button
                  className="btn btn-link btn-sm p-0 ms-2"
                  onClick={() => handleFilterClick("fundType")}
                >
                  <img
                    src={filter}
                    alt="Filter"
                    style={{
                      width: "12px",
                      height: "12px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  />
                </button>
              </div>
              {activeFilter === "fundType" && (
                <div className="filter-popup" ref={filterRef}>
                  <div className="input-group input-group-type">
                    <select
                      className="form-select transaction-width"
                      value={fundTypeFilter}
                      onChange={(e) => {
                        setFundTypeFilter(e.target.value);
                        handleFundsTypeChange(e.target.value);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="" disabled selected>
                        Select Type
                      </option>
                      {uniqueFundsTypes.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                    {fundTypeFilter && (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => clearFilter("type")}
                      >
                        <IoMdClose className="close-btn-mb" />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </th>

            <th>
              Status
              <button
                className="btn btn-link btn-sm p-0 ms-2"
                onClick={() => handleFilterClick("status")}
              >
                <img
                  src={filter}
                  alt="Filter"
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                />
              </button>
              {activeFilter === "status" && (
                <div className="status-filter-popup" ref={filterRef}>
                  <div className="input-group">
                    <select
                      className="form-select transaction-width"
                      value={statusFilter}
                      onChange={(e) => {
                        setStatusFilter(e.target.value);
                        handleStatusChange(e.target.value);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="" disabled selected>
                        Select Status
                      </option>
                      {uniqueStatuses.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                    {statusFilter && (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => clearFilter("status")}
                      >
                        <IoMdClose className="close-btn-mb" />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.orders && data?.orders.length > 0 ? (
            data.orders.map((transaction, index) => (
              <tr
                key={index}
                className={`${
                  transaction?.status?.toLowerCase() ||
                  transaction?.status?.toLowerCase()
                } transaction_row`}
              >
                <td>
                  {formatDate(
                    transaction?.post_date || transaction?.created_at
                  )}
                </td>
                <td>
                  <img
                    src={transaction?.icon}
                    alt="Holding"
                    className="transection_image"
                  />
                  {transaction?.scheme_name ||
                    transaction?.fund ||
                    transaction?.product_long_name}
                </td>

                {/* new  */}

                {/* <td>
                  <div className='transaction-filter-td'>

                    <img
                      src={transaction?.icon}
                      alt="Holding"
                      className="transection_image"
                    />
                    <span className="two-line-text">
                      {transaction?.product_long_name || transaction?.fund}
                    </span>

                  </div>
                </td> */}

                <td>
                  {transaction?.amt ||
                    transaction?.amount ||
                    transaction?.transaction?.amount}
                </td>
                <td>{transaction?.purchase_type || transaction?.trxn_type}</td>
                <td>
                  {transaction?.sub_purchase_type ||
                    transaction?.investment_type}
                </td>
                <td>
                  <span
                    className={`status ${
                      transaction?.transaction?.trxn_status?.toLowerCase() ||
                      transaction?.status?.toLowerCase()
                    }`}
                  >
                    {transaction?.transaction?.trxn_status ||
                      transaction?.status}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ height: "200px" }}>
              <td colSpan="6" className="no-data-message">
                No transactions found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <style jsx>{`
        .filter-popup {
          position: sticky;
          padding: 8px;
          border-radius: 4px;
          z-index: 10;
          min-width: 200px;
          margin-top: -59px;
          display: flex;
          align-items: center;
        }

        .input-group-type {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          top: 55px;
        }

        .close-btn-mb {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          width: 24px;
          height: 25px;
          // border-radius: 50%;
          // background-color: #007bff;
          color: white;
          // font-size: 16px;
          cursor: pointer;
          border: none;
          flex-shrink: 0; /* Ensures button does not shrink */
        }

        // .filter-popup {
        //   // position: absolute;
        //   position: sticky;
        //   padding: 8px;
        //   border-radius: 4px;
        //   z-index: 1000;
        //   min-width: 200px;
        //   margin-top: -59px;
        // }

        // .input-group-type {
        //   top: 55px;
        // }

        .status-filter-popup {
          position: absolute;
          padding: 8px;
          border-radius: 4px;
          z-index: 1000;
          min-width: 200px;
          right: 36px;
        }
        // .close-btn-mb{
        //     margin-bottom: 4px;
        // }

        .btn-link {
          color: #6c757d;
          text-decoration: none;
        }

        .btn-link:hover {
          color: #0d6efd;
        }

        .input-group .btn {
          border-left: none;
        }
      `}</style>
    </div>
  );
};

export default TransactionTable;
