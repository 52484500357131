import {
  USER_START,
  USER_LOGIN,
  USER_VERIFY_LOGIN,
  USER_FORGOT_PASSWORD,
  USER_ONBOARD_GET_DATA,
  USER_ONBOARD_STORE_DATA,
  USER_GET_PROFILE,
  USER_STORE_PROFILE,
  USER_STORE_PROFILE_UPDATE,
  USER_BANK_DETAIL,
  USER_BANK_DETAIL_UPDATE,
  USER_BANK_DETAIL_ADD,
  USER_FATCA_DETAIL,
  USER_FATCA_DETAIL_UPDATE,
  USER_DOCUMENTS,
  USER_DOCUMENTS_UPDATE,
  USER_NOMINEE_DETAIL,
  USER_NOMINEE_DETAIL_ADD,
  USER_ENACH_REGISTRATION_GET,
  USER_ENACH_REGISTRATION_STORE,
  USER_ENACH_REGISTRATION_CREATE,
  USER_ENACH_REGISTRATION_MAP,
  USER_ENACH_REGISTRATION_DELETE_MANDATE,
} from "../actions/types";

const initialState = {
  users: "",
  loading: false,
  usersVerifyLogin: "",
  usersForgotPassword: "",
  userOnboardGetData: "",
  userOnboardStoreData: "",
  userGetProfile: "",
  userStoreProfile: "",
  userStoreProfileUpdate: "",
  userBankDetail: "",
  userBankDetailUpdates: "",
  userBankDetailsAdd: "",
  userFatcaDetail: "",
  userFatcaDetailUpdate: "",
  userDocument: "",
  userDocumentUdate: "",
  userNomineeDet: "",
  userNomineeDetAdd: "",
  userEnachRegistrationsGet: "",
  userEnachRegistrationsStore: "",
  userEnachRegitrationsCreate: "",
  userEnachRegitrationsMap: "",
  userEnachRegitrationsDeleteMandates: "",
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_START:
      return { ...state, loading: true };
    case USER_LOGIN:
      return { ...state, loading: false, users: action.payload };
    case USER_VERIFY_LOGIN:
      return { ...state, loading: false, usersVerifyLogin: action.payload };
    case USER_FORGOT_PASSWORD:
      return { ...state, loading: false, usersForgotPassword: action.payload };
    case USER_ONBOARD_GET_DATA:
      return { ...state, loading: false, userOnboardGetData: action.payload };
    case USER_ONBOARD_STORE_DATA:
      return { ...state, loading: false, userOnboardStoreData: action.payload };
    case USER_GET_PROFILE:
      return { ...state, loading: false, userGetProfile: action.payload };
    case USER_STORE_PROFILE:
      return { ...state, loading: false, userStoreProfile: action.payload };
    case USER_STORE_PROFILE_UPDATE:
      return {
        ...state,
        loading: false,
        userStoreProfileUpdate: action.payload,
      };
    case USER_BANK_DETAIL:
      return { ...state, loading: false, userBankDetail: action.payload };
    case USER_BANK_DETAIL_UPDATE:
      return {
        ...state,
        loading: false,
        userBankDetailUpdates: action.payload,
      };
    case USER_BANK_DETAIL_ADD:
      return { ...state, loading: false, userBankDetailsAdd: action.payload };
    case USER_FATCA_DETAIL:
      return { ...state, loading: false, userFatcaDetail: action.payload };
    case USER_FATCA_DETAIL_UPDATE:
      return {
        ...state,
        loading: false,
        userFatcaDetailUpdate: action.payload,
      };
    case USER_DOCUMENTS:
      return { ...state, loading: false, userDocument: action.payload };
    case USER_DOCUMENTS_UPDATE:
      return { ...state, loading: false, userDocumentUdate: action.payload };
    case USER_NOMINEE_DETAIL:
      return { ...state, loading: false, userNomineeDet: action.payload };
    case USER_NOMINEE_DETAIL_ADD:
      return { ...state, loading: false, userNomineeDetAdd: action.payload };
    case USER_ENACH_REGISTRATION_GET:
      return {
        ...state,
        loading: false,
        userEnachRegistrationsGet: action.payload,
      };
    case USER_ENACH_REGISTRATION_STORE:
      return {
        ...state,
        loading: false,
        userEnachRegistrationsStore: action.payload,
      };
    case USER_ENACH_REGISTRATION_CREATE:
      return {
        ...state,
        loading: false,
        userEnachRegitrationsCreate: action.payload,
      };
    case USER_ENACH_REGISTRATION_MAP:
      return {
        ...state,
        loading: false,
        userEnachRegitrationsMap: action.payload,
      };
    case USER_ENACH_REGISTRATION_DELETE_MANDATE:
      return {
        ...state,
        loading: false,
        userEnachRegitrationsDeleteMandates: action.payload,
      };

    default:
      return state;
  }
};
export default usersReducer;
