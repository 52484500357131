import React, { useEffect, useState } from "react";
import "../styles/redeem.css";
import InvestmentHeaderBox1 from "../components/InvestmentHeaderBox1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import searchDropDown from "../images/downloads/Drop Down Active.svg";
import SelectDropDown from "../components/SelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  switchShow,
  switchStore,
  switchToScheme,
} from "../actions/holdingAction";
import { getLocalStorageUserData } from "../utils/userData";
import { currentDate } from "../utils/DateFormate";
import PopupModel from "../components/PopupModel";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

const Switch = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sourceCategory, setSourceCategory] = useState("");
  const [category, setCategory] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const userData = getLocalStorageUserData();
  const { switchShows, switchSchemes, switchStors, loading, error } =
    useSelector((state) => state.holdings);
  const dataOfSwitch = location?.state?.props;

  const closeModal = () => {
    setIsPopupVisible(false);
  };

  useEffect(() => {
    const payload = {
      id: userData?.id,
      holding_id: dataOfSwitch?.holding_id,
      folio_no: dataOfSwitch?.folioNumber
    };
    dispatch(switchShow(payload));
  }, []);

  const formik = useFormik({
    initialValues: {
      id: userData?.id,
      amt_unit: "Amount",
      amt: switchShows?.data?.current_valuation,
      amc_product_id: switchShows?.data?.product?.amc_product_id,
      current_value: switchShows?.data?.current_valuation,
      current_units: switchShows?.data?.units,
      source_scheme: switchShows?.data?.product?.amc_product_id,
      source_productlimit_id: switchShows?.data?.productlimit?.id,
      target_scheme: "",
      full_switch: "N",
      category: "",
      product_nav: switchShows?.data?.product?.nav?.nav,
      folio_no: switchShows?.data?.folio_no,
      reinvest_tag: "",
      t_id: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amt: Yup.string()
        .matches(/^\d+(\.\d+)?$/, "Amount must be a valid number") // Regex to allow only numbers or float values
        .required("Amount is required")
        .typeError("Amount must be a number"),
      category: Yup.string()
        .notOneOf(["Select"], "Fund Category is required")
        .required("Fund Category is required"),
      target_scheme: Yup.string()
        .notOneOf(["Select"], "Switch to scheme is required")
        .required("Switch to scheme is required"),
      reinvest_tag: Yup.string()
        .notOneOf(["Select"], "Growth / Dividend Option is required")
        .required("Growth / Dividend Option is required"),
    }),
    onSubmit: (values) => {
      dispatch(switchStore(values)).then((response) => {
        if (response) {
          if (response?.status == true) {
            navigate(
              `/orders?order=${
                response?.data?.order_id
                  ? response?.data?.order_id
                  : response?.data?.order
              }`
            );
          } else {
            setIsPopupVisible(true);
          }
        } else {
          setIsPopupVisible(true);
        }
      });
    },
  });

  const actionPopup = () => {
    navigate("/portfolio");
  };

  useEffect(() => {
    if (category && sourceCategory) {
      const payload = {
        id: userData?.id,
        holding_id: dataOfSwitch?.holding_id,
        dividend_mode: category,
        category: sourceCategory,
      };
      dispatch(switchToScheme(payload));
    }
  }, [category, sourceCategory]);
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const cancelBack = () => {
    formik.resetForm();
    navigate("/portfolio");
  };

  return (
    <div className="container-fluid page_background holding-switch-section">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            <Link className="back_icon" to="/portfolio">
              <FontAwesomeIcon icon={faAngleLeft} className="color me-3" />
            </Link>
            Switch
          </h5>
          <InvestmentHeaderBox1 props={dataOfSwitch} />

          <div className="card redeam_bottom_card ">
            <div className="card-body p-0">
              <div className="row align-items-center r_inner_section">
                <div className="col-md-auto col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    Amount:
                    <span className="fw-bold ms-2">
                      ₹{switchShows?.data?.current_valuation}
                    </span>
                  </h6>
                </div>
                <div className="col-md-auto col-sm-auto px-lg-3 px-0">
                  <span className="divider"></span>
                </div>
                <div className="col-md-auto col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    Available units:
                    <span className="fw-bold ms-2 ">
                      {switchShows?.data?.units}
                    </span>
                  </h6>
                </div>
                <div className="col-md-auto col-sm-auto px-lg-3 px-0">
                  <span className="divider"></span>
                </div>
                <div className="col-md-auto col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    NAV as on {currentDate()}:
                    <span className="fw-bold ms-2 text_success">
                      ₹{switchShows?.data?.product_nav}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="card redeam_bottom_card">
              <div className="row form_body justify-content-center">
                <div className="col-lg-7">
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label className="form-label">Switch By</label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <div class="d-flex flex-wrap align-items-center gap-2">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="amt_unit"
                            id="amt_unit"
                            value={formik.values.amt_unit}
                            onChange={() => {
                              formik.setFieldValue("amt_unit", "Amount");
                              formik.setFieldValue(
                                "amt",
                                switchShows?.data?.current_valuation
                              );
                            }}
                            checked={formik.values.amt_unit == "Amount"}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="amt_unit"
                          >
                            Amount
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="amt_unit"
                            id="amt_unit"
                            checked={formik.values.amt_unit == "Units"}
                            value={formik.values.amt_unit}
                            onChange={() => {
                              formik.setFieldValue("amt_unit", "Units");
                              formik.setFieldValue(
                                "amt",
                                switchShows?.data?.units
                              );
                            }}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="amt_unit"
                          >
                            Units
                          </label>
                        </div>
                        {formik.errors.amt_unit && formik.touched.amt_unit && (
                          <div className="text-danger">
                            {formik.errors.amt_unit}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="amount" className="form-label">
                        Total{" "}
                        {formik?.values?.amt_unit == "Amount"
                          ? "amount"
                          : "units"}{" "}
                        you want to switch
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <input
                        type="text"
                        className="form-control"
                        id="amount"
                        name="amt"
                        value={formik.values.amt}
                        disabled={
                          formik?.values?.full_switch == "Y" ? true : false
                        }
                        placeholder="₹"
                        onChange={formik.handleChange}
                      />
                      {formik.errors.amt && formik.touched.amt && (
                        <div className="text-danger">{formik.errors.amt}</div>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label className="form-label">Full Switch</label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <div class="d-flex align-items-center gap-2">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="full_switch"
                            id="fswitchAmount"
                            value="Y"
                            onChange={() => {
                              formik.setFieldValue("full_switch", "Y");
                            }}
                            checked={
                              formik.values.full_switch == "Y" ? true : false
                            }
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="fswitchAmount"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="full_switch"
                            id="fswitchAmountNo"
                            value="N"
                            onChange={() => {
                              formik.setFieldValue("full_switch", "N");
                            }}
                            checked={
                              formik.values.full_switch == "N" ? true : false
                            }
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="fswitchAmountNo"
                          >
                            No
                          </label>
                        </div>
                        {formik.errors.full_switch &&
                          formik.touched.full_switch && (
                            <div className="text-danger">
                              {formik.errors.full_switch}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="fcategory" className="form-label">
                        Fund Category
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                      <SelectDropDown
                        name="category"
                        id=""
                        value={formik.values.category}
                        changeFunction={(value) => {
                          setSourceCategory(value);
                          formik?.setFieldValue("category", value);
                        }}
                        options={switchShows?.data?.categories
                          ?.filter((category) => category.trim() !== "")
                          ?.map((category) => ({
                            value: category,
                            label: capitalizeFirstLetter(category),
                          }))}
                        {...formik.getFieldProps("category")}
                      />
                      {formik.errors.category && formik.touched.category && (
                        <div className="text-danger">
                          {formik.errors.category}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="gdOption" className="form-label">
                        Growth / Dividend Option
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                      <SelectDropDown
                        name="reinvest_tag"
                        id=""
                        value={formik.values.reinvest_tag}
                        changeFunction={(value) => {
                          formik?.setFieldValue("reinvest_tag", value);
                          setCategory(value);
                        }}
                        options={
                          switchShows?.data?.options &&
                          Object.keys(switchShows?.data?.options)
                            .filter(
                              (key) =>
                                switchShows?.data?.options[key]?.trim() !== ""
                            )
                            .map((key) => ({
                              value: key,
                              label: switchShows?.data?.options[key],
                            }))
                        }
                        {...formik.getFieldProps("reinvest_tag")}
                      />
                      {
                        formik.errors.reinvest_tag && (
                          //  formik.touched.reinvest_tag && (
                          <div className="text-danger">
                            {formik.errors.reinvest_tag}
                          </div>
                        )
                        // )
                      }
                    </div>
                  </div>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 ">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="stScheme" className="form-label">
                        Switch to scheme:
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7  position-relative">
                      <SelectDropDown
                        name="target_scheme"
                        id=""
                        value={formik.values.target_scheme}
                        changeFunction={(value) => {
                          formik?.setFieldValue("target_scheme", value);
                        }}
                        options={switchSchemes?.products
                          ?.filter(
                            (product) =>
                              product?.product_long_name?.trim() !== ""
                          )
                          .map((product) => ({
                            value: product?.amc_product_id,
                            label: capitalizeFirstLetter(
                              product?.product_long_name
                            ),
                          }))}
                        {...formik.getFieldProps("target_scheme")}
                      />
                      {formik.errors.target_scheme &&
                        formik.touched.target_scheme && (
                          <div className="text-danger">
                            {formik.errors.target_scheme}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer form_card ">
              <div className="">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    onClick={cancelBack}
                    className="form_button "
                  >
                    Cancel
                  </button>
                  <button type="submit" className="form_button next_btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isPopupVisible && switchStors?.status != true && (
        <PopupModel
          id="alertModel"
          title="Lorem Ipsum"
          content={switchStors?.message}
          image={cancel}
          actionPopup={actionPopup}
          closeModal={closeModal}
          btnColor={"btn-success"}
        />
      )}
    </div>
  );
};

export default Switch;
