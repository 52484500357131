import '../styles/fundplan3.css';
import growthTree from '../images/icons/growth-tree.svg';
import { Link } from 'react-router-dom';
import ActionPopupButton from './ActionPopupButton';

const SectionLumpsum = (props) => {

        return (

                <div className="card fund-card-four overflow-hiddden card-bg-white border-0">
                        <div className="card-body position-relative">
                                <div className="d-flex align-items-md-start align-items-center">
                                        <img src={props.image} alt="Fund Logo"
                                                className="fund-logo me-lg-3 me-3 mb-sm-0 mb-2" />
                                        <Link 
                                        // to={props.url} 
                                        className="d-md-none d-block" 
                                        // state={{ data: props }}
                                        style={{pointerEvents: 'none'}}
                                        >
                                                <h6 className="fund-name-header mb-0">{props.title}</h6>
                                        </Link>
                                </div>
                                <div>
                                        <div className="fund-details-info d-flex align-items-top ">

                                                <div>
                                                        <Link 
                                                        // to={props.url} 
                                                        className="d-md-block d-none" 
                                                        // state={{ data: props }}
                                                        style={{pointerEvents: 'none'}}
                                                        >
                                                                <h6 className="fund-name-header cursor-pointer">{props.title}</h6>
                                                        </Link>
                                                        <div className="d-flex flex-wrap gap-2">
                                                                <span className={`badge custom-badge green-badge`}>{props.badge?.toUpperCase()}</span>
                                                                <span className={`badge custom-badge purple-badge`}>{props?.badge1?.toUpperCase()}</span>
                                                        </div>
                                                </div>
                                                {/* {props.actionButton} */}
                                                <ActionPopupButton
                                                filterColor={props?.actionButton?.filterColor}
                                                item={props?.actionButton?.item}
                                                />


                                        </div>
                                        <div className="d-flex flex-wrap column-gap-xxl-5 column-gap-xl-5 column-gap-lg-5 column-gap-4  row-gap-lg-3  row-gap-3 mt-3  ">
                                                <p className="investment-details mb-0 ">Folio No. <span
                                                        className="d-block">{props.price1}</span></p>
                                                <p className="investment-details mb-0">Type <span
                                                        className="d-block">{props.price2}</span></p>
                                                <p className="investment-details mb-0">Invested Value <span
                                                        className="d-block">{props.price3}</span></p>
                                                <p className="investment-details mb-0">Market Value <span
                                                        className="d-block">{props.price4}</span></p>
                                                <p className="investment-details mb-0">Gain/Loss <span
                                                        className="d-block" style={{ color: props.price5 < 0 ? 'red' : '#00ac78' }}>{props.price5}</span></p>
                                                <p className="investment-details mb-0">Units <span
                                                        className="d-block">{props.price6}</span></p>
                                              
                                        </div>
                                </div>

                        </div>
                </div>

        );
};

export default SectionLumpsum;
