import {
  USER_START,
  USER_LOGIN,
  USER_VERIFY_LOGIN,
  USER_FORGOT_PASSWORD,
  USER_SIGN_UP,
  USER_CHANGE_PASSWORD,
  USER_ONBOARD_GET_DATA,
  USER_ONBOARD_STORE_DATA,
  USER_GET_PROFILE,
  USER_STORE_PROFILE,
  USER_STORE_PROFILE_UPDATE,
  USER_BANK_DETAIL,
  USER_BANK_DETAIL_UPDATE,
  USER_BANK_DETAIL_ADD,
  USER_FATCA_DETAIL,
  USER_FATCA_DETAIL_UPDATE,
  USER_DOCUMENTS,
  USER_DOCUMENTS_UPDATE,
  USER_NOMINEE_DETAIL,
  USER_NOMINEE_DETAIL_ADD,
  USER_ENACH_REGISTRATION_GET,
  USER_ENACH_REGISTRATION_STORE,
  USER_ENACH_REGISTRATION_CREATE,
  USER_ENACH_REGISTRATION_MAP,
  USER_ENACH_REGISTRATION_DELETE_MANDATE,
  PINCODE,
  BANK_DETAIL,
  ORDER_ID,
  PRODUCT_REF,
  USER_RESENDOTP,
} from "./types";
import { toast } from "react-toastify";
import axios from "axios";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
import { encryptData } from "../utils/userData";
import { convertDateToDDMMMYYYY } from "../utils/DateFormate";
const token = getLocalStorageUserToken();

// Action to fetch posts
export const userLogin = (payload, type) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}login`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_LOGIN, payload: response.data });

      if (response?.data?.status !== false) {
        if (type == 1) {
          toast.success("OTP generate successfully!");
        } else {
          toast.success(response?.data?.message);
          if (response?.data?.token) {
            const encryptedUserData = encryptData(response.data?.data);
            const encryptedUserToken = encryptData(response.data?.token);
            const encryptedUserId = encryptData(response.data?.data?.id);
            localStorage.setItem("userData", encryptedUserData);
            localStorage.setItem("token", encryptedUserToken);
            localStorage.setItem("userId", encryptedUserId);

            if (response?.data?.data?.type == "C") {
              if (response.data?.data?.has_iin_activated == true) {
                setTimeout(() => {
                  window.location.href = "/portfolio";
                }, 400);
              } else {
                if (response.data?.data?.current_wizard_step == 0) {
                  setTimeout(() => {
                    window.location.href = "/personal-details";
                  }, 400);
                } else if (response.data?.data?.current_wizard_step == 1) {
                  setTimeout(() => {
                    window.location.href = "/bank-details";
                  }, 400);
                } else if (response.data?.data?.current_wizard_step == 2) {
                  setTimeout(() => {
                    window.location.href = "/fatca-declartion";
                  }, 400);
                } else if (response.data?.data?.current_wizard_step == 3) {
                  setTimeout(() => {
                    window.location.href = "/manage-nominees";
                  }, 400);
                } else if (response.data?.data?.current_wizard_step == 4) {
                  setTimeout(() => {
                    window.location.href = "/portfolio";
                  }, 400);
                } else if (response.data?.data?.current_wizard_step == 5) {
                  setTimeout(() => {
                    window.location.href = "/portfolio";
                  }, 400);
                }
              }
            } else if (response?.data?.data?.type == "R") {
              window.location.href = "/rm/dashboard";
            } else if (response?.data?.data?.type == "A") {
              window.location.href = "/admin/dashboard";
            }
          }
        }
      } else {
        if (
          response?.data?.message ==
          "Invalid password, couldn\u2019t login. This is attempt number "
        ) {
          toast.error("Invalid password, couldn\u2019t login. ");
        } else {
          toast.error(response?.data?.message);
        }
      }
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_LOGIN, error: error.message });
    }
  };
};

export const userResendOtp = (payload, type) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}resend-otp`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_RESENDOTP, payload: response.data });

      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_RESENDOTP, error: error.message });
    }
  };
};

export const userVerifyLogin = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}verify-login`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_VERIFY_LOGIN, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
        if (response?.data?.token) {
          const encryptedUserData = encryptData(response.data?.data);
          const encryptedUserToken = encryptData(response.data?.token);
          const encryptedUserId = encryptData(response.data?.data?.id);
          localStorage.setItem("userData", encryptedUserData);
          localStorage.setItem("token", encryptedUserToken);
          localStorage.setItem("userId", encryptedUserId);

          if (response.data?.data?.has_iin_activated == true) {
            setTimeout(() => {
              window.location.href = "/portfolio";
            }, 400);
          } else {
            if (response.data?.data?.current_wizard_step == 0) {
              setTimeout(() => {
                window.location.href = "/personal-details";
              }, 400);
            } else if (response.data?.data?.current_wizard_step == 1) {
              setTimeout(() => {
                window.location.href = "/bank-details";
              }, 400);
            } else if (response.data?.data?.current_wizard_step == 2) {
              setTimeout(() => {
                window.location.href = "/fatca-declartion";
              }, 400);
            } else if (response.data?.data?.current_wizard_step == 3) {
              setTimeout(() => {
                window.location.href = "/manage-nominees";
              }, 400);
            } else if (response.data?.data?.current_wizard_step == 4) {
              setTimeout(() => {
                window.location.href = "/portfolio";
              }, 400);
            } else if (response.data?.data?.current_wizard_step == 5) {
              setTimeout(() => {
                window.location.href = "/portfolio";
              }, 400);
            }
          }
        }
      } else {
        // sessionStorage.setItem("attempt", response?.data?.attempt);
        toast.error(response.data.message);
      }
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_VERIFY_LOGIN, error: error.message });
    }
  };
};

export const userForgotPassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}forgot-password`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_FORGOT_PASSWORD, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_FORGOT_PASSWORD, error: error.message });
    }
  };
};

export const userSignup = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}register`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_SIGN_UP, payload: response.data });
      // if (response?.data?.status !== false) {
      //   toast.success(response?.data?.message);
      //   // setTimeout(() => {
      //   //   window.location.href = "/login";
      //   // }, 1000);
      // } else {
      //   toast.error(response.data.message);
      // }
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_SIGN_UP, error: error.message });
    }
  };
};

export const userChangePassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}change-password`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_CHANGE_PASSWORD, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
        localStorage.removeItem("userData");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_CHANGE_PASSWORD, error: error.message });
    }
  };
};

export const userOnboardingGetDetails = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}user-onboard/get-data`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      if (
        response?.data?.message == "Session expired. Please login again." ||
        response?.data?.message == "Authentication Error!"
      ) {
        localStorage.removeItem("userData");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_ONBOARD_GET_DATA, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_ONBOARD_GET_DATA, error: error.message });
    }
  };
};

export const userOnboardingStoreData = (
  payload,
  userId,
  account_no,
  completed_wizard_step
) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    const payloads = {
      id: userId,
      completed_wizard_step: "1",
    };

    let responseOfBank;
    if (completed_wizard_step == 1) {
      responseOfBank = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}user-onboard/get-data`,
        payloads,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
    }

    const bankAccounts = responseOfBank?.data?.data?.bank_account;
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}user-onboard/store-data`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      if (response.data.status == true) {
        toast.success(response.data.message);
        if (response.data.wizard_step == 1) {
          window.location.href = "/bank-details";
        } else if (response.data.wizard_step == 2) {
          window.location.href = "/fatca-declartion";
        } else if (response.data.wizard_step == 3) {
          window.location.href = "/manage-nominees";
        } else if (response.data.wizard_step == 4) {
          window.location.href = "/dashboard";
        } else if (response.data.wizard_step == null) {
          window.location.href = "/dashboard";
        }
      } else {
        if (bankAccounts?.length > 0) {
          const accountExists = bankAccounts?.some(
            (account) => account.acc_no === account_no
          );
          if (accountExists) {
            window.location.href = "/fatca-declartion";
          } else {
            toast.error(response.data.message);
          }
        } else {
          toast.error(response.data.message);
        }
      }

      // Assuming the response data is in response.data
      dispatch({ type: USER_ONBOARD_STORE_DATA, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_ONBOARD_STORE_DATA, error: error.message });
    }
  };
};

export const pincode = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}pincode`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch({ type: PINCODE, payload: response.data });
      return response.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: PINCODE, error: error.message });
    }
  };
};

export const getBankDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`https://ifsc.razorpay.com/${payload}`);
      dispatch({ type: BANK_DETAIL, payload: response.data });
      return response.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: BANK_DETAIL, error: error.message });
    }
  };
};

export const userPofileGet = () => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}profile`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_GET_PROFILE, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_GET_PROFILE, error: error.message });
    }
  };
};

export const userPofileStore = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}profile`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_STORE_PROFILE, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_STORE_PROFILE, error: error.message });
    }
  };
};

export const userPofileUpdate = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.patch(
        `${process.env.REACT_APP_API_URL}profile`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch(userPofileGet());
      if (response.data.status == false) {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_STORE_PROFILE_UPDATE, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_STORE_PROFILE_UPDATE, error: error.message });
    }
  };
};

export const userBankDetails = () => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}get-bank-accounts`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_BANK_DETAIL, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_BANK_DETAIL, error: error.message });
    }
  };
};

export const userBankDetailsUpdate = (payload, id) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}bank-accounts/${id}`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch(userBankDetails());
      if (response.data.status == false) {
        toast.error(
          response.data.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
        );
      } else {
        toast.success(response.data.message);
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_BANK_DETAIL_UPDATE, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_BANK_DETAIL_UPDATE, error: error.message });
    }
  };
};

export const userBankDetailsAdd = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}store-bank-accounts`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch(userBankDetails());
      if (response.data.status == false) {
        toast.error(
          response.data.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
        );
      } else {
        toast.success(response.data.message);
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_BANK_DETAIL_ADD, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_BANK_DETAIL_ADD, error: error.message });
    }
  };
};

export const userFatcaDetails = () => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}fatca`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_FATCA_DETAIL, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_FATCA_DETAIL, error: error.message });
    }
  };
};

export const userFatcaDetailsUpdate = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}fatca`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch(userFatcaDetails());
      if (response.data.status == false) {
        toast.error(
          response.data.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
        );
      } else {
        toast.success(response.data.message);
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_FATCA_DETAIL_UPDATE, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_FATCA_DETAIL_UPDATE, error: error.message });
    }
  };
};

export const userDocumentGet = () => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}important-documents`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_DOCUMENTS, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_DOCUMENTS, error: error.message });
    }
  };
};

export const userDocumentUpdate = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}important-documents`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      if (response.data.status == false) {
        toast.error(
          response.data.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
        );
      } else {
        toast.success(response.data.message);
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_DOCUMENTS_UPDATE, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_DOCUMENTS_UPDATE, error: error.message });
    }
  };
};

export const userNomineeDetail = () => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}nominees`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_NOMINEE_DETAIL, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_NOMINEE_DETAIL, error: error.message });
    }
  };
};

export const userNomineeDetailAdd = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}nominees`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch(userNomineeDetail());
      if (response.data.status == false) {
        toast.error(
          response?.data?.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
            ? response.data?.message
            : response.data?.message[0]
        );
      } else {
        toast.success(
          response.data.message
            ? response.data.message
            : "Nominee add successfully."
        );
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_NOMINEE_DETAIL_ADD, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_NOMINEE_DETAIL_ADD, error: error.message });
    }
  };
};

export const userEnachRegitrationGet = () => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}enach-list`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_ENACH_REGISTRATION_GET, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_ENACH_REGISTRATION_GET, error: error.message });
    }
  };
};

export const userEnachRegitrationStore = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const payloadEdit = {
        ...payload,
        end_date: convertDateToDDMMMYYYY(payload?.end_date),
        start_date: convertDateToDDMMMYYYY(payload?.start_date),
      };
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}enach-save`,
        payloadEdit,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      if (response?.data?.status == false) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.href =
            response?.data?.data?.mandate_activation_link?.emandatelink;
        }, 1000);
      }
      // Assuming the response data is in response.data
      dispatch({ type: USER_ENACH_REGISTRATION_STORE, payload: response.data });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_ENACH_REGISTRATION_STORE, error: error.message });
    }
  };
};

export const userEnachRegitrationCreate = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}enach-list/${payload.id}/${payload.type}`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      if (response?.data?.status == false) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
      }
      // Assuming the response data is in response.data
      dispatch({
        type: USER_ENACH_REGISTRATION_CREATE,
        payload: response.data,
      });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_ENACH_REGISTRATION_CREATE, error: error.message });
    }
  };
};

export const userEnachRegitrationMap = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}enach-map/${payload}`,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      if (response?.data?.status == false) {
        toast.error(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
      }
      // Assuming the response data is in response.data
      dispatch({
        type: USER_ENACH_REGISTRATION_MAP,
        payload: response.data,
      });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_ENACH_REGISTRATION_MAP, error: error.message });
    }
  };
};

export const userEnachRegitrationDeleteMandates = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}enach-delete`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      if (response?.data?.status == false) {
        toast.error(response?.data?.message);
      } else {
        dispatch(userEnachRegitrationGet());
        toast.success(response?.data?.message);
      }
      // Assuming the response data is in response.data
      dispatch({
        type: USER_ENACH_REGISTRATION_DELETE_MANDATE,
        payload: response.data,
      });
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({
        type: USER_ENACH_REGISTRATION_DELETE_MANDATE,
        error: error.message,
      });
    }
  };
};

export const orderIdData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}orders-details/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({
        type: ORDER_ID,
        payload: response.data,
      });
      return response?.data;
    } catch (error) {
      return error;
    }
  };
};

export const purchaseRefData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}payments-details`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({
        type: PRODUCT_REF,
        payload: response.data,
      });
      return response?.data;
    } catch (error) {
      dispatch({
        type: PRODUCT_REF,
        error: error.message,
      });
      return error;
    }
  };
};

export const primaryBankData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}bank-accounts/set-default-account/${payload?.bankId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error;
    }
  };
};
