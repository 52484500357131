import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  ResubmitProfileDetail,
  ResubmitProfile,
} from "../../actions/adminAction";

const AdminMailBack = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(ResubmitProfileDetail(id)).finally(() => setIsLoading(false));
    }
  }, [id, dispatch]);

  const { adminResubmitProfile = {} } = useSelector((state) => state?.admin);
  const data = adminResubmitProfile?.profile || {};
  const image = adminResubmitProfile?.image;

  const formik = useFormik({
    initialValues: {
      cheque: "",
      button_type: "",
    },
    validationSchema: Yup.object({
      cheque: Yup.mixed()
        .required("Cheque is required")
        .test("fileType", "Only PDF and image files are allowed", (value) => {
          return (
            value &&
            ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
          );
        }),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("profile_id", id);
      formData.append("button_type", values.button_type);
      formData.append("user_id", data?.user_id);
      formData.append("file_attachment", values.cheque);

      dispatch(ResubmitProfile(formData));
      navigate(-1);
    },
  });

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">Resubmit Profile</h5>

          <div
            className="card redeam_bottom_card"
            style={{ borderRadius: "12px" }}
          >
            <div className="row form_body justify-content-center">
              <form onSubmit={formik.handleSubmit}>
                {image && (
                  <div className="col-xl-12 col-lg-12 col-md-12 my-2">
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                        <label htmlFor="type" className="form-label">
                          Cheque image uploded by you
                        </label>
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 custom-file">
                        {image?.endsWith(".pdf") ? (
                          <embed
                            src={image}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                          />
                        ) : (
                          <img src={image} alt="Resubmitprofile" width="100%" />
                        )}
                      </div>
                    </div>
                  </div>
                  // <div className="col-12 my-2">
                  //   <label className="form-label">Cheque image uploaded by you</label>
                  //   <img src={image} alt="Uploaded cheque" width="100%" />
                  // </div>
                )}

                <div className="col-12 my-2">
                  <label className="form-label">
                    Upload Cancelled Cheque (Image/PDF){" "}
                    <span className="red-color">*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (
                        file &&
                        ["image/jpeg", "image/png", "application/pdf"].includes(
                          file.type
                        )
                      ) {
                        formik.setFieldValue("cheque", file);
                      } else {
                        formik.setFieldError(
                          "cheque",
                          "Only PDF and image files are allowed"
                        );
                      }
                    }}
                  />
                  {formik.touched.cheque && formik.errors.cheque ? (
                    <div className="text-danger">{formik.errors.cheque}</div>
                  ) : null}
                </div>

                <div className="card-footer d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    onClick={() =>
                      formik.setFieldValue("button_type", "uploadCheque")
                    }
                  >
                    Upload Cheque
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() =>
                      formik.setFieldValue("button_type", "resubmit")
                    }
                    disabled={!formik.values.cheque}
                  >
                    Resubmit Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMailBack;
