import '../styles/redeem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import RecommendedFundCard from '../components/RecommendedFundPlan2';
import ChartCard from '../components/ChartCard';
import SliderComponent from '../components/SliderComponent';
import SelectDropDown from '../components/SelectDropDown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import InvestButton from '../components/InvestButton';
import { useDispatch, useSelector } from 'react-redux';
import { addOrUpdateGoal, getGoalsType, goalAmountCalculation, recommendedFunds } from '../actions/goalsActions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { debounce } from "lodash";
import {getLocalStorageUserData} from "../utils/userData";


const GoalEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const sectionRef = useRef(null);
    const [options, setOptions] = useState([]);
        const [selectedGoal, setSelectedGoal] = useState("");
        const [chartData, setChartData] = useState(null);
        const [page, setPage] = useState(1); 
        const [loading, setLoading] = useState(false);
        const [totalPages, setTotalPages] = useState(1);
        // const [fundsData, setFundsData] = useState([]);
        const [sliderData, setSliderData] = useState({
            amount: 10000,
            expected_rate: 1,
            tenure: 1,
            inflation: 0,
          });
          const [error , setError] = useState("");
          const dispatch = useDispatch();
          const isMounted = useRef(false); 
          const goalType = useSelector((state) => state?.goals?.goalType?.data?.goals);
          const goal = useSelector((state) => state.goals);
          
          const fundsData = goal?.recommendedFunds?.data?.funds || [];
          const userData = getLocalStorageUserData();
        //   const backLink = location?.state?.from === "dashboard" ? "/dashboard" : "/goals";
        //   const goalData = location?.state?.goalData ;
        const { goalData, from , status } = location.state || {};  
        
          useEffect(() => {
            if (goalData) {
                setSliderData({
                    goal: goalData.goal || "",
                    amount: goalData.amount || "",
                    tenure: goalData.tenure || "",
                    inflation: goalData.inflation || 0,
                    expected_rate: goalData.expected_rate || goalData?.rate|| 1,
                });
            }
        }, [goalData]);
        
        const handleGoalChange = (value) => {
            setSelectedGoal(value);
          };

          const validationSchema = Yup.object({
            goal: Yup.string()
                .oneOf(
                    ['buy_house/property', 'buy-vehicle', 'start-business', 'marriage', 'education-child', 'vacation', 'retirement'],
                    'Please select a valid goal.'
                )
                .nullable() // Allows null values
                .default('buy_house/property') // Default selected value
                .required('Goal is required'),
            // amount: Yup.number().min(10000, "Amount must be at least 10000").required("Amount is required"),
        });
      
    // useEffect(() => {
    //   const fetchData = async ()=>{
    //     const payload = { id: userData?.id , goal : goalData.goal};
    //     try {
    //       setLoading(true);
    //      await dispatch(getGoalsType(payload));
    //     } catch (error) {
    //       console.error("API call failed: ", error);
    //     }
    //     finally{
    //       setLoading(false);
    //     }
    //   }
    //   // if (isMounted.current) {
    //     fetchData();
    //   // } else {
    //   //   isMounted.current = true;
    //   // }
        
    // }, [dispatch, userData?.id]);

    // useEffect(() => {
    //     if (goalType && Array.isArray(goalType)) {
    //         const formattedOptions = goalType?.map((item) => ({
    //             label: item, // Display value
    //             value: item?.toLowerCase()?.replace(/ /g, "_"), 
    //         }));
    //         setOptions(formattedOptions);
    //     }
    // }, [goalType]);

    useEffect(() => {
        const fetchFunds = async() => {
          const payload = {
            amount: parseInt(sliderData?.amount) || 0,
            expected_rate_of_interest: parseFloat(sliderData?.expected_rate) || 0,
            period_years: parseInt(sliderData?.tenure) || 0,
            inflation_rate: parseFloat(sliderData?.inflation) || 0,
            perpage: parseInt(9999), // Hardcoded as an integer
            pagenum: parseInt(page) || 0,
          };
          
      
          try {
            setLoading(true);
            await Promise.all([dispatch(recommendedFunds(payload))]);
      
          }
          catch (error) {
            console.error("API call failed: ", error);
          } finally {
            setLoading(false);
          }
        };
      
        // if (isMounted.current) {
          fetchFunds();
        // } else {
        //   isMounted.current = true;
        // }
      }, [dispatch, sliderData, page]); 
      // useEffect(()=>{
      //   const payload={
      //     id: userData?.id, 
      //     goal: selectedGoal, 
      //     expected_rate: goalData?.expected_rate, 
      //     tenure: goalData?.tenure,
      //     amount: goalData?.amount,
      //     inflation: goalData?.inflation,
      //   }
      //   dispatch(goalAmountCalculation(payload));
      // },[dispatch, userData?.id, selectedGoal]);
      const newStatus = useRef(status);

      const handleSliderChange = (updatedData) => {
        // Merge the updated data with goals data
        setSliderData((prevData) => ({
          ...prevData,
          ...updatedData,
          
        })); 
        
        if (updatedData && newStatus.current !== false) {
          newStatus.current = false;
        }
      };
      const updateGoals = async () => {
        let defaultValuesApplied = false;
        if (sliderData.amount < 10000) {
          setError("Amount must be at least 10000");
          return;
        } else {
          setError(""); // Clear the error if amount is valid
        }
        const payload = {
            id: userData?.id,
            goal: sliderData?.goal || goalData?.goal || "", // Default value if sliderData is empty
            expected_rate: sliderData?.expected_rate || sliderData?.rate || 1, // Default value if missing
            tenure: sliderData?.tenure || 1, // Default value if missing
            amount: sliderData?.amount || 10000, // Default value if missing
            inflation: sliderData?.inflation || 0, // Default value if missing
        };
        await dispatch(addOrUpdateGoal(payload))
            .then(() => {
                toast.success("Goal Updated Successfully");
                const fromPage = location.state?.from || "goals";
                navigate(`${fromPage}`, { replace: true });
                
                // window.location.reload();
            })
            .catch(() => {
                toast.error("Failed to update goal");
            });
    };
    

        const investmentMonthly = ( newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.investment_monthly_total : goalData?.investment_monthly_total) ;
        const interestMonthly =  (newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.interest_monthly : goalData?.interest_monthly);
      
        const investmentYearlyTotal =  ( newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.investment_yearly_total : goalData?.investment_yearly_total) ;
        const interestYearly =  (newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.interest_yearly : goalData?.interest_yearly) ;
      
        const investmentOneTime =  ( newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.investment_one_time : goalData?.investment_one_time) ;
        const interestOneTime = ( newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.interest_one_time : goalData?.interest_one_time ) ;
        
        
        const perMonthData = {
            labels: ['Total Investment', 'Total Interest'],
            datasets: [
              {
                data: [investmentMonthly, interestMonthly], 
                backgroundColor: ['#3583FF', '#34D3B7'], 
                hoverBackgroundColor: ['#3583FF', '#34D3B7'],
              },
            ],
          };
        
          const perYearData = {
            labels: ['Total Investment', 'Total Interest'],
            datasets: [
              {
                data: [investmentYearlyTotal, interestYearly], 
                backgroundColor: ['#3583FF', '#34D3B7'],
                hoverBackgroundColor: ['#3583FF', '#34D3B7'],
              },
            ],
          };
        
          const oneTimeData = {
            labels: ['Total Investment', 'Total Interest'],
            datasets: [
              {
                data: [investmentOneTime, interestOneTime], 
                backgroundColor: ['#3583FF', '#34D3B7'], 
                hoverBackgroundColor: ['#3583FF', '#34D3B7'],
              },
            ],
          };
          const option = [
            { value: "Buy House/Property", label: "Buy House/Property" },
            { value: "Buy Vehicle", label: "Buy Vehicle" },
            { value: "Start a Business", label: "Start a Business" },
            { value: "Marriage", label: "Marriage" },
            { value: "Education of Child/Children", label: "Education of Child/Children" },
            { value: "Vacation", label: "Vacation" },
            { value: "Retirement", label: "Retirement" }
          ];
    return (
        <div className="container-fluid page_background holding-switch-section">
            <div class="page-inner-content mb-3" >
                <div class="page_inner_content_box">
                    <div className='d-flex justify-content-between'>
                   
                        <h5 className="mb-4 main-heading-title">
                        <Link className="back_icon" to={`${from}`}>
                            <FontAwesomeIcon icon={faAngleLeft} className='color me-3' />
                        </Link>
                            Edit Goal
                        </h5>
                    </div>
                    <div className="goal_planner_section card">
                <div className="card-body p-0">
                <div className="goal_planner_top px-md-5 pt-lg-5 pt-4 px-3">
                        {/* <h5 className="mb-lg-5 mb-4 left-section-title">Goal</h5> */}
                        <Formik
                       initialValues={{
                        goal: selectedGoal ,
                        // amount: sliderData?.amount || 10000,
                      }}
                        validationSchema={validationSchema}
                        onSubmit={updateGoals}
                    >
                        {({ touched, errors ,setFieldValue ,handleSubmit}) => (
                            <Form>
                        <div className="card border-0">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-xxl-4 col-xl-4 col-lg-7 mb-md-0 mb-4">
                                        <div className="mb-lg-5 mb-4 slider-container">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label htmlFor="goal" className="slider-label">Goal <span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <Field
                                                as={SelectDropDown}
                                                name="goal"
                                                id="goal"
                                                value={sliderData?.goal} // Default value (either selectedGoal or sliderData.goal)
                                                changeFunction={(value) => {
                                                  setFieldValue('goal', value); // Set field value when changed
                                                  // const selectedOption = option.find((opt) => opt.value === value);
                                                  // setSelectedGoal(selectedOption ? selectedOption.label : ""); // Update selected label
                                              }}
                                                options={option} // Options for the dropdown
                                                disabled
                                            />
                                            {touched.goal && errors.goal && (
                                                <div className="error-message">{errors.goal}</div> // Display error only if touched and has an error
                                            )}

                                        </div>
                                        <SliderComponent
                                            sliderData={sliderData}
                                            onSliderChange={handleSliderChange}
                                            userId={userData?.id}
                                            selectedGoal={selectedGoal || goalData?.goal}
                                            error={error}
                                            // amount={sliderData?.amount} // Pass the amount to SliderComponent
                                          // setFieldValue={setFieldValue}
                                          // error={errors?.amount} // Pass the error for amount field
                                          // touched={touched?.amount} 
                                        />
                                    </div>
                                       <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 chart_section mb-4">
                                             <div className="d-flex justify-content-center gap-4 chart_header ">
                                                <h6 className="d-flex gap-2 align-items-center">
                                                    <span className="Investment_color_box"></span>
                                                    Investment
                                                </h6>
                                                <h6 className="d-flex gap-2 align-items-center">
                                                    <span className="Investment_color_box color_box"></span>
                                                    Interest
                                                </h6>
                                             </div>
                                             <div className="row investment_cards ms-lg-3">
                                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 mt-3">
                                                    <div className="card border-0 h-100 position-relative">
                                                        <div className="card-body">
                                                            <h6 className="d-flex mb-4">Per Month <span className="ms-auto">₹{newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.investment_monthly : goalData?.investment_monthly }</span></h6>
                                                           <div className="d-flex flex-column gap-2">
                                                            <h5 className="d-flex">Total Investment:<span className="ms-auto">₹{newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.investment_monthly_total : goalData?.investment_monthly_total}</span></h5>
                                                            <h5 className="d-flex">Total Interest:<span className="ms-auto">₹{newStatus.current === false ?  goal?.goalsAmountCalucalation?.data?.interest_monthly : goalData?.interest_monthly }</span></h5>
                                                            </div> 
                                                            <div className="mt-3 chart_div">
                                                            <ChartCard dataArr={perMonthData} /> 
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6  mt-3">
                                                    <div className="card border-0 h-100 position-relative">
                                                        <div className="card-body">
                                                            <h6 className="d-flex mb-4">Per Year <span className="ms-auto">₹{newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.investment_yearly : goalData?.investment_yearly }</span></h6>
                                                           <div className="d-flex flex-column gap-2">
                                                            <h5 className="d-flex">Total Investment:<span className="ms-auto">₹{newStatus.current === false ?  goal?.goalsAmountCalucalation?.data?.investment_yearly_total : goalData?.investment_yearly_total}</span></h5>
                                                            <h5 className="d-flex">Total Interest:<span className="ms-auto">₹{newStatus.current === false?  goal?.goalsAmountCalucalation?.data?.interest_yearly : goalData?.interest_yearly}</span></h5>
                                                            </div> 
                                                            <div className="mt-3 chart_div">
                                                              <ChartCard dataArr={perYearData} /> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6  mt-3">
                                                    <div className="card border-0 h-100 position-relative">
                                                        <div className="card-body">
                                                            <h6 className="d-flex mb-4">One Time <span className="ms-auto">₹{newStatus.current === false ? goal?.goalsAmountCalucalation?.data?.investment_one_time : goalData?.investment_one_time }</span></h6>
                                                           <div className="d-flex flex-column gap-2">
                                                            <h5 className="d-flex">Total Interest:<span className="ms-auto">₹{newStatus.current === false ?  goal?.goalsAmountCalucalation?.data?.interest_one_time : goalData?.interest_one_time}</span></h5>
                                                            </div>
                                                            <div className="mt-3 chart_div">
                                                              <ChartCard dataArr={oneTimeData} /> 
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                             </div>
                                       </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center my-md-4 my-3">
                        <InvestButton 
                             label='Update' 
                             type="submit" 
                             color='blue' 
                             onClick={() => {
                                handleSubmit();
                              }} 
                        />
                    </div>
                    </Form>
                        )}
                    </Formik>
                    </div>
                    <div className="goal_planner_content px-md-5 px-3">
                        <h5 className="mb-4">Recommended Funds</h5>
                        <div className="fund-card-content mb-3 no-scrollbar" ref={sectionRef}  style={{ overflowY: "auto", maxHeight: "300px" }}>
                            <div className="row ">
                              {fundsData?.length > 0 ? (
                                fundsData?.map((items, index) => (
                                  <div className="col-xl-4 col-lg-6 col-md-12 mb-md-4 mb-3" key={index} >
                                    <RecommendedFundCard
                                      image={items?.amc?.icon || ""}
                                      // bookmark={false}
                                      // plus={false}
                                      title={items?.product_long_name}
                                      badge={items?.asset_class}
                                      badge2={items?.plan_type}
                                      price1={`₹${items?.nav?.nav || "0.00"}`}
                                      price2={
                                        `₹${
                                          items?.productlimits?.find(
                                        (limit) => limit.sub_trxn_type === "N"
                                      )?.minimum_amount || "0.00" // added this condition to show SIP and lumpsum price difference
                                    }`
                                      }
                                      price3={`₹${
                                        items?.productlimits?.find(
                                          (limit) => limit.sub_trxn_type === "S"
                                        )?.minimum_amount || "0.00"
                                      }`}
                                      url="/fund-detail"
                                    />
                                  </div>
                                ))
                              ) : (
                                <p>Coming Soon</p>
                              )}
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
                </div>
            </div>
        </div>
    );
};

export default GoalEdit;