import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/sidebar_icon/logo.svg";
import dashbord from "../../images/sidebar_icon/dashbord.svg";
import explore from "../../images/sidebar_icon/explore.svg";
import portfolioIcon from "../../images/sidebar_icon/portfolio2.svg";
import transactionsIcon from "../../images/sidebar_icon/transactions.svg";
import reportsIcon from "../../images/sidebar_icon/reports.svg";
import referralCode from "../../images/sidebar_icon/Referral Code.svg";

const RmAfterLoginSidebar = (props) => {
  const menuItems = [
    { label: "Dashboard", path: "/rm/dashboard", icon: dashbord },
    { label: "Investors", path: "/rm/investors", icon: explore },
    { label: "Investments", path: "/rm/investments", icon: portfolioIcon },
    { label: "Transactions", path: "/rm/transactons", icon: transactionsIcon },
    { label: "Reports", path: "/rm/reports-summery", icon: reportsIcon },
    { label: "Referral Code", path: "/rm/referral-code", icon: referralCode },
  ];
  // Use window.matchMedia to check screen width dynamically
  const isLargeScreen = window.matchMedia("(max-width: 992px)").matches;
  return (
    <>
      <nav
        id={`sidebar`}
        className={`text-white  ${!props.isVisible ? "" : "active"}`}
      >
        <div className="sidebar-header text-center primary-text">
          <button
            id="close-sidebar"
            className="btn btn-link text-white position-absolute"
            style={{ top: "10px", right: "10px", fontSize: "15px" }}
            onClick={props.onClickBtn}
          >
            <i className="fas fa-times"></i>
          </button>
          <Link to="/" className="">
            <img src={logo} alt="" className="" />
          </Link>
        </div>
        <ul className="main_menu list-unstyled components  d-flex flex-column gap-2">
          {menuItems.map((item, index) => (
            <li key={index} className="pcoded-hasmenu d-flex flex-column">
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={props.onClickBtn}
              >
                <img src={item.icon} alt={`${item.label} Icon`} className="" />
                <span className="pcoded-mtext">{item.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default RmAfterLoginSidebar;
