import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FatcaLinkDetail,
  IINLinkDetail,
  FatcaDetail,
} from "../../actions/adminAction";

const MappingDetails = () => {
  const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.data;
  const type = location.state?.type;
  const [isLoading, setIsLoading] = useState(false);

  const fetchFatcaDetail = async () => {
    try {
      setIsLoading(true);
      await dispatch(FatcaDetail(id));
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchFatcaDetail();
    }
  }, []);

  const { adminFatcaData = {} } = useSelector((state) => state?.admin);
  const data = adminFatcaData?.profile || {};

  const handleNavigation = (data) => {
    navigate("/admin/resubmitprofile", { state: data });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    }).format(date);
  };

  const handleSubmit = async (id) => {
    try {
      if (type === "Fatca") {
        await dispatch(FatcaLinkDetail(id)); // Edit API call
        //   toast.success("FAQ updated successfully!");
      } else {
        await dispatch(IINLinkDetail(id)); // Add API call
        //   toast.success("FAQ added successfully!");
      }
      // navigate(-1); // Navigate back after submission
    } catch (error) {
      // toast.error("Something went wrong! Please try again.");
    }
  };

  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title"> Investor's status </h5>

          <div
            // className="card investor-status-box"
            className="card mapping-details-box mapping-details-box1 investor-no-border"
          >
            <div className="header-purple">
              <p>
                {" "}
                Cheque Image exists -{" "}
                <span
                  className="header-purple-span custom-pointer"
                //   onClick={handleNavigation}
                  onClick={() => handleNavigation(data?.id)}
                >
                  {" "}
                  Resubmit Profile{" "}
                </span>{" "}
              </p>
            </div>

            <div className="card redeam_bottom_card accordion-body1 mapping-details-body">
              <div className="row form_body justify-content-center">
                <div className="col-xl-6 col-lg-10 col-md-10 ">
                  <div className="row align-items-center personalAccordian mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">IIN:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>{data?.customer_id}</span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Name:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>{data?.inv_name}</span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">IIN Status:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>{data?.iin_status}</span>
                    </div>
                  </div>
                  {data?.iin_status === "N" && (
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                      <div className="col-6 my-md-2 my-1">
                        <label className="form-label">Reason:</label>
                      </div>
                      <div className="col-6 my-md-2 my-1 d-flex align-items-center">
                        <span>{data?.iin_reason}</span>
                        {/* {data?.iin_reason?.includes("link not confirmed") && (
                          <button
                            className="generate-link-btn"
                            onClick={() => handleSubmit(data?.id)}
                          >
                            Generate Link
                          </button>
                        )} */}
                      </div>
                    </div>
                  )}
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">FATCA/KYC status:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1 d-flex align-items-center">
                      <span>{data?.kyc_status === "Y" ? "Yes" : "No"}</span>
                      {/* {data?.kyc_status === "N" && ( */}
                      <button
                        className="generate-link-btn"
                        onClick={() => handleSubmit(data?.id)}
                      >
                        Generate Link
                      </button>
                      {/* )} */}
                    </div>
                  </div>
                  {/* <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">FATCA/KYC status:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1 d-flex align-items-center">
                      <span>{data?.fatca_status}</span>
                      <button className="generate-link-btn">
                        Generate Link
                      </button>
                    </div>
                  </div> */}

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Last updated on:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>{formatDate(data?.updated_at)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card-footer form_card form_card_border">
            <div className="d-flex justify-content-end gap-2">
              <button
                type="submit"
                className="form_button next_btn next-btn-status"
              >
                Click to Activate
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MappingDetails;
