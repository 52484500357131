import React, { useEffect, useState } from "react";
// import '../styles/fund.css'; // Import styles
import MyProfileHeader from "../components/MyProfileHeader";
import MyProfileAccordian from "../components/MyProfileAccordian";
import { useDispatch } from "react-redux";
import { userPofileGet } from "../actions/usersActions";

// Functional component for the funddetails page
const MyProfile = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userPofileGet());
  });
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content fund_section mb-3">
        <div className="">
          <div className="card overflow-hiddden border-0">
            <MyProfileHeader />
            <div className="card-body page_inner_content_box">
              <MyProfileAccordian />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
