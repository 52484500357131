import axios from "axios";
import { TRANSACTION_MAILBACK, TRANSACTION_ONLINE } from "./types";
import { toast } from "react-toastify";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const getTransactionMailback = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}recent_trxn`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: TRANSACTION_MAILBACK, payload: response.data });
    } catch (error) {
      console.error("Error fetching transaction mailback", error.message);
      toast.error("Unable to fetch transactions. Please try again");
    }
  };
};
export const getTransactionOnline = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}online_recent_trxn`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: TRANSACTION_ONLINE, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      console.error("Error fetching transaction Online", error.message);
      toast.error("Unable to fetch transactions. Please try again");
    }
  };
};
