import React, { useEffect } from "react";
import "../styles/CustomerSupport.css";
import { useDispatch, useSelector } from "react-redux";
import { termAndConditions } from "../actions/footerActions";
import { Helmet } from 'react-helmet-async'; // Import Helmet

// import Loader from "../loader/Loader-ui";

const TermsAndConditions = () => {

  const dispatch = useDispatch();
  const terms = useSelector((state) => state.footer);
  
  useEffect(() => {
    dispatch(termAndConditions());
  }, [dispatch])
  const htmlContext = terms?.termsAndConditions?.data?.terms_and_conditions[0].body;
  return (
    <>
    {/* Helmet for SEO Metadata */}
    <Helmet>
        <title>Terms and Conditions | Fundswala</title>
        <meta 
          name="description" 
          content="Read the Terms and Conditions of Fundswala. Learn about our policies, privacy, and your rights while using our platform." 
        />
      </Helmet>
    <div className="container-fluid page_background">

      <div className="page-inner-content mb-4">
        <div className="page_inner_content_box">
          <h5
            className="mb-4 main-heading-title text-center"
            style={{ fontSize: "40px" }}
          >
            Terms and Conditions
          </h5>
          <div className="container mt-5">
            {/* Safely render the HTML content */}
            <div dangerouslySetInnerHTML={{ __html: htmlContext }}></div>
          </div>
        </div>
      </div>

      {/* <Loader /> */}



    </div>
    </>
  );
};

export default TermsAndConditions;
