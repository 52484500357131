import {
  GET_CARD,
  TRANSACTION_MAILBACK,
  TRANSACTION_ONLINE,
} from "../actions/types";

const initialState = {
  getMailback: {},
  getOnline: {},
  loading: false,
};

const transactionReducer = (state = initialState, action) => {
  switch (action?.type) {
    case TRANSACTION_MAILBACK:
      return { ...state, loading: false, getMailback: action?.payload };
    case TRANSACTION_ONLINE:
      return { ...state, loading: false, getOnline: action?.payload };
    default:
      return state;
  }
};

export default transactionReducer;
