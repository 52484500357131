import React, { useEffect, useRef, useState } from "react";
import "../styles/FundDetailInvestmentBox.css"; // Import styles
import Gross_down from "../images/gross_down_icon.svg";
import upArrow from "../images/Up.svg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardData, dashboardHeader } from "../actions/holdingAction";
import Acdeactivatesvg from "../images/dashboard/ac-deactivate.svg";
import { getLocalStorageUserId } from "../utils/userData";
// import { GetLocalStorageUserData } from "../utils/userData";
// Functional component for the funddetails page
const DashboardTopHeaderSection = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const { dashboardDatas } = useSelector((state) => state.holdings);
  const [IINlinkClicked, setIINlinkClicked] = useState();
  const [iinDeactivationReason, setiinDeactivationReason] = useState();
  const [IINlink, setIINlink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = getLocalStorageUserId();
  // const userData = GetLocalStorageUserData();
  useEffect(() => {
    const fetchData = async () => {
      const payload = { id: userId };
      try {
        setIsLoading(true);
        await Promise.all([dispatch(dashboardData(payload))]);
      } catch (error) {
        console.error("One or more API calls failed: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    // if (isMounted.current) {
    fetchData();
    // } else {
    //   isMounted.current = true;
    // }
  }, [dispatch, userId]);

  const containsKeywords = (text, keywords) =>
    keywords.some((keyword) => text?.includes(keyword));

  useEffect(() => {
    setIINlinkClicked(dashboardDatas?.data?.activation_links?.iin_link_clicked);
    setiinDeactivationReason(dashboardDatas?.data?.iin_deactivation_reason);
    setIINlink(dashboardDatas?.data?.activation_links?.iin_link);
  }, [dashboardDatas]);

  return (
    <>
      {!dashboardDatas?.data?.iin_status &&
        dashboardDatas?.wizard_step >= 4 && (
          <div className="start_investment_box card-header ac-deactivated-box-bg">
            <div className="">
              {/* <div className="flex items-center"> */}

              <div className="row align-items-between">
                <div className="deactivated-img-container col-lg-2">
                  <img src={Acdeactivatesvg} alt="Account Deactivated" />

                  <a type="button" class="deactivated-btn">
                    Inactive
                  </a>
                </div>

                <div className="space-y-1 col-lg-10">
                  <div className="flex items-center gap-3">
                    <h2 className="text-xl font-semibold deactivated-title">
                      Your profile has been deactivated.
                    </h2>
                  </div>
                  {!IINlinkClicked ? (
                    containsKeywords(iinDeactivationReason, [
                      "IIN",
                      "Confirmation",
                      "link",
                      "not",
                      "confirmed",
                    ]) ? (
                      <>
                        <p className="text-danger">
                          Your profile deactivation reason:{" "}
                          <b>{iinDeactivationReason}</b>.<br />
                          Please click the link below
                        </p>
                        <a
                          className="text-info"
                          href={IINlink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Please click here to confirm your registration.
                        </a>
                      </>
                    ) : (
                      <>
                        {IINlink && (
                          <>
                            <br />
                            <a
                              className="text-info"
                              href={IINlink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Please click here to confirm your registration.
                            </a>
                          </>
                        )}
                      </>
                    )
                  ) : iinDeactivationReason ? (
                    <>
                      <p className="text-danger">
                        Your profile deactivation reason:{" "}
                        <b>{iinDeactivationReason}</b>
                      </p>
                      {IINlink && (
                        <>
                          <br />
                          <a
                            className="text-info"
                            href={IINlink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Please click here to confirm your registration.
                          </a>
                        </>
                      )}
                    </>
                  ) : (
                    <p className="text-danger">
                      Thank you for completing your profile. Check your email
                      and follow the instructions.
                      <br />
                      Your profile will get activated within 48 to 72 hours
                      after clicking the activation link sent to your email.
                    </p>
                  )}
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        )}

      {!dashboardDatas?.data?.kyc_status &&
        dashboardDatas?.wizard_step >= 4 && (
          <div className="start_investment_box card-header ac-deactivated-box-bg">
            <div className=" row ">
              {/* <div className="flex items-center"> */}

              <div className="deactivated-img-container col-lg-2">
                <img src={Acdeactivatesvg} alt="Account Deactivated" />

                <a type="button" class="deactivated-btn">
                  Inactive
                </a>
              </div>

              <div className="space-y-1 col-lg-10">
                <div className="flex items-center gap-3">
                  <p className="text-xl font-semibold deactivated-title">
                    Complete setting up your mutual fund account fund account to
                    start investingin mutual fund.
                  </p>
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        )}

      {!dashboardDatas?.data?.fatca_status &&
        dashboardDatas?.wizard_step >= 4 && (
          <div className="start_investment_box card-header ac-deactivated-box-bg">
            <div className="">
              {/* <div className="flex items-center"> */}
              <div className="row align-items-center">
                <div className="deactivated-img-container col-lg-2">
                  <img src={Acdeactivatesvg} alt="Account Deactivated" />

                  <a type="button" class="deactivated-btn">
                    Inactive
                  </a>
                </div>

                <div className="space-y-1 col-lg-10">
                  <div className="flex items-center gap-3">
                    <h2 className="text-xl font-semibold deactivated-title">
                      Your FATCA has been deactivated.
                    </h2>
                  </div>
                  <p className="text-danger">
                    Thank you for completing your profile. Check your email and
                    follow the instructions.
                  </p>
                  {!dashboardDatas?.data?.activation_links?.fatca_link_clicked ? (
                    <a
                      className="text-info"
                      href={dashboardDatas?.data?.activation_links?.fatca_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Please click here to confirm your registration.
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        )}
    </>
  );
};

export default DashboardTopHeaderSection;
