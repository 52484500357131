import axios from "axios";
import { getLocalStorageUserToken } from "../utils/userData";
import {
  AMC_LIST,
  CAPGAIN_LOSS_REPORT,
  DATE_WISE_REPORT,
  PRODUCT_LIST,
  SCHEME_WISE_REPORT,
  TYPE_WISE_REPORT,
  CUSTOMER_LIST,
  AMC_LIST_ADMIN,
  PRODUCT_LIST_ADMIN
} from "./types";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const getDateWiseReport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}date-wise-report`,payload,{
        headers:{
         
            Authorization: token
        }
      }); 

      dispatch({ type: DATE_WISE_REPORT, payload: response?.data }); 
      return response.data;
    } catch (error) {
      console.error("Error fetching Date Wise Reports", error.message);
    }
  };
};
export const getTypeWiseReport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}category-type-wise-report`,payload,{
        headers:{
            Authorization: token
        }
      });
           
      dispatch({ type: TYPE_WISE_REPORT, payload: response.data }); 
      return response?.data;
    } catch (error) {
      console.error("Error fetching Type Wise Reports", error.message);
    }
  };
};
export const getSchemeWiseReport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}scheme-wise-report`,payload,{
        headers:{
            Authorization: token
        }
      });
           
      dispatch({ type: SCHEME_WISE_REPORT, payload: response.data }); 
      return response?.data;
    } catch (error) {
      console.error("Error fetching Scheme Wise Reports", error.message);
    }
  };
};
export const getAmcList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}amc-list`,payload,{
        headers:{
            Authorization: token
        }
      });
           
      dispatch({ type: AMC_LIST, payload: response.data }); 
    } catch (error) {
      console.error("Error fetching AMC list", error.message);
    }
  };
};
export const getProductList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}product-list`,payload,{
        headers:{
            Authorization: token
        }
      });
           
      dispatch({ type: PRODUCT_LIST, payload: response.data }); 
    } catch (error) {
      console.error("Error fetching Product list", error.message);
    }
  };
};
export const getCapGainLossReport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}date-wise-report`,payload,{
        headers:{
            Authorization: token
        }
      });
           
      dispatch({ type: CAPGAIN_LOSS_REPORT, payload: response.data }); 
      return response?.data;
    } catch (error) {
      console.error("Error fetching cap Gain/loss reports", error.message);
    }
  };
};

export const getDateWiseReportAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/date-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: DATE_WISE_REPORT, payload: response?.data });
      return response.data;
    } catch (error) {
      console.error("Error fetching Date Wise Reports", error.message);
    }
  };
};
export const getTypeWiseReportAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/category-type-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: TYPE_WISE_REPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      console.error("Error fetching Type Wise Reports", error.message);
    }
  };
};
export const getSchemeWiseReportAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/scheme-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: SCHEME_WISE_REPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      console.error("Error fetching Scheme Wise Reports", error.message);
    }
  };
};
export const getAmcListAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/reports/getAmcs/${payload.id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: AMC_LIST_ADMIN, payload: response.data });
    } catch (error) {
      console.error("Error fetching AMC list", error.message);
    }
  };
};
export const getProductListAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/reports/getProducts/${payload.amc_code}/${payload.id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: PRODUCT_LIST_ADMIN, payload: response.data });
    } catch (error) {
      console.error("Error fetching Product list", error.message);
    }
  };
};

export const getCustomerListAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/transactions/getCustomersList?page_name=investments`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: CUSTOMER_LIST, payload: response.data });
    } catch (error) {
      console.error("Error fetching Product list", error.message);
    }
  };
};
export const getCapGainLossReportAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/date-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: CAPGAIN_LOSS_REPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      console.error("Error fetching cap Gain/loss reports", error.message);
    }
  };
};

export const getDateWiseReportRM = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/date-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: DATE_WISE_REPORT, payload: response?.data });
      return response.data;
    } catch (error) {
      console.error("Error fetching Date Wise Reports", error.message);
    }
  };
};
export const getTypeWiseReportRM = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/category-type-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: TYPE_WISE_REPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      console.error("Error fetching Type Wise Reports", error.message);
    }
  };
};
export const getSchemeWiseReportRM = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/scheme-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: SCHEME_WISE_REPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      console.error("Error fetching Scheme Wise Reports", error.message);
    }
  };
};
export const getAmcListRM = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/reports/getAmcs/${payload.id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: AMC_LIST_ADMIN, payload: response.data });
    } catch (error) {
      console.error("Error fetching AMC list", error.message);
    }
  };
};
export const getProductListRM = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/reports/getProducts/${payload.amc_code}/${payload.id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: PRODUCT_LIST_ADMIN, payload: response.data });
    } catch (error) {
      console.error("Error fetching Product list", error.message);
    }
  };
};

export const getCustomerListRM = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/transactions/getCustomersList?page_name=investments`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: CUSTOMER_LIST, payload: response.data });
    } catch (error) {
      console.error("Error fetching Product list", error.message);
    }
  };
};
export const getCapGainLossReportRM = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/reports/date-wise-report`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: CAPGAIN_LOSS_REPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      console.error("Error fetching cap Gain/loss reports", error.message);
    }
  };
};
