import { SIP_CALCULATOR, LUMPSUM_CALCULATOR } from "../actions/types";

const initialState = {
    sipCalculatorData : {},
    LUMPSUM_CALCULATOR : {},
    loading: false,
};

const sipCalculatorReducers = (state = initialState, action) => {
    switch (action.type) {
        case SIP_CALCULATOR:
            return { ...state, loading: false, sipCalculatorData: action.payload };
        case LUMPSUM_CALCULATOR:
            return {...state, loading: false, lumpsumCalculatorData: action.payload };
        default:
            return state;
    }
};

export default sipCalculatorReducers;