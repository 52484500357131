import React, { useEffect, useState, useRef, useCallback } from "react";
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import "../styles/explore2.css"; // Import styles
// import RecommendedFundPlan from '../components/RecommendedFundPlan2';
import searchSrc from "../images/search_icon.svg";
import crossimage from "../images/icons/cross.png";
import Pagination from "../components/Pagination";
import RecommendedFundCard from "../components/RecommendedFundPlan2";
import { useDispatch, useSelector } from "react-redux";
import {
  getExploreFundsList,
  getAMCLists,
  updateExploreFundsList,
} from "../actions/exploreFundsActions";
import Loader from "../loader/Loader-ui";
import { getLocalStorageUserId } from "../utils/userData";
import { useLocation, useNavigate } from "react-router-dom";
import slugify from 'slugify'; // Install with `npm install slugify`

// Functional component for the explore page
const Explore = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(false); // To track the first render
  const [currentPage, setCurrentPage] = useState(1);
  // const [selectedAMC, setSelectedAMC] = useState(location.state !== null && location.state !== undefined ? location.state.amcId : "");
  // const [searchFunds, setSearchFunds] = useState(location.state !== null && location.state !== undefined ? location.state.search : "");
  const [selectedAMC, setSelectedAMC] = useState("");
  const [searchFunds, setSearchFunds] = useState("");
  const [searchAMC, setSearchAMC] = useState("");
  const [filteredAMCList, setFilteredAMCList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [selectedTypes, setSelectedTypes] = useState("");
  const [selectedInvestment, setSelectedInvestment] = useState("");
  const [selectedAmountFilter, setSelectedAmountFilter] = useState("");
  const [selectedMinimumAmountSIP, setSelectedMinimumAmountSIP] = useState("");
  const [selectedMaximumAmountSIP, setSelectedMaximumAmountSIP] = useState("");
  const [selectedMinimumAmountLumpsum, setSelectedMinimumAmountLumpsum] =
    useState("");
  const [selectedMaximumAmountLumpsum, setSelectedMaximumAmountLumpsum] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedSearchFunds, setDebouncedSearchFunds] = useState("");
  const [debouncedSearchAMC, setDebouncedSearchAMC] = useState("");
  const [isFundsLoading, setIsFundsLoading] = useState(false);
  const [isAMCLoading, setIsAMCLoading] = useState(false);
  const userId = getLocalStorageUserId();

  const currentPageURL = window.location.pathname;    

  // Type options with labels and values
  const typeOptions = [
    { label: "Growth", value: "Z" },
    { label: "Dividend Payout", value: "N" },
    { label: "Dividend Reinvest", value: "Y" },
    { label: "Both Payout & Reinvest", value: "X" },
  ];
  // Investment options with label and filter values
  const investmentOptions = [
    { label: "≤ ₹500", value: "L", amount: "500" }, // L for <=
    { label: "₹501 - ₹2000", value: "B", amount: "500-2000" }, // B for =
    { label: "> ₹2000", value: "G", amount: "2000" }, // G for >=
  ];

  // Accessing Redux state correctly
  const { exploreFundsList = [], loadData } = useSelector(
    (state) => state.exploreFunds
  );
  // Get AMC list for the dropdown menu
  const { amcLists = [] } = useSelector((state) => state.exploreFunds);

  const exploreListProducts = exploreFundsList?.data?.products || [];
  const totalPages = exploreFundsList?.data?.total_page || 1;
  const totalRecords = exploreFundsList?.data?.total_records || 0;
  const amcListData = amcLists?.data || [];  

  useEffect(() => {
    // Clear location.state on component mount
    if (location.state) {
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, []);

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearchFunds(searchFunds); // Update debounced value after delay
  //   }, 1000); // Adjust delay as needed

  //   // Cleanup timeout on component unmount or before next effect
  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchFunds]);

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDebouncedSearchAMC(searchAMC); // Update debounced value after delay
  //   }, 1000); // Adjust delay as needed

  //   // Cleanup timeout on component unmount or before next effect
  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchAMC]);


  const fetchData = useCallback(async () => {
    const fundsPayload = {
      id: userId,
      pagenum: currentPage,
      amc: location.state?.amcId || selectedAMC,
      category: selectedCategories.toLowerCase(),
      type: selectedTypes.toLowerCase(),
      search_query: location.state?.search || searchFunds,
      minimum_amount_lumpsum: selectedMinimumAmountLumpsum,
      minimum_amount_sip: selectedMinimumAmountSIP,
      maximum_amount_lumpsum: selectedMaximumAmountLumpsum,
      maximum_amount_sip: selectedMaximumAmountSIP,
      amount_filter: selectedAmountFilter,
    };

    try {
      setIsFundsLoading(true); // Show loader
      await dispatch(getExploreFundsList(fundsPayload));
    } catch (error) {
      console.error("One or more API calls failed: ", error);
    } finally {
      setIsFundsLoading(false); // Hide loader
    }
  }, [
    dispatch,
    userId,
    currentPage,
    selectedAMC,
    selectedCategories,
    selectedTypes,
    searchFunds,
    selectedMinimumAmountLumpsum,
    selectedMinimumAmountSIP,
    selectedMaximumAmountLumpsum,
    selectedMaximumAmountSIP,
    selectedAmountFilter,
    location.state
  ]);

  const amcData = useCallback(async () => {
    const amcPayload = {
      id: userId,
      search: searchAMC,
    };
    try {
      setIsAMCLoading(true); // Show loader
      await dispatch(getAMCLists(amcPayload));
    } catch (error) {
      console.error("One or more API calls failed: ", error);
    } finally {
      setIsAMCLoading(false); // Hide loader
    }
  }, [dispatch, userId, searchAMC]);

  useEffect(() => {
      fetchData();
  }, [fetchData]);
  
  useEffect(() => {
      amcData();
  }, [amcData]);

//   useEffect(() => {
//     if(!location.state) return;
//     setSearchFunds(location.state?.search);
//     if (location.state?.amcId) {
//       setSearchFunds("");
//       setSelectedAMC(location.state?.amcId)
//     }
//     if (location.state?.search) {
//       setSearchFunds(location.state?.search);
//       setSelectedAMC("")
//     }
//     fetchData();
//     // amcData();
// }, [location.state]);
useEffect(() => {
  const updateStatesAndFetchData = async () => {
    if (!location.state) return;

    const { search, amcId } = location.state;

    if (amcId) {
      setSearchFunds("");
      setSelectedAMC(amcId);
    } else if (search) {
      setSearchFunds(search);
      setSelectedAMC("");
    }

    await fetchData(); // Assuming fetchData is an async function
    // Optionally call amcData if it's required
    // await amcData();
  };

  updateStatesAndFetchData();
}, [location.state]);

  useEffect(() => {
    if (searchAMC) {
      const filtered = amcListData.filter((amc) =>
        amc.long_name.toLowerCase().includes(searchAMC.toLowerCase())
      );
      setFilteredAMCList(filtered); // Show only the first 4 results
    } else {
      setFilteredAMCList(amcListData);
    }
  }, [searchAMC, amcListData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAMCSelect = (amcCode) => {
    // If the selectedAMC is already the clicked one, deselect it
    if (selectedAMC === amcCode) {
      setSelectedAMC(null); // Deselect
    } else {
      setSelectedAMC(amcCode); // Select
    }
  };

  const handleAMCSearchChange = (e) => {
    setSearchAMC(e.target.value);
  };

  const handleCategoryChange = (category) => {
    // Toggle category filter
    if (selectedCategories === category) {
      setSelectedCategories(""); // Deselect if already selected
    } else {
      setSelectedCategories(category); // Select the new category
    }
  };

  const handleTypeChange = (type) => {
    // Toggle type filter
    if (selectedTypes === type) {
      setSelectedTypes(""); // Deselect if already selected
    } else {
      setSelectedTypes(type); // Select the new type
    }
  };

  const handleInvestmentChange = (option) => {
    const { value, amount } = option; // Destructure value and amount

    if (selectedAmountFilter === value) {
      // Deselect if already selected
      setSelectedAmountFilter("");
      setSelectedMinimumAmountSIP("");
      setSelectedMaximumAmountSIP("");
      setSelectedMinimumAmountLumpsum("");
      setSelectedMaximumAmountLumpsum("");
    } else {
      // Set the new filter
      setSelectedAmountFilter(value);

      // Handle the amount logic
      if (value === "B") {
        // Split for range-based amounts (e.g., "501-2000")
        const [min, max] = amount.split("-");
        setSelectedMinimumAmountSIP(min);
        setSelectedMaximumAmountSIP(max);
        setSelectedMinimumAmountLumpsum(min);
        setSelectedMaximumAmountLumpsum(max);
      } else {
        // Single value logic (e.g., "500" or "2000")
        setSelectedMinimumAmountSIP(""); // we send max blank for other values
        setSelectedMaximumAmountSIP("");
        setSelectedMinimumAmountLumpsum(amount);
        setSelectedMaximumAmountLumpsum(amount);
      }
    }
  };

  const handleFundSearchChange = (e) => {
    setSearchFunds(e.target.value);
  };

  // Function to clear all filters
  const clearFilters = () => {
    setSelectedAMC("");
    setSelectedCategories("");
    setSelectedTypes("");
    setSelectedInvestment("");
    setSearchFunds("");

    // Clear location.state by navigating to the same route without state
    navigate(location.pathname, { replace: true, state: null });
  };

  let viewPageUrl;
  if (currentPageURL === "/explore-funds") {
    viewPageUrl = "/fund-detail";
  } else {
    viewPageUrl = "/explore/fund-detail";
  }

  const formatLabel = (label) => {
    const words = label.split(" ");
    if (words.length === 1) return label;
    const [firstWord, ...remainingWords] = words;
    const camelCased = remainingWords
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return `${firstWord} ${camelCased}`;
  };

  return (
    <>
    {/* Helmet for SEO Metadata */}
    <Helmet>
  <title>All Mutual Funds | Fundswala</title>
  <meta 
    name="description" 
    content="Discover and compare top mutual funds at Fundswala. Get the latest NAV, SIP returns, fund performance, expense ratios, and more to make informed investment decisions." 
  />
</Helmet>
    <div className="container-fluid page_background ">
      <div className="page-inner-content explore_fund_section mb-3">
        <div className="page_inner_content_box">
          <div className="d-flex gap-2 flex-wrap align-items-center justify-content-between mb-4">
            <h5 className="left-section-title mb-0">Explore Funds</h5>
            <form className="search-box-top ">
              <button
                className="me-2"
                type="submit"
                style={{ background: "none", border: "none", padding: 0 }}
              >
                <img src={searchSrc} alt="" />
              </button>
              <input
                type="search"
                name="focus"
                placeholder="Search by Fund Name"
                id="search-input"
                value={searchFunds}
                onChange={handleFundSearchChange}
              />
            </form>
          </div>
          <div className="row explore_fund_content">
            <div className="col-xl-3 col-lg-4 col-md-5  pe-md-0 mobile_sidebar">
              <div className="card filter_left_card border-0 position-relative">
                <div className="card-header  ">
                  <h5 className="filter-heading d-flex align-items-center mb-0">
                    Filters
                    <span
                      className="ms-auto"
                      style={{ cursor: "pointer" }}
                      onClick={clearFilters}
                    >
                      Clear
                    </span>
                  </h5>
                </div>
                <div className="card-body p-0">
                  <div className="accordion pb-3" id="filterAccordion">
                    {/* AMC Section  */}
                    <div className="accordion-item custom_accordion">
                      <h2 className="accordion-header" id="headingAmc">
                        <button
                          className="accordion-button  shadow-none py-lg-4"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseAmc"
                          aria-expanded="true"
                          aria-controls="collapseAmc"
                        >
                          AMC
                        </button>
                      </h2>
                      <div
                        id="collapseAmc"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingAmc"
                        data-bs-parent="#filterAccordion"
                      >
                        <div className="accordion-body custom_accordion_body pt-0">
                          <form className="search-box mb-2">
                            <button
                              className="me-2"
                              type="submit"
                              style={{
                                background: "none",
                                border: "none",
                                padding: 0,
                              }}
                            >
                              <img src={searchSrc} alt="" />
                            </button>
                            <input
                              type="search"
                              placeholder="Search AMC"
                              value={searchAMC}
                              id="search-input"
                              onChange={handleAMCSearchChange}
                            />
                          </form>
                          {/* Scrollable Container */}
                          <div
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                          >
                            {filteredAMCList.map((amc, index) => (
                              <div
                                className="form-check filter-checkbox-pt"
                                key={amc.amc_code}
                              >
                                <input
                                  name={amc.long_name}
                                  value={amc.amc_code}
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  id={`amc-${amc.amc_code}`}
                                  onChange={() => handleAMCSelect(amc.amc_code)}
                                  checked={selectedAMC === amc.amc_code}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`amc-${amc.amc_code}`}
                                >
                                  {formatLabel(amc.long_name)}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Category Section */}
                    <div className="accordion-item custom_accordion">
                      <h2 className="accordion-header" id="headingCategory">
                        <button
                          className="accordion-button  shadow-none py-lg-4"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseCategory"
                          aria-expanded="true"
                          aria-controls="collapseCategory"
                        >
                          Category
                        </button>
                      </h2>
                      <div
                        id="collapseCategory"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingCategory"
                        data-bs-parent="#filterAccordion"
                      >
                        <div className="accordion-body custom_accordion_body pt-0">
                          {["Equity", "Debt", "Balanced", "Liquid", "ELSS"].map(
                            (category) => (
                              <div className="form-check" key={category}>
                                <input
                                  name="category[]"
                                  value={category}
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  onChange={() =>
                                    handleCategoryChange(category)
                                  }
                                  checked={selectedCategories === category}
                                />
                                <label className="form-check-label">
                                  {category}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {/* type Section */}
                    <div className="accordion-item custom_accordion">
                      <h2 className="accordion-header" id="headingType">
                        <button
                          className="accordion-button  shadow-none py-lg-4"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseType"
                          aria-expanded="true"
                          aria-controls="collapseType"
                        >
                          Type
                        </button>
                      </h2>
                      <div
                        id="collapseType"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingType"
                        data-bs-parent="#filterAccordion"
                      >
                        <div className="accordion-body custom_accordion_body pt-0">
                          {typeOptions.map((type) => (
                            <div className="form-check" key={type.value}>
                              <input
                                name="type[]"
                                value={type.value}
                                className="form-check-input me-3"
                                type="checkbox"
                                onChange={() => handleTypeChange(type.value)}
                                checked={selectedTypes === type.value}
                              />
                              <label className="form-check-label">
                                {type.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Min Investment Section */}
                    <div className="accordion-item custom_accordion">
                      <h2 className="accordion-header" id="headingInvestment">
                        <button
                          className="accordion-button  shadow-none py-lg-4"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseInvestment"
                          aria-expanded="true"
                          aria-controls="collapseInvestment"
                        >
                          Min Investment
                        </button>
                      </h2>
                      <div
                        id="collapseInvestment"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingInvestment"
                        data-bs-parent="#filterAccordion"
                      >
                        {investmentOptions.map((option) => (
                          <div className="accordion-body custom_accordion_body pt-0">
                            <div className="form-check" key={option.value}>
                              <input
                                name="min-investment[]"
                                value={option.value}
                                className="form-check-input me-3"
                                type="checkbox"
                                onChange={() => handleInvestmentChange(option)}
                                checked={selectedAmountFilter === option.value}
                              />
                              <label className="form-check-label">
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-7 ps-md-0">
              <div className="filter_right_card card border-0">
                <div className="filter_result_box card-header border-0 p-0">
                  <h6 className="filter_result_heading">
                    Search Results:
                    <span className="ms-3">{totalRecords} Mutual Funds</span>
                  </h6>
                  <div className="d-flex flex-wrap gap-3 mb-4">
                    {selectedAMC && (
                      <span className="search-result-text">
                        {
                          amcListData.find(
                            (amc) => amc.amc_code === selectedAMC
                          )?.long_name
                        }
                        <img
                          src={crossimage}
                          alt="Clear"
                          className="ms-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {                            
                            setSelectedAMC("");
                            navigate(location.pathname, { replace: true, state: null });
                          }}
                        />
                      </span>
                    )}
                    {selectedCategories && (
                      <span className="search-result-text">
                        {selectedCategories}
                        <img
                          src={crossimage}
                          alt="Clear"
                          className="ms-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedCategories("")
                            window.history.replaceState({}, document.title, location.pathname);
                          }}
                        />
                      </span>
                    )}
                    {selectedTypes && (
                      <span className="search-result-text">
                        {
                          typeOptions.find(
                            (type) => type.value === selectedTypes
                          )?.label
                        }
                        <img
                          src={crossimage}
                          alt="Clear"
                          className="ms-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedTypes("")}
                        />
                      </span>
                    )}
                    {selectedAmountFilter && (
                      <span className="search-result-text">
                        {
                          investmentOptions.find(
                            (option) => option.value === selectedAmountFilter
                          )?.label
                        }
                        <img
                          src={crossimage}
                          alt="Clear"
                          className="ms-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedAmountFilter("");
                            setSelectedMinimumAmountLumpsum("");
                            setSelectedMaximumAmountLumpsum("");
                            setSelectedMinimumAmountSIP("");
                            setSelectedMaximumAmountSIP("");
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                {/* fund cards */}
                <div className="mb-3 card-body p-0">
                  <div className="row ">
                    {/* Card 1 */}
                    {isFundsLoading ? (
                      <Loader />
                    ) : exploreListProducts.length === 0 ? (
                      <p>No product in your explore funds list.</p>
                    ) : (
                      exploreListProducts.map((product, index) => (
                        <div className="col-xl-6 col-lg-12 col-md-12 mb-md-4 mb-3">
                          <RecommendedFundCard
                            id={product.id}
                            amcId={product.amc_product_id}
                            isin={product.isin}
                            image={product.amc?.icon || ""}
                            bookmark={product.watch_list} // Assuming `watch_list` indicates bookmarked status
                            cart_list={product?.cart_list}
                            plus={product.cart_list} // Example logic for the `plus` icon
                            title={product.product_long_name}
                            badge={product.plan_type} // e.g., "REGULAR"
                            badge2={product.asset_class}
                            price1={`₹${parseFloat(
                              product.nav?.nav || "0.00"
                            ).toFixed(2)}`} // Current NAV price
                            navDate={product.nav?.date}
                            price2={`₹${parseFloat(
                              product.productlimits?.find(
                                (limit) => limit.sub_trxn_type === "N"
                              )?.minimum_amount || "0.00"
                            ).toFixed(2)}`} // Added condition to show SIP and lumpsum price difference
                            price3={`₹${parseFloat(
                              product.productlimits?.find(
                                (limit) => limit.sub_trxn_type === "S"
                              )?.minimum_amount || "0.00"
                            ).toFixed(2)}`}
                            product={product}
                            // url={viewPageUrl} // Dynamic URL
                            url={`${viewPageUrl}/${slugify(product.product_long_name, { lower: true, strict: true })}`} // Add fund name to URL
                            redirectUrl={currentPageURL}
                            // handleUpdate={handleUpdate}
                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
                {/* end fund cards */}
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Explore;
