import React from 'react';
import '../styles/about.css'; // Import styles
import HeroSection from '../components/HeroSection';
import HeroBackgroundImage from '../images/about/graph_chart_image.svg';
import RupayeeImage from '../images/about/rupayee_image.svg';
import DashboardImage from '../images/about/dashboard_ss.svg';
import IconeOne from '../images/about/icon_one.svg';
import IconeTwo from '../images/about/icon_two.svg';
import IconeThree from '../images/about/icon_three.svg';
import IconContentBox from '../components/IconContentBox';
import { getLocalStorageUserToken } from '../utils/userData';
import { Helmet } from 'react-helmet-async'; // Import Helmet

// Functional component for the Home page
const About = () => {
    const token = getLocalStorageUserToken();
  return (
    <>
    {/* Helmet for Dynamic SEO Metadata */}
    <Helmet>
                <title>About Us | Fundswala</title>
                <meta name="description" content="Learn more about Fundswala and how we help you make smart investment decisions with transparency and integrity." />
            </Helmet>
    <HeroSection title='' subtitle='Transforming Dreams into Financial Reality' bgImage={HeroBackgroundImage} />

    <section className="about_mission_section" id="about_mission_section">
        <div className="container-lg container-fluid px-lg-3 px-md-4">
            <div className="row align-items-center position-relative">
                <div className="col-xl-6 col-lg-7  mb-3">
                    <h1 className="section-title mb-xl-5 mb-4">Our Mission
                    </h1>
                    <p className="fund_invest_text">Fundswala is on a mission to make wealth creation simple and accessible
                        for everyone.
                        We aim to redefine how individuals approach investing by offering clear insights and easyto-use
                        tools. Whether you re new to investing or experienced, we make the process
                        intuitive and empowering.</p>

                    <p className="fund_invest_text mb-0">We understand that every individual’s financial journey is unique. That
                        s why we’re
                        dedicated to equipping you with personalized strategies that cater to your specific goals
                        and needs. Through a blend of technology, expert guidance, and tailored investment
                        plans, we enable you to confidently navigate the ever-evolving financial landscape. Our
                        mission is to empower you to take control of your financial future, providing you with the
                        knowledge and support you need to make informed decisions that align with your
                        aspirations.
                    </p>
                </div>
                <div className="col-xl-6 col-lg-5">
                    <img src={RupayeeImage} alt="" className="fund_invest_box_two position-absolute" />
                </div>
            </div>
        </div>
    </section>

    <section className="about_third_section pb-lg-5 pb-md-4 pb-3" id="about_third_section">
        <div className="container-lg container-fluid px-lg-4">
            <h1 className="section-title mb-xl-4 mb-md-4 mb-3" style={{color: '#fff'}}>Why Choose Us?
            </h1>
            <p className="section-description mb-3 ">Fundswala is more than just a financial platform it's a partner in your
                wealth creation journey.</p>

            <div className="row align-items-center mt-lg-5 mt-4 pt-4">
                <div className="col-xl-6 col-lg-6  mb-lg-3 mb-5 text-lg-start text-center">
                    <img src={DashboardImage} alt="" className="about_dashbord_img" />
                </div>
                <div className="col-xl-6 col-lg-6 ps-xl-0">
                    <div className="d-flex flex-column gap-xl-5 gap-md-3 gap-3 about_right_content ">
                       
                        
                        <IconContentBox icone={IconeOne} title='Innovation in Investing' content='We bring innovation to investing, leveraging cutting-edge technology to streamline
                        the investment process. Our platform is designed for simplicity without compromising
                        on sophistication.' />

                        <IconContentBox icone={IconeTwo} title='Transparency and Trust' content=' We believe in transparency as the cornerstone of trust. Our fee structures are straightforward, and our commitment to security ensures that your financial data is always protected.' />
                        
                        <IconContentBox icone={IconeThree} title='Tailored for You' content='No two financial journeys are the same. That’s why we focus on personalized solutions, offering a range of services like SIPs, SWPs, and Mutual Funds to cater to your unique goals and preferences.' />

                    </div>

                </div>
            </div>

            <div className="start_journey_box">
                <h5 className="heading ">Start your investment journey with Fundswala</h5>
                <p className="description ">Whether you're dreaming of early retirement, homeownership, or world travel, 
                    we're here to help you turn those dreams into tangible achievements.</p>
                   <div className="text-center">
                    <a href={token ? `/dashboard` : `/login`} className="btn mt-3 get_start_btn get_start_btn_white text-capitalize mb-2">Join Us NOW!
                    </a>
                </div>
            </div>
            
        </div>
    </section>
    </>
  );
};

export default About;
