import React from 'react';
import '../styles/mysip.css';
import SipCart from '../components/SipCarts';
import growthTree from '../images/icons/growth-tree.svg';
import featureimage from '../images/orders/logo.svg';
import LumpsumCarts from '../components/LumpsumCarts';
const MyLumpsumCart = () => {

    return (
        <div className="container-fluid page_background ">
            <div className="page-inner-content mb-3" >
                <div className="page_inner_content_box page_content_overflow">
                    <h5 className="mb-4 main-heading-title">
                    My Cart
                    </h5>

                    <div className="order_box pt-2">
                      
                        <div className='mb-4'>
                            <LumpsumCarts image={featureimage} title='Aditya Birla Sun Life PSU Equity Fund' investmentType='lumpsum' investmentName='investmentTypeLumpsum' stepUp={true}/>
                        </div>

                        <div className='cart-bottom d-flex'>
                                 <p className='mb-0'>Total Investment Amount <span className=''>₹10000</span></p>
                                 <button type="submit" className="form_button next_btn">Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyLumpsumCart;