import React from 'react';
import '../styles/CustomerSupport.css'
import { Link } from 'react-router-dom';
import raiseTicket from '../images/customer_support/raise_ticket.svg';
import arrowTicket from '../images/customer_support/arrow_right.svg';
import email from '../images/customer_support/email.svg';
import contacts from '../images/customer_support/contacts.svg';
import customerSupport from '../images/customer_support/customer_support.svg';

// Functional component for the Home page
const CustomerSupport = () => {
  return (
    <div className="container-fluid page_background ">
        <div className="page-inner-content mb-4" >
        <div className="page_inner_content_box ">
        <h5 className="mb-4 main-heading-title">Customer Support</h5>
            <form id="help_form" action="">
                <div className="card border-0" style={{borderRadius:'12px'}}>
                    <div className="card-body form_cart form_card_customer">
                    <h2 className="mb-4 looking_help d-lg-block d-none">Looking for Help?</h2>
                        <div className="row align-items-center">
                           
                            <div className="col-xl-7 col-lg-6 col-12 order-lg-1 order-2">
                                <div className="help-content">
                                    
                                    <div className="help-btn d-flex flex-column">

                                        <Link to="/ticket-message" className="btn d-flex align-items-center p-3 raise-ticket-gradient raise-all-same">
                                            <img src={raiseTicket} alt="Ticket" className="img-fluid rounded mx-xl-3 me-3  raise_ticket_img" />
                                            <span style={{overflowWrap:'anywhere'}}> Raise a Ticket </span>
                                            <img src={arrowTicket} alt="arrow" className="img-fluid rounded mx-2" />
                                        </Link>
                                        <Link to="mailto:vishal.kokate@fundswala.com" className="btn d-flex align-items-center  p-3 text-white email email-ticket-gradient raise-all-same">
                                            <img src={email} alt="Email" className="img-fluid rounded mx-xl-3 me-3 email_image" />
                                            <span style={{overflowWrap:'anywhere'}}>  vishal.kokate@fundswala.com</span>
                                        </Link>
                                        <Link to="tel:+917798777767" className="btn d-flex p-3 align-items-center  text-white phone contact-ticket-gradient raise-all-same">
                                            <img src={contacts} alt="Support" className="img-fluid rounded mx-xl-3 me-3 contact_img" />
                                           <span style={{overflowWrap:'anywhere'}}> +91 7798777767</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-12 order-lg-2 order-1 mb-md-4 mb-1">
                                <div className="help-image  text-center">
                                    
                                    <img src={customerSupport} alt="Help Desk" className="img-fluid  rounded img_customer_support" />
                                </div>
                                <h2 className="mt-3 mb-0 looking_help d-lg-none d-block text-center">Looking for Help?</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </div>
    </div>
  )
}

export default CustomerSupport;