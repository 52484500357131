import "../styles/wishlistcard.css";
import trash from "../images/icons/delete_icon.svg";
import { Link } from "react-router-dom";
import defaultFundIcon from "../images/Default icon.svg"

const WishlistCards = (props) => {
  const serializableProps = JSON.parse(JSON.stringify(props));
  return (
    <div className="card wishlist_card overflow-hiddden card-bg-white border-0">
      <div className="card-body position-relative">
        <div>
          <img
            src={props.image}
            alt="Fund Logo"
            className="fund-logo me-lg-3 me-4 mb-sm-0 mb-2"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop if default image fails
              e.target.src = defaultFundIcon;
            }}
          />
        </div>
        <div>
          <div className="fund-details-info d-flex align-items-top ">
            <div>
              <Link
                to="/explore/fund-detail"
                state={{ data: props.amcId }}
              >
                <h6 className="fund-name-header">{props.title}</h6>
              </Link>
              <div className="d-flex flex-wrap gap-2">
                <span
                  className={`badge custom-badge ${props.badgecolor}-badge`}
                >
                  {props.badge?.toUpperCase()}
                </span>
                <span
                  className={`badge custom-badge ${props.badgecolor2}-badge`}
                >
                  {props.badge2?.toUpperCase()}
                </span>
              </div>
            </div>

            <div
              className="ms-auto d-flex custom_dropdown dropdown"
              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"
            >
              <Link
                className="btn_delete me-2"
                onClick={() => props.onDelete(props.id)}
              >
                <img src={trash} alt="" />
              </Link>
            </div>
          </div>
          <div className="d-flex flex-wrap column-gap-xxl-5 column-gap-xl-5 column-gap-lg-5 column-gap-4  row-gap-lg-3  row-gap-3 mt-3  ">
            <p className="investment-details mb-0 ">
              Current NAV <span className="d-block">{props.price1}</span>
            </p>
            <p className="investment-details mb-0">
              SIP <span className="d-block">{props.value}</span>
            </p>
            <p className="investment-details mb-0">
              Lumpsum<span className="d-block">{props.value2}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistCards;
