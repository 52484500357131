import React, { useEffect, useRef } from "react";
import "../styles/notification.css";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationList, markAsRead } from "../actions/notificationAction";
import { getLocalStorageUserData } from "../utils/userData";

const NotificationPanel = ({ isOpen, onClose, notificationRef }) => {
  const isMounted = useRef(false); 
  const dispatch = useDispatch();
  const controller = new AbortController();
  const notifications = useSelector(
    (state) => state.notification?.notificationList?.data?.notifications || []
  );
  const userData = getLocalStorageUserData(); // Fetch user data from local storage

  // Fetch notifications when the panel is opened
  useEffect(() => {
    if (isOpen && userData?.id) {
      const payload = { id: userData.id };
      dispatch(getNotificationList(payload));
    }
  }, [isOpen, userData?.id, dispatch]);

  // useEffect(()=>{
  //   const fetchData = async()=>{
  //     const payload = { id: userData?.id };
  //     try {
  //       if (isOpen && userData?.id) {
  //            await Promise.all([dispatch(getNotificationList(payload))]);
  //           }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   // if (isMounted.current) {
  //     fetchData();
  //   // } else {
  //   //   isMounted.current = true;
  //   // }
  //   return () => {
  //     controller.abort(); // Abort the ongoing request on cleanup
  //   };
  // },[dispatch , userData?.id])

  // Mark notification as read
  const markAsReadHandler = (notificationId) => {
    if (userData?.id && notificationId) {
      const payload = { id: userData?.id, notification_id: notificationId };
      dispatch(markAsRead(payload));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="notification-panel" ref={notificationRef}>
      <div className="notification-header">
        <h4>Notifications</h4>
        <IoMdClose className="close-btn" onClick={onClose} />
      </div>

      <div className="notification-content">
        {notifications.length ? (
          notifications.map((notification) => (
            <div
              key={notification.id}
              className={`notification-item ${
                notification.read_at ? "read" : "unread"
              }`}
              onClick={() => markAsReadHandler(notification?.id)}
            >
              <div className="notification-details">
                <h6>{notification?.data?.model}</h6>
                <p>{notification?.data?.message}</p>
                <small>
                  {new Date(notification?.created_at).toLocaleString()}
                </small>
              </div>
            </div>
          ))
        ) : (
          <p className="no-notifications">No notifications available</p>
        )}
      </div>
    </div>
  );
};

export default NotificationPanel;
