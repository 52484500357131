import "../styles/fundplan3.css";
import growthTree from "../images/icons/growth-tree.svg";
import { Link } from "react-router-dom";
import ActionPopupButton from "./ActionPopupButton";
import defaultFundIcon from "../images/Default icon.svg";

const RecommendedFundPlanDesign4 = (props) => {
  return (
    <div className="card fund-card-four overflow-hiddden card-bg-white border-0">
      <div className="card-body position-relative">
        <div className="d-flex align-items-md-start align-items-center">
          <img
            src={props.image ? props.image : props.image}
            alt="Fund Logo"
            className="fund-logo me-lg-3 me-3 mb-sm-0 mb-2"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop if default image fails
              e.target.src = defaultFundIcon;
            }}
          />
          <Link
            // to={props.url}
            className="d-md-none d-block"
            // state={{ data: { ...props?.item, amcId: props?.amcId } }}
            style={{ pointerEvents: "none" }}
          >
            <h6 className="fund-name-header mb-0">{props.title}</h6>
          </Link>
        </div>
        <div>
          <div className="fund-details-info d-flex align-items-top ">
            <div>
              <Link
                // to={props.url}
                className="d-md-block d-none"
                // state={{ data: { ...props?.item, amcId: props?.amcId } }}
                style={{ pointerEvents: "none" }}
              >
                <h6 className="fund-name-header cursor-pointer">
                  {props.title}
                </h6>
              </Link>
              <div className="d-flex flex-wrap gap-2">
                <span className={`badge custom-badge green-badge`}>
                  {props.badge?.toUpperCase()}
                </span>
                <span className={`badge custom-badge purple-badge`}>
                  {props?.badge1?.toUpperCase()}
                </span>
              </div>
            </div>
            <ActionPopupButton
              filterColor={props?.actionButton?.filterColor}
              item={props?.actionButton?.item}
            />
            {/* {props.actionButton} */}
          </div>
          <div className="d-flex flex-wrap  column-gap-xl-5 column-gap-lg-5 column-gap-4 row-gap-lg-3 row-gap-3 mt-3">
            <p className="investment-details mb-0">
              Folio No.{" "}
              <span className="d-block text-value">{props.price1}</span>
            </p>
            <p className="investment-details mb-0">
              Type <span className="d-block text-value">{props.price2}</span>
            </p>
            <p className="investment-details mb-0">
              Invested Value{" "}
              <span className="d-block text-value">{props.price3}</span>
            </p>
            <p className="investment-details mb-0">
              Market Value{" "}
              <span className="d-block text-value">{props.price4}</span>
            </p>
            <p className="investment-details mb-0">
              Gain/Loss
              <span
                className="d-block text-value"
                style={{
                  color: String(props.price5 ?? "0").startsWith("-")
                    ? "red"
                    : "#00ac78",
                }}
              >
                {props.price5 ?? "0.00"}
              </span>
            </p>
            <p className="investment-details mb-0">
              Units <span className="d-block text-value">{props.units}</span>
            </p>
            <p className="investment-details mb-0">
              SIP Date{" "}
              <span className="d-block text-value">{props.price6}</span>
            </p>
            <p className="investment-details mb-0">
              SIP Amount{" "}
              <span className="d-block text-value">{props.price7}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedFundPlanDesign4;
