import "../styles/HoldingSection.css";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import FundPlanDesignStp from "./FundPlanDesignStp";

const HoldingSectionStp = ({ data }) => {
  return (
    <div>
      <div className="mb-3">
        {data?.current_holdings?.length > 0 ? (
          data?.current_holdings?.map((res) => {
            return (
              <FundPlanDesignStp
                bookmark={true}
                plus={true}
                title={res?.from_scheme}
                title2={res?.to_scheme}
                badge="Growth"
                folioNo={res?.folio_no}
                price={`₹${res?.amount}`}
                datefrom={res?.from_date}
                dateto={res?.to_date}
                bgcolor={"primary"}
                badgecolor="green"
                badgecolor2="purple"
              />
            );
          })
        ) : (
          <p>No STP data available</p>
        )}
      </div>
    </div>
  );
};

export default HoldingSectionStp;
