import React, { useEffect, useRef, useState } from "react";
import HoldingSectionAll from "./HoldingSectionAll";
import HoldingSectionSip from "./HoldingSectionSip";
import HoldingSectionSwp from "./HoldingSectionSwp";
import HoldingSectionStp from "./HoldingSectionStp";
import HoldingSectionLumpsum from "./HoldingSectionLumpsum";
import Pagination from "./Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  holdingsDetails,
  holdingsTypesDetails,
} from "../actions/holdingAction";
import { useLocation } from "react-router-dom";
import Loader from "../loader/Loader-ui";
import { getLocalStorageUserData } from "../utils/userData";

const HoldingSectionSidebar = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [type, setType] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const isMounted = useRef(false);
  const holdingsData = useSelector(
    (state) => state.holdings?.holdingData?.data
  );
  const valuationData = useSelector((state) => state.holdings);
  const filteredHoldingsData = useSelector(
    (state) => state.holdings?.holdingTypesData?.data
  );
  const userData = getLocalStorageUserData();
  const controller = new AbortController();
  const isDashboard = location.pathname === "/dashboard";
  const GoalData = useSelector(
    (state) => state.holdings?.dashboardDatas?.data?.goals
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        id: userData?.id,
        perpage: 10,
        pagenum: currentPage,
      };
      try {
        setLoading(true);
        await Promise.all([dispatch(holdingsDetails(payload))]);
      } catch (error) {
        console.error("API call failed: ", error);
      } finally {
        setLoading(false);
      }
    };
    // if (isMounted.current) {
    fetchData();
    // } else {
    //   isMounted.current = true;
    // }
  }, [dispatch, userData?.id, currentPage]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const payload = {
  //       id: userData?.id,
  //       perpage: 10,
  //       pagenum: currentPage,
  //       ...(activeTab !== "All" && { type: activeTab }),
  //     };
  //     try {
  //       setLoading(true);
  //       if (activeTab !== "All") {
  //         // Prevent dispatch when activeTab is 'All'
  //         await Promise.all([dispatch(holdingsTypesDetails(payload))]).then((response)=>{
  //           console.log("response",response);
  //           if (response?.length > 0) {
  //             setType(response);
  //             setLoading(false);
  //           } else {
  //             setType([]);
  //             setLoading(false);
  //           }
  //         });
  //       }
  //     } catch (error) {
  //       console.error("API call failed: ", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   // if (isMounted.current) {
  //   fetchData();
  //   // } else {
  //   //   isMounted.current = true;
  //   // }
  //   // return () => {
  //   //   controller.abort(); // Abort the ongoing request on cleanup
  //   // };
  // }, [dispatch, userData?.id, activeTab, currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      if (activeTab === "All" || type[activeTab]) return; // Skip if data exists
      const payload = {
        id: userData?.id,
        perpage: 10,
        pagenum: currentPage,
        type: activeTab,
      };
      try {
        setLoading(true);
        const response = await dispatch(holdingsTypesDetails(payload));
        if(isDashboard){
          setType((prev) => ({ ...prev, [activeTab]: response?.data || [] }));
          setIsFirstLoad((prev) => ({ ...prev, [activeTab]: true })); // Mark as loaded
        }
       
      } catch (error) {
        console.error("API call failed: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [activeTab, currentPage, dispatch, type, userData?.id, isDashboard]);

  console.log("type", type);

  useEffect(() => {
    if (activeTab === "All" && holdingsData) {
      setTotalPages(holdingsData?.total_page || 1);
    } else if (filteredHoldingsData) {
      setTotalPages(filteredHoldingsData?.total_page || 1);
    }
  }, [activeTab, holdingsData, filteredHoldingsData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderTabContent = () => {
    if (
      loading &&
      !isFirstLoad[activeTab]
    ) {
      return (
        <div className="loader-overlay">
          <Loader />
        </div>
      );
    }
    switch (activeTab) {
      case "All":
        return (
          <HoldingSectionAll data={holdingsData} isDashboard={isDashboard} />
        );
      case "SIP":
        return (
          <HoldingSectionSip
            // data={filteredHoldingsData || []}
            data={isDashboard ? type["SIP"] : filteredHoldingsData || []}
            isDashboard={isDashboard}
          />
        );
      case "STP":
        return (
          <HoldingSectionStp
            // data={filteredHoldingsData || []}
            data={isDashboard ? type["STP"] : filteredHoldingsData || []}
            isDashboard={isDashboard}
          />
        );
      case "SWP":
        return (
          <HoldingSectionSwp
            // data={filteredHoldingsData || []}
            data={isDashboard ? type["SWP"] : filteredHoldingsData || []}
            isDashboard={isDashboard}
          />
        );
      case "Lumpsum":
        return (
          <HoldingSectionLumpsum
            // data={filteredHoldingsData || []}
            data={isDashboard ? type["Lumpsum"] : filteredHoldingsData || []}
            isDashboard={isDashboard}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="holdings-container gap-xl-4 gap-4 mb-3">
        {/* Tabs */}
        <div className="d-flex flex-md-row flex-lg-column flex-xl-column flex-wrap holding-recommendedfundplan-left row-gap-2 column-gap-2 gap-md-3 gap-lg-1 gap-xl-1 pe-lg-4">
          {["All", "SIP", "STP", "SWP", "Lumpsum"].map((tab) => (
            <button
              key={tab}
              className={`sidebar-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="holding-recommendedfundplan-right">
          {renderTabContent()}
        </div>
      </div>

      {!isDashboard && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default HoldingSectionSidebar;
