import React from "react";
import "../styles/profileAccordian.css"; // Import styles
import BankDetailAdd from "./BankDetailAdd";
import BankDetail from "./BankDetail";
import PersonelDetail from "./PersonelDetail";
import BankDetailEdit from "./BankDetailEdit";
import FatcaDeclaration from "./FatcaDeclaration";
import FatcaDeclarationEdit from "./FatcaDeclarationEdit";
import ManageNominees from "./ManageNominees";
import AddNominee from "./AddNominee";
import EnachRegistration from "./EnachRegistration";
import EnachRegistrationAdd from "./EnachRegistrationAdd";
import PersonelDetailEdit from "./PersonelDetailEdit";

const MyProfileAccordian = () => {
  return (
    <div className="accordion profile_accordian my-lg-3" id="accordionExample">
      {/* Personal Details Accordion */}

      <PersonelDetail />
      {/* Personal edit Accordion */}
      {/* <PersonelDetailEdit/>   */}

      {/* =============================== !/}

      {/* Bank Details Accordion */}

      <BankDetail />
      {/* Bank Details add Accordion */}

      {/* <BankDetailAdd /> */}
      {/* Bank Details Edit Accordion */}
      {/* <BankDetailEdit /> */}

      {/* =============================== !/}

      {/* FatcaDeclaration  Accordion */}
      <FatcaDeclaration />
      {/* FatcaDeclaration Edit Accordion */}
      {/* <FatcaDeclarationEdit /> */}

      {/* =============================== !/}

      {/* ManageNominees Accordion */}
      <ManageNominees />
      {/* ManageNominees Accordion */}
      {/* <AddNominee /> */}

      {/* =============================== !/}

      {/* Enach Registration Accordion */}
      <EnachRegistration />
      {/* Enach Registration Accordion */}
      {/* <EnachRegistrationAdd /> */}
    </div>
  );
};

export default MyProfileAccordian;
