import { useEffect, useState } from "react";
import "../styles/fundplanSip.css";
import featureimage from "../images/orders/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { sipCancel, sipPause, sipPeriod } from "../actions/holdingAction";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { getLocalStorageUserData } from "../utils/userData";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const FundPlanDesignSip = (props) => {
  const dispatch = useDispatch();
  const cancelSIP = useSelector((state) => state.holdings);
  const [holdings, setHoldings] = useState([]);
  // const [cancelSip, setCancelSip] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalClose, setIsModalClose] = useState(false);
  const [durationOptions, setDurationOptions] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOtherReason, setIsOtherReason] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState("");
  // const [otherReason, setOtherReason] = useState('');
  useEffect(() => {
    if (props?.holdings_data) {
      setHoldings(props?.holdings_data);
    }
  }, [props?.holdings_data]);
  const userData = getLocalStorageUserData();
  const duration = cancelSIP?.sipPeriod?.data;

 

  const handlePause = async (values) => {
    const payload = {
      user_id: userData?.id,
      transaction_id: props?.auto_trxn_no,
      from_month: values?.selectedMonth,
      number_of_months: values?.selectedDuration,
    };

    setLoading(true);
    await dispatch(sipPause(payload))
      .then((response) => {
        console.log("response",response);
        if(response.status == false){
          toast.error(response.message);
        }
        else{
          toast.success("SIP Pause Successfully");
        }
        setIsModalOpen(false);
        window?.location?.reload();
      })
      .catch((error) => {
        console.error("API call failed: ", error);
      })
      .finally(() => {
        setLoading(false);
        setIsModalOpen(false);
      });
    // dispatch(sipPause(payload));
    // setIsModalOpen(false);
    // window?.location?.reload();
    // setLoading(false);
  };

  const handlePauseClick = async () => {
    setIsModalOpen(true);
    const payload = {
      user_id: userData?.id,
      transaction_id: props?.auto_trxn_no,
      amc_product_id: props?.amc_product_id,
    };
    await dispatch(sipPeriod(payload));
  };

  const validationSchema = Yup.object().shape({
    selectedMonth: Yup.number()
      .required("Please select a month")
      .min(1, "Invalid month selection"),
    selectedDuration: Yup.string().required("Please select a duration"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form values:", values);
    handlePause(values); // Call your pause function
    setSubmitting(false);
  };

  const initialValues = {
    cancelReason: "",
    cancelSip: "",
    otherReason: "",
  };

  const validationCancelSchema = Yup.object({
    cancelReason: Yup.string().required(
      "Please select a SIP Cancellation Reason."
    ),
    cancelSip: Yup.string().required(
      "Please confirm your decision (Yes or No)."
    ),
    otherReason: Yup.string().when("cancelReason", (cancelReason, schema) => {
      return cancelReason[0] === "SC13"
        ? schema.required("Please enter a specific reason")
        : schema;
    }),
  });

  const onSubmit = (values) => {
    const payload = {
      cancelReason: values.cancelReason,
      otherReason: values.otherReason,
      cancelSip: values.cancelSip,
    };
    console.log("Form Data: ", values);
    handleClick(payload);
    setIsModalOpen(false);
  };

  // const handleRadioChange = (e) => {
  //   setCancelSip(e?.target?.id); // Update state when radio button is selected
  // };
  // useEffect(() => {
  //   // Optionally, you can set an initial value if required (e.g., first option or any logic)
  //   if (props?.cease_reason_codes?.length > 0) {
  //     setSelectedCancelReason(props?.cease_reason_codes[0]?.reason_code);
  //   }
  // }, [props?.cease_reason_codes]);
  const handleClick = async (values) => {
    const payload = {
      user_id: userData?.id,
      transaction_id: props?.auto_trxn_no,
      reason: values.otherReason,
    };
    if (values?.cancelSip === "No") {
      payload.cancelSip = "No";
    }
    setLoading(true);
    await dispatch(sipCancel(payload))
      .then((response) => {
        if(response.status == false){
          toast.error(response.message);
        }
        else{
          toast.success("SIP Cancel Successfully");
        }
        window?.location?.reload();
      })
      .catch((error) => {
        console.error("API call failed: ", error);
      })
      .finally(() => {
        setLoading(false);
        setIsModalOpen(false);
      });
    // dispatch(sipCancel(payload));
    // setIsModalOpen(false);
    // window.location.reload();
  };

  const handleCancelReasonChange = (e) => {
    setSelectedCancelReason(e.target?.value);
    if (e.target?.value !== "SC13") {
      // setOtherReason('');
    }
  };

  const handleOtherReasonChange = (e) => {
    // setOtherReason(e.target.value);
  };

  return (
    <div className="card fund-card-sip overflow-hidden border-0">
      <div className="card-body p-0">
        <div className="accordion sip-accordian" id="fundAccordion">
          <div className="accordion-item sip-accordian-items mb-4">
            <h2 className="accordion-header" id={`heading-${props?.id}`}>
              <button
                className={`accordion-button fund-header ${
                  props?.activeId === props?.id ? "" : "collapsed"
                }`}
                type="button"
                onClick={() => props?.onToggleAccordion(props?.id)}
                aria-expanded={props?.activeId === props?.id ? "true" : "false"}
                aria-controls={`collapse-${props?.id}`}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={props?.image}
                    alt="Logo"
                    className="fund-logo me-3"
                  />
                  <span className="fund-name-header cursor-pointer pe-3 d-sm-none d-block">
                    {props?.title}
                  </span>
                </div>
                <span className="fund-name-header cursor-pointer pe-3 d-sm-block d-none">
                  {props?.title}
                </span>
                {/* <div className="d-flex align-items-center gap-2 pe-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target={`#pauseModal-${props.id}`}
                  >
                    Pause
                  </button>
                  <button
                    type="button"
                    className="custom-status"
                    data-bs-toggle="modal"
                    data-bs-target={`#cancelModal-${props.id}`}
                  >
                    Cancel
                  </button>
                </div> */}
                <div className="d-flex align-items-center gap-2 pe-4">
                  {props?.allow_pause_sip === "Y" &&
                    props?.auto_trxn_no !== null &&
                    props?.ceased_status !== "true" &&
                    (props?.pause_status === "true" ? (
                      <button type="button" className="btn btn-primary">
                        Paused
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target={`#pauseModal-${props?.id}`}
                        onClick={handlePauseClick}
                      >
                        Pause
                      </button>
                    ))}
                  {props?.ceased_status === "true" ? (
                    <button type="button" className="btn  btn-primary">
                      <span className="navi-text">Canceled</span>
                    </button>
                  ) : (
                    props?.auto_trxn_no !== null && (
                      <button
                        type="button"
                        className="btn  btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target={`#cancelModal-${props?.id}`}
                      >
                        <span className="navi-text">Cancel</span>
                      </button>
                    )
                  )}
                </div>
              </button>
            </h2>
            <div
              id={`collapse-${props?.id}`}
              className={`accordion-collapse collapse ${
                props?.activeId === props?.id ? "show" : ""
              }`}
              aria-labelledby={`heading-${props?.id}`}
            >
              <div className="table-responsive">
                <table className="table sip-accordian-table">
                  <tbody>
                    {holdings.map((item, index) => (
                      <tr key={index} className="table_row">
                        <td className="text-start table_data">
                          Folio No.{" "}
                          <span className="d-block data_span">
                            {item?.folio_no || "-"}
                          </span>
                        </td>
                        <td className="text-start table_data">
                          Invested Value{" "}
                          <span className="d-block data_span">
                            {item?.amount || "-"}
                          </span>
                        </td>
                        <td className="text-start table_data">
                          Market Value{" "}
                          <span className="d-block data_span">
                            {item?.current_val || "-"}
                          </span>
                        </td>
                        <td className="text-start table_data">
                          Gain/Loss{" "}
                          <span className="d-block data_span">
                            {item?.gainLoss}
                            <span
                              className="text_danger"
                              style={{
                                color:
                                  item?.returnsPercentage < 0
                                    ? "red"
                                    : "#00ac78",
                              }}
                            >
                              {item?.returnsPercentage}
                            </span>
                          </span>
                        </td>
                        <td className="text-start table_data">
                          Units{" "}
                          <span className="d-block data_span">
                            {item?.units || "-"}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Pause Modal */}
          {/* <div
            className="modal fade modal_card_inner"
            id={`pauseModal-${props?.id}`}
            tabIndex="-1"
            aria-labelledby={`pauseModalLabel-${props?.id}`}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered p-3">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id={`pauseModalLabel-${props?.id}`}
                  >
                    SIP Pause
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <img
                    src={props?.image}
                    alt="Logo"
                    className="fund-logo me-3"
                  />
                  <span className=" cursor-pointer">{props?.title}</span>
                  <br />
                  <Formik
                    initialValues={{ selectedMonth: "", selectedDuration: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="mb-3 mt-5">
                          <label htmlFor="selectMonth" className="form-label">
                            Select Month <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            className="form-select"
                            id="selectedMonth"
                            name="selectedMonth"
                          >
                            <option value="">Select month</option>
                            {[...Array(12).keys()].map((i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="selectedMonth"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="selectDuration"
                            className="form-label"
                          >
                            Select Duration{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            as="select"
                            className="form-select"
                            id="selectedDuration"
                            name="selectedDuration"
                          >
                            <option value="">Select duration</option>
                            <option value={duration?.tenure}>
                              {duration?.tenure}
                            </option>
                          </Field>
                          <ErrorMessage
                            name="selectedDuration"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="form_button next_btn"
                            disabled={isSubmitting || loading}
                          >
                            {isSubmitting || loading ? "Saving..." : "Save"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div> */}
          <div
  className="modal fade modal_card_inner"
  id={`pauseModal-${props?.id}`}
  tabIndex="-1"
  aria-labelledby={`pauseModalLabel-${props?.id}`}
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered p-3">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id={`pauseModalLabel-${props?.id}`}>
          SIP Pause
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <img
          src={props?.image}
          alt="Logo"
          className="fund-logo me-3"
        />
        <span className="cursor-pointer">{props?.title}</span>
        <br />
        <Formik
          initialValues={{ selectedMonth: "", selectedDuration: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3 mt-5">
                <label htmlFor="selectMonth" className="form-label">
                  Select Month <span className="text-danger">*</span>
                </label>
                <Field
                  as="select"
                  className="form-select"
                  id="selectedMonth"
                  name="selectedMonth"
                >
                  <option value="">Select month</option>
                  {[...Array(12).keys()].map((i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="selectedMonth"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="selectDuration" className="form-label">
                  Select Duration <span className="text-danger">*</span>
                </label>
                <Field
                  as="select"
                  className="form-select"
                  id="selectedDuration"
                  name="selectedDuration"
                >
                  <option value="">Select duration</option>
                  <option value={duration?.tenure}>
                    {duration?.tenure}
                  </option>
                </Field>
                <ErrorMessage
                  name="selectedDuration"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="form_button next_btn"
                  data-bs-dismiss="modal" // Modal close karne ke liye attribute
                  disabled={isSubmitting || loading}
                >
                  {isSubmitting || loading ? "Saving..." : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  </div>
</div>

          {/* Cancel Modal */}
          <div
            className={`modal fade modal_card_inner ${
              isModalClose ? "show" : ""
            }`}
            id={`cancelModal-${props?.id}`}
            tabIndex="-1"
            aria-labelledby={`cancelModalLabel-${props?.id}`}
            aria-hidden={isModalClose ? "false" : "true"}
            style={{ display: isModalClose ? "block" : "none" }}
          >
            <div className="sip-modal modal-dialog modal-dialog-centered p-3">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id={`cancelModalLabel-${props?.id}`}
                  >
                    SIP Cancel
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setIsModalOpen(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <img
                    src={props?.image}
                    alt="Logo"
                    className="fund-logo me-3"
                  />
                  <span className=" cursor-pointer">{props?.title}</span>
                  <br />
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationCancelSchema}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting, values }) => (
                      <Form>
                        <label
                          htmlFor={`cancelReason-${props?.id}`}
                          className="form-label mt-5"
                        >
                          SIP Cancellation Reason:{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          as="select"
                          className="form-select"
                          name="cancelReason"
                          id={`cancelReason-${props?.id}`}
                          value={values.cancelReason}
                        >
                          <option value="">
                            Please Select SIP Cancellation Reason
                          </option>
                          {props?.cease_reason_codes?.map((reason) => (
                            <option
                              key={reason?.id}
                              value={reason?.reason_code}
                            >
                              {reason?.reason_detail}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="cancelReason"
                          component="div"
                          className="text-danger mt-1"
                        />

                        {props?.cease_reason_codes?.find(
                          (reason) =>
                            reason.reason_code === values.cancelReason &&
                            reason.reason_detail === "Others (Please specify)"
                        ) && (
                          <div className="mb-3 mt-4">
                            <label htmlFor="otherReason" className="form-label">
                              Please specify the reason:{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              id="otherReason"
                              name="otherReason"
                              placeholder="Enter your reason"
                            />
                            <ErrorMessage
                              name="otherReason"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        )}

                        <div>
                          <label
                            htmlFor={`cancelSip-${props?.id}`}
                            className="form-label mt-5"
                          >
                            Are you sure you want to cancel this SIP?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <br />
                          <div className="form-check">
                            <Field
                              type="radio"
                              className="form-check-input"
                              name="cancelSip"
                              value="Yes"
                              id="Yes"
                            />
                            <label className="form-check-label" htmlFor="Yes">
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <Field
                              type="radio"
                              className="form-check-input"
                              name="cancelSip"
                              value="No"
                              id="No"
                            />
                            <label className="form-check-label" htmlFor="No">
                              No
                            </label>
                          </div>
                          <ErrorMessage
                            name="cancelSip"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="modal-footer">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="form_button next_btn"
                          >
                            Save
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundPlanDesignSip;
