import threeDotImage from "../images/dashboard/three_dot_img.svg";
import invest_img from "../images/icons/invest_icon.svg";
import redeem_img from "../images/icons/redeem_icon.svg";
import stp_img from "../images/icons/stp_icon.svg";
import swp_img from "../images/icons/swp_icon.svg";
import switch_img from "../images/icons/switch.svg";
import { Link, useNavigate } from "react-router-dom";
import { AddToMyCart } from "../actions/exploreFundsActions";
import PopupModel from "./PopupModel";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalStorageUserData,
  getLocalStorageUserToken,
  redirectToLoginIfNoToken,
} from "../utils/userData";
import { useEffect, useState } from "react";

const ActionPopupButton = (props) => {
  const dispatch = useDispatch(); // Initialize dispatch
  const { addMyCart } = useSelector((state) => state.exploreFunds);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const userData = getLocalStorageUserData();
  const token = getLocalStorageUserToken();

  const AddToCart = (invest) => {
    redirectToLoginIfNoToken();
    if (!token) return;
    const payload = {
      id: userData.id,
      product_id: props?.item?.product_id,
      amc_product_id: props?.item?.amc_product_id,
      amc_code: props?.item?.product?.amc_code,
      product_code: props?.item?.product?.product_code,
      isin: props?.item?.product?.isin,
      is_additional_purchase: invest == 1 ? true : false,
      reinvest: props?.item?.product?.reinvest_tag,
    };
    dispatch(AddToMyCart(payload));
  };

  useEffect(() => {
    if (addMyCart?.status == true || addMyCart?.status == false) {
      setIsPopupVisible(true);
    }
  }, [addMyCart]);

  const closeModal = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="ms-auto d-flex custom_dropdown2 dropdown">
      <a
        className="nav-link dropdown-toggle p-0"
        href="/"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ paddingRight: 0 }}
      >
        <img
          src={threeDotImage}
          alt="bookmark"
          class={props.filterColor ? "dropdown_filter_color" : ""}
        />
      </a>
      <div
        className="dropdown-menu dropdown-menu-end"
        aria-labelledby="navbarDropdown"
      >
        <Link
          className="dropdown-item d-flex align-items-center py-2"
          // to="/sip-lumpsum-cart"
          onClick={() => {
            AddToCart(1);
          }}
        >
          <img src={invest_img} alt="Invest" className="dropdown_img" />
          Invest More
        </Link>
        {props?.item?.units > "0.00" ?
        <>
        <Link
          className="dropdown-item d-flex align-items-center py-2"
          to="/redeem"
          state={{ props: props.item }}
        >
          <img src={redeem_img} alt="Redeem" className="dropdown_img" />
          Redeem
        </Link>
        <Link
          className="dropdown-item d-flex align-items-center py-2"
          to="/stp"
          state={{ props: props.item }}
        >
          <img src={stp_img} alt="STP" class="dropdown_img" />
          STP
        </Link>
        <Link
          className="dropdown-item d-flex align-items-center py-2"
          to="/swp"
          state={{ props: props.item }}
        >
          <img src={swp_img} alt="SWP" className="dropdown_img" />
          SWP
        </Link>
        <Link
          className="dropdown-item d-flex align-items-center py-2"
          to="/switch"
          state={{ props: props.item }}
        >
          <img src={switch_img} alt="SWP" className="dropdown_img" />
          Switch
        </Link>
        </>
        : ""}
      </div>
    </div>
  );
};

export default ActionPopupButton;
