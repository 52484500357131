import '../styles/redeem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import RecommendedFundCard from '../components/RecommendedFundPlan2';
import ChartCard from '../components/ChartCard';
import SliderComponent from '../components/SliderComponent';
import SelectDropDown from '../components/SelectDropDown';
import InvestButton from '../components/InvestButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { addOrUpdateGoal, getGoalsType, goalAmountCalculation, recommendedFunds } from '../actions/goalsActions';
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { debounce } from "lodash";
import {getLocalStorageUserData} from "../utils/userData";


const GoalAdd = () => {
  const location = useLocation();  
  const sectionRef = useRef(null);
  const isMounted = useRef(false); 
  const dispatch = useDispatch();
  const goalType = useSelector((state) => state?.goals?.goalType?.data?.goals);
  const goal = useSelector((state) => state.goals);
  const fundsData = goal?.recommendedFunds?.data?.funds || [];
  const [options, setOptions] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState("");
  const [chartData, setChartData] = useState(null);
  const [page, setPage] = useState(1); 
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [error , setError] = useState("");
  const [fundsData1, setFundsData] = useState([]);
  const [sliderData, setSliderData] = useState({
    amount: 10000,
    expected_rate: 1,
    tenure: 1,
    inflation: 0,
  });

    const handleGoalChange = (value) => {
        setSelectedGoal(value);
      };
      const handleChartDataUpdate = (newChartData) => {
        setSliderData((prevData) => ({
            ...prevData,
            ...newChartData,
        }));
    };
    
    const userData = getLocalStorageUserData();
    useEffect(() => {
      const fetchData = async () =>{
        const payload = { id: userData?.id };
        try {
          setLoading(true);
          await dispatch(getGoalsType(payload)); 
        } catch (error) {
          console.error("API call failed: ", error);
        }
        finally{
          setLoading(false);
        }
      }
      // if (isMounted.current) {
        fetchData();
      // } else {
      //   isMounted.current = true;
      // }
    }, [dispatch, userData?.id]);

    useEffect(() => {
      const fetchFunds = async () => {
    
        const payload = {
          amount: sliderData?.amount,
          expected_rate_of_interest: sliderData?.expected_rate,
          period_years: sliderData?.tenure,
          inflation_rate: sliderData?.inflation,
          perpage: 9999,
          pagenum: page,
        };
    
        try {
          setLoading(true);
          await dispatch(recommendedFunds(payload));
        }catch (error) {
          console.error("API call failed: ", error);
        }
        finally {
          setLoading(false);
        }
      };
    
      // if (isMounted.current) {
        fetchFunds();
      // } else {
      //   isMounted.current = true;
      // }
    }, [dispatch, sliderData, page]); 

   
    useEffect(() => {
        if (goalType && Array.isArray(goalType)) {
            const formattedOptions = goalType?.map((item) => ({
                label: item, // Display value
                value: item?.toLowerCase()?.replace(/ /g, "_"), 
            }));
            
            setOptions(formattedOptions);
        }
    }, [goalType]);

    const validationSchema = Yup.object({
        goal: Yup.string().required("Goal is required"),
        // amount: Yup.number().min(10000, "Amount must be at least 10000").required("Amount is required"),
      });
  
    const navigate = useNavigate();
    const addGoals = async(values) => {
      if (sliderData.amount < 10000) {
        setError("Amount must be at least 10000");
        return;
      } else {
        setError(""); // Clear the error if amount is valid
      }
  
      const payload = {
          id: userData?.id, 
          goal: selectedGoal, 
          expected_rate: sliderData?.expected_rate, 
          tenure: sliderData?.tenure,
          amount: sliderData?.amount,
          inflation: sliderData?.inflation,
      };
  
      try {
          await dispatch(addOrUpdateGoal(payload));
          toast.success("Goal Added Successfully");
  
          // Navigate only after goal is added successfully
          const fromPage = location.state?.from || "goals"; // Default "goals"
          navigate(`/${fromPage}`, { replace: true });
          window.location.reload();
      } catch (error) {
          console.error("Error adding goal:", error);
          toast.error("Failed to add goal");
      }
  };

  useEffect(()=>{
    const payload={
      id: userData?.id, 
      goal: selectedGoal, 
      rate: sliderData?.expected_rate, 
      tenure: sliderData?.tenure,
      amount: sliderData?.amount,
      inflation: sliderData?.inflation,
    }
    dispatch(goalAmountCalculation(payload));
  },[dispatch, userData?.id, selectedGoal]);

  const backLink = location?.state?.from === "dashboard" ? "/dashboard" : "/goals";

      const investmentMonthly = goal?.goalsAmountCalucalation?.data?.investment_monthly_total || 0;
      const interestMonthly = goal?.goalsAmountCalucalation?.data?.interest_monthly || 0;
    
      const investmentYearlyTotal = goal?.goalsAmountCalucalation?.data?.investment_yearly_total || 0;
      const interestYearly = goal?.goalsAmountCalucalation?.data?.interest_yearly || 0;
    
      const investmentOneTime = goal?.goalsAmountCalucalation?.data?.investment_one_time || 0;
      const interestOneTime = goal?.goalsAmountCalucalation?.data?.interest_one_time || 0;

      const perMonthData = {
        labels: ['Total Investment', 'Total Interest'],
        datasets: [
          {
            data: [investmentMonthly, interestMonthly], 
            backgroundColor: ['#3583FF', '#34D3B7'], 
            hoverBackgroundColor: ['#3583FF', '#34D3B7'],
          },
        ],
      };
    
   
      const perYearData = {
        labels: ['Total Investment', 'Total Interest'],
        datasets: [
          {
            data: [investmentYearlyTotal, interestYearly], 
            backgroundColor: ['#3583FF', '#34D3B7'],
            hoverBackgroundColor: ['#3583FF', '#34D3B7'],
          },
        ],
      };
    
    
      const oneTimeData = {
        labels: ['Total Investment', 'Total Interest'],
        datasets: [
          {
            data: [investmentOneTime, interestOneTime], 
            backgroundColor: ['#3583FF', '#34D3B7'], 
            hoverBackgroundColor: ['#3583FF', '#34D3B7'],
          },
        ],
      };
      const option = [
        { value: "buy_house/property", label: "Buy House/Property" },
        { value: "buy-vehicle", label: "Buy Vehicle" },
        { value: "start-business", label: "Start a Business" },
        { value: "marriage", label: "Marriage" },
        { value: "education-child", label: "Education of Child/Children" },
        { value: "vacation", label: "Vacation" },
        { value: "retirement", label: "Retirement" }
      ];

      useEffect(() => {
        // Set the dropdown value based on location.state if it exists
        if (location.state) {
          const matchingOption = option.find((opt) => opt.value === location.state);
          if (matchingOption) {
            setSelectedGoal(matchingOption.label);
          }
        }
      }, [location.state, option]);

    return (
        <div className="container-fluid page_background holding-switch-section">
            <div class="page-inner-content mb-3" >
                <div class="page_inner_content_box">
                    <div className='d-flex justify-content-between'>
                   
                        <h5 className="mb-4 main-heading-title">
                        <Link className="back_icon" to={backLink}>
                            <FontAwesomeIcon icon={faAngleLeft} className='color me-3' />
                        </Link>
                            Add Goal
                        </h5>
                    </div>
                    <div className="goal_planner_section card">
                <div className="card-body p-0">
                <div className="goal_planner_top px-md-5 pt-lg-5 pt-4 px-3">
                        {/* <h5 className="mb-lg-5 mb-4 left-section-title">Goal</h5> */}
                        <Formik
                        enableReinitialize
                  initialValues={{
                    goal: selectedGoal ? option.find((opt) => opt.label === selectedGoal)?.value : "",
                    // amount: sliderData?.amount || 10000,
                  }}
                  validationSchema={validationSchema}
                  validateOnBlur={true} // Add this line
                  validateOnChange={true} 
                  onSubmit={(values) => 
                  {
                    addGoals(values)}
                  }
                  
                >
                  {({ values, setFieldValue, handleSubmit, touched, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="card border-0">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-xxl-4 col-xl-4 col-lg-7 mb-md-0 mb-4">
                                        <div className="mb-lg-5 mb-4 slider-container">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <label htmlFor="goal" className="slider-label">Goal <span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <Field
                                              name="goal"
                                              // value={values.goal}
                                              value={selectedGoal ? option.find((opt) => opt.label === selectedGoal)?.value : ""}
                                              options={option}
                                              component={SelectDropDown}
                                              error={errors.goal}
                                              touched={touched.goal}
                                              // onBlur={() => setFieldValue('goal', values.goal)}
                                              changeFunction={(value) => {
                                                const selectedOption = option.find((opt) => opt.value === value);
                                                setFieldValue('goal', value);
                                                setSelectedGoal(selectedOption?.label || "");
                                              }}
                                            />
                                        </div>
                                        <SliderComponent
                                          sliderData={sliderData}
                                          onSliderChange={handleChartDataUpdate}
                                          userId={userData?.id}
                                          selectedGoal={selectedGoal}
                                          error={error}
                                          // amount={values.amount} // Pass the amount to SliderComponent
                                          // setFieldValue={setFieldValue}
                                          // error={errors.amount} // Pass the error for amount field
                                          // touched={touched.amount} // Pass touched status
                                        />
                                        {/* {errors.amount && touched.amount && (
                                          <div className="text-danger">{errors.amount}</div>
                                        )} */}
                                    </div>
                                       <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 chart_section mb-4">
                                             <div className="d-flex justify-content-center gap-4 chart_header ">
                                                <h6 className="d-flex gap-2 align-items-center">
                                                    <span className="Investment_color_box"></span>
                                                    Investment
                                                </h6>
                                                <h6 className="d-flex gap-2 align-items-center">
                                                    <span className="Investment_color_box color_box"></span>
                                                    Interest
                                                </h6>
                                             </div>
                                             <div className="row investment_cards ms-lg-3">
                                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 mt-3">
                                                    <div className="card border-0 h-100 position-relative">
                                                        <div className="card-body">
                                                            <h6 className="d-flex mb-4">Per Month <span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.investment_monthly || 0}</span></h6>
                                                           <div className="d-flex flex-column gap-2">
                                                            <h5 className="d-flex">Total Investment:<span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.investment_monthly_total || 0}</span></h5>
                                                            <h5 className="d-flex">Total Interest:<span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.interest_monthly || 0}</span></h5>
                                                            </div> 
                                                            <div className="mt-3 chart_div">
                                                            <ChartCard dataArr={perMonthData} />
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6  mt-3">
                                                    <div className="card border-0 h-100 position-relative">
                                                        <div className="card-body">
                                                            <h6 className="d-flex mb-4">Per Year <span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.investment_yearly || 0}</span></h6>
                                                           <div className="d-flex flex-column gap-2">
                                                            <h5 className="d-flex">Total Investment:<span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.investment_yearly_total || 0}</span></h5>
                                                            <h5 className="d-flex">Total Interest:<span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.interest_yearly || 0}</span></h5>
                                                            </div> 
                                                            <div className="mt-3 chart_div">
                                                              <ChartCard dataArr={perYearData} /> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6  mt-3">
                                                    <div className="card border-0 h-100 position-relative">
                                                        <div className="card-body">
                                                            <h6 className="d-flex mb-4">One Time <span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.investment_one_time || 0}</span></h6>
                                                           <div className="d-flex flex-column gap-2">
                                                            <h5 className="d-flex">Total Interest:<span className="ms-auto">₹{goal?.goalsAmountCalucalation?.data?.interest_one_time || 0}</span></h5>
                                                            </div>
                                                            <div className="mt-3 chart_div">
                                                              <ChartCard dataArr={oneTimeData} /> 
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                             </div>
                                       </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center my-md-4 my-3">
                        <InvestButton
                            label="Save"
                            color="blue"
                            onClick={handleSubmit}
                        />
                    </div>
                    </Form>
                  )}
                </Formik>
                </div>    

                    <div className="goal_planner_content px-md-5 px-3">
                        <h5 className="mb-4">Recommended Funds</h5>
                        <div className="fund-card-content mb-3 no-scrollbar" ref={sectionRef}  style={{ overflowY: "auto", maxHeight: "300px" }}>
                            <div className="row ">
                              {fundsData?.length > 0 ? (
                                fundsData?.map((items, index) => (
                                  <div className="col-xl-4 col-lg-6 col-md-12 mb-md-4 mb-3" key={index} >
                                    <RecommendedFundCard
                                      image={items?.amc?.icon || ""}
                                      // bookmark={false}
                                      // plus={false}
                                      title={items?.product_long_name}
                                      badge={items?.asset_class}
                                      badge2={items?.plan_type}
                                      price1={`₹${items?.nav?.nav || "0.00"}`}
                                      price2={
                                        `₹${
                                          items?.productlimits?.find(
                                        (limit) => limit?.sub_trxn_type === "N"
                                      )?.minimum_amount || "0.00" // added this condition to show SIP and lumpsum price difference
                                    }`
                                      }
                                      price3={`₹${
                                        items?.productlimits?.find(
                                          (limit) => limit?.sub_trxn_type === "S"
                                        )?.minimum_amount || "0.00"
                                      }`}
                                      url="/fund-detail"
                                    />
                                  </div>
                                ))
                              ) : (
                                <p>Coming Soon </p>
                              )}
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
                </div>
            </div>
        </div>
    );
};

export default GoalAdd;
