import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import "../../styles/common.css";
import manage_nominees from "../../images/my_profile/manage_nominees.svg";

const PersonelDetail = ({ data }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingTwo">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEight"
          aria-expanded="false"
          aria-controls="collapseEight"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img
                src={manage_nominees}
                alt=""
                className="personel_details_img"
              />

              <span className="personal_inner_text">Nominees</span>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseEight"
        className="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />

        <div className="accordion-body accordion-body1 profile_accordion_body">
          <div className="d-flex justify-content-end align-items-right"></div>

          <div className="card redeam_bottom_card">
            <div className="row form_body justify-content-center">
              <div className="col-xl-6 col-lg-10 col-md-10 ">
                <form>
                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    {/* <div className="col-6 my-md-2 my-1"> */}
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">
                        <strong>1st Nominee:</strong>
                      </label>
                    </div>
                  </div>

                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Nominee Name:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.nominee1_name}
                        {/* {userGetProfile?.data?.user?.name} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Relation:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.nominee1_relation}
                        {/* {userGetProfile?.data?.user?.dob &&
                          convertDateToDDMMYYYYStartWithYear(
                            userGetProfile?.data?.user?.dob
                          )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">
                        Nominee Date of Birth:
                      </label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span className="white-space-nowrap">
                        {data?.nominee1_dob}
                        {/* {userGetProfile?.data?.user?.email} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Nominee %</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span>
                        {data?.nominee1_percent}
                        {/* {relationLabel(
                          userGetProfile?.data?.user?.profile
                            ?.email_relation
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">
                        <strong>2nd Nominee:</strong>
                      </label>
                    </div>
                  </div>

                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Nominee Name:</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span>
                        {data?.nominee2_name}

                        {/* {userGetProfile?.data?.user?.name} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Relation:</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span>
                        {data?.nominee2_relation}
                        {/* {userGetProfile?.data?.user?.dob &&
                          convertDateToDDMMYYYYStartWithYear(
                            userGetProfile?.data?.user?.dob
                          )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">
                        Nominee Date of Birth:
                      </label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span className="white-space-nowrap">
                        {data?.nominee2_dob}
                        {/* {userGetProfile?.data?.user?.email} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Nominee %</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span>
                        {data?.nominee2_percent}
                        {/* {relationLabel(
                          userGetProfile?.data?.user?.profile
                            ?.email_relation
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">
                        <strong>3rd Nominee:</strong>
                      </label>
                    </div>
                  </div>

                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Nominee Name:</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span>
                        {data?.nominee3_name}
                        {/* {userGetProfile?.data?.user?.name} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Relation:</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span>
                        {data?.nominee3_relation}
                        {/* {userGetProfile?.data?.user?.dob &&
                          convertDateToDDMMYYYYStartWithYear(
                            userGetProfile?.data?.user?.dob
                          )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">
                        Nominee Date of Birth:
                      </label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span className="white-space-nowrap">
                        {data?.nominee3_dob}
                        {/* {userGetProfile?.data?.user?.email} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Nominee %</label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                      <span>
                        {data?.nominee3_percent}
                        {/* {relationLabel(
                          userGetProfile?.data?.user?.profile
                            ?.email_relation
                        )} */}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonelDetail;
