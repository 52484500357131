import axios from "axios";
import { GET_NOTIFICATION_LIST, GET_HEADER_COUNT } from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const getNotificationList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_NOTIFICATION_LIST });
    try {
      if (payload?.id) {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}notifications`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        dispatch({ type: GET_NOTIFICATION_LIST, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: GET_NOTIFICATION_LIST, error: error.message }); // Dispatching an error state if there's a problem
      console.error("Error fetching explore funds list:", error.message);
    }
  };
};

export const markAsRead = (payload) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}notifications/read`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch(getHeaderCount(payload?.id));
      dispatch(getNotificationList(payload));
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };
};

export const getHeaderCount = (payload) => {
  return async (dispatch) => {
    try {
      if (payload) {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}headerCounts/${payload}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        dispatch({ type: GET_HEADER_COUNT, payload: response.data });
      }
    } catch (error) {
      console.error("Error fetching header count:", error.message);
    }
  };
};
