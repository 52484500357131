import React, { useEffect, useRef, useState } from "react";
import "../styles/FundDetailInvestmentBox.css"; // Import styles
import Gross_down from "../images/gross_down_icon.svg";
import upArrow from "../images/Up.svg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardHeader } from "../actions/holdingAction";
import { getLocalStorageUserId } from "../utils/userData";
// Functional component for the funddetails page
const DashboardTopHeaderSection = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const valuationDetails = useSelector((state) => state.holdings);
  const valuationData = valuationDetails?.valuationData?.data;
  const userId = getLocalStorageUserId();
  // useEffect(() => {
  //   const payload = { id: userId };
  //   dispatch(dashboardHeader(payload));
  // }, [dispatch, userId]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        id: userId,
      };
      try {
        setIsLoading(true);
        await Promise.all([dispatch(dashboardHeader(payload))]);
      } catch (error) {
        console.error("One or more API calls failed: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    // if (isMounted.current) {
    fetchData();
    // } else {
    //   isMounted.current = true;
    // }
  }, [dispatch]);

  return (
    <div className="start_investment_box card-header">
      <div className="investment-info">
        <div className="">
          <h6 className="investment-value-header">Invested Value</h6>
          <span className="value-text">
            ₹
            {valuationData?.net_invested_amount ? valuationData.net_invested_amount : 0}
            {/* {valuationData?.net_invested_amount
              ? Number(valuationData?.net_invested_amount).toFixed(2)
              : 0} */}
          </span>
        </div>
        <div className="">
          <h6 className="investment-value-header">Market Value</h6>
          <span className="value-text">₹{valuationData?.portfolio_value}</span>
        </div>

        <div className="">
          <h6 className="investment-value-header">Gain/Loss</h6>
          <div className="d-flex gap-3 align-items-center">
            <span className="value-text">
              <span
                style={{
                  color: String(
                    valuationData?.totalGainLossAmount ?? "0"
                  ).startsWith("-")
                    ? "red"
                    : "#80ff9c",
                }}
              >
                ₹{valuationData?.totalGainLossAmount ?? "0.00"}
              </span>
            </span>

            {Number(valuationData?.totalGainLossAmount) !== 0 && (
              <>
                <img
                  src={
                    String(valuationData?.total_percentage ?? "0").startsWith(
                      "-"
                    )
                      ? Gross_down
                      : upArrow
                  }
                  alt=""
                />
                <span
                  className="gross_percent"
                  style={{
                    color: String(
                      valuationData?.total_percentage ?? "0"
                    ).startsWith("-")
                      ? "red"
                      : "#80ff9c",
                  }}
                >
                  {valuationData?.total_percentage ?? "0"}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="">
          <h6 className="investment-value-header">Monthly SIP Amt.</h6>
          <span className="value-text">
            ₹{valuationData?.monthlySIPAmount}.00
          </span>
        </div>
        <div className="">
          <h6 className="investment-value-header">Amt. Debited this Month</h6>
          <span className="value-text">
            ₹{valuationData?.amountDebitedThisMonth}.00
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardTopHeaderSection;
