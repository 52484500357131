import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateLumpsum } from "../actions/calculatorAction"; // Import your lumpsum API action
import _ from "lodash"; // For debounce

function LumpsumSliderComponent() {
  const dispatch = useDispatch();
  const lumpsumData = useSelector(
    (state) => state.calculator.lumpsumCalculatorData
  ); // Access lumpsum API result from Redux

  const [amount, setAmount] = useState(500);
  const [timePeriod, setTimePeriod] = useState(1);
  const [rateOfReturn, setRateOfReturn] = useState(10); // Default return rate

  // Function to update slider background
  const updateSliderBackground = (value, element) => {
    const min = element.min || 0;
    const max = element.max || 1;
    const percentage = ((value - min) / (max - min)) * 100;
    element.style.background = `linear-gradient(to right, var(--primary-second) ${percentage}%, #e0e0e0 ${percentage}%)`;
  };

  const handleSliderChange = (e, setter, min, max) => {
    const value = Math.max(min, Math.min(max, Number(e.target.value)));
    setter(value);
    updateSliderBackground(value, e.target);
  };

  const handleInputChange = (e, setter, min, max) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    value = value === "" ? "" : Math.max(min, Math.min(max, parseInt(value))); // Clamp to range
    setter(value || min); // Default to min if empty
  };

  const formatCurrency = (value) => {
    if (typeof value === "string") {
      value = parseFloat(value?.replace(/,/g, ""));
    }

    if (isNaN(value)) {
      console.error("Invalid value for currency formatting:", value);
      return "Invalid value";
    }

    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  };

  const debouncedCalculateSip = useCallback(
    _.debounce((payload) => {
      dispatch(calculateLumpsum(payload));
    }, 500), // 500ms debounce time
    [dispatch]
  );

  // Trigger API call when sliders change
  useEffect(() => {
    const payload = {
      amount: Number(amount),
      years: Number(timePeriod),
      returns: Number(rateOfReturn),
    };
    // dispatch(calculateLumpsum(payload)); // Call API with updated values
    debouncedCalculateSip(payload);
  }, [amount, timePeriod,  debouncedCalculateSip, rateOfReturn]);

  useEffect(() => {
    ["amountSlider2", "timePeriodSlider2"].forEach((id) => {
      const slider = document.getElementById(id);
      if (slider) updateSliderBackground(slider.value, slider);
    });
  }, [amount, timePeriod]);

  return (
    <div>
      {/* Amount Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="amountSlider2" className="slider-label">
             One time Investment Amount
          </label>
          <span className="position-relative d-flex align-items-center">
            {/* <span
              className="position-absolute px-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              ₹
            </span> */}
            <input
              id="amountValue"
              className="slider-value border-0"
              value={`₹${amount}`}
              // onChange={(e) => {
              //   const value = Math.max(
              //     0,
              //     Math.min(5000000, Number(e.target.value))
              //   );
              //   setAmount(value);
              // }}
              onChange={(e) => handleInputChange(e, setAmount, 0, 5000000)}
              // onChange={(e) => handleSliderChange(e, setAmount, 500, 5000000)}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="amountSlider2"
          min="500"
          max="5000000"
          value={amount}
          onChange={(e) => handleSliderChange(e, setAmount, 0, 5000000)}
        />
      </div>

      {/* Time Period Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="timePeriodSlider2" className="slider-label">
            Time Period
          </label>
          <span className="position-relative d-flex align-items-center">
            <span
              className="position-absolute end-0 pe-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              Yr
            </span>
            <input
              id="timePeriodValue"
              className="slider-value d-flex gap-md-5 justify-content-between border-0 px-4"
              value={timePeriod}
              // onChange={(e) => {
              //   const value = Math.max(0, Math.min(30, Number(e.target.value)));
              //   setTimePeriod(value);
              // }}
              onChange={(e) => handleInputChange(e, setTimePeriod, 0, 30)}
              // onChange={(e) => handleSliderChange(e, setTimePeriod, 1, 30)}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="timePeriodSlider2"
          min="1"
          max="30"
          value={timePeriod}
          onChange={(e) => handleSliderChange(e, setTimePeriod, 0, 30)}
        />
      </div>

      {/* Return Rate Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="returnSlider2" className="slider-label">
            Return Rate
          </label>
          <span className="position-relative d-flex align-items-center">
            <span
              className="position-absolute end-0 pe-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              %
            </span>
            <input
              id="returnValue"
              className="slider-value d-flex gap-md-5 justify-content-between border-0 px-4"
              value={rateOfReturn}
              // onChange={(e) => {
              //   const value = Math.max(0, Math.min(40, Number(e.target.value))); // Limit to realistic range (1% - 20%)
              //   setRateOfReturn(value);
              // }}
              onChange={(e) => handleInputChange(e, setRateOfReturn, 0, 40)}
              // onChange={(e) => handleSliderChange(e, setRateOfReturn, 1, 40)}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="returnSlider2"
          min="1"
          max="40"
          value={rateOfReturn}
          onChange={(e) => handleSliderChange(e, setRateOfReturn, 0, 40)}
        />
      </div>

      {/* Results */}
      <p className="result-text mb-md-3">
        Total investment of{" "}
        {lumpsumData?.amount
          ? formatCurrency(lumpsumData?.amount)
          : "Calculating..."}{" "}
      </p>
      <p className="result-text-label">
        Would have become:{" "}
        {lumpsumData?.future_value
          ? formatCurrency(lumpsumData?.future_value)
          : "Calculating..."}{" "}
        <span className="profit-text">(+{lumpsumData?.returns}%)</span>
      </p>
    </div>
  );
}

export default LumpsumSliderComponent;
