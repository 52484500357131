import React, { useEffect } from "react";
import "../../styles/FundDetailInvestmentBox.css"; // Import styles
import Gross_down from "../../images/gross_down_icon.svg";
import upArrow from "../../images/Up.svg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardHeader } from "../../actions/holdingAction";
import { getLocalStorageUserId } from "../../utils/userData";
// Functional component for the funddetails page
const DashboardTopHeaderSection = (props) => {
  const dashboardSummaryData = props?.data;  

  return (
    <>
      <div className="start_investment_box card-header">
        <h5 className="page-title">Investment</h5>

        <div className="investment-info">
          <div className="">
            <h6 className="investment-value-header">Total</h6>
            <span className="value-text">{dashboardSummaryData?.investmentSummary?.total || 0}</span>
          </div>
          <div className="">
            <h6 className="investment-value-header">Lumpsum</h6>
            <span className="value-text">{dashboardSummaryData?.investmentSummary?.lumpsum || 0}</span>
          </div>

          <div className="">
            <h6 className="investment-value-header">SIP</h6>
            <div className="d-flex gap-3 align-items-center">
              <span className="value-text">{dashboardSummaryData?.investmentSummary?.sip || 0}</span>
            </div>
          </div>

          <div className="">
            <h6 className="investment-value-header">STP</h6>
            <span className="value-text">{dashboardSummaryData?.investmentSummary?.stp || 0}</span>
          </div>
          <div className="">
            <h6 className="investment-value-header">SWP</h6>
            <span className="value-text">{dashboardSummaryData?.investmentSummary?.swp || 0}</span>
          </div>
          <div className="">
            <h6 className="investment-value-header">Switch</h6>
            <span className="value-text">{dashboardSummaryData?.investmentSummary?.switch || 0}</span>
          </div>
        </div>
      </div>
      <div className="start_investment_box headerBackground card-header">
        <h5 className="page-title">Investors</h5>

        <div className="investment-info">
          <div className="">
            <h6 className="investment-value-header">Total</h6>
            <span className="value-text">{dashboardSummaryData?.investorSummary?.total || 0}</span>
          </div>
          <div className="">
            <h6 className="investment-value-header">With KYC</h6>
            <span className="value-text">{dashboardSummaryData?.investorSummary?.is_kyc || 0}</span>
          </div>

          <div className="">
            <h6 className="investment-value-header">With IIN</h6>
            <div className="d-flex gap-3 align-items-center">
              <span className="value-text">{dashboardSummaryData?.investorSummary?.iin_activated || 0}</span>
            </div>
          </div>

          <div className="">
            <h6 className="investment-value-header">With complete profile</h6>
            <span className="value-text">{dashboardSummaryData?.investorSummary?.completed_profile || 0}</span>
          </div>
          <div className="">
            <h6 className="investment-value-header">Pending</h6>
            <span className="value-text">{dashboardSummaryData?.investorSummary?.pending || 0}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardTopHeaderSection;
