import React, { useEffect, useState } from "react";
import "../styles/FundDetailHeader.css"; // Import styles
import Bookmarked from "../images/fund_page/bookmarked.svg";
import Bookmark from "../images/icons/bookmark.svg";
import AddIcon from "../images/fund_page/cart.svg";
import Check from "../images/icons/check.svg";
import growthTree from "../images/icons/growth-tree.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import InvestButton from "./InvestButton";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addedWatchList, deleteWatchList } from "../actions/watchListActions";
import { getLocalStorageUserId } from "../utils/userData";
import {
  AddToMyCart,
  changeAddToCartState,
  changewatchlistState,
  DeleteMyCart,
} from "../actions/exploreFundsActions";
import {
  getLocalStorageUserData,
  getLocalStorageUserToken,
} from "../utils/userData";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
import PopupModel from "./PopupModel";
import defaultFundIcon from "../images/Default icon.svg";

const token = getLocalStorageUserToken();

// Functional component for the funddetails page
const FundDetailHeader = (props) => {
  const funddetails = props?.data;
  const dispatch = useDispatch(); // Initialize dispatch
  const [isBookmarked, setIsBookmarked] = useState(funddetails?.watch_list || false);
  const [isAddInCart, setIsAddInCart] = useState(funddetails?.cart_list || false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const [buttonCheck, setButtonCheck] = useState(false);
  const userData = getLocalStorageUserData();
  const { addMyCart, loadData } = useSelector((state) => state.exploreFunds);
  const navigate = useNavigate();
  const redirectToLoginIfNoToken = () => {
    if (!token) {
      navigate("/login");
    }
  };
  const handleBookmarkClick = () => {
    redirectToLoginIfNoToken();
    if (!token) return; // Prevent further execution if no token

    const payload = {
      id: getLocalStorageUserId(), // Get userId from localStorage (or any other source)
      product_id: funddetails?.id ? funddetails?.id : funddetails?.product?.id, // Use the product ID from props
    };
    // If already bookmarked, remove from the watchlist
    if (isBookmarked) {
      dispatch(deleteWatchList(payload));
      dispatch(changewatchlistState(!loadData));
    } else {
      dispatch(addedWatchList(payload)); // Dispatch add action
    }
    setIsBookmarked(!isBookmarked);
  };
  const closeModal = () => {
    setIsPopupVisible(false);
  };

  const AddToCart = (invest, investNow) => {
    redirectToLoginIfNoToken();
    if (!token) return;
    if (investNow == "InvestNow") {
      const payload = {
        id: userData.id,
        product_id: funddetails?.id,
        amc_product_id: funddetails?.amc_product_id
          ? funddetails?.amc_product_id
          : funddetails?.product?.productlimits?.[0]?.amc_product_id,
        amc_code: funddetails?.amc_code
          ? funddetails?.amc_code
          : funddetails?.product?.productlimits?.[0]?.amc_code,
        product_code: funddetails?.product_code
          ? funddetails?.product_code
          : funddetails?.product?.productlimits?.[0]?.product_code,
        isin: funddetails?.isin,
        is_additional_purchase: invest == 1 ? true : false,
        reinvest: funddetails?.reinvest_tag,
      };
      dispatch(AddToMyCart(payload)); // Dispatch add action
    } else {
      if (isAddInCart) {
        dispatch(
          DeleteMyCart(
            funddetails?.amc_product_id
              ? funddetails?.amc_product_id
              : funddetails?.product?.productlimits[0]?.amc_product_id,
            1,
            userData?.id
          )
        ); // Dispatch delete action
        dispatch(changeAddToCartState(!loadData));
      } else {
        const payload = {
          id: userData.id,
          product_id: funddetails?.id,
          amc_product_id: funddetails?.amc_product_id
            ? funddetails?.amc_product_id
            : funddetails?.product?.productlimits?.[0]?.amc_product_id,
          amc_code: funddetails?.amc_code
            ? funddetails?.amc_code
            : funddetails?.product?.productlimits?.[0]?.amc_code,
          product_code: funddetails?.product_code
            ? funddetails?.product_code
            : funddetails?.product?.productlimits?.[0]?.product_code,
          isin: funddetails?.product?.isin,
          is_additional_purchase: invest == 1 ? true : false,
          reinvest: funddetails?.reinvest_tag,
        };
        dispatch(AddToMyCart(payload)); // Dispatch add action
      }
      setIsAddInCart(!isAddInCart);
      setButtonCheck(true);
    }
  };

  useEffect(() => {
    if (
      (addMyCart?.status == true || addMyCart?.status == false) &&
      buttonCheck
    ) {
      setIsPopupVisible(true);
    }
  }, [addMyCart]);
  return (
    <div className="start_fund_box card-header">
      <div className="fund-details-info d-flex flex-md-row flex-column align-items-md-center gap-md-0 gap-sm-3 gap-3">
        <div className="d-flex  align-items-center justify-contnet-center">
          <Link
            className=""
            to={
              !userData ? "/explore-funds" : "/explore"
            }
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="color me-2"
              style={{ fontSize: "24px", color: "#FFFF", paddingRight: "10px" }}
            />
          </Link>
          <img
            src={funddetails?.amc?.icon ? funddetails?.amc?.icon : funddetails?.icon}
            alt="Fund Logo"
            className="fund-logo me-3 mb-sm-0 mb-2"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop if default image fails
              e.target.src = defaultFundIcon;
            }}
          />
          <Link to="/">
            <h6 className="fund-name-header d-sm-none d-block">
              {funddetails?.product_long_name
                ? funddetails?.product_long_name
                : funddetails?.scheme_name}
            </h6>
          </Link>
        </div>

        {/* <div className="ms-md-1"> */}
        <div className="col-md-6 col-6 ms-md-1">
          <h6 className="fund-name-header d-sm-block d-none">
            {funddetails?.product_long_name ? funddetails?.product_long_name : funddetails?.scheme_name}
          </h6>
          <span className="badge custom-badge green-badge me-2">
            {funddetails?.asset_class?.toUpperCase()
              ? funddetails?.asset_class?.toUpperCase()
              : funddetails?.product?.plan_type?.toUpperCase()}
          </span>
          <span className="badge custom-badge purple-badge">
            {funddetails?.plan_type?.toUpperCase()
              ? funddetails?.plan_type?.toUpperCase()
              : funddetails?.product?.asset_class?.toUpperCase()}
          </span>
        </div>

        <div className="right-item  ms-md-auto">
          <InvestButton
            label={`${props.btnLabel ? props.btnLabel : "Invest Now"}`}
            // url="/sip-lumpsum-cart"
            onClick={() => {
              AddToCart(1, "InvestNow");
            }}
            color="white"
            className="me-2"
          />
          <span
            className="me-2"
            onClick={handleBookmarkClick}
            style={{ cursor: "pointer" }}
          >
            <img
              src={isBookmarked === false ? Bookmarked : Bookmark }
              alt="bookmark"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title={isBookmarked  === false ? "Add to wishlist" : "Remove from wishlist"}
            />
          </span>
          <span className="">
            <img
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Add to cart"
              src={isAddInCart ? Check : AddIcon}
              alt="plus"
              onClick={() => {
                AddToCart(0);
              }}
            />
          </span>
        </div>
      </div>

      {isPopupVisible && addMyCart?.status == true ? (
        <PopupModel
          id="successModel"
          title="Lorem Ipsum"
          content={addMyCart?.message}
          image={successlogo}
          closeModal={closeModal}
          btnColor={"btn-success"}
          type="addtocart"
        />
      ) : (
        isPopupVisible && (
          <PopupModel
            id="alertModel"
            title="Lorem Ipsum"
            content={addMyCart?.message}
            image={cancel}
            closeModal={closeModal}
            btnColor={"btn-success"}
            type="addtocart"
          />
        )
      )}
    </div>
  );
};

export default FundDetailHeader;
