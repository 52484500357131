import React, { useMemo } from "react";
import backgroundVideo from "../images/homepage/video/homepage_hero_video.mp4";

const HomeHeroSection = (props) => {
  const memoizedContent = useMemo(
    () => (
      <div className="container-fluid">
        <div className="hero_inner_section text-center">
          <div>
            <h1>
              <span>{props.title}</span> {props.subtitle}
            </h1>
          </div>
        </div>
      </div>
    ),
    [props.title, props.subtitle]
  );

  return (
    <section
      className="home_hero_section d-flex justify-content-center align-items-center"
      id="hero_section"
    >
      <video autoPlay muted loop playsInline className="background-video">
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      {memoizedContent}
    </section>
  );
};

export default HomeHeroSection;
