import React, { useEffect, useRef, useState } from "react";
import help_icon from "../images/sidebar_icon/help_icon.svg";
import InvestmentHeaderBox1 from "../components/InvestmentHeaderBox1";
import "../styles/transaction.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import TransactionTable from "../components/TransactionTable";
import Pagination from "../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionMailback,
  getTransactionOnline,
} from "../actions/transactionAction";
import Loader from "../loader/Loader-ui";
import { getLocalStorageUserData } from "../utils/userData";

const Transaction = () => {
  const [activeTab, setActiveTab] = useState("mailback"); // New state to track active tab
  const [selectedType, setSelectedType] = useState("all");
  const [selectedTypeOnline, setSelectedTypeOnline] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedFundsType, setSelectedFundsType] = useState("all");
  const [selectedFundsTypeOnline, setSelectedFundsTypeOnline] = useState("all");
  const [selectedStatusOnline, setSelectedStatusOnline] = useState("all");
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [currentPageOnline, setCurrentPageOnline] = useState(1); // State to manage current page
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesOnline, setTotalPagesOnline] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [sortFields, setSortFields] = useState({});
  const [sortFieldsMailback, setSortFieldsMailback] = useState({ post_date: "desc" });
  const [sortFieldsOnline, setSortFieldsOnline] = useState({ created_at: "desc" });
  

  const dispatch = useDispatch();
  const isMounted = useRef(false); 
  const mailback = useSelector(
    (state) => state?.transaction?.getMailback?.data
  );
  const online = useSelector((state) => state?.transaction?.getOnline?.data);

  const userData = getLocalStorageUserData();
  

  // useEffect(() => {
  //   const payload = {
  //     id: userData?.id,
  //     perpage: 10,
  //     pagenum: currentPage,
  //     trxn_type: selectedType,
  //     trxn_status: selectedStatus,
  //   };
  //   setIsLoading(true);
  //   dispatch(getTransactionMailback(payload))
  //     .then(() => {
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("API call failed: ", error);
  //     });
  // }, [dispatch, selectedType, selectedStatus, userData?.id, currentPage]);

  // useEffect(() => {
  //   const payload = {
  //     id: userData?.id,
  //     perpage: 10,
  //     pagenum: currentPageOnline,
  //     trxn_type: selectedTypeOnline,
  //     trxn_status: selectedStatusOnline,
  //   };
  //   setIsLoading(true);
  //   dispatch(getTransactionOnline(payload))
  //     .then(() => {
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("API call failed: ", error);
  //     });
  // }, [
  //   dispatch,
  //   selectedTypeOnline,
  //   selectedStatusOnline,
  //   userData?.id,
  //   currentPageOnline,
  // ]);
  
  useEffect(()=>{
    const fetchData = async() =>{
      const mailbackPayload ={
      id: userData?.id,
      perpage: 10,
      pagenum: currentPage,
      trxn_type: selectedType,
      trxn_status: selectedStatus,
      sort_fields: sortFieldsMailback,
      fund_type:selectedFundsType
      }
      try {
        setIsLoading(true);
        await Promise.all([
          dispatch(getTransactionMailback(mailbackPayload))
        ])
      } catch (error) {
        console.error("One or more API calls failed: ", error);
      }
      finally{
        setIsLoading(false);
      }
    };
    // if (isMounted.current) {
      fetchData();
    // } else {
    //   isMounted.current = true;
    // }
  },[dispatch, selectedType,selectedStatus,currentPage,userData?.id,sortFields, selectedFundsType,sortFieldsMailback]);


  
  useEffect(()=>{
    const fetchData = async() =>{
      const onlinePayload ={
        id: userData?.id,
        perpage: 10,
        pagenum: currentPageOnline,
        trxn_type: selectedTypeOnline,
        trxn_status: selectedStatusOnline,
        sort_fields: sortFieldsOnline,
        invested_type:selectedFundsTypeOnline
      }
      try {
        setIsLoading(true);
        await Promise.all([
          dispatch(getTransactionOnline(onlinePayload))
        ])
      } catch (error) {
        console.error("One or more API calls failed: ", error);
      }
      finally{
        setIsLoading(false);
      }
    };
    // if (isMounted.current) {
      fetchData();
    // } else {
    //   isMounted.current = true;
    // }
  },[dispatch,userData?.id, currentPageOnline, selectedTypeOnline,selectedStatusOnline,sortFields,selectedFundsTypeOnline ,sortFieldsOnline]);
  
  useEffect(() => {
    if (mailback) {
      setTotalPages(mailback.total_page || 1); // Set total pages from API response
    }
    if (online) {
      setTotalPagesOnline(online.total_page || 1); // Set total pages from API response
    }
  }, [mailback, online]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePageChangeOnline = (page) => {
    setCurrentPageOnline(page);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };
  const handleTypeChangeOnline = (type) => {
    setSelectedTypeOnline(type);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };
  const handleStatusChangeOnline = (status) => {
    setSelectedStatusOnline(status);
  };
  const handleFundsType = (type) => {
    setSelectedFundsType(type);
  };
  const handleFundsTypeOnline = (type) => {
    setSelectedFundsTypeOnline(type);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Reset filters and pagination when switching tabs
    setSelectedType("all");
    setSelectedStatus("all");
    setSelectedTypeOnline("all");
    setSelectedStatusOnline("all");
    setCurrentPage(1);
    setCurrentPageOnline(1);
  };
  const fieldMappingMailback = {
    post_date: "post_date",  // Map the 'post_date' field from mailback to 'created_at'
    product_long_name: "product_long_name",  // Map 'product_long_name' to 'scheme_name'
    amount: "amount",  // Map 'amount' to 'amt'
  };
  
  const fieldMappingOnline = {
    post_date: "created_at",  // Map the 'post_date' field from online to 'created_at'
    product_long_name: "scheme_name",  // Map 'product_long_name' to 'scheme_name'
    amount: "amt",  // Map 'amount' to 'amt'
  };
  // const handleSortChange = (field) => {
  //   if (activeTab === "mailback") {
  //     const mapping = fieldMappingMailback;
  //     const mappedField = mapping[field] || field;
  //     const currentOrder = sortFieldsMailback[mappedField] || "asc";
  //     const newOrder = currentOrder === "asc" ? "desc" : "asc";
  
  //     setSortFieldsMailback((prevFields) => ({
  //       ...prevFields,
  //       [mappedField]: newOrder,
  //     }));
  //   } else if (activeTab === "online") {
  //     const mapping = fieldMappingOnline;
  //     const mappedField = mapping[field] || field;
  //     const currentOrder = sortFieldsOnline[mappedField] || "asc";
  //     const newOrder = currentOrder === "asc" ? "desc" : "asc";
  
  //     setSortFieldsOnline((prevFields) => ({
  //       ...prevFields,
  //       [mappedField]: newOrder,
  //     }));
  //   }
  // };

  const handleSortChange = (field) => {
    if (activeTab === "mailback") {
      const mapping = fieldMappingMailback;
      const mappedField = mapping[field] || field;
      const currentOrder = sortFieldsMailback[mappedField] || "asc";
      const newOrder = currentOrder === "asc" ? "desc" : "asc";
  
      // Reset all other fields and set only the current field
      setSortFieldsMailback({
        [mappedField]: newOrder,
      });
    } else if (activeTab === "online") {
      const mapping = fieldMappingOnline;
      const mappedField = mapping[field] || field;
      const currentOrder = sortFieldsOnline[mappedField] || "asc";
      const newOrder = currentOrder === "asc" ? "desc" : "asc";
  
      // Reset all other fields and set only the current field
      setSortFieldsOnline({
        [mappedField]: newOrder,
      });
    }
  };
  
  
  

  return (
    <div className="container-fluid page_background">
      {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">Transactions</h5>

          {/* Tabs */}
          <div className="transaction_tab">
            <ul
              className="nav nav-tabs gap-3"
              id="calculatorTabs"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  // className="nav-link active"
                  className={`nav-link ${activeTab === "mailback" ? "active" : ""}`}
                  id="mailBack-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#mailBack"
                  type="button"
                  role="tab"
                  aria-controls="mailBack"
                  aria-selected="true"
                  onClick={() => handleTabChange("mailback")}
                >
                  Mailback
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  // className="nav-link"
                  className={`nav-link ${activeTab === "online" ? "active" : ""}`}
                  id="online-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#online"
                  type="button"
                  role="tab"
                  aria-controls="online"
                  aria-selected="false"
                  onClick={() => handleTabChange("online")}
                >
                  Online
                </button>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="mailBack"
                role="tabpanel"
                aria-labelledby="mailBack-tab"
              >
                <TransactionTable
                  data={mailback}
                  selectedType={selectedType}
                  selectedStatus={selectedStatus}
                  onTypeChange={handleTypeChange}
                  onStatusChange={handleStatusChange}
                  onFundType={handleFundsType}
                  onSortChange={handleSortChange}
                  sortFields={sortFieldsMailback}
                />
                <div className="pt-5">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
              <div
                className="tab-pane fade show"
                id="online"
                role="tabpanel"
                aria-labelledby="online-tab"
              >
                <TransactionTable
                  data={online}
                  selectedType={selectedTypeOnline}
                  selectedStatus={selectedStatusOnline}
                  onTypeChange={handleTypeChangeOnline}
                  onStatusChange={handleStatusChangeOnline}
                  onFundType={handleFundsTypeOnline}
                  onSortChange={handleSortChange}
                  sortFields={sortFieldsOnline}
                />
                <div className="pt-5">
                  <Pagination
                    totalPages={totalPagesOnline}
                    currentPage={currentPageOnline}
                    onPageChange={handlePageChangeOnline}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
