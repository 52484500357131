import React from "react";
import "../styles/FundDetailHeader.css"; // Import styles
import BookmarkIcon from "../images/fund_page/bookmarked.svg";
import AddIcon from "../images/fund_page/cart.svg";
import growthTree from "../images/icons/growth-tree.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import InvestButton from "./InvestButton";
import defaultFundIcon from "../images/Default icon.svg"

// Functional component for the funddetails page
const InvestmentHeaderBox1 = (props) => {
  return (
    <div className="start_fund_box card-header">
      <div className="fund-details-info d-flex flex-sm-row flex-column align-items-md-center gap-sm-0   gap-3">
        <div className="d-flex justify-contnet-center align-items-center">
          <img
            src={props?.props?.icon}
            alt="Fund Logo"
            className="fund-logo me-3 mb-0"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop if default image fails
              e.target.src = defaultFundIcon;
            }}
          />
          <h6 className="fund-name-header cursor-pointer d-sm-none d-block mb-0">
            {props?.props?.scheme_name}
          </h6>
        </div>
        <div className="ms-sm-1">
          <h6 className="fund-name-header cursor-pointer d-sm-block d-none">
            {props?.props?.scheme_name}s
          </h6>
          <span className="badge custom-badge green-badge me-2">Growth</span>
          <span className="badge custom-badge purple-badge">Equity</span>
        </div>
      </div>
    </div>
  );
};

export default InvestmentHeaderBox1;
