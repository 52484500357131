import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Pagination from "../../components/Pagination";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/dashboard.css";
import { getLocalStorageUserData } from "../../utils/userData";
import Pencil from "../../images/icons/Pencil.svg";
import Trash from "../../images/icons/Trash.svg";
import AddRM from "../AddRM";
import { FaPlus } from "react-icons/fa6";
import AssignCustomers from "../AssignCustomers";
import InvestButton from "../../components/InvestButton";
import { rmUserList, rmDelete } from "../../actions/adminAction";
import { useDispatch, useSelector } from "react-redux";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [viewMode, setViewMode] = useState("table"); // Tracks the current view: 'table', 'assign', or 'add'

  useEffect(() => {
    dispatch(
      rmUserList({
        page_num: currentPage,
        per_page: "10",
      })
    );
  }, [dispatch, currentPage]);

  const { rmList = {} } = useSelector((state) => state?.admin);

  const userList = rmList?.data?.RMList || [];
  const totalPages = rmList?.data?.pagination?.total_pages || 1;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleDeleteClick = (faqId) => {    
    dispatch(rmDelete(faqId)); // Dispatch action to delete FAQ
  };
  const handleAddRMClick = (faqId) => {
    navigate("/admin/assigncustomer", { state: faqId}); // Switch to the AddRM form view
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid page_background">
        <div className="page-inner-content dashboardSectionBox">
          <div className="page_inner_content_box fund_holding_section">
            <div className="d-flex justify-content-between align-itmes-center mb-2">
              <h5 className="mb-4 main-heading-title">RMs</h5>
              <InvestButton
                label={
                  <>
                    <FaPlus className="mr-1" />
                    Add
                  </>
                }
                url="/admin/addrm"
                color="blue"
                className=""
              />
            </div>
            {viewMode === "table" && (
              <div className="table-responsive">
                <table className="table_transaction">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((rm, index) => (
                      <tr key={index} className="transaction_row">
                        <td className="align-middle">
                          {(currentPage - 1) * 10 + index + 1}
                        </td>
                        <td className="align-middle">{rm.name}</td>
                        <td className="align-middle">{rm.email}</td>
                        <td className="align-middle">
                          <div className="d-flex align-items-center gap-3">
                            <div
                              className="goals-edit"
                              style={{ cursor: "pointer" }}
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Edit"
                              onClick={() =>
                                navigate("/admin/addrm", {
                                  state: { mode: "edit", id: rm?.id },
                                })
                              }
                            >
                              <img
                                src={Pencil}
                                alt=""
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Edit"
                              />
                            </div>
                            <div
                              className="goals-delete"
                              style={{ cursor: "pointer" }}
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Delete"
                              onClick={() => handleDeleteClick(rm?.user_id)}
                            >
                              <img
                                src={Trash}
                                alt=""
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Delete"
                              />
                            </div>
                            <button
                              className="btn"
                              style={{
                                border: "2px solid #00d4aa none",
                                color: "#00d4aa", // Text color
                                backgroundColor: "#AEFFF1",
                                borderRadius: "5px",
                                padding: "6px 16px",
                                fontWeight: "500",
                              }}
                              onClick={() => handleAddRMClick(rm?.user_id)}
                            >
                              Assign Customer
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
            {viewMode === "add" && <AddRM />}{" "}
            {/* Show the AddRM form when viewMode is 'add' */}
            {viewMode === "assign" && <AssignCustomers />}{" "}
            {/* Show AssignCustomers component when viewMode is 'assign' */}
            {/* {showAddRM && <AddRM />}
           {showAssignCustomer && <AssignCustomers />}   */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
