import React from "react";
import "../styles/pagination.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
  
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
  
      if (currentPage > 4) {
        pageNumbers.push("...");
      }
  
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
  
      if (currentPage < totalPages - 3) {
        pageNumbers.push("...");
      }
  
      pageNumbers.push(totalPages);
    }
  
    return pageNumbers;
  };
  

  const renderPageNumbers = () => {
    const pageNumbers = getPageNumbers();
  
    return pageNumbers.map((page, index) =>
      page === "..." ? (
        <li key={`ellipsis-${index}`} className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      ) : (
        <li
          key={page}
          className={`page-item ${page === currentPage ? "active" : ""}`}
          onClick={() => onPageChange(page)}
        >
          <a className="page-link" href="#">
            {page}
          </a>
        </li>
      )
    );
  };
  
  return (
    <nav>
      <ul className="pagination justify-content-center">
        {/* Previous Page */}
        <li
          className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        >
          <a className="page-link" href="#">
            <FontAwesomeIcon icon={faAngleLeft} style={{ color: "#5A4AD5" }} />
          </a>
        </li>

        {/* Page Numbers */}
        {renderPageNumbers()}

        {/* Next Page */}
        <li
          className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
          onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
        >
          <a className="page-link" href="#">
            <FontAwesomeIcon icon={faAngleRight} style={{ color: "#5A4AD5" }} />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
