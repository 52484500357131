import axios from "axios";
import {
  DASHBOARD_VALUATION,
  HOLDINGS_DETAILS,
  HOLDINGS_TYPES_DETAILS,
  SIP_CANCEL,
  SIP_PAUSE,
  SIP_PERIOD,
  REDEEM_SHOW,
  REDEEM_STORE,
  STP_SCHEME,
  STP_SHOW,
  STP_STORE,
  SWITCH_SCHEME,
  SWITCH_SHOW,
  SWITCH_STORE,
  SWP_SHOW,
  SWP_STORE,
  DASHBOARD_DATA,
} from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const dashboardHeader = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}valuation/dashboard`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: DASHBOARD_VALUATION, payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error fetching message types:", error.message);
    }
  };
};

export const dashboardData = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}dashboard`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: DASHBOARD_DATA, payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error fetching message types:", error.message);
    }
  };
};

export const holdingsDetails = (payload) => {
  return async (dispatch) => {
    try {
      if (payload?.id) {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}holdings`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        dispatch({ type: HOLDINGS_DETAILS, payload: response.data }); // Ensure `response.data` has the correct format
      }
    } catch (error) {
      console.error("Error fetching holdings data:", error.message);
    }
  };
};
export const sipCancel = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}sip/cancel`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SIP_CANCEL, payload: response.data }); // Ensure `response.data` has the correct format
      return response.data;
    } catch (error) {
      console.error("Error SIP cancel:", error.message);
    }
  };
};
export const sipPeriod = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}sip/period`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SIP_PERIOD, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      console.error("Error SIP period:", error.message);
    }
  };
};
export const sipPause = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}sip/pause`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SIP_PAUSE, payload: response.data }); // Ensure `response.data` has the correct format
      return response.data;
    } catch (error) {
      console.error("Error SIP Pause:", error.message);
    }
  };
};
export const holdingsTypesDetails = (payload) => {
  return async (dispatch) => {
    try {
      if (payload?.id) {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}systematic-holdings`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        dispatch({ type: HOLDINGS_TYPES_DETAILS, payload: response.data }); // Ensure `response.data` has the correct format
        return response.data;
      }
    } catch (error) {
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const redeemShow = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}redeem/show`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: REDEEM_SHOW, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      dispatch({ type: REDEEM_SHOW, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const redeemStore = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}redeem/store`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: REDEEM_STORE, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      dispatch({ type: REDEEM_STORE, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const stpShow = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}stp/show`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: STP_SHOW, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      dispatch({ type: STP_SHOW, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const stpStore = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}stp/store`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: STP_STORE, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      dispatch({ type: STP_STORE, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const stpScheme = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}stp-scheme`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: STP_SCHEME, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      dispatch({ type: STP_SCHEME, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const swpShow = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}swp/show`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SWP_SHOW, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      dispatch({ type: SWP_SHOW, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const swpStore = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}swp/store`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SWP_STORE, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      dispatch({ type: SWP_STORE, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const switchShow = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}switch/show`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SWITCH_SHOW, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      dispatch({ type: SWITCH_SHOW, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const switchToScheme = (payload) => {
  return async (dispatch) => {
    try {
      const token = getLocalStorageUserToken();
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}switch/SwitchToScheme`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SWITCH_SCHEME, payload: response.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      dispatch({ type: SWITCH_SCHEME, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};

export const switchStore = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}switch/store`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SWITCH_STORE, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      dispatch({ type: SWITCH_STORE, payload: error?.data });
      console.error("Error fetching holdings types data:", error.message);
    }
  };
};
