import React, { useEffect, useState } from "react";
import "../styles/Investers.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { rmAssignList, rmAssign } from "../actions/adminAction";

const AssignCustomers = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const id = location.state;

  const fetchInvestorList = async () => {
    try {
      // setLoading(true);
      await dispatch(rmAssignList(id));
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchInvestorList();
    }
  }, [dispatch, id]);

  const { rmAssignListData = {} } = useSelector((state) => state.admin);
  const rmData = rmAssignListData?.data?.rm;
  const userData = rmAssignListData?.data?.users || [];
  const userAssignData = rmAssignListData?.data?.user_ids || [];

  const [availableUsers, setAvailableUsers] = useState([]);

  const [assignedUsers, setAssignedUsers] = useState([]);

  const [selectedAvailableUser, setSelectedAvailableUser] = useState(null);
  const [selectedAssignedUser, setSelectedAssignedUser] = useState(null);

  useEffect(() => {
    if (rmAssignListData?.data) {
      const allUsers = rmAssignListData?.data?.users || [];
      const assignedUserIds = rmAssignListData?.data?.user_ids || [];

      const assigned = allUsers.filter((user) =>
        assignedUserIds.includes(user.id)
      );
      const available = allUsers.filter(
        (user) => !assignedUserIds.includes(user.id)
      );

      setAssignedUsers(assigned);
      setAvailableUsers(available);
    }
  }, [rmAssignListData]);

  const handleAssign = () => {
    if (selectedAvailableUser) {
      const userToAssign = availableUsers.find(
        (user) => user.id === parseInt(selectedAvailableUser)
      );
      if (userToAssign) {
        setAssignedUsers([...assignedUsers, userToAssign]);
        setAvailableUsers(
          availableUsers.filter((user) => user.id !== userToAssign.id)
        );
        setSelectedAvailableUser(null);
      }
    }
  };

  const handleRemove = () => {
    if (selectedAssignedUser) {
      const userToRemove = assignedUsers.find(
        (user) => user.id === parseInt(selectedAssignedUser)
      );
      if (userToRemove) {
        setAvailableUsers([...availableUsers, userToRemove]);
        setAssignedUsers(
          assignedUsers.filter((user) => user.id !== userToRemove.id)
        );
        setSelectedAssignedUser(null);
      }
    }
  };
  console.log(userAssignData, "check userAssignData");
  console.log(assignedUsers, "check assignedUsers");

  const handleSave = async () => {
    if (!assignedUsers.length) {
      toast.error("Please assign at least one user.");
      return;
    }

    const payload = {
      user_ids: rmAssignListData?.data?.user_ids || [], // Previously assigned users
      user_id: assignedUsers.map((user) => user.id), // Newly assigned users
      agent_id: rmData?.id, // RM (agent) ID
    };

    try {
      setLoading(true);
      let response = await dispatch(rmAssign(payload));
      if (response?.status === false) {
        toast.error(response?.message || "Something went wrong!");
      } else {
        toast.success(response?.message);
        navigate(-1); // Redirect to dashboard page
        fetchInvestorList(); // Refresh list
      }
    } catch (error) {
      toast.error("Failed to assign users.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content dashboardSectionBox">
        <div className="page_inner_content_box fund_holding_section">
          <div className="d-flex justify-content-between align-itmes-center mb-2">
            <h5 className="mb-4 main-heading-title">
              Assign Customers to {rmData?.name}
            </h5>
          </div>
          <div
            className="card redeam_bottom_card"
            style={{
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <div className="row ">
              <div className="col-md-5">
                <label className="form-label mb-3">Select Users*</label>
                <select
                  className="form-select"
                  size="10"
                  onChange={(e) => setSelectedAvailableUser(e.target.value)}
                  value={selectedAvailableUser || ""}
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {availableUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-2 d-flex flex-column justify-content-center align-items-center assign-customer-btn">
                <button
                  className="btn btn-outline-primary mb-2"
                  style={{ width: "80px" }}
                  onClick={handleAssign}
                  disabled={!selectedAvailableUser}
                >
                  &gt;
                </button>
                <button
                  className="btn btn-outline-danger mb-2"
                  style={{ width: "80px" }}
                  onClick={handleRemove}
                  disabled={!selectedAssignedUser}
                >
                  &lt;
                </button>
              </div>

              <div className="col-md-5">
                <label className="form-label mb-3">Assign Users*</label>
                <select
                  className="form-select"
                  size="10"
                  onChange={(e) => setSelectedAssignedUser(e.target.value)}
                  value={selectedAssignedUser || ""}
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {assignedUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="card-footer form_card form_card_border">
            <div className="d-flex justify-content-end">
              <button className="form_button next_btn" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignCustomers;
