import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/sidebar_icon/logo.svg";
import dashbord from "../../images/sidebar_icon/dashbord.svg";
import Investors from "../../images/sidebar_icon/Investors.svg";
import portfolioIcon from "../../images/sidebar_icon/portfolio2.svg";
import transactionsIcon from "../../images/sidebar_icon/transactions.svg";
import reportsIcon from "../../images/sidebar_icon/reports.svg";
import RM from "../../images/sidebar_icon/RM.svg";
import supportIcon from "../../images/sidebar_icon/support2.svg";
import Mailback from "../../images/sidebar_icon/Mailback.svg";
import FAQ from "../../images/sidebar_icon/FAQ_Default.svg";

const AdminAfterLoginSidebar = (props) => {
  const menuItems = [
    { label: "Dashboard", path: "/admin/dashboard", icon: dashbord },
    { label: "Investors", path: "/admin/investor", icon: Investors },
    { label: "Investments", path: "/admin/investment", icon: portfolioIcon },
    { label: "Transactions", path: "/admin/transaction", icon: transactionsIcon },
    { label: "Reports", path: "/admin/reports", icon: reportsIcon },
    { label: "RM", path: "/admin/rm", icon: RM },
    { label: "Mailback", path: "/admin/mailback", icon: Mailback },
    { label: "Support", path: "/admin/support", icon: supportIcon },
    { label: "FAQs", path: "/admin/faqs", icon: FAQ },
  ];
  // Use window.matchMedia to check screen width dynamically
  const isLargeScreen = window.matchMedia("(max-width: 992px)").matches;
  return (
    <>
      <nav
        id={`sidebar`}
        className={`text-white  ${!props.isVisible ? "" : "active"}`}
      >
        <div className="sidebar-header text-center primary-text">
          <button
            id="close-sidebar"
            className="btn btn-link text-white position-absolute"
            style={{ top: "10px", right: "10px", fontSize: "15px" }}
            onClick={props.onClickBtn}
          >
            <i className="fas fa-times"></i>
          </button>
          <Link to="/" className="">
            <img src={logo} alt="" className="" />
          </Link>
        </div>
        <ul className="main_menu list-unstyled components  d-flex flex-column gap-2">
          {menuItems.map((item, index) => (
            <li key={index} className="pcoded-hasmenu d-flex flex-column">
              <NavLink
                to={item.path}
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={props.onClickBtn}
              >
                <img src={item.icon} alt={`${item.label} Icon`} className="" />
                <span className="pcoded-mtext">{item.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default AdminAfterLoginSidebar;