import trash from "../images/icons/delete_icon.svg";
import "../styles/sipCart.css";
import { Link } from "react-router-dom";
import SelectDropDown from "./SelectDropDown";
import defaultFundIcon from "../images/Default icon.svg";

const SipCart = (props) => {
  return (
    <div className="card Sip-card overflow-hiddden bg-white">
      <div className="card-body p-0">
        <div className="fund-details-info d-flex flex-wrap align-items-center gap-3">
          <img
            src={props.image}
            alt="Fund Logo"
            className="fund-logo me-3 mb-sm-0 mb-2"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop if default image fails
              e.target.src = defaultFundIcon;
            }}
          />
          <div>
            <Link to={`/explore/fund-detail`}>
              <h6 className="fund-name">{props.title}</h6>
            </Link>
            <div className="d-flex flex-wrap gap-2"></div>
          </div>
          <div className="ms-lg-auto mt-sm-0 mt-0 d-flex align-items-center">
            <div className="right-toggle-section">
              <div className="toggle-group">
                <label className="toggle-option">
                  <input type="radio" name={props.investmentName} />
                  SIP
                </label>
                <label className="toggle-option">
                  <input type="radio" name={props.investmentName} />
                  Lumpsum
                </label>
              </div>
              <div className="divider_toggle"></div>
              <div className="step-up-toggle">
                <label className="toggle-option"> Step Up? </label>
                <div className="form-check form-switch ms-2">
                  <input
                    className="form-check-input shadow-none switch_mode"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={props.stepUp ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Input Fields */}
        <div className=" mt-3">
          <form className="investment-inputs d-flex gap-3">
            <div className="input_group">
              <label for="folioNumber" className="form-lable">
                Folio No.
              </label>
              <SelectDropDown
                name="folioNumber"
                id="folioNumber"
                value=""
                changeFunction=""
                options=""
              />
            </div>
            <div className="input_group">
              <label className="form-lable">Amount</label>
              <input
                type="number"
                className="form-control"
                defaultValue="100.00"
              />
            </div>
            <div className="input_group">
              <label for="day" className="form-lable">
                Day
              </label>
              <SelectDropDown
                name="day"
                id="day"
                value="1"
                changeFunction=""
                options=""
              />
            </div>
            <div className="input_group">
              <label className="form-lable">Frequency</label>
              <SelectDropDown
                name="folioNumber"
                id="folioNumber"
                value=""
                changeFunction=""
                options=""
              />
            </div>
            <div className="input_group">
              <label className="form-lable">No. of Installments</label>
              <SelectDropDown
                name="folioNumber"
                id="folioNumber"
                value=""
                changeFunction=""
                options=""
              />
            </div>
            <div className="input_group">
              <label className="form-lable">Step-Up Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter"
              />
            </div>
            <div className="input_group">
              <label className="form-lable">Step-Up Frequency</label>
              <SelectDropDown
                name="folioNumber"
                id="folioNumber"
                value=""
                changeFunction=""
                options=""
              />
            </div>
            <div className="input_group">
              <label className="form-lable">Final Amount</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter"
              />
            </div>
            <div className="delete-action align-self-end ms-auto">
              <Link to="" className="btn_delete ms-3">
                <img src={trash} alt="" />
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SipCart;
