import { GET_MESSAGE, MESSAGE_SEND, MESSAGE_TYPE } from "../actions/types";

const initialState = {
    messagetype: [], // Change from string to array
    loading: false,
    messageDetails:{},
    getMessage:[],
};

const messageReducers = (state = initialState, action) => {
    switch (action.type) {
        case MESSAGE_TYPE:
            return { ...state, loading: false, messagetype: action.payload }; 
        case MESSAGE_SEND:
            return { ...state, loading: false, messageDetails: action.payload }; 
        case GET_MESSAGE:
            return { ...state, loading: false, getMessage: action.payload }; 
        default:
            return state;
    }
};

export default messageReducers;
