import React, { useState, useRef } from "react";
import "../styles/auth.css"; // Import styles
import logo from "../images/icons/header-logo.svg";
import loginSideImage from "../images/login_page/login_image.svg";
import calendarImage from "../images/calendar_month.svg";
import eyeImage from "../images/login_page/eye-image.svg";
import { Link,useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import DatePickerWithIcon from "../components/DatePickerWithIcon";
import {
  userLogin,
  userResendOtp,
  userSignup,
  userVerifyLogin,
} from "../actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { encryptData } from "../utils/userData";
import { toast } from "react-toastify";

// Functional component for the Home page
const Login = () => {
  const { slug } = useParams(); // Get full slug from URL  
  const [showPassword, setShowPassword] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const dispatch = useDispatch();
  const [disabledOtp, setDisabledOtp] = useState(false);
  const [otp_time, setOtp_time] = useState();
  const [attempt, setAttempt] = useState(0);
  const [disable, setDisable] = useState(false);

  const { users, usersVerifyLogin, loading, error } = useSelector(
    (state) => state.users
  );
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTabClick = (tab) => {
    setIsSignIn(tab === "signIn");
  };

  const userLoginCall = (values) => {
    setDisable(true);
    const loginWithOtp = {
      mobile: values.mobile,
      login_with: values.login_with,
    };

    const loginWithPassword = {
      mobile: values.mobile,
      login_with: values.login_with,
      password: values.password,
    };

    // const attempt = sessionStorage.getItem("attempt");
    const verifyOtp = {
      mobile: values.mobile,
      otp: values.otp,
      otp_time: otp_time,
      attempt: attempt,
    };
    const payload =
      values.login_with == "otp" ? loginWithOtp : loginWithPassword;

    if (otp_time) {
      dispatch(userVerifyLogin(verifyOtp)).then((response) => {
        setDisable(false);
        if (response?.status == false) {
          setOtp_time(response?.otp_time);
          setAttempt(response?.attempt);
        }
      });
    } else {
      dispatch(userLogin(payload)).then((response) => {
        setDisable(false);
        if (response?.status == true) {
          setOtp_time(response?.otp_time);
          setAttempt(response?.attempt);
        }
      });
      if (users?.token) {
        const encryptedUserToken = encryptData(usersVerifyLogin?.token);
        localStorage.setItem("token", encryptedUserToken);
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 1000);
      }
    }
  };

  const handleSubmit = (values) => {
    dispatch(userSignup(values)).then((response) => {
      if (response?.status == true) {
        setAttempt(response?.attempt);
        setOtp_time(response?.otp_time);
        setIsSignIn(true);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    });
    // Add your API call or form submission logic here
  };

  const changeValueOfVerify = (condition, setFieldValue) => {
    if (condition) {
      setFieldValue("verifyOtp", true); // Update Formik state
    }
  };
  const otpInputs = useRef([]); // Ref to store all otp input elements
  const handleInputChange = (values, index, event, setFieldValue) => {
    const value = event.target.value;

    // Allow only a single digit or empty value
    if (!/^\d?$/.test(value)) return;

    // Update the OTP array at the specific index
    const otpArray = [...values.otp]; // Create a copy of the OTP array
    otpArray[index] = value || ""; // Update the value at the current index

    // Update Formik's OTP state
    setFieldValue("otp", otpArray.join("")); // Convert array back to a string

    // Move focus to the next input only if a valid digit is entered
    if (value && index < otpInputs.current.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (values, index, event) => {
    if (event.key === "Backspace" && !values.otp[index] && index > 0) {
      // Move focus to the previous input if empty and Backspace is pressed
      otpInputs.current[index - 1].focus();
    }
  };

  const resendOtp = (mobile, login_with) => {
    setDisabledOtp(true);
    if (!disabledOtp) {
      const loginWithOtp = {
        mobile: mobile,
      };
      dispatch(userResendOtp(loginWithOtp)).then((response) => {
        if (response?.status == true) {
          setOtp_time(response?.otp_time);
          setAttempt(response?.attempt);
        }
      });
      setTimeout(() => {
        setDisabledOtp(false);
      }, 60000);
    }
  };
  const validationSchema = Yup.object().shape({
    mobile: Yup.string().when("login_with", (login_with, schema) => {
      return login_with[0] === "Password"
        ? schema
            .required("Mobile Number/Email is required")
            .test(
              "is-valid-contact",
              "Enter a valid email or phone number",
              (value) => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const phoneRegex = /^[6-9]\d{9}$/; // Assuming 10-digit Indian phone numbers starting with 6-9
                return emailRegex.test(value) || phoneRegex.test(value);
              }
            )
        : schema
            .required("Mobile Number is required")
            .matches(
              /^[0-9]{10}$/,
              "Enter a valid 10-digit mobile number is accepted "
            );
    }),
    login_with: Yup.string()
      .oneOf(["Password", "otp"], "Invalid login method") // Ensure login_with is one of the expected values
      .required("Login method is required"),

    password: Yup.string().when("login_with", (login_with, schema) => {
      return login_with[0] === "Password"
        ? schema.required("Password is required")
        : schema;
    }),

    otp: Yup.string().when("verifyOtp", (verifyOtp, schema) => {
      return verifyOtp[0] === true && otp_time
        ? schema
            .required("OTP is required")
            .length(6, "OTP must be exactly 6 digits")
        : schema;
    }),
  });

  const validationSchemaForSignup = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    pan: Yup.string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Enter valid PAN number")
      .required("PAN is required"),
    dob: Yup.string().required("Date of Birth is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .matches(
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        "Enter valid email address"
      )
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, "10 digit mobile number is accepted ")
      .required("Mobile number is required"),
    password: Yup.string()
      .min(6, "Password at least 6 characters long is accepted")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#]).{8,}$/,
        "Password must include uppercase, lowercase, number, and special character"
      )
      .required("Password is required"),
    referral_code: Yup.string(),
    did_agree: Yup.boolean()
      .oneOf([true], "You must agree to the Terms of Service")
      .required("Agreement is required"),
  });

  const formik = useFormik({
    initialValues: {
      mobile: "",
      password: "",
      otp: "",
      login_with: "otp", // Default to otp
      verifyOtp: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      userLoginCall(values);
    },
  });

  return (
    <section className="login" id="auth_section">
      <div className="container-fluid  login-container">
        <div className="row">
          <div className="col-lg-6 col-md-5 px-0">
            <div className="auth_left_content d-md-flex d-none card h-100 justify-content-start">
              <Link className="navbar-brand" to={`/`}>
                <img src={logo} alt="Logo" />
                {/* Replace 'logo.png' with your logo image path */}
                FUNDSWALA
              </Link>
              <div className="mb-xxl-5">
                <h3 className="geeting_text mb-lg-4">Welcome to Fundswala</h3>
                <h3 className="mb-4">Join Us Now ! </h3>
              </div>
              <img src={loginSideImage} alt="" className="login-image " />
            </div>
          </div>
          <div className="col-lg-6  col-md-7 px-0">
            <div
              className="right-side-container"
              style={{ height: isSignIn ? "100vh" : "auto" }}
            >
              <div className="d-md-none d-flex flex-column align-items-center">
                <Link
                  className="navbar-brand"
                  to={`/`}
                  style={{ color: "var(--primary-color)" }}
                >
                  <img src={logo} alt="Logo" />
                  {/* Replace 'logo.png' with your logo image path */}
                  FUNDSWALA
                </Link>
                <div className="my-3 text-center">
                  <h3
                    className="geeting_text "
                    style={{ color: "var(--primary-color)" }}
                  >
                    Welcome to Fundswala
                  </h3>
                  <h3
                    className="geeting_text"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Join Us Now !{" "}
                  </h3>
                </div>
              </div>
              {/* <!-- Tabs --> */}
              <ul
                className="nav nav-tabs gap-3"
                id="calculatorTabs"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="signin-tab"
                    data-bs-toggle="tab"
                    data-bs-target={`#${isSignIn == true ? "signIn" : ""}`}
                    type="button"
                    role="tab"
                    aria-controls={isSignIn == true && "signIn"}
                    aria-selected={isSignIn}
                    onClick={() => handleTabClick("signIn")}
                  >
                    Sign in
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="signUp-tab"
                    data-bs-toggle="tab"
                    data-bs-target={`#${isSignIn == false ? "signUp" : ""}`}
                    type="button"
                    role="tab"
                    aria-controls={isSignIn == false && "signUp"}
                    aria-selected={!isSignIn}
                    onClick={() => handleTabClick("signUp")}
                  >
                    Sign up
                  </button>
                </li>
              </ul>

              {/* <!-- Tab Content --> */}
              <div className="tab-content mt-md-4 mt-4">
                <div
                  className={`tab-pane  fade  ${
                    isSignIn == true ? "show active" : ""
                  }`}
                  id="signIn"
                  role="tabpanel"
                  aria-labelledby="signin-tab"
                >
                  <h5 className="form-title d-md-block d-none">Sign in</h5>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                      <label htmlFor="mobile" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        onChange={formik.handleChange}
                        value={formik?.values?.mobile}
                        placeholder={
                          formik?.values.login_with === "Password"
                            ? "Enter Your Mobile Number/Email"
                            : "Enter Your Mobile Number"
                        }
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className="text-danger">
                          {formik.errors.mobile}
                        </div>
                      ) : null}
                    </div>

                    {(formik.values.login_with == "otp" ||
                      formik.values.login_with == "") && (
                      <div className="form-group">
                        <label className="form-label ps-0">Login with</label>
                        <div className="d-flex align-items-center gap-4">
                          <div className="form-check d-flex flex-wrap align-items-center gap-1">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="login_with"
                              id="loginotp"
                              value="otp"
                              checked={formik.values.login_with === "otp"}
                              onChange={(e) => {
                                formik.setFieldValue("login_with", "otp");
                                formik.setFieldValue("verifyOtp", true);
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="loginotp"
                            >
                              OTP
                            </label>
                          </div>
                          <div className="form-check d-flex flex-wrap align-items-center gap-1">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="login_with"
                              id="loginPassword"
                              value="Password"
                              checked={formik.values.login_with === "Password"}
                              onChange={(e) => {
                                formik.setFieldValue("login_with", "Password");
                                formik.setFieldValue("verifyOtp", false);
                                setAttempt(0);
                                setOtp_time("");
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="loginPassword"
                            >
                              Password
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {formik.values.login_with === "otp" ? (
                      <>
                        {otp_time && (
                          <>
                            <div className="form-group mb-3">
                              <label htmlFor="otp" className="form-label ps-0">
                                Enter OTP sent to this Mobile Number
                              </label>
                              <div className="otp-input-group">
                                {[...Array(6)].map((_, index) => (
                                  <input
                                    key={index}
                                    type="text"
                                    className="otp-input"
                                    maxLength="1"
                                    ref={(el) =>
                                      (otpInputs.current[index] = el)
                                    }
                                    value={formik.values.otp[index] || ""} // Bind value to specific position
                                    onChange={(event) =>
                                      handleInputChange(
                                        formik.values,
                                        index,
                                        event,
                                        formik.setFieldValue
                                      )
                                    }
                                    onKeyDown={(event) =>
                                      handleKeyDown(formik.values, index, event)
                                    }
                                  />
                                ))}
                              </div>
                              {formik.touched.otp && formik.errors.otp ? (
                                <div className="text-danger">
                                  {formik.errors.otp}
                                </div>
                              ) : null}
                            </div>
                            <span className="otp-label">
                              Didn't receive OTP?{" "}
                              <a
                                onClick={() => {
                                  resendOtp(
                                    formik.values?.mobile,
                                    formik.values?.login_with
                                  );
                                }}
                                className="resend-link"
                              >
                                Resend
                              </a>
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group position-relative">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control mb-0"
                              id="password"
                              name="password"
                              onChange={formik.handleChange}
                              value={formik?.values?.password}
                              placeholder="Enter Your Password"
                            />
                            <span
                              className="passwordicon"
                              onClick={togglePasswordVisibility}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="color"
                              />
                            </span>
                          </div>
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger">
                              {formik.errors.password}
                            </div>
                          ) : null}
                          <div className="form-group  mt-3">
                            <a
                              href="/forgot-password"
                              className="forget_password"
                            >
                              Forgot Password?
                            </a>
                          </div>
                        </div>
                      </>
                    )}

                    {formik.values.login_with == "Password" && (
                      <div className="form-group">
                        <label className="form-label ps-0">Login with</label>
                        <div className="d-flex align-items-center gap-4">
                          <div className="form-check d-flex flex-wrap align-items-center gap-1">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="login_with"
                              id="loginotp"
                              value="otp"
                              checked={formik.values.login_with === "otp"}
                              onChange={(e) => {
                                formik.setFieldValue("login_with", "otp");
                                formik.setFieldValue("verifyOtp", true);
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="loginotp"
                            >
                              OTP
                            </label>
                          </div>
                          <div className="form-check d-flex flex-wrap align-items-center gap-1">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="login_with"
                              id="loginPassword"
                              value="Password"
                              checked={formik.values.login_with === "Password"}
                              onChange={(e) => {
                                formik.setFieldValue("login_with", "Password");
                                formik.setFieldValue("verifyOtp", false);
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="loginPassword"
                            >
                              Password
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {formik.values?.login_with == "otp" ? (
                      <>
                        <p className="notice  mt-3">
                          OTP will expire in 2 minutes.
                        </p>
                        <p className="notice">
                          After 3 consecutive unsuccessful attempts your ID will
                          be disable for 24 hours.
                        </p>
                      </>
                    ) : (
                      <p className="notice  mt-3">
                        You can create/change password by logging in with OTP.
                      </p>
                    )}

                    <div className="mt-md-5 mt-sm-4 mt-3 text-end">
                      <button
                        type="submit"
                        className="form_button next_btn"
                        onClick={() => {
                          changeValueOfVerify(
                            formik.values.login_with === "otp" && otp_time,
                            formik.setFieldValue
                          );
                        }}
                        disabled={disable}
                      >
                        {formik.values.login_with === "Password"
                          ? "Sign in"
                          : formik.values.login_with === "otp" && otp_time
                          ? "Verify OTP"
                          : "Send OTP"}
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className={`tab-pane  fade  ${
                    isSignIn == false ? "show active" : ""
                  }`}
                  id="signUp"
                  role="tabpanel"
                  aria-labelledby="signUp-tab"
                >
                  {/* <!-- Lumpsum Content Here --> */}
                  <h5 className="form-title d-md-block d-none">Sign up</h5>
                  <Formik
                    initialValues={{
                      name: "",
                      pan: "",
                      dob: "",
                      email: "",
                      mobile: "",
                      password: "",
                      referral_code: slug || "",
                      did_agree: false,
                    }}
                    validationSchema={validationSchemaForSignup}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <div className="form-group">
                          <label htmlFor="fullName" className="form-label">
                            Full Name (As on PAN)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="fullName"
                            name="name"
                            placeholder="Enter Your Full Name (As on PAN)"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="pan" className="form-label">
                            PAN
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="pan"
                            name="pan"
                            placeholder="Enter Your PAN"
                          />
                          <ErrorMessage
                            name="pan"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <DatePickerWithIcon
                          value={values.dob}
                          onChange={(date) => setFieldValue("dob", date)}
                          placeholder="Select Your Date of Birth"
                        />

                        <div className="form-group">
                          <label htmlFor="mobile" className="form-label">
                            Mobile Number
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            placeholder="Enter Your Mobile Number"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter Your Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="Password" className="form-label">
                            Password
                          </label>
                          <div className="input-group position-relative">
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id="Password"
                              name="password"
                              placeholder="Enter Your Password"
                            />
                            <span
                              className="passwordicon"
                              onClick={togglePasswordVisibility}
                            >
                              <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="color"
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="code" className="form-label">
                            Referral Code
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="code"
                            name="referral_code"
                            placeholder="Enter Your Referral Code"
                          />
                        </div>

                        <div className="form-group ">
                          <div className="d-flex">
                            <Field
                              type="checkbox"
                              className="form-check-input mt-0 me-0"
                              id="confirm_box"
                              name="did_agree"
                            />
                            <label
                              htmlFor="confirm_box"
                              className="form-label confirm_box"
                            >
                              {" "}
                              I agree with the{" "}
                              <Link
                                to={`/termsconditions`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                Terms of services{" "}
                              </Link>{" "}
                              and{" "}
                              <Link
                                to={`/privacy-policy`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                Privacy Policy
                              </Link>
                            </label>
                          </div>
                          <ErrorMessage
                            name="did_agree"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="mt-md-5 mt-sm-4 mt-3 text-end">
                          <button
                            type="submit"
                            className="form_button next_btn"
                            onClick={() => {
                              formik?.setFieldValue("mobile", values?.mobile);
                              formik?.setFieldValue("verifyOtp", true);
                            }}
                          >
                            Create Account
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
