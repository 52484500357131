import {
  DASHBOARD_DATA,
  DASHBOARD_VALUATION,
  HOLDINGS_DETAILS,
  HOLDINGS_TYPES_DETAILS,
  REDEEM_SHOW,
  REDEEM_STORE,
  SIP_CANCEL,
  SIP_PAUSE,
  SIP_PERIOD,
  STP_SCHEME,
  STP_SHOW,
  STP_STORE,
  SWITCH_SCHEME,
  SWITCH_SHOW,
  SWITCH_STORE,
  SWP_SHOW,
  SWP_STORE,
} from "../actions/types";

const initialState = {
  valuationData: {},
  holdingData: {},
  holdingTypesData: {},
  sipCancel: {},
  sipPeriod: {},
  sipPause: {},
  loading: false,
  redeemShow: "",
  redeemStore: "",
  stpShows: "",
  stpStores: "",
  swpShows: "",
  swpStores: "",
  switchShows: "",
  switchSchemes: "",
  switchStors: "",
  stpSchemes: "",
  dashboardDatas: "",
};

const holdingreducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_VALUATION:
      return { ...state, loading: false, valuationData: action.payload };
    case HOLDINGS_DETAILS:
      return { ...state, loading: false, holdingData: action.payload };
    case HOLDINGS_TYPES_DETAILS:
      return { ...state, loading: false, holdingTypesData: action.payload };
    case SIP_CANCEL:
      return { ...state, loading: false, sipCancel: action.payload };
    case SIP_PERIOD:
      return { ...state, loading: false, sipPeriod: action.payload };
    case SIP_PAUSE:
      return { ...state, loading: false, sipPause: action.payload };
    case REDEEM_SHOW:
      return { ...state, loading: false, redeemShow: action.payload };
    case REDEEM_STORE:
      return { ...state, loading: false, redeemStore: action.payload };
    case STP_SHOW:
      return { ...state, loading: false, stpShows: action.payload };
    case STP_STORE:
      return { ...state, loading: false, stpStores: action.payload };
    case SWITCH_SHOW:
      return { ...state, loading: false, switchShows: action.payload };
    case SWITCH_SCHEME:
      return { ...state, loading: false, switchSchemes: action.payload };
    case SWITCH_STORE:
      return { ...state, loading: false, switchStors: action.payload };
    case SWP_SHOW:
      return { ...state, loading: false, swpShows: action.payload };
    case SWP_STORE:
      return { ...state, loading: false, swpStores: action.payload };
    case STP_SCHEME:
      return { ...state, loading: false, stpSchemes: action.payload };
    case DASHBOARD_DATA:
      return { ...state, loading: false, dashboardDatas: action.payload };

    default:
      return state;
  }
};

export default holdingreducer;
