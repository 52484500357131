import React, { useState } from 'react';

const InvestmentPlans = (props) => {
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12" key={`golplan`+props.indexKey}>
        <a href='javascript:;' onClick={props.onClick}>
            <div className={`goal-card ${props.isActive?'active':""}`}>
                <img src={props.imageSrc} alt="" />
                <h5>{props.title}</h5>
            </div>
        </a>
    </div>
  );
};

export default InvestmentPlans;
