import React, { useEffect, useState } from "react";
import RMReportTable from "../../components/RM/RmReportTable";
import { useDispatch, useSelector } from "react-redux";
import { getSummeryReport } from "../../actions/rmAction";
import Loader from "../../loader/Loader-ui";
import { getLocalStorageUserData } from "../../utils/userData";
import RMReportChart from "../../components/RM/RmReportChart";

// Functional component for the Home page
const RMReport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userData = getLocalStorageUserData();
  useEffect(() => {
    setIsLoading(true);
    dispatch(getSummeryReport())
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed: ", error);
      });
  }, [dispatch]);
  const { rmSummaryReport = {} } = useSelector((state) => state?.admin);
  const reportData = rmSummaryReport?.data || {};

  return (
    <div className="container-fluid page_background ">
      {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box ">
          <div className="d-flex gap-3 flex-wrap justify-content-between align-itmes-center mb-4 ">
            <h5 className="mb-0 main-heading-title">Report Summary</h5>
          </div>

          <div className="ticket_card_inner page_content_overflow  tickets_Messages">
            <div className="mb-3">
              {/* <TicketTable number='123' message='Lorem Ipsum' status='Open'/> */}
              <RMReportTable reportData={reportData} />
              {/* <RMReportChart reportData={reportData} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RMReport;
