import React, { useState } from 'react';
import FundSmallBox from './FundSmallBox'; // Ensure this is the correct import path
import LargeCap from '../images/icons/LargeCap.svg';
import MidCap from '../images/icons/MidCap.svg';
import SmallCap from '../images/icons/SmallCap.svg';
import TaxSaving from '../images/icons/TaxSaving.svg';

const FundInvestSection = () => {
    const [scale, setScale] = useState(1); // Initial scale state

    const handleScroll = (event) => {
      const scrollDirection = event.deltaY; // Positive for scroll down, negative for scroll up
      setScale((prevScale) => {
        if (scrollDirection < 0) {
          // Scroll up: zoom in (increase scale), max scale set to 1.5
          return Math.min(prevScale + 0.1, 1.1);
        } else {
          // Scroll down: zoom out (decrease scale), min scale set to 0.5
          return Math.max(prevScale - 0.1, 1);
        }
      });
    };

  return (
    <section
    className="fund_invest_section"
    id="fund_invest_section"
    onWheel={handleScroll} // Trigger zoom effect on mouse scroll
  >
    <div className="container px-lg-3 px-4">
      <div className="row align-items-center">
        <div className="col-lg-6 mb-3">
          <h1 className="section-title mb-lg-5 mb-4">
            Choose Funds to <br className="d-lg-block d-none" /> Invest In
          </h1>
          <p className="fund_invest_text">
            Craft Your Wealth Journey - Explore, analyze, and <br className="d-lg-block d-none" />
            choose funds aligned with your financial goals.
          </p>
        </div>
        <div className="col-lg-6">
          <div
            className="fund_invest_box justify-content-lg-end justify-content-center"

          >
            <div
              style={{
                transform: `scale(${scale})`, // Apply zoom effect
                transition: 'transform 0.9s ease-out',
              }}
            >
              <FundSmallBox label='Large Cap' color='linear_blue' url='/explore-funds' imageSrc={LargeCap} state={"large cap"}/>
            </div>
            <div
              style={{
                transform: `scale(${scale})`, // Apply zoom effect
                transition: 'transform 0.9s ease-out',
              }}
            >
              <FundSmallBox label='Mid Cap' color='linear_blue' url='/explore-funds' imageSrc={MidCap} state={"mid cap"}/>
            </div>
            <div
              style={{
                transform: `scale(${scale})`, // Apply zoom effect
                transition: 'transform 0.9s ease-out',
              }}
            >
              <FundSmallBox label='Small Cap' color='linear_blue' url='/explore-funds' imageSrc={SmallCap} state={"small cap"}/>
            </div>
            <div
              style={{
                transform: `scale(${scale})`, // Apply zoom effect
                transition: 'transform 0.9s ease-out',
              }}
            >
              <FundSmallBox label='Tax Saving' color='linear_blue' url='/explore-funds' imageSrc={TaxSaving} state={"elss"}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default FundInvestSection;
