import axios from "axios";
import {
  ADMIN_DASHBOARD_SUMMARY,
  ADMIN_INVESTOR_LIST,
  ADMIN_INVESTOR_LIST_EXPORT,
  ADMIN_INVESTMENT_LIST,
  ADMIN_TRANSACTION_LIST,
  CUSTOMER_LIST,
  RM_LIST,
  FAQ_LIST,
  FAQ_DETAIL,
  SUPPORT_LIST,
  SUPPORT_SHOW,
  SUPPORT_DETAIL,
  SUPPORT_CLOSE,
  RM_ASSIGN_LIST,
  RM_DETAIL,
  ADMIN_MAILBACK,
  CUSTOMER_STATUS_LIST,
  RM_BANK_LIST,
  ADMIN_EMAIL_UPDATE, 
  ADMIN_MOBILE_UPDATE,
  USER_FORGOT_PASSWORD,
  ADMIN_INVESTOR_ADD,
  ADMIN_INVESTOR_DETAIL,
  ADMIN_RESUBMIT_PROFILE,
  ADMIN_ADD_INVESTOR_DATA,
  ADMIN_EDIT_INVESTOR_DATA,
  ADMIN_FATCA_DATA,
  ADMIN_FATCA_LINK,
  ADMIN_IIN_LINK,
  ADMIN_CHECK_BROKER,
  BANK_DETAIL_IFSC
} from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
import { toast } from "react-toastify";

const token = getLocalStorageUserToken();

export const dashboardSummary = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/admin-dashboard`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({
        type: ADMIN_DASHBOARD_SUMMARY,
        payload: response.data,
      });
      return response?.data;
    } catch (error) {
      dispatch({
        type: ADMIN_DASHBOARD_SUMMARY,
        error: error.message,
      });
      console.error("Error fetching Dashboard Valuation Data:", error.message);
      return error;
    }
  };
};

export const investorList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/list?per_page=${payload?.per_page}&page_num=${payload?.page_num}&rmid=${payload?.rmId}&search=${payload?.search}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_INVESTOR_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTOR_LIST, error: error.message });
      console.error("Error fetching Investor List:", error.message);
      return error;
    }
  };
};

export const investorListExport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/export?rmid=${payload?.rmId}&search_value=${payload?.search}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_INVESTOR_LIST_EXPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTOR_LIST_EXPORT, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const investorIINExport  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/getInstaIINReport?customer_id=${payload?.userId}&from_date=${payload?.startDate}&to_date=${payload?.endDate}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_TRANSACTION_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_TRANSACTION_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const investmentList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investments/list?perpage=${payload?.per_page}&pagenum=${payload?.page_num}&search=${payload?.search}&type=${payload?.type}&subtype=${payload?.subType}&rmid=${payload?.rmid}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_INVESTMENT_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTMENT_LIST, error: error.message });
      console.error("Error fetching Investment List:", error.message);
      return error;
    }
  };
};

export const transactionList  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/transactions/list?perpage=${payload?.per_page}&pagenum=${payload?.page_num}&search=${payload?.search}&status=${payload?.status}&rmid=${payload?.rmId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_TRANSACTION_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_TRANSACTION_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const investmentListExport  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investments/export?user_id=${payload?.userId}&start_date=${payload?.startDate}&end_date=${payload?.endDate}&rm_id=${payload?.rmId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_TRANSACTION_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_TRANSACTION_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const transactionListExport  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/transactions/export?user_id=${payload?.userId}&start_date=${payload?.startDate}&end_date=${payload?.endDate}&rm_id=${payload?.rmId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_TRANSACTION_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_TRANSACTION_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const customerListExport  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/transactions/getCustomersList?page_name=${payload.page}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  CUSTOMER_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: CUSTOMER_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const rmUserList  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/relationship-manager?per_page=${payload?.per_page}&page_num=${payload?.page_num}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  RM_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const faqList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/faq/list?search=${payload?.search}&per_page=${payload?.per_page}&page_num=${payload?.page_num}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  FAQ_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: FAQ_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const faqAdd = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/faq/add`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  FAQ_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: FAQ_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};
export const faqEdit = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}admin/faq/edit/${id}`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  FAQ_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: FAQ_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};
export const faqDelete = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}admin/faq/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: FAQ_LIST, payload: response.data });
      if (response.data.status == false) {
        toast.error(
          response.data.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
        );
      } else {
        toast.success(response.data.message);
        dispatch(
          faqList({
            page_num: "",
            per_page: "10",
            search: "",
          })
        ); 
      }
    } catch (error) {
      dispatch({ type: FAQ_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const faqDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/faq/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  FAQ_DETAIL, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: FAQ_DETAIL, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const supportList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/support/list?per_page=${payload?.per_page}&page_num=${payload?.page_num}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: SUPPORT_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: SUPPORT_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const supportShow = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/support/show/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  SUPPORT_SHOW, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: SUPPORT_SHOW, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const supportDetail = (messageId,ticketId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/support/showReplyData/${messageId}/${ticketId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: SUPPORT_DETAIL, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: SUPPORT_DETAIL, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const supportCloseTicket = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/support/closeTicket/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  SUPPORT_CLOSE, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: SUPPORT_CLOSE, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const supportReply = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/support/reply`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  FAQ_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: FAQ_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const rmAdd = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/relationship-manager/add`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: RM_LIST, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};
export const rmEdit = (payload, id) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}admin/relationship-manager/edit/${id}`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: RM_LIST, payload: response.data }); // Ensure `response.data` has the correct format
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};
export const rmDelete = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}admin/relationship-manager/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: RM_LIST, payload: response.data });
      if (response.data.status == false) {
        toast.error(
          response.data.message?.original?.message
            ? response.data.message?.original?.message
            : response.data?.message
        );
      } else {
        toast.success(response.data.message);
        dispatch(
          rmUserList({
            page_num: "",
            per_page: "10",
          })
        ); 
      }
    } catch (error) {
      dispatch({ type: RM_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const rmDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/relationship-manager/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  RM_DETAIL, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_DETAIL, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const rmAssign = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/relationship-manager/assign-users`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  RM_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const rmAssignList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/relationship-manager/userList/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  RM_ASSIGN_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_ASSIGN_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const rmBankList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/bankList`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  RM_BANK_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_BANK_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const adminMailBack = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/admin-mailback`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      dispatch({ type: ADMIN_MAILBACK, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_MAILBACK, error: error.message });
      console.error("Error fetching Dashboard Valuation Data:", error.message);
      return error;
    }
  };
};

export const customerStatusList  = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/getIINUpdate`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  CUSTOMER_STATUS_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: CUSTOMER_STATUS_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const updateAMCList  = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investments/updateAMCs`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  CUSTOMER_STATUS_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: CUSTOMER_STATUS_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const adminEmailUpdate = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/email/update`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      dispatch({ type: ADMIN_EMAIL_UPDATE, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_EMAIL_UPDATE, error: error.message });
      console.error("Error fetching Dashboard Valuation Data:", error.message);
      return error;
    }
  };
};

export const adminMobileUpdate = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/investors/updateMobileNo`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      dispatch({ type: ADMIN_MOBILE_UPDATE, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_MOBILE_UPDATE, error: error.message });
      console.error("Error fetching Dashboard Valuation Data:", error.message);
      return error;
    }
  };
};

export const AdminChangePasswordPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/changePassword`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_FORGOT_PASSWORD, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_FORGOT_PASSWORD, error: error.message });
    }
  };
};

export const AddInvestor = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/investors/addNewInvestor`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: ADMIN_INVESTOR_ADD, payload: response.data });
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: ADMIN_INVESTOR_ADD, error: error.message });
    }
  };
};

export const UpdateInvestor = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/investors/updateInvestor`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: ADMIN_INVESTOR_ADD, payload: response.data });
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: ADMIN_INVESTOR_ADD, error: error.message });
    }
  };
};

export const InvestorDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/viewInvestorsDetail/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_INVESTOR_DETAIL, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTOR_DETAIL, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const InvestorAddFormData = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/getAddFormsData`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_ADD_INVESTOR_DATA, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_ADD_INVESTOR_DATA, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const InvestorEditFormData = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/getEditFormsData/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_EDIT_INVESTOR_DATA, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_EDIT_INVESTOR_DATA, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const ResubmitProfileDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/getResubmitProfileData/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_RESUBMIT_PROFILE, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_RESUBMIT_PROFILE, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const ResubmitProfileUcid = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/profile-resubmit/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_RESUBMIT_PROFILE, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_RESUBMIT_PROFILE, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const FatcaDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/fatcaStatus/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_FATCA_DATA, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_FATCA_DATA, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const IINLinkDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/generateIINlink/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_IIN_LINK, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_IIN_LINK, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const FatcaLinkDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/generateFatcaLink/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  ADMIN_FATCA_LINK, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_FATCA_LINK, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const ResubmitProfile = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}admin/investors/resubmitProfile`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: ADMIN_INVESTOR_ADD, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: ADMIN_INVESTOR_ADD, error: error.message });
    }
  };
};

export const BrokerDetail = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/check-broker/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: ADMIN_CHECK_BROKER, payload: response.data });
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: ADMIN_CHECK_BROKER, error: error.message });
    }
  };
};

export const BankDetailsByIfsc = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}admin/investors/getBankDetailsFromIFSC`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: BANK_DETAIL_IFSC, payload: response.data });
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: BANK_DETAIL_IFSC, error: error.message });
    }
  };
};