import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartCard = ({dataArr}) => {
  const options = {
    plugins: {
      legend: {
        display: false, // Hides the legend
      },
      tooltip: {
        enabled: true, // Hides the tooltips
      },
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, cur) => acc + cur, 0);
          const percentage = ((value / total) * 100).toFixed(2) + '%'; // Calculate percentage
          return percentage;
        },
        color: '#fff',
        font: {
          weight: 'bold',
        },
      },
    },
    maintainAspectRatio: false
  };

  return (
    <Pie data={dataArr} options={options} />
  );
};

export default ChartCard;
