import '../styles/HoldingSection.css';
import RecommendedFundPlanDesign4 from './RecommendedFundPlanDesign4';
import ActionPopupButton from './ActionPopupButton';
import SectionLumpsum from './SectionLumpsum';

const HoldingSectionLumpsum = ({ data, isDashboard }) => {
  const currentPageURL = window.location.pathname;
  let viewPageUrl = "/explore/fund-detail";
  // Limit the data to 2 items if on the dashboard
  const limitedData = isDashboard ? data?.current_holdings?.slice(0, 2) : data?.current_holdings;

  return (
    <div>
      {limitedData && limitedData.length > 0 ? (
        limitedData.map((item, index) => (
          <div key={index} className="mb-3">
            <SectionLumpsum
              bookmark={true}
              plus={true}
              image={item.icon}
              amcId={item?.amc_product_id}
              title={item.amc_name || '-'}
              badge={item?.product?.asset_class || '-'}
              badge1={item?.product?.plan_type || '-'}
              price1={item.folioNumber || '-'}
              price2={item.transactionType || '-'}
              price3={item.invested_amount || '-'}
              price4={item.current_value || '-'}
              price5={item.return || '-'}
              price6={item.units || '-'}
              price7={item.price7 || '-'}
              url={viewPageUrl}
              redirectUrl={currentPageURL}
              // actionButton={<ActionPopupButton filterColor={true} />}
              actionButton={{ filterColor: true, item }}
            />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default HoldingSectionLumpsum;
