import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import WishlistCards from "../components/WishlistCards";
import { getWatchList, deleteWatchList } from "../actions/watchListActions";
import { getLocalStorageUserId } from "../utils/userData";
import Loader from "../loader/Loader-ui";

const Wishlist = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentPageURL = window.location.pathname;
  // Extracting necessary state with default values for safe destructuring
  const { getWatchList: watchListData = {} } = useSelector(
    (state) => state.watchList
  );

  const userId = getLocalStorageUserId();
  const watchListProducts = watchListData?.data?.products || [];

  // Function to fetch watchlist data
  const fetchWatchList = useCallback(async () => {
    if (userId) {
      try {
        setIsLoading(true); // Show loader
        await Promise.all([dispatch(getWatchList({ id: userId }))]);
      } catch (error) {
        console.error("Error fetching watchlist:", error);
      } finally {
        setIsLoading(false); // Hide loader
      }
    }
  }, [dispatch, userId]);

  // Fetch watchlist on component mount
  useEffect(() => {
    // if (isMounted.current) {
    fetchWatchList();
    // } else {
    //   isMounted.current = true;
    // }
  }, [fetchWatchList]);

  // Memoized delete handler to avoid unnecessary re-renders
  const handleDelete = useCallback(
    (productId) => {
      if (userId) {
        try {
          setIsLoading(true); // Hide loader
          dispatch(deleteWatchList({ id: userId, product_id: productId }));
        } catch (error) {
          console.error("Error deleting item from watchlist:", error);
        } finally {
          setIsLoading(false); // Hide loader
        }
      }
    },
    [dispatch, userId]
  );

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box page_content_overflow">
          <h5 className="mb-4 main-heading-title">Watchlist</h5>
          <div className="wishlist_card_inner">
            {isLoading ? (
              <Loader />
            ) : watchListProducts.length > 0 ? (
              watchListProducts.map((item) => (
                <div className="mb-3" key={item.id}>
                  <WishlistCards
                    id={item.id}
                    image={item.amc?.icon || ""}
                    amcId={item?.amc_product_id}
                    isin={item?.isin}
                    bookmark={item?.watch_list}
                    plus={item?.cart_list}
                    title={item.product_long_name}
                    badge={item.plan_type}
                    badge2={item.asset_class}
                    price1={`₹${parseFloat(
                      item.nav?.nav || "0.00"
                    ).toFixed(2)}`} // Current NAV price
                    navDate={item.nav?.date}
                    price2={`₹${parseFloat(
                      item.productlimits?.find(
                        (limit) => limit.sub_trxn_type === "N"
                      )?.minimum_amount || "0.00"
                    ).toFixed(2)}`} // Added condition to show SIP and lumpsum price difference
                    price3={`₹${parseFloat(
                      item.productlimits?.find(
                        (limit) => limit.sub_trxn_type === "S"
                      )?.minimum_amount || "0.00"
                    ).toFixed(2)}`}
                    value={
                      item.purchase_allowed === "Y" ? "Allowed" : "Not Allowed"
                    }
                    value2={
                      item.redemption_allowed === "Y"
                        ? "Allowed"
                        : "Not Allowed"
                    }
                    badgecolor="green"
                    badgecolor2="purple"
                    onDelete={handleDelete}
                    redirectUrl={currentPageURL}
                    product={item}
                  />
                </div>
              ))
            ) : (
              <p>No items in your watchlist.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
