import {
  AMC_LIST,
  CAPGAIN_LOSS_REPORT,
  DATE_WISE_REPORT,
  PRODUCT_LIST,
  SCHEME_WISE_REPORT,
  TYPE_WISE_REPORT,
  CUSTOMER_LIST,
  AMC_LIST_ADMIN,
  PRODUCT_LIST_ADMIN
} from "../actions/types";

const initialState = {
  dateWiseReport: {},
  typeWiseReport: {},
  schemeWiseReport: {},
  amcList: {},
  amcListAdmin: [],
  capGainLossReport: {},
  productList: {},
  productListAdmin: [],
  customerList: [],
  loading: false,
};

const reportReducers = (state = initialState, action) => {
  switch (action?.type) {
    case DATE_WISE_REPORT:
      return { ...state, loading: false, dateWiseReport: action?.payload };
    case TYPE_WISE_REPORT:
      return { ...state, loading: false, typeWiseReport: action?.payload };
    case SCHEME_WISE_REPORT:
      return { ...state, loading: false, schemeWiseReport: action?.payload };
    case AMC_LIST:
      return { ...state, loading: false, amcList: action?.payload };
    case AMC_LIST_ADMIN:
      return { ...state, loading: false, amcListAdmin: action?.payload };
    case PRODUCT_LIST:
      return { ...state, loading: false, productList: action?.payload };
      case PRODUCT_LIST_ADMIN:
        return { ...state, loading: false, productListAdmin: action?.payload };
    case CAPGAIN_LOSS_REPORT:
      return { ...state, loading: false, capGainLossReport: action?.payload };
    case CUSTOMER_LIST:
      return { ...state, loading: false, customerList: action?.payload };
    default:
      return state;
  }
};

export default reportReducers;
