import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik"; // Import Formik
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { messageType, sendMessage } from "../../actions/messageAction";
import { getLocalStorageUserData } from "../../utils/userData";
import { adminMailBack } from "../../actions/adminAction";
import { toast } from "react-toastify";

const AdminMailBack = () => {
  const [kfintechFileName, setKfintechFileName] = useState("No file chosen");
  const [disable, setDisable] = useState(false);
  const [camsonlineFileName, setCamsonlineFileName] =
    useState("No file chosen");
  const formData = new FormData();

  const [userTypeVal, setUserTypeVal] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      camsFile: "",
      karvyFile: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      camsFile: Yup.mixed().required("CAMS file is required"),
      // .test(
      //   "fileType",
      //   "Only .csv, .pdf, or .xlsx files are allowed",
      //   (value) =>
      //     value &&
      //     [
      //       "application/pdf",
      //       "application/vnd.ms-excel",
      //       "text/csv",
      //     ].includes(value.type)
      // ),
      karvyFile: Yup.mixed().required("Karvy file is required"),
      // .test(
      //   "fileType",
      //   "Only .csv, .pdf, or .xlsx files are allowed",
      //   (value) =>
      //     value &&
      //     [
      //       "application/pdf",
      //       "application/vnd.ms-excel",
      //       "text/csv",
      //     ].includes(value.type)
      // ),
    }),
    onSubmit: (payload) => {
      setDisable(true);
      for (const key in payload) {
        // For file attachments, ensure it is a valid File object
        if (payload[key] instanceof File) {
          formData.append(key, payload[key]);
        } else {
          console.warn("file_attachment is not a valid File object.");
        }
      }
      dispatch(adminMailBack(formData))
        .then((response) => {
          setDisable(false);
          if (response?.status == true) {
            toast.success(response?.message);
            navigate("/admin/dashboard");
          } else {
            toast.error(
              response?.response?.data?.errors?.message
                ? response?.response?.data?.errors?.message
                : response?.response?.data?.message
                ? response?.response?.data?.message
                : response?.message
                ? response?.message
                : response?.message?.[0]
                ? response?.message?.[0]
                : "The files must be a file of type: csv, txt."
            );
          }
        })
        .catch((error) => {
          setDisable(false);
          console.log(error);
        });
    },
  });

  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">Upload mailback file</h5>
          <form onSubmit={formik.handleSubmit}>
            <div
              className="card redeam_bottom_card"
              style={{
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-11 col-md-10 my-2">
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                      <label
                        htmlFor="type"
                        className="form-label"
                        style={{ fontWeight: "100" }}
                      >
                        Mailback file{" "}
                        <span className="" style={{ fontWeight: "bold" }}>
                          K-Fintech
                        </span>{" "}
                        <span>*</span>
                      </label>
                    </div>

                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 custom-file">
                      <input
                        type="file"
                        className="form-control custom-file-input"
                        name="karvyFile"
                        id="kfintech-file"
                        value={formik.values.subject}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setKfintechFileName(file?.name);
                          formik?.setFieldValue("karvyFile", file);
                        }}
                      />
                      <label
                        htmlFor="kfintech-file"
                        className="custom-file-input-label"
                      >
                        Choose File
                        {/* <span style="border-left: 1px solid black; height: 20px; margin: 0 10px;"></span> */}
                        <span
                          style={{
                            borderLeft: "1px solid black",
                            height: "20px",
                            margin: "0 10px",
                          }}
                        ></span>
                        <span className="file-name">{kfintechFileName}</span>
                      </label>
                      {formik.touched.karvyFile && formik.errors.karvyFile ? (
                        <div className="text-danger mb-2 mailback_error">
                          {formik.errors.karvyFile}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 ">
                      <label
                        className="form-label"
                        style={{ fontWeight: "100" }}
                      >
                        Mailback file{" "}
                        <span className="" style={{ fontWeight: "bold" }}>
                          CamsOnline
                        </span>{" "}
                        <span>*</span>
                      </label>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7">
                      <input
                        type="file"
                        className="form-control custom-file-input"
                        name="camsFile"
                        id="camsonline-file"
                        value={formik.values.subject}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setCamsonlineFileName(file?.name);
                          formik?.setFieldValue("camsFile", file);
                        }}
                      />
                      <label
                        htmlFor="camsonline-file"
                        className="custom-file-input-label"
                      >
                        Choose File
                        <span
                          style={{
                            borderLeft: "1px solid black",
                            height: "20px",
                            margin: "0 10px",
                          }}
                        ></span>
                        <span className="file-name">{camsonlineFileName}</span>
                      </label>
                      {formik.touched.camsFile && formik.errors.camsFile ? (
                        <div className="text-danger mb-2 mailback_error">
                          {formik.errors.camsFile}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Submit button inside the form */}
            <div className="card-footer form_card form_card_border">
              <div className="d-flex justify-content-between gap-2">
                <Link
                  className="form_button"
                  onClick={() => {
                    formik.resetForm();
                    setKfintechFileName("No file chosen");
                    setCamsonlineFileName("No file chosen");
                  }}
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="form_button next_btn"
                  disabled={disable}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminMailBack;
