import React from 'react';
import '../../styles/ticketTable.css';

const RMReportTable = ({reportData}) => {
    return (
        <div className="table-responsive ">
            <table className="table tickets_Messages_table">
                <thead>
                    <tr className="ticket_table_head">
                        <th scope="col">Type</th>
                        <th scope="col">Total Investments</th>
                        <th scope="col">Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Static data for table */}
                    <tr className="ticket_table">
                        <td>Lumpsum</td>
                        <td>{reportData?.type?.lumpsum}</td>
                        <td>₹{reportData?.amount?.lumpsum}</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>SIP</td>
                        <td>{reportData?.type?.sip}</td>
                        <td>₹{reportData?.amount?.sip}</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>STP</td>
                        <td>{reportData?.type?.stp}</td>
                        <td>₹{reportData?.amount?.stp}</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>Switch</td>
                        <td>{reportData?.type?.switch}</td>
                        <td>₹{reportData?.amount?.switch}</td>
                    </tr>
                    <tr className="ticket_table">
                        <td>SWP</td>
                        <td>{reportData?.type?.swp}</td>
                        <td>₹{reportData?.amount?.swp}</td>
                    </tr>

                    {/* Total row */}
                    <tr className="ticket_table total_row">
                        <td>Total</td>
                        <td>{reportData?.total?.investments}</td>
                        <td>₹{reportData?.total?.amount}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default RMReportTable;
