import React, { useEffect, useState } from "react";
import growthTree from "../images/icons/growth-tree.svg";
import Bookmark from "../images/icons/bookmark.svg";
import Plus from "../images/icons/plus.svg";
import Bookmarked from "../images/icons/bookmarked.svg";
import Check from "../images/icons/check.svg";
import "../styles/fundplan.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch
import { addedWatchList } from "../actions/watchListActions"; // Import your action
import { deleteWatchList } from "../actions/watchListActions"; // Import delete action
import {
  getLocalStorageUserData,
  getLocalStorageUserId,
  getLocalStorageUserToken,
} from "../utils/userData";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
import PopupModel from "../components/PopupModel";
import {
  AddToMyCart,
  changeAddToCartState,
  DeleteMyCart,
  updateExploreFundsList,
} from "../actions/exploreFundsActions";
import defaultFundIcon from "../images/Default icon.svg";
const token = getLocalStorageUserToken();
const RecommendedFundPlan2 = (props) => {
  const [isBookmarked, setIsBookmarked] = useState(props?.bookmark);
  const [isAddInCart, setIsAddInCart] = useState(props?.cart_list);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const { addMyCart, loadData } = useSelector((state) => state.exploreFunds);
  const [buttonCheck, setButtonCheck] = useState(false);
  const userData = getLocalStorageUserData();
  const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate();
  const redirectToLoginIfNoToken = () => {
    if (!token) {
      navigate("/login");
    }
  };

  const handleUpdate = (updatedRecords) => {
    dispatch(updateExploreFundsList(updatedRecords)); // Only update changed records
  };

  const closeModal = async () => {
    setIsPopupVisible(false);
    setButtonCheck(false);
    await dispatch(changeAddToCartState(!loadData));
  };
  // useEffect(() => {
  //   if (
  //     (addMyCart?.status == true || addMyCart?.status == false) &&
  //     buttonCheck
  //   ) {
  //     setIsPopupVisible(true);

  //     // setButtonCheck(false);
  //   }
  // }, [buttonCheck]);
  const handleBookmarkClick = async () => {
    redirectToLoginIfNoToken();
    if (!token) return; // Prevent further execution if no token

    const payload = {
      id: getLocalStorageUserId(), // Get userId from localStorage (or any other source)
      product_id: props?.id, // Use the product ID from props
    };

    // If already bookmarked, remove from the watchlist
    if (isBookmarked) {
      await dispatch(deleteWatchList(payload));
      // dispatch(changeAddToCartState(!loadData));
    } else {
      await dispatch(addedWatchList(payload));
    }

    // Toggle the bookmark state after API call
    setIsBookmarked(!isBookmarked);
  };
  const handleAddToCart = async () => {
    redirectToLoginIfNoToken();
    if (!token) return;
    setIsAddInCart(!isAddInCart);
    if (isAddInCart) {
      await dispatch(
        DeleteMyCart(props?.product?.amc_product_id, 1, userData?.id)
      ); // Dispatch delete action
      handleUpdate({ ...props?.product, cart_list: false });
      // dispatch(changeAddToCartState(!loadData));
    } else {
      // setButtonCheck(true);
      handleUpdate({ ...props?.product, cart_list: true });
      const payload = {
        id: userData?.id,
        product_id: props?.id,
        amc_product_id: props?.product?.amc_product_id,
        amc_code: props?.product?.amc_code,
        product_code: props?.product?.productlimits[0]?.product_code,
        isin: props?.product?.isin,
        is_additional_purchase: false,
        reinvest: props?.product?.reinvest_tag,
      };
      await dispatch(AddToMyCart(payload)).then((response) => {
        if (response) {
          setButtonCheck(true);
          setIsPopupVisible(true);
        }
      }); // Dispatch add action
    }
  };
  return (
    <div className="card fund-card overflow-hiddden bg-white">
      <div className="card-body">
        <div className="fund-details-info d-flex flex-sm-row flex-column">
          <div className="d-flex align-items-center">
            <img
              src={props.image}
              alt="Fund Logo"
              className="fund-logo me-3 mb-sm-0 mb-2"
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop if default image fails
                e.target.src = defaultFundIcon;
              }}
            />
            <Link
              className="d-sm-none d-block"
              to={props.url}
              data-toggle="tooltip"
              data-placement="top"
              data-trigger="hover"
              state={{ data: props.amcId }}
            >
              <h6 className="fund-name">{props.title}</h6>
            </Link>
          </div>

          <div>
            <div>
              <Link
                className="d-sm-block d-none"
                to={props.url}
                data-toggle="tooltip"
                data-placement="top"
                data-trigger="hover"
                title={props.title}
                state={{ data: props.amcId }}
              >
                <h6 className="fund-name">{props.title}</h6>
              </Link>
            </div>
            <div className="d-flex flex-wrap gap-2">
              <span className="badge custom-badge green-badge ">
                {props?.badge?.toUpperCase()}
              </span>
              <span className="badge custom-badge purple-badge">
                {props?.badge2?.toUpperCase()}
              </span>
            </div>
          </div>
          <div className="ms-sm-auto mt-sm-0 mt-2 d-flex">
            <span
              className="me-2 bookmarked"
              onClick={handleBookmarkClick}
              style={{ cursor: "pointer" }}
            >
              <img
                src={isBookmarked ? Bookmarked : Bookmark}
                alt="bookmark"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Wishlist"
              />
            </span>
            <span
              className="checked"
              onClick={handleAddToCart}
              style={{ cursor: "pointer" }}
            >
              <img
                src={props?.cart_list ? Check : Plus}
                alt="plus"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={isAddInCart ? "Added to Cart" : "Add to Cart"}
              />
            </span>
          </div>
        </div>
        <div className="d-flex flex-sm-row flex-column gap-3 mt-3 justify-content-between">
          <p className="investment-details mb-0 me-4">
            NAV: <span className="d-block">{props?.price1}</span>
          </p>
          {props.price2 !== "₹0.00" ? (
            <p className="investment-details mb-0">
              Min. Investment (Lumpsum):{" "}
              <span className="d-block">{props?.price2}</span>
            </p>
          ) : (
            <p className="investment-details mb-0">
              Min. Investment (Lumpsum):{" "}
              <span className="d-block">Not available</span>
            </p>
          )}

          {props.price3 !== "₹0.00" ? (
            <p className="investment-details mb-0">
              Min. Investment (SIP):{" "}
              <span className="d-block">{props?.price3}</span>
            </p>
          ) : (
            <p className="investment-details mb-0">
              Min. Investment (SIP):{" "}
              <span className="d-block">Not available</span>
            </p>
          )}
        </div>
      </div>

      {buttonCheck && isPopupVisible && addMyCart?.status == true ? (
        <PopupModel
          id="successModel"
          title="Lorem Ipsum"
          content={addMyCart?.message}
          image={successlogo}
          closeModal={closeModal}
          btnColor={"btn-success"}
          type="addtocart"
        />
      ) : (
        buttonCheck &&
        isPopupVisible && (
          <PopupModel
            id="alertModel"
            title="Lorem Ipsum"
            content={addMyCart?.message}
            image={cancel}
            closeModal={closeModal}
            btnColor={"btn-success"}
            type="addtocart"
          />
        )
      )}
    </div>
  );
};

export default RecommendedFundPlan2;
