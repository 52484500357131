import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import FaqTable from "../../components/FaqTable";
import { FaPlus } from "react-icons/fa6";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { faqList } from "../../actions/adminAction";
import Loader from "../../loader/Loader-ui";
import InvestButton from "../../components/InvestButton";

const AdminFAQ = () => {
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      faqList({
        page_num: currentPage,
        per_page: "10",
        search: search,
      })
    );
  }, [dispatch, currentPage, search]);

  const { faqListData = {} } = useSelector((state) => state?.admin);

  const data = faqListData?.data?.faqsList || [];
  const totalPages = faqListData?.data?.pagination?.total_pages || 1;
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="container-fluid page_background">
      {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <div className="d-flex justify-content-between mb-3 align-items-center">
            <h5 className="mb-4 main-heading-title">
              Frequently Asked Questions{" "}
            </h5>
            <InvestButton
              label={
                <>
                  <FaPlus className="mr-1" />
                  Add
                </>
              }
              url="/admin/addfaq"
              color="blue"
              className=""
            />
          </div>
          {/* Tabs */}
          <div className="transaction_tab">
            <FaqTable
            data={data}
            currentPage={currentPage}
            // onTypeChange={handleTypeChange}
            />
            <div className="pt-5">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFAQ;
