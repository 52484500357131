

import { useNavigate } from 'react-router-dom';

const MappingDetails = () => {

    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/admin/resubmitprofile');
    };

    return (
        <div className="container-fluid page_background ">
            <div className="page-inner-content mb-3">
                <div className="page_inner_content_box">
                    <h5 className="mb-4 main-heading-title"> Investor's status </h5>

                    <div
                        className="card mapping-details-box mapping-details-box1 investor-no-border"

                    >

                        <div className="header-purple">
                            <p> Cheque Image exists - <span className="header-purple-span custom-pointer" onClick={handleNavigation}> Resubmit Profile </span>  </p>
                        </div>


                        <div className="card redeam_bottom_card mapping-details-no-border accordion-body1 mapping-details-body">
                            <div className="row form_body justify-content-center">
                                <div className="col-xl-6 col-lg-10 col-md-10 ">

                                    <div className="row align-items-center personalAccordian mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                                        <div className="col-6 my-md-2 my-1">
                                            <label className="form-label">IIN:</label>
                                        </div>
                                        <div className="col-6 my-md-2 my-1">
                                            <span>5014715633</span>
                                        </div>
                                    </div>


                                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                                        <div className="col-6 my-md-2 my-1">
                                            <label className="form-label">Name:</label>
                                        </div>
                                        <div className="col-6 my-md-2 my-1">
                                            <span>Naveen K</span>
                                        </div>
                                    </div>


                                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                                        <div className="col-6 my-md-2 my-1">
                                            <label className="form-label">IIN Status:</label>
                                        </div>
                                        <div className="col-6 my-md-2 my-1">
                                            <span>Y</span>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                                        <div className="col-6 my-md-2 my-1">
                                            <label className="form-label">Reason:</label>
                                        </div>
                                        <div className="col-6 my-md-2 my-1">
                                            <span>KYC status not available</span>
                                        </div>
                                    </div>


                                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                                        <div className="col-6 my-md-2 my-1">
                                            <label className="form-label">FATCA/KYC status:</label>
                                        </div>
                                        <div className="col-6 my-md-2 my-1 d-flex align-items-center">
                                            <span>Yes</span>

                                        </div>
                                    </div>


                                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                                        <div className="col-6 my-md-2 my-1">
                                            <label className="form-label">Last updated on:</label>
                                        </div>
                                        <div className="col-6 my-md-2 my-1">
                                            <span>03-Jan 2025, 01:48:14 pm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    );
};

export default MappingDetails;