import React, { useState } from "react"; 
import successlogo from '../images/popup/success-popup.svg'; 
import cancel from '../images/popup/alert-image.svg'; 
import '../styles/profile.css';
import PopupModel from "../components/PopupModel";

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };


  return (
    <div className="outer_pop_up d-flex flex-wrap gap-3">
  <button
    className="btn btn-primary modelButton "
    data-bs-toggle="modal"
    data-bs-target="#successModal"
  >
    Success Modal
  </button>
  <button
    className="btn btn-primary modelButton  "
    data-bs-toggle="modal"
    data-bs-target="#alertModel"
  >
    Alert Modal
  </button>

  {/* Bootstrap Modal */}
  <PopupModel id='successModal' title='Order placed successfully!' content='Please check your email for details.' image={successlogo}/>
  <PopupModel id='alertModel' title='Lorem Ipsum' content='Lorem Ipsum dolor sit amet.' image={cancel}/>
</div>



  );
};

export default App;
