import React, { useState, useRef, useEffect } from "react";
import "../styles/transaction.css";
import addIcon from "../images/my_profile/add.svg"; // Add your add icon here
import manage_nominees from "../images/my_profile/manage_nominees.svg";
import pen_inner from "../images/my_profile/pen_inner.svg";

import arrow from "../images/my_profile/arrow.svg";
import plus from "../images/my_profile/plus.svg";
import calendar_month from "../images/my_profile/calendar_month.svg"; // Add your calendar icon path
import SelectDropDown from "./SelectDropDown"; // Assuming this is a custom component
import DatePickerInput from "./DatePickerInput";
import { useFormik } from "formik";
import "../styles/profile.css";
import * as Yup from "yup";
import view from "../images/my_profile/eye.svg";
import {
  userNomineeDetail,
  userNomineeDetailAdd,
  userOnboardingGetDetails,
} from "../actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageUserData } from "../utils/userData";
import {
  convertDateToDDMMMYYYY,
  convertDateToDDMMYYYY,
} from "../utils/DateFormate";
import { toast } from "react-toastify";

const getOrdinalSuffix = (index) => {
  const j = index % 10,
    k = index % 100;

  if (j === 1 && k !== 11) return `${index}st`;
  if (j === 2 && k !== 12) return `${index}nd`;
  if (j === 3 && k !== 13) return `${index}rd`;
  return `${index}th`;
};

const ManageNominees = () => {
  const [currentView, setCurrentView] = useState("view");
  const [nomineeCount, setNomineeCount] = useState(1);
  const [save, setSave] = useState(false);
  const [editId, setEditId] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // Track if form is submitted
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const { userNomineeDet, userOnboardGetData } = useSelector(
    (state) => state.users
  );
  // Handlers for view and add clicks
  const handleViewClick = () => setCurrentView("view");
  const handleAddClick = () => setCurrentView("add");
  const handleEditClick = () => setCurrentView("edit");

  const dateInputRef = useRef(null);
  // State to manage the nominees list
  const [nominees, setNominees] = useState([
    { name: "", relation: "", dob: "", percent: "", pan: "" },
  ]);

  const handleInputChange = (index, field, value) => {
    const updatedNominees = [...nominees];
    updatedNominees[index][field] = value;
    setNominees(updatedNominees);
  };

  useEffect(() => {
    const payload = {
      id: userData?.id,
      completed_wizard_step: "3",
    };
    dispatch(userOnboardingGetDetails(payload));
  }, [userNomineeDet]);

  const addNominee = () => {
    if (nomineeCount != 3) {
      //   setNominees([
      //     ...nominees,
      //     { name: "", relation: "", dob: "", percent: "", pan: "" },
      //   ]);
      setNomineeCount(nomineeCount + 1);
      formik.setValues({
        ...values,
        [`nominee${nomineeCount + 1}_name`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_name`
          ] || "",
        [`nominee${nomineeCount + 1}_relation`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_relation`
          ] || "",
        [`nominee${nomineeCount + 1}_dob`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_dob`
          ] != null
            ? userOnboardGetData?.data?.user_profile?.[
                `nominee${nomineeCount + 1}_dob`
              ]
            : "" || "",
        [`nominee${nomineeCount + 1}_percent`]:
          parseFloat(
            userOnboardGetData?.data?.user_profile?.[
              `nominee${nomineeCount + 1}_percent`
            ]
          ).toFixed(2) || "",
        [`nominee${nomineeCount + 1}_guard_name`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_guard_name`
          ] || "",
        [`nominee${nomineeCount + 1}_guard_pan`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_guard_pan`
          ] || "",
      });
    }
  };

  const handleIconClick = () => {
    if (dateInputRef.current) {
      if (dateInputRef.current.showPicker) {
        dateInputRef.current.showPicker(); // For modern browsers
      } else {
        dateInputRef.current.focus(); // Fallback for older browsers
      }
    }
  };

  // const handleIconClick = (inputId) => {
  //   const input = document.getElementById(inputId);
  //   if (input) {
  //     input.focus();
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      nominee1_name: userNomineeDet?.data?.nominees?.nominee1_name || "",
      nominee1_relation:
        userNomineeDet?.data?.nominees?.nominee1_relation || "",
      nominee1_dob:
        userNomineeDet?.data?.nominees?.nominee1_dob != null
          ? userNomineeDet?.data?.nominees?.nominee1_dob
          : "" || "",
      nominee1_percent:
        userNomineeDet?.data?.nominees?.nominee1_percent == null
          ? 0
          : parseFloat(
              userNomineeDet?.data?.nominees?.nominee1_percent
            ).toFixed(2) || 0,
      nom1_pan: userNomineeDet?.data?.nominees?.nom1_pan || "",
      nominee1_guard_name:
        userNomineeDet?.data?.nominees?.nominee1_guard_name || "",
      nominee1_guard_pan:
        userNomineeDet?.data?.nominees?.nominee1_guard_pan || "",
      nominee2_name: userNomineeDet?.data?.nominees?.nominee2_name || "",
      nominee2_relation:
        userNomineeDet?.data?.nominees?.nominee2_relation || "",
      ...(userNomineeDet?.data?.nominees?.nom2_pan
        ? { nom2_pan: userNomineeDet?.data?.nominees?.nom2_pan }
        : {}),
      nominee2_dob:
        userNomineeDet?.data?.nominees?.nominee2_dob != null
          ? userNomineeDet?.data?.nominees?.nominee2_dob
          : "" || "",
      nominee2_percent: userNomineeDet?.data?.nominees?.nominee2_percent
        ? parseFloat(userNomineeDet?.data?.nominees?.nominee2_percent).toFixed(
            2
          )
        : 0 || 0,
      nominee2_guard_name:
        userNomineeDet?.data?.nominees?.nominee2_guard_name || "",
      nominee2_guard_pan:
        userNomineeDet?.data?.nominees?.nominee2_guard_pan || "",
      nominee3_name: userNomineeDet?.data?.nominees?.nominee3_name || "",
      nominee3_relation:
        userNomineeDet?.data?.nominees?.nominee3_relation || "",
      nominee3_dob:
        userNomineeDet?.data?.nominees?.nominee3_dob != null
          ? userNomineeDet?.data?.nominees?.nominee3_dob
          : "" || "",
      ...(userNomineeDet?.data?.nominees?.nom3_pan
        ? { nom3_pan: userNomineeDet?.data?.nominees?.nom3_pan }
        : {}),
      nominee3_percent: userNomineeDet?.data?.nominees?.nominee3_percent
        ? parseFloat(userNomineeDet?.data?.nominees?.nominee3_percent).toFixed(
            2
          )
        : 0 || 0,
      nominee3_guard_name:
        userNomineeDet?.data?.nominees?.nominee3_guard_name || "",
      nominee3_guard_pan:
        userNomineeDet?.data?.nominees?.nominee3_guard_pan || "",
      nom1_guardian_relation:
        userNomineeDet?.data?.nominees?.nom1_guardian_relation || "",
      nom2_guardian_relation:
        userNomineeDet?.data?.nominees?.nom2_guardian_relation || "",
      nom3_guardian_relation:
        userNomineeDet?.data?.nominees?.nom3_guardian_relation || "",
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (isFormSubmitted) {
        for (let i = 1; i <= nomineeCount; i++) {
          const nameKey = `nominee${i}_name`;
          const relationKey = `nominee${i}_relation`;
          const dobKey = `nominee${i}_dob`;
          const percentKey = `nominee${i}_percent`;
          const guardianNameKey = `nominee${i}_guard_name`;
          const guardianRelationKey = `nom${i}_guardian_relation`;
          const guardianPanKey = `nominee${i}_guard_pan`;
          const panKey = `nom${i}_pan`;

          const currentDate = new Date();
          const dob = new Date(values[dobKey]);
          const age = currentDate.getFullYear() - dob.getFullYear();
          const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
          if (!values[nameKey]) {
            errors[nameKey] = "Name is required";
          } else if (!/^[A-Za-z\s]+$/.test(values[nameKey])) {
            errors[nameKey] = "Name must contain only alphabets";
          }
          if (age >= 18) {
            if (!values[panKey]) {
              errors[panKey] = "PAN Number is required";
            } else if (!panRegex.test(values[panKey])) {
              errors[panKey] = "Invalid PAN Details";
            }
          }
          if (!values[relationKey] || values[relationKey] === "Select")
            errors[relationKey] = "Relation is required";
          if (!values[dobKey]) errors[dobKey] = "Date of birth is required";
          if (
            !values[percentKey] ||
            values[percentKey] <= 0 ||
            values[percentKey] > 100
          ) {
            errors[percentKey] = "Percentage must be between 1 and 100";
          }

          if (age < 18) {
            if (!values[guardianNameKey]) {
              errors[guardianNameKey] = "Guardian Name is required";
            } else if (!/^[A-Za-z\s]+$/.test(values[guardianNameKey])) {
              errors[guardianNameKey] =
                "Guardian Name must contain only alphabets";
            }

            if (
              !values[guardianRelationKey] ||
              values[guardianRelationKey] === "Select"
            )
              errors[guardianRelationKey] = "Guardian Relation is required";

            if (!values[guardianPanKey]) {
              errors[guardianPanKey] = "Guardian PAN is required";
            } else if (!panRegex.test(values[guardianPanKey])) {
              errors[guardianPanKey] = "Invalid Guardian PAN format";
            }
          }
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      setSave(true);
      const payload = {
        ...values,
        nominee1_dob: values?.nominee1_dob
          ? convertDateToDDMMMYYYY(values?.nominee1_dob)
          : "",
        nominee2_dob: values?.nominee2_dob
          ? convertDateToDDMMMYYYY(values?.nominee2_dob)
          : "",
        nominee3_dob: values?.nominee3_dob
          ? convertDateToDDMMMYYYY(values?.nominee3_dob)
          : "",
      };

      const nom1 = values?.nominee1_percent
        ? parseFloat(values?.nominee1_percent)
        : 0;
      const nom2 = values?.nominee2_percent
        ? parseFloat(values?.nominee2_percent)
        : 0;
      const nom3 = values?.nominee3_percent
        ? parseFloat(values?.nominee3_percent)
        : 0;
      if (nom1 + nom2 + nom3 == 100) {
        dispatch(userNomineeDetailAdd(payload));
        setTimeout(() => {
          setCurrentView("view");
        }, 1000);
      } else {
        toast.error("Total percentage cannot exceed 100%");
      }
      //   const formatDate = (dateString) => {
      //     const date = new Date(dateString);

      //     const options = {
      //       day: "2-digit",
      //       month: "short", // 'short' gives abbreviated month names like 'Jan', 'Feb', etc.
      //       year: "numeric",
      //     };

      //     return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
      //   };

      //   const payload = {
      //     ...values,
      //     nominee1_dob: values?.nominee1_dob
      //       ? formatDate(values?.nominee1_dob)
      //       : "",
      //     nominee2_dob: values?.nominee2_dob
      //       ? formatDate(values?.nominee2_dob)
      //       : "",
      //     nominee3_dob: values?.nominee3_dob
      //       ? formatDate(values?.nominee3_dob)
      //       : "",
      //     id: userData?.id,
      //     completed_wizard_step: 3,
      //   };
    },
  });

  useEffect(() => {
    setNomineeCount(
      userNomineeDet?.data?.nominees?.no_of_nominees == null
        ? 1
        : userNomineeDet?.data?.nominees?.no_of_nominees
    );
  }, [userNomineeDet]);
  useEffect(() => {
    dispatch(userNomineeDetail());
  }, []);

  const deleteNominee = (position) => {
    const updatedValues = { ...formik.values };

    // Remove the specific nominee
    for (let i = position; i < nomineeCount; i++) {
      updatedValues[`nominee${i}_name`] =
        updatedValues[`nominee${i + 1}_name`] || "";
      updatedValues[`nominee${i}_relation`] =
        updatedValues[`nominee${i + 1}_relation`] || "";
      updatedValues[`nominee${i}_dob`] =
        updatedValues[`nominee${i + 1}_dob`] || "";
      updatedValues[`nominee${i}_percent`] =
        updatedValues[`nominee${i + 1}_percent`] || "";
      updatedValues[`nominee${i}_guard_name`] =
        updatedValues[`nominee${i + 1}_guard_name`] || "";
      updatedValues[`nominee${i}_guard_pan`] =
        updatedValues[`nominee${i + 1}_guard_pan`] || "";
    }

    // Remove the last nominee (shifted forward)
    delete updatedValues[`nominee${nomineeCount}_name`];
    delete updatedValues[`nominee${nomineeCount}_relation`];
    delete updatedValues[`nominee${nomineeCount}_dob`];
    delete updatedValues[`nominee${nomineeCount}_percent`];
    delete updatedValues[`nominee${nomineeCount}_guard_name`];
    delete updatedValues[`nominee${nomineeCount}_guard_pan`];

    setNomineeCount(nomineeCount - 1);
    formik.setValues(updatedValues);
  };

  const { values, errors, handleChange, setFieldValue, handleSubmit } = formik;
  const dob = new Date(values[`nominee${editId}_dob`]);
  const age = isNaN(dob.getTime())
    ? null
    : new Date().getFullYear() - dob.getFullYear();
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingEight">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEight"
          aria-expanded="false"
          aria-controls="collapseEight"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img
                src={manage_nominees}
                alt=""
                className="personel_details_img"
              />
              {/* <span className="personal_inner_text">Manage Nominees</span> */}
              <span className="personal_inner_text">
                {currentView === "view" && "Manage Nominees"}
                {currentView === "add" && "Manage Nominees Add"}
                {currentView === "edit" && "Manage Nominees Edit"}
              </span>
            </div>

            {/* <div className="edit_personel">
                            <span className="badge editBadge">
                                <img src={addIcon} alt="" className="pen_image" />
                                <span className="editInner d-sm-block d-none">Add</span>
                            </span>
                        </div> */}
          </div>
        </button>
      </h2>
      <div
        id="collapseEight"
        className="accordion-collapse collapse"
        aria-labelledby="headingEight"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />

        <div className="accordion-body profile_accordion_body redeam_bottom_card">
          <div className="d-flex justify-content-end align-items-right">
            <div className="edit_personel">
              {currentView !== "view" && (
                <>
                  <span
                    onClick={handleViewClick}
                    className="badge editBadge profile-mr"
                  >
                    <img src={view} alt="View Icon" className="pen_image" />

                    <span className="editInner d-sm-block d-none">View</span>
                  </span>
                </>
              )}

              {currentView === "view" && (
                <span
                  onClick={handleAddClick}
                  className="badge editBadge addbank-btn-mb profile-mr"
                >
                  <img src={addIcon} alt="" className="pen_image" />

                  <span
                    className="editInner d-sm-block d-none"
                    onClick={handleAddClick}
                  >
                    Add
                  </span>
                </span>
              )}

              {/* </span> */}
            </div>
          </div>

          {/* Manage Noaminess Details Table */}

          {currentView === "view" && (
            <div className="table-responsive bankDetailTable">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Relation</th>
                    <th scope="col">DOB</th>
                    <th scope="col">%</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userNomineeDet?.data?.nominees?.nominee1_name != null && (
                    <tr>
                      <td>1</td>
                      <td>{userNomineeDet?.data?.nominees?.nominee1_name}</td>
                      <td>
                        {userNomineeDet?.data?.nominees?.nominee1_relation}
                      </td>
                      <td>
                        {convertDateToDDMMYYYY(
                          userNomineeDet?.data?.nominees?.nominee1_dob
                        )}
                      </td>
                      <td>
                        {parseFloat(
                          userNomineeDet?.data?.nominees?.nominee1_percent
                        ).toFixed(2)}
                      </td>
                      <td>
                        <button
                          className="details_edit_btn btn p-0"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="Edit"
                          onClick={() => {
                            handleEditClick();
                            setEditId(1);
                          }}
                        >
                          <img
                            src={pen_inner}
                            alt="Edit Nominee"
                            className="pen_image_inner"
                          />
                        </button>
                      </td>
                    </tr>
                  )}
                  {userNomineeDet?.data?.nominees?.nominee2_name != null && (
                    <tr>
                      <td>2</td>
                      <td>{userNomineeDet?.data?.nominees?.nominee2_name}</td>
                      <td>
                        {userNomineeDet?.data?.nominees?.nominee2_relation}
                      </td>
                      <td>
                        {convertDateToDDMMYYYY(
                          userNomineeDet?.data?.nominees?.nominee2_dob
                        )}
                      </td>
                      <td>
                        {parseFloat(
                          userNomineeDet?.data?.nominees?.nominee2_percent
                        ).toFixed(2)}
                      </td>
                      <td>
                        <button
                          className="details_edit_btn btn p-0"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="Edit"
                          onClick={() => {
                            handleEditClick();
                            setEditId(2);
                          }}
                        >
                          <img
                            src={pen_inner}
                            alt="Edit Nominee"
                            className="pen_image_inner"
                          />
                        </button>
                      </td>
                    </tr>
                  )}
                  {userNomineeDet?.data?.nominees?.nominee3_name != null && (
                    <tr>
                      <td>3</td>
                      <td>{userNomineeDet?.data?.nominees?.nominee3_name}</td>
                      <td>
                        {userNomineeDet?.data?.nominees?.nominee3_relation}
                      </td>
                      <td>
                        {convertDateToDDMMYYYY(
                          userNomineeDet?.data?.nominees?.nominee3_dob
                        )}
                      </td>
                      <td>
                        {parseFloat(
                          userNomineeDet?.data?.nominees?.nominee3_percent
                        ).toFixed(2)}
                      </td>
                      <td>
                        <button
                          className="details_edit_btn btn p-0"
                          onClick={() => {
                            handleEditClick();
                            setEditId(3);
                          }}
                        >
                          <img
                            src={pen_inner}
                            alt="Edit Nominee"
                            className="pen_image_inner"
                          />
                        </button>
                      </td>
                    </tr>
                  )}

                  {/* Add more rows as needed */}
                </tbody>
              </table>
              {userNomineeDet?.data?.nominees?.nominee1_name == null &&
                userNomineeDet?.data?.nominees?.nominee2_name == null &&
                userNomineeDet?.data?.nominees?.nominee3_name == null && (
                  <p className="text-center">No nominees found</p>
                )}
            </div>
          )}

          {/* Add Noaminess Form */}

          {currentView === "add" && (
            <div>
              {/* Heading with Back Button */}
              {/* <div className="d-flex align-items-center mb-md-4 mb-3">
                <img src={arrow} alt="" className="add_bank_detail me-3" />
                <h5 className="m-0 add_bank_detail_text">Add Nominee</h5>
              </div> */}

              {/* Nominee Form */}
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <p className="noteNominee mb-4">
                    <span className="noteHeading">Note </span>: You can add a
                    maximum of 3 Nominees.
                  </p>

                  <form onSubmit={formik.handleSubmit} id="manage_nominee">
                    {[...Array(nomineeCount)].map((_, index) => {
                      const i = index + 1;
                      const nameKey = `nominee${i}_name`;
                      const relationKey = `nominee${i}_relation`;
                      const dobKey = `nominee${i}_dob`;
                      const percentKey = `nominee${i}_percent`;
                      const panKey = `nom${i}_pan`;
                      const guardianNameKey = `nominee${i}_guard_name`;
                      const guardianPanKey = `nominee${i}_guard_pan`;
                      const guardianRelationKey = `nom${i}_guardian_relation`;

                      const dob = new Date(values[dobKey]);
                      const age = isNaN(dob.getTime())
                        ? null
                        : new Date().getFullYear() - dob.getFullYear();

                      return (
                        <div key={i}>
                          <div className="mb-4">
                            <h5 className="nominee_title">
                              {`${getOrdinalSuffix(i)} Nominee:`}
                            </h5>
                          </div>
                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label htmlFor={nameKey} className="form-label">
                                Nominee Name*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                id={nameKey}
                                name={nameKey}
                                placeholder="Enter Nominee Name"
                                value={values[nameKey]}
                                onChange={handleChange}
                              />
                              {errors[nameKey] && (
                                <div className="text-danger">
                                  {errors[nameKey]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={relationKey}
                                className="form-label"
                              >
                                Relation*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <SelectDropDown
                                name={relationKey}
                                id={relationKey}
                                value={values[relationKey]}
                                changeFunction={(value) =>
                                  setFieldValue(relationKey, value)
                                }
                                options={userOnboardGetData?.data?.relations?.map(
                                  (relation) => ({
                                    value: relation.value,
                                    label: relation.label,
                                  })
                                )}
                              />
                              {errors[relationKey] && (
                                <div className="text-danger">
                                  {errors[relationKey]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`dob-${index}`}
                                className="form-label"
                              >
                                Nominee Date of Birth*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <DatePickerInput
                                id={dobKey}
                                maxDate={new Date().toISOString().split("T")[0]}
                                selectedDate={values[dobKey]}
                                onChange={(date) => setFieldValue(dobKey, date)}
                              />
                              {errors[dobKey] && (
                                <div className="text-danger">
                                  {errors[dobKey]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`percent-${index}`}
                                className="form-label"
                              >
                                Nominee %*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <input
                                type="number"
                                id={percentKey}
                                name={percentKey}
                                className="form-control"
                                placeholder="Enter Nominee %"
                                value={values[percentKey]}
                                onChange={handleChange}
                              />
                              {errors[percentKey] && (
                                <div className="text-danger">
                                  {errors[percentKey]}
                                </div>
                              )}
                            </div>
                          </div>
                          {age >= 18 || age === null ? (
                            <>
                              <div className="row align-items-center mb-4">
                                <div className="col-lg-5 col-md-6">
                                  <label
                                    htmlFor={panKey}
                                    className="form-label"
                                  >
                                    Enter Pan Number*
                                  </label>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={panKey}
                                    name={panKey}
                                    placeholder="Enter Pan Number"
                                    value={values[panKey]}
                                    onChange={handleChange}
                                  />
                                  {errors[panKey] && (
                                    <div className="text-danger">
                                      {errors[panKey]}
                                    </div>
                                  )}
                                </div>

                                {/* <div className="col-lg-5 col-md-6">
                                <label
                                  htmlFor={`pan-${index}`}
                                  className="form-label"
                                >
                                  Nominee PAN
                                </label>
                              </div>
                              <div className="col-lg-7 col-md-6">
                                <input
                                  type="file"
                                  id={panKey}
                                  name={panKey}
                                  className="form-control"
                                  onChange={(e) =>
                                    setFieldValue(panKey, e.target.files[0])
                                  }
                                  accept=".jpg,.jpeg,.png,.pdf"
                                />
                              </div> */}
                              </div>
                              <button
                                type="button"
                                className="delete_button text-decoration-none"
                                onClick={deleteNominee}
                              >
                                <span>-</span> Delete nominee
                              </button>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="row align-items-center mb-4">
                                <div className="col-lg-5 col-md-6">
                                  <label
                                    htmlFor={`percent-${index}`}
                                    className="form-label"
                                  >
                                    Guardian Name*
                                  </label>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                  <input
                                    type="text"
                                    id={guardianNameKey}
                                    name={guardianNameKey}
                                    className="form-control"
                                    placeholder="Guardian Name"
                                    value={values[guardianNameKey]}
                                    onChange={handleChange}
                                  />
                                  {errors[guardianNameKey] && (
                                    <div className="text-danger">
                                      {errors[guardianNameKey]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="row align-items-center mb-4">
                                <div className="col-lg-5 col-md-6">
                                  <label
                                    htmlFor={`percent-${index}`}
                                    className="form-label"
                                  >
                                    Guardian Pan*
                                  </label>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                  <input
                                    type="text"
                                    id={guardianPanKey}
                                    name={guardianPanKey}
                                    className="form-control"
                                    placeholder="Guardian Pan"
                                    value={values[guardianPanKey]}
                                    onChange={handleChange}
                                  />
                                  {errors[guardianPanKey] && (
                                    <div className="text-danger">
                                      {errors[guardianPanKey]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="row align-items-center mb-4">
                                <div className="col-lg-5 col-md-6">
                                  <label
                                    htmlFor={guardianRelationKey}
                                    className="form-label"
                                  >
                                    Guardian Relation*
                                  </label>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                  <SelectDropDown
                                    name={guardianRelationKey}
                                    id={guardianRelationKey}
                                    value={values[guardianRelationKey]}
                                    changeFunction={(value) =>
                                      setFieldValue(guardianRelationKey, value)
                                    }
                                    options={userOnboardGetData?.data?.relations?.map(
                                      (relation) => ({
                                        value: relation.value,
                                        label: relation.label,
                                      })
                                    )}
                                  />
                                  {errors[guardianRelationKey] && (
                                    <div className="text-danger">
                                      {errors[guardianRelationKey]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <button
                                type="button"
                                className="delete_button text-decoration-none"
                                onClick={deleteNominee}
                              >
                                <span>-</span> Delete nominee
                              </button>
                            </>
                          )}
                        </div>
                      );
                    })}

                    {nomineeCount < 3 && (
                      <button
                        type="button"
                        className="more_button text-decoration-none"
                        onClick={addNominee}
                      >
                        <span>+</span> Add More
                      </button>
                    )}

                    <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                      <button
                        type="submit"
                        className="form_button next_btn"
                        onClick={() => setIsFormSubmitted(true)}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* Edit Noaminess Form */}

          {currentView === "edit" && (
            <div>
              {/* Heading with Back Button */}
              {/* <div className="d-flex align-items-center mb-md-4 mb-3">
                <img src={arrow} alt="" className="add_bank_detail me-3" />
                <h5 className="m-0 add_bank_detail_text">Edit Nominee</h5>
              </div> */}

              {/* Nominee Form */}
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <form onSubmit={formik.handleSubmit} id="manage_nominee">
                    <div>
                      <div className="mb-4">
                        <h5 className="nominee_title">
                          {`${getOrdinalSuffix(editId)} Nominee:`}
                        </h5>
                      </div>
                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6">
                          <label
                            htmlFor={`nominee${editId}_name`}
                            className="form-label"
                          >
                            Nominee Name*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            id={`nominee${editId}_name`}
                            name={`nominee${editId}_name`}
                            placeholder="Enter Nominee Name"
                            value={values[`nominee${editId}_name`]}
                            onChange={handleChange}
                          />
                          {errors[`nominee${editId}_name`] && (
                            <div className="text-danger">
                              {errors[`nominee${editId}_name`]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6">
                          <label
                            htmlFor={`nominee${editId}_relation`}
                            className="form-label"
                          >
                            Relation*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6">
                          <SelectDropDown
                            name={`nominee${editId}_relation`}
                            id={`nominee${editId}_relation`}
                            value={values[`nominee${editId}_relation`]}
                            changeFunction={(value) =>
                              setFieldValue(`nominee${editId}_relation`, value)
                            }
                            options={userOnboardGetData?.data?.relations?.map(
                              (relation) => ({
                                value: relation.value,
                                label: relation.label,
                              })
                            )}
                          />
                          {errors[`nominee${editId}_relation`] && (
                            <div className="text-danger">
                              {errors[`nominee${editId}_relation`]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6">
                          <label
                            htmlFor={`dob-${editId}`}
                            className="form-label"
                          >
                            Nominee Date of Birth*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6">
                          <DatePickerInput
                            id={`nominee${editId}_dob`}
                            maxDate={new Date().toISOString().split("T")[0]}
                            selectedDate={values[`nominee${editId}_dob`]}
                            onChange={(date) =>
                              setFieldValue(`nominee${editId}_dob`, date)
                            }
                          />
                          {errors[`nominee${editId}_dob`] && (
                            <div className="text-danger">
                              {errors[`nominee${editId}_dob`]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6">
                          <label
                            htmlFor={`percent-${editId}`}
                            className="form-label"
                          >
                            Nominee %*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6">
                          <input
                            type="number"
                            id={`nominee${editId}_percent`}
                            name={`nominee${editId}_percent`}
                            className="form-control"
                            placeholder="Enter Nominee %"
                            value={values[`nominee${editId}_percent`]}
                            onChange={handleChange}
                          />
                          {errors[`nominee${editId}_percent`] && (
                            <div className="text-danger">
                              {errors[`nominee${editId}_percent`]}
                            </div>
                          )}
                        </div>
                      </div>
                      {age >= 18 || age === null ? (
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-5 col-md-6">
                            <label
                              htmlFor={`nom${editId}_pan`}
                              className="form-label"
                            >
                              Enter Pan Number*
                            </label>
                          </div>
                          <div className="col-lg-7 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id={`nom${editId}_pan`}
                              name={`nom${editId}_pan`}
                              placeholder="Enter Pan Number"
                              value={values[`nom${editId}_pan`]}
                              onChange={handleChange}
                            />
                            {errors[`nom${editId}_pan`] && (
                              <div className="text-danger">
                                {errors[`nom${editId}_pan`]}
                              </div>
                            )}
                          </div>
                          {/* <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`pan-${editId}`}
                                className="form-label"
                              >
                                Nominee PAN
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <input
                                type="file"
                                id={panKey}
                                name={panKey}
                                className="form-control"
                                onChange={(e) =>
                                  setFieldValue(panKey, e.target.files[0])
                                }
                                accept=".jpg,.jpeg,.png,.pdf"
                              />
                            </div> */}
                        </div>
                      ) : (
                        <>
                          {" "}
                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`percent-${editId}`}
                                className="form-label"
                              >
                                Guardian Name*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <input
                                type="text"
                                id={`nominee${editId}_guard_name`}
                                name={`nominee${editId}_guard_name`}
                                className="form-control"
                                placeholder="Guardian Name"
                                value={values[`nominee${editId}_guard_name`]}
                                onChange={handleChange}
                              />
                              {errors[`nominee${editId}_guard_name`] && (
                                <div className="text-danger">
                                  {errors[`nominee${editId}_guard_name`]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`percent-${editId}`}
                                className="form-label"
                              >
                                Guardian Pan*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <input
                                type="text"
                                id={`nominee${editId}_guard_pan`}
                                name={`nominee${editId}_guard_pan`}
                                className="form-control"
                                placeholder="Guardian Pan"
                                value={values[`nominee${editId}_guard_pan`]}
                                onChange={handleChange}
                              />
                              {errors[`nominee${editId}_guard_pan`] && (
                                <div className="text-danger">
                                  {errors[`nominee${editId}_guard_pan`]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`nom${editId}_guardian_relation`}
                                className="form-label"
                              >
                                Guardian Relation*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <SelectDropDown
                                name={`nom${editId}_guardian_relation`}
                                id={`nom${editId}_guardian_relation`}
                                value={values[`nom${editId}_guardian_relation`]}
                                changeFunction={(value) =>
                                  setFieldValue(
                                    `nom${editId}_guardian_relation`,
                                    value
                                  )
                                }
                                options={userOnboardGetData?.data?.relations?.map(
                                  (relation) => ({
                                    value: relation.value,
                                    label: relation.label,
                                  })
                                )}
                              />
                              {errors[`nom${editId}_guardian_relation`] && (
                                <div className="text-danger">
                                  {errors[`nom${editId}_guardian_relation`]}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                      <button type="submit" className="form_button next_btn">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageNominees;
