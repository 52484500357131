import React, { useState } from "react";
import "../styles/auth.css"; // Import styles
import logo from "../images/icons/header-logo.svg";
import loginSideImage from "../images/login_page/login_image.svg";
import calendarImage from "../images/calendar_month.svg";
import eyeImage from "../images/login_page/eye-image.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import DatePickerWithIcon from "../components/DatePickerWithIcon";

// Functional component for the Home page
const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="login" id="auth_section">
      <div className="container-fluid  login-container">
        <div className="row">
          <div className="col-lg-6 col-md-5 px-0">
            <div className="auth_left_content d-md-flex d-none card h-100 justify-content-start">
              <Link className="navbar-brand" to={`/`}>
                <img src={logo} alt="Logo" />
                {/* Replace 'logo.png' with your logo image path */}
                FUNDSWALA
              </Link>
              <div className="mb-xxl-5">
                <h3 className="geeting_text mb-lg-4">Welcome to Fundswala</h3>
                <h3 className="mb-4">Join Us Now ! </h3>
              </div>
              <img src={loginSideImage} alt="" className="login-image " />
            </div>
          </div>
          <div className="col-lg-6  col-md-7 px-0">
            <div className="right-side-container">
              <div className="d-md-none d-flex flex-column align-items-center">
                <Link
                  className="navbar-brand"
                  to={`/`}
                  style={{ color: "var(--primary-color)" }}
                >
                  <img src={logo} alt="Logo" />
                  {/* Replace 'logo.png' with your logo image path */}
                  FUNDSWALA
                </Link>
                <div className="my-2 text-center">
                  <h3
                    className="geeting_text "
                    style={{ color: "var(--primary-color)" }}
                  >
                    Welcome to Fundswala
                  </h3>
                  <h3
                    className="geeting_text"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Join Us Now !{" "}
                  </h3>
                </div>
              </div>
              {/* <!-- Tabs --> */}
              <ul
                className="nav nav-tabs gap-3"
                id="calculatorTabs"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="signin-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#signIn"
                    type="button"
                    role="tab"
                    aria-controls="signIn"
                    aria-selected="true"
                  >
                    Sign in
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="signUp-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#signUp"
                    type="button"
                    role="tab"
                    aria-controls="signUp"
                    aria-selected="false"
                  >
                    Sign up
                  </button>
                </li>
              </ul>

              {/* <!-- Tab Content --> */}
              <div className="tab-content mt-md-4 mt-4">
                <div
                  className="tab-pane fade show active"
                  id="signIn"
                  role="tabpanel"
                  aria-labelledby="signin-tab"
                >
                  <h5 className="form-title d-md-block d-none">Sign in</h5>
                  <form>
                    <div className="mb-4">
                      <label htmlFor="mobileNumber" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobileNumber"
                        placeholder="Enter Your Mobile Number"
                      />
                    </div>

                    <div className="form-group ">
                      <label className="form-label ps-0">Login with</label>
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="loginMethod"
                            id="loginOTP"
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="loginOTP"
                          >
                            OTP
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="loginMethod"
                            id="loginPassword"
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="loginPassword"
                          >
                            Password
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="input-group position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control mb-0"
                          id="password"
                          placeholder="Enter Your Password"
                        />

                        <span
                          className="passwordicon"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className="color"
                          />
                        </span>
                      </div>
                    </div>

                    <div className="form-group ">
                      <a href="/forgot-password" className="forget_password">
                        Forgot Password?
                      </a>
                    </div>
                    <div className="mt-md-5 mt-sm-4 mt-3 text-end">
                      <button type="submit" className="form_button next_btn">
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="tab-pane fade"
                  id="signUp"
                  role="tabpanel"
                  aria-labelledby="signUp-tab"
                >
                  {/* <!-- Lumpsum Content Here --> */}
                  <h5 className="form-title d-md-block d-none">Sign up</h5>
                  <form>
                    <div className="form-group ">
                      <label htmlFor="fullName" className="form-label">
                        Full Name (As on PAN)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        placeholder="Enter Your Full Name (As on PAN)"
                      />
                    </div>

                    <div className="form-group ">
                      <label htmlFor="pan" className="form-label">
                        PAN
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pan"
                        placeholder="Enter Your PAN"
                      />
                    </div>

                    <DatePickerWithIcon />

                    <div className="form-group ">
                      <label htmlFor="mobile" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        placeholder="Enter Your Mobile Number"
                      />
                    </div>

                    <div className="form-group ">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter Your Email"
                      />
                    </div>

                    <div className="form-group ">
                      <label htmlFor="Password" className="form-label">
                        Password
                      </label>
                      <div className="input-group position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          id="Password"
                          placeholder="Enter Your Password"
                        />
                        <span
                          className="passwordicon"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className="color"
                          />
                        </span>
                      </div>
                    </div>

                    <div className="form-group ">
                      <label htmlFor="code" className="form-label">
                        Referral Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        placeholder="Enter Your Referral Code"
                      />
                    </div>

                    <div className="form-group d-flex">
                      <input
                        type="checkbox"
                        className="form-check-input mt-0 me-0"
                        id="confirm_box"
                      />
                      <label
                        htmlFor="confirm_box"
                        className="form-label confirm_box"
                      >
                        {" "}
                        I agree with the{" "}
                        <Link
                          to={`/termsconditions`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Terms of services{" "}
                        </Link>{" "}
                        and{" "}
                        <Link
                          to={`/privacy-policy`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                    <div className="mt-md-5 mt-sm-4 mt-3 text-end">
                      <button type="submit" className="form_button next_btn">
                        Create Account
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
