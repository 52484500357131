import React, { useEffect, useRef, useState } from 'react';
import WishlistCards from '../components/WishlistCards';
import featureimage from '../images/orders/logo.svg';
import growthTree from '../images/icons/growth-tree.svg';
import InvestButton from '../components/InvestButton';
import TicketTable from '../components/TicketTable';
import { useDispatch, useSelector } from 'react-redux';
import { getMessage } from '../actions/messageAction';
import Loader from '../loader/Loader-ui';
import {getLocalStorageUserData} from "../utils/userData";

// Functional component for the Home page
const TicketMessage = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(false); 
    const { getMessage: { data }, loading } = useSelector((state) => state.messages);
    const [isLoading , setIsLoading] = useState(false);
    const userData = getLocalStorageUserData();

    useEffect(()=>{
        const fetchData = async()=>{
            const payload = { id: userData?.id };
            try {
                setIsLoading(true);
                await Promise.all([dispatch(getMessage(payload))]);
            } catch (error) {
                console.error("API call failed: ", error);
            }
            finally{
                setIsLoading(false);
            }
        }
        // if (isMounted.current) {
            fetchData();
        //   } else {
        //     isMounted.current = true;
        //   }
    },[dispatch,userData?.id])

    const tickets = data?.ticket || [];
    
    return (
        <div className="container-fluid page_background ">
            {isLoading && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            <div className="page-inner-content mb-3" >
                <div className="page_inner_content_box ">
                    <div className="d-flex gap-3 flex-wrap justify-content-between align-itmes-center mb-4 ">
                        <h5 className="mb-0 main-heading-title">
                        Tickets & Messages
                        </h5>
                        <InvestButton label='Raise Ticket' url='/raise-ticket' color='blue' />
                    </div>
                    <div className="ticket_card_inner page_content_overflow  tickets_Messages">

                        <div className='mb-3'>
                            {/* <TicketTable number='123' message='Lorem Ipsum' status='Open'/> */}
                            <TicketTable tickets={tickets} />
                        </div>
                       
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TicketMessage;
