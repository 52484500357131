import { useState, useEffect, useRef } from "react";
import "../styles/transaction.css";
import Pencil from "../images/icons/Pencil.svg";
import Trash from "../images/icons/Trash.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { faqDelete } from "../actions/adminAction";

const FaqTable = ({ data, currentPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState({}); // State to manage expanded rows

  const handleDeleteClick = (faqId) => {
      dispatch(faqDelete(faqId)); // Dispatch action to delete FAQ
  };

  const handleEditClick = (faqId) => {
    navigate("/admin/editfaq", { state: faqId }); // Redirect to the Edit FAQ page
  };

  const toggleExpandRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="table-container table-responsive">
      <table className="table_transaction">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((faqs, index) => (
              <tr
                key={index}
                className={`${
                  faqs?.faqs?.trxn_status?.toLowerCase() ||
                  faqs?.status?.toLowerCase()
                } transaction_row`}
              >
                <td>{(currentPage - 1) * 10 + index + 1}</td>
                <td className="white-space-normal">{faqs?.question}</td>
                <td className="white-space-normal">
                  {expandedRows[index]
                    ? faqs.answer
                    : faqs.answer.slice(0, 50) +
                      (faqs.answer.length > 50 ? "..." : "")}
                  {faqs.answer.length > 50 && (
                    <button
                      className="see-more-less"
                      onClick={() => toggleExpandRow(index)}
                    >
                      {expandedRows[index] ? "See Less" : "See More"}
                    </button>
                  )}
                </td>
                <td>
                  {" "}
                  <div className="d-flex gap-2">
                    <div
                      className="goals-edit"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Edit"
                    >
                      <img
                        src={Pencil}
                        alt="Edit Icon"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Edit"
                        style={{ cursor: "pointer" }} // Add pointer cursor to indicate interactivity
                        onClick={() => handleEditClick(faqs?.id)}
                      />
                    </div>
                    <div
                      className="goals-delete"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Delete"
                      onClick={() => handleDeleteClick(faqs?.id)}
                      >
                      <img
                        src={Trash}
                        alt=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Delete"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ height: "200px" }}>
              <td colSpan="6" className="no-data-message">
                No FAQ found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FaqTable;
