import React from "react";
import { RotatingLines } from "react-loader-spinner";
import "../styles/common.css";

const Loader = () => {
    return (
        <div className="loader-container">
            <RotatingLines
                height="80"
                width="80"
                strokeColor="#5A4AD5"
                ariaLabel="loading"
            />
        </div>
    );
};

export default Loader;
