import React, { useEffect, useState, useRef, useCallback } from "react";
import logo from "../images/icons/header-logo.svg";
import emailImage from "../images/icons/email.svg";
import phoneImage from "../images/icons/phone.svg";
import locationImage from "../images/icons/location.svg";
import facebook from "../images/icons/facebook-logo.svg";
import twitter from "../images/icons/x-twitter-logo.svg";
import linkdin from "../images/icons/linkdin-logo.svg";
import instagram from "../images/icons/instagram-logo.svg";
import appstore from "../images/appstore.svg";
import playstore from "../images/playstore.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAMCLists,
} from "../actions/exploreFundsActions";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAMCLoading, setIsAMCLoading] = useState(false);
  // Get AMC list for the dropdown menu
  const { amcLists = [] } = useSelector((state) => state.exploreFunds);

  const amcData = useCallback(async () => {
    try {
      setIsAMCLoading(true); // Show loader
      await dispatch(getAMCLists());
    } catch (error) {
      console.error("One or more API calls failed: ", error);
    } finally {
      setIsAMCLoading(false); // Hide loader
    }
  }, [dispatch]);

  useEffect(() => {
    amcData();
  }, [amcData]);

  const handleAMCClick = (amcId) => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  
    // Navigate to the desired page
    navigate("/explore-funds", { state: { amcId: amcId} });
  };


  const amcListData = amcLists?.data || [];

  // console.log(amcLists.data,"amcLists");
  const getCurrentYear = () => new Date().getFullYear();


  return (
    <footer className="footer_section" id="footer_section">
      <div className="container footer_inner_content">
        <div className="top-footer pb-lg-5 pb-4">
          <div className="">
            <a className="navbar-brand d-block" href="/">
              <img src={logo} alt="Logo" />
              FUNDSWALA
            </a>
            <ul className="top_content ps-0">
              <li>
                <a
                  href="/"
                  className="d-flex gap-2 align-items-top top_content_link"
                >
                  <img className="" src={emailImage} alt="" />
                  <span>service@fundswala.com</span>
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="d-flex  gap-2 align-items-top top_content_link"
                >
                  <img className="" src={phoneImage} alt="" />
                  <span>7796777767 / 9632697256</span>
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="d-flex  gap-2 align-items-start top_content_link"
                >
                  <img className="" src={locationImage} alt="" />
                  <span>
                    Regd.office: <br />
                    2503, Tilak Chowk,
                    <br />
                    Barshi. Solapur 413401{" "}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="d-flex  gap-2 align-items-start top_content_link"
                >
                  <img className="" src={locationImage} alt="" />
                  <span>
                    Corporate office: <br />
                    #12, 8th Main Road, <br />
                    TATA Silk Farm,
                    <br />
                    Bangalore 560004
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <span className="footer-heading " href="#">
              Our Company
            </span>
            <ul className="top_content ps-0">
              <li className="d-flex gap-3 align-items-top">
                <a href="/about">About</a>
              </li>
              <li className="d-flex  gap-3 align-items-top">
                <a
                  href="/termsconditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Terms & Conditions
                </a>
              </li>
              <li className="d-flex  gap-3 align-items-start">
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  {" "}
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="">
            <span className="footer-heading " href="#">
              Our Offerings
            </span>
            <ul className="top_content ps-0">
              <li className="d-flex gap-3 align-items-top">
                <Link
                  to="/explore-funds"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Explore Funds
                </Link>
              </li>
              <li className="d-flex  gap-3 align-items-top">
                <Link
                  to="/explore-funds"
                  state={{ search: "large cap" }}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Large Cap Funds
                </Link>
              </li>
              <li className="d-flex  gap-3 align-items-start">
                <Link
                  to="/explore-funds"
                  state={{ search: "mid cap" }}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  {" "}
                  Mid Cap Funds
                </Link>
              </li>
              <li className="d-flex  gap-3 align-items-start">
                <Link
                  to="/explore-funds"
                  state={{ search: "small cap" }}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  {" "}
                  Small Cap Funds
                </Link>
              </li>
              <li className="d-flex  gap-3 align-items-start">
                <Link
                  to="/explore-funds"
                  state={{ search: "elss" }}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  {" "}
                  Tax Saving Funds
                </Link>
              </li>
            </ul>
          </div>
          <div className="">
            <span className="footer-heading " href="#">
              Follow Us On
            </span>
            <ul className="social-icon ps-0 d-flex gap-3 align-items-top">
              <li className="">
                <a
                  href="https://www.facebook.com/fundswala"
                  className="icon-round"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="" />
                </a>
              </li>
              <li className="">
                <a
                  href="https://x.com/fundswala?t=e1V5mMLl3nLhXd28nGG6PA&s=08"
                  className="icon-round"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="" />
                </a>
              </li>
              <li className="">
                <a
                  href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHdlOMGDCnk9QAAAZO_eSGYw2evtXXhTSTku0DejIMCvmoSu8MRUjmZm15S6K4GQv48woby9QV2luPInkFLu4GGzS02qSssd55fzz8Galjcm74h0qOBJS4Ypd_FPgxyS6SU1S4=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Ffundswala-com-7b8552225"
                  className="icon-round"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkdin} alt="" />
                </a>
              </li>
              <li className="">
                <a
                  href="https://www.instagram.com/fundswala/?utm_source=qr"
                  className="icon-round"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <span className="divider_footer"></span>
        <div className="copy-right-section">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="mb-3">
              <span className="copy-right-text">
                {" "}
                © {getCurrentYear()} Fundswala.com. All rights reserved. An
                Initiative by -
                <br className="d-lg-block d-none" />
                VNP Mutual Fund Services Private Limited
                <br className="d-lg-block d-none" /> Site by{" "}
                <a
                  className="sideby-links"
                  href="https://www.codeplateau.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="power bi consulting services"
                >
                  Codeplateau
                </a>{" "}
              </span>
            </div>

            <div className="download_button d-flex gap-3 flex-wrap">
              <a
                href={process.env.REACT_APP_APPLESTORE_URL}
                className="d-block"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appstore} alt="" />
              </a>
              <a
                href={process.env.REACT_APP_PLAYSTORE_URL}
                className="d-block"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={playstore} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <h2 className="bottom-heading">MOST POPULAR ON FUNDSWALA</h2>
          <p className="bottom-text mb-md-5 mb-4">
            <span className="span_highlight ps-0">POPULAR MUTUAL FUNDS:</span>
            QUANT SMALL CAP FUND
            <span>|</span>
            ICICI PRUDENTIAL COMMODITIES FUND
            <span>|</span>
            NIPPON INDIA SMALL CAP FUND
            <span>|</span>
            PARAG PARIKH FLEXI CAP FUND
            <span>|</span>
            GROWW NIFTY TOTAL MARKET INDEX FUND
            <span>|</span>
            SBI SMALL MIDCAP FUND
            <span>|</span>
            TATA DIGITAL INDIA FUND
            <span>|</span>
            AXIS SMALL CAP FUND
            <span>|</span>
            ICICI PRUDENTIAL TECHNOLOGY FUND
            <span>|</span>
            HDFC INDEX FUND SENSEX PLAN
            <span>|</span>
            HDFC SMALL CAP FUND
            <span>|</span>
            AXIS EQUITY FUND
            <span>|</span>
            CANARA ROBECO SMALL CAP FUND
            <span>|</span>
            TATA SMALL CAP FUND
            <span>|</span>
            UTI NIFTY FUND
          </p>

          {/* <p className="bottom-text">
            <span className="span_highlight ps-0">MUTUAL FUNDS COMPANIES:</span>
            SBI
            <span>|</span>
            AXIS
            <span>|</span>
            HDFC
            <span>|</span>
            UTI
            <span>|</span>
            NIPPON INDIA
            <span>|</span>
            ICICI PRUDENTIAL
            <span>|</span>
            TATA
            <span>|</span>
            KOTAK
            <span>|</span>
            DSP
            <span>|</span>
            CANARA ROBECO
            <span>|</span>
            SUNDARAM
            <span>|</span>
            MIRAE ASSET
            <span>|</span>
            IDFC
            <span>|</span>
            FRANKLIN TEMPLETON
            <span>|</span>
            PPFAS
            <span>|</span>
            MOTILAL OSWAL
            <span>|</span>
            INVESCO
            <span>|</span>
            EDELWEISS
            <span>| </span>
            ADITYA BIRLA SUN LIFE
            <span> |</span>
            LIC
            <span>|</span>
            HSBC
            <span>|</span>
            NAVI
            <span>|</span>
            QUANTUM
            <span>|</span>
            UNION
            <span>|</span>
            ITI
            <span>|</span>
            MAHINDRA MANULIFE
            <span>|</span>
            360 ONE
            <span>|</span>
            BOI
            <span>|</span>
            TAURUS
            <span>|</span>
            JM FINANCIAL
            <span>|</span>
            PGIM
            <span>|</span>
            SHRIRAM
            <span>|</span>
            BARODA BNP PARIBAS
            <span>|</span>
            QUANT
            <span>|</span>
            WHITEOAK CAPITAL
            <span>|</span>
            TRUST
            <span>|</span>
            SAMCO
            <span>|</span>
            NJ
          </p> */}
          <p className="bottom-text">
            <span className="span_highlight ps-0">MUTUAL FUNDS COMPANIES:</span>
            {isAMCLoading ? (
              <span>Loading...</span>
            ) : (
              amcListData.map((amc, index) => (
                <span key={amc?.amc_code}>
                  <span
                    onClick={() => handleAMCClick(amc?.amc_code)}
                    style={{ cursor: "pointer" }}
                  >
                    {amc?.long_name}
                  </span>
                  {index < amcListData.length - 1 && <span>|</span>}
                </span>
              ))
            )}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
