import React from "react";
import logo from "../images/icons/header-logo.svg";
import avatar from "../images/sidebar_icon/avatar.svg";
import { Link } from "react-router-dom";
import MoreDropdown from "./MoreDropdown";
import { getLocalStorageUserToken, getLocalStorageUserData,} from "../utils/userData";
const token = getLocalStorageUserToken();
const Header = () => {
  const userData = getLocalStorageUserData();
  return (
    <header className="header_section" id="header_section">
      <div className="">
        <nav className="navbar navbar-expand-lg py-4">
          <div className="container-fluid px-md-4 mx-md-5 px-3">
            {/* Logo  */}
            <Link className="navbar-brand" to={`/`}>
              <img src={logo} alt="Logo" />
              FUNDSWALA
            </Link>
            {/* Toggle button for mobile view  */}
            <button
              className="navbar-toggler shadow-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* Navbar links and button  */}
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarNav"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link className="nav-link" to={`/`}>
                    Home{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/about`}>
                    About{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/downloads`}>
                    Downloads{" "}
                  </Link>
                </li>
                {/* <MoreDropdown /> */}
              </ul>
              {token ? (
                userData?.type === "A" ? (
                  <Link className="btn" to={`/admin/dashboard`}>
                    <img src={avatar} alt="user icon" className="me-2" />
                  </Link>
                ) : userData?.type === "R" ? (
                  <Link className="btn" to={`/rm/dashboard`}>
                    <img src={avatar} alt="user icon" className="me-2" />
                  </Link>
                ) : (
                  <Link className="btn" to={`/dashboard`}>
                    <img src={avatar} alt="user icon" className="me-2" />
                  </Link>
                )
              ) : (
                <Link className="btn btn-login" to={`/login`}>
                  Login / Register{" "}
                </Link>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
