import {
  ADMIN_DASHBOARD_SUMMARY,
  ADMIN_INVESTOR_LIST,
  ADMIN_INVESTOR_LIST_EXPORT,
  ADMIN_INVESTOR_VIEW,
  ADMIN_INVESTMENT_LIST,
  ADMIN_TRANSACTION_LIST,
  CUSTOMER_LIST,
  RM_LIST,
  FAQ_LIST,
  FAQ_DETAIL,
  SUPPORT_LIST,
  SUPPORT_SHOW,
  SUPPORT_DETAIL,
  SUPPORT_CLOSE,
  RM_ASSIGN_LIST,
  RM_DETAIL,
  ADMIN_MAILBACK,
  CUSTOMER_STATUS_LIST,
  RM_BANK_LIST,
  ADMIN_EMAIL_UPDATE,
  ADMIN_MOBILE_UPDATE,
  RM_REFERRAL_CODE,
  USER_FORGOT_PASSWORD,
  RM_SUMMARY_REPORT,
  CUSTOMER_LIST_RM
} from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
import { toast } from "react-toastify";
const token = getLocalStorageUserToken();

export const dashboardSummary = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/dashboard`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({
        type: ADMIN_DASHBOARD_SUMMARY,
        payload: response.data,
      });
      return response?.data;
    } catch (error) {
      dispatch({
        type: ADMIN_DASHBOARD_SUMMARY,
        error: error.message,
      });
      console.error("Error fetching Dashboard Valuation Data:", error.message);
      return error;
    }
  };
};

export const investorList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/investors/list?per_page=${payload?.per_page}&page_num=${payload?.page_num}&search=${payload?.search}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_INVESTOR_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTOR_LIST, error: error.message });
      console.error("Error fetching Investor List:", error.message);
      return error;
    }
  };
};

export const investorListExport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/investors/export?search_value=${payload?.search}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_INVESTOR_LIST_EXPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTOR_LIST_EXPORT, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const investorView = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/investors/view/${payload}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_INVESTOR_VIEW, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTOR_VIEW, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const investmentList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/investments/list?per_page=${payload?.per_page}&page_num=${payload?.page_num}&search=${payload?.search}&type=${payload?.type}&subtype=${payload?.subType}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_INVESTMENT_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_INVESTMENT_LIST, error: error.message });
      console.error("Error fetching Investment List:", error.message);
      return error;
    }
  };
};

export const investmentListExport  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/investments/export?user_id=${payload?.userId}&start_date=${payload?.startDate}&end_date=${payload?.endDate}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_TRANSACTION_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_TRANSACTION_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const transactionList  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/transactions/list?per_page=${payload?.per_page}&page_num=${payload?.page_num}&search=${payload?.search}&status=${payload?.status}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_TRANSACTION_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_TRANSACTION_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const transactionListExport  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/transactions/export?user_id=${payload?.userId}&start_date=${payload?.startDate}&end_date=${payload?.endDate}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: ADMIN_TRANSACTION_LIST, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: ADMIN_TRANSACTION_LIST, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const customerListExport  = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/transactions/getCustomersList?page_name=${payload.page}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type:  CUSTOMER_LIST_RM, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: CUSTOMER_LIST_RM, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const getReferralCode = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/getReferralCode`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: RM_REFERRAL_CODE, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_REFERRAL_CODE, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const genrateReferralCode = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/generateReferralCode`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: RM_REFERRAL_CODE, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_REFERRAL_CODE, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};

export const rMChangePasswordPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}rm/changePassword`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_FORGOT_PASSWORD, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_FORGOT_PASSWORD, error: error.message });
    }
  };
};

export const getSummeryReport = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}rm/summaryReport`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: RM_SUMMARY_REPORT, payload: response.data });
      return response?.data;
    } catch (error) {
      dispatch({ type: RM_SUMMARY_REPORT, error: error.message });
      console.error("Error fetching Transaction List:", error.message);
      return error;
    }
  };
};