import '../styles/HoldingSection.css';
import RecommendedFundPlanDesign4 from './RecommendedFundPlanDesign4';
import ActionPopupButton from './ActionPopupButton';
import SectionSWP from './SectionSWP';

const HoldingSectionSwp = ({ data, isDashboard }) => {
  const currentPageURL = window.location.pathname;
  // Limit the data to 2 items if on the dashboard
  let viewPageUrl = "/explore/fund-detail";
  const limitedData = isDashboard ? data?.current_holdings?.slice(0, 2) : data?.current_holdings;
  return (
    <div>
      {limitedData && limitedData.length > 0 ? (
        limitedData.map((item, index) => (
          <div key={index} className="mb-3">
            <SectionSWP
              bookmark={true}
              plus={true}
              image={item.icon}
              // isin={item?.product.isin}
              amcId={item?.amc_product_id}
              title={item.product_long_name || '-'}
              badge={item?.asset_class}
              badge1={item?.plan_type}
              price1={item.folio_no || '-'}
              price2={item.amount || '-'}
              price3={item.from_date || '-'}
              price4={item.to_date || '-'}
              url={viewPageUrl}
              redirectUrl={currentPageURL}
              // actionButton={<ActionPopupButton filterColor={true} />}
              actionButton={{ filterColor: true, item }}
            />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default HoldingSectionSwp;
    