import React, { useEffect, useRef } from "react";
import SelectDropDown from "../SelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import { InvestorAddFormData } from "../../actions/adminAction";
import { getBankDetail } from "../../actions/usersActions";
export default function StaticFATCADeclarationForm(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(InvestorAddFormData());
  }, [dispatch]);
  const userOnboardGetData =
    useSelector((state) => state.admin.adminAddInvestorData) || {};
  const { formik } = props;
  return (
    <div className="row form_body justify-content-center">
      <div className="col-xl-7 col-lg-10 col-md-10 my-1">
        <form>
          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="annual_income" className="form-label">
                Annual Income <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7">
              <SelectDropDown
                name="annual_income"
                id=""
                value={formik.values.annual_income}
                changeFunction={(value) =>
                  formik?.setFieldValue("annual_income", value)
                }
                options={userOnboardGetData?.data?.annual_income?.map(
                  (annual_incomes) => ({
                    value: annual_incomes.app_income_code,
                    label: annual_incomes.app_income_desc,
                  })
                )}
              />
              {formik.errors.annual_income ? (
                <div className="text-danger">{formik.errors.annual_income}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="addressType" className="form-label">
                Address Type <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7">
              <SelectDropDown
                name="address_type"
                id=""
                value={formik.values.address_type}
                changeFunction={(value) =>
                  formik?.setFieldValue("address_type", value)
                }
                options={userOnboardGetData?.data?.addresstypes?.map(
                  (addresstype) => ({
                    value: addresstype.code,
                    label: addresstype.description,
                  })
                )}
              />
              {formik.errors.address_type ? (
                <div className="text-danger">{formik.errors.address_type}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="source_of_wealth" className="form-label">
                Source of Income <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7">
              <SelectDropDown
                name="source_of_wealth"
                id=""
                value={formik.values.source_of_wealth}
                changeFunction={(value) =>
                  formik?.setFieldValue("source_of_wealth", value)
                }
                options={userOnboardGetData?.data?.source_of_wealth?.map(
                  (source_of_wealths) => ({
                    value: source_of_wealths.code,
                    label: source_of_wealths.source_of_wealth,
                  })
                )}
              />
              {formik.errors.source_of_wealth ? (
                <div className="text-danger">
                  {formik.errors.source_of_wealth}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="confirmAccountNumber" className="form-label">
                Birth Place <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="birth_place"
                placeholder="Enter Birth Place"
                {...formik.getFieldProps("birth_place")}
              />
              {formik.errors.birth_place ? (
                <div className="text-danger">{formik.errors.birth_place}</div>
              ) : null}
            </div>
          </div>
          {/* commented for now for checking profile will active without this field */}
          {/* <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="idtype" className="form-label">
                ID Type <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7">
              <SelectDropDown
                name="idtype"
                disabled={formik.values.idtype == "C" ? true : false}
                id=""
                value={formik.values.idtype}
                changeFunction={(value) =>
                  formik?.setFieldValue("idtype", value)
                }
                options={userOnboardGetData?.data?.idtypes?.map((idtype) => ({
                  value: idtype.code,
                  label: idtype.description,
                }))}
              />
              {formik.touched.idtype && formik.errors.idtype ? (
                <div className="text-danger">{formik.errors.idtype}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-lg-5 col-md-6 col-sm-5">
              <label htmlFor="id_no" className="form-label">
                ID Number <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="id_no"
                placeholder="Enter Id Number"
                disabled={formik.values.idtype == "C" ? true : false}
                {...formik.getFieldProps("id_no")}
              />
              {formik.touched.id_no && formik.errors.id_no ? (
                <div className="text-danger">{formik.errors.id_no}</div>
              ) : null}
            </div>
          </div> */}
        </form>
      </div>
    </div>
  );
}
