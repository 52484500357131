import React, { useRef, useState } from "react";
import calendarImage from "../images/calendar_month.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";

const DatePickerWithIcon = ({
  value,
  onChange,
  placeholder = "DD/MM/YYYY",
}) => {
  const dateInputRef = useRef(null);
  const [hasValue, setHasValue] = useState(!!value);

  const handleIconClick = () => {
    if (dateInputRef.current) {
      if (dateInputRef.current.showPicker) {
        dateInputRef.current.showPicker();
      } else {
        dateInputRef.current.focus();
      }
    }
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };
  const parseDate = (date) => {
    if (!date) return "";
    const [day, month, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (event) => {
    const dateValue = event.target.value;
    setHasValue(!!dateValue);

    if (onChange) {
      onChange(formatDate(dateValue)); // Pass formatted date to the parent
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="dob" className="form-label">
        Date of Birth
      </label>
      <div className="input-group position-relative custom-date-wrapper">
        <input
          type="date"
          className={`form-control ${hasValue ? "has-value" : ""}`}
          id="dob"
          ref={dateInputRef}
          value={value ? parseDate(value) : ""} // Convert to yyyy-mm-dd for input
          onChange={handleInputChange}
        />
        {!hasValue && <span className="custom-placeholder">{placeholder}</span>}
        <span
          className="passwordicon calendericon"
          onClick={handleIconClick}
          style={{ cursor: "pointer" }}
        >
          <img src={calendarImage} alt="calendar icon" />
        </span>
      </div>

      <ErrorMessage name="dob" component="div" className="text-danger mt-1" />
    </div>
  );
};

export default DatePickerWithIcon;
