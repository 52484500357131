import React from 'react';
import '../styles/fund.css'; // Import styles
import NAVMovementGraph from '../components/NAVMovementGraph';
import SIPSliderComponent from '../components/SIPSliderComponent';
import FundDetailHeader from '../components/FundDetailHeader';
import FundDetailInvestmentBox from '../components/FundDetailInvestmentBox';
import RecommendedFundCard from '../components/RecommendedFundPlan2';
import LumsumSliderComponent from '../components/LumsumSliderComponent';

// Functional component for the funddetails page
const FundDetailAfterInvesting = () => {
  return (
    <div className="container-fluid page_background ">
    <div className="page-inner-content fund_section mb-3">
      <div className="">
      <div className="card overflow-hiddden border-0">
                   <FundDetailHeader btnLabel='Invest More'/>
                    <div className="card-body page_inner_content_box">
                        <FundDetailInvestmentBox/>
                        <div className="row  ">
                            <div className="col-xl-7 col-lg-6 mb-lg-0 mb-4">
                                <div className="card h-100 overflow-hiddden border-0">
                                <div className="card-body h-100 calculator-container"> 
                                    <NAVMovementGraph />
                                </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="card border-0">
                                        <div className="calculator-container">
                                            <h5 className="mb-3">Return Calculator</h5>
                                            
                                            <ul className="nav nav-tabs gap-2" id="calculatorTabs" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="sip-tab" data-bs-toggle="tab" data-bs-target="#sip" type="button" role="tab" aria-controls="sip" aria-selected="true">SIP</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="lumpsum-tab" data-bs-toggle="tab" data-bs-target="#lumpsum" type="button" role="tab" aria-controls="lumpsum" aria-selected="false">Lumpsum</button>
                                                </li>
                                            </ul>
                                            
                                            <div className="tab-content mt-md-4 mt-4">
                                                <div className="tab-pane fade show active" id="sip" role="tabpanel" aria-labelledby="sip-tab">
                                                    <SIPSliderComponent />
                                                </div>
                                                <div className="tab-pane fade" id="lumpsum" role="tabpanel" aria-labelledby="lumpsum-tab">
                                                    <LumsumSliderComponent />
                                                </div>
                                            </div>
                                        </div>
                                </div>

                            </div>
                        </div>

                        <div className="goal_planner_content mt-4">
                            <h5 className="mb-4">Similar Funds</h5>
                            <div className="fund-card-content ">
                                <div className="row ">
                                    <div className="col-xxl-4 col-lg-6 mb-xxl-0 mb-md-4 mb-3">
                                        <RecommendedFundCard bookmark={false} plus={false} title='SBI PSU Direct Plan Growth' badge='Growth' price1='₹19.68' price2='₹100.00' price3='₹100.00' url="/fund-detail-after-investing" />
                                    </div>
                                    <div className="col-xxl-4 col-lg-6 mb-xxl-0 mb-md-4 mb-3">
                                        <RecommendedFundCard bookmark={true} plus={true} title='SBI PSU Direct Plan Growth' badge='Growth' price1='₹19.68' price2='₹100.00' price3='₹100.00' url="/fund-detail-after-investing" />
                                    </div>
                                    <div className="col-xxl-4 col-lg-6  mb-xxl-0 mb-md-4 mb-3">
                                        <RecommendedFundCard bookmark={true} plus={true} title='SBI PSU Direct Plan Growth' badge='Growth' price1='₹19.68' price2='₹100.00' price3='₹100.00' url="/fund-detail-after-investing" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    </div>
  </div>
  )
}

export default FundDetailAfterInvesting;