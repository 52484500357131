import ButtonRound from './ButtonRound';

const CardBox = (props) => {
    
  return (
      <div className="cards_box" key={`card_box`+props.indexKey}>
        <div className="flip-box">
            <div className="flip-box-inner">
                {/* Front Side  */}
                <div className={`flip-box-front card card-`+props.color+``}>
                    <div className="card-body text-center d-flex align-items-center">
                        <h3 className="card-title d-flex flex-column gap-md-3 gap-2">{props.title} <span>{props.subtitle}</span></h3>
                    </div>
                </div>
                {/* Back Side  */}
                <div className={`flip-box-back card card-`+props.color}>
                    <div className="card-body text-center d-flex align-items-center flex-column justify-content-center">
                        <h3 className="card-title mb-3">{props.title} <span>{props.subtitle}</span></h3>
                        <p className="card-details">{props.content}</p>
                        <ButtonRound label={props?.buttonName} url={props?.buttonUrl} color='white' />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default CardBox;
