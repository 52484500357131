import React, { useState } from "react";
import {
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    CartesianGrid,
    ComposedChart,
    ResponsiveContainer,
} from "recharts";
import { IoMdArrowDropdown } from "react-icons/io";


// Define datasets for each option
const datasets = {
    "Data of last year": [
        { month: "Jan'24", lumpsum: 0, sip: 0 },
        { month: "Feb'24", lumpsum: 0, sip: 0 },
        { month: "Mar'24", lumpsum: 0, sip: 0 },
        { month: "Apr'24", lumpsum: 0, sip: 0 },
        { month: "May'24", lumpsum: 0, sip: 0 },
        { month: "Jun'24", lumpsum: 0, sip: 0 },
        { month: "Jul'24", lumpsum: 0, sip: 0 },
        { month: "Aug'24", lumpsum: 0, sip: 0 },
        { month: "Sep'24", lumpsum: 0, sip: 0 },
        { month: "Oct'24", lumpsum: 0, sip: 0 },
        { month: "Nov'24", lumpsum: 0, sip: 0 },
        { month: "Dec'24", lumpsum: 2500000, sip: 5000000 },
    ],
    "Data of last 6 months": [
        { month: "Jul'24", lumpsum: 0, sip: 0 },
        { month: "Aug'24", lumpsum: 0, sip: 0 },
        { month: "Sep'24", lumpsum: 0, sip: 0 },
        { month: "Oct'24", lumpsum: 0, sip: 0 },
        { month: "Nov'24", lumpsum: 0, sip: 0 },
        { month: "Dec'24", lumpsum: 2500000, sip: 5000000 },
    ],
    "Data of last 3 months": [
        { month: "Oct'24", lumpsum: 0, sip: 0 },
        { month: "Nov'24", lumpsum: 0, sip: 0 },
        { month: "Dec'24", lumpsum: 2500000, sip: 5000000 },
    ],
};

const ReportChart = () => {
    const [selectedOption, setSelectedOption] = useState("Data of last year"); // Default selection

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value); // Update the selected option
    };

    return (
        <div>
            {/* Dropdown */}
            <div className="dropdown-row" style={{ marginBottom: "20px" }}>
                <div className="dropdown-column">
                    <div className="dropdown-label-custom">Show in graph:</div>
                    <div className="custom-dropdown-wrapper">
                        <select
                            className="custom-dropdown-select"
                            value={selectedOption}
                            onChange={handleDropdownChange}
                        >
                            <option value="Data of last year">Data of last year</option>
                            <option value="Data of last 6 months">Data of last 6 months</option>
                            <option value="Data of last 3 months">Data of last 3 months</option>
                        </select>
                        <div className="dropdown-arrow"><IoMdArrowDropdown /></div>
                    </div>
                </div>
            </div>

            {/* Chart */}
            <div style={{ width: "100%", height: "500px" }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        style={{ padding: "20px" }}
                        data={datasets[selectedOption]} // Dynamically set data based on selection
                        margin={{ top: 20, right: 50, bottom: 20, left: 50 }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="month" />
                        <YAxis
                            yAxisId="left"
                            orientation="left"
                            tick={{ fill: "#FF4081" }}
                            tickFormatter={(value) => value.toFixed(2)}
                            label={{
                                value: "Lumpsum Investments",
                                angle: -90,
                                position: "insideLeft",
                                dx: -65,
                                fill: "#FF4081",
                            }}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tick={{ fill: "#7C4DFF" }}
                            tickFormatter={(value) => value.toFixed(2)}
                            label={{
                                value: "SIP Investments",
                                angle: 90,
                                position: "insideRight",
                                dx: 65,
                                fill: "#7C4DFF",
                            }}
                        />
                        <Tooltip />
                        <Legend />
                        <Bar
                            yAxisId="left"
                            dataKey="lumpsum"
                            fill="#FF4081"
                            name="Lumpsum"
                            barSize={10}
                        />
                        <Bar
                            yAxisId="right"
                            dataKey="sip"
                            fill="#7C4DFF"
                            name="SIP"
                            barSize={10}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ReportChart;
