import React from 'react';

const HeroSection = (props) => {
    
  return (
    <section className="hero_section d-flex justify-content-center align-items-center" id="hero_section" style={{ backgroundImage: `url(`+props.bgImage+`)` }}>
        <div className="container-fluid ">
            <div className="hero_inner_section text-center">
                <div>
                  <h1><span>{props.title}</span> {props.subtitle}</h1>
                </div>
            </div>
        </div>
    </section>
  );
};

export default HeroSection;
