import axios from "axios";
import {
  ADD_UPDATE_GOALS,
  AMOUNT_GOAL_CALCULATION,
  DELETE_GOAL,
  GET_GOAL_LIST,
  GET_GOAL_TYPE,
  GOAL_DATA_BY_ID,
  RECOMMENDED_FUNDS,
} from "./types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLocalStorageUserToken } from "../utils/userData";
import axiosInstance from "../api/axios";
const token = getLocalStorageUserToken();

export const getGoalsList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}goals/fetch`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: GET_GOAL_LIST, payload: response?.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      console.error("Error fetching goals data list", error?.message);
    }
  };
};
export const getGoalsType = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}goal/fetch`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: GET_GOAL_TYPE, payload: response?.data }); // Ensure `response.data` has the correct format
    } catch (error) {
      console.error("Error fetching goals types", error?.message);
    }
  };
};
export const addOrUpdateGoal = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}goal/save`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: ADD_UPDATE_GOALS, payload: response?.data });
      dispatch({ type: GET_GOAL_LIST, payload });
    } catch (error) {
      console.error("Error fetching Add and update Goals", error?.message);
    }
  };
};
export const goalAmountCalculation = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}calc/goal`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: AMOUNT_GOAL_CALCULATION, payload: response?.data });
    } catch (error) {
      console.error("Error fetching Goals amount calculation", error?.message);
    }
  };
};
export const deleteGoal = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}goal/delete-goal`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: DELETE_GOAL, payload: response?.data });
      // toast.success("Goal deleted successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error fetching Goals delete", error?.message);
      toast.error("Failed to delete goal.");
    }
  };
};
export const goalDataByID = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}goal/fetch-goal-by-id`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: GOAL_DATA_BY_ID, payload: response?.data });
      return response?.data;
    } catch (error) {
      console.error("Error fetching Goals delete", error?.message);
    }
  };
};
export const recommendedFunds = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}recommend-funds`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      dispatch({ type: RECOMMENDED_FUNDS, payload: response?.data });
    } catch (error) {
      console.error(
        "Error fetching Goals Recommended funds List",
        error?.message
      );
    }
  };
};
