import React, { useState } from "react";
import help_icon from "../../images/sidebar_icon/help_icon.svg";
import InvestmentHeaderBox1 from "../../components/InvestmentHeaderBox1";
import "../../styles/profile.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import pen_inner from "../../images/my_profile/pen_inner.svg";
import { useNavigate, useLocation } from "react-router-dom";

// Functional component for the Home page
const FaqAdd = () => {
  const location = useLocation();
  const mandateData = location.state;
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title"> Nach Details </h5>
          <div className="card redeam_bottom_card mapping-details-box investor-status-box">
            <div className="table-responsive bankDetailTable">
              <table className="table_transaction">
                <thead>
                  <tr>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Account Type</th>
                    <th scope="col">Account No.</th>
                    <th scope="col">Nach Status</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                    <th scope="col">Nach Limit</th>
                  </tr>
                </thead>
                <tbody>
                  {mandateData.length > 0 ? (
                    mandateData.map((mandate, index) => (
                      <tr>
                        <td>{mandate?.bank_name || "-"}</td>
                        <td>{mandate.ac_type || "-"}</td>
                        <td>{mandate?.account_no || "-"}</td>
                        <td>{mandate?.ach_mandate_status || "-"}</td>
                        <td>
                          {mandate?.from_date
                            ? new Date(mandate?.from_date).toLocaleDateString(
                                "en-GB"
                              )
                            : "-"}
                        </td>
                        <td>
                          {mandate?.to_date
                            ? new Date(mandate?.to_date).toLocaleDateString(
                                "en-GB"
                              )
                            : "-"}
                        </td>
                        <td>{mandate?.amount || "-"}</td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr style={{ height: "200px" }}>
                        <td colSpan="7" className="no-data-message">
                          No Mandate Account in your list.
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAdd;
