import { GET_CARD, NACH_VERIFY_OTP, PROCEED_CARD } from "../actions/types";

const initialState = {
  addToCardData: {},
  proceedCarts: "",
  loading: false,
  nachVerifyOtps: "",
};

const cardReducers = (state = initialState, action) => {
  switch (action?.type) {
    case GET_CARD:
      return { ...state, loading: false, addToCardData: action?.payload };
    case PROCEED_CARD:
      return { ...state, loading: false, proceedCarts: action?.payload };
    case NACH_VERIFY_OTP:
      return { ...state, loading: false, nachVerifyOtps: action?.payload };

    default:
      return state;
  }
};

export default cardReducers;
