import { ADD_UPDATE_GOALS, AMOUNT_GOAL_CALCULATION, DELETE_GOAL, GET_GOAL_LIST, GET_GOAL_TYPE, GOAL_DATA_BY_ID, RECOMMENDED_FUNDS } from "../actions/types";


const initialState = {
    goalList : {},
    goalType :{},
    addOrUpdateGoals:{},
    goalsAmountCalucalation :{},
    deleteGoals:{},
    loading: false,
    goalDetailsById:{},
    recommendedFunds:{}
};

const goalReducers = (state = initialState, action) => {
    switch (action?.type) {
        case GET_GOAL_LIST:
            return { ...state, loading: false, goalList: action?.payload };
        case GET_GOAL_TYPE:
            return { ...state, loading: false, goalType: action?.payload };
        case ADD_UPDATE_GOALS:
            return { ...state, loading: false, addOrUpdateGoals: action?.payload };
        case AMOUNT_GOAL_CALCULATION:
            return { ...state, loading: false, goalsAmountCalucalation: action?.payload };
        case DELETE_GOAL:
            return { ...state, loading: false, deleteGoals: action?.payload };
        case GOAL_DATA_BY_ID:
            return { ...state, loading: false, goalDetailsById: action?.payload };
        case RECOMMENDED_FUNDS:
            return { ...state, loading: false, recommendedFunds: action?.payload };
        default:
            return state;
    }
};

export default goalReducers;