// import React, { useEffect, useState } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
// import { useDispatch, useSelector } from "react-redux";
// import { getNAVChart } from "../actions/exploreFundsActions";
// const NAVMovementGraph = (props) => {
//   const funddetails = props.data;
//   const isin = funddetails?.isin;
//   // State to manage the selected time range
//   const [timeRange, setTimeRange] = useState(1);

//   const dispatch = useDispatch(); // Initialize dispatch
//   const { navChart = [] } = useSelector((state) => state.exploreFunds);
//   console.log(navChart, "state<<<");

//   useEffect(() => {
//     if (isin) {
//       const payload = { isin: isin, range: timeRange };
//       dispatch(getNAVChart(payload));
//     }
//   }, [dispatch, isin, timeRange]);

//   // Sample data for demonstration; replace with your actual data
//   const data = [
//     { date: "2001-09-01", nav: 1.5 },
//     { date: "2024-09-02", nav: 5.8 },
//     { date: "2024-09-03", nav: 20.0 },
//     { date: "2024-09-04", nav: 10.7 },
//     { date: "2024-09-05", nav: 20.3 },
//     { date: "2024-09-05", nav: 2.3 },
//     { date: "2024-09-05", nav: 20.3 },
//     { date: "2024-09-05", nav: 3.3 },
//     { date: "2022-09-05", nav: 3.3 },
//     { date: "2024-09-05", nav: 4.3 },
//     { date: "2024-09-05", nav: 20.3 },
//     { date: "2021-09-05", nav: 20.3 },
//     { date: "2024-09-05", nav: 1.3 },
//     { date: "2015-09-05", nav: 2.3 },
//     // Add more points as needed...
//   ];

//   // Function to filter data based on the selected time range (dummy implementation)
//   const filterData = () => {
//     // Implement your filtering logic based on `timeRange`
//     return data; // For now, returning the full dataset
//   };

//   return (
//     <>
//       <h5 className="mb-3 left-section-title text-center">NAV Movement</h5>
//       <ResponsiveContainer width="100%" height={237}>
//         <LineChart data={filterData()}>
//           <CartesianGrid strokeDasharray="3 3" />
//           {/* <XAxis dataKey="date" /> */}
//           {/* <YAxis /> */}
//           <Tooltip />
//           <Line type="monotone" dataKey="nav" stroke="#007bff" />
//         </LineChart>
//       </ResponsiveContainer>

//       <div
//         className="graph_month_wrapper"
//         style={{
//           marginTop: "22px",
//           display: "flex",
//           flexWrap: "wrap",
//           justifyContent: "center",
//         }}
//       >
//         {["1M", "6M", "1Y", "3Y"].map((range) => (
//           <a
//             className="graph_month_list"
//             key={range}
//             onClick={() => setTimeRange(range)}
//             style={{
//               backgroundColor: range === timeRange ? "#F5F5F5" : "#fff",
//               color: range === timeRange ? "#565A71" : "#000",
//             }}
//           >
//             {range}
//           </a>
//         ))}
//       </div>

//       <p style={{ marginTop: "16px", textAlign: "center" }}>
//         NAV as on {funddetails?.navDate}:
//         <span style={{ fontWeight: 600 }}> {funddetails?.price1} </span>
//       </p>
//     </>
//   );
// };

// export default NAVMovementGraph;

import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getNAVChart } from "../actions/exploreFundsActions";

const NAVMovementGraph = (props) => {
  const funddetails = props?.data || {};
  const isin = funddetails?.isin
    ? funddetails?.isin
    : funddetails?.product?.isin;

  // State to manage the selected time range (default to 1 month)
  const [timeRange, setTimeRange] = useState(1);
  let nav;
  let percentageReturn;

  const dispatch = useDispatch();
  const { navChart = [] } = useSelector((state) => state.exploreFunds);

  useEffect(() => {
    if (isin) {
      const payload = { isin: isin, range: timeRange }; // Passing numeric time range
      dispatch(getNAVChart(payload));
    }
  }, [dispatch, isin, timeRange]);

  const filterData = () => {
    if (!Array.isArray(navChart)) {
      console.error("navChart is not an array:", navChart);
      return [];
    }

    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - timeRange);

    return navChart
      .filter((item) => {
        const navDate = new Date(item.nav_date);
        return navDate >= startDate && navDate <= endDate;
      })
      .map((item) => ({
        date: item.nav_date,
        NAV: item.nav,
      }));
  };

  //   function calculatePercentageReturn(navData) {
  //     // Calculate the percentage return based on the NAV data
  //     var firstNav = navData[0]; // NAV at the beginning of the selected time range
  //     var lastNav = navData[navData.length - 1]; // NAV at the end of the selected time range

  //     var percentageReturn = ((lastNav - firstNav) / firstNav) * 100;
  //     return percentageReturn;
  // }
  function calculatePercentageReturn(navData) {
    if (!navData || navData.length < 2) {
      return "N/A";
    }

    var firstNav = navData[0];
    var lastNav = navData[navData.length - 1];
    if (
      typeof firstNav !== "number" ||
      typeof lastNav !== "number" ||
      firstNav === 0
    ) {
      return "N/A";
    }

    var percentageReturn = ((lastNav - firstNav) / firstNav) * 100;
    return isNaN(percentageReturn) ? "N/A" : percentageReturn.toFixed(2);
  }

  nav = navChart.map((item) => item.nav);
  percentageReturn = calculatePercentageReturn(nav);

  function calculateOneDayReturn(navData) {
    if (navData.length < 2) {
      return null;
    }
    var lastNav = navData[navData.length - 1];
    var previousNav = navData[navData.length - 2];
    var oneDayReturn = ((lastNav - previousNav) / previousNav) * 100;
    return oneDayReturn;
  }

  var oneDayReturn = calculateOneDayReturn(nav);

  return (
    <>
      {/* <h5 className="mb-3 left-section-title text-center">NAV Movement</h5> */}

      {/* <div className="d-flex align-items-center justify-content-start gap-2 mb-3"> */}
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-start gap-2 mb-3 custom-margin-left">
        <h5 className="nav-section-title text-base font-medium">
          NAV Movement:
        </h5>
        {/* <span className="nav-graph-title">{funddetails?.threeYearReturn || '32.18%'}</span> */}
        <span className="nav-graph-title">{percentageReturn}%</span>
        <span className="nav-graph-yeartitle">
          {timeRange === 1
            ? "1M"
            : timeRange === 6
            ? "6M"
            : timeRange === 12
            ? "1Y"
            : "3Y"}{" "}
          annualised
        </span>
        <span className="nav-line-ttitle">|</span>
        <span className="nav-graph-percentttitle">
          {oneDayReturn?.toFixed(2) || "-0.60%"}
        </span>
        <span className="nav-graph-yeartitle">1D</span>
      </div>

      <div
        style={{
          marginTop: "20px",
          height: "237px",
          width: "100%",
          textAlign: "center",
        }}
      >
        {filterData() && filterData().length > 0 ? (
          <ResponsiveContainer width="100%" height={237}>
            <LineChart data={filterData()}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(tick) => tick.slice(0, 10)}
              />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="NAV"
                stroke="#007bff"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p
            className="d-flex"
            style={{
              justifyContent: "center",
              height: "237px",
              alignItems: "center",
            }}
          >
            No data available
          </p>
        )}
      </div>

      <div
        className="graph_month_wrapper"
        style={{
          marginTop: "22px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {[1, 6, 12, 36].map((range) => (
          <a
            className="graph_month_list"
            key={range}
            onClick={() => setTimeRange(range)} // Set numeric time range
            style={{
              backgroundColor: range === timeRange ? "#3583FF" : "#fff",
              color: range === timeRange ? "#F5F5F5" : "#000",
            }}
          >
            {range === 1
              ? "1M"
              : range === 6
              ? "6M"
              : range === 12
              ? "1Y"
              : "3Y"}
          </a>
        ))}
      </div>
      <p style={{ marginTop: "16px" }}>
        NAV as on{" "}
        {funddetails?.nav?.date
          ? funddetails?.nav?.date
          : funddetails?.product?.nav?.date}
        :{" "}
        <span style={{ fontWeight: 600 }}>
          {funddetails?.nav?.nav
            ? funddetails?.nav?.nav
            : funddetails?.product?.nav?.nav}
        </span>
        &nbsp;&nbsp;|&nbsp;&nbsp; Min. SIP Amount:{" "}
        <span style={{ fontWeight: 600 }}>
        {`₹${
            funddetails.productlimits?.find(
              (limit) => limit.sub_trxn_type === "S"
            )?.minimum_amount || "NA"
          }`}          
          {/* {funddetails?.price2 !== "₹0.00" ? funddetails?.price2 : "NA"} */}
        </span>
        &nbsp;&nbsp;|&nbsp;&nbsp; Min. Lumpsum Amount:{" "}
        <span style={{ fontWeight: 600 }}>
        {`₹${
            funddetails.productlimits?.find(
              (limit) => limit.sub_trxn_type === "N"
            )?.minimum_amount || "NA" // added this condition to show SIP and lumpsum price difference
          }`}
          {/* {funddetails?.price3 !== "₹0.00" ? funddetails?.price3 : "NA"} */}
        </span>
      </p>
    </>
  );
};

export default NAVMovementGraph;
