import React, { useEffect, useState } from "react";
import "../../../src/styles/adminviewticket.css";
import { useDispatch, useSelector } from "react-redux";
import { supportShow } from "../../actions/adminAction";
import { useNavigate, useLocation } from "react-router-dom";

const AdminViewTicket = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the navigate function
  const ticketId = location.state?.ticketId;
  const messageId = location.state?.messageId;
  useEffect(() => {
    if (messageId) {
      dispatch(supportShow(messageId));
    }
  }, [dispatch, messageId]);

  const { supportShowData = {} } = useSelector((state) => state?.admin);
  const data = supportShowData?.data || {};

  const handleCancel = () => {
    navigate(-1); // Go to the previous page
  };
  const handleReplyClick = () => {
    navigate("/admin/support-reply", { state: {ticketId: ticketId, messageId: messageId} }); // Navigate to the reply page
  };
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box ">
          <div className="d-flex gap-3 flex-wrap justify-content-between align-itmes-center mb-4 ">
            <h5 className="mb-0 main-heading-title">View Ticket & Message</h5>
          </div>
          <div
            className="card redeam_bottom_card"
            style={{
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              padding: "0px",
            }}
          >
            <div className="ticket_card_inner   tickets_Messages">
              <div className="row form_body justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-10 my-2">
                  <div className="row align-items-center justify-content-center mb-2 mt-2">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="otp" className="form-label">
                        Ticket ID:
                      </label>
                    </div>
                    <div className="col-6  col-sm-6">{data?.ticket_id}</div>
                  </div>
                  <div className="row align-items-center justify-content-center mb-2 mt-4">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="otp" className="form-label">
                        Customer:
                      </label>
                    </div>
                    <div className="col-6 col-sm-6">{data?.sender}</div>
                  </div>
                  <div className="row align-items-center justify-content-center mb-2 mt-4">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="otp" className="form-label">
                        Type:
                      </label>
                    </div>
                    <div className="col-6 col-sm-6">{data?.type}</div>
                  </div>
                  <div className="row align-items-center justify-content-center mb-2 mt-4">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="otp" className="form-label">
                        Subject:
                      </label>
                    </div>
                    <div className="col-6 col-sm-6">{data?.subject}</div>
                  </div>
                  <div className="row align-items-center justify-content-center mb-2 mt-4">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="otp" className="form-label">
                        Message:
                      </label>
                    </div>
                    <div className="col-6 col-sm-6">{data?.message_text}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer form_card form_card_border">
            <div className="d-flex justify-content-between gap-2">
              <button
                type="button"
                className="form_button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="form_button next_btn"
                onClick={handleReplyClick}
              >
                Reply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminViewTicket;
