import React from "react";
import "../styles/mysip.css";
import SipCart from "../components/SipCarts";
import growthTree from "../images/icons/growth-tree.svg";
import featureimage from "../images/orders/logo.svg";
import bankimage from "../images/bankimage.svg";

const BuySipModel = () => {
  return (
    <div className="container-fluid page_background ">
      <div className=" mb-3">
        <div className="">
          <div className="modal_card">
            {/* Trigger Button */}
            {/* <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#paymentModal"
            >
              Select Payment modal
            </button> */}

            {/* Modal */}
            <div
              className="modal fade modal_card_inner"
              id="paymentModal"
              tabIndex="-1"
              aria-labelledby="paymentModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered p-3">
                <div className="modal-content">
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="paymentModalLabel">
                      Select a Payment Method
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  {/* Modal Body */}
                  <div className="modal-body p-0">
                    {/* eNACH Section */}
                    <div className="modal_body">
                      <div className="form-check eNach_middle_part">
                        <input
                          className="form-input sip_input"
                          type="radio"
                          name="eNACH"
                          id="eNACH"
                        />
                        <label className="form-label mb-0" htmlFor="eNACH">
                          eNACH
                        </label>
                      </div>
                      <p className="form-label text-danger mt-2">
                        {/* Cart has SIPs, therefore, payment can be done through
                        eNACH only. */}
                      </p>
                      <div className="mt-3 table-responsive">
                        <table
                          className="table sip_modaltable mb-0"
                          style={{
                            borderCollapse: "separate",
                            borderSpacing: "0px 15px",
                          }}
                        >
                          <tr>
                            <th></th>
                            <th className="fw-semibold">Bank</th>
                            <th className="fw-semibold">Account Number</th>
                          </tr>
                          <tr>
                            <td>
                              <input
                                className="form-input sip_input"
                                type="radio"
                                name="paymentMethod"
                                id="payment"
                              />
                            </td>
                            <td>
                              <img src={bankimage} className="bank_image" />
                              ICICI Bank Ltd
                            </td>
                            <td>111111111111</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    {/* OTP Section */}
                    <div className="mt-xl-4 modal_body text-center">
                      <label className="form_label">
                        Verify through OTP *
                        <a href="#" className="ms-md-4 ms-3 ">
                          Send OTP
                        </a>
                      </label>
                      <div className="d-flex justify-content-center mt-md-4  mt-2">
                        <input
                          type="text"
                          maxLength="1"
                          className="otp-input text-center mx-1"
                        />
                        <input
                          type="text"
                          maxLength="1"
                          className="otp-input  text-center mx-1"
                        />
                        <input
                          type="text"
                          maxLength="1"
                          className="otp-input text-center mx-1"
                        />
                        <input
                          type="text"
                          maxLength="1"
                          className=" otp-input text-center mx-1"
                        />
                        <input
                          type="text"
                          maxLength="1"
                          className=" otp-input text-center mx-1"
                        />
                        <input
                          type="text"
                          maxLength="1"
                          className=" otp-input text-center mx-1"
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        <button className="verify_button">Verify</button>
                      </div>
                    </div>
                  </div>

                  {/* Modal Footer */}
                  <div className="modal-footer form_card ">
                    <div className="d-flex justify-content-between gap-3">
                      <button type="submit" className="form_button ">
                        Close
                      </button>
                      <button type="submit" className="form_button next_btn">
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_card">
            {/* Trigger Button */}
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#otpVerifyModal"
            >
              OTP verified Modal
            </button>

            {/* Modal */}
            <div
              className="modal fade modal_card_inner"
              id="otpVerifyModal"
              tabIndex="-1"
              aria-labelledby="otpVerifyModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered p-3">
                <div className="modal-content">
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h5 className="modal-title" id="otpVerifyModalLabel">
                      Select a Payment Method
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  {/* Modal Body */}
                  <div className="modal-body p-0">
                    {/* eNACH Section */}
                    <div className="modal_body">
                      <div className="form-check eNach_middle_part">
                        <input
                          className="form-input sip_input"
                          type="radio"
                          name="eNACH"
                          id="eNACH"
                        />
                        <label className="form-label mb-0" htmlFor="eNACH">
                          eNACH
                        </label>
                      </div>
                      <p className="form-label text-danger mt-2">
                        {/* Cart has SIPs, therefore, payment can be done through eNACH
                                    only. */}
                      </p>
                      <div className="mt-3 table-responsive">
                        <table
                          className="table sip_modaltable mb-0"
                          style={{
                            borderCollapse: "separate",
                            borderSpacing: "0px 15px",
                          }}
                        >
                          <tr>
                            <th></th>
                            <th className="fw-semibold">Bank</th>
                            <th className="fw-semibold">Account Number</th>
                          </tr>
                          <tr>
                            <td>
                              <input
                                className="form-input sip_input"
                                type="radio"
                                name="paymentMethod"
                                id="payment"
                                checked
                              />
                            </td>
                            <td>
                              <img src={bankimage} className="bank_image" />
                              ICICI Bank Ltd
                            </td>
                            <td>111111111111</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    {/* OTP Section */}
                    <div className="modal_body text-center">
                      <div className="d-flex justify-content-center p-lg-2">
                        <button className="verified_button ">
                          OTP verified!
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Modal Footer */}
                  <div className="modal-footer form_card ">
                    <div className="d-flex justify-content-between gap-3">
                      <button type="submit" className="form_button ">
                        Close
                      </button>
                      <button type="submit" className="form_button next_btn">
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySipModel;
