import React, { useEffect, useState } from "react";
import "../styles/transaction.css";
import fatca_declaration from "../images/my_profile/fatca_declaration.svg";
import pen from "../images/my_profile/pen.svg";
import view from "../images/my_profile/eye.svg";

import arrow from "../images/my_profile/arrow.svg";
import SelectDropDown from "./SelectDropDown";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../styles/profile.css";
import { sanitizeInput } from "../utils/sanitizeInput";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageUserData } from "../utils/userData";
import {
  userFatcaDetails,
  userFatcaDetailsUpdate,
  userOnboardingGetDetails,
} from "../actions/usersActions";

const FatcaDeclaration = () => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const { userOnboardGetData, userFatcaDetail } = useSelector(
    (state) => state.users
  );
  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });
  const toggleEditMode = (e) => {
    e.stopPropagation();
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    const idTypeOptions = userFatcaDetail?.data?.idtypes?.map((idtype) => ({
      value: idtype.code,
      label: idtype.description,
    }));

    const panCardOption = idTypeOptions?.find(
      (option) => option.label === "PAN Card"
    );
    const payload = {
      id: userData?.id,
      completed_wizard_step: "2",
    };
    dispatch(userOnboardingGetDetails(payload));

    if (panCardOption && !formik.values.idtype) {
      formik.setFieldValue("idtype", panCardOption.value);
      formik.setFieldValue(
        "id_no",
        userOnboardGetData?.data?.profile?.pan
          ? userOnboardGetData?.data?.profile?.pan
          : userData?.pan
      );
    }
  }, [userFatcaDetail]);

  const formik = useFormik({
    initialValues: {
      annual_income: userFatcaDetail?.data?.fatca_kyc?.annual_income || "",
      address_type: userFatcaDetail?.data?.fatca_kyc?.address_type || "",
      source_of_wealth:
        userFatcaDetail?.data?.fatca_kyc?.source_of_wealth || "",
      birth_place: userFatcaDetail?.data?.fatca_kyc?.birth_place || "",
      id_no: userFatcaDetail?.data?.fatca_kyc?.id_no
        ? userFatcaDetail?.data?.fatca_kyc?.id_no
        : userData?.pan,
      idtype:
        userFatcaDetail?.data?.fatca_kyc?.idtype || userData?.pan ? "C" : "C",
      tax_residency: "N",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      annual_income: Yup.string()
        .notOneOf(["Select"], "Annual Income is required")
        .required("Annual Income is required"),
      address_type: Yup.string()
        .notOneOf(["Select"], "Address Type is required")
        .required("Address Type is required"),
      source_of_wealth: Yup.string()
        .notOneOf(["Select"], "Source of Income is required")
        .required("Source of Income is required"),
      birth_place: Yup.string()
        .test(sanitizeTest("Birth Place"))
        .max(100, "Input should not accept more than 100 characters")
        .required("Birth Place is required")
        .matches(/^[A-Za-z\s]+$/, "Only alphabets allowed"),
      idtype: Yup.string()
        .notOneOf(["Select"], "ID Type is required")
        .required("ID Type is required"),
      id_no: Yup.string().required("ID Number is required"),
      // tax_residency: Yup.string().required("Tax Residency is required"),
    }),
    onSubmit: (values) => {
      dispatch(userFatcaDetailsUpdate(values));
      setTimeout(() => {
        setIsEditing(false);
      }, 1000);
    },
  });

  useEffect(() => {
    dispatch(userFatcaDetails());
  }, []);

  const annualIncomeLabel = (value) => {
    const option = userFatcaDetail?.data?.annual_income?.find(
      (option) => option.app_income_code == value
    );
    return option ? option.app_income_desc : null; // Return label or null if not found
  };

  const addressTypeLabel = (value) => {
    const option = userFatcaDetail?.data?.addresstypes?.find(
      (option) => option.code == value
    );
    return option ? option.description : null; // Return label or null if not found
  };

  const sourceOfIncomeLabel = (value) => {
    const option = userFatcaDetail?.data?.source_of_wealth?.find(
      (option) => option.code == value
    );
    return option ? option.source_of_wealth : null; // Return label or null if not found
  };
  const idTypeLabel = (value) => {
    const option = userFatcaDetail?.data?.idtypes?.find(
      (option) => option.code == value
    );
    return option ? option.description : null; // Return label or null if not found
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingsix">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsesix"
          aria-expanded="false"
          aria-controls="collapsesix"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img
                src={fatca_declaration}
                alt=""
                className="fatca_declaration personel_details_img"
              />
              {/* <span className="personal_inner_text">FATCA Declaration</span> */}
              {isEditing ? (
                <>
                  <span className="personal_inner_text">
                    FATCA Declaration Edit
                  </span>
                </>
              ) : (
                <>
                  <span className="personal_inner_text">
                    {" "}
                    FATCA Declaration{" "}
                  </span>
                </>
              )}
            </div>
            {/* <div className="edit_personel">
                            <span className="badge editBadge">
                                <img src={pen} alt="" className="pen_image" />
                                <span className="editInner d-sm-block d-none">Edit</span>
                            </span>
                        </div> */}
          </div>
        </button>
      </h2>
      <div
        id="collapsesix"
        className="accordion-collapse collapse"
        aria-labelledby="headingsix"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />

        <div className="accordion-body profile_accordion_body">
          <div className="d-flex justify-content-end align-items-right">
            <div className="edit_personel">
              <span
                className="badge editBadge profile-mr"
                onClick={toggleEditMode}
              >
                {isEditing ? (
                  <>
                    <img src={view} alt="View Icon" className="pen_image" />
                    <span className="editInner d-sm-block d-none">View</span>
                  </>
                ) : (
                  <>
                    <img src={pen} alt="Edit Icon" className="pen_image" />
                    <span className="editInner d-sm-block d-none">Edit</span>
                  </>
                )}
              </span>
            </div>
          </div>

          {/* Bank Details Add Form */}

          {isEditing ? (
            <>
              <div className="row form_body justify-content-center redeam_bottom_card">
                <div className="col-xl-7 col-lg-10 col-md-10 my-1">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="annual_income" className="form-label">
                          Annual Income <span className="red-color">*</span>
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          name="annual_income"
                          id=""
                          value={formik.values.annual_income}
                          changeFunction={(value) =>
                            formik?.setFieldValue("annual_income", value)
                          }
                          options={userFatcaDetail?.data?.annual_income?.map(
                            (annual_incomes) => ({
                              value: annual_incomes.app_income_code,
                              label: annual_incomes.app_income_desc,
                            })
                          )}
                        />
                        {formik.touched.annual_income &&
                        formik.errors.annual_income ? (
                          <div className="text-danger">
                            {formik.errors.annual_income}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="addressType" className="form-label">
                          Address Type <span className="red-color">*</span>
                        </label>
                      </div>

                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          name="address_type"
                          id=""
                          value={formik.values.address_type}
                          changeFunction={(value) =>
                            formik?.setFieldValue("address_type", value)
                          }
                          options={userFatcaDetail?.data?.addresstypes?.map(
                            (addresstype) => ({
                              value: addresstype.code,
                              label: addresstype.description,
                            })
                          )}
                        />
                        {formik.touched.address_type &&
                        formik.errors.address_type ? (
                          <div className="text-danger">
                            {formik.errors.address_type}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label
                          htmlFor="source_of_wealth"
                          className="form-label"
                        >
                          Source of Income <span className="red-color">*</span>
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          name="idType"
                          id=""
                          value={formik.values.source_of_wealth}
                          changeFunction={(value) =>
                            formik?.setFieldValue("source_of_wealth", value)
                          }
                          options={userFatcaDetail?.data?.source_of_wealth?.map(
                            (source_of_wealths) => ({
                              value: source_of_wealths.code,
                              label: source_of_wealths.source_of_wealth,
                            })
                          )}
                        />
                        {formik.touched.source_of_wealth &&
                        formik.errors.source_of_wealth ? (
                          <div className="text-danger">
                            {formik.errors.source_of_wealth}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* Other Fields (same as before) */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label
                          htmlFor="confirmAccountNumber"
                          className="form-label"
                        >
                          Birth Place <span className="red-color">*</span>
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="birth_place"
                          placeholder="Enter Birth Place"
                          {...formik.getFieldProps("birth_place")}
                        />
                        {formik.touched.birth_place &&
                        formik.errors.birth_place ? (
                          <div className="text-danger">
                            {formik.errors.birth_place}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="idtype" className="form-label">
                          ID Type <span className="red-color">*</span>
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          name="idtype"
                          id=""
                          disabled={formik.values.idtype == "C" ? true : false}
                          value={formik.values.idtype}
                          changeFunction={(value) =>
                            formik?.setFieldValue("idtype", value)
                          }
                          options={userFatcaDetail?.data?.idtypes?.map(
                            (idtype) => ({
                              value: idtype.code,
                              label: idtype.description,
                            })
                          )}
                        />
                        {formik.touched.idtype && formik.errors.idtype ? (
                          <div className="text-danger">
                            {formik.errors.idtype}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="id_no" className="form-label">
                          ID Number <span className="red-color">*</span>
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="id_no"
                          placeholder="Enter ID Number"
                          disabled={formik.values.idtype == "C" ? true : false}
                          {...formik.getFieldProps("id_no")}
                        />
                        {formik.touched.id_no && formik.errors.id_no ? (
                          <div className="text-danger">
                            {formik.errors.id_no}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                      <button type="submit" className="form_button next_btn">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Personal Details Content */}

              <div className="card redeam_bottom_card">
                <div className="row form_body justify-content-center">
                  <div className="col-xl-6 col-lg-10 col-md-10 ">
                    <form>
                      <div className="row align-items-center mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Annual Income:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {annualIncomeLabel(
                              userFatcaDetail?.data?.fatca_kyc?.annual_income
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Address Type:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {" "}
                            {addressTypeLabel(
                              userFatcaDetail?.data?.fatca_kyc?.address_type
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Source of Income:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {sourceOfIncomeLabel(
                              userFatcaDetail?.data?.fatca_kyc?.source_of_wealth
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Birth Place:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userFatcaDetail?.data?.fatca_kyc?.birth_place}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            ID Type:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>{idTypeLabel(formik?.values?.idtype)}</span>
                        </div>
                      </div>
                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            ID Number:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userFatcaDetail?.data?.fatca_kyc?.id_no
                              ? userFatcaDetail?.data?.fatca_kyc?.id_no
                              : userData?.pan}
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FatcaDeclaration;
