import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRETKEY;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (encryptedData) => {
  if (encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    window.location.href = "/login";
  }
};

export const getLocalStorageUserData = (key) => {
  try {
    const value = localStorage?.getItem("userData");
    if (value) {
      const decryptedData = decryptData(value);
      return decryptedData ? decryptedData : null;
    }
  } catch (error) {
    console.error(`Error getting localStorage key "${key}":`, error);
    return null;
  }
};

export const getLocalStorageUserToken = (key) => {
  try {
    const value = localStorage?.getItem("token");
    if (value) {
      const decryptedData = decryptData(value);
      return decryptedData;
    }
  } catch (error) {
    console.error(`Error getting localStorage key "${key}":`, error);
    return null;
  }
};

export const getLocalStorageUserId = (key) => {
  try {
    const value = localStorage?.getItem("userData");
    if (value) {
      const decryptedData = decryptData(value);
      return decryptedData.id;
    }
  } catch (error) {
    console.error(`Error getting localStorage key "${key}":`, error);
    return null;
  }
};

export const redirectToLoginIfNoToken = () => {
  const token = getLocalStorageUserToken();
  if (!token) {
    window.location.href = "/login";
  }
};
