import React, { useEffect, useRef, useState } from "react";
import "../styles/dashboard.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import DashboardHoldingSection from "../components/DashboardHoldingSection";
import { Link, useNavigate } from "react-router-dom";
import DashboardTopHeaderSection from "../components/DashboardTopHeaderSection";
import AccountDeactivated from "../components/AccountDeactivated";
import "../styles/dashboard.css";
import disclaimer_image from "../images/dashboard/disclaimer_image.svg";
import RetirementPlan from "../images/icons/RetirementPlan.svg";
import BuyHouse from "../images/icons/BuyHouse.svg";
import UpArrowRound from "../images/icons/UpArrowRound.svg";
import { GoalCard } from "../components/GoalCard";
import InvestButton from "../components/InvestButton";
import { useDispatch, useSelector } from "react-redux";
import { userOnboardingGetDetails } from "../actions/usersActions";
import { getLocalStorageUserData } from "../utils/userData";
import { getGoalsList } from "../actions/goalsActions";
import HoldingSectionSidebar from "../components/HoldingSectionSidebar";
import Loader from "../loader/Loader-ui";

// Functional component for the Home page
const Dashboard = () => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const goals = useSelector((state) => state.goals?.goalList?.data?.goalData);
  const user = useSelector((state) => state.users);
  const GoalData = useSelector(
    (state) => state.holdings?.dashboardDatas?.data?.goals
  );

  const [showSwiper, setShowSwiper] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const userData = getLocalStorageUserData();

  useEffect(() => {
    const fetchData = async()=>{
      const payload = {
        id: userData?.id,
        perpage: "",
        pagenum: "",
      };
      try {
        setIsLoading(true);
        await dispatch(getGoalsList(payload))
      } catch (error) {
        console.error("API call failed: ", error);
      }
      finally{
        setIsLoading(false);
      }
    }
    if (isMounted.current) {
      fetchData();
    } else {
      isMounted.current = true;
    }
  }, [userData?.id, dispatch]);

  const handleImageClick = () => {
    setShowSwiper((prevState) => !prevState);
  };

  const handleNavigate = () => {
    naviagte("/goals/add", { state: { from: "dashboard" } });
  };

  // Refs for custom navigation
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { userOnboardGetData, userOnboardStoreData, loading, error } =
    useSelector((state) => state.users);
  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        id: userData?.id,
        completed_wizard_step: "1",
      };
      try {
        setIsLoading(true);
        await dispatch(userOnboardingGetDetails(payload));
      } catch (error) {
        console.error("API call failed: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    // if (isMounted.current) {
    fetchData();
    // }
    // else {
    //   isMounted.current = true;
    // }
  }, [dispatch]);

  useEffect(() => {
    if (userOnboardGetData) {
      if (
        userOnboardGetData?.wizard_step == 4 ||
        userOnboardGetData?.wizard_step == 5
      ) {
        setIsVisible(true);
      } else if (userOnboardGetData?.wizard_step == 0) {
        window.location.href = "/personal-details";
      } else if (userOnboardGetData?.wizard_step == 1) {
        window.location.href = "/bank-details";
      } else if (userOnboardGetData?.wizard_step == 2) {
        window.location.href = "/fatca-declartion";
      } else if (userOnboardGetData?.wizard_step == 3) {
        window.location.href = "/manage-nominees";
      }
    }
  }, [userOnboardGetData]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        isVisible && (
          <div className="container-fluid page_background">
            <AccountDeactivated />

            <DashboardTopHeaderSection />
            <div className="page-inner-content dashboardSectionBox">
              <div className="page_inner_content_box fund_holding_section">
                <div className="d-flex justify-content-between align-itmes-center mb-4">
                  <h5 className="main-heading-title">Holdings</h5>
                  <Link to="/portfolio" className="view_all_btn">
                    View All
                  </Link>
                </div>
                <div>
                  <HoldingSectionSidebar />
                </div>
              </div>
            </div>

            <div class="page-inner-content dashboardSectionBox">
              <div class="page_inner_content_box fund_holding_section position-relative">
                <div class="d-flex justify-content-between align-itmes-center mb-4 ">
                  <h5 className="main-heading-title">Goals</h5>
                  <span>
                    <InvestButton
                      label="Add Goal"
                      url="/goals/add"
                      color="blue"
                      className="me-4"
                      onClick={handleNavigate}
                    />
                    <Link to="#" onClick={handleImageClick}>
                      <img src={UpArrowRound} alt="" />
                    </Link>
                  </span>
                </div>
                {showSwiper && (
                  <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    slidesPerView={2}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                      },
                      0: {
                        slidesPerView: 1,
                      },
                    }}
                    navigation={{
                      prevEl: prevRef?.current,
                      nextEl: nextRef?.current,
                    }}
                    onBeforeInit={(swiper) => {
                      swiper.params.navigation.prevEl = prevRef?.current;
                      swiper.params.navigation.nextEl = nextRef?.current;
                    }}
                  >
                    {goals?.length > 0 ? (
                      goals?.map((goal, index) => (
                        <div
                          className="col-sm-12 col-md-6 col-lg-6 px-lg-3 mb-4"
                          key={goal?.id}
                        >
                          <SwiperSlide key={index}>
                            <GoalCard
                              goal={goal}
                              image={RetirementPlan && BuyHouse}
                            />
                          </SwiperSlide>
                        </div>
                      ))
                    ) : (
                      <div className="no-goals-message">
                        No goals available.
                      </div>
                    )}
                  </Swiper>
                )}

                <div className="custom-navigation">
                  <button ref={prevRef} className="custom-prev-btn">
                    <i className="fas fa-chevron-left"></i>
                  </button>
                  <button ref={nextRef} className="custom-next-btn">
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="page-inner-content">
              <div className="page_inner_content_box disclaimer-box pb-sm-0">
                <div className="row justify-content-center">
                  <div className="col-lg-11 col-sm-11 px-md-4 d-flex flex-sm-row flex-column align-items-center gap-md-5 gap-sm-4 gap-3">
                    <div className="disclaimer_image">
                      <img src={disclaimer_image} alt="Avatar" className="" />
                    </div>
                    <div className="disclaimer-text ms-md-3 mb-sm-4">
                      Mutual funds, like securities investments, are subject to
                      market and other risks, and there can be no assurance that
                      the objectives of any of the schemes of the Fund will be
                      achieved. Please read the Offer Document carefully in its
                      entirety prior to making an investment decision.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Dashboard;
