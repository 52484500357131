import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./styles/main.css"; // Import styles
import "./styles/main2.css"; // Import styles
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <ToastContainer />
        <App />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);
