import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet-async'; // Import Helmet
import "../styles/home.css"; // Import styles
import HomeHeroSection from "../components/HomeHeroSection";
import CardBox from "../components/CardBox";
import InvestmentPlans from "../components/InvestmentPlans";
import ButtonRound from "../components/ButtonRound";
import HeroBackgroundImage from "../images/heroimg.jpg";
import DreamVacation from "../images/homepage/DreamVacation.svg";
import Education from "../images/homepage/Education.svg";
import CarImage from "../images/homepage/Car.svg";
import DreamHome from "../images/homepage/DreamHome.svg";
import RetirementPlan from "../images/homepage/RetirementPlan.svg";
import Business from "../images/homepage/Start_Business.svg";
import Marriage from "../images/homepage/Marriage.svg";
import SaveTax from "../images/homepage/SaveTax.svg";
import PersonalGrowth from "../images/homepage/PersonalGrowth.svg";
import FinancialFreedom from "../images/homepage/FinancialFreedom.svg";
import MobileImage from "../images/homepage/mobile_ss.svg";
import SliderComponent from "../components/SliderComponent";
import MouseMoveAnimation from "../components/MouseMoveAnimation";
import FundInvestSection from "../components/FundInvestSection";
import ChartCard from "../components/ChartCard";
import RecommendedFundCard from "../components/RecommendedFundPlan2";
import { getLocalStorageUserToken } from "../utils/userData";
import { useDispatch, useSelector } from "react-redux";
import { goalAmountCalculation, recommendedFunds } from "../actions/goalsActions";
import { debounce } from "lodash";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
const token = getLocalStorageUserToken();

// Functional component for the Home page
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollableSectionRef = useRef(null);
  const recommended = useSelector((state) => state.goals);
  const [sliderData, setSliderData] = useState({
    amount: 10000,
    expected_rate: 1,
    tenure: 1,
    inflation: 0,
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fundsData1, setFundsData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const fundsData = recommended?.recommendedFunds?.data?.funds || [];
  useEffect(() => {
    const fetchFunds = async () => {
      const payload = {
        amount: sliderData?.amount,
        expected_rate_of_interest: sliderData?.expected_rate,
        period_years: sliderData?.tenure,
        inflation_rate: sliderData?.inflation,
        perpage: 9999,
        pagenum: 1,
      };

      try {
        await dispatch(recommendedFunds(payload));
        const apiFunds = recommended?.recommendedFunds?.data?.funds || [];
        setFundsData(apiFunds);
      } catch (error) {
        console.error("Error fetching funds", error);
      }
    };

    fetchFunds();
  }, [dispatch, sliderData]);

  const handleChartDataUpdate = (newChartData) => {
    setSliderData((prevData) => ({
      ...prevData,
      ...newChartData,
    }));
  };

    useEffect(()=>{
      const payload={ 
        rate: sliderData?.expected_rate, 
        tenure: sliderData?.tenure,
        amount: sliderData?.amount,
        inflation: sliderData?.inflation,
      }
      dispatch(goalAmountCalculation(payload));
    },[dispatch]);

  const validationSchema = Yup.object({
          amount: Yup.number().min(10000, "Amount must be at least 10000").required("Amount is required"),
        });

  const goal = useSelector((state) => state.goals);
  const [activeCardIndex, setActiveCardIndex] = useState(null); // Track active card

  const investmentMonthly =
    goal?.goalsAmountCalucalation?.data?.investment_monthly_total || 0;
  const interestMonthly =
    goal?.goalsAmountCalucalation?.data?.interest_monthly || 0;

  const investmentYearlyTotal =
    goal?.goalsAmountCalucalation?.data?.investment_yearly_total || 0;
  const interestYearly =
    goal?.goalsAmountCalucalation?.data?.interest_yearly || 0;

  const investmentOneTime =
    goal?.goalsAmountCalucalation?.data?.investment_one_time || 0;
  const interestOneTime =
    goal?.goalsAmountCalucalation?.data?.interest_one_time || 0;

  const perMonthData = {
    labels: ["Total Investment", "Total Interest"],
    datasets: [
      {
        data: [investmentMonthly, interestMonthly],
        backgroundColor: ["#3583FF", "#34D3B7"],
        hoverBackgroundColor: ["#3583FF", "#34D3B7"],
      },
    ],
  };

  const perYearData = {
    labels: ["Total Investment", "Total Interest"],
    datasets: [
      {
        data: [investmentYearlyTotal, interestYearly],
        backgroundColor: ["#3583FF", "#34D3B7"],
        hoverBackgroundColor: ["#3583FF", "#34D3B7"],
      },
    ],
  };

  const oneTimeData = {
    labels: ["Total Investment", "Total Interest"],
    datasets: [
      {
        data: [investmentOneTime, interestOneTime],
        backgroundColor: ["#3583FF", "#34D3B7"],
        hoverBackgroundColor: ["#3583FF", "#34D3B7"],
      },
    ],
  };
  const investmentPlans = {
    plans: [
      {
        title: "Buy House/Property",
        value: "buy_house/property",
        images: DreamHome,
      },
      {
        title: "Buy Vehicle",
        value: "buy-vehicle",
        images: CarImage,
      },
      {
        title: "Start a Business",
        value: "start-business",
        images: Business,
      },
      {
        title: "Marriage",
        value: "marriage",
        images: Marriage,
      },
      {
        title: "Child Education",
        value: "education-child",
        images: Education,
      },
      {
        title: "Vacation",
        value: "vacation",
        images: DreamVacation,
      },
      {
        title: "Retirement Plan",
        value: "retirement",
        images: RetirementPlan,
      },
      // {
      //   title: "Save Tax",
      //   images: SaveTax,
      // },
      // {
      //   title: "Personal Growth",
      //   images: PersonalGrowth,
      // },
      // {
      //   title: "Financial Freedom",
      //   images: FinancialFreedom,
      // },
    ],
  };

  const featurePlans = {
    plans: [
      {
        title: "SIP",
        subtitle: "Mastery",
        color: "sip",
        content:
          "Start your SIP journey for steady wealth creation. Invest easily, benefit from rupee cost averaging, and watch your financial goals grow.",
        button_name: "Get Started",
        button_url: token ? `/explore` : `/login`,
      },
      {
        title: "SWP",
        subtitle: "Freedom",
        color: "swp",
        content:
          "Take control of your financial future with Systematic Withdrawal Plans (SWP). Customize your withdrawals, enjoy returns, and protect your capital—your path to financial independence.",
        button_name: "Get Started",
        button_url: token ? `/dashboard` : `/login`,
      },
      {
        title: "WEALTH",
        subtitle: "Creation",
        color: "wealth",
        content:
          "Grow your wealth with our curated Mutual Fund portfolios. Enjoy professional management, diversification, and potential high returns. Choose smart, choose our expertise.",
        button_name: "Get Started",
        button_url: token ? `/portfolio` : `/login`,
      },
    ],
  };

  const handleCardClick = (entry,index) => {    
    if (token) {
      navigate("/goals/add", { state: entry?.value});
    } else {
      navigate("/login")
    }
    setActiveCardIndex(index === activeCardIndex ? null : index); // Toggle active card
  };

  return (
    <>
      {/* Helmet for Dynamic SEO Metadata */}
      <Helmet>
                <title>Home | Fundswala</title>
                <meta name="description" content="Invest in the best mutual funds with Fundswala. Start your SIP today!" />
      </Helmet>
      <HomeHeroSection
        title="Your Gateway to"
        subtitle="Financial Freedom"
        bgImage={HeroBackgroundImage}
      />

      <section className="cards_section border-bottom" id="cards_section">
        <div className="container-lg container-fluid my-lg-5 px-lg-4 ">
          <div className="cards_main_box g-5 justify-content-center">
            {featurePlans.plans.map((entry, index) => {
              return (
                <CardBox
                  title={entry.title}
                  subtitle={entry.subtitle}
                  color={entry.color}
                  content={entry.content}
                  buttonName={entry.button_name}
                  buttonUrl={entry.button_url}
                  indexKey={index}
                />
              );
            })}
          </div>
        </div>
      </section>

      <section className="plan_section" id="plan_section">
        <div className="container-lg container-fluid">
          <h1 className="section-title">
            Achieve Goals with <br className="d-md-block d-none" />
            <span>Personalized</span> Investment Plans
          </h1>

          <div className="row g-4  mt-md-5  mt-3 ">
            {investmentPlans.plans.map((entry, index) => {
              return (
                <InvestmentPlans
                  key={index}
                  imageSrc={entry.images}
                  title={entry.title}
                  indexKey={index}
                  isActive={activeCardIndex === index}
                  onClick={() => handleCardClick(entry,index)}
                />
              );
            })}
          </div>

          <div className="goal_planner_section card mt-md-5 mt-4">
            <div className="card-body p-0">
              <div className="goal_planner_top px-md-5 pt-lg-5 pt-4 px-3">
                <h5 className="mb-lg-5 mb-4 left-section-title">
                  Goal Planner
                </h5>
                <div className="card border-0">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-7 mb-md-0 mb-4">
                      <Formik
                  initialValues={{
                    amount: sliderData?.amount || 10000,
                  }}
                  validationSchema={validationSchema}
                  
                >
                  {({ values, setFieldValue, handleSubmit, touched, errors }) => (
                    <Form>
                        <SliderComponent
                          sliderData={sliderData} // Pass the state down
                          onSliderChange={handleChartDataUpdate}
                          amount={sliderData?.amount} // Pass the amount to SliderComponent
                          setFieldValue={setFieldValue}
                          error={errors.amount} // Pass the error for amount field
                          touched={touched.amount}
                        />
                    </Form>
                  )}
                </Formik>
                      </div>
                      <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 chart_section mb-4">
                        <div className="d-flex justify-content-center gap-4 chart_header ">
                          <h6 className="d-flex gap-2 align-items-center">
                            <span className="Investment_color_box"></span>
                            Investment
                          </h6>
                          <h6 className="d-flex gap-2 align-items-center">
                            <span className="Investment_color_box color_box"></span>
                            Interest
                          </h6>
                        </div>
                        <div className="row investment_cards ms-lg-3 justify-content-lg-start justify-content-center">
                          <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6 mt-3">
                            <div className="card border-0 h-100 position-relative">
                              <div className="card-body">
                                <h6 className="d-flex mb-4">
                                  Per Month{" "}
                                  <span className="ms-auto">
                                    ₹
                                    {goal?.goalsAmountCalucalation?.data
                                      ?.investment_monthly }
                                  </span>
                                </h6>
                                <div className="d-flex flex-column gap-2">
                                  <h5 className="d-flex">
                                    Total Investment:
                                    <span className="ms-auto">
                                      ₹
                                      {goal?.goalsAmountCalucalation?.data
                                        ?.investment_monthly_total }
                                    </span>
                                  </h5>
                                  <h5 className="d-flex">
                                    Total Interest:
                                    <span className="ms-auto">
                                      ₹
                                      {goal?.goalsAmountCalucalation?.data
                                        ?.interest_monthly}
                                    </span>
                                  </h5>
                                </div>
                                <div className="mt-3 chart_div">
                                  <ChartCard dataArr={perMonthData} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6  mt-3">
                            <div className="card border-0 h-100 position-relative">
                              <div className="card-body">
                                <h6 className="d-flex mb-4">
                                  Per Year{" "}
                                  <span className="ms-auto">
                                    ₹
                                    {goal?.goalsAmountCalucalation?.data
                                      ?.investment_yearly }
                                  </span>
                                </h6>
                                <div className="d-flex flex-column gap-2">
                                  <h5 className="d-flex">
                                    Total Investment:
                                    <span className="ms-auto">
                                      ₹
                                      {goal?.goalsAmountCalucalation?.data
                                        ?.investment_yearly_total }
                                    </span>
                                  </h5>
                                  <h5 className="d-flex">
                                    Total Interest:
                                    <span className="ms-auto">
                                      ₹
                                      {goal?.goalsAmountCalucalation?.data
                                        ?.interest_yearly }
                                    </span>
                                  </h5>
                                </div>
                                <div className="mt-3 chart_div">
                                  <ChartCard dataArr={perYearData} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-lg-6 col-md-6 col-sm-6  mt-3">
                            <div className="card border-0 h-100 position-relative">
                              <div className="card-body">
                                <h6 className="d-flex mb-4">
                                  One Time{" "}
                                  <span className="ms-auto">
                                    ₹
                                    {goal?.goalsAmountCalucalation?.data
                                      ?.investment_one_time }
                                  </span>
                                </h6>
                                <div className="d-flex flex-column gap-2">
                                  <h5 className="d-flex">
                                    Total Interest:
                                    <span className="ms-auto">
                                      ₹
                                      {goal?.goalsAmountCalucalation?.data
                                        ?.interest_one_time }
                                    </span>
                                  </h5>
                                </div>
                                <div className="mt-3 chart_div">
                                  <ChartCard dataArr={oneTimeData} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* i commented out below because Aparna ma'am inputs */}
              {/* <div className="card_divider mb-lg-5 mt-xl-0 mt-lg-5 mt-4 mb-4"></div> */}
              {/* <div className="goal_planner_content px-md-5 px-3">
                <h5 className="mb-4">Recommended Funds</h5>
                <div
                  className="fund-card-content mb-3 no-scrollbar"
                  ref={scrollableSectionRef}
                  style={{ overflowY: "auto", maxHeight: "370px" }}
                >
                  <div className="row">
                    {fundsData?.map((items, index) => (
                      <div
                        className="col-xl-4 col-lg-6 col-md-12 mb-md-4 mb-3"
                        key={index}
                      >
                        <RecommendedFundCard
                          image={items?.amc?.icon || ""}
                          title={items?.product_long_name}
                          badge={items?.asset_class}
                          badge2={items?.plan_type}
                          price1={`₹${items?.nav?.nav || "0.00"}`}
                          price2={`₹${
                            items?.productlimits?.find(
                              (limit) => limit.sub_trxn_type === "N"
                            )?.minimum_amount || "0.00"
                          }`}
                          price3={`₹${
                            items?.productlimits?.find(
                              (limit) => limit.sub_trxn_type === "S"
                            )?.minimum_amount || "0.00"
                          }`}
                          url="/fund-detail"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="text-center mt-md-5 mt-4 mb-md-4">
            <ButtonRound
              label="Get Started"
              url={token ? `/goals` : `/login`}
              color="blue"
            />
          </div>
        </div>
      </section>

      <FundInvestSection />

      <MouseMoveAnimation src={MobileImage} />
    </>
  );
};

export default Home;
