import React, { useEffect, useState } from "react";
import help_icon from "../images/sidebar_icon/help_icon.svg";
import InvestmentHeaderBox1 from "../components/InvestmentHeaderBox1";
import "../styles/redeem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { redeemShow, redeemStore } from "../actions/holdingAction";
import { getLocalStorageUserData } from "../utils/userData";
import { currentDate } from "../utils/DateFormate";
import { useFormik } from "formik";
import * as Yup from "yup";
import PopupModel from "../components/PopupModel";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
// Functional component for the Home page
const Redeem = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = getLocalStorageUserData();
  const state = location.state;

  const holdingsData = useSelector((state) => state.holdings);
  const dataOfRedeem = location?.state?.props;

  const closeModal = () => {
    setIsPopupVisible(false);
  };

  const cancelBack = () => {
    formik.resetForm();
    navigate("/portfolio");
  };  
  useEffect(() => {
    if (dataOfRedeem) {
      const payload = {
        id: userData?.id,
        amc_product_id: dataOfRedeem?.product?.amc_product_id,
        productlimit_id: dataOfRedeem?.productlimit?.id,
        folio_no: dataOfRedeem?.folioNumber
      };
      dispatch(redeemShow(payload));
    }
  }, [dataOfRedeem]);

  const formik = useFormik({
    initialValues: {
      id: userData?.id || "",
      amt:
        holdingsData?.redeemShow?.data?.amt ||
        holdingsData?.redeemShow?.data?.current_amount,
      unit:
        holdingsData?.redeemShow?.data?.unit ||
        holdingsData?.redeemShow?.data?.available_units,
      amt_unit_type: holdingsData?.redeemShow?.data?.amt_unit_type || "Amount",
      full_redeem: holdingsData?.redeemShow?.data?.full_redeem || 0,
      productlimit_id: dataOfRedeem?.productlimit?.id || "",
      product_id: dataOfRedeem?.product?.id || "",
      amc_product_id: dataOfRedeem?.product?.amc_product_id,
      isin: dataOfRedeem?.product?.isin || "",
      product_current_nav: holdingsData?.redeemShow?.data?.product_nav,
      current_value: holdingsData?.redeemShow?.data?.current_amount || "",
      current_units: holdingsData?.redeemShow?.data?.available_units || "",
      minimum_amt: holdingsData?.redeemShow?.data?.redeem_minimum_amount || "",
      folio_no: holdingsData?.redeemShow?.data?.folio_no || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amt: Yup.string()
        .matches(/^\d+(\.\d+)?$/, "Amount must only contain numbers")
        .when(["amt_unit_type", "full_redeem"], (values, schema) => {
          const [amt_unit_type, full_redeem] = values;
          if (amt_unit_type === "Amount") {
            if (full_redeem == 0) {
              return schema.required("Amount is required");
            }
            return schema.notRequired();
          }
          return schema;
        }),
      unit: Yup.string()
        .matches(/^\d+(\.\d+)?$/, "Units must only contain numbers")
        .when(["amt_unit_type", "full_redeem"], (values, schema) => {
          const [amt_unit_type, full_redeem] = values;
          if (amt_unit_type === "Units") {
            if (full_redeem == 0) {
              return schema.required("Units is required");
            }
            return schema.notRequired();
          }
          return schema;
        }),
    }),
    onSubmit: (values) => {
      dispatch(redeemStore(values))
        .then((response) => {
          if (response) {
            if (response?.status == true) {
              navigate(
                `/orders?order=${
                  response?.data?.order_id
                    ? response?.data?.order_id
                    : response?.data?.order
                }`
              );
            } else {
              setIsPopupVisible(true);
            }
          }
        })
        .catch((error) => {
          setIsPopupVisible(true);
        });
    },
  });

  const actionPopup = () => {
    navigate("/portfolio");
  };

  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            <Link className="back_icon" to="/portfolio">
              <FontAwesomeIcon icon={faAngleLeft} className="color me-3" />
            </Link>
            Redeem
          </h5>
          <InvestmentHeaderBox1 props={dataOfRedeem} />
          <form onSubmit={formik.handleSubmit}>
            <div className="card redeam_bottom_card ">
              <div className="card-body p-0">
                <div className="row align-items-center r_inner_section">
                  <div className="col-md-auto col-sm-auto my-1">
                    <h6 className="text-muted r_inner_title mb-0">
                      Amount:
                      <span className="fw-bold ms-2">
                        ₹{holdingsData?.redeemShow?.data?.current_amount}
                      </span>
                    </h6>
                  </div>
                  <div className="col-md-auto col-sm-auto px-lg-3 px-0">
                    <span className="divider"></span>
                  </div>
                  <div className="col-md-auto col-sm-auto my-1">
                    <h6 className="text-muted r_inner_title mb-0">
                      Available units:
                      <span className="fw-bold ms-2 ">
                        {holdingsData?.redeemShow?.data?.available_units}
                      </span>
                    </h6>
                  </div>
                  <div className="col-md-auto col-sm-auto px-lg-3 px-0">
                    <span className="divider"></span>
                  </div>
                  <div className="col-md-auto col-sm-auto my-1">
                    <h6 className="text-muted r_inner_title mb-0">
                      NAV as on {currentDate()}:
                      <span className="fw-bold ms-2 text_success">
                        ₹{holdingsData?.redeemShow?.data?.product_nav}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="card redeam_bottom_card">
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  {formik.values.amt_unit_type == "Amount" && (
                    <div className="row align-items-center mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="amount" className="form-label">
                          Amount to Redeem
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          name="amt"
                          className="form-control"
                          id="Amount"
                          placeholder="₹"
                          disabled={formik.values.full_redeem} // Disable if full redeem is selected
                          value={formik.values.amt}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.amt && formik.touched.amt && (
                          <div className="text-danger">{formik.errors.amt}</div>
                        )}
                      </div>
                    </div>
                  )}
                  {formik.values.amt_unit_type == "Units" &&
                    (formik.values.full_redeem == 0 ||
                      formik.values.full_redeem == 1) && (
                      <div className="row align-items-center mb-3">
                        <div className="col-lg-5 col-md-6 col-sm-5">
                          <label htmlFor="unit" className="form-label">
                            Number of Units
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-7">
                          <input
                            type="text"
                            name="unit"
                            className="form-control"
                            id="unit"
                            placeholder="Units"
                            disabled={formik.values.full_redeem} // Disable if full redeem is selected
                            value={formik.values.unit}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.unit && formik.touched.unit && (
                            <div className="text-danger">
                              {formik.errors.unit}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label className="form-label">Redeem By</label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <div className="d-flex align-items-center gap-2 flex-wrap">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="amt_unit_type"
                            id="Amount"
                            value="Amount"
                            disabled={formik.values.full_redeem} // Disable if full redeem is selected
                            checked={formik.values.amt_unit_type === "Amount"}
                            onChange={formik.handleChange}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="Amount"
                          >
                            Amount
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="amt_unit_type"
                            id="Units"
                            value="Units"
                            disabled={formik.values.full_redeem} // Disable if full redeem is selected
                            checked={formik.values.amt_unit_type === "Units"}
                            onChange={() => {
                              formik.setFieldValue("amt_unit_type", "Units");
                              formik.setFieldValue(
                                "unit",
                                holdingsData?.redeemShow?.data?.available_units
                              );
                            }}
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor="Units"
                          >
                            Units
                          </label>
                        </div>
                      </div>
                      {formik.errors.amt_unit_type &&
                        formik.touched.amt_unit_type && (
                          <div className="text-danger">
                            {formik.errors.amt_unit_type}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5"></div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="full_redeem"
                          id="Redeem"
                          value={formik.values.full_redeem}
                          checked={
                            formik.values.full_redeem == 0 ? false : true
                          }
                          onChange={(e) => {
                            formik.setFieldValue(
                              "full_redeem",
                              e.target.checked == true ? 1 : 0
                            );
                            formik.setFieldValue(
                              "amt",
                              holdingsData?.redeemShow?.data?.current_amount
                            );
                            // formik.setFieldValue("amt_unit_type", "Amount");
                          }}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor="Redeem"
                        >
                          Full Redeem
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <button type="submit" className="btn btn-primary">
                    Submit
                  </button> */}
                </div>
              </div>
            </div>
            <div className="card-footer form_card form_card_border">
              <div className="d-flex justify-content-between gap-2">
                <button
                  type="button"
                  onClick={cancelBack}
                  className="form_button "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={
                    formik.values.amt_unit_type === "Units"
                      ? parseFloat(
                          holdingsData?.redeemShow?.data?.available_units
                        ) < parseFloat(formik.values.unit)
                        ? true
                        : false
                      : parseFloat(
                          holdingsData?.redeemShow?.data?.current_amount
                        ) < parseFloat(formik.values.amt)
                      ? true
                      : false
                  }
                  className="form_button next_btn"
                >
                  Redeem
                </button>
              </div>
            </div>
          </form>
          {isPopupVisible && holdingsData?.redeemStore?.status != true && (
            <PopupModel
              id="alertModel"
              title="Lorem Ipsum"
              content={holdingsData?.redeemStore?.message}
              image={cancel}
              actionPopup={actionPopup}
              closeModal={closeModal}
              btnColor={"btn-success"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Redeem;
