import React, { useEffect, useRef } from "react";
import help_icon from "../../images/sidebar_icon/help_icon.svg";
import InvestmentHeaderBox1 from "../InvestmentHeaderBox1";
import "../../styles/profile.css";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import SelectDropDown from "../SelectDropDown";
import DatePickerInput from "../DatePickerInput";
import { useDispatch, useSelector } from "react-redux";
import { InvestorAddFormData } from "../../actions/adminAction";
import {
  pincode,
} from "../../actions/usersActions";
// Functional component for the Home page
const FaqAdd = (props) => {
  const dispatch = useDispatch();
  const { formik } = props;
  console.log(formik.errors,"formik.errors.name");
  // Fetch existing data if editing
  useEffect(() => {
    dispatch(InvestorAddFormData());
  }, [dispatch]);
  const userOnboardGetData =
    useSelector((state) => state.admin.adminAddInvestorData) || {};
  // console.log(userOnboardGetData?.data?.mobileEmailRelation, "userGetProfile1");

  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];

  const maritalOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "UNMARRIED", label: "Unmarried" },
    { value: "OTHER", label: "Other" },
  ];

  const callStateAndDistrict = async (pin) => {
      try {
        if (pin?.split("")?.length == 6) {
          const payload = {
            pincode: pin,
          };
          dispatch(pincode(payload))
            .then((res) => {
              formik?.setFieldValue("city", res?.data?.city);
              formik?.setFieldValue("state", res?.data?.state_code);
            })
            .catch((error) => {
              console.log("Error:", error);
            });
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };
  return (
    <div>
      <div className="row form_body justify-content-center">
        <div className="col-xl-8 col-lg-10 col-md-11 ">
          <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Full Name <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter Full Name"
                {...formik.getFieldProps("name")}
              />
              {/* {formik.errors.name  (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        ) : null} */}
              {formik.errors.name && (
                <div className="text-danger">{formik.errors.name}</div>
              )}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Date of Birth <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <DatePickerInput
                selectedDate={formik.values.dob}
                maxDate={new Date().toISOString().split("T")[0]}
                onChange={(date) => formik.setFieldValue("dob", date)}
              />
              {formik.errors.dob && (
                <div className="text-danger">{formik.errors.dob}</div>
              )}{" "}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Email <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="anandr@gmail.com"
                {...formik.getFieldProps("email")}
              />
              {formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Email Belongs to <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              {/* <SelectDropDown /> */}
              <SelectDropDown
                name="email_relation"
                id=""
                value={formik.values.email_relation || ""}
                changeFunction={
                  (value) => formik.setFieldValue("email_relation", value) // Ensure only `value` is stored
                }
                options={(
                  userOnboardGetData?.data?.mobileEmailRelation || []
                ).map((item) => ({
                  value: item.email_relation,
                  label: item.description,
                }))}
              />
              {formik.errors.email_relation ? (
                <div className="text-danger">
                  {formik.errors.email_relation}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Mobile Number <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="mobile"
                placeholder="Mobile Number"
                {...formik.getFieldProps("mobile")}
              />
              {formik.errors.mobile ? (
                <div className="text-danger">{formik.errors.mobile}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Mobile Number Belongs to <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <SelectDropDown
                name="mobile_relation"
                id=""
                value={formik.values.mobile_relation || ""}
                changeFunction={
                  (value) =>
                    formik.setFieldValue("mobile_relation", value) // Ensure only `value` is stored
                }
                options={(
                  userOnboardGetData?.data?.mobileEmailRelation || []
                ).map((item) => ({
                  value: item.email_relation,
                  label: item.description,
                }))}
              />
              {formik.errors.mobile_relation ? (
                <div className="text-danger">
                  {formik.errors.mobile_relation}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Gender <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <div className="d-flex align-items-center gap-2 flex-wrap">
                {genderOptions.map(({ value, label }) => (
                  <div className="form-check form-check-inline" key={value}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id={value}
                      value={value}
                      onChange={formik.handleChange}
                      checked={formik.values.gender === value}
                    />
                    <label className="form-check-label ms-2" htmlFor={value}>
                      {label}
                    </label>
                  </div>
                ))}{" "}
              </div>
              {formik.errors.gender ? (
                <div className="text-danger">{formik.errors.gender}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Marital Status <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <div className="d-flex align-items-center gap-2 flex-wrap">
                {maritalOptions.map(({ value, label }) => (
                  <div className="form-check form-check-inline" key={value}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="marital_status"
                      id={value}
                      value={value}
                      onChange={formik.handleChange}
                      checked={formik.values.marital_status === value}
                    />
                    <label className="form-check-label ms-2" htmlFor={value}>
                      {label}
                    </label>
                  </div>
                ))}
              </div>

              {formik.errors.marital_status ? (
                <div className="text-danger">
                  {formik.errors.marital_status}
                </div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Father/Husband Name <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="father_name"
                placeholder="Enter Parent/Guardian Name"
                {...formik.getFieldProps("father_name")}
              />
              {formik.errors.father_name ? (
                <div className="text-danger">{formik.errors.father_name}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Occupation <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <SelectDropDown
                name="occupation"
                id=""
                value={formik?.values?.occupation}
                changeFunction={(value) =>
                  formik?.setFieldValue("occupation", value)
                }
                options={userOnboardGetData?.data?.occupations.map((occ) => ({
                  value: occ.occupation_code,
                  label: occ.occupation_desc,
                }))}
              />
              {formik.errors.occupation ? (
                <div className="text-danger">{formik.errors.occupation}</div>
              ) : null}{" "}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                PAN <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="pan"
                placeholder="Enter PAN"
                {...formik.getFieldProps("pan")}
              />
              {formik.errors.pan ? (
                <div className="text-danger">{formik.errors.pan}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Aadhar Number <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="adhaar_card"
                placeholder="Enter Aadhar Number"
                {...formik.getFieldProps("adhaar_card")}
              />
              {formik.errors.adhaar_card ? (
                <div className="text-danger">{formik.errors.adhaar_card}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Address <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="addr1"
                placeholder="Enter Address"
                {...formik.getFieldProps("addr1")}
              />
              {formik.errors.addr1 ? (
                <div className="text-danger">{formik.errors.addr1}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                Pincode <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="pincode"
                name="pincode"
                placeholder="Enter Pincode"
                onChange={(e) => {
                  formik.handleChange(e); // Handle Formik's change
                  callStateAndDistrict(e.target.value); // Call your custom function
                }}
                value={formik.values.pincode}
              />
              {formik.errors.pincode ? (
                <div className="text-danger">{formik.errors.pincode}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                City <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="Enter City"
                {...formik.getFieldProps("city")}
              />
              {formik.errors.city ? (
                <div className="text-danger">{formik.errors.city}</div>
              ) : null}
            </div>
          </div>

          <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
              <label className="form-label">
                State <span className="red-color">*</span>
              </label>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
              <SelectDropDown
                name="state"
                id=""
                value={formik?.values?.state}
                changeFunction={(value) =>
                  formik?.setFieldValue("state", value)
                }
                options={userOnboardGetData?.data?.states.map((state) => ({
                  value: state.state_code,
                  label: state.state_name,
                }))}
              />
              {formik.errors.state ? (
                <div className="text-danger">{formik.errors.state}</div>
              ) : null}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAdd;
