import React, { useState } from "react";
import '../styles/GoalCard.css';
import '../styles/common.css';
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Pencil from '../images/icons/Pencil.svg';
import Trash from '../images/icons/Trash.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteGoal, goalDataByID } from "../actions/goalsActions";
import { toast } from "react-toastify";
import ChartCard from "./ChartCard";
import { getLocalStorageUserData } from "../utils/userData";
import RetirementPlan from '../images/Retirement.svg';
import BuyHouse from '../images/Buy house or property.svg';
import BuyVehicale from '../images/Buy vehicle.svg';
import Vacation from '../images/Vacation.svg';
import EducationChild from '../images/Education of child.svg';
import startBussiness from '../images/Start Business.svg';
import marraige from '../images/Marriage.svg';
import { IoMdClose } from "react-icons/io";

ChartJS.register(ArcElement, Tooltip, Legend);

export const GoalCard = ({ goal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userData = getLocalStorageUserData();
  const goalByIdData = useSelector((state) => state.goals.goalDetailsById)

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async() => {
    const payload = {
      id: userData?.id,
      goal_id: goal?.id,
    };
    await dispatch(deleteGoal(payload));
    toast.success("Goal deleted successfully!");
    closeModal();
  };

  const goalDetails = async() => {
    const payload = {
      id: userData?.id,
      goalId: goal?.id,
    };

    await dispatch(goalDataByID(payload))
      .then((response) => {
        const fromPage = location.pathname.includes("dashboard") ? "/dashboard" : "/goals";
        const goalData = response?.data;
        if(goalData){
          navigate('/goals/edit', { state: { goalData: goalData, from: fromPage , status : true} });
        }
      })
      .catch((error) => {

        console.error('Error fetching goal data:', error);
      });
  };

  // Modal open handler
  const openModal = () => {
    setIsModalOpen(true);
  };

  const pieChartColors = {
    monthly: ["#34D3B7", "#3583FF"],
    yearly: ["#34D3B7", "#3583FF"],
    oneTime: ["#34D3B7", "#3583FF"],
  };

  // const createPieData = (percentage, colors) => ({
  //   labels: [`${percentage}%`, `${100 - percentage}%`],
  //   datasets: [
  //     {
  //       data: [percentage, 100 - percentage],
  //       backgroundColor: colors,
  //       borderWidth: 0,
  //     },
  //   ],
  // });

  const pieOptions = {
    plugins: {
      tooltip: { enabled: true },
      legend: { display: false },
    },
    maintainAspectRatio: false,
  };
  const investmentMonthly = goal?.investment_monthly_total;
  const interestMonthly = goal?.interest_monthly;

  const investmentYearlyTotal = goal?.investment_yearly_total;
  const interestYearly = goal?.interest_yearly;

  const investmentOneTime = goal?.investment_one_time;
  const interestOneTime = goal?.interest_one_time;
  const perMonthData = {
    labels: ['Total Investment', 'Total Interest'],
    datasets: [
      {
        data: [investmentMonthly, interestMonthly],
        backgroundColor: ['#3583FF', '#34D3B7'],
        hoverBackgroundColor: ['#3583FF', '#34D3B7'],
      },
    ],
  };

  const perYearData = {
    labels: ['Total Investment', 'Total Interest'],
    datasets: [
      {
        data: [investmentYearlyTotal, interestYearly],
        backgroundColor: ['#3583FF', '#34D3B7'],
        hoverBackgroundColor: ['#3583FF', '#34D3B7'],
      },
    ],
  };

  const oneTimeData = {
    labels: ['Total Investment', 'Total Interest'],
    datasets: [
      {
        data: [investmentOneTime, interestOneTime],
        backgroundColor: ['#3583FF', '#34D3B7'],
        hoverBackgroundColor: ['#3583FF', '#34D3B7'],
      },
    ],
  };
  const goalImages = {
    "Retirement": RetirementPlan,
    "Buy House/Property": BuyHouse,
    "Buy Vehicle": BuyVehicale,
    "Vacation": Vacation,
    "Education of Child/Children": EducationChild,
    "Start a Business":startBussiness,
    "Marriage" :marraige
  };
  const image = goalImages[goal?.goal] || goalImages[""];
  return (
    <div className="card  shadow-sm goalCardMainBox mb-2">
      <div className="d-flex card-header justify-content-between align-items-center  column-gap-3 row-gap-2">
        <div className="d-flex align-items-center gap-sm-3 gap-2">
          <div className="goalTopSideImage">
            <img src={image} alt="" />
          </div>
          <span className="goalPlanTitle mb-0" data-toggle="tooltip" data-placement="top" data-trigger="hover" title={goal?.goal}>{goal?.goal}</span>
        </div>
        <div className="d-flex gap-2">
          <div
            onClick={goalDetails} className="goals-edit" style={{ cursor: 'pointer' }}
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"
          >
            <img
              src={Pencil} alt=""
              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"
            />
          </div>
          <div onClick={openModal} className="goals-delete" style={{ cursor: 'pointer' }}
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"
          >
            <img src={Trash} alt=""
              data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"
            />
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="mb-3 d-flex flex-wrap column-gap-4 row-gap-2 goalCardSecondSection">
          <div className="pe-2">
            <p className="mb-1">Amount: </p>
            <span className="d-block">₹{goal.amount.toLocaleString()}</span>
          </div>
          <div className="pe-2">
            <p className="mb-1">Rate of Interest: </p>
            <span className="d-block">{goal.expected_rate}%</span>
          </div>
          <div className="pe-2">
            <p className="mb-1">Time Period: </p>
            <span className="d-block">{goal.tenure}  Yr</span>
          </div>
          <div className="pe-2">
            <p className="mb-1">Inflation: </p>
            <span className="d-block">{goal.inflation}%</span>
          </div>
        </div>

        <div className="bg-white text-dark p-3 goalCardPaiBox">
          <span className="d-block goalCardPaiBoxTitle pb-lg-3 pb-2">Required Investment:</span>
          <div className="d-flex justify-content-center flex-wrap gap-4">
            <div className="text-center">
              <p className="mb-1 goalTime">Monthly</p>
              <h6 className="mb-2 goalPrice">₹{goal?.investment_monthly?.toLocaleString()}</h6>
              <div style={{ width: "100px", height: "100px", margin: "0 auto" }}>
                <ChartCard dataArr={perMonthData} />
              </div>
            </div>
            <div className="text-center">
              <p className="mb-1 goalTime">Yearly</p>
              <h6 className="mb-2 goalPrice">₹{goal?.investment_yearly?.toLocaleString()}</h6>
              <div style={{ width: "100px", height: "100px", margin: "0 auto" }}>
                <ChartCard dataArr={perYearData} />
              </div>
            </div>
            <div className="text-center">
              <p className="mb-1 goalTime">One-time</p>
              <h6 className="mb-2 goalPrice">₹{goal.investment_one_time?.toLocaleString()}</h6>
              <div style={{ width: "100px", height: "100px", margin: "0 auto" }}>
                <ChartCard dataArr={oneTimeData} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for confirmation */}
      {isModalOpen && (
        <div className="modal show">
          <div className="modal-content goals-custom-popup">
            <div className="modal-header goals-custom-header">
              <h5 className="modal-title">Confirm Deletion</h5>

              {/* <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button> */}

              <IoMdClose onClick={closeModal} className="close-popup-color" />

            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this goal?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
