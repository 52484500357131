import React, { useEffect, useRef } from "react";
import help_icon from "../images/sidebar_icon/help_icon.svg";
import SelectDropDown from "../components/SelectDropDown";
import calendar_month from "../images/my_profile/calendar_month.svg";
import DatePickerInput from "../components/DatePickerInput";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  pincode,
  userOnboardingGetDetails,
  userOnboardingStoreData,
} from "../actions/usersActions";
import { getLocalStorageUserData } from "../utils/userData";
import { sanitizeInput } from "../utils/sanitizeInput";
import { convertDateToDDMMMYYYY } from "../utils/DateFormate";
// Functional component for the Home page
const PersonalDetails = () => {
  const dateInputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const { userOnboardGetData, userOnboardStoreData, loading, error } =
    useSelector((state) => state.users);

  const handleIconClick = () => {
    if (dateInputRef.current) {
      if (dateInputRef.current.showPicker) {
        dateInputRef.current.showPicker(); // For modern browsers
      } else {
        dateInputRef.current.focus(); // Fallback for older browsers
      }
    }
  };

  useEffect(() => {
    if (userOnboardGetData?.wizard_step) {
      if (
        userOnboardGetData?.wizard_step == 4 ||
        userOnboardGetData?.wizard_step == 5
      ) {
        window.location.href = "/dashboard";
      } else if (0 <= userOnboardGetData?.wizard_step) {
      } else {
        window.location.href = "/personal-details";
      }
    }
  }, [userOnboardGetData]);

  const handleNextClick = () => {
    navigate("/bank-details"); // "Next" page par navigate karega
  };

  useEffect(() => {
    const payload = {
      id: userData?.id,
      completed_wizard_step: "0",
    };
    dispatch(userOnboardingGetDetails(payload));
  }, []);

  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];

  const maritalOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "UNMARRIED", label: "Unmarried" },
    { value: "OTHER", label: "Other" },
  ];

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });

  const formik = useFormik({
    initialValues: {
      inv_name: userOnboardGetData?.data?.user?.name || "",
      dob: userOnboardGetData?.data?.user?.dob || "",
      email: userOnboardGetData?.data?.user?.email || "",
      email_relation:
        userOnboardGetData?.data?.user?.profile?.email_relation || "",
      mobile_no: userOnboardGetData?.data?.user?.mobile || "",
      mobile_relation:
        userOnboardGetData?.data?.user?.profile?.mobile_relation || "",
      gender: userOnboardGetData?.data?.user?.profile?.gender || "",
      marital_status:
        userOnboardGetData?.data?.user?.profile?.marital_status || "",
      father_name: userOnboardGetData?.data?.user?.profile?.father_name || "",
      occupation: userOnboardGetData?.data?.user?.profile?.occupation || "",
      pan: userOnboardGetData?.data?.user?.pan || "",
      adhaar_card: userOnboardGetData?.data?.user?.profile?.adhaar_card || "",
      addr1: userOnboardGetData?.data?.user?.profile?.addr1 || "",
      pincode: userOnboardGetData?.data?.user?.profile?.pincode || "",
      city: userOnboardGetData?.data?.user?.profile?.city || "",
      state: userOnboardGetData?.data?.user?.profile?.state || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      inv_name: Yup.string()
        .test(sanitizeTest("Full Name"))
        .matches(/^[A-Za-z\s]+$/, "Full Name must contain only alphabets")
        .required("Full Name is required"),
      dob: Yup.date().required("Date of Birth is required"),
      email: Yup.string()
        .test(sanitizeTest("Email"))
        .email("Enter a valid email address.")
        .required("Email is required"),
      email_relation: Yup.string()
        .notOneOf(["Select"], "Email Belongs To is required")
        .required("Email Belongs To is required"),
      mobile_no: Yup.string()
        .test(sanitizeTest("Mobile Number"))
        .matches(/^\d{10}$/, "Mobile Number must be 10 digits")
        .required("Mobile Number is required"),
      mobile_relation: Yup.string()
        .notOneOf(["Select"], "Mobile Belongs To is required")
        .required("Mobile Belongs To is required"),
      gender: Yup.string().required("Gender is required"),
      occupation: Yup.string()
        .notOneOf(["Select"], "Occupation is required")
        .required("Occupation is required"),
      marital_status: Yup.string().required("Marital Status is required"),
      father_name: Yup.string()
        .test(sanitizeTest("Parent Name"))
        .matches(/^[A-Za-z\s]+$/, "Parent Name must contain only alphabets")
        .required("Parent Name is required"),
      pan: Yup.string()
        .test(sanitizeTest("PAN"))
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN Details")
        .required("PAN is required"),
      adhaar_card: Yup.string()
        .test(sanitizeTest("Aadhar Number"))
        .matches(/^\d{12}$/, "Aadhar Number must be 12 digits")
        .required("Aadhar Number is required"),
      addr1: Yup.string()
        .test(sanitizeTest("Address"))
        .max(200, "Input should not accept more than 200 characters")
        .required("Address is required"),
      pincode: Yup.string()
        .test(sanitizeTest("Pincode"))
        .matches(/^\d{6}$/, "Pincode must be 6 digits")
        .required("Pincode is required"),
      city: Yup.string()
        .test(sanitizeTest("City"))
        .required("City is required"),
      state: Yup.string()
        .notOneOf(["Select"], "State is required")
        .required("State is required"),
    }),
    onSubmit: (values) => {
      const formatDate = convertDateToDDMMMYYYY(values?.dob);
      const payload = {
        ...values,
        dob: formatDate,
        id: userData?.id,
        completed_wizard_step: 0,
      };
      dispatch(userOnboardingStoreData(payload, userData?.id));
    },
  });

  const callStateAndDistrict = async (pin) => {
    try {
      if (pin?.split("")?.length == 6) {
        const payload = {
          pincode: pin,
        };
        dispatch(pincode(payload))
          .then((res) => {
            formik?.setFieldValue("city", res?.data?.city);
            formik?.setFieldValue("state", res?.data?.state_code);
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  if (loading || !userOnboardGetData) {
    return <div>Loading...</div>; // Wait for data before rendering form
  }
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="progress-container page_inner_content_box">
          <div className="step-info">
            <span>Step 1 of 4</span>
          </div>
          <h4 className="page-title mb-0">Personal Details</h4>
        </div>
        <div className="progress-bar">
          <div className="progress-filled" style={{ width: "25%" }}></div>
        </div>

        <div className="form_group page_inner_content_box d-flex flex-wrap justify-content-between ">
          <form
            id="profile_form"
            onSubmit={formik.handleSubmit}
            className="step_form"
            action=""
          >
            <div className="  card border-0">
              <div className="card-body form_card">
                <div className="row">
                  <div className="col-xl-7 col-lg-8 col-12  order-lg-1 order-2">
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="inv_name" className="form-label">
                          Full Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inv_name"
                          placeholder="Enter Full Name"
                          value={formik.values.inv_name}
                          {...formik.getFieldProps("inv_name")}
                        />
                        {formik.touched.inv_name && formik.errors.inv_name ? (
                          <div className="text-danger">
                            {formik.errors.inv_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="dob" className="form-label">
                          Date of Birth*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <DatePickerInput
                          selectedDate={formik.values.dob}
                          maxDate={new Date().toISOString().split("T")[0]}
                          onChange={(date) => formik.setFieldValue("dob", date)}
                        />
                        {formik.touched.dob && formik.errors.dob ? (
                          <div className="text-danger">{formik.errors.dob}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="anandr@gmail.com"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="email_relation" className="form-label">
                          Email Belongs to*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="email_relation"
                          id=""
                          value={formik.values.email_relation}
                          changeFunction={(value) =>
                            formik?.setFieldValue("email_relation", value)
                          }
                          options={Object.keys(
                            userOnboardGetData?.data?.mobile_email_relations
                          ).map((key) => ({
                            value: key,
                            label:
                              userOnboardGetData?.data?.mobile_email_relations[
                                key
                              ],
                          }))}
                        />
                        {formik.touched.email_relation &&
                        formik.errors.email_relation ? (
                          <div className="text-danger">
                            {formik.errors.email_relation}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="mobile_no" className="form-label">
                          Mobile Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="mobile_no"
                          placeholder="Mobile Number"
                          {...formik.getFieldProps("mobile_no")}
                        />
                        {formik.touched.mobile_no && formik.errors.mobile_no ? (
                          <div className="text-danger">
                            {formik.errors.mobile_no}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="mobile_relation" className="form-label">
                          Mobile Number Belongs to*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="mobile_relation"
                          id=""
                          value={formik.values.mobile_relation}
                          changeFunction={(value) =>
                            formik?.setFieldValue("mobile_relation", value)
                          }
                          options={Object.keys(
                            userOnboardGetData?.data?.mobile_email_relations
                          ).map((key) => ({
                            value: key,
                            label:
                              userOnboardGetData?.data?.mobile_email_relations[
                                key
                              ],
                          }))}
                        />
                        {formik.touched.mobile_relation &&
                        formik.errors.mobile_relation ? (
                          <div className="text-danger">
                            {formik.errors.mobile_relation}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label className="form-label">Gender*</label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <div className="d-flex align-items-center gap-2 flex-wrap">
                          {genderOptions.map(({ value, label }) => (
                            <div
                              className="form-check form-check-inline"
                              key={value}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                id={value}
                                value={value}
                                onChange={formik.handleChange}
                                checked={formik.values.gender === value}
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor={value}
                              >
                                {label}
                              </label>
                            </div>
                          ))}
                        </div>
                        {formik.touched.gender && formik.errors.gender ? (
                          <div className="text-danger">
                            {formik.errors.gender}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label className="form-label">Marital Status*</label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <div className="d-flex align-items-center gap-2 flex-wrap">
                          {maritalOptions.map(({ value, label }) => (
                            <div
                              className="form-check form-check-inline"
                              key={value}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="marital_status"
                                id={value}
                                value={value}
                                onChange={formik.handleChange}
                                checked={formik.values.marital_status === value}
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor={value}
                              >
                                {label}
                              </label>
                            </div>
                          ))}
                        </div>

                        {formik.touched.marital_status &&
                        formik.errors.marital_status ? (
                          <div className="text-danger">
                            {formik.errors.marital_status}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="father_name" className="form-label">
                          Father/Husband Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="father_name"
                          placeholder="Enter Parent/Guardian Name"
                          {...formik.getFieldProps("father_name")}
                        />
                        {formik.touched.father_name &&
                        formik.errors.father_name ? (
                          <div className="text-danger">
                            {formik.errors.father_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-3">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="occupation" className="form-label">
                          Occupation*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="occupation"
                          id=""
                          value={formik?.values?.occupation}
                          changeFunction={(value) =>
                            formik?.setFieldValue("occupation", value)
                          }
                          options={userOnboardGetData?.data?.occupations.map(
                            (occ) => ({
                              value: occ.occupation_code,
                              label: occ.occupation_desc,
                            })
                          )}
                        />
                        {formik.touched.occupation &&
                        formik.errors.occupation ? (
                          <div className="text-danger">
                            {formik.errors.occupation}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="pan" className="form-label">
                          PAN*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="pan"
                          placeholder="Enter PAN"
                          {...formik.getFieldProps("pan")}
                        />
                        {formik.touched.pan && formik.errors.pan ? (
                          <div className="text-danger">{formik.errors.pan}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="addr1" className="form-label">
                          Aadhar Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="adhaar_card"
                          placeholder="Enter Aadhar Number"
                          {...formik.getFieldProps("adhaar_card")}
                        />
                        {formik.touched.adhaar_card &&
                        formik.errors.adhaar_card ? (
                          <div className="text-danger">
                            {formik.errors.adhaar_card}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="addr1" className="form-label">
                          Address*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="addr1"
                          placeholder="Enter Address"
                          {...formik.getFieldProps("addr1")}
                        />
                        {formik.touched.addr1 && formik.errors.addr1 ? (
                          <div className="text-danger">
                            {formik.errors.addr1}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="pincode" className="form-label">
                          Pincode*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="pincode"
                          name="pincode"
                          placeholder="Enter Pincode"
                          onChange={(e) => {
                            formik.handleChange(e); // Handle Formik's change
                            callStateAndDistrict(e.target.value); // Call your custom function
                          }}
                          value={formik.values.pincode}
                        />
                        {formik.touched.pincode && formik.errors.pincode ? (
                          <div className="text-danger">
                            {formik.errors.pincode}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="city" className="form-label">
                          City*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          placeholder="Enter City"
                          {...formik.getFieldProps("city")}
                        />
                        {formik.touched.city && formik.errors.city ? (
                          <div className="text-danger">
                            {formik.errors.city}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor="state" className="form-label">
                          State*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="state"
                          id=""
                          value={formik?.values?.state}
                          changeFunction={(value) =>
                            formik?.setFieldValue("state", value)
                          }
                          options={userOnboardGetData?.data?.states.map(
                            (state) => ({
                              value: state.state_code,
                              label: state.state_name,
                            })
                          )}
                        />
                        {formik.touched.state && formik.errors.state ? (
                          <div className="text-danger">
                            {formik.errors.state}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-4 ps-xl-5 order-lg-2 order-1 mb-lg-0 mb-4">
                    <div className="info-box px-lg-4">
                      <div className="d-flex align-items-start">
                        <img
                          src={help_icon}
                          alt=""
                          className="me-3"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <div>
                          <h5 className="fw-semibold mb-3">
                            Help Us Know You Better
                          </h5>
                          <p className="mb-2">
                            Provide basic details to create your profile.
                            Accurate information allows us to tailor your
                            experience and help you achieve your financial
                            goals.
                          </p>
                          <p className="highlight_text">
                            You can edit these details later if needed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer form_card card_footer">
                <div className="">
                  <div className="d-flex justify-content-end gap-2">
                    <button
                      type="submit"
                      className="form_button next_btn"
                      //   onClick={handleNextClick}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
