import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MdMailOutline } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { supportDetail, supportReply } from "../../actions/adminAction";
import * as Yup from "yup";

const SupportReply = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ticketId = location.state?.ticketId;
  const messageId = location.state?.messageId;
  const [supportData, setSupportData] = useState(null);

  useEffect(() => {
    if (ticketId && messageId) {
      dispatch(supportDetail(messageId,ticketId))
        .then((response) => setSupportData(response))
        .catch((error) => toast.error("Failed to fetch support details"));
    }
  }, [dispatch, ticketId,messageId]);  

  const validationSchema = Yup.object({
    type: Yup.string().required("Message Type is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (ticketId) {
        const payload = {
            ticket_id: ticketId,
            subject: values.subject,
            type: values.type,
            message: values.message,
        };
        await dispatch(supportReply(payload)); // Edit API call
        toast.success("Reply sent successfully");
      }
      navigate(-1); // Navigate back after submission
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">Send Reply</h5>
          <div className="card investor-no-border">
            <div className="card redeam_bottom_card" style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}>
              <div className="purple-bg">
                <p>
                  <MdMailOutline className="purple-env" /> Customer's message: 
                  <span className="msg-span"> {supportData?.replyData[0]?.message_text || "Loading..."}</span>
                </p>
              </div>
            </div>
            <div className="card redeam_bottom_card">
              <Formik
                enableReinitialize
                initialValues={{
                    type: supportData?.replyData[0]?.type || "", 
                    subject: supportData?.replyData[0]?.subject || "", 
                    message: ""
                  }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                  <Form>
                    <div className="row form_body justify-content-center">
                      <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                        {/* Message Type */}
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-5 col-md-6 col-sm-5">
                            <label htmlFor="type" className="form-label">Message Type:*</label>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-7">
                            <Field as="select" name="type" className="form-select custom-select">
                              <option value="" disabled hidden>Select</option>
                              <option value="Reply">Reply</option>
                              <option value="Query">Query</option>
                              <option value="Complaint">Complaint</option>
                              <option value="Feedback">Feedback</option>
                              <option value="Follow Up">Follow Up</option>
                              <option value="Need Information">Need Information</option>
                              <option value="Need Suggestion">Need Suggestion</option>
                            </Field>
                            <ErrorMessage name="type" component="div" className="text-danger" />
                          </div>
                        </div>
                        {/* Subject */}
                        <div className="row align-items-center mb-4">
                          <div className="col-lg-5 col-md-6 col-sm-5">
                            <label htmlFor="subject" className="form-label">Subject:*</label>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-7">
                            <Field type="text" name="subject" className="form-control" placeholder="Enter Subject" />
                            <ErrorMessage name="subject" component="div" className="text-danger" />
                          </div>
                        </div>
                        {/* Message */}
                        <div className="row mb-4">
                          <div className="col-lg-5 col-md-6 col-sm-5">
                            <label htmlFor="message" className="form-label">Message:*</label>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-7">
                            <Field as="textarea" name="message" rows="5" className="form-control" placeholder="Enter Message" />
                            <ErrorMessage name="message" component="div" className="text-danger" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Submit and Cancel Buttons */}
                    <div className="card-footer form_card form_card_border">
                      <div className="d-flex justify-content-between gap-2">
                        <button type="button" className="form_button" onClick={() => navigate(-1)}>Cancel</button>
                        <button type="submit" className="form_button next_btn">Send</button>
                      </div>
                    </div>
                  </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportReply;