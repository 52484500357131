import '../styles/redeem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { GoalCard } from '../components/GoalCard';
import InvestButton from '../components/InvestButton';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { getGoalsList } from '../actions/goalsActions';
import Loader from '../loader/Loader-ui';
import {getLocalStorageUserData} from "../utils/userData";


const Goals = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(false); 
    const goals = useSelector((state) => state.goals?.goalList?.data?.goalData);
    const userData = getLocalStorageUserData();
    const [loading , setLoading] = useState(false);
    // useEffect(()=>{
    //   const payload ={
    //     id : userData?.id,
    //     perpage:"",
    //     pagenum:""
    //   }
    //   setLoading(true);
    //   dispatch(getGoalsList(payload)).then(()=>{
    //     setLoading(false)
    //   }).catch((error) =>{
    //     console.error("API call failed: ", error);
    //   })
    // },[userData?.id,dispatch]);

    useEffect(()=>{
      const fetchData = async() =>{
        const payload ={
              id : userData?.id,
              perpage:"",
              pagenum:""
          }
          try {
            setLoading(true);
            await Promise.all([
              dispatch(getGoalsList(payload))
            ]);
          } catch (error) {
            console.error("One or more API calls failed: ", error);
          }
          finally{
            setLoading(false);
          }
      };
      
      // if (isMounted.current) {
        fetchData();
      // } else {
      //   isMounted.current = true;
      // }
    },[dispatch])
    
    return (
        <div className="container-fluid page_background holding-switch-section">
          {loading && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            <div class="page-inner-content mb-3" >
                <div class="page_inner_content_box">
                    <div className='d-flex justify-content-between mb-3 align-items-center'>
                        <h5 className="main-heading-title mb-0">
                            Goals
                        </h5>
                        <InvestButton label='Add Goal' url='/goals/add' color='blue' className='' state={{ from: "goals" }}/>
                    </div>
                    <div class="row justify-content-center">
                    {goals?.length > 0 ? (
                        goals.map((goal) => (
                          <div className="col-sm-12 col-md-6 col-lg-6 px-lg-3 mb-4" key={goal?.id}>
                            <GoalCard goal={goal} />
                          </div>
                        ))
                      ) : (
                        <div className="no-goals-message">
                          No goals available.
                        </div>
                      )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Goals;
