import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Loader from "../../loader/Loader-ui";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/dashboard.css";
import { getLocalStorageUserData } from "../../utils/userData";
import DashboardTopHeaderSection from "../../components/Admin/AdminDashboardTopHeaderSection";
import InvestorTable from "../../components/Admin/AdminInvestorTable";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSummary } from "../../actions/adminAction";
// Functional component for the Home page
const AdminDashboard = () => {
  const dispatch = useDispatch(); // Initialize dispatch
  const [isLoading, setIsLoading] = useState(false);

  const fetchDashboardummary = async () => {
    try {
      setIsLoading(true);
      await dispatch(dashboardSummary());
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardummary();
  }, []);

  const { adminDashboardSummary = {} } = useSelector((state) => state?.admin);

  const dashboardSummaryFetch = adminDashboardSummary?.data || {};
  return (
    <>
    {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="container-fluid ">
        <DashboardTopHeaderSection data={dashboardSummaryFetch}/>
        <div className="page-inner-content dashboardSectionBox">
          <div className="page_inner_content_box fund_holding_section">
            <div className="d-flex justify-content-between align-itmes-center mb-4">
              <h5 className="page-title">Investors</h5>
              <Link to="/admin/investor" className="view_all_btn">
                View All
              </Link>
            </div>
            <div>
              <InvestorTable data={dashboardSummaryFetch}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
