import React, { useState, useRef, useEffect } from "react";
import help_icon from "../images/sidebar_icon/help_icon.svg";
import calendar_month from "../images/sidebar_icon/calendar_month.svg";
import "../styles/nominee.css"; // Import styles
import DatePickerWithIcon from "../components/DatePickerWithIcon";
import SelectDropDown from "../components/SelectDropDown";
import DatePickerInput from "../components/DatePickerInput";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageUserData } from "../utils/userData";
import {
  userOnboardingGetDetails,
  userOnboardingStoreData,
} from "../actions/usersActions";
import { toast } from "react-toastify";
import { convertDateToDDMMMYYYY } from "../utils/DateFormate";

const getOrdinalSuffix = (index) => {
  const j = index % 10,
    k = index % 100;

  if (j === 1 && k !== 11) return `${index}st`;
  if (j === 2 && k !== 12) return `${index}nd`;
  if (j === 3 && k !== 13) return `${index}rd`;
  return `${index}th`;
};

// Functional component for the Home page
const ManageNominees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const [noNominee, setNoNominee] = useState("N");
  const dateInputRef = useRef(null);
  const { userOnboardGetData, userOnboardStoreData, loading, error } =
    useSelector((state) => state.users);
  const formData = new FormData();
  // State to manage the nominees list
  const [nominees, setNominees] = useState([
    { name: "", relation: "", dob: "", percent: "", pan: "" },
  ]);
  const [nomineeCount, setNomineeCount] = useState(1);

  //   const handleSubmit = () => {
  //     navigate("/dashboard");
  //   };

  const handleBack = () => {
    navigate("/fatca-declartion");
  };
  useEffect(() => {
    const payload = {
      id: userData?.id,
      completed_wizard_step: "3",
    };
    dispatch(userOnboardingGetDetails(payload));
  }, []);

  // Function to handle input change for nominee fields
  //   const handleInputChange = (index, field, value) => {
  //     const updatedNominees = [...nominees];
  //     updatedNominees[index][field] = value;
  //     setNominees(updatedNominees);
  //   };

  const formik = useFormik({
    initialValues: {
      nominee1_name:
        userOnboardGetData?.data?.user_profile?.nominee1_name || "",
      nominee1_relation:
        userOnboardGetData?.data?.user_profile?.nominee1_relation || "",
      nominee1_dob:
        userOnboardGetData?.data?.user_profile?.nominee1_dob != null
          ? userOnboardGetData?.data?.user_profile?.nominee1_dob
          : "" || "",
      nominee1_percent:
        parseFloat(
          userOnboardGetData?.data?.user_profile?.nominee1_percent
        ).toFixed(2) || "",
      nom1_pan: userOnboardGetData?.data?.user_profile?.nom1_pan || "",
      nominee1_guard_name:
        userOnboardGetData?.data?.user_profile?.nominee1_guard_name || "",
      nominee1_guard_pan:
        userOnboardGetData?.data?.user_profile?.nominee1_guard_pan || "",
      nominee2_name:
        userOnboardGetData?.data?.user_profile?.nominee2_name || "",
      nominee2_relation:
        userOnboardGetData?.data?.user_profile?.nominee2_relation || "",
      ...(userOnboardGetData?.data?.user_profile?.nom2_pan
        ? { nom2_pan: userOnboardGetData?.data?.user_profile?.nom2_pan }
        : {}),
      nominee2_dob:
        userOnboardGetData?.data?.user_profile?.nominee2_dob != null
          ? userOnboardGetData?.data?.user_profile?.nominee2_dob
          : "" || "",
      nominee2_percent: userOnboardGetData?.data?.user_profile?.nominee2_percent
        ? parseFloat(
            userOnboardGetData?.data?.user_profile?.nominee2_percent
          ).toFixed(2)
        : 0 || "",
      nominee2_guard_name:
        userOnboardGetData?.data?.user_profile?.nominee2_guard_name || "",
      nominee2_guard_pan:
        userOnboardGetData?.data?.user_profile?.nominee2_guard_pan || "",
      nominee3_name:
        userOnboardGetData?.data?.user_profile?.nominee3_name || "",
      nominee3_relation:
        userOnboardGetData?.data?.user_profile?.nominee3_relation || "",
      nominee3_dob:
        userOnboardGetData?.data?.user_profile?.nominee3_dob != null
          ? userOnboardGetData?.data?.user_profile?.nominee3_dob
          : "" || "",
      ...(userOnboardGetData?.data?.user_profile?.nom3_pan
        ? { nom3_pan: userOnboardGetData?.data?.user_profile?.nom3_pan }
        : {}),
      nominee3_percent: userOnboardGetData?.data?.user_profile?.nominee3_percent
        ? parseFloat(
            userOnboardGetData?.data?.user_profile?.nominee3_percent
          ).toFixed(2)
        : 0 || "",
      nominee3_guard_name:
        userOnboardGetData?.data?.user_profile?.nominee3_guard_name || "",
      nominee3_guard_pan:
        userOnboardGetData?.data?.user_profile?.nominee3_guard_pan || "",
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      for (let i = 1; i <= nomineeCount; i++) {
        const nameKey = `nominee${i}_name`;
        const relationKey = `nominee${i}_relation`;
        const dobKey = `nominee${i}_dob`;
        const percentKey = `nominee${i}_percent`;
        const guardianNameKey = `nominee${i}_guard_name`;
        const guardianPanKey = `nominee${i}_guard_pan`;
        const panKey = `nom${i}_pan`;

        const currentDate = new Date();
        const dob = new Date(values[dobKey]);
        const age = currentDate.getFullYear() - dob.getFullYear();
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

        if (!values[nameKey]) {
          errors[nameKey] = "Name is required";
        } else if (!/^[A-Za-z\s]+$/.test(values[nameKey])) {
          errors[nameKey] = "Name must contain only alphabets";
        }
        if (age >= 18) {
          if (!values[panKey]) {
            errors[panKey] = "PAN Number is required";
          } else if (!panRegex.test(values[panKey])) {
            errors[panKey] = "Invalid PAN Details";
          }
        }
        if (!values[relationKey] || values[relationKey] === "Select")
          errors[relationKey] = "Relation is required";
        if (!values[dobKey]) errors[dobKey] = "Date of birth is required";
        if (
          !values[percentKey] ||
          values[percentKey] <= 0 ||
          values[percentKey] > 100
        ) {
          errors[percentKey] = "Percentage must be between 1 and 100";
        }

        if (age < 18) {
          if (!values[guardianNameKey]) {
            errors[guardianNameKey] = "Guardian Name is required";
          } else if (!/^[A-Za-z\s]+$/.test(values[guardianNameKey])) {
            errors[guardianNameKey] =
              "Guardian Name must contain only alphabets";
          }

          if (!values[guardianPanKey]) {
            errors[guardianPanKey] = "Guardian PAN is required";
          } else if (!panRegex.test(values[guardianPanKey])) {
            errors[guardianPanKey] = "Invalid Guardian PAN format";
          }
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      const formatDate = (dateString) => {
        const date = new Date(dateString);

        const options = {
          day: "2-digit",
          month: "short", // 'short' gives abbreviated month names like 'Jan', 'Feb', etc.
          year: "numeric",
        };

        return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
      };

      const payload = {
        ...values,
        nominee1_dob: values?.nominee1_dob
          ? convertDateToDDMMMYYYY(new Date(values?.nominee1_dob))
          : "",
        nominee2_dob: values?.nominee2_dob
          ? convertDateToDDMMMYYYY(new Date(values?.nominee1_dob))
          : "",
        nominee3_dob: values?.nominee3_dob
          ? convertDateToDDMMMYYYY(new Date(values?.nominee1_dob))
          : "",
        id: userData?.id,
        completed_wizard_step: 3,
      };

      const nom1 = values?.nominee1_percent
        ? parseFloat(values?.nominee1_percent)
        : 0;
      const nom2 = values?.nominee2_percent
        ? parseFloat(values?.nominee2_percent)
        : 0;
      const nom3 = values?.nominee3_percent
        ? parseFloat(values?.nominee3_percent)
        : 0;

      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      if (noNominee == "Y") {
        const noNomineePayload = {
          id: userData?.id,
          completed_wizard_step: 3,
          noNominee: "Y",
        };
        dispatch(userOnboardingStoreData(noNomineePayload, userData?.id));
      } else {
        if (nom1 + nom2 + nom3 == 100) {
          dispatch(userOnboardingStoreData(formData, userData?.id));
        } else {
          toast.error("Total percentage cannot exceed 100%");
        }
      }
    },
  });

  // Function to add a new nominee form (up to 3 nominees)
  const addNominee = () => {
    if (nomineeCount != 3) {
      //   setNominees([
      //     ...nominees,
      //     { name: "", relation: "", dob: "", percent: "", pan: "" },
      //   ]);
      setNomineeCount(nomineeCount + 1);
      formik.setValues({
        ...values,
        [`nominee${nomineeCount + 1}_name`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_name`
          ] || "",
        [`nominee${nomineeCount + 1}_relation`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_relation`
          ] || "",
        [`nominee${nomineeCount + 1}_dob`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_dob`
          ] != null
            ? userOnboardGetData?.data?.user_profile?.[
                `nominee${nomineeCount + 1}_dob`
              ]
            : "" || "",
        [`nominee${nomineeCount + 1}_percent`]: userOnboardGetData?.data
          ?.user_profile?.[`nominee${nomineeCount + 1}_percent`]
          ? parseFloat(
              userOnboardGetData?.data?.user_profile?.[
                `nominee${nomineeCount + 1}_percent`
              ]
            ).toFixed(2)
          : "" || "",
        [`nominee${nomineeCount + 1}_guard_name`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_guard_name`
          ] || "",
        [`nominee${nomineeCount + 1}_guard_pan`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_guard_pan`
          ] || "",
      });
    }
  };

  const handleIconClick = () => {
    if (dateInputRef.current) {
      if (dateInputRef.current.showPicker) {
        dateInputRef.current.showPicker(); // For modern browsers
      } else {
        dateInputRef.current.focus(); // Fallback for older browsers
      }
    }
  };

  const deleteNominee = (position) => {
    const updatedValues = { ...formik.values };

    // Remove the specific nominee
    for (let i = position; i < nomineeCount; i++) {
      updatedValues[`nominee${i}_name`] =
        updatedValues[`nominee${i + 1}_name`] || "";
      updatedValues[`nominee${i}_relation`] =
        updatedValues[`nominee${i + 1}_relation`] || "";
      updatedValues[`nominee${i}_dob`] =
        updatedValues[`nominee${i + 1}_dob`] || "";
      updatedValues[`nominee${i}_percent`] =
        updatedValues[`nominee${i + 1}_percent`] || "";
      updatedValues[`nominee${i}_guard_name`] =
        updatedValues[`nominee${i + 1}_guard_name`] || "";
      updatedValues[`nominee${i}_guard_pan`] =
        updatedValues[`nominee${i + 1}_guard_pan`] || "";
    }

    // Remove the last nominee (shifted forward)
    delete updatedValues[`nominee${nomineeCount}_name`];
    delete updatedValues[`nominee${nomineeCount}_relation`];
    delete updatedValues[`nominee${nomineeCount}_dob`];
    delete updatedValues[`nominee${nomineeCount}_percent`];
    delete updatedValues[`nominee${nomineeCount}_guard_name`];
    delete updatedValues[`nominee${nomineeCount}_guard_pan`];

    setNomineeCount(nomineeCount - 1);
    formik.setValues(updatedValues);
  };
  const { values, errors, handleChange, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (userOnboardGetData) {
      if (
        userOnboardGetData?.wizard_step == 4 ||
        userOnboardGetData?.wizard_step == 5
      ) {
        window.location.href = "/dashboard";
      } else if (3 <= userOnboardGetData?.wizard_step) {
      } else {
        if (userOnboardGetData?.wizard_step == 0) {
          window.location.href = "/personal-details";
        } else if (userOnboardGetData?.wizard_step == 1) {
          window.location.href = "/bank-details";
        } else if (userOnboardGetData?.wizard_step == 2) {
          window.location.href = "/fatca-declartion";
        }
      }
    }
  }, [userOnboardGetData]);
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="progress-container page_inner_content_box">
          <div className="step-info">
            <span>Step 4 of 4</span>
          </div>
          <h4 className="page-title mb-0">Manage Nominees</h4>
        </div>
        <div className="progress-bar">
          <div className="progress-filled" style={{ width: "100%" }}></div>
        </div>
        <div className="page_inner_content_box d-flex flex-wrap justify-content-between ">
          <form
            id="manage_nominee"
            className="step_form"
            onSubmit={handleSubmit}
          >
            <div className="card border-0">
              <div className="card-body form_card">
                <div className="row">
                  <div className="col-xl-7 col-lg-8 col-12 order-lg-1 order-2">
                    <div className="form-check mb-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="no_nominee"
                        checked={noNominee == "Y"}
                        onChange={(e) => {
                          if (e.target.checked == true) {
                            setNomineeCount(0);
                            setNoNominee("Y");
                          } else {
                            setNomineeCount(1);
                            setNoNominee("N");
                          }
                        }}
                      />
                      <label className="form-check-label" for="no_nominee">
                        I do not wish to add nominee.
                      </label>
                    </div>
                    {[...Array(nomineeCount)].map((_, index) => {
                      const i = index + 1;
                      const nameKey = `nominee${i}_name`;
                      const relationKey = `nominee${i}_relation`;
                      const dobKey = `nominee${i}_dob`;
                      const percentKey = `nominee${i}_percent`;
                      const panKey = `nom${i}_pan`;
                      const guardianNameKey = `nominee${i}_guard_name`;
                      const guardianPanKey = `nominee${i}_guard_pan`;

                      const dob = new Date(values[dobKey]);
                      const age = isNaN(dob.getTime())
                        ? null
                        : new Date().getFullYear() - dob.getFullYear();
                      return (
                        <div key={i}>
                          <div
                            className="mb-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h5 className="nominee_title">
                              {`${getOrdinalSuffix(i)} Nominee:`}
                            </h5>
                            <button
                              type="button"
                              className="delete_button text-decoration-none"
                              onClick={deleteNominee}
                              style={{ marginBottom: "0px" }}
                            >
                              <span>-</span> Delete nominee
                            </button>
                          </div>

                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label htmlFor={nameKey} className="form-label">
                                Nominee Name*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                id={nameKey}
                                name={nameKey}
                                placeholder="Enter Nominee Name"
                                value={values[nameKey]}
                                onChange={handleChange}
                              />
                              {errors[nameKey] && (
                                <div className="text-danger">
                                  {errors[nameKey]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={relationKey}
                                className="form-label"
                              >
                                Relation*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <SelectDropDown
                                name={relationKey}
                                id={relationKey}
                                value={values[relationKey]}
                                changeFunction={(value) =>
                                  setFieldValue(relationKey, value)
                                }
                                options={userOnboardGetData?.data?.relations?.map(
                                  (relation) => ({
                                    value: relation.value,
                                    label: relation.label,
                                  })
                                )}
                              />
                              {errors[relationKey] && (
                                <div className="text-danger">
                                  {errors[relationKey]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`dob-${index}`}
                                className="form-label"
                              >
                                Nominee Date of Birth*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <DatePickerInput
                                id={dobKey}
                                selectedDate={values[dobKey]}
                                maxDate={new Date().toISOString().split("T")[0]}
                                onChange={(date) => setFieldValue(dobKey, date)}
                              />
                              {errors[dobKey] && (
                                <div className="text-danger">
                                  {errors[dobKey]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row align-items-center mb-4">
                            <div className="col-lg-5 col-md-6">
                              <label
                                htmlFor={`percent-${index}`}
                                className="form-label"
                              >
                                Nominee %*
                              </label>
                            </div>
                            <div className="col-lg-7 col-md-6">
                              <input
                                type="number"
                                id={percentKey}
                                name={percentKey}
                                className="form-control"
                                placeholder="Enter Nominee %"
                                value={values[percentKey]}
                                onChange={handleChange}
                              />
                              {errors[percentKey] && (
                                <div className="text-danger">
                                  {errors[percentKey]}
                                </div>
                              )}
                            </div>
                          </div>
                          {age >= 18 || age === null ? (
                            <div className="row align-items-center mb-4">
                              <div className="col-lg-5 col-md-6">
                                <label htmlFor={panKey} className="form-label">
                                  Enter Pan Number*
                                </label>
                              </div>
                              <div className="col-lg-7 col-md-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id={panKey}
                                  name={panKey}
                                  placeholder="Enter Pan Number"
                                  value={values[panKey]}
                                  onChange={handleChange}
                                />
                                {errors[panKey] && (
                                  <div className="text-danger">
                                    {errors[panKey]}
                                  </div>
                                )}
                              </div>
                              {/* <div className="col-lg-5 col-md-6">
                                <label
                                  htmlFor={`pan-${index}`}
                                  className="form-label"
                                >
                                  Nominee PAN
                                </label>
                              </div>
                              <div className="col-lg-7 col-md-6">
                                <input
                                  type="file"
                                  id={panKey}
                                  name={panKey}
                                  className="form-control"
                                  onChange={(e) =>
                                    setFieldValue(panKey, e.target.files[0])
                                  }
                                  accept=".jpg,.jpeg,.png,.pdf"
                                />
                              </div> */}
                            </div>
                          ) : (
                            <>
                              {" "}
                              <div className="row align-items-center mb-4">
                                <div className="col-lg-5 col-md-6">
                                  <label
                                    htmlFor={`percent-${index}`}
                                    className="form-label"
                                  >
                                    Guardian Name*
                                  </label>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                  <input
                                    type="text"
                                    id={guardianNameKey}
                                    name={guardianNameKey}
                                    className="form-control"
                                    placeholder="Guardian Name"
                                    value={values[guardianNameKey]}
                                    onChange={handleChange}
                                  />
                                  {errors[guardianNameKey] && (
                                    <div className="text-danger">
                                      {errors[guardianNameKey]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="row align-items-center mb-4">
                                <div className="col-lg-5 col-md-6">
                                  <label
                                    htmlFor={`percent-${index}`}
                                    className="form-label"
                                  >
                                    Guardian Pan*
                                  </label>
                                </div>
                                <div className="col-lg-7 col-md-6">
                                  <input
                                    type="text"
                                    id={guardianPanKey}
                                    name={guardianPanKey}
                                    className="form-control"
                                    placeholder="Guardian Pan"
                                    value={values[guardianPanKey]}
                                    onChange={handleChange}
                                  />
                                  {errors[guardianPanKey] && (
                                    <div className="text-danger">
                                      {errors[guardianPanKey]}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                    {nomineeCount > 0 && (
                      <div className="text-start">
                        <button
                          type="button"
                          className="more_button text-decoration-none"
                          onClick={addNominee}
                        >
                          <span>+</span> Add More
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="col-xl-5 col-lg-4 ps-xl-5 order-lg-2 order-1 mb-lg-0 mb-4">
                    <div className="info-box px-lg-4">
                      <div className="d-flex align-items-start">
                        <img
                          src={help_icon}
                          alt=""
                          className="me-3"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <div>
                          <h5 className="fw-semibold mb-3">
                            Secure Your Legacy
                          </h5>
                          <p className="mb-2">
                            Designate nominees to ensure your investments are
                            transferred according to your wishes. This step
                            gives you peace of mind for the future.
                          </p>
                          <p className="highlight_text">
                            You can add a maximum of 3 Nominees.
                          </p>
                          <p className="highlight_text">
                            You can edit these details later if needed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer form_card card_footer">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="form_button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="form_button next_btn"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageNominees;
