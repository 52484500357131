import "../styles/auth.css"; // Import styles
import logo from "../images/icons/header-logo.svg";
import loginSideImage from "../images/login_page/login_image.svg";
import calendarImage from "../images/calendar_month.svg";
import eyeImage from "../images/login_page/eye-image.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userForgotPassword } from "../actions/usersActions";
import { useDispatch, useSelector } from "react-redux";

// Functional component for the Home page
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { usersForgotPassword, loading, error } = useSelector(
    (state) => state.users
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = (values) => {
    dispatch(userForgotPassword(values));
  };
  return (
    <section className="login" id="auth_section">
      <div className="container-fluid  login-container">
        <div className="row">
          <div className="col-lg-6 col-md-5 px-0">
            <div className="auth_left_content d-md-flex d-none card h-100 justify-content-start">
              <Link className="navbar-brand" to={`/`}>
                <img src={logo} alt="Logo" />
                {/* Replace 'logo.png' with your logo image path */}
                FUNDSWALA
              </Link>
              <div className="mb-xxl-5">
                <h3 className="geeting_text mb-lg-4">Welcome to Fundswala</h3>
                <h3 className="mb-4">Join Us Now ! </h3>
              </div>
              <img src={loginSideImage} alt="" className="login-image " />
            </div>
          </div>
          <div className="col-lg-6  col-md-7 px-0">
            <div className="right-side-container" style={{ height: "100vh" }}>
              <div className="d-md-none d-flex flex-column align-items-center">
                <Link
                  className="navbar-brand"
                  to={`/`}
                  style={{ color: "var(--primary-color)" }}
                >
                  <img src={logo} alt="Logo" />
                  {/* Replace 'logo.png' with your logo image path */}
                  FUNDSWALA
                </Link>
                <div className="my-3 text-center">
                  <h3
                    className="geeting_text "
                    style={{ color: "var(--primary-color)" }}
                  >
                    Welcome to Fundswala
                  </h3>
                  <h3
                    className="geeting_text mb-0"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Join Us Now !{" "}
                  </h3>
                </div>
                <a href="/" className="back-link  d-block d-md-none mb-3 mt-3">
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className="color me-2"
                    style={{ fontSize: "16px" }}
                  />
                  Back
                </a>
                <h5 className="form-title d-md-none d-block my-0" style={{}}>
                  Forgot Password?
                </h5>
              </div>

              {/* <!-- Tab Content --> */}
              <div className="tab-content mt-md-3 mt-4">
                <div
                  className="tab-pane fade show active"
                  id="signIn"
                  role="tabpanel"
                  aria-labelledby="signin-tab"
                >
                  <a href="/" className="back-link d-md-block d-none">
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className="color me-2"
                      style={{ fontSize: "16px" }}
                    />
                    Back
                  </a>
                  <h5 className="form-title d-md-block d-none">
                    Forgot Password?
                  </h5>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="form-group">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter Your Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message text-danger mt-1"
                          />
                        </div>

                        <div className="mt-md-5 mt-sm-4 mt-3 text-end">
                          <button
                            type="submit"
                            className="form_button next_btn"
                            disabled={isSubmitting}
                          >
                            Reset Password
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
