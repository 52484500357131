import "../styles/fundplan4.css";
import growthTree from "../images/icons/growth-tree.svg";
import featureimage from "../images/orders/logo.svg";

const FundPlanDesignStp = (props) => {
  return (
    <div className="card fund-card-five overflow-hiddden card-bg-white border-0">
      <div className="card-body position-relative">
        <div className="grid_content">
          {/* <div className="row"> */}
          <div className="hover_box">
            <p className="investment-details mb-2">FROM</p>
            <div className="fund-details-info d-flex ">
              <img
                src={growthTree}
                alt="Fund Logo"
                className="fund-logo me-lg-3 me-4 mb-sm-0 mb-2"
              />
              <div>
                {/* <a href={`/fund-detail/${props.title}`}> */}
                  <h6 className="fund-name-header cursor-pointer" style={{pointerEvents: 'none'}}>
                    {props.title}
                  </h6>
                {/* </a> */}
                <div className="d-flex flex-wrap gap-2">
                  <span
                    className={`badge custom-badge ${props.badgecolor}-badge`}
                  >
                    {props.badge?.toUpperCase()}
                  </span>
                  <span
                    className={`badge custom-badge ${props.badgecolor2}-badge`}
                  >
                    Equity
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="hover_box">
            <p className="investment-details  mb-2">TO</p>
            <div className="fund-details-info d-flex ">
              <img
                src={featureimage}
                alt="Fund Logo"
                className="fund-logo me-lg-3 me-4 mb-sm-0 mb-2"
              />
              <div>
                {/* <a href={`/fund-detail/${props.title2}`}> */}
                  <h6 className="fund-name-header cursor-pointer" style={{pointerEvents: 'none'}}>
                    {props.title2}
                  </h6>
                {/* </a> */}
                <div className="d-flex flex-wrap gap-2">
                  <span
                    className={`badge custom-badge ${props.badgecolor}-badge`}
                  >
                    {props.badge}
                  </span>
                  <span
                    className={`badge custom-badge ${props.badgecolor2}-badge`}
                  >
                    Equity
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
          {/* three dot image*/}
        </div>
        <div className="d-flex flex-wrap column-gap-xxl-5 column-gap-xl-5 column-gap-lg-5 column-gap-4  row-gap-lg-3  row-gap-3 mt-3  ">
          <p className="investment-details mb-0 ">
            Folio No. <span className="d-block">{props.folioNo}</span>
          </p>
          <p className="investment-details mb-0">
            SIP Amount <span className="d-block">{props.price}</span>
          </p>
          <p className="investment-details mb-0">
            From Date <span className="d-block">{props.datefrom}</span>
          </p>
          <p className="investment-details mb-0">
            To Date<span className="d-block">{props.dateto}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FundPlanDesignStp;
