import React from "react";
import { Link } from "react-router-dom";

const AdminAfterLoginFooter = () => {
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <footer className="dashboard_footer" id="dashboard_footer">
      <div>
        <ul className="nav d-flex flex-wrap justify-content-center align-items-center gap-lg-5 gap-md-4 gap-2">
          <li className="nav_item">
            <Link
              to="/termsconditions"
              className="nav_link"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Terms & Conditions
            </Link>
          </li>
          <li className="nav_item">
            <Link
              to="/privacy-policy"
              className="nav_link"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Privacy Policy
            </Link>
          </li>
          <li className="nav_item">
            <Link
              to="/FAQs"
              className="nav_link"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              FAQs
            </Link>
          </li>
          <li className="nav_item ms-lg-4">
            <Link
              to="/"
              className="nav_link"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              {/* © 2024 Fundswala.com */}
              © {getCurrentYear()} Fundswala.com
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default AdminAfterLoginFooter;
