import React from 'react';
import help_icon from '../images/sidebar_icon/help_icon.svg';
import PortFolioInvestmentHeader  from '../components/PortFolioInvestmentHeader';
import HoldingSectionSidebar from '../components/HoldingSectionSidebar';
// Functional component for the Home page
const PersonalDetails = () => {
    return (
        <div className="container-fluid page_background ">
        <PortFolioInvestmentHeader/>
            <div className="page-inner-content">
              <div className="page_inner_content_box fund_holding_section">
                    <div className="d-flex justify-content-between align-itmes-center mb-4 ">
                     <h5 className="main-heading-title">   
                     Holdings
                        </h5>
                        
                        </div>
                <div className="">
                    <HoldingSectionSidebar />
                </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalDetails;
