import React, { useEffect, useState } from "react";
import "../styles/mysip.css";
import bankimage from "../images/bankimage.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  nachSendOtp,
  nachVerifyOtp,
  purchaseCart,
} from "../actions/cardAction";
import { getLocalStorageUserData } from "../utils/userData";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
import PopupModel from "../components/PopupModel";
import "../styles/popup-custom.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BuyLumpsumModel = ({ isModalOpen, handleModalClose, amt }) => {
  const { addToCardData } = useSelector((state) => state.myCard);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = getLocalStorageUserData();
  const [paymentMode, setPaymentMode] = useState("OL");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpMessage, setOtpMessage] = useState("");
  const [bankDetail, setBankDetail] = useState(addToCardData?.data?.enach[0]);
  const [openWitch, setOpenWhich] = useState();
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const [disabled, setDisable] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [verifyBtnDisable, setVerifyBtnDisable] = useState(false);
  const [viewBtn, setViewBtn] = useState(false);
  const [verifyOtpError, setVerifyOTPError] = useState("");
  const handleModalCloseWithReset = (val) => {
    handleModalClose(); // Call the original modal close function
    setIsOtpVerified(false);
    setIsDisabled(false);
    setVerifyOTPError("");
    setOtp(["", "", "", "", "", ""]);
    setOtpMessage("");
    setPaymentMode("OL");
    setProceed("false");
    setBankDetail("");
    setOpenWhich("");
    setViewBtn(false);

    if (val != 1) {
      navigate("/my-profile");
    }
  };

  useEffect(() => {
    setBankDetail(addToCardData?.data?.enach[0]);
  }, [addToCardData]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/[^0-9]/.test(value) && value !== "") return;

    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
    if (!value && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // If the current input is empty and the user presses backspace, move focus to the previous input
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };
  const sendOtp = () => {
    setIsDisabled(true);
    const paload = {
      id: userData?.id,
    };
    dispatch(nachSendOtp(paload)).then((response) => {
      if (response?.status == true) {
        setViewBtn(true);
      }
    });
    setTimeout(() => {
      setIsDisabled(false);
    }, 120000); // 120000ms = 2 minutes
  };

  const closeModal = () => {
    setIsPopupVisible(false);
  };

  const verifyOtp = () => {
    setVerifyBtnDisable(true);
    if (otp.every((digit) => digit === "")) {
      setVerifyOTPError("OTP is required");
    } else if (otp.some((digit) => digit === "")) {
      setVerifyOTPError("OTP length must be 6 digits");
    } else {
      setVerifyOTPError("");
      const payload = {
        id: userData?.id,
        otp: otp.join(""),
      };
      dispatch(nachVerifyOtp(payload)).then((res) => {
        setVerifyBtnDisable(false);
        if (res?.status == false) {
          setOtpMessage(res?.message);
        } else {
          setViewBtn(false);
          setIsOtpVerified(true);
          setOtpMessage(res?.message);
        }
      });
    }
  };

  const callPurchaseApi = () => {
    console.log("disabled", disabled);

    setDisable(true);
    if (amt <= 0) {
      toast.error("Amount must be geter then 0");
    } else {
      setIsPopupVisible(true);
      const findBankId = addToCardData?.data?.bank_accounts?.find(
        (res) => res?.bank_code == bankDetail?.bank_code
      );

      if (paymentMode == "NACH") {
        if (bankDetail != "" && isOtpVerified) {
          const payload = {
            id: userData?.id,
            bank_account_id: findBankId?.id,
            no_netbanking: "1",
            no_upi: "1",
            mandate_id: bankDetail?.id,
            payment_mode: paymentMode,
            proceed: null,
            amt: amt,
          };
          dispatch(purchaseCart(payload))
            .then((res) => {
              setOpenWhich(res);
              setDisable(false);
              // handleModalCloseWithReset();
            })
            .catch((error) => {
              setDisable(false);
              // handleModalCloseWithReset();
            });
        }
      } else {
        // if (bankDetail != "") {
        const payload = {
          id: userData?.id,
          bank_account_id: addToCardData?.data?.bank_accounts[0].id,
          no_netbanking: "1",
          no_upi: "1",
          mandate_id: bankDetail?.mandate_id,
          payment_mode: paymentMode,
          proceed: null,
          amt: amt,
        };
        dispatch(purchaseCart(payload))
          .then((res) => {
            setOpenWhich(res);
            setDisable(false);
            // handleModalCloseWithReset();
          })
          .catch((error) => {
            setDisable(false);
            // handleModalCloseWithReset();
          });
        // }
      }
    }
  };

  useEffect(() => {
    if (openWitch) {
      if (openWitch?.data?.link && openWitch?.status == true) {
        window.location.href = openWitch?.data?.link;
      } else if (openWitch?.status == false) {
        toast.error(openWitch?.message, {
          autoClose: 2000, // Duration in milliseconds (5 seconds in this case)
        });
        // setTimeout(() => {
        //   window.location.href = "/sip-lumpsum-cart";
        // }, 1000);
      } else if (openWitch?.status == true) {
        toast.success(openWitch?.message);
        setTimeout(() => {
          navigate("/orders", { state: { orderId: openWitch?.data?.order } });
        }, 1000);
      }
    }
  }, [openWitch]);

  if (!isModalOpen) return null;
  return (
    <div className="container-fluid page_background ">
      <div className="mb-3">
        <div className="">
          <div className="modal_card">
            {/* Trigger Button */}
            {/* <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#netbankingModal"
            >
              Net Banking model
            </button> */}

            {console.log("addToCardData", addToCardData)}
            {/* Modal */}
            <div
              className={`modal fade modal_card_inner ${
                isModalOpen ? "show" : ""
              }`}
              id="netbankingModal"
              tabIndex="-1"
              aria-labelledby="netbankingModalLabel"
              aria-hidden={!isModalOpen}
            >
              {paymentMode == "NACH" ? (
                <>
                  <div className="modal-dialog modal-dialog-centered p-3 modal-dialog-scrollable my-cart-scrollbar">
                    <div className="modal-content cart-custom-popup">
                      {/* Modal Header */}
                      {!isOtpVerified ? (
                        <>
                          {" "}
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="netbankingModalLabel"
                            >
                              Select a Payment Methoddd
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                            ></button>
                          </div>
                          {/* Modal Body */}
                          <div className="modal-body p-0 my-cart-custom-no-border2">
                            {/* eNACH Section */}
                            <div className="modal_body my-cart-custom-no-border2">
                              <div className=" eNach_middle_part gap-3">
                                <div className="form-check ps-0 d-flex align-items-center">
                                  <input
                                    className="form-input sip_input"
                                    type="radio"
                                    name="payment_mode"
                                    id="Netbanking"
                                    value={paymentMode}
                                    checked={paymentMode == "OL"}
                                    onChange={() => {
                                      setPaymentMode("OL");
                                    }}
                                  />
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="Netbanking"
                                  >
                                    Netbanking
                                  </label>
                                </div>
                                <div className="form-check ps-0 d-flex align-items-center">
                                  <input
                                    className="form-input sip_input"
                                    type="radio"
                                    name="payment_mode"
                                    id="upi"
                                    value={paymentMode}
                                    checked={paymentMode == "UPI"}
                                    onChange={() => {
                                      setPaymentMode("UPI");
                                    }}
                                  />
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="upi"
                                  >
                                    UPI
                                  </label>
                                </div>
                                <div className="form-check ps-0 d-flex align-items-center">
                                  <input
                                    className="form-input sip_input"
                                    type="radio"
                                    name="payment_mode"
                                    id="eNACH"
                                    value={paymentMode}
                                    checked={paymentMode == "NACH"}
                                    onChange={() => {
                                      setPaymentMode("NACH");
                                    }}
                                  />
                                  <label
                                    className="form-label mb-0"
                                    htmlFor="eNACH"
                                  >
                                    eNACH
                                  </label>
                                </div>
                              </div>

                              <div className="mt-3 table-responsive">
                                <table
                                  className="table sip_modaltable mb-0"
                                  style={{
                                    borderCollapse: "separate",
                                    borderSpacing: "0px 15px",
                                  }}
                                >
                                  {addToCardData?.data?.enach?.length > 0 && (
                                    <tr>
                                      <th></th>
                                      <th className="fw-semibold">Bank</th>
                                      <th className="fw-semibold">
                                        Account Number
                                      </th>
                                      <th className="fw-semibold">
                                        Limit (Rs.)
                                      </th>
                                    </tr>
                                  )}
                                  {paymentMode == "NACH" ? (
                                    <>
                                      {addToCardData?.data?.enach?.length >
                                      0 ? (
                                        addToCardData?.data?.enach?.map(
                                          (res) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <input
                                                    className="form-input sip_input"
                                                    type="radio"
                                                    name="bankId"
                                                    id="bankId"
                                                    onClick={() => {
                                                      setBankDetail(res);
                                                    }}
                                                    checked={
                                                      parseInt(res?.id) ==
                                                      parseInt(bankDetail?.id)
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <img
                                                    src={res?.icon}
                                                    className="bank_image"
                                                  />
                                                  {res?.bank_name}
                                                </td>
                                                <td>{res?.account_no}</td>
                                                <td>{res?.amount}</td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : paymentMode == "UPI" ||
                                    paymentMode == "OL" ? (
                                    <>
                                      {addToCardData?.data?.bank_accounts
                                        ?.length > 0 ? (
                                        addToCardData?.data?.bank_accounts?.map(
                                          (res) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <input
                                                    className="form-input sip_input"
                                                    type="radio"
                                                    name="paymentMethod"
                                                    id="payment"
                                                  />
                                                </td>
                                                <td>
                                                  <img
                                                    src={res?.icon}
                                                    className="bank_image"
                                                  />
                                                  {res?.bank_name}
                                                </td>
                                                <td>{res?.acc_no}</td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <p>No record found</p>
                                      )}
                                    </>
                                  ) : (
                                    <p>No record found</p>
                                  )}
                                </table>
                                {bankDetail == "" && proceed && (
                                  <p style={{ color: "red" }}>
                                    Please, select bank
                                  </p>
                                )}
                              </div>
                              {addToCardData?.data?.enach?.length > 0 ? (
                                <div className="mt-xl-4 modal_body text-center">
                                  <label className="form_label">
                                    {/* Verify through OTP * */}
                                    <a
                                      onClick={() => {
                                        if (!isDisabled) {
                                          sendOtp();
                                        }
                                      }}
                                      className="ms-md-4 ms-3 "
                                    >
                                      Send OTP to verify
                                    </a>
                                  </label>
                                  <div className="d-flex justify-content-center mt-md-4 mt-2">
                                    {otp?.map((value, index) => (
                                      <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        className="otp-input text-center mx-1"
                                        value={value}
                                        onChange={(e) =>
                                          handleOtpChange(e, index)
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, index)
                                        }
                                        id={`otp-input-${index}`}
                                        autoFocus={index === 0}
                                      />
                                    ))}
                                  </div>
                                  {verifyOtpError && (
                                    <p style={{ color: "red" }}>
                                      {verifyOtpError}
                                    </p>
                                  )}
                                  {otpMessage && (
                                    <p style={{ color: "red" }}>{otpMessage}</p>
                                  )}
                                  <div className="d-flex justify-content-center mt-3">
                                    {viewBtn && (
                                      <button
                                        className="verify_button"
                                        // disabled={verifyBtnDisable}
                                        onClick={() => {
                                          verifyOtp();
                                        }}
                                      >
                                        Verify OTP
                                      </button>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div style={{ color: "red" }}>
                                  You have not registered for eNACH, please
                                  register.
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="modal_body text-center">
                          <div className="mt-3 table-responsive">
                            <table
                              className="table sip_modaltable mb-0"
                              style={{
                                borderCollapse: "separate",
                                borderSpacing: "0px 15px",
                              }}
                            >
                              <tr>
                                <th></th>
                                <th className="fw-semibold">Bank</th>
                                <th className="fw-semibold">Account Number</th>
                                <th>Limit (Rs.)</th>
                              </tr>
                              {paymentMode == "NACH" ? (
                                <>
                                  {addToCardData?.data?.enach?.length > 0 ? (
                                    addToCardData?.data?.enach?.map((res) => {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              className="form-input sip_input"
                                              type="radio"
                                              name="bankId"
                                              id="bankId"
                                              onClick={() => {
                                                setBankDetail(res);
                                              }}
                                              checked={
                                                res?.id == bankDetail?.id
                                              }
                                            />
                                          </td>
                                          <td>
                                            <img
                                              src={res?.icon}
                                              className="bank_image"
                                            />
                                            {res?.bank_name}
                                          </td>
                                          <td>{res?.account_no}</td>
                                          <td>{res?.amount}</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <p>No record found</p>
                                  )}
                                </>
                              ) : paymentMode == "UPI" ||
                                paymentMode == "OL" ? (
                                <>
                                  {addToCardData?.data?.bank_accounts?.length >
                                  0 ? (
                                    addToCardData?.data?.bank_accounts?.map(
                                      (res) => {
                                        return (
                                          <tr>
                                            <td>
                                              <input
                                                className="form-input sip_input"
                                                type="radio"
                                                name="bankId"
                                                id="bankId"
                                                onClick={() => {
                                                  setBankDetail(res);
                                                }}
                                                checked={
                                                  res?.id == bankDetail?.id
                                                }
                                              />
                                            </td>
                                            <td>
                                              <img
                                                src={res?.icon}
                                                className="bank_image"
                                              />
                                              {res?.bank_name}
                                            </td>
                                            <td>{res?.acc_no}</td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <p>No record found</p>
                                  )}
                                </>
                              ) : (
                                <p>No record found</p>
                              )}
                            </table>
                            {bankDetail == "" &&
                              proceed &&
                              addToCardData?.data?.enach?.length > 0 && (
                                <p style={{ color: "red" }}>
                                  Please, select bank
                                </p>
                              )}
                          </div>
                          {/* OTP Verified Section */}
                          <div className="d-flex justify-content-center p-lg-2">
                            <button className="verified_button">
                              {otpMessage}
                            </button>
                          </div>
                        </div>
                      )}

                      {/* Modal Footer */}
                      <div className="modal-footer form_card border-top-0 custom-model-footer">
                        <div className="d-flex justify-content-between gap-3">
                          <button
                            type="submit"
                            className="form_button "
                            onClick={() => handleModalCloseWithReset(1)}
                          >
                            Close
                          </button>
                          {paymentMode == "NACH" &&
                          addToCardData?.data?.enach?.length <= 0 ? (
                            <button
                              className="form_button next_btn"
                              onClick={() => {
                                navigate("/my-profile");
                              }}
                            >
                              OK
                            </button>
                          ) : (
                            <>
                              {console.log(
                                "xsax",
                                paymentMode == "NACH" && isOtpVerified
                              )}
                              <button
                                type="submit"
                                disabled={
                                  paymentMode == "NACH" && isOtpVerified
                                    ? false
                                    : true
                                }
                                // disabled={
                                //   paymentMode == "NACH" && isOtpVerified
                                //     ? false
                                //     : bankDetail != ""
                                //     ? false
                                //     : true
                                // }
                                className="form_button next_btn"
                                onClick={() => {
                                  if (paymentMode == "NACH" && isOtpVerified) {
                                    setDisable(false);
                                  } else {
                                    setDisable(false);
                                  }
                                  setProceed(true);
                                  callPurchaseApi();
                                }}
                              >
                                Proceed
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="modal-dialog modal-dialog-centered p-3">
                    <div className="modal-content">
                      <>
                        <div className="modal-header">
                          <h5 className="modal-title" id="netbankingModalLabel">
                            Select a Payment Method
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                          ></button>
                        </div>

                        {/* Modal Body */}
                        <div className="modal-body p-0">
                          {/* eNACH Section */}
                          <div className="modal_body">
                            <div className=" eNach_middle_part gap-3">
                              <div className="form-check ps-0 d-flex align-items-center">
                                <input
                                  className="form-input sip_input"
                                  type="radio"
                                  name="payment_mode"
                                  id="Netbanking"
                                  value={paymentMode}
                                  checked={paymentMode == "OL"}
                                  onChange={() => {
                                    setPaymentMode("OL");
                                  }}
                                />
                                <label
                                  className="form-label mb-0"
                                  htmlFor="Netbanking"
                                >
                                  Netbanking
                                </label>
                              </div>
                              <div className="form-check ps-0 d-flex align-items-center">
                                <input
                                  className="form-input sip_input"
                                  type="radio"
                                  name="payment_mode"
                                  id="upi"
                                  value={paymentMode}
                                  checked={paymentMode == "UPI"}
                                  onChange={() => {
                                    setPaymentMode("UPI");
                                  }}
                                />
                                <label
                                  className="form-label mb-0"
                                  htmlFor="upi"
                                >
                                  UPI
                                </label>
                              </div>
                              <div className="form-check ps-0 d-flex align-items-center">
                                <input
                                  className="form-input sip_input"
                                  type="radio"
                                  name="payment_mode"
                                  id="eNACH"
                                  value={paymentMode}
                                  checked={paymentMode == "NACH"}
                                  onChange={() => {
                                    setPaymentMode("NACH");
                                  }}
                                />
                                <label
                                  className="form-label mb-0"
                                  htmlFor="eNACH"
                                >
                                  eNACH
                                </label>
                              </div>
                            </div>
                            {/* 
                        <div className="mt-3 table-responsive">
                          <table
                            className="table sip_modaltable mb-0"
                            style={{
                              borderCollapse: "separate",
                              borderSpacing: "0px 15px",
                            }}
                          >
                            <tr>
                              <th></th>
                              <th className="fw-semibold">Bank</th>
                              <th className="fw-semibold">Account Number</th>
                            </tr>
                            {paymentMode == "NACH" ? (
                              <>
                                {addToCardData?.data?.enach?.length > 0 ? (
                                  addToCardData?.data?.enach?.map((res) => {
                                    return (
                                      <tr>
                                        <td>
                                          <input
                                            className="form-input sip_input"
                                            type="radio"
                                            name="bankId"
                                            id="bankId"
                                            onClick={() => {
                                              setBankDetail(res);
                                            }}
                                            checked={res?.id == bankDetail?.id}
                                          />
                                        </td>
                                        <td>
                                          <img
                                            src={res?.icon}
                                            className="bank_image"
                                          />
                                          {res?.bank_name}
                                        </td>
                                        <td>{res?.account_no}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <p>No record found</p>
                                )}
                              </>
                            ) : paymentMode == "UPI" || paymentMode == "OL" ? (
                              <>
                                {addToCardData?.data?.bank_accounts?.length >
                                  0 ? (
                                  addToCardData?.data?.bank_accounts?.map(
                                    (res) => {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              className="form-input sip_input"
                                              type="radio"
                                              name="bankId"
                                              id="bankId"
                                              onClick={() => {
                                                setBankDetail(res);
                                              }}
                                              checked={
                                                res?.id == bankDetail?.id
                                              }
                                            />
                                          </td>
                                          <td>
                                            <img
                                              src={res?.icon}
                                              className="bank_image"
                                            />
                                            {res?.bank_name}
                                          </td>
                                          <td>{res?.acc_no}</td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <p>No record found</p>
                                )}
                              </>
                            ) : (
                              <p>No record found</p>
                            )}
                          </table>
                          {bankDetail == "" && proceed && (
                            <p style={{ color: "red" }}>Please, select bank</p>
                          )}
                        </div> */}
                          </div>
                        </div>

                        {/* Modal Footer */}
                        <div className="modal-footer form_card border-top-0">
                          <div className="d-flex justify-content-between gap-3">
                            <button
                              type="submit"
                              className="form_button "
                              onClick={() => handleModalCloseWithReset(1)} // Close modal on click
                            >
                              Close
                            </button>

                            <button
                              type="submit"
                              disabled={
                                paymentMode == "UPI" || paymentMode == "OL"
                                  ? false
                                  : true
                              }
                              className="form_button next_btn"
                              onClick={() => {
                                setProceed(true);
                                callPurchaseApi();
                              }}
                            >
                              Proceed
                            </button>
                          </div>
                        </div>
                      </>

                      {/* Modal Header */}
                    </div>
                  </div>
                  {/* // ) : (
                  //   <>
                  //     <p style={{ color: "red" }}>
                  //       You have not registered for eNACH, please register.
                  //     </p>
                  //   </>
                  // )} */}
                </>
              )}
            </div>
          </div>
          {/* {isPopupVisible && openWitch?.status == true && (
            <PopupModel
              id="alertModel"
              title="Lorem Ipsum"
              content={
                openWitch?.message == "Online payment link"
                  ? "Online payment link received for the next process click on OK"
                  : openWitch?.message
              }
              actionPopup={actionPopup}
              image={successlogo}
              closeModal={closeModal}
              btnColor={"btn-success"}
              type="addtocart"
              customClass="cart-custom-popup"
              custombodyclass="custom-popup-content"
            />
          )} */}
          {/* {isPopupVisible && openWitch?.status == false && (
            <PopupModel
              id="alertModel"
              title="Lorem Ipsum"
              content={openWitch?.message}
              image={cancel}
              closeModal={closeModal}
              actionPopup={actionPopup}
              btnColor={"btn-success"}
              type="addtocart"
              customClass="cart-custom-popup"
              custombodyclass="custom-popup-content"
            />
          )} */}
          {/* remove modal data here */}
        </div>
      </div>
    </div>
  );
};

export default BuyLumpsumModel;
