export const convertDateToDDMMYYYY = (dateStr) => {
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  // Split the input date string
  const [day, month, year] = dateStr.split("-");

  // Convert the month abbreviation to a number
  const monthNumber = months[month];

  // Return the date in dd/mm/yyyy format
  return `${day}/${monthNumber}/${year}`;
};

export const convertDateToDDMMMYYYY = (date) => {
  const dates = new Date(date);
  const day = dates.getDate().toString().padStart(2, "0"); // Get day and ensure 2 digits
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dates.getMonth()]; // Get month as uppercase 3-letter abbreviation
  const year = dates.getFullYear(); // Get full year

  console.log(`${day}-${month}-${year}`);
  return `${day}-${month}-${year}`;
};

export const convertDateToDDMMYYYYStartWithYear = (dateStr) => {
  // Split the input date string
  const [year, month, day] = dateStr?.split("-");
  // Return the date in dd/mm/yyyy format
  return `${day}/${month}/${year}`;
};

export const currentDate = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const now = new Date();

  const day = now.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
  const month = months[now.getMonth()]; // Get the abbreviated month name
  const year = now.getFullYear();

  return `${day}-${month}-${year}`;
};
