import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../styles/model.css";
import view from "../../images/view.svg";
import reply from "../../images/reply.svg";
import close from "../../images/close.svg";
import danger from "../../images/danger.svg";
import "../../styles/ticketTable.css";
import { useNavigate } from "react-router-dom";
import "../../styles/adminviewticket.css";
import AdminViewTicket from "../../pages/Admin/AdminViewTicket";
import { useDispatch, useSelector } from "react-redux";
import { supportList, supportCloseTicket } from "../../actions/adminAction";
import Pagination from "../../components/Pagination";
import Loader from "../../loader/Loader-ui";
import { toast } from "react-toastify";

const AdminTicketTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [selectedTicketId, setSelectedTicketId] = useState(null); // Store selected ticket ID

  useEffect(() => {
    dispatch(
      supportList({
        page_num: currentPage,
        per_page: "10",
      })
    );
  }, [dispatch, currentPage]);

  const { supportListData = {} } = useSelector((state) => state?.admin);

  const data = supportListData?.data?.ticketList || [];
  const totalPages = supportListData?.data?.pagination?.total_pages || 1;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Function to handle 'View' button click
  const handleViewClick = (ticketId, messageId) => {
    navigate("/admin/view-ticket", {
      state: { ticketId: ticketId, messageId: messageId },
    });
  };

  const handleReplyClick = (ticketId, messageId) => {
    navigate("/admin/support-reply", {
      state: { ticketId: ticketId, messageId: messageId },
    }); // Navigate to the reply page
  };

  const handleCloseClick = (ticketId) => {
    dispatch(supportCloseTicket(ticketId)); // Dispatch action to delete FAQ
  };

  const handleClose = () => setShow(false);
  const handleShow = (ticketId) => {
    setSelectedTicketId(ticketId); // Set the ticket ID when opening modal
    setShow(true);
  };

  const handleConfirmClose = async () => {
    try {
      if (selectedTicketId) {
        await dispatch(supportCloseTicket(selectedTicketId)); // Edit API call
        toast.success("Ticket Closed successfully!");
        await dispatch(
          supportList({
            page_num: currentPage,
            per_page: "10",
          })
        );
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    }
    setShow(false); // Close modal after dispatch
  };

  return (
    <>
      <div className="table-responsive">
        {isLoading && (
          <div className="loader-overlay">
            <Loader />
          </div>
        )}
        <table className="table tickets_Messages_table">
          <thead>
            <tr className="ticket_table_head">
              <th scope="col">Ticket No.</th>
              <th scope="col">Message</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((ticket) =>
                ticket?.message.map((message, index) => (
                  <tr key={message.id} className="ticket_table">
                    {index === 0 && (
                      <td rowSpan={ticket.message.length}>{ticket.id}</td>
                    )}
                    <td>
                      <div
                        style={{
                          marginBottom: "8px",
                          borderBottom: "1px solid #ddd",
                          paddingBottom: "5px",
                        }}
                      >
                        <div>
                          <strong>Type:</strong> {message.type}
                        </div>
                        <div>
                          <strong>Subject:</strong> {message.subject}
                        </div>
                        <div>
                          <strong>Sender:</strong> {message.sender}
                        </div>
                        {ticket.status === 1 && (
                          <>
                            <Button
                              variant="success"
                              className="me-2"
                              style={{
                                backgroundColor: "#AEFFF1",
                                color: "#0B9E84",
                                border: "none",
                              }}
                              onClick={() =>
                                handleViewClick(ticket.id, message?.id)
                              }
                            >
                              <img
                                src={view}
                                alt="View Icon"
                                style={{ marginRight: "8px" }}
                              />
                              View
                            </Button>
                            <Button
                              variant="primary"
                              className="me-2"
                              style={{
                                backgroundColor: "#DFDBFF",
                                color: "#5A4AD5",
                                border: "none",
                              }}
                              onClick={() =>
                                handleReplyClick(ticket.id, message?.id)
                              }
                            >
                              <img
                                src={reply}
                                alt="Reply Icon"
                                style={{ marginRight: "8px" }}
                              />
                              Reply
                            </Button>
                          </>
                        )}
                      </div>
                    </td>
                    {index === 0 && (
                      <td rowSpan={ticket.message.length}>
                        {ticket.status === 1 ? "Open" : "Close"}
                      </td>
                    )}
                    {index === 0 && (
                      <td rowSpan={ticket.message.length}>
                        {ticket.status === 1 && (
                          <Button
                            variant="danger"
                            onClick={() => handleShow(ticket.id)}
                            style={{
                              backgroundColor: "#E0EBFB",
                              color: "#3583FF",
                              border: "none",
                            }}
                          >
                            <img
                              src={close}
                              alt="Close Icon"
                              style={{ marginRight: "8px" }}
                            />
                            Close Ticket
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              )
            ) : (
              <tr style={{ height: "200px" }}>
                <td colSpan="4" className="no-data-message">
                  No Ticket found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pt-5">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="custom-modal justify-content-center"
      >
        <div className=" position-relative">
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0"
            onClick={handleClose}
            aria-label="Close"
            style={{
              fontSize: "1.1rem",
              cursor: "pointer",
              opacity: 0.8, // Ensure full visibility
            }}
          ></button>
        </div>
        <div className="modal-title mt-4">
          <img src={danger} alt="Danger Icon" className="modal-icon" />
          <h4 className="modal-text">
            Are you sure you want to close the ticket?
          </h4>
        </div>
        <div className="d-flex justify-content-spacearound mt-4">
          <button onClick={handleClose} className="form_button ">
            Cancel
          </button>
          <button
            type="button"
            className="form_button next_btn"
            onClick={handleConfirmClose} // Call function to close ticket
          >
            OK
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminTicketTable;
