import "../styles/HoldingSection.css";
import { Link } from "react-router-dom";
import FundPlanDesignSip from "./FundPlanDesignSip";
import { useState } from "react";

const HoldingSectionSip = ({ data, isDashboard }) => {
  const [activeId, setActiveId] = useState(null);
  const holdingsArray = Object.entries(data?.current_holdings || {});
  // Limit the data to 2 items if on the dashboard
  const limitedHoldingsArray = isDashboard
    ? holdingsArray.slice(0, 2)
    : holdingsArray;

    const handleAccordionToggle = (id) => {
      setActiveId((prevActiveId) => (prevActiveId === id ? null : id)); // Update activeId
    };

  return (
    <div>
      {limitedHoldingsArray.length > 0 ? (
        limitedHoldingsArray.map(([key, item], index) => (
          
          <div key={index} className="mb-3">
            <FundPlanDesignSip
            image={item?.icon}
              bookmark={true}
              plus={true}
              id={key}
              title={item?.product_long_name || "-"} 
              holdings_data={item?.holdings_data}
              allow_pause_sip={item?.allow_pause_sip}
              auto_trxn_no={item?.auto_trxn_no}
              ceased_status={item?.ceased_status}
              pause_status={item?.pause_status}
              cease_reason_codes={item?.cease_reason_codes}
              amc_product_id={item?.amc_product_id}
              activeId={activeId} 
              onToggleAccordion={handleAccordionToggle}
            />
          </div>
        ))
      ) : (
        <p>No SIP data available</p>
      )}
    </div>
  );
};

export default HoldingSectionSip;
