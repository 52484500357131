import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MyProfileAccordian from "../../components/Admin/ViewInvestorAccordian";
import { InvestorDetail } from "../../actions/adminAction";
import { useDispatch, useSelector } from "react-redux";

// Functional component for the funddetails page
const MyProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const id = location.state;

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(InvestorDetail(id)).finally(() => setIsLoading(false));
    }
  }, [id, dispatch]);

  const { adminInvestorDetail = {} } = useSelector((state) => state?.admin);
  const data = adminInvestorDetail?.profile || {};
  
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content fund_section mb-3">
        <div className="">
          <div className="card overflow-hiddden border-0">
            <div className="card-body page_inner_content_box">
              <MyProfileAccordian data={data}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
