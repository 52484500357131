import React, { useEffect } from 'react';
import '../styles/Faqs.css';
import FaqAccordionItem from '../components/FaqAccordionItem';
import { useDispatch, useSelector } from 'react-redux';
import { getFaqs } from '../actions/footerActions';
import {getLocalStorageUserData} from "../utils/userData";

const Faq = () => {
    
    const dispatch = useDispatch();
    const faqs = useSelector((state) => state?.footer?.faqsData?.data?.faqs || []);
    const userData = getLocalStorageUserData();
    
    useEffect(()=>{
        const payload ={
            id:userData?.id
        }
        dispatch(getFaqs(payload));
    },[dispatch,userData?.id]);

    return (
        <div className="container-fluid page_background ">
            <div className="page-inner-content mb-3" >
                <div className="page_inner_content_box">
                    <h5 className="mb-4 main-heading-title">
                        Frequently Asked Questions
                    </h5>
                    <div className="faq_card">
                        <div className="row  justify-content-center">
                            <div className="col-xl-8 col-md-10">

                            <div className="accordion faq_accordian my-md-3 my-2" id="accordionExample">
                                {faqs.map((faq, index) => (
                                    <FaqAccordionItem
                                    key={faq.id}
                                    id={`faq-${index}`} // Unique ID for each FAQ item
                                    title={faq.question}
                                    content={faq.answer}
                                    />
                                ))}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Faq;