import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import bell from "../../images/sidebar_icon/bell.svg";
import cart from "../../images/sidebar_icon/cart.svg";
import bookmark from "../../images/sidebar_icon/watchlist.svg";
import moon from "../../images/sidebar_icon/moon.svg";
import avatar from "../../images/sidebar_icon/avatar.svg";
import { toast } from "react-toastify";
import { getLocalStorageUserData } from "../../utils/userData.js";
import { useDispatch, useSelector } from "react-redux";
import { getCards } from "../../actions/cardAction.js";
import {
  getNotificationList,
  markAsRead,
} from "../../actions/notificationAction.js";
import { getWatchList } from "../../actions/watchListActions.js";
import NotificationPanel from "../Notification.js";

const AdminAfterLoginHeader = (props) => {
  const controller = new AbortController();
  const isMounted = useRef(false);
  const userData = getLocalStorageUserData();
  const carddata = useSelector((state) => state.myCard);
  const watchList = useSelector(
    (state) => state.watchList?.getWatchList?.data?.watchlist
  );
  const [cartCount, setCartCount] = useState(0);
  const [watchListCount, setWatchListCount] = useState(0);

  // Notification api call
  const { notificationList = {} } = useSelector((state) => state.notification);
  const notificationData = notificationList?.data?.notifications || [];

  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const notificationRef = useRef(null);

  const dispatch = useDispatch();
  // const logoutUser = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userId");
  //   localStorage.removeItem("userData");
  //   toast.success("Logout successfully!");
  //   setTimeout(() => {
  //     window.location.href = "/login";
  //   }, 1000);
  // };
  const logoutUser = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("userData");
      
      toast.success("Logout successfully!");
  
      setTimeout(() => {
        // Redirect to login page
        window.location.href = "/login";
  
        // Clear browser history to prevent back navigation
        setTimeout(() => {
          window.history.pushState(null, "", "/login");
          window.history.pushState(null, "", "/login");
        }, 500);
      }, 1000);
  };
  // useEffect(() => {
  //   dispatch(
  //     getCards({
  //       id: userData?.id,
  //     })
  //   );
  // }, []);

  //Notifications api call
  // useEffect(() => {
  //   if (userData?.id) {
  //     const payload = { id: userData?.id };
  //     dispatch(getNotificationList(payload));
  //   }
  // }, [dispatch, userData?.id]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const payload = { id: userData?.id };
  //     try {
  //       if (userData?.id) {
  //         await Promise.all([dispatch(getNotificationList(payload))]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   // if (isMounted.current) {
  //   fetchData();
  //   // } else {
  //   //   isMounted.current = true;
  //   // }
  //   return () => {
  //     controller.abort(); // Abort the ongoing request on cleanup
  //   };
  // }, [userData?.id, dispatch]);

  // useEffect(() => {
  //   setCartCount(carddata?.addToCardData?.data?.products_in_cart?.length);
  // }, [carddata?.addToCardData?.data?.products_in_cart]);
  // useEffect(() => {
  //   setWatchListCount(watchList?.length);
  // }, [watchList?.length]);

  // const toggleNotification = () => {
  //   setNotificationOpen(!isNotificationOpen);
  // };
  // useEffect(() => {
  //   const payload = { id: userData?.id };
  //   dispatch(getWatchList(payload));
  // }, [dispatch, userData?.id]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const payload = { id: userData?.id };
  //     try {
  //       await Promise.all([dispatch(getWatchList(payload))]);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  //   // if (isMounted.current) {
  //   fetchData();
  //   // } else {
  //   //   isMounted.current = true;
  //   // }
  // }, [dispatch, userData?.id]);

  // Mark a specific notification as read
  // const markAsReadHandler = (notificationId) => {
  //   if (userData.id && notificationId) {
  //     const payload = { id: userData.id, notification_id: notificationId };
  //     dispatch(markAsRead(payload));
  //   }
  // };

  // Handle click outside

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       notificationRef.current &&
  //       !notificationRef.current.contains(event.target) &&
  //       !event.target.closest(".nav-link") // Ensure the bell icon click doesn't close it
  //     ) {
  //       setNotificationOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <nav className="profile_navbar navbar navbar-expand-lg navbar-light">
      <div className="container-fluid mobile-nav-head">
        <button
          class="btn ms-4 p-0 shadow-none border-0 d-lg-none"
          id="menu-toggle"
          onClick={props?.onClickBtn}
        >
          <i class="fa-solid fa-bars text-white"></i>
        </button>
        <div
          className="navbar-collapse"
          id="navbarSupportedContent"
          style={{ flexBasis: "auto" }}
        >
          <ul className="managed_navbar navbar-nav ms-auto mt-2 mt-lg-0 align-items-center justify-content-end d-flex flex-row flex-wrap">
            {/* <li className="nav-item active">
              <a
                className="nav-link p-2 d-flex align-items-center gap-2"
                href="/"
              >
                <img src={moon} alt="" className="header-images" />
                <span className="mode_label">Dark Mode</span>
                <div className="form-check form-switch ms-2">
                  <input
                    className="form-check-input shadow-none switch_mode"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked
                  />
                </div>
              </a>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle avtar d-flex align-items-center"
                href="/"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#007bff",
                    color: "white",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  {userData?.name ? userData.name.charAt(0).toUpperCase() : ""}
                </div>
                <span className="ps-2 user_label pe-3 d-sm-inline-flex">
                  Welcome {userData?.name ? userData.name : "NA"}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end py-0 overflow-hidden"
                aria-labelledby="navbarDropdown"
              >
                {/* <div className="border-bottom p-2 d-sm-none d-flex">
                  <img src={avatar} alt="user icon" className="me-2" />
                  <span className="ps-2 user_label text-nowrap">
                    Welcome {userData?.name ? userData?.name : "NA"}
                  </span>
                </div> */}
                <span className="dropdown-item py-2">
                    {userData?.email ? userData?.email : "NA"}
                </span>
                {/* <Link className="dropdown-item py-2" to="/my-profile">
                  My Profile
                </Link> */}
                <Link className="dropdown-item py-2" to="/admin/change-password">
                  Change Password{" "}
                </Link>
                {/* <div className="dropdown-divider"></div> */}
                <Link
                  className="dropdown-item py-2"
                  onClick={() => {
                    logoutUser();
                  }}
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* <NotificationPanel
        isOpen={isNotificationOpen}
        onClose={toggleNotification}
        notifications={notificationData}
        markAsRead={markAsReadHandler}
        notificationRef={notificationRef}
      /> */}
    </nav>
  );
};

export default AdminAfterLoginHeader;
