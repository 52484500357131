import "../styles/HoldingSection.css";
import { Link } from "react-router-dom";
import RecommendedFundPlanDesign4 from "./RecommendedFundPlanDesign4";
import Pagination from "./Pagination";
import ActionPopupButton from "./ActionPopupButton";

const HoldingSectionAll = ({ data, isDashboard }) => {
  const currentPageURL = window.location.pathname;
  // Limit data to 2 items if on the dashboard
  const limitedData = isDashboard
    ? data?.current_holdings?.slice(0, 2)
    : data?.current_holdings;

  let viewPageUrl = "/explore/fund-detail";
  return (
    <div>
      {limitedData && limitedData.length > 0 ? (
        limitedData.map((item, index) => (
          <div key={index} className="mb-3">
            <RecommendedFundPlanDesign4
              id={item?.product?.id}
              bookmark={true}
              plus={true}
              image={item?.icon}
              isin={item?.product.isin}
              amcId={item?.amc_product_id}
              title={item?.scheme_name || "-"}
              badge={item?.product?.asset_class || "-"}
              badge1={item?.product?.plan_type || "-"}
              price1={item?.folioNumber || "-"}
              price2={item?.transactionType || "-"}
              price3={item?.invested_amount || "-"}
              price4={item?.current_value || "-"}
              price5={item?.return || "-"}
              price6={item?.nextSipDate || "-"}
              price7={item?.nextSipAmount}
              units={item?.units || "0"}
              url={viewPageUrl}
              redirectUrl={currentPageURL}
              item={item}
              actionButton={{ filterColor: true, item }}
              // actionButton={
              //   <ActionPopupButton filterColor={true} item={item} />
              // }
            />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default HoldingSectionAll;
