import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  matchPath,
} from "react-router-dom";
import BeforeLoginHeader from "./components/Header";
import BeforeLoginFooter from "./components/Footer";
import AfterLoginHeader from "./components/AfterLoginHeader";
import AfterLoginFooter from "./components/AfterLoginFooter";
import AfterLoginSidebar from "./components/AfterLoginSidebar";
import Home from "./pages/Home";
import About from "./pages/About";
import Downloads from "./pages/Downloads";
import ExploreFunds from "./pages/ExploreFunds";
import FundDetail from "./pages/FundDetail";
import Login from "./pages/Login";
import LoginOtp from "./pages/LoginOtp";
import PersonalDetails from "./pages/PersonalDetails";
import BankDetails from "./pages/BankDetails";
import FatcaDeclartion from "./pages/FatcaDeclartion";
import ManageNominees from "./pages/ManageNominees";
import Dashboard from "./pages/Dashboard";
import Explore from "./pages/Explore";
import FundDetail2 from "./pages/FundDetail2";
import FundDetailAfterInvesting from "./pages/FundDetailAfterInvesting";
import PortfolioHoldings from "./pages/PortfolioHoldings";
import InvestMore from "./pages/InvestMore";
import Redeem from "./pages/Redeem";
import Faqs from "./pages/Faq";
import CustomerSupport from "./pages/CustomerSupport";
import Orders from "./pages/Orders";
import RedeemOrderPlaced from "./pages/RedeemOrderPlaced";
import RaiseTicket from "./pages/RaiseTicket";
import Reports from "./pages/Reports";
import ReportsList from "./pages/ReportsList";
import AdminReportsList from "./pages/Admin/AdminReportsList";
import Transaction from "./pages/Transaction";
import ChangePassword from "./pages/ChangePassword";
import SuccessPopup from "./pages/SuccessPopup";
import STP from "./pages/STP";
import SWP from "./pages/SWP";
import Switch from "./pages/Switch";
import MyProfile from "./pages/MyProfile";
import Goals from "./pages/Goals";
import Wishlist from "./pages/Wishlist";
import TicketMessage from "./pages/TicketMessage";
import MySip from "./pages/MySip";
import BuySipModel from "./pages/BuySipModel";
import MyLumpsumCart from "./pages/MyLumpsumCart";
import BuyLumpsumModel from "./pages/BuyLumpsumModel";
import MySipAndLumpsum from "./pages/MySipAndLumpsum";
import BuySipLumpsumModel from "./pages/BuySipLumpsumModel";
import ForgotPassword from "./pages/ForgotPassword";
import GoalAdd from "./pages/GoalAdd";
import GoalEdit from "./pages/GoalEdit";
import SignUp from "./pages/SignUp";
import PrivacyPolicy from "./pages/Privacy&policy";
import TermsConditions from "./pages/Terms&conditions";
import Loader from "./loader/Loader-ui";
import ScrollToTop from "./components/ScrollToTop";
import {
  getLocalStorageUserData,
  getLocalStorageUserToken,
} from "./utils/userData";
import RmAfterLoginSidebar from "./components/RM/RmAfterLoginSidebar";
import RmAfterLoginHeader from "./components/RM/RmAfterLoginHeader";
import RmAfterLoginFooter from "./components/RM/RmAfterLoginFooter";
// import RmDashboard from "./pages/RM/RmDashboard";
import AdminAfterLoginSidebar from "./components/Admin/AdminAfterLoginSidebar";
import AdminAfterLoginHeader from "./components/Admin/AdminAfterLoginHeader";
import AdminAfterLoginFooter from "./components/Admin/AdminAfterLoginFooter";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminRM from "./pages/Admin/AdminRM";
import AddRM from "./pages/AddRM";
import AdminInvestor from "./pages/Admin/AdminInvestor";
import AdminMailback from "./pages/Admin/AdminMailback";
import Investments from "./pages/Admin/Investments";
import Investers from "./pages/Admin/Investers";
import AddInvester from "./pages/Admin/AddInvestor";
import ViewInvestor from "./pages/Admin/ViewInvestor";
import ViewInvestorRM from "./pages/RM/ViewInvestor";
import ViewNachdetails from "./components/Admin/ViewNachdetails";
import Maping from "./components/Admin/Mapping";
import Investorstatus from "./components/Admin/Investorstatus";
import IINstatus from "./components/Admin/IINstatus";
import IINStatusReport from "./components/Admin/IINStatusReport";
import IINStatusReportRM from "./components/RM/IINStatusReport";
import CustomersIINStatus from "./components/Admin/CustomersIINStatus";
import AdminFAQ from "./pages/Admin/Faqs";
import FaqAdd from "./pages/FaqAdd";
import FaqEdit from "./pages/Admin/EditFaq";
import AdminTransaction from "./pages/Admin/Transaction";
import AdminReports from "./pages/Admin/AdminReports";
import SummaryReport from "./components/Admin/SummaryReport";
import AdminSupport from "./pages/Admin/AdminSupport";
import SupportReply from "./components/Admin/SupportReply";
import EditInvestor from "./pages/Admin/EditInvestor";
import AssignCustomers from "./pages/AssignCustomers";
import RMinvesters from "./pages/RM/Investers";
import RMInvestments from "./pages/RM/Investments";
import RmDashboard from "./pages/RM/RMDashboardUI";

import AdminViewTicket from "./pages/Admin/AdminViewTicket";
import ResubmitProfile from "./pages/Admin/ResubmitProfile";
import RMTransaction from "./pages/RM/RMTransactions";
import RMReports from "./pages/RM/RMReport";
import RMReportsSummery from "./pages/RM/RMReportSummery";
import RMReferral from "./pages/RM/Referral";
import RMChangePassword from "./pages/RM/ChangePassword";
import AdminChangePassword from "./pages/Admin/ChangePassword";
function App() {
  // Simulated state to track user login status; replace with actual authentication logic
  const [isLoggedIn, setIsLoggedIn] = useState(false); // This should be replaced with your auth logic
  const [isVisible, setIsVisible] = useState(false);
  const isLargeScreen = window.matchMedia("(max-width: 992px)").matches;
  const userData = getLocalStorageUserData();
  const toggleSidebar = () => {
    // Only toggle for smaller screens
    if (window.matchMedia("(max-width: 991px)").matches) {
      setIsVisible(!isVisible);
    }
  };

  // Layouts
  const WithoutLoginLayout = ({ children }) => (
    <div className="App">
      <BeforeLoginHeader />
      <main>{children}</main>
      <BeforeLoginFooter />
    </div>
  );

  const AfterLoginLayout = ({ children }) => (
    <div className={`App d-flex mb-5 padding-left`} id="wrapper">
      {userData?.type == "C" ? (
        <>
          <AfterLoginSidebar isVisible={isVisible} onClickBtn={toggleSidebar} />
          <div id="page-content-wrapper" className="w-100">
            <AfterLoginHeader onClickBtn={toggleSidebar} />
            {children}
            <AfterLoginFooter />
          </div>
        </>
      ) : userData?.type == "R" ? (
        <>
          <RmAfterLoginSidebar
            isVisible={isVisible}
            onClickBtn={toggleSidebar}
          />
          <div id="page-content-wrapper" className="w-100">
            <RmAfterLoginHeader onClickBtn={toggleSidebar} />
            {children}
            <RmAfterLoginFooter />
          </div>
        </>
      ) : userData?.type == "A" ? (
        <>
          <AdminAfterLoginSidebar
            isVisible={isVisible}
            onClickBtn={toggleSidebar}
          />
          <div id="page-content-wrapper" className="w-100">
            <AdminAfterLoginHeader onClickBtn={toggleSidebar} />
            {children}
            <AdminAfterLoginFooter />
          </div>
        </>
      ) : (
        <>
          <AfterLoginSidebar isVisible={isVisible} onClickBtn={toggleSidebar} />
          <div id="page-content-wrapper" className="w-100">
            <AfterLoginHeader onClickBtn={toggleSidebar} />
            {children}
            <AfterLoginFooter />
          </div>
        </>
      )}
    </div>
  );

  // LayoutWrapper to conditionally render layouts based on login status and route
  const LayoutWrapper = ({ children }) => {
    const location = useLocation();
    const isLoginPage = location.pathname === "/login";
    const isLoginOtpPage = location.pathname === "/verify-otp";
    const isForgotPasswordPage = location.pathname === "/forgot-password";
    var arrayPathname = [
      "/",
      "/about",
      "/downloads",
      "/explore-funds",
      "/login",
      "/login/:slug",
      "/verify-otp",
      "/forgot-password",
      "/fund-detail",
      "/fund-detail/:slug",
      "/fund-detail/:slug/:slug",
      "/termsconditions",
      "/privacy-policy",
    ];
    // Check if location.pathname is in arrayPathname or matches a dynamic route
    // const isPathIncluded = arrayPathname.includes(location.pathname);
    // Added this change because slug related url are not getting mached in condition so used this library
    const isPathIncluded = arrayPathname.some((path) => {
      return matchPath({ path, exact: true }, location.pathname);
    });
    // Before login - login page has no header/footer, other routes show basic header/footer
    if (!isLoggedIn && isPathIncluded) {
      return isLoginPage ? (
        <div className="App">
          <main>{children}</main>
        </div>
      ) : isLoginOtpPage ? (
        <div className="App">
          <main>{children}</main>
        </div>
      ) : isForgotPasswordPage ? (
        <div className="App">
          <main>{children}</main>
        </div>
      ) : (
        <WithoutLoginLayout>{children}</WithoutLoginLayout>
      );
    }

    // After login - authenticated layout
    return <AfterLoginLayout>{children}</AfterLoginLayout>;
  };

  const ProtectedRoute = ({ children }) => {
    const token = getLocalStorageUserToken();
    return token ? children : <Navigate to="/login" replace />;
  };
  return (
    <Router>
      <LayoutWrapper>
        <ScrollToTop />
        <Routes>
          {/* customer */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/downloads" element={<Downloads />} />
          {/* make the common route to use as common module of explore fund */}
          {/* <Route path="/explore-funds" element={<ExploreFunds />} /> */}
          <Route path="/explore-funds" element={<Explore />} />
          {/* <Route path="/fund-detail" element={<FundDetail />} /> */}
          <Route path="/fund-detail" element={<FundDetail2 />} />
          <Route path="/fund-detail/:slug" element={<FundDetail2 />} />
          <Route path="/fund-detail/:slug/:slug" element={<FundDetail2 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/:slug" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify-otp" element={<LoginOtp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/personal-details"
            element={
              <ProtectedRoute>
                <PersonalDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bank-details"
            element={
              <ProtectedRoute>
                <BankDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/fatca-declartion"
            element={
              <ProtectedRoute>
                <FatcaDeclartion />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-nominees"
            element={
              <ProtectedRoute>
                <ManageNominees />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/termsconditions" element={<TermsConditions />} />
          <Route
            path="/explore"
            element={
              <ProtectedRoute>
                {" "}
                <Explore />
              </ProtectedRoute>
            }
          />
          <Route
            path="/explore/fund-detail"
            element={
              <ProtectedRoute>
                <FundDetail2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/explore/fund-detail/:slug"
            element={
              <ProtectedRoute>
                <FundDetail2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/explore/fund-detail/:slug/:slug"
            element={
              <ProtectedRoute>
                <FundDetail2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/fund-detail-after-investing"
            element={
              <ProtectedRoute>
                <FundDetailAfterInvesting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/portfolio"
            element={
              <ProtectedRoute>
                <PortfolioHoldings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invest-more"
            element={
              <ProtectedRoute>
                <InvestMore />
              </ProtectedRoute>
            }
          />
          <Route
            path="/redeem"
            element={
              <ProtectedRoute>
                <Redeem />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stp"
            element={
              <ProtectedRoute>
                <STP />
              </ProtectedRoute>
            }
          />
          <Route
            path="/swp"
            element={
              <ProtectedRoute>
                <SWP />
              </ProtectedRoute>
            }
          />
          <Route
            path="/switch"
            element={
              <ProtectedRoute>
                <Switch />
              </ProtectedRoute>
            }
          />
          <Route path="/FAQs" element={<ProtectedRoute><Faqs /></ProtectedRoute>} />
          <Route path="/customer-support" element={<ProtectedRoute><CustomerSupport /></ProtectedRoute>} />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/redeem-order-placed"
            element={
              <ProtectedRoute>
                <RedeemOrderPlaced />
              </ProtectedRoute>
            }
          />
          <Route
            path="/raise-ticket"
            element={
              <ProtectedRoute>
                <RaiseTicket />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports-list"
            element={
              <ProtectedRoute>
                <ReportsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transaction"
            element={
              <ProtectedRoute>
                <Transaction />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wishlist"
            element={
              <ProtectedRoute>
                <Wishlist />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ticket-message"
            element={
              <ProtectedRoute>
                <TicketMessage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />
          <Route path="/success-pop-up" element={<SuccessPopup />} />
          <Route
            path="/my-profile"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/goals"
            element={
              <ProtectedRoute>
                <Goals />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mysip-cart"
            element={
              <ProtectedRoute>
                <MySip />
              </ProtectedRoute>
            }
          />
          <Route path="/buy-sip-model" element={<BuySipModel />} />
          <Route
            path="/lumbsum-cart"
            element={
              <ProtectedRoute>
                <MyLumpsumCart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lumbsum-models"
            element={
              <ProtectedRoute>
                <BuyLumpsumModel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sip-lumpsum-cart"
            element={
              <ProtectedRoute>
                <MySipAndLumpsum />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sip-lumpsum-model"
            element={
              <ProtectedRoute>
                <BuySipLumpsumModel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/goals/add"
            element={
              <ProtectedRoute>
                <GoalAdd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/goals/edit"
            element={
              <ProtectedRoute>
                <GoalEdit />
              </ProtectedRoute>
            }
          />
          <Route path="/loader" element={<Loader />} />

          {/* RM */}
          <Route path="/rm" element={<Home />} />
          <Route path="/rm/about" element={<About />} />
          <Route path="/rm/downloads" element={<Downloads />} />
          {/* make the common route to use as common module of explore fund */}
          {/* <Route path="/explore-funds" element={<ExploreFunds />} /> */}
          <Route path="/rm/explore-funds" element={<Explore />} />
          {/* <Route path="/fund-detail" element={<FundDetail />} /> */}
          <Route path="/rm/fund-detail" element={<FundDetail2 />} />
          <Route path="/rm/personal-details" element={<PersonalDetails />} />
          <Route path="/rm/bank-details" element={<BankDetails />} />
          <Route path="/rm/fatca-declartion" element={<FatcaDeclartion />} />
          <Route path="/rm/manage-nominees" element={<ManageNominees />} />
          <Route path="/rm/investors" element={<RMinvesters />} />
          <Route path="/rm/viewinvestor" element={<ViewInvestorRM />} />
          <Route path="/rm/investments" element={<RMInvestments />} />
          <Route path="/rm/transactons" element={<RMTransaction />} />
          <Route path="/rm/investmentExport" element={<IINStatusReportRM />} />
          <Route path="/rm/transactionExport" element={<IINStatusReportRM />} />
          <Route path="/rm/reports" element={<RMReports />} />
          <Route path="/rm/reports-summery" element={<RMReportsSummery />} />
          <Route path="/rm/referral-code" element={<RMReferral />} />
          <Route path="/rm/change-password" element={<RMChangePassword />} />
          <Route
            path="/rm/dashboard"
            element={
              <ProtectedRoute>
                <RmDashboard />
              </ProtectedRoute>
            }
          />

          {/* Admin */}
          <Route path="/admin" element={<Home />} />
          <Route path="/admin/about" element={<About />} />
          <Route path="/admin/downloads" element={<Downloads />} />
          {/* make the common route to use as common module of explore fund */}
          {/* <Route path="/explore-funds" element={<ExploreFunds />} /> */}
          <Route path="/admin/explore-funds" element={<Explore />} />
          {/* <Route path="/fund-detail" element={<FundDetail />} /> */}
          <Route path="/admin/fund-detail" element={<FundDetail2 />} />
          <Route path="/admin/personal-details" element={<PersonalDetails />} />
          <Route path="/admin/bank-details" element={<BankDetails />} />
          <Route path="/admin/fatca-declartion" element={<FatcaDeclartion />} />
          <Route path="/admin/manage-nominees" element={<ManageNominees />} />
          {/* <Route path="/admin/investor" element={<AdminInvestor />} /> */}
          <Route path="/admin/rm" element={<AdminRM />} />
          <Route path="/admin/support" element={<AdminSupport />} />
          <Route path="/admin/support-reply" element={<SupportReply />} />
          <Route path="/admin/addrm" element={<AddRM />} />
          <Route path="/admin/assigncustomer" element={<AssignCustomers />} />
          <Route path="/admin/mailback" element={<AdminMailback />} />
          <Route path="/admin/investment" element={<Investments />} />
          <Route path="/admin/investor" element={<Investers />} />
          <Route path="/admin/investeradd" element={<AddInvester />} />
          <Route path="/admin/investeredit" element={<AddInvester />} />

          <Route path="/admin/viewinvestor" element={<ViewInvestor />} />
          <Route path="/admin/viewnachdetails" element={<ViewNachdetails />} />
          <Route path="/admin/viewmaping" element={<Maping />} />
          <Route path="/admin/investorstatus" element={<Investorstatus />} />
          <Route path="/admin/iinstatus" element={<IINstatus />} />
          <Route path="/admin/iinstatusreport" element={<IINStatusReport />} />
          <Route path="/admin/investmentExport" element={<IINStatusReport />} />
          <Route path="/admin/transactionExport" element={<IINStatusReport />} />
          <Route
            path="/admin/customersiinstatus"
            element={<CustomersIINStatus />}
          />
          <Route path="/admin/faqs" element={<AdminFAQ />} />
          <Route path="/admin/addfaq" element={<FaqAdd />} />
          <Route path="/admin/editfaq" element={<FaqAdd />} />
          <Route path="/admin/transaction" element={<AdminTransaction />} />
          <Route path="/admin/change-password" element={<AdminChangePassword />} />
          <Route path="/admin/reports" element={<AdminReports />} />
          <Route
            path="/admin/reports-list"
            element={
              <ProtectedRoute>
                <AdminReportsList />
              </ProtectedRoute>
            }
          />
          <Route path="/admin/summary-report" element={<SummaryReport />} />
          <Route path="/admin/view-ticket" element={<AdminViewTicket />} />
          <Route path="/admin/resubmitprofile" element={<ResubmitProfile />} />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/termsconditions" element={<TermsConditions />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/explore/fund-detail" element={<FundDetail2 />} />
          <Route
            path="/fund-detail-after-investing"
            element={<FundDetailAfterInvesting />}
          />
          <Route path="/portfolio" element={<PortfolioHoldings />} />
          <Route path="/invest-more" element={<InvestMore />} />
          <Route path="/redeem" element={<Redeem />} />
          <Route path="/stp" element={<STP />} />
          <Route path="/swp" element={<SWP />} />
          <Route path="/switch" element={<Switch />} />
          <Route path="/FAQs" element={<Faqs />} />
          <Route path="/customer-support" element={<CustomerSupport />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/redeem-order-placed" element={<RedeemOrderPlaced />} />
          <Route path="/raise-ticket" element={<RaiseTicket />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/reports-list" element={<ReportsList />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/ticket-message" element={<TicketMessage />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/success-pop-up" element={<SuccessPopup />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/goals" element={<Goals />} />
          <Route path="/mysip-cart" element={<MySip />} />
          <Route path="/buy-sip-model" element={<BuySipModel />} />
          <Route path="/lumbsum-cart" element={<MyLumpsumCart />} />
          <Route path="/lumbsum-models" element={<BuyLumpsumModel />} />
          <Route path="/sip-lumpsum-cart" element={<MySipAndLumpsum />} />
          <Route path="/sip-lumpsum-model" element={<BuySipLumpsumModel />} />
          <Route path="/goals/add" element={<GoalAdd />} />
          <Route path="/goals/edit" element={<GoalEdit />} />
          <Route path="/loader" element={<Loader />} /> */}
        </Routes>
      </LayoutWrapper>
    </Router>
  );
}

export default App;
