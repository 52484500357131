import React from "react";
import "../styles/myProfileHeader.css"; // Import styles
import Gross_down from "../images/gross_down_icon.svg";
import { useSelector } from "react-redux";
import { getLocalStorageUserData } from "../utils/userData";
// Functional component for the funddetails page
const MyProfileHeader = () => {
  const userData = getLocalStorageUserData();
  const { userGetProfile } = useSelector((state) => state.users);
  return (
    <div className="my_profile_header card-header">
      <h5 className="page-title d-flex flex-wrap column-gap-4 align-items-center">
        My Profile
        <span
          class={`${
            userGetProfile?.data?.user?.has_iin_activated
              ? "success_message"
              : "error_message"
          } `}
        >
          {userGetProfile?.data?.user?.has_iin_activated
            ? "(FATCA completed successfully.)"
            : "(FATCA not completed successfully.)"}
        </span>
      </h5>
      <div className="investment-info">
        <div className="">
          <h6 className="investment-value-header">Email</h6>
          <span className="value-text">
            {userGetProfile?.data?.user?.email}
          </span>
        </div>
        <div className="">
          <h6 className="investment-value-header">IIN Number</h6>
          <span className="value-text">
            {userData?.profile?.customer_id
              ? userData?.profile?.customer_id
              : "NA"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MyProfileHeader;
