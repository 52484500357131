import React from 'react';
import { Link } from 'react-router-dom';

const InvestButton = (props) => {
  const handleClick = (e) => {
    if (props.onClick) {
      e.preventDefault(); // Prevent navigation if `onClick` is provided
      props.onClick();
    }
  };

  return (
    <Link
      to={{ pathname: props.url, state: props.state }}  // Pass state dynamically from parent
      className={`btn invest_btn invest_btn_${props.color} ${props.className}`}
      onClick={handleClick} // Attach the click handler
    >
      {props.label}
    </Link>
  );
};

export default InvestButton;
