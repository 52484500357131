import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import TransactionTable from "../../components/Admin/InvesmentTable";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { investmentList } from "../../actions/rmAction";
import Loader from "../../loader/Loader-ui";
import InvestButton from "../../components/InvestButton";
import exportIcon from "../../images/icons/export.svg";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Transaction = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [isLoading, setIsLoading] = useState(false);
  const [searchFunds, setSearchFunds] = useState("");
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");
  const [rmType, setRMType] = useState("");
  const dispatch = useDispatch();

  const fetchInvestmentList = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        investmentList({
          page_num: currentPage,
          per_page: "10",
          search: searchFunds,
          type: type,
          subType: subType,
        })
      );
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestmentList();
  }, [currentPage, searchFunds, type, subType]);

  const { adminInvestmentList = {} } = useSelector((state) => state?.admin);
  const investmentSchemeData = adminInvestmentList?.data?.investmentsList || [];
  const totalPages = adminInvestmentList?.data?.pagination?.total_pages || 1;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFundSearchChange = (e) => {
    setSearchFunds(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleSubTypeChange = (e) => {
    setSubType(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  // Function to clear all filters
  const clearFilters = () => {
    setRMType("");
    setSearchFunds("");
    setType("");
    setSubType("");
    setCurrentPage(1); // Reset to first page when search changes
  };

  return (
    <div className="container-fluid page_background">
      {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          {/* <h5 className="mb-4 main-heading-title">Investments</h5> */}

          <div className="d-flex justify-content-between mb-3 align-items-center">
          <h5 className="mb-4 main-heading-title investment-heading-title">
              Investments{" "}
            </h5>
            <div className="investor-btn-container">
              <div>
                <InvestButton
                  label={
                    <>
                      <img src={exportIcon} className="mr-1" />
                      Export
                    </>
                  }
                  color="blue"
                  className="white-space-nowrap investor-btn-p-6"
                  onClick={() => {
                    navigate("/rm/investmentExport", {
                      state: { from: "investments" },
                    });
                  }}               
                  />
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
                <InvestButton
                  label={<>Clear</>}
                  color="blue"
                  className="white-space-nowrap investor-btn-p-10"
                  onClick={clearFilters}
                />
              </div>
            </div>
          </div>

          <div className="search-container">
            <div>
              <div className="row align-items-end">
                <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
                  <div className="position-relative">
                    <IoSearch className="search-icon-custom" />
                    <input
                      type="text"
                      className="search-input-custom"
                      placeholder="Search Name"
                      value={searchFunds}
                      onChange={handleFundSearchChange}
                    />
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
                  <div className="dropdown-label-custom">Transaction type:</div>
                  <select
                    className="form-select custom-select2"
                    value={type} // Set selected value
                    onChange={handleTypeChange} // Handle selection
                    displayEmpty
                  >
                    <option value={""} disabled>
                      Select
                    </option>
                    <option value={"all"}>All</option>
                    <option value={"Fresh Purchase"}>Fresh Purchase</option>
                    <option value={"Additional Purchase"}>
                      Additional Purchase
                    </option>
                    <option value={"Full Redeem"}>Full Redeem</option>
                    {/* <option value={"Switch"}>Switch</option> */}
                    <option value={"Switch In"}>Switch In</option>
                    <option value={"Switch Out"}>Switch Out</option>
                    <option value={"STP In"}>STP In</option>
                    <option value={"STP Out"}>STP Out</option>
                  </select>
                </div>

                <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
                  <div className="dropdown-label-custom">Sub type:</div>
                  <select
                    className="form-select custom-select2"
                    value={subType} // Set selected value
                    onChange={handleSubTypeChange} // Handle selection
                    displayEmpty
                  >
                    <option value={""} disabled>
                      Select
                    </option>
                    <option value={"all"}>All</option>
                    <option value={"Lumpsum"}>Lumpsum</option>
                    <option value={"SIP"}>SIP</option>
                    <option value={"STP"}>STP</option>
                    <option value={"SWP"}>SWP</option>
                    <option value={"Redeem"}>Redeem</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="transaction_tab">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="mailBack"
                role="tabpanel"
                aria-labelledby="mailBack-tab"
              >
                <TransactionTable
                  data={investmentSchemeData}
                  currentPage={currentPage}
                  // selectedType={selectedType}
                  // selectedStatus={selectedStatus}
                  // onTypeChange={handleTypeChange}
                  // onStatusChange={handleStatusChange}
                />
                <div className="pt-5">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
