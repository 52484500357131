import { GET_FAQS, TERMS_CONDITIONS } from "../actions/types";

const initialState = {
    faqsData : {},
    termsAndConditions :{},
    loading: false,
};

const footerreducers = (state = initialState, action) => {
    switch (action?.type) {
        case GET_FAQS:
            return { ...state, loading: false, faqsData: action?.payload };
        case TERMS_CONDITIONS:
            return { ...state, loading: false, termsAndConditions: action?.payload };
        default:
            return state;
    }
};

export default footerreducers;