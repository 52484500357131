import React, { useEffect, useState } from "react";
import "../styles/transaction.css";
import "../styles/common.css";
import my_profile123 from "../images/my_profile/personel_details.svg";
import pen from "../images/my_profile/pen.svg";
import view from "../images/my_profile/eye.svg";

import SelectDropDown from "./SelectDropDown";
import calendar_month from "../images/my_profile/calendar_month.svg";
import DatePickerInput from "./DatePickerInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../styles/profile.css";
import { useDispatch, useSelector } from "react-redux";
import { sanitizeInput } from "../utils/sanitizeInput";
import { getLocalStorageUserData } from "../utils/userData";
import {
  pincode,
  userPofileStore,
  userPofileUpdate,
} from "../actions/usersActions";
import {
  convertDateToDDMMMYYYY,
  convertDateToDDMMYYYY,
  convertDateToDDMMYYYYStartWithYear,
} from "../utils/DateFormate";

const PersonelDetail = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { userGetProfile } = useSelector((state) => state.users);
  const userData = getLocalStorageUserData();
  const dispatch = useDispatch();
  const toggleEditMode = (e) => {
    e.stopPropagation();
    setIsEditing(!isEditing);
  };

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });
  const formik = useFormik({
    initialValues: {
      inv_name: userGetProfile?.data?.user?.name || "",
      dob: userGetProfile?.data?.user?.dob || "",
      email: userGetProfile?.data?.user?.email || "",
      email_relation: userGetProfile?.data?.user?.profile?.email_relation || "",
      mobile_no: userGetProfile?.data?.user?.mobile || "",
      mobile_relation:
        userGetProfile?.data?.user?.profile?.mobile_relation || "",
      gender: userGetProfile?.data?.user?.profile?.gender || "",
      marital_status: userGetProfile?.data?.user?.profile?.marital_status || "",
      father_name: userGetProfile?.data?.user?.profile?.father_name || "",
      occupation: userGetProfile?.data?.user?.profile?.occupation || "",
      pan: userGetProfile?.data?.user?.pan || "",
      adhaar_card: userGetProfile?.data?.user?.profile?.adhaar_card || "",
      addr1: userGetProfile?.data?.user?.profile?.addr1 || "",
      pincode: userGetProfile?.data?.user?.profile?.pincode || "",
      city: userGetProfile?.data?.user?.profile?.city || "",
      state: userGetProfile?.data?.user?.profile?.state || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      inv_name: Yup.string()
        .test(sanitizeTest("Full Name"))
        .matches(/^[A-Za-z\s]+$/, "Full Name must contain only alphabets")
        .required("Full Name is required"),
      dob: Yup.date().required("Date of Birth is required"),
      email: Yup.string()
        .test(sanitizeTest("Email"))
        .email("Enter a valid email address.")
        .required("Email is required"),
      email_relation: Yup.string()
        .notOneOf(["Select"], "Email Belongs To is required")
        .required("Email Belongs To is required"),
      mobile_no: Yup.string()
        .test(sanitizeTest("Mobile Number"))
        .matches(/^\d{10}$/, "Mobile Number must be 10 digits")
        .required("Mobile Number is required"),
      mobile_relation: Yup.string()
        .notOneOf(["Select"], "Mobile Belongs To is required")
        .required("Mobile Belongs To is required"),
      gender: Yup.string().required("Gender is required"),
      occupation: Yup.string()
        .notOneOf(["Select"], "Occupation is required")
        .required("Occupation is required"),
      marital_status: Yup.string().required("Marital Status is required"),
      father_name: Yup.string()
        .test(sanitizeTest("Parent Name"))
        .matches(/^[A-Za-z\s]+$/, "Parent Name must contain only alphabets")
        .required("Parent Name is required"),
      pan: Yup.string()
        .test(sanitizeTest("PAN"))
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN Details")
        .required("PAN is required"),
      adhaar_card: Yup.string()
        .test(sanitizeTest("Aadhar Number"))
        .matches(/^\d{12}$/, "Aadhar Number must be 12 digits")
        .required("Aadhar Number is required"),
      addr1: Yup.string()
        .test(sanitizeTest("Address"))
        .max(200, "Input should not accept more than 200 characters")
        .required("Address is required"),
      pincode: Yup.string()
        .test(sanitizeTest("Pincode"))
        .matches(/^\d{6}$/, "Pincode must be 6 digits")
        .required("Pincode is required"),
      city: Yup.string()
        .test(sanitizeTest("City"))
        .required("City is required"),
      state: Yup.string()
        .notOneOf(["Select"], "State is required")
        .required("State is required"),
    }),
    onSubmit: (values) => {
      const formatDate = convertDateToDDMMMYYYY(values?.dob);
      const payload = {
        ...values,
        dob: formatDate,
        addr1: values?.addr1?.trim(),
      };
      dispatch(userPofileUpdate(payload));
      setIsEditing(false);
    },
  });

  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];

  const maritalOptions = [
    { value: "MARRIED", label: "Married" },
    { value: "UNMARRIED", label: "Unmarried" },
    { value: "OTHER", label: "Other" },
  ];

  const relationLabel = (email_relation) => {
    return Object.keys(userGetProfile?.data?.mobile_email_relations || {})
      .filter((key) => key === email_relation) // Match the specific email_relation
      .map((key) => userGetProfile?.data?.mobile_email_relations[key]); // Return the label
  };
  const getGenderLabel = (value) => {
    const option = genderOptions.find((option) => option.value === value);
    return option ? option.label : null; // Return label or null if not found
  };
  const getMaritalLabel = (value) => {
    const option = maritalOptions.find((option) => option.value === value);
    return option ? option.label : null; // Return label or null if not found
  };
  const getOccupationLabel = (value) => {
    const option = userGetProfile?.data?.occupations?.find(
      (option) => option.id == value
    );
    return option ? option.occupation_desc : null; // Return label or null if not found
  };
  const getStateLabel = (value) => {
    const option = userGetProfile?.data?.states?.find(
      (option) => option.state_code === value
    );
    return option ? option.state_name : null; // Return label or null if not found
  };

  const callStateAndDistrict = async (pin) => {
    try {
      if (pin?.split("")?.length == 6) {
        const payload = {
          pincode: pin,
        };
        dispatch(pincode(payload))
          .then((res) => {
            formik?.setFieldValue("city", res?.data?.city);
            formik?.setFieldValue("state", res?.data?.state_code);
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingTwo">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img
                src={my_profile123}
                alt=""
                className="personel_details_img"
              />
              {isEditing ? (
                <>
                  <span className="personal_inner_text">
                    Personal Details Edit
                  </span>
                </>
              ) : (
                <>
                  <span className="personal_inner_text">Personal Details</span>
                </>
              )}
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />

        <div className="accordion-body profile_accordion_body">
          <div className="d-flex justify-content-end align-items-right">
            <div className="edit_personel">
              <span
                className="badge editBadge profile-mr"
                onClick={toggleEditMode}
              >
                {isEditing ? (
                  <>
                    <img src={view} alt="View Icon" className="pen_image" />
                    <span className="editInner d-sm-block d-none">View</span>
                  </>
                ) : (
                  <>
                    <img src={pen} alt="Edit Icon" className="pen_image" />
                    <span className="editInner d-sm-block d-none">Edit</span>
                  </>
                )}
              </span>
            </div>
          </div>

          {/* Edit Personal Details Content */}

          {isEditing ? (
            <>
              <div className="card redeam_bottom_card">
                <div className="row form_body justify-content-center">
                  <div className="col-xl-8 col-lg-10 col-md-11 ">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Full Name <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="inv_name"
                            placeholder="Enter Full Name"
                            {...formik.getFieldProps("inv_name")}
                          />
                          {formik.touched.inv_name &&
                            formik.errors.inv_name && (
                              <div className="text-danger">
                                {formik.errors.inv_name}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Date of Birth <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <DatePickerInput
                            selectedDate={formik.values.dob}
                            maxDate={new Date().toISOString().split("T")[0]}
                            onChange={(date) =>
                              formik.setFieldValue("dob", date)
                            }
                          />
                          {formik.touched.dob && formik.errors.dob && (
                            <div className="text-danger">
                              {formik.errors.dob}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Email <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="anandr@gmail.com"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Email Belongs to{" "}
                            <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <SelectDropDown
                            name="email_relation"
                            id=""
                            value={formik.values.email_relation}
                            changeFunction={(value) =>
                              formik?.setFieldValue("email_relation", value)
                            }
                            options={Object.keys(
                              userGetProfile?.data?.mobile_email_relations
                            )?.map((key) => ({
                              value: key,
                              label:
                                userGetProfile?.data?.mobile_email_relations[
                                  key
                                ],
                            }))}
                            {...formik.getFieldProps("email_relation")}
                          />
                          {formik.touched.email_relation &&
                            formik.errors.email_relation && (
                              <div className="text-danger">
                                {formik.errors.email_relation}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Mobile Number <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="mobile_no"
                            placeholder="Mobile Number"
                            {...formik.getFieldProps("mobile_no")}
                          />
                          {formik.touched.mobile_no &&
                            formik.errors.mobile_no && (
                              <div className="text-danger">
                                {formik.errors.mobile_no}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Mobile Number Belongs to{" "}
                            <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <SelectDropDown
                            name="mobile_relation"
                            id=""
                            value={formik.values.mobile_relation}
                            changeFunction={(value) =>
                              formik?.setFieldValue("mobile_relation", value)
                            }
                            options={Object.keys(
                              userGetProfile?.data?.mobile_email_relations
                            ).map((key) => ({
                              value: key,
                              label:
                                userGetProfile?.data?.mobile_email_relations[
                                  key
                                ],
                            }))}
                          />
                          {formik.touched.mobile_relation &&
                            formik.errors.mobile_relation && (
                              <div className="text-danger">
                                {formik.errors.mobile_relation}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Gender <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <div class="d-flex align-items-center gap-2 flex-wrap">
                            {genderOptions.map(({ value, label }) => (
                              <div
                                className="form-check form-check-inline"
                                key={value}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id={value}
                                  value={value}
                                  onChange={formik.handleChange}
                                  checked={formik.values.gender === value}
                                />
                                <label
                                  className="form-check-label ms-2"
                                  htmlFor={value}
                                >
                                  {label}
                                </label>
                              </div>
                            ))}
                          </div>
                          {formik.touched.gender && formik.errors.gender ? (
                            <div className="text-danger">
                              {formik.errors.gender}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Marital Status <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <div class="d-flex align-items-center gap-2 flex-wrap">
                            {maritalOptions.map(({ value, label }) => (
                              <div
                                className="form-check form-check-inline"
                                key={value}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="marital_status"
                                  id={value}
                                  value={value}
                                  onChange={formik.handleChange}
                                  checked={
                                    formik.values.marital_status === value
                                  }
                                />
                                <label
                                  className="form-check-label ms-2"
                                  htmlFor={value}
                                >
                                  {label}
                                </label>
                              </div>
                            ))}
                          </div>
                          {formik.touched.marital_status &&
                          formik.errors.marital_status ? (
                            <div className="text-danger">
                              {formik.errors.marital_status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Father/Husband Name{" "}
                            <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="father_name"
                            placeholder="Enter Father/Husband Name"
                            value=""
                            {...formik.getFieldProps("father_name")}
                          />
                          {formik.touched.father_name &&
                            formik.errors.father_name && (
                              <div className="text-danger">
                                {formik.errors.father_name}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Occupation <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <SelectDropDown
                            name="occupation"
                            id=""
                            value={formik?.values?.occupation}
                            changeFunction={(value) =>
                              formik?.setFieldValue("occupation", value)
                            }
                            options={userGetProfile?.data?.occupations.map(
                              (occ) => ({
                                value: occ.occupation_code,
                                label: occ.occupation_desc,
                              })
                            )}
                          />
                          {formik.touched.occupation &&
                            formik.errors.occupation && (
                              <div className="text-danger">
                                {formik.errors.occupation}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            PAN <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="pan"
                            placeholder="Enter Pan"
                            disabled={true}
                            {...formik.getFieldProps("pan")}
                          />
                          {formik.touched.pan && formik.errors.pan && (
                            <div className="text-danger">
                              {formik.errors.pan}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Aadhar Number <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="adhaar_card"
                            placeholder="Enter Aadhar Number"
                            {...formik.getFieldProps("adhaar_card")}
                          />
                          {formik.touched.adhaar_card &&
                            formik.errors.adhaar_card && (
                              <div className="text-danger">
                                {formik.errors.adhaar_card}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Address <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="addr1"
                            placeholder="Enter Address"
                            {...formik.getFieldProps("addr1")}
                          />
                          {formik.touched.addr1 && formik.errors.addr1 && (
                            <div className="text-danger">
                              {formik.errors.addr1}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Pincode <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="pincode"
                            name="pincode"
                            placeholder="Enter Pincode"
                            onChange={(e) => {
                              formik.handleChange(e); // Handle Formik's change
                              callStateAndDistrict(e.target.value); // Call your custom function
                            }}
                            value={formik.values.pincode}
                          />
                          {formik.touched.pincode && formik.errors.pincode && (
                            <div className="text-danger">
                              {formik.errors.pincode}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            City <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            placeholder="Enter City"
                            {...formik.getFieldProps("city")}
                          />
                          {formik.touched.city && formik.errors.city && (
                            <div className="text-danger">
                              {formik.errors.city}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            State <span className="red-color">*</span>
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <SelectDropDown
                            name="state"
                            id=""
                            value={formik?.values?.state}
                            changeFunction={(value) =>
                              formik?.setFieldValue("state", value)
                            }
                            options={userGetProfile?.data?.states.map(
                              (state) => ({
                                value: state.state_code,
                                label: state.state_name,
                              })
                            )}
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                        <button type="submit" className="form_button next_btn">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* View Personal Details Content */}

              <div className="card redeam_bottom_card">
                <div className="row form_body justify-content-center">
                  <div className="col-xl-6 col-lg-10 col-md-10 ">
                    <form>
                      <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Full Name:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>{userGetProfile?.data?.user?.name}</span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Date of Birth:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userGetProfile?.data?.user?.dob &&
                              convertDateToDDMMYYYYStartWithYear(
                                userGetProfile?.data?.user?.dob
                              )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Email:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span className="white-space-nowrap">
                            {userGetProfile?.data?.user?.email}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Email Belongs to:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {relationLabel(
                              userGetProfile?.data?.user?.profile
                                ?.email_relation
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Mobile Number:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>{userGetProfile?.data?.user?.mobile}</span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Mobile Number Belongs to:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {relationLabel(
                              userGetProfile?.data?.user?.profile
                                ?.mobile_relation
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Gender:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {getGenderLabel(
                              userGetProfile?.data?.user?.profile?.gender
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Marital Status:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {getMaritalLabel(
                              userGetProfile?.data?.user?.profile
                                ?.marital_status
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Father/Husband Name:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userGetProfile?.data?.user?.profile?.father_name}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Occupation:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {getOccupationLabel(
                              userGetProfile?.data?.user?.profile?.occupation
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            PAN:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>{userGetProfile?.data?.user?.pan}</span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Aadhar Number:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userGetProfile?.data?.user?.profile?.adhaar_card}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Address:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userGetProfile?.data?.user?.profile?.addr1}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            Pincode:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userGetProfile?.data?.user?.profile?.pincode}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            City:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {userGetProfile?.data?.user?.profile?.city}
                          </span>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-lg-6 col-md-6 col-sm-5 my-md-2 my-1">
                          <label className="form-label accordion-from-label">
                            State:
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-7 my-md-2 my-1">
                          <span>
                            {getStateLabel(
                              userGetProfile?.data?.user?.profile?.state
                            )}
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonelDetail;
