import { GET_WATCH_LIST, DELETE_WATCH_LIST } from "../actions/types";

const initialState = {
  getWatchList: {},
  deleteWatchList: {},
  loading: false,
};

const watchListReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_WATCH_LIST:
      return { ...state, loading: false, getWatchList: action.payload };
    case DELETE_WATCH_LIST:
          return { ...state, loading: false, deleteWatchList: action.payload };
    default:
      return state;
  }
};
export default watchListReducers;
