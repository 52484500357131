import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import InvestersTable from "../../components/RM/InvestersTable";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../loader/Loader-ui";
import InvestButton from "../../components/InvestButton";
import exportIcon from "../../images/icons/export.svg";
import { IoSearch } from "react-icons/io5";
import { toast } from "react-toastify";
import { investorList, investorListExport } from "../../actions/rmAction";

const Transaction = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFunds, setSearchFunds] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchInvestorList = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        investorList({
          page_num: currentPage,
          per_page: "10",
          search: searchFunds,
        })
      );
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvestorListExport = async () => {
    try {
      setIsLoading(true);
      const payload = {
        search: searchFunds,
      };
      await dispatch(investorListExport(payload)).then((response) => {
        if (response.status === true) {
          handleButtonClick(response);
        } else {
          toast.error(response?.message);
        }
      });
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestorList();
  }, [currentPage, searchFunds]);

  const { adminInvestorList = {} } = useSelector((state) => state?.admin);

  const investorSchemeData = adminInvestorList?.data?.investorsList || [];
  const totalPages = adminInvestorList?.data?.pagination?.total_pages || 1;
  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFundSearchChange = (e) => {
    setSearchFunds(e.target.value);
    setCurrentPage(1); // Reset to first page when search changes
  };

  const handleExportChange = () => {
    fetchInvestorListExport();
  };

  const handleButtonClick = (response) => {
    const fileUrl = response?.data?.report_url;
    if (fileUrl) {
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = "";
      // anchor.target = "_blank";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else {
      console.error("File URL not found in the response");
    }
    toast.success(response?.message);
    // navigate('/reports-list', { state: { apiResponse: response } });
  };

  return (
    <div className="container-fluid page_background">
      {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          {/* <h5 className="mb-4 main-heading-title">Investments</h5> */}

          <div className="d-flex justify-content-between mb-3 align-items-center">
            <h5 className="mb-4 main-heading-title"> Investors </h5>

            <div className="d-flex justify-content-center align-items-center gap-3">
              <div>
                <InvestButton
                  label={
                    <>
                      <img src={exportIcon} className="mr-1" />
                      Export
                    </>
                  }
                  // url="/admin/addfaq"
                  color="blue"
                  className=""
                  onClick={handleExportChange}
                />
              </div>
            </div>
          </div>

          <div className="search-container">
            <div>
              <div className="row align-items-end">
                <div className="col-md-3 col-sm-6 col-xs-12 mb-3">
                  <div className="position-relative">
                    <IoSearch className="search-icon-custom" />
                    <input
                      type="text"
                      className="search-input-custom"
                      placeholder="Search Name"
                      value={searchFunds}
                      onChange={handleFundSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="transaction_tab">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="mailBack"
                role="tabpanel"
                aria-labelledby="mailBack-tab"
              >
                <InvestersTable
                  data={investorSchemeData}
                  currentPage={currentPage}
                  // selectedType={selectedType}
                  // selectedStatus={selectedStatus}
                  // onTypeChange={handleTypeChange}
                  // onStatusChange={handleStatusChange}
                />
                <div className="pt-5">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
