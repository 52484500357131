import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  rmDetail,
  rmEdit,
  rmAdd,
  rmBankList,
  BankDetailsByIfsc,
} from "../actions/adminAction";
import { getBankDetail } from "../actions/usersActions";
import * as Yup from "yup";

const AddRM = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userId, setUserId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location.state?.mode === "edit";
  const id = location.state?.id;

  const fetchInvestorList = async () => {
    try {
      // setLoading(true);
      await dispatch(rmBankList());
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvestorList();
  }, [dispatch]);

  const { rmBankListData = {} } = useSelector((state) => state.admin);
  const bankListData = rmBankListData?.data || [];

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    pan: "",
    password: "",
    mobile: "",
    alt_mobile: "",
    aadhaar: "",
    addr: "",
    amfi_registered: 0,
    broker_code: "",
    bank_code: "",
    acc_no: "",
    acc_type: "",
    ifsc: "",
    bank_branch: "",
    bank_addr: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: isEditMode
      ? Yup.string().nullable()
      : Yup.string()
          .min(6, "Minimum 6 characters required")
          .required("Password is required"),
    confirmPassword: isEditMode
      ? Yup.string().nullable()
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Invalid mobile number")
      .required("Mobile number is required"),
    pan: Yup.string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format")
      .required("PAN is required"),
    aadhaar: Yup.string()
      .matches(/^\d{12}$/, "Invalid Aadhar number")
      .required("Aadhar is required"),
    addr: Yup.string().required("Address is required"),
    acc_no: Yup.string().required("Account number is required"),
    acc_type: Yup.string().required("Account type is required"),
    ifsc: Yup.string().required("IFSC is required"),
    bank_code: Yup.string().required("Bank name is required"),
    bank_branch: Yup.string().required("Branch name is required"),
  });

  useEffect(() => {
    if (isEditMode && id) {
      dispatch(rmDetail(id))
        .then((response) => {
          const data = response?.data || {};
          setUserId(data.user_id);
          setInitialValues({
            name: data.name || "",
            email: data.email || "",
            password: "", // Do not pre-fill password for security
            mobile: data.mobile || "",
            alt_mobile: data.alt_mobile || "",
            amfi_registered: data.amfi_registered,
            broker_code: data.broker_code || "",
            pan: data.pan || "",
            aadhaar: data.aadhaar || "",
            addr: data.addr || "",
            acc_no: data.acc_no || "",
            acc_type: data.acc_type || "",
            ifsc: data.ifsc || "",
            bank_code: data.bank_code || "",
            bank_branch: data.bank_branch || "",
            bank_addr: data.bank_addr || "",
          });
        })
        .catch(() => {
          toast.error("Failed to fetch details");
        });
    }
  }, [dispatch, id, isEditMode]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let response;
      if (isEditMode) {
        response = await dispatch(rmEdit(values, userId));
      } else {
        response = await dispatch(rmAdd(values));
      }
      if (response?.status === false) {
        toast.error(response?.message || "Something went wrong!");
      } else {
        toast.success(response?.message);
        navigate(-1); // Redirect to dashboard page
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An unexpected error occurred.");
    } finally {
      setSubmitting(false);
    }
  };

  const callDetilOnIFSCCode = async (ifscCode, setFieldValue) => {
    if (!ifscCode || ifscCode.length !== 11) {
      console.log("Invalid IFSC Code");
      return;
    }

    try {
      const res = await dispatch(getBankDetail(ifscCode));

      if (!res) {
        console.log("No response from API");
        return;
      }

      // // Finding matching bank code in user's bank details
      // const matchingOption = Object.entries(bankListData?.banks || {}).find(
      //   ([id]) => res.BANKCODE?.includes(id)
      // );

      // if (matchingOption) {
      //   const [value] = matchingOption;
      //   setFieldValue("bank_code", value);
      // } else {
      //   setFieldValue("bank_code", res.BANKCODE || "");
      // }

      // Setting other bank details
      setFieldValue("bank_branch", res?.BRANCH || "");
      setFieldValue("bank_addr", res?.ADDRESS || "");
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content dashboardSectionBox">
        <div className="page_inner_content_box fund_holding_section">
          <div className="d-flex justify-content-between align-itmes-center mb-2">
            <h5 className="mb-4 main-heading-title">
              {isEditMode ? "Edit RM" : "Add RM"}
            </h5>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form className="form">
                <div
                  className="card redeam_bottom_card"
                  style={{
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  <div className="row form_body justify-content-center">
                    <div className="col-xl-8 col-lg-10 col-md-11 ">
                      <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Name*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter Name"
                            id="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Email*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6 col-sm-5">
                          <label htmlFor="password" className="form-label">
                            Password*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                          <div style={{ position: "relative" }}>
                            <Field
                              type={showNewPassword ? "text" : "password"}
                              className="form-control"
                              id="password"
                              name="password"
                              placeholder="Enter Password"
                            />
                            <span
                              className="position-absolute"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                              style={{
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            >
                              {showNewPassword ? (
                                <IoEyeOffOutline className="eye-icon" />
                              ) : (
                                <IoEyeOutline className="eye-icon" />
                              )}
                            </span>
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                              style={{
                                position: "absolute",
                                bottom: "-20px", // Adjust based on UI needs
                                left: "0",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Confirm Password Field */}
                      <div className="row align-items-center mb-4">
                        <div className="col-lg-5 col-md-6 col-sm-5">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password*
                          </label>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                          <div style={{ position: "relative" }}>
                            <Field
                              type={showConfirmPassword ? "text" : "password"}
                              className="form-control"
                              id="confirmPassword"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                            />
                            <span
                              className="position-absolute"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              style={{
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            >
                              {showConfirmPassword ? (
                                <IoEyeOffOutline className="eye-icon" />
                              ) : (
                                <IoEyeOutline className="eye-icon" />
                              )}
                            </span>
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-danger"
                              style={{
                                position: "absolute",
                                bottom: "-20px", // Adjust based on UI needs
                                left: "0",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Mobile Number*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            placeholder="Enter Mobile Number"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Alternate Contact No.
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="alt_mobile"
                            name="alt_mobile"
                            placeholder="Alternate Contact No."
                          />
                          <ErrorMessage
                            name="alt_mobile"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">
                            Is AMFI Registered?*
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <div class="d-flex align-items-center gap-2 flex-wrap">
                            <div className="d-flex align-items-center">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="amfi_registered"
                                id="amfi_registered_yes"
                                value="1"
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor="amfi_registered_yes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="d-flex align-items-center">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="amfi_registered"
                                id="amfi_registered_no"
                                value="0"
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor="amfi_registered_no"
                              >
                                No
                              </label>
                            </div>
                            <ErrorMessage
                              name="amfi_registered"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Broker Code*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="broker_code"
                            name="broker_code"
                            placeholder="Enter Broker Code"
                          />
                          <ErrorMessage
                            name="broker_code"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">PAN*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="pan"
                            name="pan"
                            placeholder="Enter PAN"
                          />
                          <ErrorMessage
                            name="pan"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Aadhar Number*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="aadhaar"
                            name="aadhaar"
                            placeholder="Enter Aadhar Number"
                          />
                          <ErrorMessage
                            name="aadhaar"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Address*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            className="form-control"
                            rows="2"
                            placeholder="Enter Address"
                            id="addr"
                            name="addr"
                          />
                          <ErrorMessage
                            name="addr"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Account Number*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="acc_no"
                            name="acc_no"
                            placeholder="Enter Account Number"
                          />
                          <ErrorMessage
                            name="acc_no"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Account Type*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            as="select"
                            className="form-select custom-select"
                            defaultValue=""
                            name="acc_type"
                            onChange={(e) =>
                              setFieldValue("acc_type", e.target.value)
                            }
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            <option value="SB">Savings</option>
                            <option value="CA">Current</option>
                            <option value="CC">CashCredit</option>
                          </Field>
                          <ErrorMessage
                            name="acc_type"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">IFSC*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="ifsc"
                            placeholder="Enter IFSC"
                            name="ifsc"
                            onChange={(e) => {
                              setFieldValue("ifsc", e.target.value);
                              if (e.target.value.length === 11) {
                                callDetilOnIFSCCode(
                                  e.target.value,
                                  setFieldValue
                                );
                              }
                            }}
                          />
                          <ErrorMessage
                            name="ifsc"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Bank Name*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            as="select"
                            name="bank_code"
                            className="form-select custom-select"
                            onChange={(e) =>
                              setFieldValue("bank_code", e.target.value)
                            }
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            {bankListData.map((bank) => (
                              <option key={bank.id} value={bank.code}>
                                {bank.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="bank_code"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Branch Name*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            type="text"
                            className="form-control"
                            id="bank_branch"
                            name="bank_branch"
                            placeholder="Enter Branch Name"
                          />
                          <ErrorMessage
                            name="bank_branch"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                          <label className="form-label">Branch Address*</label>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                          <Field
                            as="textarea"
                            style={{ resize: "both" }} // Allows resizing in both directions
                            className="form-control"
                            rows="2"
                            placeholder="Enter Branch Address"
                            id="bank_addr"
                            name="bank_addr"
                          />
                          <ErrorMessage
                            name="bank_addr"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      {/* <div className="text-end mt-4">
              <button type="submit" className="btn btn-primary px-5">
                Save
              </button>
            </div> */}
                    </div>
                  </div>
                  {/* <div
              style={{
                borderTop: "2px solid #ccc",
                marginBottom: "24px"
              }}
            ></div> */}
                </div>
                <div className="card-footer form_card form_card_border">
                  <div className="d-flex justify-content-end ">
                    <button type="submit" className="form_button next_btn">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddRM;
