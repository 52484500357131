import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/profile.css";
import Loader from "../../loader/Loader-ui";
import {
  transactionListExport,
  investmentListExport,
  customerListExport,
} from "../../actions/rmAction";
import SelectDropDown from "../SelectDropDown";
import DatePickerInput from "../DatePickerInput";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const FaqAdd = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateError, setDateError] = useState("");

  useEffect(() => {
    dispatch(
      customerListExport({
        page: location.state?.from || "investors",
      })
    );
  }, [dispatch, location.state]);

  const { customerListRM = [] } = useSelector((state) => state?.admin);  

  const resetFields = () => {
    setUserId(null);
    setStartDate(null);
    setEndDate(null);
  };

  const handleExportChange = () => {
    if (!userId || !startDate || !endDate) {
      setDateError("Please select a customer and date range.");
      return;
    }

    if (new Date(endDate) < new Date(startDate)) {
      setDateError("To Date cannot be earlier than From Date.");
      return;
    }

    setDateError("");

    if (location.state?.from === "investments") {
      fetchInvestmentListExport();
    } else if (location.state?.from === "transactions") {
      fetchTransactionListExport();
    }
  };

  const fetchInvestmentListExport = async () => {
    try {
      setIsLoading(true);
      const payload = { userId, startDate, endDate };
      const response = await dispatch(investmentListExport(payload));
      handleApiResponse(response);
    } catch (error) {
      console.error("Error fetching investment list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransactionListExport = async () => {
    try {
      setIsLoading(true);
      const payload = { userId, startDate, endDate };
      const response = await dispatch(transactionListExport(payload));
      handleApiResponse(response);
    } catch (error) {
      console.error("Error fetching transaction list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiResponse = (response) => {
    if (response?.status) {
      const fileUrl = response?.data?.report_url;
      if (fileUrl) {
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = "";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        toast.success(response?.message);
        resetFields();
      } else {
        toast.error("File URL not found.");
      }
    } else {
      toast.error(response?.message);
    }
  };

  return (
    <div className="container-fluid page_background ">
      {isLoading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            {location.state?.from === "investments"
              ? "Investment Export"
              : location.state?.from === "transactions"
              ? "Transaction Export"
              : "Insta IIN Report"}
          </h5>
          <div className="card redeam_bottom_card">
            <div className="row form_body justify-content-center">
              <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                <div className="row align-items-center mb-3 personalAccordian">
                  <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                    <label className="form-label">
                      Customer <span className="red-color">*</span>
                    </label>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                    <select
                      className="form-select custom-select"
                      defaultValue=""
                      required
                      value={userId || ""}
                      onChange={(e) => setUserId(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Customer
                      </option>
                      {customerListRM?.map((customer) => (
                        <option key={customer.user_id} value={customer.user_id}>
                          {customer.inv_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row align-items-center mb-3 personalAccordian">
                  <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                    <label className="form-label">
                      From Date: <span className="red-color">*</span>
                    </label>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                    <DatePickerInput
                      selectedDate={startDate}
                      // minDate={new Date().toISOString().split("T")[0]}
                      maxDate={new Date().toISOString().split("T")[0]} // Restrict future dates
                      onChange={(date) => {
                        setStartDate(date);
                        if (endDate && new Date(date) > new Date(endDate)) {
                          setEndDate(null); // Reset endDate if it becomes invalid
                        }
                      }}                    />
                  </div>
                </div>
                <div className="row align-items-center mb-3 personalAccordian">
                  <div className="col-xl-5 col-lg-6 col-md-6 col-sm-5">
                    <label className="form-label">
                      To Date: <span className="red-color">*</span>
                    </label>
                  </div>
                  <div className="col-xl-7 col-lg-6 col-md-6 col-sm-7">
                    <DatePickerInput
                      selectedDate={endDate}
                      minDate={startDate || ""} // Ensure endDate is not before startDate
                      maxDate={new Date().toISOString().split("T")[0]} // Restrict future dates
                    //   minDate={new Date().toISOString().split("T")[0]}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
                {dateError && <p className="text-danger">{dateError}</p>}
              </div>
            </div>
          </div>
          <div className="card-footer form_card form_card_border">
            <div className="d-flex justify-content-between gap-2">
              <button
                type="button"
                className="form_button"
                onClick={resetFields}
              >
                Clear
              </button>
              <button
                type="button"
                className="form_button next_btn"
                onClick={handleExportChange}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAdd;
