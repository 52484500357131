import React, { useState } from "react";
import PlayStoreBlack from "../images/homepage/playstore_black.svg";
import AppleStoreBlack from "../images/homepage/applestore_black.svg";

const ScrollAnimation = (props) => {
  const [position, setPosition] = useState(100); // State to track position

  const handleScroll = (event) => {
    const scrollDirection = event.deltaY; // deltaY indicates scroll direction
    setPosition((prevPosition) => {
      if (scrollDirection < 0) {
        // Scrolling up: move right, with boundary constraint at 120px
        return Math.min(prevPosition + 20, 120);
      } else {
        // Scrolling down: move left, with boundary constraint at 0px
        return Math.max(prevPosition - 20, 0);
      }
    });
  };

  return (
    <section
      className="download_section"
      id="download_section"
      onWheel={handleScroll}
    >
      <div className="container-fluid p-0 section-container">
        <div className="download_inner_content align-items-center">
          {/* Left Side: Text and Download Buttons */}
          <div className="d-flex justify-content-center align-items-lg-start align-items-center  flex-column mx-auto">
            <h2 className="section-title mb-lg-5 mb-md-3 mb-3">
              Invest Anytime, Anywhere!
            </h2>
            <p className="section-description mb-lg-5 mb-md-3 mb-3">
              Elevate your financial experience with the Fundswala mobile app.
              Take control of your investments, access real-time portfolio
              updates, and make informed decisions—all from the palm of your
              hand.
            </p>
            <div className="download-buttons d-flex justify-content-center flex-wrap gap-3">
              <a
                href={process.env.REACT_APP_APPLESTORE_URL}
                className="btn btn-appstore p-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AppleStoreBlack} alt="Download on the App Store" />
              </a>
              <a
                href={process.env.REACT_APP_PLAYSTORE_URL}
                className="btn btn-playstore p-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={PlayStoreBlack} alt="Get it on Google Play" />
              </a>
            </div>
          </div>

          {/* Right Side: Phone Image with Gradient Background  */}
          <div className="phone-image-container position-relative text-center d-md-block d-none">
            <img
              src={props.src}
              alt="Fundswala App"
              className="phone-image"
              style={{
                transform: `translateX(${position}px)`,
                transition: "transform 0.9s ease-out",
              }}
            />

            <div className="brand-name text-upparcase">FUNDSWALA</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScrollAnimation;
