import React, { useEffect, useRef, useState } from "react";
import "../styles/mysip.css";
import growthTree from "../images/icons/growth-tree.svg";
import featureimage from "../images/orders/logo.svg";
import SipAndLumpsumCart from "../components/SipAndLumpsumCart";
import { useNavigate } from "react-router-dom";
import BuySipLumpsumModel from "./BuySipLumpsumModel"; // Modal import
import { useDispatch, useSelector } from "react-redux";
import { cartProceed, getCards } from "../actions/cardAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import BuyLumpsumModel from "./BuyLumpsumModel";
import { getLocalStorageUserId } from "../utils/userData";
import { toast } from "react-toastify";
import PopupModel from "../components/PopupModel";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";
const MySipAndLumpsum = () => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carddata = useSelector((state) => state.myCard);
  const [disabled, setDisable] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const closeModal = () => {
    setIsPopupVisible(false);
  };

  const userId = getLocalStorageUserId();

  // useEffect(() => {
  //   const payload = {
  //     id: userId,
  //   };
  //   dispatch(getCards(payload));
  // }, [dispatch, userId]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        id: userId,
      };

      try {
        // Prevent multiple API calls by ensuring the state is only set once
        // if (!isMounted.current) return; // Prevent fetch if the component is unmounted
        if (userId) {
          await dispatch(getCards(payload));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // if (isMounted.current) {
    fetchData();
    // } else {
    //   isMounted.current = true;
    // }

    // Cleanup function to mark the component as unmounted
    // return () => {
    //   isMounted.current = false;
    // };
  }, [dispatch, userId]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModel, setOpenModel] = useState(1);
  const [runPopUp, setRunPopUp] = useState(false);
  const [totalInvest, setTotalInvest] = useState(0);
  const handleModalOpen = () => setIsModalOpen(true); // Open Modal
  const handleModalClose = () => setIsModalOpen(false); // Close Modal
  const initialData = carddata?.addToCardData?.data;

  const formik = useFormik({
    initialValues: {
      ...initialData,
      products_in_cart: initialData?.products_in_cart?.map((product) => ({
        ...product,
        amount:
          product?.sipProductlimit == null
            ? product?.lumpsumProductlimit?.minimum_amount
            : product?.sipProductlimit?.minimum_amount, // Add default values for new fields
        invest_type: product?.sipProductlimit == null ? "lumpsum" : "sip",
        reinvest:
          product?.cart_product?.product?.reinvest_tag == "X"
            ? ""
            : product?.cart_product?.product?.reinvest_tag,
        until_cancel: 0,
      })),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      products_in_cart: Yup.array().of(
        Yup.object({
          invest_type: Yup.string().required("Investment type is required"),
          // folio: Yup.string().when("invest_type", (invest_type, schema) => {
          //   return invest_type[0] === "lumpsum" || invest_type[0] === "sip"
          //     ? schema.required("Folio is required")
          //     : schema;
          // }),
          amount: Yup.string().when("invest_type", (invest_type, schema) => {
            return invest_type[0] === "lumpsum" || invest_type[0] === "sip"
              ? schema
                  .required("Amount is required")
                  .test("min-amount", function (value) {
                    const {
                      invest_type,
                      sipProductlimit,
                      lumpsumProductlimit,
                    } = this.parent || {};
                    const minAmount =
                      invest_type === "sip"
                        ? sipProductlimit?.minimum_amount
                        : invest_type === "lumpsum"
                        ? lumpsumProductlimit?.minimum_amount
                        : 0;

                    // If minimum amount is not met, return a custom error message
                    if (!value || Number(value) < minAmount) {
                      return this.createError({
                        path: this.path,
                        message: `Amount must be at least ${minAmount}`,
                      });
                    }

                    // If validation passes, return true
                    return true;
                  })
              : schema;
          }),
          day: Yup.string()
            .notOneOf(["Select"], "Day is required")
            .when("invest_type", (invest_type, schema) => {
              return invest_type[0] === "sip"
                ? schema.required("Day is required")
                : schema;
            }),

          frequency: Yup.string()
            .notOneOf(["Select"], "frequency is required")
            .when("invest_type", (invest_type, schema) => {
              return invest_type[0] === "sip"
                ? schema.required("frequency is required")
                : schema;
            }),

          noofinstallment: Yup.string().when(
            ["invest_type", "until_cancel"],
            (values, schema) => {
              const [invest_type, until_cancel] = values;
              console.log("until_cancel", until_cancel);
              if (invest_type === "sip" && until_cancel == "0") {
                return schema
                  .required("No of Installment is required")
                  .notOneOf(["Select"], "No of Installment is required");
              }

              return schema;
            }
          ),

          // noofinstallment: Yup.string()
          //   .notOneOf(["Select"], "No of Installment is required")
          //   .when("invest_type", (invest_type, schema) => {
          //     return invest_type[0] === "sip"
          //       ? schema.required("No of Installment is required")
          //       : schema;
          //   }),
          stepup: Yup.string(),
          step_amt: Yup.string().when(
            ["invest_type", "stepup"],
            (values, schema) => {
              const [invest_type, stepup] = values;
              if (invest_type === "sip") {
                if (stepup == "true") {
                  return schema.required("Step amount is required");
                }
                return schema.notRequired();
              }
              return schema;
            }
          ),
          step_frequency: Yup.string()
            .notOneOf(["Select"], "Step frequency is required")
            .when(["invest_type", "stepup"], (values, schema) => {
              const [invest_type, stepup] = values;
              if (invest_type === "sip") {
                if (stepup == "true") {
                  return schema.required("Step frequency is required");
                }
                return schema.notRequired();
              }
              return schema;
            }),
          reinvest: Yup.string()
            .notOneOf(["Select"], "Reinvest is required")
            .required("Reinvest is required"),
          final_amt: Yup.string().when(
            ["invest_type", "stepup"],
            (values, schema) => {
              const [invest_type, stepup] = values;
              if (invest_type === "sip") {
                if (stepup == "true") {
                  return schema.required("Final amount is required");
                }
                return schema.notRequired();
              }
              return schema;
            }
          ),
          folioList: Yup.array(),
        })
      ),
    }),
    onSubmit: (values) => {
      setDisable(true);
      const payload = [];
      values?.products_in_cart?.map((res) => {
        payload.push({
          minimum_amount:
            res?.invest_type == "sip"
              ? res?.sipProductlimit?.minimum_amount == undefined
                ? "0"
                : res?.sipProductlimit?.minimum_amount
              : res?.invest_type == "lumpsum"
              ? res?.lumpsumProductlimit?.minimum_amount == undefined
                ? "0"
                : res?.lumpsumProductlimit?.minimum_amount
              : "0",
          productlimit_id: res?.sipProductlimit?.id,
          product_id: res?.cart_product?.product?.id,
          amc_code: res?.cart_product?.product?.amc_code,
          product_code: res?.cart_product?.product?.product_code,
          amc_product_id: res?.cart_product?.product?.amc_product_id,
          isin: res?.cart_product?.product?.isin,
          investment_mode:
            res?.invest_type == "sip"
              ? "S"
              : res?.invest_type == "lumpsum"
              ? "N"
              : "",
          wizard_status: values?.isWizardCompleted,
          is_additional_purchase:
            res?.cart_product?.trxn_type == "FP"
              ? false
              : res?.cart_product?.trxn_type == "AP"
              ? true
              : "",
          folioNumber: res?.folionumber,
          date_installment: res?.day,
          frequency: res?.frequency,
          tenure: res?.noofinstallment,
          reinvest: res?.reinvest
            ? res?.reinvest
            : res?.cart_product?.product?.reinvest_tag,
          amount: res?.amount,
          stepup: res?.stepup == undefined ? "" : res?.stepup,
          stepup_freq:
            res?.step_frequency == undefined ? "" : res?.step_frequency,
          stepup_amount: res?.step_amt == undefined ? "" : res?.step_amt,
          // final_amount: res?.final_amt == undefined ? 0 : 0,
          until_cancel: res?.invest_type == "sip" ? res?.until_cancel : 0,
        });
      });

      const finalResponse = {
        id: userId,
        products_in_cart: payload,
      };
      dispatch(cartProceed(finalResponse))
        .then((response) => {
          if (response) {
            if (response?.status != true) {
              setDisable(false);
              setIsPopupVisible(true);
              // toast.error("Please check Bank added or not!");
            } else {
              setDisable(false);
            }
          } else {
            setIsPopupVisible(true);
            // toast.error("Please check Bank added or not!");
            setDisable(false);
          }
        })
        .catch((error) => {
          setIsPopupVisible(true);
          // toast.error("Please check Bank added or not!");
          setDisable(false);
        });
      setRunPopUp(true);
    },
  });

  useEffect(() => {
    if (carddata?.proceedCarts?.status == true && runPopUp == true) {
      const investTypeFlag = formik?.values?.products_in_cart.some(
        (product) => product.invest_type === "sip"
      )
        ? setOpenModel(1)
        : setOpenModel(2);
      handleModalOpen();
    }
  }, [carddata?.proceedCarts, runPopUp]);

  const actionPopup = (val) => {
    if (val != 1) {
      navigate("/my-profile");
    }
  };
  useEffect(() => {
    setTotalInvest(0);
    let previousValue = 0;

    formik?.values?.products_in_cart?.forEach((res) => {
      const newValue = parseInt(res?.amount) || 0; // Handle cases where amount might be null or undefined
      // Update the total investment
      setTotalInvest((prevTotal) => {
        const updatedTotal = prevTotal + newValue;
        previousValue = newValue; // Update the previous value for the next iteration
        return updatedTotal;
      });
    });
  }, [formik?.values]);

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box page_content_overflow">
          <h5 className="mb-4 main-heading-title">My Cart</h5>

          <div className="order_box pt-2">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <SipAndLumpsumCart
                  values={formik?.values?.products_in_cart}
                  handleChange={formik?.handleChange}
                  setFieldValue={formik?.setFieldValue}
                  formik={formik}
                  resetForm={formik?.resetForm}
                />
              </div>

              {formik?.values?.products_in_cart?.length > 0 && (
                <div className="cart-bottom d-flex">
                  <p className="mb-0">
                    Total Investment Amount{" "}
                    <span className="">₹{totalInvest}</span>
                  </p>
                  <button
                    type="submit"
                    className="form_button next_btn"
                    disabled={disabled}
                    //   onClick={handleModalOpen} // Open modal on click
                  >
                    Proceed
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      {/* Pass state and close function to modal component */}
      {openModel == 1 ? (
        <BuySipLumpsumModel
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          amt={totalInvest}
          // handleOtpVerify={handleOtpVerify} // Pass OTP verify function
          // isOtpVerified={isOtpVerified} // Pass OTP state
        />
      ) : (
        <BuyLumpsumModel
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          amt={totalInvest}
        />
      )}

      {isPopupVisible && (
        <PopupModel
          id="alertModel"
          title="Information"
          content={"You have not registered for eNACH, please register."}
          image={cancel}
          actionPopup={actionPopup}
          closeModal={closeModal}
          btnColor={"btn-success"}
          later={"btn-primary"}
        />
      )}
    </div>
  );
};

export default MySipAndLumpsum;
