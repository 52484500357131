import { useState, useEffect, useRef } from "react";
import { goalAmountCalculation } from "../actions/goalsActions";
import { useDispatch } from "react-redux";
import "../styles/GoalCard.css";

function SliderComponent({
  sliderData,
  onSliderChange,
  userId,
  selectedGoal,
  // amount,
  setFieldValue,
  error,
  touched,
}) { 

  
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  // Check if onSliderChange is passed, otherwise it's static
  const isStatic = !onSliderChange;
  const [amount, setAmount] = useState(sliderData?.amount || 10000);
  const [interestRate, setInterestRate] = useState(
    sliderData?.expected_rate || 1
  );
  const [timePeriod, setTimePeriod] = useState(1);
  const [inflation, setInflation] = useState(0);

  // Initialize the states with sliderData or default values
  useEffect(() => {
    if (sliderData && !isStatic) {
      // setFieldValue("amount", sliderData?.amount);
      setAmount(sliderData?.amount || 10000);
      setInterestRate(sliderData?.expected_rate || 1);
      setTimePeriod(sliderData.tenure || 1);
      setInflation(sliderData.inflation || 0);
    }
  }, [sliderData, isStatic]);

  // Update the slider background dynamically based on value
  const updateSliderBackground = (value, element) => {
    if (element) {
      const min = element.min ? Number(element.min) : 10000;
      const max = element.max ? Number(element.max) : 5000000;
      const percentage = ((value - min) / (max - min)) * 100;
      element.style.background = `linear-gradient(to right, var(--primary-second) ${percentage}%, #e0e0e0 ${percentage}%)`;
    }
  };

  // Debounced function to update data after 500ms delay
  const debouncedUpdate = (updatedData) => {
    console.log("updatedData",updatedData);
    
    clearTimeout(timeoutRef?.current);
    timeoutRef.current = setTimeout(() => {
      if (onSliderChange) {
        onSliderChange(updatedData);
      }
      handleSliderChange(updatedData);
    }, 500);
  };

  // Handle the logic for dispatching goal calculations
  const handleSliderChange = async (updatedData) => {

    if (onSliderChange) {
      const payload = {
        id: userId,
        goal: selectedGoal || updatedData?.goal,
        rate: updatedData?.expected_rate || interestRate,
        tenure: updatedData?.tenure || timePeriod,
        amount: updatedData?.amount || amount,
        inflation:
          updatedData?.inflation !== undefined
            ? updatedData?.inflation
            : inflation,
      };

      await dispatch(goalAmountCalculation(payload));
    }
  };

  // Handle the changes in each slider
  const updateAmount = (e) => {
    const value = Math.max(10000, Math.min(5000000, Number(e?.target?.value)));
    // setAmount(value);
    updateSliderBackground(value, e?.target);
    if (!isStatic) debouncedUpdate({ amount: value });
  };

  const updateInterestRate = (e) => {
    const value = Math.max(1, Math.min(30, Number(e?.target?.value)));
    setInterestRate(value);
    updateSliderBackground(value, e?.target);
    if (!isStatic) debouncedUpdate({ expected_rate: value });
  };

  const updateTimePeriod = (e) => {
    const value = Math.max(1, Math.min(30, Number(e.target.value)));
    setTimePeriod(value);
    updateSliderBackground(value, e.target);
    if (!isStatic) debouncedUpdate({ tenure: value });
  };

  const updateInflation = (e) => {
    const value = Math.max(0, Math.min(30, Number(e.target.value)));
    setInflation(value);
    updateSliderBackground(value, e.target);
    if (!isStatic) debouncedUpdate({ inflation: value });
  };

  // Apply slider background dynamically when the values change
  useEffect(() => {
    if (!isStatic) {
      const slider = document.getElementById("amountSlider");
      if (slider) updateSliderBackground(amount, slider);

      const interestRateSlider = document.getElementById("InterestRateSlider");
      if (interestRateSlider)
        updateSliderBackground(interestRate, interestRateSlider);

      const timePeriodSlider = document.getElementById("timePeriodSlider");
      if (timePeriodSlider)
        updateSliderBackground(timePeriod, timePeriodSlider);

      const inflationSlider = document.getElementById("inflationSlider");
      if (inflationSlider) updateSliderBackground(inflation, inflationSlider);
    }
  }, [amount, interestRate, timePeriod, inflation, isStatic]);

  return (
    <div>
      {/* Amount Slider */}
      <div className="mb-lg-5 slider-container">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="amountSlider" className="slider-label">
            Amount
          </label>
          <span className="position-relative d-flex align-items-center">
            <input
              id="amountValue"
              className="slider-value d-flex gap-md-5 justify-content-between border-0"
              value={`₹${amount.toLocaleString()}`} // Add localization for the amount
              onChange={(e) => {
                // Remove non-numeric characters and ensure the value is between 10000 and 5000000
                let value = Number(e.target.value.replace(/[^0-9]/g, ""));
                value = Math.max(Math.min(value, 5000000)); // Ensure it's within range
                // setFieldValue("amount", value); // Sync with the slider's value
                setAmount(value);
                if (!isStatic) debouncedUpdate({ amount: value }); // Optional: Adjust debounce if necessary
              }}
            />
          </span>
        </div>
        <input
          type="range"
          id="amountSlider"
          min="10000"
          max="5000000"
          value={amount}
          onChange={(e) => {
            let value = Number(e.target.value.replace(/[^0-9]/g, ""));
            value = Math.max(10000, Math.min(value, 5000000));
            // setFieldValue("amount", value);
            setAmount(value);
            // updateSliderBackground(value, e.target); // Optional: Update slider background color
            updateSliderBackground(value, e.target);
            // if (!isStatic) {
            //   clearTimeout(timeoutRef.current);
            //   timeoutRef.current = setTimeout(() => {
            //     onSliderChange({ amount: value });
            //   }, 300); // Adjust debounce time (e.g., 300ms)
            // }
            // if (!isStatic) debouncedUpdate({ amount: value });
            if (!isStatic) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = setTimeout(() => {
                debouncedUpdate({ amount: value });
              }, 300); // Adjust debounce time as needed
            }
          }}
          className="slider"
          style={{
            "--thumb-color": "blue",
            // Adjust the thumb position dynamically based on the value
            "--thumb-position": `${
              ((amount - 10000) / (5000000 - 10000)) * 100
            }%`, // Position the thumb
            transition: "all 0.2s ease-in-out",
          }}
        />
      </div>

      {error && (
        <div className="text-danger  mb-3" style={{ marginTop: "-25px" }}>
          {error}
        </div>
      )}

      {/* Interest Rate Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="InterestRateSlider" className="slider-label">
            Expected Rate of Interest
          </label>
          <span className="position-relative d-flex align-items-center">
            <span
              className="position-absolute end-0 pe-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              %
            </span>
            <input
              id="InterestRateValue"
              min="1"
              max="30"
              className="slider-value d-flex gap-md-5 justify-content-between border-0 px-4"
              value={interestRate || 0} // Ensure the value is empty when no number is entered
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

                // If the input is empty, clear the value
                if (value === "") {
                  setInterestRate("");
                  return;
                }

                value = Number(value); // Convert the input to number

                // Ensure value is within bounds
                if (value < 1) value = 1;
                if (value > 30) value = 30;

                setInterestRate(value);
                if (!isStatic) debouncedUpdate({ expected_rate: value });
              }}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="InterestRateSlider"
          min="1"
          max="30"
          value={interestRate || 1} // If interestRate is empty, default to 1 for the slider
          onChange={(e) => {
            const value = Math.max(1, Math.min(30, Number(e.target.value)));
            setInterestRate(value);
            if (!isStatic) debouncedUpdate({ expected_rate: value });
          }}
          style={{
            "--thumb-color": "blue",
          }}
        />
      </div>

      {/* Time Period Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="timePeriodSlider" className="slider-label">
            Time Period
          </label>
          <span className="position-relative d-flex align-items-center">
            <span
              className="position-absolute end-0 pe-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              Yr
            </span>
            <input
              id="timePeriodValue"
              min="1"
              max="30"
              className="slider-value d-flex gap-md-5 justify-content-between border-0 px-4"
              value={timePeriod || 1}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

                // If input is cleared, allow empty input and return
                if (value === "") {
                  setTimePeriod("");
                  return;
                }

                value = Number(value); // Convert to number

                // Ensure value is within valid bounds
                if (value < 1) value = 1;
                if (value > 30) value = 30;

                setTimePeriod(value);
                if (!isStatic) debouncedUpdate({ tenure: value });
              }}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="timePeriodSlider"
          min="1"
          max="30"
          value={timePeriod || 1} // Default to 1 if timePeriod is empty
          onChange={(e) => {
            const value = Math.max(1, Math.min(30, Number(e.target.value)));
            setTimePeriod(value);
            if (!isStatic) debouncedUpdate({ tenure: value });
          }}
          style={{
            "--thumb-color": "blue",
          }}
        />
      </div>

      {/* Inflation Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="inflationSlider" className="slider-label">
            Inflation
          </label>
          <span className="position-relative d-flex align-items-center">
            <span
              className="position-absolute end-0 pe-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              %
            </span>
            <input
              id="inflationValue"
              min="0"
              max="30"
              className="slider-value d-flex gap-md-5 justify-content-between border-0 px-4"
              value={inflation || 0} // Ensure the input field is empty if inflation is undefined or null
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

                // If input is cleared, allow empty input and return
                if (value === "") {
                  setInflation(0);
                  return;
                }

                value = Number(value); // Convert to number

                // Ensure value is within valid bounds (0-30)
                if (value < 0) value = 0;
                if (value > 30) value = 30;

                setInflation(value);
                if (!isStatic) debouncedUpdate({ inflation: value });
              }}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="inflationSlider"
          min="0"
          max="30"
          value={inflation || 0} // Default to 0 if inflation is empty
          onChange={(e) => {
            const value = Math.max(0, Math.min(30, Number(e.target.value)));
            setInflation(value);
            if (!isStatic) debouncedUpdate({ inflation: value });
          }}
          style={{
            "--thumb-color": "blue",
          }}
        />
      </div>
    </div>
  );
}

export default SliderComponent;
