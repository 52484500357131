import { Link } from "react-router-dom";

const FundSmallBox = (props) => {    
    return (
      <Link to={props.url} className={`fund_invest_box_child text-center ${props.color}`} state={{search:props.state}}>
          <img src={props.imageSrc} alt="" />
          <span className="d-block mt-3">{props.label}</span>
      </Link>
    );
  };
  
  export default FundSmallBox;
  