import React, { useEffect, useState } from "react";
import "../styles/redeem.css";
import InvestmentHeaderBox1 from "../components/InvestmentHeaderBox1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectDropDown from "../components/SelectDropDown";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageUserData } from "../utils/userData";
import { swpShow, swpStore } from "../actions/holdingAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import { currentDate } from "../utils/DateFormate";
import PopupModel from "../components/PopupModel";
import successlogo from "../images/popup/success-popup.svg";
import cancel from "../images/popup/alert-image.svg";

const SWP = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const userData = getLocalStorageUserData();
  const { swpShows, swpStores, loading, error } = useSelector(
    (state) => state.holdings
  );
  const dataOfSwp = location?.state?.props;
  const closeModal = () => {
    setIsPopupVisible(false);
  };

  useEffect(() => {
    const payload = {
      id: userData?.id,
      amc_product_id: dataOfSwp?.product?.amc_product_id,
      productlimit_id: dataOfSwp?.productlimit?.id,
      folio_no: dataOfSwp?.folioNumber
    };
    dispatch(swpShow(payload));
  }, []);

  const formik = useFormik({
    initialValues: {
      id: userData?.id,
      amount:
        parseInt(
          swpShows?.data?.current_amount?.replace(/,/g, "")
        )?.toString() || "0",
      amc_product_id: swpShows?.data?.product?.amc_product_id,
      product_id: swpShows?.data?.product?.id,
      productlimit_id: dataOfSwp?.productlimit?.id,
      date_installment: "",
      isin: swpShows?.data?.product?.isin,
      current_value: swpShows?.data?.current_amount,
      current_units: swpShows?.data?.available_units,
      frequency: "",
      tenure: "",
      foliono: swpShows?.data?.folio_no?.toString(),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(/^\d+(\.\d+)?$/, "Amount must be a valid number") // Regex to allow only numbers or float values
        .required("Amount is required")
        .typeError("Amount must be a number"),
      date_installment: Yup.string()
        .notOneOf(["Select"], "Day is required")
        .required("Day is required"),
      frequency: Yup.string()
        .notOneOf(["Select"], "Frequency is required")
        .required("Frequency is required"),
      tenure: Yup.number()
        .notOneOf(["Select"], "No. of Installations is required")
        .typeError("No. of Installations must be a number")
        .required("No. of Installations is required")
        .min(1, "No. of Installations must be at least 1"),
    }),
    onSubmit: (values) => {
      dispatch(swpStore(values)).then((response) => {
        if (response) {
          if (response?.status == true) {
            navigate(
              `/orders?order=${
                response?.data?.order_id
                  ? response?.data?.order_id
                  : response?.data?.order
              }`
            );
          } else {
            setIsPopupVisible(true);
          }
        } else {
          setIsPopupVisible(true);
        }
      });
      // setIsPopupVisible(true);
    },
  });

  const actionPopup = () => {
    navigate("/portfolio");
  };

  const cancelBack = () => {
    formik.resetForm();
    navigate("/portfolio");
  };
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            <Link className="back_icon" to="/portfolio">
              <FontAwesomeIcon icon={faAngleLeft} className="color me-3" />
            </Link>
            SWP
          </h5>
          <InvestmentHeaderBox1 props={dataOfSwp} />

          <div className="card redeam_bottom_card ">
            <div className="card-body p-0">
              <div className="row align-items-center r_inner_section">
                <div className="col-md-2 col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    Available units:
                    <span className="fw-bold ms-2">
                      {swpShows?.data?.available_units}
                    </span>
                  </h6>
                </div>

                <div className="col-md-1 col-sm-auto px-lg-3 px-0 ">
                  <span className="divider"></span>
                </div>

                <div className="col-md-2 col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    Invested Amount:
                    <span className="fw-bold ms-2 ">
                      ₹{swpShows?.data?.invested_amount}
                    </span>
                  </h6>
                </div>

                <div className="col-md-1 col-sm-auto px-lg-3 px-0">
                  <span className="divider"></span>
                </div>

                <div className="col-md-2 col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    Current Amount:
                    <span className="fw-bold ms-2 ">
                      ₹{swpShows?.data?.current_amount}
                    </span>
                  </h6>
                </div>

                <div className="col-md-1 col-sm-auto px-lg-3 px-0">
                  <span className="divider"></span>
                </div>

                <div className="col-md-2 col-sm-auto my-1">
                  <h6 className="text-muted r_inner_title mb-0">
                    NAV as on {currentDate()}:
                    <span className="fw-bold ms-2 text_success">
                      ₹{swpShows?.data?.product_nav}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="card redeam_bottom_card">
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="amounttoswp" className="form-label">
                        Amount to SWP
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7">
                      <input
                        type="text"
                        className="form-control"
                        id="amounttoswp"
                        name="amount"
                        placeholder="₹"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.amount && formik.touched.amount && (
                        <div className="text-danger">
                          {formik.errors.amount}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="day" className="form-label">
                        Day
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                      <SelectDropDown
                        name="date_installment"
                        id="date_installment"
                        value={formik?.values?.date_installment}
                        changeFunction={(value) =>
                          formik?.setFieldValue("date_installment", value)
                        }
                        options={swpShows?.data?.swp_dates?.map((swp_date) => ({
                          value: swp_date,
                          label: swp_date,
                        }))}
                      />
                      {formik.errors.date_installment &&
                        formik.touched.date_installment && (
                          <div className="text-danger">
                            {formik.errors.date_installment}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="frequency" className="form-label">
                        Frequency
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                      <SelectDropDown
                        name="frequency"
                        id="frequency"
                        value={formik?.values?.frequency}
                        changeFunction={(value) => {
                          formik?.setFieldValue("frequency", value);
                          formik?.setFieldValue("tenure", "");
                        }}
                        options={
                          swpShows?.data?.frequency
                            ? Object.entries(swpShows?.data?.frequency).map(
                                ([id, label]) => ({
                                  value: id,
                                  label:
                                    label.charAt(0).toUpperCase() +
                                    label.slice(1),
                                })
                              )
                            : []
                        }
                      />
                      {formik.errors.frequency && formik.touched.frequency && (
                        <div className="text-danger">
                          {formik.errors.frequency}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4">
                    <div className="col-lg-5 col-md-6 col-sm-5">
                      <label htmlFor="installations" className="form-label">
                        No. of Installations
                      </label>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-7 position-relative">
                      <SelectDropDown
                        name="tenure"
                        id="tenure"
                        value={formik?.values?.tenure}
                        changeFunction={(value) =>
                          formik?.setFieldValue("tenure", value)
                        }
                        disabled={
                          formik?.values?.frequency != "" &&
                          formik?.values?.frequency != "Select"
                            ? false
                            : true
                        }
                        options={swpShows?.data?.tenure
                          ?.filter((swp_tenure) => {
                            switch (formik?.values?.frequency) {
                              case "Q": // Quarterly
                                return parseInt(swp_tenure) > 12; // Example logic: show dates > 12
                              case "Y": // Yearly
                                return parseInt(swp_tenure) > 60; // Example logic: show dates > 60
                              case "OM": // Other Monthly
                              case "OW": // Other Weekly
                              default: // Default case
                                return true; // Show all dates
                            }
                          })
                          ?.map((stp_date) => ({
                            value: stp_date,
                            label: stp_date,
                          }))}
                      />
                      {formik.errors.tenure && formik.touched.tenure && (
                        <div className="text-danger">
                          {formik.errors.tenure}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer form_card form_card_border">
              <div className="d-flex justify-content-between gap-2">
                <button
                  type="button"
                  onClick={cancelBack}
                  className="form_button "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={
                    parseFloat(
                      swpShows?.data?.current_amount?.replace(/,/g, "")
                    ) < parseFloat(formik?.values?.amount)
                      ? true
                      : false
                  }
                  className="form_button next_btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        {isPopupVisible && swpStores?.status != true && (
          <PopupModel
            id="alertModel"
            title="Lorem Ipsum"
            content={swpStores?.message}
            image={cancel}
            actionPopup={actionPopup}
            closeModal={closeModal}
            btnColor={"btn-success"}
          />
        )}
      </div>
    </div>
  );
};

export default SWP;
