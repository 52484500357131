import React, { useEffect, useState } from "react";
import help_icon from "../images/sidebar_icon/help_icon.svg";
import SelectDropDown from "../components/SelectDropDown";
import { useNavigate } from "react-router-dom";
import {
  userOnboardingGetDetails,
  userOnboardingStoreData,
} from "../actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageUserData } from "../utils/userData";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sanitizeInput } from "../utils/sanitizeInput";

// Functional component for the Home page
const FatcaDeclartion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const [panType, setPanType] = useState();
  const { userOnboardGetData, userOnboardStoreData, loading, error } =
    useSelector((state) => state.users);
  useEffect(() => {
    const payload = {
      id: userData?.id,
      completed_wizard_step: "2",
    };
    dispatch(userOnboardingGetDetails(payload));
  }, []);
  const handleNextClick = () => {
    navigate("/manage-nominees");
  };
  const handleBack = () => {
    window.location.href = "/bank-details";
  };

  useEffect(() => {
    const idTypeOptions = userOnboardGetData?.data?.idtypes?.map((idtype) => ({
      value: idtype.code,
      label: idtype.description,
    }));

    const panCardOption = idTypeOptions?.find(
      (option) => option.label === "PAN Card"
    );
    if (panCardOption && !formik.values.idtype) {
      formik.setFieldValue("idtype", panCardOption.value);
      formik.setFieldValue(
        "id_no",
        userOnboardGetData?.data?.profile?.pan
          ? userOnboardGetData?.data?.profile?.pan
          : userData?.pan
      );
    }
  }, [userOnboardGetData]);

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });

  const formik = useFormik({
    initialValues: {
      annual_income: userOnboardGetData?.data?.fatca_kyc?.annual_income || "",
      address_type: userOnboardGetData?.data?.fatca_kyc?.address_type || "",
      source_of_wealth:
        userOnboardGetData?.data?.fatca_kyc?.source_of_wealth || "",
      birth_place: userOnboardGetData?.data?.fatca_kyc?.birth_place || "",
      id_no: userOnboardGetData?.data?.fatca_kyc?.id_no
        ? userOnboardGetData?.data?.fatca_kyc?.id_no
        : userData?.pan,
      idtype: userOnboardGetData?.data?.fatca_kyc?.idtype || "",
      tax_residency: "N",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      annual_income: Yup.string()
        .notOneOf(["Select"], "Annual Income is required")
        .required("Annual Income is required"),
      address_type: Yup.string()
        .notOneOf(["Select"], "Address Type is required")
        .required("Address Type is required"),
      source_of_wealth: Yup.string()
        .notOneOf(["Select"], "Source of Income is required")
        .required("Source of Income is required"),
      birth_place: Yup.string()
        .test(sanitizeTest("Birth Place"))
        .max(100, "Input should not accept more than 100 characters")
        .required("Birth Place is required")
        .matches(/^[A-Za-z\s]+$/, "Only alphabets allowed"),
      idtype: Yup.string()
        .notOneOf(["Select"], "ID Type is required")
        .required("ID Type is required"),
      id_no: Yup.string().required("ID Number is required"),
      // tax_residency: Yup.string().required("Tax Residency is required"),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        id: userData?.id,
        completed_wizard_step: 2,
      };

      dispatch(userOnboardingStoreData(payload, userData?.id));
    },
  });

  useEffect(() => {
    if (userOnboardGetData) {
      if (
        userOnboardGetData?.wizard_step == 4 ||
        userOnboardGetData?.wizard_step == 5
      ) {
        window.location.href = "/dashboard";
      } else if (2 <= userOnboardGetData?.wizard_step) {
      } else {
        if (userOnboardGetData?.wizard_step == 0) {
          window.location.href = "/personal-details";
        } else if (userOnboardGetData?.wizard_step == 1) {
          window.location.href = "/bank-details";
        } else if (userOnboardGetData?.wizard_step == 2) {
          window.location.href = "/fatca-declartion";
        }
      }
    }
  }, [userOnboardGetData]);
  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="progress-container page_inner_content_box">
          <div className="step-info">
            <span>Step 3 of 4</span>
          </div>
          <h4 className="page-title mb-0">FATCA Declaration</h4>
        </div>
        <div className="progress-bar">
          <div className="progress-filled" style={{ width: "75%" }}></div>
        </div>
        <div className="page_inner_content_box d-flex flex-wrap justify-content-between ">
          <form
            id="fatca_form"
            onSubmit={formik.handleSubmit}
            className="step_form"
          >
            <div className="card border-0">
              <div className="card-body form_card">
                <div className="row">
                  <div className="col-xl-7 col-lg-8 col-12 order-lg-1 order-2 ">
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label for="annual_income" className="form-label">
                          Annual Income*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="annual_income"
                          id=""
                          value={formik.values.annual_income}
                          changeFunction={(value) =>
                            formik?.setFieldValue("annual_income", value)
                          }
                          options={userOnboardGetData?.data?.annual_income?.map(
                            (annual_incomes) => ({
                              value: annual_incomes.app_income_code,
                              label: annual_incomes.app_income_desc,
                            })
                          )}
                        />
                        {formik.touched.annual_income &&
                        formik.errors.annual_income ? (
                          <div className="text-danger">
                            {formik.errors.annual_income}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label for="address_type" className="form-label">
                          Address Type*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="address_type"
                          id=""
                          value={formik.values.address_type}
                          changeFunction={(value) =>
                            formik?.setFieldValue("address_type", value)
                          }
                          options={userOnboardGetData?.data?.addresstypes?.map(
                            (addresstype) => ({
                              value: addresstype.code,
                              label: addresstype.description,
                            })
                          )}
                        />
                        {formik.touched.address_type &&
                        formik.errors.address_type ? (
                          <div className="text-danger">
                            {formik.errors.address_type}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label for="source_of_wealth" className="form-label">
                          Source of Income*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="source_of_wealth"
                          id=""
                          value={formik.values.source_of_wealth}
                          changeFunction={(value) =>
                            formik?.setFieldValue("source_of_wealth", value)
                          }
                          options={userOnboardGetData?.data?.source_of_wealth?.map(
                            (source_of_wealths) => ({
                              value: source_of_wealths.code,
                              label: source_of_wealths.source_of_wealth,
                            })
                          )}
                        />
                        {formik.touched.source_of_wealth &&
                        formik.errors.source_of_wealth ? (
                          <div className="text-danger">
                            {formik.errors.source_of_wealth}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label for="birth_place" className="form-label">
                          Birth Place*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="birth_place"
                          placeholder="Enter Birth Place"
                          {...formik.getFieldProps("birth_place")}
                        />
                        {formik.touched.birth_place &&
                        formik.errors.birth_place ? (
                          <div className="text-danger">
                            {formik.errors.birth_place}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label for="idtype" className="form-label">
                          ID Type*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <SelectDropDown
                          name="idtype"
                          disabled={formik.values.idtype == "C" ? true : false}
                          id=""
                          value={formik.values.idtype}
                          changeFunction={(value) =>
                            formik?.setFieldValue("idtype", value)
                          }
                          options={userOnboardGetData?.data?.idtypes?.map(
                            (idtype) => ({
                              value: idtype.code,
                              label: idtype.description,
                            })
                          )}
                        />
                        {formik.touched.idtype && formik.errors.idtype ? (
                          <div className="text-danger">
                            {formik.errors.idtype}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-5 col-md-6">
                        <label for="id_no" className="form-label">
                          ID Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="id_no"
                          placeholder="Enter Id Number"
                          disabled={formik.values.idtype == "C" ? true : false}
                          {...formik.getFieldProps("id_no")}
                        />
                        {formik.touched.id_no && formik.errors.id_no ? (
                          <div className="text-danger">
                            {formik.errors.id_no}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-4 ps-xl-5 order-lg-2 order-1 mb-lg-0 mb-4">
                    <div className="info-box px-lg-4">
                      <div className="d-flex align-items-start">
                        <img
                          src={help_icon}
                          alt=""
                          className="me-3"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <div>
                          <h5 className="fw-semibold mb-3">
                            Stay Compliant with Regulatory Requirements
                          </h5>
                          <p className="mb-2">
                            As part of compliance, we need some additional
                            information for tax purposes. This step helps ensure
                            that your investments meet regulatory standards.
                          </p>
                          <p className="highlight_text">
                            You can edit these details later if needed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer form_card card_footer">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="form_button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button type="submit" className="form_button next_btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FatcaDeclartion;
