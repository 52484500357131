import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import "../styles/fund.css"; // Import styles
import NAVMovementGraph from "../components/NAVMovementGraph";
import SIPSliderComponent from "../components/SIPSliderComponent";
import FundDetailHeader from "../components/FundDetailHeader";
import RecommendedFundCard from "../components/RecommendedFundPlan2";
import LumsumSliderComponent from "../components/LumsumSliderComponent";
import {
  Link,
  redirect,
  useLocation,
  useNavigate,
  matchPath,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getExploreFundsList,
  getAMCLists,
  getSimilarFunds,
  getSimilarFundsGuest,
  getFundDetails,
  getFundDetailsForGuest,
} from "../actions/exploreFundsActions";
import {
  getLocalStorageUserToken,
  getLocalStorageUserId,
} from "../utils/userData";
import Loader from "../loader/Loader-ui";
import slugify from "slugify"; // Install with `npm install slugify`
const token = getLocalStorageUserToken();
// Functional component for the funddetails page
const FundDetail2 = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams(); // Get full slug from URL
  const amc_code = location.state?.data || slug.split("/").pop();
  const userId = getLocalStorageUserId();
  const { exploreFundsDetail = {} } = useSelector(
    (state) => state?.exploreFunds
  );

  // Function to fetch funds data
  const fetchFundsData = useCallback(async () => {
    // if (userId) {
    try {
      setIsLoading(true);
      if (!userId) {
        setIsLoading(true);
        await dispatch(
          getFundDetailsForGuest({
            amc_product_id: amc_code,
          })
        );
      } else {
        setIsLoading(true);
        await dispatch(
          getFundDetails({
            id: userId,
            amc_product_id: amc_code,
          })
        );
      }
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
    // }
  }, [dispatch, userId, amc_code]);

  useEffect(() => {
    // if (!isFetched.current) {
    fetchFundsData();
    // isFetched.current = true;
    // }
  }, [fetchFundsData]);

  const fundDetails = exploreFundsDetail?.data?.product || {};

  const FundsDataState = location.state?.data;
  const currentPageURL = window.location.pathname;
  const { similarFunds = {}, loading } = useSelector(
    (state) => state.exploreFunds
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token) {
      const payload = amc_code;
      dispatch(getSimilarFunds(payload));
    } else {
      const payload = amc_code;
      dispatch(getSimilarFundsGuest(payload));
    }
  }, [dispatch, amc_code]);

  // Extract data from similarFunds array
  const fundsData = similarFunds?.data?.similar_funds || {}; // Assuming `similarFunds.data` contains the array

  let viewPageUrl;
  let redirectUrl;

  // Check if currentPageURL matches /fund-detail or /fund-detail/:slug
  if (
    matchPath({ path: "/fund-detail", exact: true }, currentPageURL) ||
    matchPath({ path: "/fund-detail/:slug", exact: true }, currentPageURL) ||
    matchPath({ path: "/fund-detail/:slug/:slug", exact: true }, currentPageURL)
  ) {
    viewPageUrl = "/fund-detail";
    redirectUrl = "/explore-funds";
  } else {
    viewPageUrl = "/explore/fund-detail";
    redirectUrl = "/explore";
  }
  return (
    <>
      {isLoading ? (
        <>
          <div className="container-fluid page_background ">
              <div className="">
                  <Loader />
            </div>
          </div>
        </>
      ) : (
        <div className="container-fluid page_background ">
          <div className="page-inner-content fund_section mb-3">
            <div className="">
              <div className="card overflow-hiddden border-0">
                <FundDetailHeader actionUrl="/explore" data={fundDetails} />
                <div className="card-body page_inner_content_box">
                  <div className="row  ">
                    <div className="col-xl-7 col-lg-6 mb-lg-0 mb-4">
                      <div className="card h-100 overflow-hiddden border-0">
                        <div className="card-body h-100 calculator-container">
                          <NAVMovementGraph data={fundDetails} />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                      <div className="card border-0">
                        <div className="calculator-container">
                          <h5 className="mb-3">Return Calculator</h5>

                          <ul
                            className="nav nav-tabs gap-2"
                            id="calculatorTabs"
                            role="tablist"
                          >
                            <li className="nav-item me-0" role="presentation">
                              <button
                                className="nav-link active"
                                id="sip-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#sip"
                                type="button"
                                role="tab"
                                aria-controls="sip"
                                aria-selected="true"
                              >
                                SIP
                              </button>
                            </li>
                            <li className="nav-item me-0" role="presentation">
                              <button
                                className="nav-link"
                                id="lumpsum-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#lumpsum"
                                type="button"
                                role="tab"
                                aria-controls="lumpsum"
                                aria-selected="false"
                              >
                                Lumpsum
                              </button>
                            </li>
                          </ul>

                          <div className="tab-content mt-md-4 mt-4">
                            <div
                              className="tab-pane fade show active"
                              id="sip"
                              role="tabpanel"
                              aria-labelledby="sip-tab"
                            >
                              <SIPSliderComponent />
                            </div>
                            <div
                              className="tab-pane fade"
                              id="lumpsum"
                              role="tabpanel"
                              aria-labelledby="lumpsum-tab"
                            >
                              <LumsumSliderComponent />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="goal_planner_content mt-4">
                    <h5 className="mb-4 main-heading-title">Similar Funds</h5>
                    <div className="fund-card-content ">
                      <div className="row ">
                        {/* {loading ? (
                      <p>Loading...</p>
                    ) : similarFunds === null ? (
                      <p>No similar funds found!</p>
                    ) : (
                      similarFunds
                    )} */}
                        {/* Iterate through each key (array) in similar_funds */}
                        {Object.keys(fundsData).length > 0 ? (
                          Object.keys(fundsData).map((key) =>
                            fundsData[key].map((fund, index) => (
                              <div
                                key={`${key}-${index}`}
                                className="col-xxl-4 col-lg-6 mb-xxl4- mb-md-4 mb-3"
                                onClick={() => {
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                <RecommendedFundCard
                                  id={fund.id}
                                  amcId={fund.amc_product_id}
                                  isin={fund.isin}
                                  image={fund.amc?.icon || ""}
                                  bookmark={fund.watch_list} // Assuming `watch_list` indicates bookmarked status
                                  plus={fund.cart_list} // Example logic for the `plus` icon
                                  title={fund.product_long_name}
                                  badge={fund.plan_type} // e.g., "REGULAR"
                                  badge2={fund.asset_class}
                                  price1={`₹${fund.nav?.nav || "0.00"}`} // Current NAV price
                                  navDate={fund.nav?.date}
                                  price2={`₹${
                                    fund.productlimits?.find(
                                      (limit) => limit.sub_trxn_type === "N"
                                    )?.minimum_amount || "0.00" // added this condition to show SIP and lumpsum price difference
                                  }`}
                                  price3={`₹${
                                    fund.productlimits?.find(
                                      (limit) => limit.sub_trxn_type === "S"
                                    )?.minimum_amount || "0.00"
                                  }`}
                                  product={fund}
                                  url={`${viewPageUrl}/${slugify(
                                    fund.product_long_name,
                                    { lower: true, strict: true }
                                  )}`} // Add fund name to URL
                                  // url={viewPageUrl} // Dynamic URL
                                  redirectUrl={redirectUrl}
                                />
                              </div>
                            ))
                          )
                        ) : (
                          <p>No similar funds available.</p>
                        )}
                        {/* <div className="col-xxl-4 col-lg-6 mb-xxl-0 mb-md-4 mb-3">
                      <RecommendedFundCard
                        bookmark={true}
                        plus={true}
                        title="SBI PSU Direct Plan Growth"
                        badge="Growth"
                        price1="₹19.68"
                        price2="₹100.00"
                        price3="₹100.00"
                        url="/explore/fund-detail"
                      />
                    </div>
                    <div className="col-xxl-4 col-lg-6  mb-xxl-0 mb-md-4 mb-3">
                      <RecommendedFundCard
                        bookmark={true}
                        plus={true}
                        title="SBI PSU Direct Plan Growth"
                        badge="Growth"
                        price1="₹19.68"
                        price2="₹100.00"
                        price3="₹100.00"
                        url="/explore/fund-detail"
                      />
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FundDetail2;
