import React, { useEffect, useState } from "react";
import help_icon from "../../images/sidebar_icon/help_icon.svg";
import InvestmentHeaderBox1 from "../InvestmentHeaderBox1";
import "../../styles/profile.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import pen_inner from "../../images/my_profile/pen_inner.svg";
import Loader from "../../loader/Loader-ui";
import { customerStatusList } from "../../actions/adminAction";
import { useDispatch, useSelector } from "react-redux";

// Customer IIN status data
const customerData = [
  {
    name: "Rohit Deepak Pandit",
    description: "IIN confirmation link not confirmed by Investor",
    status: "No",
  },
  {
    name: "Vishal Satish Kokate",
    description: "Invalid KYC Status",
    status: "No",
  },
  {
    name: "Anil Dilip Waychal",
    description: "Name misspell from KYC",
    status: "No",
  },
  {
    name: "Deepak Debadwar",
    description: "Reason not provided by NSE",
    status: "No",
  },
  {
    name: "Ruchi Sabu",
    description: "KYC status not available",
    status: "No",
  },
];

const FaqAdd = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInvestorList = async () => {
    try {
      setIsLoading(true);
      await dispatch(customerStatusList());
    } catch (error) {
      console.error("Error fetching watchlist:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchInvestorList();
  }, [dispatch]);

  const { customerStatusListData = {} } = useSelector((state) => state?.admin);
  const data = customerStatusListData?.data || [];
  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">Customers IIN Status</h5>

          {/* Customer Status Table */}
          <div className="card redeam_bottom_card mapping-details-box investor-status-box">
            <div className="table-responsive bankDetailTable">
              {isLoading && (
                <div className="loader-overlay">
                  <Loader />
                </div>
              )}
              <table className="table_transaction">
                <thead>
                  <tr>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Activation Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((customer, index) => (
                      <tr key={index}>
                        <td>{customer?.inv_name}</td>
                        <td>{customer?.iin_reason}</td>
                        <td>{customer?.activation_status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className="table-body">
                      <td colSpan="3" className="no-data-message">
                        No Customer IIN Status in your list.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAdd;
