import React from "react";
import "../../styles/profileAccordian.css"; // Import styles
import BankDetail from "../../components/Admin/ViewBankDetail";
import PersonelDetail from "../../components/Admin/ViewPersonelDetail";
import ManageNominees from "../../components/Admin/ViewNominees";
// import FatcaDeclaration from "./FatcaDeclaration";



const MyProfileAccordian = ({data}) => {
  return (
    <div className="accordion profile_accordian my-lg-3" id="accordionExample">

      <PersonelDetail data={data}/>

      <BankDetail data={data}/>

      <ManageNominees data={data}/>


    </div>
  );
};

export default MyProfileAccordian;
