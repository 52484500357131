import React from "react";
import "../../styles/transaction.css";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const sampleData = {
  orders: [
    {
      name: "Prajakta Rahul Baravkar",
      post_date: "30-12-2024",
      scheme: "Quant Small Cap Fund - Regular Plan",
      target_scheme: "N/A",
      amount: "1000.00",
      transaction_type: "STP IN",
      status: "SIP",
    },
    {
      name: "Indra H. L.",
      post_date: "21-12-2024",
      scheme: "Canara Robeco Blue Chip Equity Fund - Regular Growth",
      target_scheme: "N/A",
      amount: "1000.00",
      transaction_type: "Additional Purchase",
      status: "STP",
    },
    {
      name: "Lalitha K. S.",
      post_date: "17-12-2024",
      scheme:
        "SBI Magnum Childrens Benefit Fund - Investment Plan Regular Growth",
      target_scheme: "N/A",
      amount: "1000.00",
      transaction_type: "Additional Purchase",
      status: "SIP",
    },
  ],
};

const TransactionTable = ({ data, currentPage }) => {
  return (
    <div className="table-container table-responsive">
      <table className="table_transaction">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Date</th>
            <th>Scheme</th>
            <th>Target Scheme</th>
            <th>Amount</th>
            <th>Transaction Type</th>
            <th>Sub Type</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data?.map((transaction, index) => (
              <tr
                key={index}
                className={`${
                  transaction?.transaction?.trxn_status?.toLowerCase() ||
                  transaction?.status?.toLowerCase()
                } transaction_row`}
              >
                <td>{(currentPage - 1) * 10 + index + 1}</td>
                <td>{transaction?.inv_name || "NA"}</td>
                <td>
                  {transaction?.post_date
                    ? new Date(transaction?.post_date).toLocaleDateString(
                        "en-GB"
                      )
                    : "NA"}
                </td>
                <td className="white-space-normal">
                  {transaction?.product_long_name}
                </td>
                <td>{transaction?.target_source_scheme || "NA"}</td>
                <td className="text-right">{transaction?.amount || "NA"}</td>
                <td className="white-space-normal">
                  {transaction?.purchase_type || "NA"}
                </td>
                <td>
                  <span
                    className={`status ${
                      transaction?.sub_purchase_type?.toLowerCase() || ""
                    }`}
                  >
                    {transaction?.sub_purchase_type || "NA"}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr style={{ height: "200px" }}>
              <td colSpan="6" className="no-data-message">
                No Investment found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
