import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik"; // Import Formik
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { messageType, sendMessage} from "../actions/messageAction";
import {getLocalStorageUserData} from "../utils/userData";

const RaiseTicket = () => {
  const [userTypeVal, setUserTypeVal] = useState([]);
  const dispatch = useDispatch();
  const isMounted = useRef(false); 
  const navigate = useNavigate();
  const messagetype = useSelector((state) => state.messages);

  const userData = getLocalStorageUserData();

  useEffect(() => {
    const fetchData = async()=>{
      const payload = { id: userData?.id };
      try {
        if (userData?.id) {
         await Promise.all([dispatch(messageType(payload))]);
        }
      } catch (error) {
        console.error("API call failed: ", error);
      }
    }
    // if (isMounted.current) {
      fetchData();
    // } else {
    //   isMounted.current = true;
    // }
  }, [dispatch, userData?.id]);

  useEffect(() => {
    if (messagetype && messagetype?.messagetype?.data?.type) {
      const data = messagetype?.messagetype?.data?.type?.map((item) => ({
        label: item,  // Assuming the message type is a string
        id: item,     // Using the message type as ID
      }));
      setUserTypeVal(data);  // Set the state with the fetched data
    }
  }, [messagetype]);

  const formik = useFormik({
    initialValues: {
      type: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Message Type is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: (values) => {
      const payload ={
        id: userData?.id,
        type :values?.type,
        subject : values?.subject,
        message : values?.message
      }
      dispatch(sendMessage(payload));
      navigate("/ticket-message")
    },
  });

  // useEffect(() => {
  //   // Assuming you want to populate the message type dropdown from the API
  //   axios
  //     .get(type)
  //     .then((res) => {
  //       let messageType = res.data; // Assuming response is in data field
  //       let data = messageType.map((d) => ({
  //         label: `${d.name}`,
  //         id: d._id,
  //       }));
  //       setUserTypeVal(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <div className="container-fluid page_background ">
      <div className="page-inner-content mb-3">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">
            <Link className="back_icon" to="/ticket-message">
              <FontAwesomeIcon icon={faAngleLeft} className="color me-3" />
            </Link>
            Raise Ticket
          </h5>

          <div
            className="card redeam_bottom_card"
            style={{
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <div className="row form_body justify-content-center">
              <div className="col-xl-7 col-lg-11 col-md-10 my-2">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5">
                      <label htmlFor="type" className="form-label">
                        Message Type <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7">
                      <select
                        className="form-select"
                        id="type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select</option>
                        {userTypeVal?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.type &&
                        formik.touched.type && (
                          <div style={{ color: "red", fontSize: "0.875rem" }}>
                            {formik.errors.type}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5">
                      <label className="form-label">
                        Subject <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Enter Subject"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.subject && formik.touched.subject && (
                        <div style={{ color: "red", fontSize: "0.875rem" }}>
                          {formik.errors.subject}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-lg-4 mb-md-4 mb-sm-4">
                    <div className="col-xl-4 col-lg-5 col-md-5 col-sm-5">
                      <label className="form-label">
                        Message <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="col-xl-8 col-lg-7 col-md-7 col-sm-7">
                      <textarea
                        className="form-control"
                        id="message"
                        placeholder="Type Your Message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.errors.message && formik.touched.message && (
                        <div style={{ color: "red", fontSize: "0.875rem" }}>
                          {formik.errors.message}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Submit button inside the form */}
                  <div className="card-footer form_card form_card_border">
                    <div className="d-flex justify-content-between gap-2">
                      <Link to="/ticket-message" className="form_button">
                        Cancel
                      </Link>
                      <button type="submit" className="form_button next_btn">
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaiseTicket;
