import React, { useEffect, useState } from "react";
import "../styles/transaction.css";
import addIcon from "../images/my_profile/add.svg"; // Add your add icon here
import manage_nominees from "../images/my_profile/manage_nominees.svg";
import pen_inner from "../images/my_profile/pen_inner.svg";
import pen from "../images/my_profile/pen.svg";
import pen_bank from "../images/my_profile/pen_bank.svg";
import set_auto_pay from "../images/my_profile/set_auto_pay.svg";
import map_ac from "../images/my_profile/map_ac.svg";
import deleteIMG from "../images/my_profile/delete.svg";
import enach_registration from "../images/my_profile/enach_registration.svg";
import arrow from "../images/my_profile/arrow.svg";
import calendar_month from "../images/my_profile/calendar_month.svg";
import DatePickerInput from "./DatePickerInput";
import SelectDropDown from "./SelectDropDown";
import { useFormik } from "formik";
import * as Yup from "yup";
import view from "../images/my_profile/eye.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  userEnachRegitrationCreate,
  userEnachRegitrationDeleteMandates,
  userEnachRegitrationGet,
  userEnachRegitrationMap,
  userEnachRegitrationStore,
} from "../actions/usersActions";
import { getLocalStorageUserData } from "../utils/userData";
import { convertDateToDDMMMYYYY } from "../utils/DateFormate";
import PopupModel from "./PopupModel";
import cancel from "../images/popup/alert-image.svg";

const EnachRegistration = () => {
  const [currentView, setCurrentView] = useState("view");
  const userData = getLocalStorageUserData();
  const [type, setType] = useState("");
  const [bankId, setBankId] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Manage popup visibility
  const [mandateId, setMandateId] = useState("");
  const dispatch = useDispatch();
  const { userEnachRegistrationsGet, userEnachRegitrationsDeleteMandates } =
    useSelector((state) => state.users);
  const channelOption = [
    { value: "NET", label: "Net-banking" },
    { value: "DC", label: "Debit Card" },
  ];

  const closeModal = () => {
    setIsPopupVisible(false);
  };

  const actionPopup = () => {
    const payload = {
      id: userData.id,
      mandate_id: mandateId,
    };
    dispatch(userEnachRegitrationDeleteMandates(payload));
  };

  const formik = useFormik({
    initialValues: {
      acc_no: "",
      bank_holder_name: "",
      ifsc_code: "",
      bank_id: "",
      mandate_amount: "",
      start_date: convertDateToDDMMMYYYY(Date.now()),
      end_date: "",
      channel_type: "",
      until_cancelled: "N",
      terms_condition: "N",
    },
    validationSchema: Yup.object({
      acc_no: Yup.string()
        .required("Account Number is required")
        .matches(/^\d+$/, "Account Number must be numeric"),
      bank_holder_name: Yup.string()
        .required("Account Holder Name is required")
        .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed"),
      ifsc_code: Yup.string()
        .required("IFSC Code is required")
        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code"),
      bank_id: Yup.string().required("Bank Name is required"),
      mandate_amount: Yup.number()
        .required("Amount is required")
        .typeError("Amount must be a valid number")
        .positive("Amount must be positive"),
      start_date: Yup.date().required("Start Date is required"),
      end_date: Yup.string().when(
        "until_cancelled",
        (until_cancelled, schema) => {
          return until_cancelled[0] === "N"
            ? schema.required("End Date is required")
            : schema;
        }
      ),
      channel_type: Yup.string().required("Channel Type is required"),
      terms_condition: Yup.string().oneOf(
        ["Y"],
        "You must accept Terms & Conditions"
      ),
    }),
    onSubmit: (values) => {
      const formatDateeEndDate = convertDateToDDMMMYYYY(values?.end_date);
      const formatDateeStartDate = convertDateToDDMMMYYYY(values?.start_date);

      const payload = {
        ...values,
        end_date: values?.end_date,
        start_date: values?.start_date,
        id: userData?.id,
        end_date: values?.until_cancelled == "Y" ? "" : values?.end_date,
      };
      const payloadOfEnachCreate = {
        id: bankId,
        type: type,
      };
      // if (type == "wizard" || type == "manual") {
      // setType("");
      // dispatch(userEnachRegitrationCreate(payloadOfEnachCreate));
      // } else {
      dispatch(userEnachRegitrationStore(payload));
      setTimeout(() => {
        setCurrentView("view");
      }, 1000);
      // }
    },
  });

  useEffect(() => {
    const payload = {
      id: userData.id,
    };
    dispatch(userEnachRegitrationGet(payload));
  }, []);
  // Handlers for view and add clicks
  const handleViewClick = () => {
    setCurrentView("view");
  };
  const handleAddClick = () => {
    setCurrentView("add");
    setType("");
  };
  const handleEditClick = (type, bank) => {
    setCurrentView("edit");
    setType(type);
    setBankId(bank.id);
    formik.setFieldValue("acc_no", bank?.acc_no);
    formik.setFieldValue("bank_holder_name", bank?.bank_holder_name);
    formik.setFieldValue("ifsc_code", bank?.ifsc_code);
    formik.setFieldValue("bank_id", bank?.id);
    formik.setFieldValue("mandate_amount", "");
    formik.setFieldValue("start_date", convertDateToDDMMMYYYY(Date.now()));
    formik.setFieldValue("end_date", "");
    formik.setFieldValue("channel_type", "");
  };

  const deleteMandate = (mandateId) => {
    setMandateId(mandateId);
    setIsPopupVisible(true);
  };
  const handleMap = (bankId) => {
    dispatch(userEnachRegitrationMap(bankId));
  };
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingTen">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTen"
          aria-expanded="false"
          aria-controls="collapseTen"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img
                src={manage_nominees}
                alt=""
                className="personel_details_img"
              />
              {/* <span className="personal_inner_text">eNACH Registration</span> */}
              <span className="personal_inner_text">
                {currentView === "view" && "eNACH Registration"}
                {currentView === "add" && "eNACH Registration Add"}
                {currentView === "edit" && "eNACH Registration Edit"}
              </span>
            </div>

            {/* <div className="edit_personel">
                            <span className="badge  editBadge">
                                <img src={pen} alt="" className="pen_image" />
                                <span className="editInner d-sm-block d-none">Edit</span>
                            </span>
                        </div> */}
          </div>
        </button>
      </h2>
      <div
        id="collapseTen"
        className="accordion-collapse collapse"
        aria-labelledby="headingTen"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />

        <div className="accordion-body profile_accordion_body redeam_bottom_card">
          <div className="d-flex justify-content-end align-items-right">
            <div className="edit_personel">
              {currentView !== "view" && (
                <>
                  <span
                    onClick={handleViewClick}
                    className="badge editBadge profile-mr"
                  >
                    <img src={view} alt="View Icon" className="pen_image" />

                    <span className="editInner d-sm-block d-none">View</span>
                  </span>
                </>
              )}
              {/* Remove add button as per sonali */}
              {/* {currentView === "view" && (
                <span
                  onClick={handleAddClick}
                  className="badge editBadge addbank-btn-mb profile-mr"
                >
                  <img src={addIcon} alt="" className="pen_image" />

                  <span
                    className="editInner d-sm-block d-none"
                    onClick={handleAddClick}
                  >
                    Add
                  </span>
                </span>
              )} */}
            </div>
          </div>

          {/* Bank Details Table */}

          {currentView === "view" && (
            <div>
              <h3 className="bankDetailHeading ps-md-3">Bank</h3>
              <div className="table-responsive manageNomineesDetailTable">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">Bank Name</th>
                      <th scope="col">Account No.</th>
                      <th scope="col">Batch Name</th>
                      <th scope="col">Set Auto Pay</th>
                      <th scope="col">Map A/C</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userEnachRegistrationsGet?.data?.banks?.map?.length > 0 &&
                      userEnachRegistrationsGet?.data?.banks?.map((bank) => {
                        return (
                          <tr>
                            <td>{bank?.bank_name}</td>
                            <td>{bank?.acc_no}</td>
                            <td>{bank?.branch_name}</td>
                            <td>
                              <div className="d-flex">
                                <div
                                  className="pen_bank_inner mx-2 set_auto_pay_same_content"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Manually Register e-Mandate"
                                >
                                  <img
                                    src={pen_bank}
                                    alt="Edit Nominee"
                                    className="pen_image_inner_bank"
                                    onClick={() => {
                                      handleEditClick("manual", bank);
                                    }}
                                  />
                                </div>
                                <div
                                  className="auto_pay_inner set_auto_pay_same_content"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Register e-Mandate using wizard"
                                >
                                  <img
                                    src={set_auto_pay}
                                    alt="Edit Nominee"
                                    className="pen_image_inner_bank"
                                    onClick={() => {
                                      handleEditClick("wizard", bank);
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className="map_ac_inner set_auto_pay_same_content"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="If registered new account, click to link it with NSE profile."
                              >
                                <img
                                  src={map_ac}
                                  alt="Edit Nominee"
                                  className="pen_image_inner_bank"
                                  onClick={() => handleMap(bank.id)}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
              </div>
              <h3 className="bankDetailHeading ps-md-3">Mandate</h3>
              <div className="table-responsive manageNomineesDetailTable">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">Bank Name</th>
                      <th scope="col">Account Type</th>
                      <th scope="col">Account No.</th>
                      <th scope="col">Nach Status</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Nach Limit</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userEnachRegistrationsGet?.data?.mandates?.length > 0 ? (
                      userEnachRegistrationsGet?.data?.mandates?.map((res) => {
                        return (
                          <tr key={res?.id}>
                            <td>{res?.bank_name}</td>
                            <td>{res?.ac_type}</td>
                            <td>{res?.account_no}</td>
                            <td>{res?.ach_mandate_status}</td>
                            <td>{res?.from_date}</td>
                            <td>{res?.to_date}</td>
                            <td>{res?.amount}</td>
                            <td>
                              {res?.ach_mandate_status === "REJECTED" && (
                                <div
                                  className="delete_inner"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Delete"
                                >
                                  <img
                                    src={deleteIMG}
                                    alt="Edit Nominee"
                                    className="delete_inner_bank"
                                    onClick={() => deleteMandate(res?.id)}
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          Mandate Not Done.
                        </td>
                      </tr>
                    )}
                  </tbody>

                  {userEnachRegistrationsGet?.data?.mandates?.map?.length <=
                    0 && <p className="text-center">No mandates found</p>}
                </table>
              </div>
            </div>
          )}

          {/* Bank Details Add Form */}

          {currentView === "add" && (
            <div>
              {/* Heading with Back Button */}
              {/* <div className="d-flex align-items-center mb-md-4 mb-3">
                <img src={arrow} alt="" className="add_bank_detail me-3" />
                <h5 className="m-0 add_bank_detail_text">Set Auto Pay</h5>
              </div> */}
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <h2 className="bankInfo mb-md-4 mb-3">Bank Info</h2>
                  <form onSubmit={formik.handleSubmit}>
                    {/* Account Number */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_no" className="form-label">
                          Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="acc_no"
                          placeholder="Enter Account Number"
                          {...formik.getFieldProps("acc_no")}
                        />
                        {formik.touched.acc_no && formik.errors.acc_no && (
                          <small className="text-danger">
                            {formik.errors.acc_no}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* Account Holder Name */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label
                          htmlFor="bank_holder_name"
                          className="form-label"
                        >
                          Account Holder Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="bank_holder_name"
                          placeholder="Enter Account Holder Name"
                          {...formik.getFieldProps("bank_holder_name")}
                        />
                        {formik.touched.bank_holder_name &&
                          formik.errors.bank_holder_name && (
                            <small className="text-danger">
                              {formik.errors.bank_holder_name}
                            </small>
                          )}
                      </div>
                    </div>

                    {/* IFSC Code */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="ifsc_code" className="form-label">
                          IFSC Code*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="ifsc_code"
                          placeholder="Enter IFSC Code"
                          {...formik.getFieldProps("ifsc_code")}
                        />
                        {formik.touched.ifsc_code &&
                          formik.errors.ifsc_code && (
                            <small className="text-danger">
                              {formik.errors.ifsc_code}
                            </small>
                          )}
                      </div>
                    </div>

                    {/* Bank Name */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="bank_id" className="form-label">
                          Bank Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          name="bank_id"
                          id=""
                          value={formik.values.bank_id}
                          changeFunction={(value) =>
                            formik?.setFieldValue("bank_id", value)
                          }
                          options={userEnachRegistrationsGet?.data?.user?.bank_accounts?.map(
                            (bank_account) => ({
                              value: bank_account.id,
                              label: bank_account.bank_name,
                            })
                          )}
                          {...formik.getFieldProps("bank_id")}
                        />
                        {/* <select
                          className="form-select"
                          id="bank_id"
                          {...formik.getFieldProps("bank_id")}
                        >
                          <option value="">Select</option>
                          <option value="ICICI">ICICI Bank</option>
                          <option value="HDFC">HDFC Bank</option>
                          <option value="SBI">SBI</option>
                        </select> */}
                        {formik.touched.bank_id && formik.errors.bank_id && (
                          <small className="text-danger">
                            {formik.errors.bank_id}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* Amount */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="mandate_amount" className="form-label">
                          Amount*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="mandate_amount"
                          placeholder="Enter Amount"
                          {...formik.getFieldProps("mandate_amount")}
                        />
                        {formik.touched.mandate_amount &&
                          formik.errors.mandate_amount && (
                            <small className="text-danger">
                              {formik.errors.mandate_amount}
                            </small>
                          )}
                      </div>
                    </div>

                    {/* Start Date and End Date */}
                    {/* Start Date */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor={`start_date`} className="form-label">
                          Start Date*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <DatePickerInput
                          selectedDate={formik.values.start_date}
                          minDate={new Date().toISOString().split("T")[0]}
                          onChange={(date) =>
                            formik.setFieldValue("start_date", date)
                          }
                        />
                        {formik.touched.start_date &&
                          formik.errors.start_date && (
                            <div className="text-danger">
                              {formik.errors.start_date}
                            </div>
                          )}
                      </div>
                    </div>

                    {/* End Date */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor={`end_date`} className="form-label">
                          End Date*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <DatePickerInput
                          selectedDate={formik.values.end_date}
                          minDate={new Date().toISOString().split("T")[0]}
                          onChange={(date) =>
                            formik.setFieldValue("end_date", date)
                          }
                          disable={formik.values.until_cancelled == "Y"}
                        />
                        {formik.touched.end_date && formik.errors.end_date && (
                          <div className="text-danger">
                            {formik.errors.end_date}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-3 mb-md-3 mb-sm-3 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="channel_type" className="form-label">
                          Channel Type*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          id=""
                          value={formik.values.channel_type}
                          changeFunction={(value) =>
                            formik?.setFieldValue("channel_type", value)
                          }
                          options={channelOption}
                        />
                        {formik.touched.channel_type &&
                        formik.errors.channel_type ? (
                          <div className="text-danger">
                            {formik.errors.channel_type}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5 d-sm-block d-none">
                        <label className="form-label"></label>
                      </div>
                      <div className="col-lg-7 col-md-6 mt-sm-3 mt-2 col-sm-7">
                        <div className="d-flex flex-wrap row-gap-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="until_cancelled"
                              id="until_cancelled"
                              checked={formik.values.until_cancelled == "Y"}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  formik.setFieldValue("until_cancelled", "Y");
                                } else {
                                  formik.setFieldValue("until_cancelled", "N");
                                }
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="male"
                            >
                              Until Cancelled
                            </label>
                            {formik.touched.until_cancelled &&
                              formik.errors.until_cancelled && (
                                <small className="text-danger">
                                  {formik.errors.until_cancelled}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5 d-sm-block d-none">
                        <label className="form-label"></label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <div className="d-flex flex-wrap row-gap-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="terms_condition"
                              id="terms_condition"
                              checked={formik.values.terms_condition == "Y"}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  formik.setFieldValue("terms_condition", "Y");
                                } else {
                                  formik.setFieldValue("terms_condition", "N");
                                }
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="male"
                            >
                              I accept all{" "}
                              <a
                                href="/termsconditions"
                                // target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </div>
                          {formik.touched.terms_condition &&
                            formik.errors.terms_condition && (
                              <small className="text-danger">
                                {formik.errors.terms_condition}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* Submit Button */}

                    <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                      <button type="submit" className="form_button next_btn">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* Bank Details Edit Form */}

          {currentView === "edit" && (
            <div>
              {/* Heading with Back Button */}
              {/* <div className="d-flex align-items-center mb-md-4 mb-3">
                <img src={arrow} alt="" className="add_bank_detail me-3" />
                <h5 className="m-0 add_bank_detail_text">Set Auto Pay</h5>
              </div> */}

              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <h2 className="bankInfo mb-md-4 mb-3">Bank Info</h2>
                  <form onSubmit={formik.handleSubmit}>
                    {/* Account Number */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_no" className="form-label">
                          Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="acc_no"
                          placeholder="Enter Account Number"
                          {...formik.getFieldProps("acc_no")}
                        />
                        {formik.touched.acc_no && formik.errors.acc_no && (
                          <small className="text-danger">
                            {formik.errors.acc_no}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* Account Holder Name */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label
                          htmlFor="bank_holder_name"
                          className="form-label"
                        >
                          Account Holder Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="bank_holder_name"
                          placeholder="Enter Account Holder Name"
                          {...formik.getFieldProps("bank_holder_name")}
                        />
                        {formik.touched.bank_holder_name &&
                          formik.errors.bank_holder_name && (
                            <small className="text-danger">
                              {formik.errors.bank_holder_name}
                            </small>
                          )}
                      </div>
                    </div>

                    {/* IFSC Code */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="ifsc_code" className="form-label">
                          IFSC Code*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="ifsc_code"
                          placeholder="Enter IFSC Code"
                          {...formik.getFieldProps("ifsc_code")}
                        />
                        {formik.touched.ifsc_code &&
                          formik.errors.ifsc_code && (
                            <small className="text-danger">
                              {formik.errors.ifsc_code}
                            </small>
                          )}
                      </div>
                    </div>

                    {/* Bank Name */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="bank_id" className="form-label">
                          Bank Name*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          name="bank_id"
                          id=""
                          value={formik.values.bank_id}
                          changeFunction={(value) =>
                            formik?.setFieldValue("bank_id", value)
                          }
                          options={userEnachRegistrationsGet?.data?.user?.bank_accounts?.map(
                            (bank_account) => ({
                              value: bank_account.id,
                              label: bank_account.bank_name,
                            })
                          )}
                          {...formik.getFieldProps("bank_id")}
                        />
                        {formik.touched.bank_id && formik.errors.bank_id && (
                          <small className="text-danger">
                            {formik.errors.bank_id}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* Amount */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="mandate_amount" className="form-label">
                          Amount*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <input
                          type="text"
                          className="form-control"
                          id="mandate_amount"
                          placeholder="Enter Amount"
                          {...formik.getFieldProps("mandate_amount")}
                        />
                        {formik.touched.mandate_amount &&
                          formik.errors.mandate_amount && (
                            <small className="text-danger">
                              {formik.errors.mandate_amount}
                            </small>
                          )}
                      </div>
                    </div>

                    {/* Start Date and End Date */}
                    {/* Start Date */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor={`start_date`} className="form-label">
                          Start Date*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <DatePickerInput
                          selectedDate={formik.values.start_date}
                          minDate={new Date().toISOString().split("T")[0]}
                          onChange={(date) =>
                            formik.setFieldValue("start_date", date)
                          }
                        />
                        {formik.touched.start_date &&
                          formik.errors.start_date && (
                            <div className="text-danger">
                              {formik.errors.start_date}
                            </div>
                          )}
                      </div>
                    </div>

                    {/* End Date */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6">
                        <label htmlFor={`end_date`} className="form-label">
                          End Date*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <DatePickerInput
                          selectedDate={formik.values.end_date}
                          minDate={new Date().toISOString().split("T")[0]}
                          onChange={(date) =>
                            formik.setFieldValue("end_date", date)
                          }
                          disable={formik.values.until_cancelled == "Y"}
                        />
                        {formik.touched.end_date && formik.errors.end_date && (
                          <div className="text-danger">
                            {formik.errors.end_date}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-3 mb-md-3 mb-sm-3 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="channel_type" className="form-label">
                          Channel Type*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <SelectDropDown
                          id=""
                          value={formik.values.channel_type}
                          changeFunction={(value) =>
                            formik?.setFieldValue("channel_type", value)
                          }
                          options={channelOption}
                        />
                        {formik.touched.channel_type &&
                        formik.errors.channel_type ? (
                          <div className="text-danger">
                            {formik.errors.channel_type}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5 d-sm-block d-none">
                        <label className="form-label"></label>
                      </div>
                      <div className="col-lg-7 col-md-6 mt-sm-3 mt-2 col-sm-7">
                        <div className="d-flex flex-wrap row-gap-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="until_cancelled"
                              id="until_cancelled"
                              checked={formik.values.until_cancelled == "Y"}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  formik.setFieldValue("until_cancelled", "Y");
                                } else {
                                  formik.setFieldValue("until_cancelled", "N");
                                }
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="male"
                            >
                              Until Cancelled
                            </label>
                            {formik.touched.until_cancelled &&
                              formik.errors.until_cancelled && (
                                <small className="text-danger">
                                  {formik.errors.until_cancelled}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5 d-sm-block d-none">
                        <label className="form-label"></label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7">
                        <div className="d-flex flex-wrap row-gap-2">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="terms_condition"
                              id="terms_condition"
                              checked={formik.values.terms_condition == "Y"}
                              onChange={(e) => {
                                if (e.target.checked == true) {
                                  formik.setFieldValue("terms_condition", "Y");
                                } else {
                                  formik.setFieldValue("terms_condition", "N");
                                }
                              }}
                            />
                            <label
                              className="form-check-label ms-2"
                              htmlFor="male"
                            >
                              I accept all{" "}
                              <a
                                href="/termsconditions"
                                // target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 underline"
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </div>
                          {formik.touched.terms_condition &&
                            formik.errors.terms_condition && (
                              <small className="text-danger">
                                {formik.errors.terms_condition}
                              </small>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* Submit Button */}

                    <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                      <button type="submit" className="form_button next_btn">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isPopupVisible && (
        <PopupModel
          id="alertModel"
          title="Lorem Ipsum"
          content="Are you confirm to delete mandate."
          image={cancel}
          closeModal={closeModal}
          btnColor={"btn-danger"}
          actionPopup={actionPopup}
        />
      )}
    </div>
  );
};

export default EnachRegistration;
