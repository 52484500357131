import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateSip } from "../actions/calculatorAction";
import _ from "lodash"; // For debounce

function SIPSliderComponent() {
  const dispatch = useDispatch();
  const sipCalculatorData = useSelector(
    (state) => state.calculator.sipCalculatorData
  );

  const [amount, setAmount] = useState(500);
  const [timePeriod, setTimePeriod] = useState(1);
  const [returnRate, setReturnRate] = useState(10);

  const updateSliderBackground = (value, element) => {
    const min = element.min || 0;
    const max = element.max || 1;
    const percentage = ((value - min) / (max - min)) * 100;
    element.style.background = `linear-gradient(to right, var(--primary-second) ${percentage}%, #e0e0e0 ${percentage}%)`;
  };

  const handleSliderChange = (e, setter, min, max) => {
    const value = parseFloat(e.target.value.replace(/[^0-9.]/g, "")) || 0; // Sanitize input
    const validValue = Math.max(min, Math.min(max, value)); // Clamp to range
    setter(validValue);
    updateSliderBackground(validValue, e.target);
  };

  const handleInputChange = (e, setter, min, max) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    value = value === "" ? "" : Math.max(min, Math.min(max, parseInt(value))); // Clamp to range
    setter(value || min); // Default to min if empty
  };

  const formatCurrency = (value) => {
    if (typeof value === "string") {
      value = parseFloat(value?.replace(/,/g, ""));
    }

    if (isNaN(value)) {
      return "Invalid value";
    }

    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  };

  const debouncedCalculateSip = useCallback(
    _.debounce((payload) => {
      dispatch(calculateSip(payload));
    }, 500), // 500ms debounce time
    [dispatch]
  );

  useEffect(() => {
    const payload = {
      amount: Number(amount),
      months: Number(timePeriod) * 12,
      returns: Number(returnRate),
    };

    debouncedCalculateSip(payload);
  }, [amount, timePeriod, debouncedCalculateSip, returnRate]);

  useEffect(() => {
    ["amountSlider", "timePeriodSlider"].forEach((id) => {
      const slider = document.getElementById(id);
      if (slider) updateSliderBackground(slider.value, slider);
    });
  }, [amount, timePeriod]);

  return (
    <div>
      {/* Amount Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="amountSlider" className="slider-label">
            Monthly Investment Amount
          </label>
          <span className="position-relative d-flex align-items-center">
            {/* <span
              className="position-absolute px-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              ₹
            </span> */}
            <input
              id="amountValue"
              className="slider-value border-0"
              // value={amount}
              value={`₹${amount}`}
              onChange={(e) => handleInputChange(e, setAmount, 0, 5000000)}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="amountSlider"
          min="100"
          max="5000000"
          value={amount}
          onChange={(e) => handleSliderChange(e, setAmount, 0, 5000000)}
        />
      </div>

      {/* Time Period Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="timePeriodSlider" className="slider-label">
            Time Period
          </label>
          <span className="position-relative d-flex align-items-center">
            <span
              className="position-absolute end-0 pe-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              Yr
            </span>
            <input
              id="timePeriodValue"
              className="slider-value border-0 px-4"
              value={timePeriod}
              onChange={(e) => handleInputChange(e, setTimePeriod, 0, 30)}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="timePeriodSlider"
          min="1"
          max="30"
          value={timePeriod}
          onChange={(e) => handleSliderChange(e, setTimePeriod, 0, 30)}
        />
      </div>

      {/* Return Rate Slider */}
      <div className="mb-lg-5 mb-4">
        <div className="d-flex align-items-center justify-content-between">
          <label htmlFor="returnSlider" className="slider-label">
            Return Rate
          </label>
          <span className="position-relative d-flex align-items-center">
            <span
              className="position-absolute end-0 pe-2 fw-bold"
              style={{ color: "var(--primary-second)" }}
            >
              %
            </span>
            <input
              id="returnValue"
              className="slider-value border-0 px-4"
              value={returnRate}
              onChange={(e) => handleInputChange(e, setReturnRate, 0, 40)}
            />
          </span>
        </div>
        <input
          type="range"
          className="slider"
          id="returnSlider"
          min="1"
          max="40"
          value={returnRate}
          onChange={(e) => handleSliderChange(e, setReturnRate, 0, 40)}
        />
      </div>

      {/* Results */}
      <p className="result-text mb-md-3">
        Total investment of {formatCurrency(amount * timePeriod * 12)}
      </p>
      <p className="result-text-label">
        Would have become:{" "}
        {sipCalculatorData?.future_value
          ? formatCurrency(sipCalculatorData.future_value)
          : "Calculating..."}{" "}
        <span className="profit-text">(+{sipCalculatorData?.returns}%)</span>
      </p>
    </div>
  );
}

export default SIPSliderComponent;
