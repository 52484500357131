import React, { useEffect, useState } from "react";
import "../styles/transaction.css";
import "../styles/common.css";
import addIcon from "../images/my_profile/add.svg"; // Add your add icon here
import bank_details from "../images/my_profile/bank_details.svg";
import pen_inner from "../images/my_profile/pen_inner.svg";
import { Link } from "react-router-dom";
import { IoMdDocument } from "react-icons/io";

import { useFormik } from "formik";
import * as Yup from "yup";
import bank_details_add from "../images/my_profile/bank_details.svg";
import arrow from "../images/my_profile/arrow.svg";
import SelectDropDown from "./SelectDropDown";
import "../styles/profile.css";
import pen from "../images/my_profile/pen.svg";
import view from "../images/my_profile/eye.svg";
import { sanitizeInput } from "../utils/sanitizeInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getBankDetail,
  primaryBankData,
  userBankDetails,
  userBankDetailsAdd,
  userBankDetailsUpdate,
  userDocumentGet,
  userDocumentUpdate,
} from "../actions/usersActions";
import { getLocalStorageUserData } from "../utils/userData";

const BankDetail = () => {
  const [currentView, setCurrentView] = useState("view");
  const [tab, setTab] = useState("Details");
  const [bankDetail, setBankDetail] = useState();
  const [chequeData, setChequeData] = useState("");
  const [initialValues, setInitialValues] = useState({
    acc_no: "",
    acc_no_confirm: "",
    bank_holder_name: "",
    acc_type: "",
    ifsc_code: "",
    branch_name: "",
    bank_code: "",
    branch_addr1: "",
    set_default: "N",
  });
  const dispatch = useDispatch();
  const formData = new FormData();
  const { userBankDetail, userDocument } = useSelector((state) => state.users);
  const userData = getLocalStorageUserData();
  const [bankData, setBankData] = useState(userBankDetail);
  // Handlers for view and add clicks
  const handleViewClick = () => setCurrentView("view");
  const handelTab = (val) => {
    setTab(val);
    if (val == "Documents") {
      dispatch(userDocumentGet());
    }
  };
  const handleAddClick = () => {
    setCurrentView("add");
    setInitialValues({
      acc_no: "",
      acc_no_confirm: "",
      bank_holder_name: "",
      acc_type: "",
      ifsc_code: "",
      branch_name: "",
      bank_code: "",
      branch_addr1: "",
      set_default: "N",
    });
  };
  const handleEditClick = (bank) => {
    setCurrentView("edit");
    const iniData = {
      acc_no: bank?.acc_no ? bank?.acc_no : "",
      acc_no_confirm: bank?.acc_no_confirm
        ? bank?.acc_no_confirm
        : bank?.acc_no,
      bank_holder_name: bank?.bank_holder_name ? bank?.bank_holder_name : "",
      acc_type: bank?.acc_type ? bank?.acc_type : "",
      ifsc_code: bank?.ifsc_code ? bank?.ifsc_code : "",
      branch_name: bank?.branch_name ? bank?.branch_name : "",
      bank_code: bank?.bank_code ? bank?.bank_code : "",
      branch_addr1: bank?.branch_addr1 ? bank?.branch_addr1 : "",
      set_default: bank?.set_default ? bank?.set_default : "N",
    };
    setInitialValues(iniData);
    setBankDetail(bank);
  };

  const handleAddDocClick = () => {
    setCurrentView("edit");
  };

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      acc_no: Yup.string()
        .matches(/^\d+$/, "Account number must contain only numbers") // Allow only numeric characters
        .min(8, "Account number must be at least 8 digits")
        .max(16, "Account number must not exceed 16 digits")
        .required("Account number is required"),
      acc_no_confirm: Yup.string()
        .matches(/^\d+$/, "Account number must contain only numbers") // Allow only numeric characters
        .min(8, "Account number must be at least 8 digits")
        .max(16, "Account number must not exceed 16 digits")
        .required("Confirm Account Number  is required")
        .oneOf([Yup.ref("acc_no")], "Account numbers do not match"),
      bank_holder_name: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Invalid Account Holder Name format")
        .required("Account Holder Name is required"),
      acc_type: Yup.string()
        .notOneOf(["Select"], "Account Type is required")
        .required("Account Type is required"),
      ifsc_code: Yup.string()
        .required("IFSC Code is required")
        .matches(
          /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
          "Invalid IFSC code, should follow format XXXX0XXXXXX"
        ),
      bank_code: Yup.string()
        .notOneOf(["Select"], "Bank Name is required")
        .required("Bank Name is required"),
      branch_name: Yup.string().required("Branch Name is required"),
      branch_addr1: Yup.string()
        .transform((value) => value.replace(/\s{2,}/g, " "))
        .test(sanitizeTest("Branch Address"))
        .max(200, "Input should not accept more than 200 characters")
        .required("Branch Address is required"),
    }),
    onSubmit: (values) => {
      if (currentView == "edit") {
        dispatch(
          userBankDetailsUpdate(
            {
              ...values,
              branch_addr1: values?.branch_addr1?.replace(/\s{2,}/g, " "),
            },
            bankDetail.id
          )
        );
      } else {
        dispatch(
          userBankDetailsAdd({
            ...values,
            branch_addr1: values?.branch_addr1?.replace(/\s{2,}/g, " "),
          })
        );
      }
      setTimeout(() => {
        setTab("Details");
        setCurrentView("view");
      }, 1000);

      //   dispatch(userOnboardingStoreData(values));
    },
  });

  const accountTypeOptions = [
    { value: "SB", label: "Saving" },
    { value: "CA", label: "Current" },
    { value: "CC", label: "CashCredit" },
  ];

  const formikDoc = useFormik({
    initialValues: {
      file_attachment: "",
      filePresent: 0,
      image_type: "CH",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      file_attachment: Yup.mixed()
        .required("Cancelled Cheque is required")
        .test("fileType", "Only PDF or image files are allowed", (value) => {
          if (!value) return false; // Ensure the value exists
          if (typeof value === "string") {
            // Handle file as a link
            const allowedExtensions = /\.(pdf|jpg|jpeg|png)$/i;
            return allowedExtensions.test(value);
          } else if (value instanceof File) {
            // Handle file as a File object
            const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
            return allowedTypes.includes(value.type);
          }
          return false;
        }),
    }),
    onSubmit: (values) => {
      for (const key in values) {
        formData.append(key, values[key]);
      }
      dispatch(userDocumentUpdate(formData));
    },
  });

  useEffect(() => {
    dispatch(userBankDetails());
  }, []);

  useEffect(() => {
    setBankData(userBankDetail);
  }, [userBankDetail]);

  useEffect(() => {
    if (userDocument) {
      setChequeData(userDocument?.data?.image);
    }
  }, [userDocument]);

  const callDetilOnIFSCCode = (ifscCode) => {
    dispatch(getBankDetail(ifscCode))
      .then((res) => {
        const matchingOption = Object.entries(userBankDetail?.banks).find(
          ([id]) => res.BANKCODE.includes(id)
        );
        if (matchingOption) {
          const [value] = matchingOption;
          formik.setFieldValue("bank_code", value);
        } else {
          formik.setFieldValue("bank_code", res.BANKCODE);
        }

        formik.setFieldValue("branch_name", res?.BRANCH);
        formik.setFieldValue("branch_addr1", res?.ADDRESS);
      })
      .catch((error) => {
        console.log("Error:".error);
      });
  };

  const handleSetDefault = async (selectedBankId) => {
    try {
      // Call API to update default bank
      // const response = await fetch("/api/set-default-bank", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ bankId: selectedBankId }),
      // });

      const payload = {
        bankId: selectedBankId,
        userId: userData?.id,
      };
      dispatch(primaryBankData(payload)).then((response) => {
        if (response.status == true) {
          // Update local state after successful API call
          setBankData((prev) => ({
            ...prev,
            bank_account: prev.bank_account.map((bank) =>
              bank.id === selectedBankId
                ? { ...bank, set_default: "Y" }
                : { ...bank, set_default: "N" }
            ),
          }));
        } else {
          console.error("Failed to update default bank");
        }
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const isPDF = chequeData && chequeData.endsWith(".pdf");
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingThree">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            {tab == "Details" ? (
              <div class="d-flex align-items-center">
                <img
                  src={bank_details}
                  alt=""
                  className="personel_details_img"
                />
                {/* <span className="personal_inner_text">Bank Details</span> */}
                <span className="personal_inner_text">
                  {currentView === "view" && "Bank Details"}
                  {currentView === "add" && "Bank Details Add"}
                  {currentView === "edit" && "Bank Details Edit"}
                </span>
              </div>
            ) : (
              <div class="d-flex align-items-center">
                <img
                  src={bank_details}
                  alt=""
                  className="personel_details_img"
                />
                {/* <span className="personal_inner_text">Bank Details</span> */}
                <span className="personal_inner_text">
                  {currentView === "view" && "Documents"}
                  {currentView === "edit" && "Documents Edit"}
                </span>
              </div>
            )}
          </div>
        </button>
      </h2>

      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />
        <div className="accordion-body profile_accordion_body redeam_bottom_card pt-3">
          <div className="d-flex justify-content-end align-items-right">
            <div className="edit_personel" style={{ marginRight: "10px" }}>
              {tab == "Details" ? (
                <>
                  <span
                    onClick={() => {
                      handelTab("Documents");
                    }}
                    className="badge editBadge profile-mr"
                  >
                    <IoMdDocument />
                    <span className="editInner d-sm-block d-none">Details</span>
                  </span>
                </>
              ) : (
                <>
                  <span
                    onClick={() => {
                      handelTab("Details");
                    }}
                    className="badge editBadge profile-mr"
                  >
                    <IoMdDocument />
                    <span className="editInner d-sm-block d-none">
                      Documents
                    </span>
                  </span>
                </>
              )}
            </div>
            <div className="edit_personel">
              {tab == "Details" && currentView !== "view" && (
                <>
                  <span
                    onClick={handleViewClick}
                    className="badge editBadge profile-mr"
                  >
                    <img src={view} alt="View Icon" className="pen_image" />
                    <span className="editInner d-sm-block d-none">View</span>
                  </span>
                </>
              )}

              {tab == "Details" && currentView === "view" && (
                <span
                  onClick={handleAddClick}
                  className="badge editBadge addbank-btn-mb profile-mr"
                >
                  <img src={addIcon} alt="" className="pen_image" />
                  <span
                    className="editInner d-sm-block d-none"
                    onClick={handleAddClick}
                  >
                    Add
                  </span>
                </span>
              )}

              {tab == "Documents" && currentView === "view" && (
                <span
                  onClick={handleAddDocClick}
                  className="badge editBadge addbank-btn-mb profile-mr"
                >
                  <img src={addIcon} alt="" className="pen_image" />
                  <span
                    className="editInner d-sm-block d-none"
                    onClick={handleAddDocClick}
                  >
                    Edit
                  </span>
                </span>
              )}
              {tab == "Documents" && currentView !== "view" && (
                <span
                  onClick={handleViewClick}
                  className="badge editBadge profile-mr"
                >
                  <img src={view} alt="View Icon" className="pen_image" />
                  <span className="editInner d-sm-block d-none">View</span>
                </span>
              )}
              {/* </span> */}
            </div>
          </div>

          {/* Bank Details Table */}

          {tab == "Details" && currentView === "view" && (
            <div className="table-responsive bankDetailTable">
              <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">Is Primary?</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Account No.</th>
                    <th scope="col">IFSC Code</th>
                    <th scope="col">Branch Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bankData?.bank_account?.map((bank) => {
                    return (
                      <tr>
                        <td>
                          <input
                            type="radio"
                            name="set_default"
                            onChange={(e) => handleSetDefault(bank.id)}
                            checked={bank?.set_default == "Y"}
                            className="form-input bank_input"
                          />
                        </td>
                        <td>{bank?.bank_code}</td>
                        <td>{bank?.acc_no}</td>
                        <td>{bank?.ifsc_code}</td>
                        <td>{bank?.branch_name}</td>
                        <td>
                          <Link
                            className="details_edit_btn btn p-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Edit"
                            // onClick={() => setCurrentView('edit')}
                            onClick={() => {
                              handleEditClick(bank);
                            }}
                          >
                            <img
                              src={pen_inner}
                              alt=""
                              className="pen_image_inner"
                            />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}

                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>
          )}

          {/* Add Bank Details */}

          {tab == "Details" && currentView === "add" && (
            <div>
              {/* Heading with Back Button */}
              <div className="d-flex align-items-center mb-md-4 mb-3">
                {/* <img src={arrow} alt="" className="add_bank_detail me-3" />
                <h5 className="m-0 add_bank_detail_text">Edit Bank Account</h5> */}
              </div>

              {/* Bank Details Add Form */}
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_no" className="form-label">
                          Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="acc_no"
                          placeholder="Enter Account Number"
                          value={formik.values.acc_no}
                          {...formik.getFieldProps("acc_no")}
                        />
                        {formik.touched.acc_no && formik.errors.acc_no ? (
                          <div className="text-danger">
                            {formik.errors.acc_no}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Other Fields (same as before) */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_no_confirm" className="form-label">
                          Confirm Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="password"
                          className="form-control"
                          id="acc_no_confirm"
                          placeholder="Confirm Account Number"
                          {...formik.getFieldProps("acc_no_confirm")}
                        />
                        {formik.touched.acc_no_confirm &&
                        formik.errors.acc_no_confirm ? (
                          <div className="text-danger">
                            {formik.errors.acc_no_confirm}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label
                          htmlFor="bank_holder_name"
                          className="form-label"
                        >
                          Account Holder Name*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="bank_holder_name"
                          placeholder="Enter Account Holder Name"
                          {...formik.getFieldProps("bank_holder_name")}
                        />
                        {formik.touched.bank_holder_name &&
                        formik.errors.bank_holder_name ? (
                          <div className="text-danger">
                            {formik.errors.bank_holder_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_type" className="form-label">
                          Account Type*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <SelectDropDown
                          name="acc_type"
                          id=""
                          value={formik.values.acc_type}
                          changeFunction={(value) =>
                            formik?.setFieldValue("acc_type", value)
                          }
                          options={accountTypeOptions}
                        />
                        {formik.touched.acc_type && formik.errors.acc_type ? (
                          <div className="text-danger">
                            {formik.errors.acc_type}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="ifsc_code" className="form-label">
                          IFSC Code*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="ifsc_code"
                          name="ifsc_code"
                          placeholder="Enter IFSC Code"
                          onChange={(e) => {
                            formik.handleChange(e); // Handle Formik's change
                            callDetilOnIFSCCode(e.target.value); // Call your custom function
                          }}
                          value={formik.values.ifsc_code}
                        />
                        {formik.touched.ifsc_code && formik.errors.ifsc_code ? (
                          <div className="text-danger">
                            {formik.errors.ifsc_code}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="bank_code" className="form-label">
                          Bank Name*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <SelectDropDown
                          name="bank_code"
                          id=""
                          value={formik.values.bank_code}
                          changeFunction={(value) =>
                            formik?.setFieldValue("bank_code", value)
                          }
                          options={
                            userBankDetail?.banks &&
                            Object.entries(userBankDetail?.banks).map(
                              ([id, label]) => ({
                                value: id,
                                label,
                              })
                            )
                          }
                        />
                        {formik.touched.bank_code && formik.errors.bank_code ? (
                          <div className="text-danger">
                            {formik.errors.bank_code}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="branch_name" className="form-label">
                          Branch Name*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="branch_name"
                          placeholder="Enter Branch Name"
                          {...formik.getFieldProps("branch_name")}
                        />
                        {formik.touched.branch_name &&
                        formik.errors.branch_name ? (
                          <div className="text-danger">
                            {formik.errors.branch_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="branch_addr1" className="form-label">
                          Branch Address*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="branch_addr1"
                          placeholder="Enter Branch Address"
                          {...formik.getFieldProps("branch_addr1")}
                        />
                        {formik.touched.branch_addr1 &&
                        formik.errors.branch_addr1 ? (
                          <div className="text-danger">
                            {formik.errors.branch_addr1}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                      <button type="submit" className="form_button next_btn">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* Edit bank Details */}

          {tab == "Details" && currentView === "edit" && (
            <div>
              {/* Heading with Back Button */}
              <div className="d-flex align-items-center mb-md-4 mb-3">
                {/* <img src={arrow} alt="" className="add_bank_detail me-3" />
                <h5 className="m-0 add_bank_detail_text">Edit Bank Account</h5> */}
              </div>

              {/* Bank Details Add Form */}
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_no" className="form-label">
                          Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="acc_no"
                          disabled={true}
                          placeholder="Enter Account Number"
                          value={formik.values.acc_no}
                          {...formik.getFieldProps("acc_no")}
                        />
                        {formik.touched.acc_no && formik.errors.acc_no ? (
                          <div className="text-danger">
                            {formik.errors.acc_no}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Other Fields (same as before) */}
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_no_confirm" className="form-label">
                          Confirm Account Number*
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="password"
                          disabled={true}
                          className="form-control"
                          id="acc_no_confirm"
                          placeholder="Confirm Account Number"
                          {...formik.getFieldProps("acc_no_confirm")}
                        />
                        {formik.touched.acc_no_confirm &&
                        formik.errors.acc_no_confirm ? (
                          <div className="text-danger">
                            {formik.errors.acc_no_confirm}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label
                          htmlFor="bank_holder_name"
                          className="form-label"
                        >
                          Account Holder Name*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="bank_holder_name"
                          placeholder="Enter Account Holder Name"
                          {...formik.getFieldProps("bank_holder_name")}
                        />
                        {formik.touched.bank_holder_name &&
                        formik.errors.bank_holder_name ? (
                          <div className="text-danger">
                            {formik.errors.bank_holder_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="acc_type" className="form-label">
                          Account Type*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <SelectDropDown
                          name="acc_type"
                          id=""
                          value={formik.values.acc_type}
                          changeFunction={(value) =>
                            formik?.setFieldValue("acc_type", value)
                          }
                          options={accountTypeOptions}
                        />
                        {formik.touched.acc_type && formik.errors.acc_type ? (
                          <div className="text-danger">
                            {formik.errors.acc_type}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="ifsc_code" className="form-label">
                          IFSC Code*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="ifsc_code"
                          name="ifsc_code"
                          placeholder="Enter IFSC Code"
                          onChange={(e) => {
                            formik.handleChange(e); // Handle Formik's change
                            callDetilOnIFSCCode(e.target.value); // Call your custom function
                          }}
                          value={formik.values.ifsc_code}
                        />
                        {formik.touched.ifsc_code && formik.errors.ifsc_code ? (
                          <div className="text-danger">
                            {formik.errors.ifsc_code}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="bank_code" className="form-label">
                          Bank Name*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <SelectDropDown
                          name="bank_code"
                          id=""
                          value={formik.values.bank_code}
                          disabled={true}
                          changeFunction={(value) =>
                            formik?.setFieldValue("bank_code", value)
                          }
                          options={Object.entries(userBankDetail?.banks).map(
                            ([id, label]) => ({
                              value: id,
                              label,
                            })
                          )}
                        />
                        {formik.touched.bank_code && formik.errors.bank_code ? (
                          <div className="text-danger">
                            {formik.errors.bank_code}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="branch_name" className="form-label">
                          Branch Name*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="branch_name"
                          placeholder="Enter Branch Name"
                          {...formik.getFieldProps("branch_name")}
                        />
                        {formik.touched.branch_name &&
                        formik.errors.branch_name ? (
                          <div className="text-danger">
                            {formik.errors.branch_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                      <div className="col-lg-5 col-md-6 col-sm-5">
                        <label htmlFor="branch_addr1" className="form-label">
                          Branch Address*{" "}
                        </label>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-7 ps-xl-0">
                        <input
                          type="text"
                          className="form-control"
                          id="branch_addr1"
                          placeholder="Enter Branch Address"
                          {...formik.getFieldProps("branch_addr1")}
                        />
                        {formik.touched.branch_addr1 &&
                        formik.errors.branch_addr1 ? (
                          <div className="text-danger">
                            {formik.errors.branch_addr1}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center gap-2 mt-lg-5 mt-4">
                      <button type="submit" className="form_button next_btn">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {tab == "Documents" && currentView === "view" && (
            <div>
              {/* Bank Details Add Form */}
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-2">
                  {isPDF ? (
                    // Render PDF
                    <iframe
                      src={chequeData}
                      width="100%"
                      height="200px"
                      title="PDF Viewer"
                      style={{ border: "none" }}
                    ></iframe>
                  ) : (
                    /* Alternatively, use react-pdf for advanced PDF rendering */
                    // <Document file={fileData}>
                    //   <Page pageNumber={1} />
                    // </Document>
                    // Render Image
                    <>
                      {chequeData ? (
                        <img
                          src={chequeData}
                          alt="Uploaded File"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      ) : (
                        <p>No document found</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {tab == "Documents" && currentView === "edit" && (
            <div>
              {/* Bank Details Add Form */}
              <div className="row form_body justify-content-center">
                <div className="col-xl-7 col-lg-10 col-md-10 my-3">
                  <form onSubmit={formikDoc.handleSubmit}>
                    <input
                      type="file"
                      className="form-control custom-file-input"
                      id="file_attachment"
                      name="file_attachment"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        formikDoc?.setFieldValue("file_attachment", file);
                      }}
                    />
                    {formikDoc.touched.file_attachment &&
                    formikDoc.errors.file_attachment ? (
                      <div className="text-danger">
                        {formikDoc.errors.file_attachment}
                      </div>
                    ) : null}
                    <button
                      type="submit"
                      className="form_button next_btn mt-2"
                      style={{ height: "40px" }}
                    >
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BankDetail;
