import React, { useEffect, useState } from "react";
import slugify from "slugify"; // Install with `npm install slugify`
import { getReferralCode, genrateReferralCode } from "../../actions/rmAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../loader/Loader-ui";
import { toast } from "react-toastify";

const ReferralDetails = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { rmReferralCode = {} } = useSelector((state) => state?.admin);
  const referralData = rmReferralCode?.data || {};
  const referralCode = referralData?.referral_code;

  // Generate the referral link if code exists
  const referralLink = referralCode
    ? `https://fundswala.com/login/${slugify(referralCode, {
        lower: true,
        strict: true,
      })}`
    : "";

  // Fetch referral code on component mount
  const fetchReferral = async () => {
    setIsLoading(true);
    try {
      await dispatch(getReferralCode());
    } catch (error) {
      console.error("Error fetching referral code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReferral();
  }, []);

  // Generate referral code if none exists
  const generateReferral = async () => {
    setIsLoading(true);
    try {
      await dispatch(genrateReferralCode()); // Call API to generate referral code
      await fetchReferral(); // Fetch updated referral code
    } catch (error) {
      console.error("Error generating referral code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Copy referral code to clipboard
  const copyToClipboard = (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text)
        .then(() => toast.success("Referral code copied!"))
        .catch((err) => toast.error("Failed to copy:", err));
    } else {
      // Fallback method for non-secure contexts
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast.success("Referral code copied!");
    }
  };

  return (
    <div className="container-fluid page_background">
      <div className="page-inner-content mb-4">
        <div className="page_inner_content_box">
          <h5 className="mb-4 main-heading-title">Referral Code</h5>

          <div className="card border-0" style={{ borderRadius: "12px" }}>
            <div className="card-body form_cart form_card_customer">
              <div className="row align-items-center">
                {isLoading ? (
                  <div className="loader-overlay">
                    <Loader />
                  </div>
                ) : referralCode ? (
                  <div className="col-12">
                    <p className="mb-2">Your referral code is:</p>

                    {/* Clickable Referral Code */}
                    <p
                      className="fw-bold text-primary border p-2 rounded d-inline-block cursor-pointer"
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                      onClick={() => copyToClipboard(referralCode)}
                    >
                      {referralCode}
                    </p>

                    <p>
                      You can share this code with your customers to use while
                      registering on Fundswala.
                    </p>

                    <p>
                      Alternatively, you can share this link with your customers
                      to click and register on Fundswala:
                    </p>

                    {/* Clickable Referral Link */}
                    <a
                      href={referralLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary"
                      style={{
                        fontSize: "18px",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {referralLink}
                    </a>
                  </div>
                ) : (
                  <div className="col-12 text-center">
                    <p className="mb-3">No referral code found.</p>
                    <button
                      className="btn btn-primary"
                      onClick={generateReferral}
                      disabled={isLoading}
                    >
                      {isLoading ? "Generating..." : "Generate Referral Code"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralDetails;
