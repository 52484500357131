import React, { useEffect, useState } from "react";
import "../../styles/transaction.css";
import "../../styles/common.css";
import my_profile123 from "../../images/my_profile/personel_details.svg";
import "../../styles/profile.css";

const PersonelDetail = ({ data }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header profile_header" id="headingTwo">
        <button
          className="accordion-button collapsed px-lg-4 px-md-4 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img
                src={my_profile123}
                alt=""
                className="personel_details_img"
              />

              <span className="personal_inner_text">Personal Details</span>
            </div>
          </div>
        </button>
      </h2>

      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <hr className="m-0" />

        <div className="accordion-body accordion-body1 profile_accordion_body">
          <div className="d-flex justify-content-end align-items-right"></div>

          {/* Edit Personal Details Content */}

          {/* View Personal Details Content */}

          <div className="card redeam_bottom_card">
            <div className="row form_body justify-content-center">
              <div className="col-xl-6 col-lg-10 col-md-10 ">
                <form>
                  <div className="row align-items-center personalAccordian  mb-lg-4 mb-md-4 mb-sm-4 mb-3">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Full Name:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>{data?.inv_name}</span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Date of Birth:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>{data?.dob}</span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Email:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span className="white-space-nowrap">
                        {data?.email}
                        {/* {userGetProfile?.data?.user?.email} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Email Belongs to:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.email_relation}
                        {/* {relationLabel(
                          userGetProfile?.data?.user?.profile
                            ?.email_relation
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Mobile Number:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.mobile_no}
                        {/* {userGetProfile?.data?.user?.mobile} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">
                        Mobile Number Belongs to:
                      </label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.mobile_relation}
                        {/* {relationLabel(
                          userGetProfile?.data?.user?.profile
                            ?.mobile_relation
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Gender:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.gender}

                        {/* {getGenderLabel(
                          userGetProfile?.data?.user?.profile?.gender
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Marital Status:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.marital_status}
                        {/* {getMaritalLabel(
                          userGetProfile?.data?.user?.profile
                            ?.marital_status
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Father/Husband Name:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.father_name}
                        {/* {userGetProfile?.data?.user?.profile?.father_name} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Occupation:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.occupation}
                        {/* {getOccupationLabel(
                          userGetProfile?.data?.user?.profile?.occupation
                        )} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">PAN:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.pan}
                        {/* {userGetProfile?.data?.user?.pan} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Aadhar Number:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.adhaar_card}
                        {/* {userGetProfile?.data?.user?.profile?.adhaar_card} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Address:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.addr1}
                        {/* {userGetProfile?.data?.user?.profile?.addr1} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">Pincode:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.pincode}

                        {/* {userGetProfile?.data?.user?.profile?.pincode} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">City:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.city}

                        {/* {userGetProfile?.data?.user?.profile?.city} */}
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center mb-lg-4 mb-md-4 mb-sm-4 mb-3 personalAccordian">
                    <div className="col-6 my-md-2 my-1">
                      <label className="form-label">State:</label>
                    </div>
                    <div className="col-6 my-md-2 my-1">
                      <span>
                        {data?.state}

                        {/* {getStateLabel(
                          userGetProfile?.data?.user?.profile?.state
                        )} */}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonelDetail;
